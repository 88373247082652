























































import Vue from "vue";
import { asMoney, asDate, capitalize } from "@/filters/common";

export default Vue.extend({
  filters: {
    asMoney,
    asDate,
    capitalize,
  },
  data: function () {
    return {
      reason: "",
    };
  },
  methods: {},
  computed: {},
  watch: {},
});
