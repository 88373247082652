import Vue from "vue";
import VueRouter from "vue-router";

import { mapViewRoutes } from "@/routes";
import store from "@/store";
import { SessionState } from "@/store/modules/session";

export interface StateValues {
  readonly session?: SessionState;
}

let baseComponent: null | Vue = null;
let stateValues: StateValues | null = null;

/**
 * Returns an HTML string which could be added to the `#details_container`, generally for an error message.
 *
 * @param content - HTML string to insert into the content div
 */
function staticMapViewLayout(content: string) {
  return `
    <div class="ibox m-b-none">
      <div class="ibox-content">
        <div class="text-center">
          ${content}
        </div>
      </div>
    </div>`;
}

/**
 * Add `stateValues` to `window.history.state` if they aren't already there so we can recover if the user refreshes the page.
 */
export function ensureHistoryHasState(): void {
  if (!stateValues) {
    throw new Error("Can't add null state to history object");
  }
  if (!window.history.state?.vue_mapView_stateValues) {
    window.history.replaceState(
      { ...(window.history.state || {}), vue_mapView_stateValues: stateValues },
      "",
      window.location.pathname + window.location.search + window.location.hash
    );
  }
}

/**
 * Called when a Django view overwrites our baseComponent, thus leaving it attached to an element that is no longer in the document. Our base component will disable itself until re-enabled by mountMapViewRoutes().
 */
export function detachMapView(): void {
  if (baseComponent) {
    baseComponent.$emit("disable");
  }
}

/** We call this from Django's map view template to enable the router for map views
 *
 * @param parentElement - the #details_container element
 * @param newStateValues - stateValues object to populate the store with, or null if there's nothing new
 */
export default function mountMapViewRoutes(
  parentElement: HTMLElement,
  newStateValues: StateValues | null
): void {
  const storeIsInitialized = stateValues !== null;
  const history_stateValues = window.history.state?.vue_mapView_stateValues as
    | StateValues
    | undefined;

  // get a value for stateValues, or fail
  stateValues = newStateValues || stateValues || history_stateValues || null;
  if (!stateValues) {
    parentElement.innerHTML = staticMapViewLayout(`
      <h1>Error</h1>
      <p>We could not recover the state of this view.</p>
    `);
    return;
  }

  // save stateValues to the history state
  ensureHistoryHasState();

  // update the store with stateValues
  if (!storeIsInitialized || newStateValues) {
    if (typeof stateValues.session === "object") {
      store.commit(
        "setSessionIsAuthenticated",
        stateValues.session.isAuthenticated
      );
      store.commit("setSessionUserId", stateValues.session.userId);
      store.commit("setSessionCompany", stateValues.session.company);
      store.commit("setSessionFeatureFlags", stateValues.session.featureFlags);
    }
  }

  if (!baseComponent) {
    // initialize our baseComponent
    baseComponent = createBaseComponent();
  } else {
    // we're arriving here from a hotlink, which wouldn't have let Vue Router know about the new URL
    baseComponent.$router.replace(
      window.location.pathname + window.location.search + window.location.hash
    );
    baseComponent.$emit("enable");
  }

  // attach to #details_container
  parentElement.innerHTML = "";
  parentElement.appendChild(baseComponent.$el);
}

/**
 * Create the base component, with routing
 */
function createBaseComponent() {
  Vue.use(VueRouter);

  const router = new VueRouter({
    mode: "history",
    routes: mapViewRoutes,
  });

  // router.onError(function () {
  //   debugger;
  // });

  // user must be logged in unless all routes' meta.requiresAuthentication === false
  router.beforeEach((to, from, next) => {
    if (
      store.state.session.isAuthenticated === true ||
      !to.matched.some(
        (routeRecord) => routeRecord.meta.requiresAuthentication !== false
      )
    ) {
      next();
    } else {
      // redirect to /accounts/login/?next=...
      const loginPage = new URL("/accounts/login/", window.location.toString());
      loginPage.search = new URLSearchParams({ next: to.fullPath }).toString();
      window.location.replace(loginPage.toString());

      next(false);
    }
  });

  // add stateValues to window.history.state so we can recover if the user reloads
  router.afterEach(() => {
    ensureHistoryHasState();
  });

  const component = new Vue({
    store,
    data: {
      enabled: true,
    },
    template: `
      <router-view v-if='enabled'/>
      <div v-else hidden>
        <!-- I am disabled (mapMapViewRoutes.ts) -->
      </div>`,
    router,
  })
    // when a Django view is displayed in our place
    .$on("disable", function (this: Vue) {
      this.$data.enabled = false;
    })
    // we're replacing a Django view
    .$on("enable", function (this: Vue) {
      this.$data.enabled = true;
    })
    .$mount();

  return component;
}
