/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-this-alias */

import { Module } from "vuex";
import { RootState } from "../../store";

import { iBoard, iFilter } from "./types";

export interface BuilderKanbanState {
  boards: Array<iBoard>;
  quickFilters: Array<iFilter>;
  clientHeight: number;
  routerViewWidth: number;
  selectedBoard: number;
  showLoadingSpinner: boolean;
}

const store: Module<BuilderKanbanState, RootState> = {
  state: (): BuilderKanbanState => ({
    boards: [
      {
        id: 1,
        name: "Orders",
        isFabricationBoard: false,
        parent_id: 0,
        columns: [
          {
            id: 1,
            name: "Incoming Orders",
            allowSubWorkflows: false,
            orders: [1, 2, 3],
          },
          {
            id: 2,
            name: "Approved Orders",
            allowSubWorkflows: false,
            orders: [4],
          },
          {
            id: 3,
            name: "Fabrication",
            allowSubWorkflows: true,
            orders: [5, 6],
          },
          {
            id: 4,
            name: "Packaging",
            allowSubWorkflows: false,
            orders: [],
          },
          {
            id: 5,
            name: "Shipping",
            allowSubWorkflows: false,
            orders: [],
          },
          {
            id: 6,
            name: "Shipped",
            allowSubWorkflows: false,
            orders: [],
          },
        ],
      },
      {
        id: 2,
        name: "Quotes",
        isFabricationBoard: false,
        parent_id: 0,
        columns: [
          {
            id: 1,
            name: "New Quotes",
            allowSubWorkflows: false,
            orders: [1],
          },
          {
            id: 2,
            name: "Waiting on us",
            allowSubWorkflows: false,
            orders: [],
          },
          {
            id: 3,
            name: "Waiting on client",
            allowSubWorkflows: false,
            orders: [],
          },
          {
            id: 4,
            name: "Accepted",
            allowSubWorkflows: false,
            orders: [],
          },
          {
            id: 2,
            name: "Rejected",
            allowSubWorkflows: false,
            orders: [],
          },
        ],
      },
      {
        id: 3,
        name: "Posts",
        isFabricationBoard: true,
        parent_id: 3,
        columns: [
          {
            id: 1,
            name: "Order Posts",
            allowSubWorkflows: false,
            orders: [],
          },
          {
            id: 2,
            name: "Paint and Finish",
            allowSubWorkflows: false,
            orders: [],
          },
        ],
      },
      {
        id: 4,
        name: "Print On Panel",
        isFabricationBoard: true,
        parent_id: 3,
        columns: [
          {
            id: 1,
            name: "Order Material",
            allowSubWorkflows: false,
            orders: [],
          },
          {
            id: 2,
            name: "Print",
            allowSubWorkflows: false,
            orders: [],
          },
          {
            id: 2,
            name: "Cure",
            allowSubWorkflows: false,
            orders: [],
          },
        ],
      },
      {
        id: 5,
        name: "Aluminum",
        isFabricationBoard: true,
        parent_id: 3,
        columns: [
          {
            id: 1,
            name: "Paint Surface",
            allowSubWorkflows: false,
            orders: [],
          },
          {
            id: 2,
            name: "Bend",
            allowSubWorkflows: false,
            orders: [],
          },
          {
            id: 2,
            name: "Print",
            allowSubWorkflows: false,
            orders: [],
          },
        ],
      },
    ],
    quickFilters: [
      {
        id: 1,
        name: "Only Mine",
        pressed: false,
        active: true,
        description: "Only show issues that I am assigned to or watching",
      },
      {
        id: 2,
        name: "Recently Updated",
        pressed: false,
        active: true,
        description: "Show orders that have been updated in the last day",
      },
      {
        id: 3,
        name: "Due Soon",
        pressed: false,
        active: true,
        description: "Show orders that are due in the next 7 days or overdue",
      },
    ],
    clientHeight: 0,
    routerViewWidth: 0,
    selectedBoard: 1,
    showLoadingSpinner: false,
  }),

  mutations: {
    setBoards(state, payload) {
      state.boards = payload;
    },
    setColumns(state, payload) {
      state.boards[payload.boardIndex].columns = payload.columns;
    },
    screenResized(state) {
      state.clientHeight = window.innerHeight - 75;
      state.routerViewWidth = window.innerWidth - 20;
    },
    setClientHeight(state, height) {
      state.clientHeight = height;
    },
    addColumn(state, boardId) {
      const board = state.boards.find(function (board: any) {
        return board.id == boardId;
      });

      if (board) {
        board.columns.push({
          id: 99,
          name: "New Column",
          allowSubWorkflows: false,
          orders: [],
        });
      }
    },
    removeColumnById(state, columnId) {
      let column_index = -1;
      for (let i = 0; i < state.boards[0].columns.length; i++) {
        if (state.boards[0].columns[i].id === columnId) {
          column_index = i;
          break;
        }
      }
      if (column_index != -1) {
        state.boards[0].columns.splice(column_index, 1);
      }
    },
    setSelectedBoard(state, payload) {
      state.selectedBoard = payload;
    },
    removeOrderFromWorkflow(state, payload) {
      //payload {orderId, workflowId, workflowStep }
      const board = state.boards.find(function (board: any) {
        return board.id == payload.workflowId;
      });

      if (board) {
        //remove order from
        board.columns[payload.workflowStep].orders = board.columns[
          payload.workflowStep
        ].orders.filter(function (order: any) {
          return order !== payload.orderId;
        });
      }
    },
    addOrderToWorkflow(state, payload) {
      //payload { orderId, workflowId, workflowStep }
      const board = state.boards.find(function (board: any) {
        return board.id == payload.workflowId;
      });

      if (board) {
        if (
          !board.columns[payload.workflowStep].orders.includes(payload.orderId)
        ) {
          board.columns[payload.workflowStep].orders.push(payload.orderId);
        }
      }
    },
    updateBoardName(state, payload) {
      const board = state.boards.find(function (board: any) {
        return board.id == payload.boardId;
      });

      if (board) {
        board.name = payload.name;
      }
    },
    newWorkflow(state, parent) {
      //find the highest id
      let maxId = 0;
      state.boards.forEach(function (board: any) {
        if (board.id > maxId) {
          maxId = board.id;
        }
      });

      maxId++;

      state.boards.push({
        id: maxId,
        name: "New workflow",
        isFabricationBoard: true,
        parent_id: parent,
        columns: [],
      });
    },
    setShowLoadingSpinner(state, payload: boolean) {
      state.showLoadingSpinner = payload;
    },
  },
  getters: {
    showLoadingSpinner(state) {
      return state.showLoadingSpinner;
    },
    boards(state) {
      return state.boards;
    },
    clientHeight(state) {
      return state.clientHeight;
    },
    routerViewWidth(state) {
      return state.routerViewWidth;
    },
    quickFilters(state) {
      return state.quickFilters;
    },
    getColumnById(state) {
      return function (columnId: any) {
        let column_index = -1;
        for (let i = 0; i < state.boards[0].columns.length; i++) {
          if (state.boards[0].columns[i].id === columnId) {
            column_index = i;
            break;
          }
        }
        return state.boards[0].columns[column_index];
      };
    },
    selectedBoard(state) {
      return state.selectedBoard;
    },
    // orders(state) {
    //   return state.orders;
    // },
    fabricationBoards(state) {
      return state.boards.filter(function (board: any) {
        return board.isFabricationBoard;
      });
    },
    boardsByParent: (state) => (id: number) => {
      return state.boards.filter(function (board: any) {
        return board.parent_id === id;
      });
    },
    boardById: (state) => (id: number) => {
      return state.boards.find(function (board: any) {
        return board.id == id;
      });
    },
    workflowSteps: (state) => (id: number) => {
      const board = state.boards.find(function (board: any) {
        return board.id == id;
      });

      if (board) {
        return board.columns;
      }
    },
  },
};

export default store;
