









































//import { iSignChild } from "../../types";
import Vue from "vue";
import CollapsibleSection from "../CollapsibleSection.vue";

import { IField } from "../../types";

export default Vue.extend({
  components: { CollapsibleSection },
  props: {
    signData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    /**
     * select a field
     * @param {IField} field - the field that was clicked on
     */
    selectField(field: IField) {
      this.$store.commit("selectFieldById", field.id);
      this.$nextTick(function () {
        //force a renderer of SVGRenderer
        this.$store.commit("setRerender", !this.$store.getters.rerender);
      });
    },
    /**
     * display the showAddFieldModal so that user can add a field
     */
    addField() {
      this.$store.commit("setShowAddFieldModal", true);
    },
    /**
     * sort the fields array by name
     * @returns {Array<IField>} an array of IFields
     */
    sortedFields: function (fieldList: Array<IField>): Array<IField> {
      return fieldList.sort((a, b) =>
        a.name > b.name ? 1 : a.name < b.name ? -1 : 0
      );
    },
  },
  computed: {
    /**
     * determine if section should be displayed open
     * @return {boolean} - true if section should be open
     */
    openSection: function (): boolean {
      //if we have some fields then section should be open
      if (this.$store.getters.fields.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
});
