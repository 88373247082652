








import Vue from "vue";

export default Vue.extend({
  name: "TimedAlert",
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showAlert: true,
    };
  },
  mounted() {
    // Hide the alert after timeInSecond seconds
    setTimeout(() => {
      this.showAlert = false;
    }, 4000); // 4 seconds
  },
  watch: {
    message: function () {
      this.showAlert = true;
    },
  },
});
