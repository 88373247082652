






















































import Vue from "vue";

interface INonBillingOwner {
  id: number;
  firstName: string;
  lastName: string;
}

export default Vue.extend({
  data: function () {
    return {
      companyId: this.$route.params.id,
      billingOwner: 0,
      confirm: false,
      nonBillingOwners: [] as Array<INonBillingOwner>,
    };
  },
  mounted: function () {
    this.getUsers();
  },
  methods: {
    getUsers: function () {
      // GET USERS
      // get company members
      const query = JSON.stringify({
        query: `query Permissions($objectId: ID = ${this.companyId}) {
              permissions: company (id:$objectId) {
                groupUsers {
                  pageInfo {
                    hasNextPage
                  }
                  edges {
                    node {
                      user {
                        id: userId
                        firstName
                        lastName
                        companyRole
                      }
                    }
                  }
                }
              }
            }`,
      });
      fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((data) => data.json())
        .then((result) => {
          result.data.permissions.groupUsers.edges.forEach(
            (edge: {
              node: {
                user: {
                  id: number;
                  firstName: string;
                  lastName: string;
                  companyRole: string;
                };
              };
            }) => {
              if (edge.node.user.companyRole !== "A_3") {
                this.nonBillingOwners.push({
                  id: edge.node.user.id,
                  firstName: edge.node.user.firstName,
                  lastName: edge.node.user.lastName,
                });
              }
            }
          );
        });
    },
    changeBillingOwner(userId: string) {
      const query = JSON.stringify({
        query: `
          mutation change_billing_owner {
            mutateCompanyBilling(input: {
              company: ${this.companyId},
              owner: ${userId}
            }) {
              errors { messages }
              companyBilling {
                owner {
                  id: contentObjectId
                  userId
                  firstName
                  lastName
                  email
                }
              }
            }
          }`,
      });
      // change billing owner on backend
      fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((data) => data.json())
        .then((result) => {
          if (result.errors) {
            alert(result.errors[0].message);
          } else if (result.data.mutateCompanyBilling?.errors.length > 0) {
            alert(
              result.data.mutateCompanyBilling.errors[0].messages.join(", ")
            );
          } else {
            this.$store.commit(
              "setSubscriptionBillingOwner",
              result.data.mutateCompanyBilling.companyBilling.owner.userId
            );
            this.$emit("billingOwnerChanged");
          }
        });
    },
  },
  computed: {},
});
