








































































































import Vue from "vue";
import { PropType } from "vue";

import $ from "jquery";

import { IPlanAndTiers, ISubscription, ISubscriptionItem } from "../types";

export default Vue.extend({
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    subscription: {
      type: Object as PropType<ISubscription>,
      required: true,
    },
    subscriptionItem: {
      type: Object as PropType<ISubscriptionItem>,
      required: true,
    },
  },
  data: function () {
    return {
      newTier: 0,
      newTotal: 0,
      saveInProgress: false,
      plan: {
        name: "",
        stripePriceId: "",
        tiers: [],
      } as IPlanAndTiers,
    };
  },
  methods: {
    fetchPlanDetails: function () {
      if (this.subscriptionItem.plan) {
        const query = JSON.stringify({
          query: `
          query getPricePlan {
            pricingPlan (id: ${this.subscriptionItem.plan.id}) {
              id: contentObjectId
              name
              prices {
                edges {
                  node {
                    billingCycle
                    stripePriceId
                    tiers {
                      edges {
                        node {
                          upTo
                          amount
                          currency
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        });

        fetch("/graphql/", {
          method: "POST",
          body: query,
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
          },
        })
          .then((data) => data.json())
          .then((result) => {
            if (result.errors) {
              alert(result.errors[0].message);
            } else {
              this.plan.name = result.data.pricingPlan.name;
              this.plan.stripePriceId =
                result.data.pricingPlan.prices.edges[0].node.stripePriceId;
              this.plan.tiers = [];

              result.data.pricingPlan.prices.edges.forEach(
                (price: {
                  node: {
                    billingCycle: string;
                    stripePriceId: string;
                    tiers: {
                      edges: Array<{
                        node: {
                          upTo: number;
                          amount: number;
                          currency: string;
                        };
                      }>;
                    };
                  };
                }) => {
                  // Stripe stores the price tier amounts differently then we display
                  // them on the front end.  Stripe stores the price as the difference - so
                  // to get the total for the second tier you add the first tier and the
                  // second tier amounts together.  tierTotal is the variable we store the
                  // total in
                  let tierTotal = 0;
                  price.node.tiers.edges.forEach((tier) => {
                    // update the tierTotal
                    tierTotal += tier.node.amount;
                    this.plan.tiers.push({
                      cycle: price.node.billingCycle,
                      upTo: tier.node.upTo,
                      amount: tierTotal,
                      currency: tier.node.currency,
                      stripeId: price.node.stripePriceId,
                    });
                  });
                }
              );
            }
          });
      }
    },

    switchToMonthly: function () {
      const items = [];
      this.$store.commit("setShowLoadingSpinner", true);
      for (const plan of this.subscription.items) {
        // const priceTier = this.$store.getters.priceTierById(plan.priceTierId);
        const monthlyTier = this.getMonthlyPlan(this.subscriptionItem.upTo);
        if (!monthlyTier) {
          throw new Error("No monthly tier found");
        }
        items.push({
          id: plan.id,
          stripeId: plan.stripeSubscriptionId,
          price: monthlyTier.stripeId,
          quantity: monthlyTier.upTo,
        });
      }

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          variables: {
            mutateSubscriptionInput: {
              company: this.$route.params.id,
              cancel: false,
              id: this.subscription.id,
              items: JSON.stringify(items),
            },
          },
          query: `
            mutation changeToMonthlyPlan (
              $mutateSubscriptionInput: MutateSubscriptionInput!
            ) {
              mutateSubscription(input: $mutateSubscriptionInput) {
                errors { messages }
                subscription {
                  id
                  stripeSubscriptionId
                }
              }
            }`,
        }),
      })
        .then((data) => data.json())
        .then((result) => {
          this.$store.commit("setShowLoadingSpinner", false);

          if (result.errors) {
            alert(result.errors[0].message);
          } else if (result.data.mutateSubscription?.errors.length > 0) {
            alert(result.data.mutateSubscription.errors[0].messages.join(", "));
          } else {
            $("#changeToMonthlyPlanModal").modal("hide");
            this.$emit("closed");
            location.reload();
          }
        });

      // for (const plan of this.$store.getters.plansForSubscription(
      //     this.$store.getters.subscription.id
      //   )) {
      //     const priceTier = this.$store.getters.priceTierById(plan.priceTierId);
      //     const monthlyTier = this.$store.getters.getMonthlyPriceTier(priceTier);
      //     if (!monthlyTier) {
      //       throw new Error("No annual tier found");
      //     }
      //     items.push({
      //       id: plan.subscriptionItemId,
      //       stripeId: plan.stripeSubscriptionItemId,
      //       price: monthlyTier.stripePriceId,
      //       quantity: priceTier.numberOfUsers,
      //     });
      //   }

      //   fetch("/graphql/", {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       variables: {
      //         mutateSubscriptionInput: {
      //           company: this.$route.params.id,
      //           cancel: false,
      //           id: this.$store.getters.subscription.id,
      //           items: JSON.stringify(items),
      //         },
      //       },
      //       query: `
      //         mutation changeToYearlyPlan (
      //           $mutateSubscriptionInput: MutateSubscriptionInput!
      //         ) {
      //           mutateSubscription(input: $mutateSubscriptionInput) {
      //             errors { messages }
      //             subscription {
      //               id
      //               stripeSubscriptionId
      //             }
      //           }
      //         }`,
      //     }),
      //   })
      //     .then((data) => data.json())
      //     .then((result) => {
      //       if (result.errors) {
      //         alert(result.errors[0].message);
      //       } else if (result.data.mutateSubscription?.errors.length > 0) {
      //         alert(result.data.mutateSubscription.errors[0].messages.join(", "));
      //       } else {
      //         $("#change-to-monthly-plan-modal").modal("hide");
      //         location.reload();
      //       }
      //     });
    },
    monthlyPlanAsArray: function (): Array<{
      cycle: string;
      upTo: number;
      amount: number;
      currency: string;
      stripeId: string;
    }> {
      const annualPlan = this.getMonthlyPlan(this.subscriptionItem.upTo);
      return [annualPlan];
    },
    getMonthlyPlan: function (upTo: number): {
      cycle: string;
      upTo: number;
      amount: number;
      currency: string;
      stripeId: string;
    } {
      let result = {} as {
        cycle: string;
        upTo: number;
        amount: number;
        currency: string;
        stripeId: string;
      };

      for (let i = 0; i <= this.plan.tiers.length; i++) {
        const tier = this.plan.tiers[i];
        if (tier) {
          if (tier.cycle === "MONTH" && tier.upTo === upTo) {
            result = tier;
            break;
          }
        }
      }
      return result;
    },
  },
  computed: {
    savingPercent: function (): string {
      const percentage =
        (this.totalMonthlyPlan * 12 - this.totalCurrentPlan) /
        (this.totalMonthlyPlan * 12);
      return (percentage * 100).toFixed(2);
    },
    totalCurrentPlan: function (): number {
      let total = 0;
      if (this.subscription.items) {
        this.subscription.items.forEach((item) => {
          total += item.amount;
        });
      }
      return total;
    },
    totalMonthlyPlan: function (): number {
      let total = 0;
      if (this.subscription.items) {
        this.subscription.items.forEach((item) => {
          total += this.getMonthlyPlan(item.upTo).amount;
        });
      }

      return total;
    },
    numberOfUsers: function (): number {
      return this.subscriptionItem.upTo - this.subscriptionItem.remainingSeats;
    },
  },
  watch: {
    subscription: function () {
      this.fetchPlanDetails();
    },
    newTier: function (newValue) {
      // if newValue is undefined then we are on the high tier
      // so we don't need to set newTotal
      if (newValue) {
        this.newTotal = newValue.amount;
      }
    },
    showModal: function (newValue: boolean) {
      if (newValue) {
        this.fetchPlanDetails();
        $("#changeToMonthlyPlanModal").modal("show");
      } else {
        $("#changeToMonthlyPlanModal").modal("hide");
      }
    },
  },
});
