

















































































































































































import Vue from "vue";
import { IConsolidatedPriceTier } from "../types";
export default Vue.extend({
  components: {},
  props: {
    consolidatedTiers: {
      type: Array,
    },
  },
  data: function () {
    return {
      showTierInputs: false,
      localConsolidatedTiers: this
        .consolidatedTiers as IConsolidatedPriceTier[],
      newTier: {
        upTo: NaN,
        monthlyAmount: NaN,
        yearlyAmount: NaN,
        inUse: false,
      } as IConsolidatedPriceTier,
      selectedTierIndex: -1, // if -1, no tier is selected
    };
  },
  methods: {
    resetNewTier() {
      this.newTier = {
        upTo: NaN,
        monthlyAmount: NaN,
        yearlyAmount: NaN,
      } as IConsolidatedPriceTier;
    },
    /**
     * toggle the panel for adding new tiers
     */
    toggleAddTierPanel() {
      // if we are disabling the panel with only the unlimited tier existing, delete unlimited tier
      if (this.showTierInputs && this.localConsolidatedTiers.length === 1) {
        this.deleteTier(0);
      }
      // if a tier is selected for editing, we need to unselect
      if (this.selectedTierIndex !== -1) {
        this.selectedTierIndex = -1;
      }
      this.showTierInputs = !this.showTierInputs;
      this.resetNewTier();
    },
    /**
     * adds new tier to the plan prices
     */
    addTier() {
      // add tier to list
      this.localConsolidatedTiers.push(this.newTier);
      this.toggleAddTierPanel();
    },
    /**
     * creates an "unlimited tier" which is required for pricing plans in stripe -
     * the user should not be able to see or edit this pricing plan
     */
    createUnlimitedTier() {
      const defaultConsolidatedTier = {
        upTo: null,
        monthlyAmount: 999999,
        yearlyAmount: 999999,
      } as unknown as IConsolidatedPriceTier;
      this.localConsolidatedTiers.push(defaultConsolidatedTier);
      // this function is triggered by an "add" button, so we want the add tier panel to show as well
      this.toggleAddTierPanel();
    },
    /**
     * deletes a price tier by index from the corresponding price tier list
     * @param {number} index - the index of the tier that is being deleted
     */
    deleteTier(index: number) {
      // if there is only one visible tier remaining, we remove the hidden "unlimited" tier as well
      if (this.localConsolidatedTiers.length === 2) {
        this.localConsolidatedTiers.splice(index, 2);
      } else {
        this.localConsolidatedTiers.splice(index, 1);
      }
    },
    toggleEditTierPanel(index: number) {
      // if add new tier panel is open, close it
      if (this.showTierInputs) {
        this.toggleAddTierPanel();
      }
      // store selected tier index
      this.selectedTierIndex = index;
      // now we can use the newTier object to replace the existing one
      this.newTier = {
        upTo: this.localConsolidatedTiers[index].upTo,
        monthlyAmount: this.localConsolidatedTiers[index].monthlyAmount,
        yearlyAmount: this.localConsolidatedTiers[index].yearlyAmount,
        inUse: this.localConsolidatedTiers[index].inUse,
      };
    },
    applyTierChanges(index: number) {
      // apply changes by
      this.localConsolidatedTiers[index] = this.newTier;
      this.sortConsolidatedTiers();
      this.selectedTierIndex = -1;
      this.resetNewTier();
    },
    discardTierChanges() {
      this.selectedTierIndex = -1;
      this.resetNewTier();
    },
    sortConsolidatedTiers() {
      this.localConsolidatedTiers.sort((a, b) => {
        if (a.upTo === null) {
          return 1;
        } else if (b.upTo === null) {
          return -1;
        }
        return Number(a.upTo) - Number(b.upTo);
      });
    },
  },
  computed: {
    newTierIsValid(): boolean {
      if (
        Number(this.newTier.upTo) > 0 &&
        Number.isInteger(Number(this.newTier.upTo)) &&
        this.newTier.monthlyAmount >= 0 &&
        this.newTier.yearlyAmount >= 0
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    consolidatedTiers(newValue) {
      this.localConsolidatedTiers = newValue;
    },
    "localConsolidatedTiers.length"(newValue, oldValue) {
      if (newValue > oldValue) {
        // if a tier is added, sort tiers
        this.sortConsolidatedTiers();
      }
    },
  },
});
