<template>
  <full-page-layout title="SignAgent" style="position: relative">
    <div
      v-if="$root.$store.state.signDesigner.showLoadingSpinner"
      class="spinner-backdrop"
    >
      <div class="sk-spinner sk-spinner-pulse"></div>
    </div>

    <div class="row">
      <div
        style="
          display: flex;
          background-color: #fff;
          height: 100%;
          min-height: calc(100vh - 50px);
        "
      >
        <div
          class="sidebar open"
          style="--bg-color: #4a4949; --menu-items-text-color: #fff"
        >
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              flex-grow: 1;
            "
          >
            <div id="my-scroll" style="margin: 6px 14px 0px">
              <ul class="nav-list" style="overflow: visible; padding-left: 0px">
                <li>
                  <router-link :to="'/builder/orders/'">
                    <span class="links_name">Orders</span>
                  </router-link>
                </li>
                <li v-if="$store.state.session.isBuilder">
                  <router-link :to="'/builder/buildprojects/'">
                    <span class="links_name">Build Projects</span>
                  </router-link>
                </li>
                <li v-if="$store.state.session.isInstaller">
                  <router-link :to="'/builder/installprojects/'">
                    <span class="links_name">Install Projects</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="container-full">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </full-page-layout>
</template>

<script>
import Vue from "vue";

import FullPageLayout from "@/components/layouts/FullPageLayout.vue";

export default Vue.extend({
  components: { FullPageLayout },
  data: function () {
    return {
      height: 800,
      width: 700,
      userHasPermission: false,
    };
  },
  mounted: function () {
    window.addEventListener("resize", this.fitStageIntoParentContainer);
    this.fitStageIntoParentContainer();
  },
  methods: {
    fitStageIntoParentContainer: function () {
      this.$store.commit("screenResized");
      this.$store.commit("setClientHeight", window.innerHeight - 55);
      this.width = window.innerWidth - 320;
    },
  },
});
</script>

<style scoped>
.btn-outline-primary:focus {
  border-color: transparent;
  outline: none;
}

.sidebar.open {
  width: 198px;
}

.sidebar {
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: -webkit-min-content;
  min-height: -moz-min-content;
  min-height: min-content;
  width: 78px;
  background: var(--bg-color);
  z-index: 99;
  transition: all 0.5s ease;
}

#my-scroll {
  height: calc(100% - 60px);
}
#my-scroll,
.my-scroll-active {
  overflow-y: auto;
}

.sidebar .nav-list {
  margin-top: 20px;
}

.sidebar li {
  position: relative;
  margin: 8px 0;
  list-style: none;
}

.sidebar li a {
  display: flex;
  height: 100%;
  width: 100%;
  border-radius: 12px;
  align-items: center;
  text-decoration: none;
  transition: all 0.4s ease;
  background: var(--bg-color);
}

.sidebar.open li a .links_name {
  opacity: 1;
  pointer-events: auto;
}
.sidebar li a .links_name {
  color: var(--menu-items-text-color);
  /* font-size: 15px; */
  font-weight: 400;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s;
}

.sidebar li i {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  border-radius: 12px;
}
.sidebar i {
  color: var(--icons-color);
  height: 60px;
  min-width: 50px;
  font-size: 28px;
  text-align: center;
  line-height: 60px;
}

.container-full {
  margin: 0 auto;
  margin-right: 15px;
  margin-left: 200px;
  width: 100%;
}

/* saving spinner */
.spinner-backdrop {
  z-index: 4000;
  position: absolute;
  background: #fffb;
  top: 3px;
  bottom: 3px;
  left: 3px;
  right: 3px;
  padding: 7em;
}
</style>
