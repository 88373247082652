



































































































import Vue from "vue";

import { IProductType } from "../../types";
import { uuidv4 } from "../../utilities";

export default Vue.extend({
  components: {},
  data: function () {
    return {
      showModal: false,
      selectedProductTypeIndex: -1,
      editMode: "edit",
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showProductTypesModal;
  },
  methods: {
    /**
     * add a product type
     * called when the user clicks the Add product type button
     */
    addProductType: function () {
      const productType = {
        id: "",
        uuid: uuidv4(),
        name: "New product type",
      } as IProductType;
      this.$store.commit("addProductType", productType);
      this.editMode = "add";
      this.selectedProductTypeIndex =
        this.$store.getters.productTypes.length - 1;
    },
    /**
     * delete the selected product type
     * called when the trash icon is clicked
     */
    deleteProductType(index: number) {
      this.$store.commit("deleteProductTypeByIndex", index);
    },
    /**
     * handle the Ok button clicked event
     */
    saveChanges: function () {
      //if we are on the details screen
      if (this.selectedProductTypeIndex >= 0) {
        // write to the back end
        if (this.editMode === "add") {
          fetch("/graphql/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              query: `
                mutation addPartType{
                  addSignTypePartType(addSignTypePartTypeInput: {name: "${
                    this.$store.getters.productTypes[
                      this.selectedProductTypeIndex
                    ].name
                  }", organizationId: ${this.$store.getters.organizationId}}) {
                    partType {
                      id: contentObjectId
                      uuid
                      name
                    }
                  }
                }
              `,
            }),
          }).then((result) => result.json());
        } else {
          fetch("/graphql/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              query: `
                mutation updatePartType {
                  updateSignTypePartType(updateSignTypePartTypeInput: {id:"${
                    this.$store.getters.productTypes[
                      this.selectedProductTypeIndex
                    ].id
                  }", name:"${
                this.$store.getters.productTypes[this.selectedProductTypeIndex]
                  .name
              }"}) {
                    partType {
                      name
                    }
                  }
                }
              `,
            }),
          }).then((result) => result.json());
        }

        this.editMode = "edit";

        //move back to list screen, don't close modal
        this.selectedProductTypeIndex = -1;
      } else {
        //otherwise we are on the list screen, close modal
        this.$store.commit("setShowProductTypesModal", false);
      }
    },
    /**
     * close the entire modal
     */
    closeModal: function () {
      this.selectedProductTypeIndex = -1;
      this.$store.commit("setShowProductTypesModal", false);
    },
    /**
     * close a page
     */
    closePage: function () {
      //if we are on the details screen
      if (this.selectedProductTypeIndex >= 0) {
        //move back to list screen, don't close modal
        this.selectedProductTypeIndex = -1;
      } else {
        //otherwise we are on the list screen, close modal
        this.$store.commit("setShowProductTypesModal", false);
      }
    },
    /**
     * set the selected product type index
     * @param {number} productTypeIndex - the array index of the product type to edit
     */
    editProductType: function (productTypeIndex: number) {
      this.selectedProductTypeIndex = productTypeIndex;
    },
  },
  computed: {
    /**
     * getter/setter for name
     */
    name: {
      get(): string {
        return this.$store.getters.productTypes[this.selectedProductTypeIndex]
          .name;
      },
      set(value: string) {
        this.$store.commit("updateProductTypeNameByIndex", {
          value: value,
          index: this.selectedProductTypeIndex,
        });
      },
    },
    /**
     * getter/setter for image URL
     */
    imageURL: {
      get(): string {
        return this.$store.getters.productTypes[this.selectedProductTypeIndex]
          .imageURL;
      },
      set(value: string) {
        this.$store.commit("updateProductTypeImageURLByIndex", {
          value: value,
          index: this.selectedProductTypeIndex,
        });
      },
    },
  },
  watch: {
    /**
     * watch showProductTypesModal from the store
     * @param {boolean} newValue - the value of showProductTypesModal from the store
     */
    "$store.state.signDesigner.showProductTypesModal": function (newValue) {
      //   const this = this;
      this.showModal = newValue;
    },
  },
});
