


























import Vue from "vue";

export default Vue.extend({
  methods: {},
  computed: {
    numberOfSides: function (): number {
      return this.$store.getters.signData.numberOfSides;
    },
  },
});
