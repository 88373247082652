










































































import { iSignChild } from "../../types";
import Vue from "vue";
import SignTypesList from "../SignTypesList.vue";

// import CollapsibleSection from "../CollapsibleSection.vue";
// import PartSection from "../panelsections/PartSection.vue";
import FieldSection from "../panelsections/FieldSection.vue";
// import ProcessSection from "../panelsections/ProcessSection.vue";
import FileSection from "../panelsections/FileSection.vue";
//import ImageSection from "../panelsections/ImageSection.vue";
import FontSection from "../panelsections/FontSection.vue";

export default Vue.extend({
  name: "connections-panel",
  components: {
    // CollapsibleSection,
    // PartSection,
    FieldSection,
    // ProcessSection,
    FileSection,
    FontSection,
    SignTypesList,
  },
  props: {
    signData: {
      type: Object,
      required: true,
    },
    // componentData: {
    //   type: Array,
    //   required: true,
    // },
  },
  data: function () {
    return {
      // signData: {},
    };
  },
  methods: {
    /**
     * pass the partSelected event up the tree
     */
    onPartSelected() {
      this.$emit("partSelected");
    },
    /**
     * pass the processSelected event up the tree
     */
    onProcessSelected(process: iSignChild) {
      this.$emit("processSelected", process);
    },
    /**
     * Switch the active panel by setting the current sign type uuid to blank
     * if the currentSignTypeUUID is blank then the signTypesList component is displayed
     */
    switchActivePanel: function () {
      //save the current sign type uuid so we know if the user has selected the same sign in the sign types list
      this.$store.commit(
        "setPreviousSignTypeUUID",
        this.$store.getters.currentSignTypeUUID
      );
      this.$store.commit("setCurrentSignTypeUUID", "");
    },
  },
  computed: {},
  watch: {},
});
