import "@/set-public-path"; // must be first

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import $ from "jquery";
import toast from "react-hot-toast";
import Vue from "vue";

import mountAsModal from "@/mounters/mountAsModal";
import mountFullPageRoutes from "@/mounters/mountFullPageRoutes";
import mountFynoBell from "@/mounters/mountFynoBell";
import mountMapViewRoutes, {
  detachMapView,
} from "@/mounters/mountMapViewRoutes";

import PermissionsUI from "@/features/PermissionsUI/PermissionsUI.vue";
import WelcomeModal from "@/features/WelcomeModal.vue";
import SignDesigner from "@/features/SignDesigner/SignDesigner.vue";

// Import Bootstrap an BootstrapVue CSS files (order is important)
//import "bootstrap/dist/css/bootstrap.css";
//import "bootstrap-vue/dist/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// Prevent the Vue production tip on startup
Vue.config.productionTip = false;

// Add the following keys to the global namespace for Django-side JavaScript to access
Object.assign(globalThis, {
  // allows Travis's sidebar to use Vue
  Vue,

  // share jQuery between Django and Vue
  $,
  jQuery: $,

  // expose toast API to Django
  toast,

  // interface to our own Vue code
  sa_vue: {
    mountFullPageRoutes,
    mountFynoBell,
    mountMapViewRoutes,
    detachMapView,

    // Modals
    showPermissionsUI: mountAsModal(PermissionsUI),
    showWelcomeModal: mountAsModal(WelcomeModal),
    startSignDesigner: mountAsModal(SignDesigner),
  },
});
