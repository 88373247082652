



























import { iSignChild } from "../types";
import Vue from "vue";
import LeftPanelComponents from "./LeftPanelComponent.vue";
import TemplateButtonBar from "./TemplateButtonBar.vue";

export default Vue.extend({
  components: { LeftPanelComponents, TemplateButtonBar },
  props: {
    // componentData: Object,
    treeData: Object,
    signData: Object,
    panelHeight: Number,
  },
  data: function () {
    return {
      current_panel: "signs" as string,
    };
  },
  methods: {
    /**
     * handle the process selected event
     */
    onProcessSelected(process: iSignChild) {
      this.$emit("processSelected", process);
    },
    /**
     * process the select component event
     */
    selectComponent: function (event: Event) {
      this.$emit("componentSelected", event);
    },
    /**
     * process the select element event
     */
    selectElement: function (event: Event) {
      this.$emit("elementSelected", event);
    },
    /**
     * prcess the element visibility toggled event
     */
    elementVisibilityToggled: function (item: iSignChild) {
      this.$emit("elementVisibilityToggled", item);
    },
  },
});
