import { FynoInappCenter } from "@fyno/inapp-react";
import { createElement } from "react";
import { createRoot } from "react-dom/client";

export interface FynoBellConfig {
  FYNO_WSID: string;
  FYNO_INAPP_ID: string;
  fyno_distinct_user_id: string;
  fyno_bell_signature: string;
}

/**
 * Mount the Fyno bell on the given DOM element
 *
 * @param element - the element to mount the Fyno bell to
 * @param config - the variable parameters required by the Fyno bell
 */
export default function mountFynoBell(
  element: Element,
  config: FynoBellConfig
): void {
  const primaryColor =
    getComputedStyle(element).getPropertyValue("--primary-color") || "#198cff";

  createRoot(element).render(
    createElement(FynoInappCenter, {
      workspace: config.FYNO_WSID,
      integration: config.FYNO_INAPP_ID,
      user: config.fyno_distinct_user_id,
      signature: config.fyno_bell_signature,

      mode: "light", // 'light' or 'dark'
      themeConfig: {
        logo: require("./assets/SA_logo.png"),
        primary: primaryColor,
        // secondary: secondaryColor, AAA6BF
        lightBackground: "#ffffff",
        darkBackground: "#000000",
        // font: <CUSTOM_FONT_FAMILY>
        // lightColor, 58, 53, 65
        // darkColor, 231, 227, 252
        // header: null, // optional string: If specified, a header will be shown
        preference_mode: "embed",
      },
      notificationSettings: {
        // sound: "", // URL
        invertTheme: false, // Set to true for inverted theme
        // duration:,
      },
      // overrideInappUrl,
      onMessageReceived: () => {
        // Handle incoming notification data
      },
      onMessageClicked: () => {
        // Handle message clicked
      },
    })
  );
}
