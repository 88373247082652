






























import { Component, Prop, Vue } from "vue-property-decorator";

import ModalLayout from "@/components/layouts/ModalLayout.vue";

@Component({
  components: {
    ModalLayout,
  },
})
export default class WelcomeModal extends Vue {
  /**
   * The desired window title string, will have ` - SignAgent` appended
   */
  @Prop({
    type: String,
    required: true,
  })
  readonly firstName!: string;
}
