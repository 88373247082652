/*

Fonts - This class manages the fonts as they are loaded. Note this is being implemented as a singleton since the Fonts
 */
import opentype from "opentype.js";
import { ClassDefinition } from "./ClassDefinition";
import { SvgCss } from "./SvgCss";
import { IFonts, OpenTypeFonts } from "@/features/SignDesigner/types";
import { getChildElementOfType, elementCssName } from "./Utils";

// interface FontDataArray {
//   [fontFamily: string]: ArrayBuffer;
// }

interface CssToFonts {
  [cssName: string]: opentype.Font;
}
interface FontFamilyToURLs {
  [fontFamilyName: string]: string;
}

// interface FontFamilyURL {
//   fontFamily: string;
//   url: string;
// }

export class SAFonts {
  public fonts: OpenTypeFonts;
  private cssToFonts: CssToFonts;
  private fontFamilyToURLs: FontFamilyToURLs;
  public fontsNotLoaded: string[];

  private static instance: SAFonts;

  private constructor() {
    this.fonts = {};
    this.cssToFonts = {};
    this.fontFamilyToURLs = {};
    this.fontsNotLoaded = [];
  }

  // ensure that a single instance is created
  public static getInstance(): SAFonts {
    if (!SAFonts.instance) {
      SAFonts.instance = new SAFonts();
    }
    return SAFonts.instance;
  }

  private getFontURLS(iFonts: IFonts): string[] {
    const svgCss = SvgCss.getInstance();
    const urls: string[] = [];

    this.fontFamilyToURLs = {};

    if (svgCss) {
      const cssFonts: ClassDefinition[] | undefined =
        svgCss.findClassDefinitionsThatHave("font-family");

      for (let i = 0; i < cssFonts.length; i++) {
        const fontFamily = cssFonts[i].valueAt("font-family");
        if (fontFamily) {
          const names: string[] = fontFamily.split(",");

          // All the cssNames that have the fontFamily;
          console.debug(`looking for font ${fontFamily}`);
          // find the font in list of all fonts in the organization

          if (iFonts[names[0]]) {
            const url = iFonts[names[0]].url;
            if (this.fontFamilyToURLs[names[0]] === undefined) {
              this.fontFamilyToURLs[names[0]] = url;
              urls.push(url);
            }
          } else {
            this.fontsNotLoaded.push(names[0]);
          }
        }
      }
    }

    if (urls.length < 1) {
      console.warn(`there are no fontfamiles definined`);
    }
    return urls;
  }

  public removeFontNotLoaded(fontName: string): void {
    const fontIndex = this.fontsNotLoaded.indexOf(fontName);
    this.fontsNotLoaded.splice(fontIndex, 1);
  }

  /**
   * getFontNameFromURL
   *
   * @param iFonts {IFonts} - all the fonts that are defined on this sign template
   * @param url {string} - the string we are looking for
   * @returns {string} - the name of the font
   */
  private getFontNameFromURL(iFonts: IFonts, url: string): string {
    for (const key in iFonts) {
      if (iFonts[key].url === url) {
        return iFonts[key].name;
      }
    }
    return ""; // Return empty string if the value is not found
  }

  public async loadFonts(iFonts: IFonts): Promise<boolean> {
    let errStr = "";
    let ok = true;
    const urls = this.getFontURLS(iFonts);

    // load all the fonts in parallel so the time to load all fonts will be the time that it takes the largest font.
    const results = await Promise.allSettled(
      urls.map((url) =>
        fetch(url)
          .then((response) => response.arrayBuffer())
          .then((buffer) => ({ url: url, buffer: buffer }))
      )
    );

    this.fonts = {};
    for (let i = 0; i < results.length; i++) {
      const result = results[i];
      switch (result.status) {
        case "fulfilled":
          {
            const font = opentype.parse(result.value.buffer);
            const name = this.getFontNameFromURL(iFonts, result.value.url);
            this.fonts[name] = font;
          }
          break;
        case "rejected":
          if (errStr !== "") {
            errStr += "\n";
          }
          errStr += result.reason;
          ok = false;
      }
    }
    if (ok) {
      this.mapCssToFonts();
      return Promise.resolve(true);
    }
    return Promise.reject(new Error(errStr));
  }

  /**
   * Populate the cssToFonts array
   *
   */
  private mapCssToFonts() {
    const svgCss = SvgCss.getInstance();

    svgCss
      ?.findClassDefinitionsThatHave("font-family")
      .forEach((classDef: ClassDefinition) => {
        classDef.classNamesArray().forEach((className: string) => {
          const fontFamily = classDef.valueAt("font-family");
          if (fontFamily) {
            const names: string[] = fontFamily.split(",");
            this.cssToFonts[className.substring(1)] = this.fonts[names[0]];
          }
        });
      });
  }

  /**
   * Return the fontData for the specified CssId.
   *
   * @param cssId
   */

  public FontDataByCssId(cssId: string): opentype.Font | undefined {
    return this.cssToFonts[cssId];
  }

  /**
   * Return Fontdata for the specified fontFamily.
   *
   * @param fontFamily
   */
  public FontDataByFontFamily(fontFamily: string): opentype.Font | undefined {
    return this.fonts[fontFamily];
  }

  /**
   * Return the FontData for the specified element. Note if the element is <g> element find the child Element that is of type "text"
   * @param element
   */

  public FontDataByElement(element: Snap.Element): opentype.Font | undefined {
    let targetElement: Snap.Element | null = element;
    let fontData = undefined;
    if (targetElement.type === "g") {
      targetElement = getChildElementOfType(element, "text");
    }

    if (targetElement) {
      const cssId = elementCssName(targetElement);
      fontData = this.FontDataByCssId(cssId);
    }
    return fontData;
  }

  public styleElementFontList(): string {
    const fonts: string[] = [];
    const fontFamilyNames = Object.keys(this.fonts);

    if (fontFamilyNames.length > 0) {
      for (let i = 0; i < fontFamilyNames.length; i++) {
        const fontFamilyName = fontFamilyNames[i];
        const font: opentype.Font = this.fonts[fontFamilyName];
        const url: string = this.fontFamilyToURLs[fontFamilyName];
        let format: string = font.outlinesFormat.toLowerCase();
        format = format === "opentype" ? format : "truetype";
        fonts.push(
          `@font-face { font-family: '${fontFamilyName}'; src: url('${url}') format('${format}'); }\n`
        );
      }
      return `<style>\n` + fonts.join("\n") + "</style>\n";
    } else {
      return "";
    }
  }
}
