










































































import Vue from "vue";
import MeasurementInput from "../MeasurementInput.vue";

export default Vue.extend({
  components: { MeasurementInput },
  computed: {
    /**
     * getter/setter for measurementUnit
     */
    measurementUnit: {
      get(): string {
        return this.$store.getters.signData.measurementUnit.unit;
      },
      set(value: string) {
        this.$store.commit("setSignMeasurementUnit", value);
      },
    },
    /**
     * getter/setter for shortCode
     */
    shortCode: {
      get() {
        return this.$store.getters.signData.shortCode;
      },
      set(value) {
        this.$store.commit("setSignDataShortCode", value);
      },
    },
    /**
     * getter/setter for shortCode
     */
    signName: {
      get() {
        return this.$store.getters.signData.name;
      },
      set(value) {
        this.$store.commit("setSignDataName", value);
      },
    },
  },
});
