




























































































import Vue from "vue";
import Snap from "snapsvg-cjs-ts";

import {
  iCatalog,
  ITemplate,
  IModulexProduct,
  IPart,
  IProductCategory,
  IProductFamily,
  IProductType,
} from "../types";
import { uuidv4 } from "../utilities";
import SignTypesPage from "../components/pages/marketplace/SignTypesPage.vue";
import FamilyPage from "../components/pages/marketplace/FamilyPage.vue";
import MarketplacePanel from "../components/panels/MarketplacePanel.vue";
import ProductModal from "./modals/ProductModal.vue";

export default Vue.extend({
  components: {
    SignTypesPage,
    FamilyPage,
    MarketplacePanel,
    ProductModal,
  },
  data: function () {
    return {
      page: "signtypes",
      snap: {} as Snap.Element,
      searchText: "",
      showDetails: false,
      selectedId: "",
      catalog: [] as Array<iCatalog>,
      templates: [] as Array<ITemplate>,
      parts: [] as Array<IPart>,
      family: {} as IProductFamily,
      panelHeight: 0,
    };
  },
  mounted: function () {
    this.fitModalIntoParentContainer();

    window.addEventListener("resize", this.fitModalIntoParentContainer);
    let categories = [] as Array<IProductCategory>;
    let families = [] as Array<IProductFamily>;
    let types = [] as Array<IProductType>;

    let maxCount = 50;

    fetch(
      "https://wotbackend.modulex.com/api/v1/Parameters/GetBasedataProducts?ApiKey=4CA681E9-E58F-45D2-BEAC-07CCFE08A1D1"
    )
      .then(function (response) {
        return response.json();
      })
      .then((data) => {
        let currentRow = 1;
        data.Data.Products.forEach((product: IModulexProduct) => {
          if (
            currentRow < maxCount &&
            ["626386", "626384", "00248620"].includes(product.ProductNo)
          ) {
            let catalogItem = {
              id: product.ProductNo,
              uuid: uuidv4(),
              isSelected: false,
              templateId: product.PK_ProductID.toString(),
              name: product.ProductName1_UK + " " + product.ProductName2_UK,
              productNo: product.ProductNo,
              price: 0,
              family: product.ProductFamilyName,
              category: product.ProductCategoryName,
              type: product.ProductTypeName,
              manufacturer: "Modulex",
              sides: 1,
              svg: "",
              thumbnail:
                "https://wotbackend.modulex.com/api/v1/Parameters/GetProductThumbnail/" +
                product.ProductNo +
                "?ApiKey=4CA681E9-E58F-45D2-BEAC-07CCFE08A1D1",
              partIds: [],
            } as iCatalog;
            this.catalog.push(catalogItem);

            // load all the categories
            let found = false;
            let categoryId = uuidv4();
            let familyId = uuidv4();

            for (let i = 0; i < families.length; i++) {
              if (families[i].name === product.ProductFamilyName) {
                found = true;
                familyId = families[i].uuid;
                break;
              }
            }

            if (!found) {
              families.push({
                id: "",
                uuid: familyId,
                name: product.ProductFamilyName,
                description:
                  "Infinity Basic is a system in square form, making it possible to match static and Paperflex signs horizontally and vertically. But who says that signs always have to be rectangular? With Infinity Blazon and Ellipsis, different shapes are available. You also have the opportunity to create your customized dimensions and shapes. There are unlimited possibilities, and with the use of graphic features, you can create a 100 % personal sign supporting your brand’s design requirements.",
                imageURL:
                  "https://modulex.com/wp-content/uploads/2021/01/Infinity-415x500.png",
                isSelected: false,
              });
            }

            found = false;
            for (let i = 0; i < categories.length; i++) {
              if (categories[i].name === product.ProductCategoryName) {
                found = true;
                categoryId = categories[i].uuid;

                if (!categories[i].familyIds.includes(familyId)) {
                  categories[i].familyIds.push(familyId);
                  this.$store.commit("updateProductCategory", categories[i]);
                }
                break;
              }
            }

            if (!found) {
              if (
                product.ProductCategoryName.toLowerCase() === "interior signs"
              ) {
                categories.push({
                  id: "",
                  uuid: categoryId,
                  name: product.ProductCategoryName,
                  imageURL:
                    "https://modulex.com/wp-content/uploads/2020/11/modular-interior-product-1080x1080.jpg",
                  familyIds: [familyId],
                  isSelected: false,
                });
              } else {
                categories.push({
                  id: "",
                  uuid: categoryId,
                  name: product.ProductCategoryName,
                  imageURL:
                    "https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg",
                  familyIds: [familyId],
                  isSelected: false,
                });
              }
            }

            found = false;
            for (let i = 0; i < types.length; i++) {
              if (types[i].name === product.ProductTypeName) {
                found = true;
                break;
              }
            }

            if (!found) {
              if (product.ProductTypeName.toLowerCase() === "panels") {
                types.push({
                  id: "",
                  uuid: uuidv4(),
                  name: product.ProductTypeName,
                  imageURL:
                    "https://modulex.com/wp-content/uploads/2020/05/Infinity18.png",
                  categoryIds: [categoryId],
                  isSelected: false,
                });
              } else if (
                product.ProductTypeName.toLowerCase() === "table signs"
              ) {
                types.push({
                  id: "",
                  uuid: uuidv4(),
                  name: product.ProductTypeName,
                  imageURL:
                    "https://modulex.com/wp-content/uploads/2020/05/Infinity6.png",
                  categoryIds: [categoryId],
                  isSelected: false,
                });
              } else if (
                product.ProductTypeName.toLowerCase() === "wall signs"
              ) {
                types.push({
                  id: "",
                  uuid: uuidv4(),
                  name: product.ProductTypeName,
                  imageURL:
                    "https://modulex.com/wp-content/uploads/2020/05/Infinity20.png",
                  categoryIds: [categoryId],
                  isSelected: false,
                });
              } else if (
                product.ProductTypeName.toLowerCase() === "door signs"
              ) {
                types.push({
                  id: "",
                  uuid: uuidv4(),
                  name: product.ProductTypeName,
                  imageURL:
                    "https://modulex.com/wp-content/uploads/2020/05/Infinity22.png",
                  categoryIds: [categoryId],
                  isSelected: false,
                });
              } else if (
                product.ProductTypeName.toLowerCase() === "accessories"
              ) {
                types.push({
                  id: "",
                  uuid: uuidv4(),
                  name: product.ProductTypeName,
                  imageURL:
                    "https://modulex.com/wp-content/uploads/2020/05/Infinity8.png",
                  categoryIds: [categoryId],
                  isSelected: false,
                });
              } else if (
                product.ProductTypeName.toLowerCase() === "paperflex"
              ) {
                types.push({
                  id: "",
                  uuid: uuidv4(),
                  name: product.ProductTypeName,
                  imageURL:
                    "https://modulex.com/wp-content/uploads/2020/05/Infinity17.png",
                  categoryIds: [categoryId],
                  isSelected: false,
                });
              } else {
                types.push({
                  id: "",
                  uuid: uuidv4(),
                  name: product.ProductTypeName,
                  imageURL:
                    "https://upload.wikimedia.org/wikipedia/commons/6/65/No-Image-Placeholder.svg",
                  categoryIds: [categoryId],
                  isSelected: false,
                });
              }
            }
            currentRow++;
          }
        });

        // manually add a family for Breeze. Take this away once we have this data in the API
        // let breezeId = uuidv4();
        // families.push({
        //   id: "",
        //   uuid: breezeId,
        //   name: "Breeze",
        //   description:
        //     "Breeze is available in a curved and flat design offering static and paperflex signs with and without a header. Inspired by the art of paper folding, the lightweight aluminum casing is the same for static and for paperflex signs making it possible to change from permanent to paperflex and vice versa. The Breeze range is available in landscape and portrait sizes from A6 to A3 in both a static and a paperflex version. The curved Breeze is also available in A2 portrait and landscape, and the flat Breeze in A2 portrait.",
        //   imageURL:
        //     "https://modulex.com/wp-content/uploads/2020/05/Breeze1.png",
        //   isSelected: false,
        // });

        // // add a category for exterior signs. Remove once the API includes this
        // categories.push({
        //   id: "",
        //   uuid: uuidv4(),
        //   name: "Exterior Signs",
        //   imageURL:
        //     "https://modulex.com/wp-content/uploads/2020/11/modular-exterior-product-1080x1080.jpg",
        //   familyIds: [breezeId],
        //   isSelected: false,
        // });

        // get all image thumbnails
        this.$store.commit("setProductCategories", categories);
        this.$store.commit("setProductFamilies", families);
        this.$store.commit("setProductTypes", types);
        this.$store.commit("setCatalog", this.catalog);
        this.$store.getters.catalog.forEach((item: iCatalog) => {
          let localItem = JSON.parse(JSON.stringify(item));
          localItem.thumbnail = this.getResponse(
            localItem,
            localItem.thumbnail
          );
          this.$store.commit("updateCatalogByUUID", localItem);
        });
      });
  },
  methods: {
    fitModalIntoParentContainer: function () {
      this.panelHeight = window.innerHeight - 250;
    },
    familyClicked: function (family: IProductFamily) {
      this.$store.commit("selectProductFamilyById", family);
      this.page = "family";
    },
    /**
     * return a portion of an array
     * @param {Array<iCatalog>} array - the full array
     * @param {number} size - the number of elements per "chunk"
     * @returns {Array<iCatalog>} an array of iCatalog that is "size" elements long
     */
    chunkArray: function (array: Array<iCatalog>, size: number) {
      let result = [];
      for (let i = 0; i < array.length; i += size) {
        let chunk = array.slice(i, i + size);
        result.push(chunk);
      }
      return result;
    },
    /**
     * show the details for a particular item
     * @param {number} id - the id of the item to show
     */
    showDetailsForItem(item: iCatalog) {
      this.$store.commit("selectProductByUUID", item);
      this.$nextTick(function () {
        this.$store.commit("setShowProductModal", true);
      });
    },
    getResponse: function (item: iCatalog, url: string): string {
      let resultData = "empty";

      const base64 = this.getImageBase64(url);
      base64.then((result) => {
        item.thumbnail = result;
      });
      return resultData;
      //   const result = this.getImageBase64(url);

      //   result.then((response) => {
      //     return response.Data;
      //   });
    },
    getImageBase64: async function (url: string): Promise<string> {
      // const response = await fetch(url);
      // const blob = await response.blob();
      // return new Promise((onSuccess, onError) => {
      //   try {
      //     const reader = new FileReader();
      //     reader.onload = function () {
      //       onSuccess(this.result);
      //     };
      //     reader.readAsDataURL(blob);
      //   } catch (e) {
      //     onError(e);
      //   }
      // });
      let dataString = "";
      await fetch(url)
        .then((response) => {
          return response.json();
        })
        .then(
          (data: {
            Message: string;
            Status: string;
            StatusCode: number;
            Data: string;
          }) => {
            dataString = data.Data;
          }
        )
        .catch((e) => {
          console.log("error: " + e);
        });
      return dataString;
    },
    addToConnections: function () {
      // this needs to be written
    },
  },
  computed: {
    nothingSelected: function (): boolean {
      if (
        this.$store.getters.isProductFamilySelected ||
        this.$store.getters.isProductCategorySelected ||
        this.$store.getters.isProductTypeSelected
      ) {
        return false;
      }
      return true;
    },
    filteredCatalog: function (): Array<iCatalog> {
      let result = [] as Array<iCatalog>;

      result = this.catalog.filter((item: iCatalog) => {
        if (this.searchText !== "") {
          if (item.id.startsWith(this.searchText)) {
            return true;
          }
        } else {
          return true;
        }
      });

      return result;
    },
    /**
     * return the details for the selected item or an empty object if selected item not found
     */
    itemDetails(): iCatalog {
      let selectedItem = {} as iCatalog;

      for (let i = 0; i < this.catalog.length; i++) {
        if (this.catalog[i].id === this.selectedId) {
          selectedItem = this.catalog[i];
        }
      }
      return selectedItem;
    },
    itemCount: function (): number {
      return this.$store.getters.catalog.length;
    },
  },
});
