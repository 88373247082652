







































import { featureAvailable } from "../utilities";
export default {
  computed: {
    marketplaceAvailable: function (): boolean {
      return featureAvailable(this, "marketplace");
    },
  },
};
