var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.order)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 bg-build",staticStyle:{"color":"#fff"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","padding-top":"10px","padding-bottom":"10px"}},[_c('div',{staticStyle:{"flex-grow":"1"}},[_c('label',{staticClass:"text-uppercase small"},[_c('strong',[_vm._v("Order Detail - "+_vm._s(_vm.order ? _vm.order.name : ""))])])]),_c('div',[(_vm.order && _vm.order.poNumber)?_c('label',{staticClass:"small"},[_c('strong',[_vm._v("PO Number - "+_vm._s(_vm.order ? _vm.order.poNumber : ""))])]):_vm._e()])])])]),_c('div',{staticClass:"row",staticStyle:{"border":"1px solid #e8e8e8"}},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6",staticStyle:{"padding-top":"5px"}},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('address',[_c('strong',[_vm._v(_vm._s(_vm.order.shippingContact))]),_c('br'),_vm._v(" "+_vm._s(_vm.order.shippingStreet1)),_c('br'),(
                    _vm.order.shippingStreet2 && _vm.order.shippingStreet2 !== 'null'
                  )?_c('span',[_vm._v(" "+_vm._s(_vm.order.shippingStreet2)),_c('br')]):_vm._e(),_vm._v(" "+_vm._s(_vm.order.shippingCity)+", "+_vm._s(_vm.order.shippingState)+" "+_vm._s(_vm.order.shippingPostalCode)),_c('br')])])])]),_c('div',{staticClass:"col-lg-6",staticStyle:{"padding-top":"5px"}},[_vm._m(1),_c('div',{staticClass:"row",staticStyle:{"text-align":"left"}},[_c('div',{staticClass:"col-lg-12"},[_c('address',[_c('strong',[_vm._v(_vm._s(_vm.order.billingContact))]),_c('br'),_vm._v(" "+_vm._s(_vm.order.billingStreet1)),_c('br'),(
                    _vm.order.billingStreet2 && _vm.order.billingStreet2 !== 'null'
                  )?_c('span',[_vm._v(" "+_vm._s(_vm.order.billingStreet2)),_c('br')]):_vm._e(),_vm._v(" "+_vm._s(_vm.order.billingCity)+", "+_vm._s(_vm.order.billingState)+" "+_vm._s(_vm.order.billingPostalCode)),_c('br')])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12",staticStyle:{"border-top":"1px solid #e8e8e8","border-bottom":"1px solid #e8e8e8","padding-top":"5px"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('label',{staticStyle:{"flex-grow":"1"}},[_c('i',{staticClass:"fa-light fa-file-invoice-dollar",staticStyle:{"margin-right":"5px"}}),_c('strong',{staticClass:"text-uppercase small"},[_vm._v(" Order Summary ("+_vm._s(_vm.order.signTypes ? _vm.order.signTypes.length : 0)+") ")])]),_c('i',{staticClass:"fa",class:_vm.expandOrderSummary ? 'fa-caret-down' : 'fa-caret-right',on:{"click":function($event){_vm.expandOrderSummary = !_vm.expandOrderSummary}}})])])]),(_vm.expandOrderSummary)?_c('div',{staticClass:"row",staticStyle:{"padding":"10px"}},[_c('table',{staticClass:"\n            table table-striped\n            order-details-table\n            table-vertical-middle\n          ",attrs:{"id":"orderDetailTable"}},[_c('thead',[_c('tr',[_c('th',[_vm._v("Product")]),_c('th',{staticStyle:{"text-align":"right","width":"100px"}},[_vm._v("Quantity")]),(_vm.$store.getters.order.includeInstall)?_c('th',{staticStyle:{"text-align":"right","width":"100px"}},[_vm._v(" Install ")]):_vm._e(),(_vm.$store.getters.order.IncludeBuild)?_c('th',{staticStyle:{"text-align":"right","width":"100px"}},[_vm._v(" Sign ")]):_vm._e(),_c('th',{staticStyle:{"text-align":"right","width":"100px"}},[_vm._v("Total")])])]),_c('tbody',[_vm._l((_vm.sortedSignTypes),function(orderItem){return [_c('tr',{key:orderItem.id},[_c('td',[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"flex-grow":"1"}},[_c('span',{staticStyle:{"padding-left":"3px","padding-right":"3px","margin-right":"5px","text-align":"center"},style:({
                          backgroundColor: '#' + orderItem.hexColor,
                          color: _vm.getTextColor(orderItem.hexColor),
                        })},[_vm._v(_vm._s(orderItem.shortCode.trim()))]),_vm._v(" "+_vm._s(orderItem.name)+" ")])])]),_c('td',{staticStyle:{"text-align":"right","width":"100px"}},[_vm._v(" "+_vm._s(_vm.calculateQuantity(orderItem))+" ")]),(_vm.$store.getters.order.includeInstall)?_c('td',{staticStyle:{"text-align":"right","width":"100px"}},[(_vm.displayPricing())?_c('span',[_vm._v(_vm._s(_vm.convertCurrency( Number(orderItem.install_price), _vm.$store.getters.order.currency )))]):_c('span',[_vm._v("-----")])]):_vm._e(),(_vm.$store.getters.order.IncludeBuild)?_c('td',{staticStyle:{"text-align":"right","width":"100px"}},[(_vm.displayPricing())?_c('span',[_vm._v(_vm._s(_vm.convertCurrency( Number(orderItem.actual_price), _vm.$store.getters.order.currency )))]):_c('span',[_vm._v("-----")])]):_vm._e(),_c('td',{staticStyle:{"text-align":"right","width":"100px"}},[(_vm.displayPricing())?_c('span',[_vm._v(_vm._s(_vm.convertCurrency( (Number(orderItem.actual_price) + Number(orderItem.install_price)) * _vm.calculateQuantity(orderItem), _vm.$store.getters.order.currency )))]):_c('span',[_vm._v("-----")])])])]})],2),(_vm.showOrderFooter())?_c('tfoot',{staticStyle:{"overflow-y":"scroll","background-color":"#e8e8e8"}},[_c('tr',[_c('td',{staticStyle:{"text-align":"right"},attrs:{"colspan":_vm.$store.getters.order.includeInstall &&
                  _vm.$store.getters.order.IncludeBuild
                    ? 4
                    : 3}},[_vm._v(" Item Subtotal ")]),_c('td',{staticStyle:{"text-align":"right","width":"100px"}},[_vm._v(" "+_vm._s(_vm.convertCurrency(_vm.itemSubtotal, _vm.$store.getters.order.currency))+" ")])]),_c('tr',[_c('td',{staticStyle:{"text-align":"right","vertical-align":"middle"},attrs:{"colspan":_vm.$store.getters.order.includeInstall &&
                  _vm.$store.getters.order.IncludeBuild
                    ? 4
                    : 3}},[_vm._v(" Shipping and Handling ")]),_c('td',{staticStyle:{"text-align":"right","width":"100px"}},[_vm._v(" "+_vm._s(_vm.convertCurrency( Number(_vm.$store.getters.order.shipping), _vm.$store.getters.order.currency ))+" ")])]),_c('tr',[_c('td',{staticStyle:{"text-align":"right"},attrs:{"colspan":_vm.$store.getters.order.includeInstall &&
                  _vm.$store.getters.order.IncludeBuild
                    ? 4
                    : 3}},[_vm._v(" Taxes ("+_vm._s(_vm.$store.getters.order.taxes.name)+") ")]),_c('td',{staticStyle:{"text-align":"right","width":"100px"}},[_vm._v(" "+_vm._s(_vm.convertCurrency( _vm.$store.getters.order.taxes.amount, _vm.$store.getters.order.currency ))+" ")])]),_c('tr',[_c('td',{staticStyle:{"text-align":"right"},attrs:{"colspan":_vm.$store.getters.order.includeInstall &&
                  _vm.$store.getters.order.IncludeBuild
                    ? 4
                    : 3}},[_vm._v(" Total ")]),_c('td',{staticStyle:{"text-align":"right","width":"100px"}},[_vm._v(" "+_vm._s(_vm.convertCurrency(_vm.itemTotal, _vm.$store.getters.order.currency))+" ")])])]):_vm._e()])]):_vm._e()])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('label',[_c('i',{staticClass:"fa-regular fa-truck",staticStyle:{"margin-right":"5px"}}),_c('strong',{staticClass:"text-uppercase small"},[_vm._v("Shipping Address")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row",staticStyle:{"text-align":"left"}},[_c('div',{staticClass:"col-lg-12"},[_c('label',[_c('i',{staticClass:"fa-light fa-file-invoice-dollar",staticStyle:{"margin-right":"5px"}}),_c('strong',{staticClass:"text-uppercase small"},[_vm._v("Billing Address")])])])])}]

export { render, staticRenderFns }