















import Vue from "vue";

import Snap from "snapsvg-cjs-ts";

import {
  getNoFillNoStrokeByElementId,
  removeSelectionHandles,
} from "../utilities";

import { underscoreToNormalCase } from "../include/Utils";

export default Vue.extend({
  data: function () {
    return {
      commands: "",
      commandArray: [] as Array<string>,
      errors: "",
      inputString: "",
      validCommands: [
        {
          command: "left",
          validator: "validateTextAlignment",
        },
        {
          command: "center",
          validator: "validateTextAlignment",
        },
        {
          command: "centre",
          validator: "validateTextAlignment",
        },
        {
          command: "right",
          validator: "validateTextAlignment",
        },
        {
          command: "top",
          validator: "validateTextAlignment",
        },
        {
          command: "middle",
          validator: "validateTextAlignment",
        },
        {
          command: "bottom",
          validator: "validateTextAlignment",
        },
        {
          command: "align_left",
          validator: "validateElementAlignment",
        },
        {
          command: "align_center",
          validator: "validateElementAlignment",
        },
        {
          command: "align_right",
          validator: "validateElementAlignment",
        },
        {
          command: "align_top",
          validator: "validateElementAlignment",
        },
        {
          command: "align_middle",
          validator: "validateElementAlignment",
        },
        {
          command: "align_bottom",
          validator: "validateElementAlignment",
        },
        {
          command: "align_left_to_left",
          validator: "validateElementAlignment",
        },
        {
          command: "align_left_to_center",
          validator: "validateElementAlignment",
        },
        {
          command: "align_left_to_right",
          validator: "validateElementAlignment",
        },
        {
          command: "align_center_to_left",
          validator: "validateElementAlignment",
        },
        {
          command: "align_center_to_center",
          validator: "validateElementAlignment",
        },
        {
          command: "align_center_to_right",
          validator: "validateElementAlignment",
        },
        {
          command: "align_right_to_left",
          validator: "validateElementAlignment",
        },
        {
          command: "align_right_to_center",
          validator: "validateElementAlignment",
        },
        {
          command: "align_right_to_right",
          validator: "validateElementAlignment",
        },
        {
          command: "align_top_to_top",
          validator: "validateElementAlignment",
        },
        {
          command: "align_top_to_bottom",
          validator: "validateElementAlignment",
        },
        {
          command: "align_top_to_middle",
          validator: "validateElementAlignment",
        },
        {
          command: "align_middle_to_top",
          validator: "validateElementAlignment",
        },
        {
          command: "align_middle_to_middle",
          validator: "validateElementAlignment",
        },
        {
          command: "align_middle_to_bottom",
          validator: "validateElementAlignment",
        },
        {
          command: "align_bottom_to_top",
          validator: "validateElementAlignment",
        },
        {
          command: "align_bottom_to_middle",
          validator: "validateElementAlignment",
        },
        {
          command: "align_bottom_to_bottom",
          validator: "validateElementAlignment",
        },
        {
          command: "slide_up",
          validator: "validateSlide",
        },
        {
          command: "slide_left",
          validator: "validateSlide",
        },
        {
          command: "slide_right",
          validator: "validateSlide",
        },
        {
          command: "color",
          validator: "validateColor",
        },
        {
          command: "fill_color",
          validator: "validateColor",
        },
        {
          command: "stroke_color",
          validator: "validateColor",
        },
        {
          command: "tab_left",
          validator: "validateTabs",
        },
        {
          command: "tab_center",
          validator: "validateTabs",
        },
        {
          command: "tab_right",
          validator: "validateTabs",
        },
        {
          command: "indent_first",
          validator: "validateIndents",
        },
        {
          command: "indent_hanging",
          validator: "validateIndents",
        },
        {
          command: "uppercase",
          validator: "",
        },
        {
          command: "lowercase",
          validator: "",
        },
        {
          command: "titlecase",
          validator: "",
        },
        {
          command: "leading",
          validator: "validateLeading",
        },
      ],
    };
  },
  mounted: function () {
    this.$root.$refs.CodeEditor = this;
  },
  methods: {
    applyChanges: function () {
      // TODO: need a way to capture the code editor commands for undo/redo processing

      // this.$store.commit("updateCodeEditor", this.commands);

      // this.$store.commit("captureState", {
      //   description: "Update code editor",
      //   undoMutations: [
      //     { mutationName: "updateCodeEditor", undoValue: this.oldCommands },
      //   ],
      //   redoMutations: [
      //     { mutationName: "updateCodeEditor", redoValue: this.commands },
      //   ],
      // });
      if (this.commands) {
        // this.$store.commit("captureState", {"Update code editor");
        this.errors = "";
        this.validateCommands();
        if (this.errors === "") {
          // save the selected field id so that we can select it later
          const selectedFieldId = this.$store.getters.selectedField.id;

          this.$store.commit("updateCodeEditor", this.commands);

          // let rect = {} as Snap.Element;
          // if (this.$store.getters.selectedField) {
          //   rect = getNoFillNoStrokeByElementId(
          //     this.$store.getters.selectedField.elementIds[0],
          //     this.$store.getters.canvas
          //   );
          // } else {
          //   rect = this.$store.getters.canvas.select(
          //     "[sa-data-id='" + this.$store.getters.selectedElementIds[0] + "']"
          //   );
          // }
          // rect.attr({ "data-name": this.commands });
          // rect.attr({
          //   id: normalCaseToUnderscore(this.commands.split(",").join(" ")),
          // });

          removeSelectionHandles(this.$store.getters.canvas);

          // clear all the sign data
          this.$store.commit("setFiles", []);
          this.$store.commit("setParts", []);
          this.$store.commit("setSignMaterials", []);
          this.$store.commit("setSignProcesses", []);
          this.$store.commit("setRepeats", []);
          this.$store.commit("setAlignments", []);
          this.$store.commit("setSelectedElementIds", []);
          this.$store.commit("deselectAllFields");
          this.$store.commit("deselectAllUsedFields");

          /* eslint-disable-next-line */
          (this.$root.$refs.SVGRenderer as any).processSVG(false, false);

          // reselect the field
          this.$store.commit("selectFieldById", selectedFieldId);
          /* eslint-disable-next-line */
          (this.$root.$refs.SVGRenderer as any).drawSelectionHandlesFromIds();
        }
      } else {
        this.$store.commit("updateCodeEditor", this.commands);
      }
    },
    validateCommands: function () {
      if (this.commands) {
        this.commandArray = this.commands
          .split(",")
          .map((element) => element.toLowerCase().trim());
        for (let i = 0; i < this.commandArray.length; i++) {
          let command: string = this.commandArray[i];
          const index = this.validCommands.findIndex((validCommand) => {
            return (
              validCommand.command.toLowerCase().trim() ===
              command.split(":")[0].toLowerCase().trim()
            );
          });
          if (index !== -1) {
            const validator = this.validCommands[index].validator; // "validateLeft";
            // if we have a validator then run it
            if (validator !== "") {
              (this as unknown as { [key: string]: (command: string) => void })[
                validator
              ](command);
            }
            //this[validator as string](command);
          } else {
            this.errors = "Invalid command: " + command;
          }
        }
      }
    },
    validateTextAlignment: function (command: string) {
      switch (command) {
        case "left":
          if (this.commandArray.includes("right")) {
            this.errors = "Conflicting commands: both left and right specified";
          } else if (
            this.commandArray.includes("center") ||
            this.commandArray.includes("centre")
          ) {
            this.errors =
              "Conflicting commands: both left and center specified";
          }
          break;
        case "right":
          if (this.commandArray.includes("left")) {
            this.errors = "Conflicting commands: both left and right specified";
          } else if (
            this.commandArray.includes("center") ||
            this.commandArray.includes("centre")
          ) {
            this.errors =
              "Conflicting commands: both right and center specified";
          }
          break;
        case "center":
        case "centre":
          if (this.commandArray.includes("left")) {
            this.errors =
              "Conflicting commands: both left and center specified";
          } else if (this.commandArray.includes("right")) {
            this.errors =
              "Conflicting commands: both right and center specified";
          }
          break;
        case "top":
          if (this.commandArray.includes("middle")) {
            this.errors = "Conflicting commands: both top and middle specified";
          } else if (this.commandArray.includes("bottom")) {
            this.errors = "Conflicting commands: both top and bottom specified";
          }
          break;
        case "middle":
          if (this.commandArray.includes("top")) {
            this.errors = "Conflicting commands: both top and middle specified";
          } else if (this.commandArray.includes("bottom")) {
            this.errors =
              "Conflicting commands: both middle and bottom specified";
          }
          break;
        case "bottom":
          if (this.commandArray.includes("top")) {
            this.errors = "Conflicting commands: both top and bottom specified";
          } else if (this.commandArray.includes("middle")) {
            this.errors =
              "Conflicting commands: both middle and bottom specified";
          }
          break;
      }
    },
    validateElementAlignment: function (command: string) {
      // first validate the parameters
      // command: <valid field> [measurement]
      // remove all extra whitespace from string so that we only have one space between each word
      command = command.replace(/\s+/g, " ");
      let parameters = command.split(":")[1];
      parameters = parameters.trim();
      const field = parameters.split(" ")[0];
      let distance = parameters.split(" ")[1];
      const units = parameters.split(" ")[2];
      if (typeof units != "undefined") {
        distance += units;
      }
      let foundField = false;
      for (let i = 0; i < this.$store.getters.usedFields.length; i++) {
        if (
          underscoreToNormalCase(field).toLowerCase().trim() ===
          this.$store.getters.usedFields[i].name.toLowerCase().trim()
        ) {
          foundField = true;
          break;
        }
      }
      if (!foundField) {
        this.errors = "Invalid field name: " + field;
      } else {
        //if units is undefined then check if the distance contains a unit
        let offsetReg =
          /\s*([+-]?\d*.?,?\d*)\s*(inch|in|feet|foot|ft|yard|yd|millimeter|millimetre|mm|centimeter|centimetre|cm|meter|metre|pica|pi|point|pt)/;
        let matches = distance.match(offsetReg);
        if (!matches) {
          this.errors = "Invalid measurement on " + command.split(":")[0];
        }
        //this.validateDistance(distance, units);
      }
    },
    validateSlide: function (command: string) {
      switch (command) {
        case "slide_up":
          if (this.commandArray.includes("slide_left")) {
            this.errors =
              "Conflicting commands: both slide_up and slide_left specified";
          } else if (this.commandArray.includes("slide_right")) {
            this.errors =
              "Conflicting commands: both slide_up and slide_right specified";
          }
          break;
        case "slide_left":
          if (this.commandArray.includes("slide_up")) {
            this.errors =
              "Conflicting commands: both slide_up and slide_left specified";
          } else if (this.commandArray.includes("slide_right")) {
            this.errors =
              "Conflicting commands: both slide_left and slide_right specified";
          }
          break;
        case "slide_right":
          if (this.commandArray.includes("slide_left")) {
            this.errors =
              "Conflicting commands: both slide_right and slide_left specified";
          } else if (this.commandArray.includes("slide_up")) {
            this.errors =
              "Conflicting commands: both slide_up and slide_right specified";
          }
          break;
      }
    },
    validateColor: function (command: string) {
      command = command.replace(/\s+/g, " ");
      let parameters = command.split(":")[1];
      parameters = parameters.trim();
      if (parameters.startsWith("{") && parameters.endsWith("}")) {
        // remove surrounding {} characters
        const field = parameters.slice(1, -1);
        let foundField = false;
        for (let i = 0; i < this.$store.getters.fields.length; i++) {
          if (
            underscoreToNormalCase(field).toLowerCase().trim() ===
            this.$store.getters.fields[i].name.toLowerCase().trim()
          ) {
            foundField = true;
            break;
          }
        }
        if (!foundField) {
          this.errors = "Invalid field name: " + field;
        }
      } else {
        this.errors = "Invalid color field format - missing { or }";
      }
    },
    validateTabs: function (command: string) {
      command = command.replace(/\s+/g, " ");
      let parameters = command.split(":")[1];
      if (typeof parameters === "undefined") {
        this.errors = "Missing offset measurement on " + command.split(":")[0];
      } else {
        parameters = parameters.trim();

        let offsetReg =
          /\s*([+-]?\d*.?,?\d*)\s*(inch|in|feet|foot|ft|yard|yd|millimeter|millimetre|mm|centimeter|centimetre|cm|meter|metre|pica|pi|point|pt)/;
        let matches = parameters.match(offsetReg);
        if (!matches) {
          this.errors = "Invalid measurement on " + command.split(":")[0];
        }
      }
    },
    validateIndents: function (command: string) {
      command = command.replace(/\s+/g, " ");
      let parameters = command.split(":")[1];
      if (typeof parameters === "undefined") {
        this.errors = "Missing offset measurement on " + command.split(":")[0];
      } else {
        parameters = parameters.trim();

        let offsetReg =
          /\s*([+-]?\d*.?,?\d*)\s*(inch|in|feet|foot|ft|yard|yd|millimeter|millimetre|mm|centimeter|centimetre|cm|meter|metre|pica|pi|point|pt)/;
        let matches = parameters.match(offsetReg);
        if (!matches) {
          this.errors = "Invalid measurement on " + command.split(":")[0];
        }
      }
    },
    validateLeading: function (command: string) {
      command = command.replace(/\s+/g, " ");
      let parameters = command.split(":")[1];
      if (typeof parameters === "undefined") {
        this.errors = "Missing offset measurement on " + command.split(":")[0];
      } else {
        parameters = parameters.trim();

        let offsetReg =
          /\s*([+-]?\d*.?,?\d*)\s*(inch|in|feet|foot|ft|yard|yd|millimeter|millimetre|mm|centimeter|centimetre|cm|meter|metre|pica|pi|point|pt)/;
        let matches = parameters.match(offsetReg);
        if (!matches) {
          this.errors = "Invalid measurement on " + command.split(":")[0];
        }
      }
    },
    getRepeatRect: function (rect: Snap.Element): Snap.Element | null {
      const gElements = rect.selectAll("g");
      let returnValue: Snap.Element | null = null;

      gElements.forEach((element: Snap.Element) => {
        if (!returnValue) {
          if (element.attr("id").startsWith("repeat")) {
            returnValue = element;
          }
        }
      });

      return returnValue;
    },
  },
  computed: {
    selectedFieldId: function () {
      return this.$store.getters.selectedField;
    },
    selectedRepeatId: function () {
      return this.$store.getters.selectedRepeat;
    },
    // commandOptions: function (): Array<string> {
    //   let result = this.validCommands.filter((command) => {
    //     return command.startsWith(this.inputString);
    //   });
    //   if (result.length > 0) {
    //     return result;
    //   } else {
    //     return ["No command found."];
    //   }
    // },
  },
  watch: {
    selectedFieldId: {
      immediate: true,
      handler() {
        let rect: Snap.Element;

        // if there is a selected field and it is used on the template
        if (
          this.$store.getters.selectedField &&
          this.$store.getters.selectedField.elementIds.length > 0
        ) {
          rect = getNoFillNoStrokeByElementId(
            this.$store.getters.selectedField.elementIds[0],
            this.$store.getters.canvas
          );
        } else {
          // because the updateCodeEditor mutation sets the
          // first field to selected we need to deselect it
          // here. It is only selecting the field to force
          // an update.
          this.$store.commit("deselectAllFields");
          rect = this.$store.getters.canvas.select(
            "[sa-data-id='" + this.$store.getters.selectedElementIds[0] + "']"
          );
        }
        if (rect) {
          this.commands = rect.attr("data-name");
          this.$store.commit("updateCommands", this.commands);
        }
        this.errors = "";
      },
    },
    selectedRepeatId: {
      immediate: true,
      handler() {
        let rect: Snap.Element;

        if (this.$store.getters.selectedRepeat) {
          rect = this.$store.getters.canvas.select(
            "[sa-data-id='" + this.$store.getters.selectedElementIds[0] + "']"
          );

          // get the repeat g element by looking for ids the start with 'repeat'
          const repeatGroup: Snap.Element | null = this.getRepeatRect(rect);

          if (repeatGroup) {
            this.commands = repeatGroup.attr("data-name");
          }
          this.errors = "";
        }
      },
    },
  },
});
