





























































































import Vue from "vue";
import { PropType } from "vue";

import draggable from "vuedraggable";
import { IKanbanSignType, IKanbanSign } from "../types";

export default Vue.extend({
  components: { draggable },
  data: function () {
    return {
      gridSize: 3,
      showModal: false,
    };
  },
  props: {
    signType: {
      type: Object as PropType<IKanbanSignType>,
    },
  },
  methods: {
    getTextColor: function (hexColor: string) {
      if (hexColor.substr(0, 1) === "#") {
        hexColor = hexColor.slice(1);
      }
      const r = parseInt(hexColor.slice(0, 2), 16);
      const g = parseInt(hexColor.slice(2, 4), 16);
      const b = parseInt(hexColor.slice(4, 6), 16);
      if (0.213 * r + 0.715 * g + 0.072 * b > 127) {
        return "#000000";
      } else {
        return "#FFFFFF";
      }
    },
    /**
     * return a portion of an array
     * @param {Array<IKanbanSign>} array - the full array
     * @param {number} size - the number of elements per "chunk"
     * @returns {Array} an array of items that is "size" elements long
     */
    chunkSignsArray: function (array: Array<IKanbanSign>, size: number) {
      let result = [];
      for (let i = 0; i < array.length; i += size) {
        let chunk = array.slice(i, i + size);
        result.push(chunk);
      }
      return result;
    },
  },
  computed: {},
  watch: {},
});
