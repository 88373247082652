import { SvgCss } from "./SvgCss";
import { SAFonts } from "./SAFonts";
import { Units } from "./Units";

type FontWeight = string | number;

/**
 * FontMetric contains the data that is extracted  from the SAFontMetric object
 */
export interface FontMetric {
  fontFamily: string;
  scale: number;
  unitsPerEm: number;
  fontWeight: FontWeight;
  fontSize: number;
  fill: string;
  capXHeight: number;
  capHeight: number;
  baseline: number;
  xHeight: number;
  descent: number;
  bottom: number;
  ascent: number;
  tittle: number;
  top: number;
  stroke: string;
  strokeWidth: number;
  lineHeight: number;
  lineGap: number;
}

/**
 *
 */
export class SAFontInfo {
  private svgCss: SvgCss | null = SvgCss.getInstance();
  private cssId: string;
  private signFonts = SAFonts.getInstance();
  private font: opentype.Font | undefined;
  private static capXChar = "X";
  private static alephChar = "ا";
  private static capHeightChar = "S";
  private static baselineChar = "n";
  private static xHeightChar = "x";
  private static descentChar = "p";
  private static ascentChar = "h";
  private static tittleChar = "i";

  private height: number;
  private capHeight: number;
  private capXHeight: number;
  private baseline: number;
  private xHeight: number;
  private descent: number;
  private ascent: number;
  private top: number;
  private bottom: number;
  private tittle: number;
  private lineHeight: number;
  private adjustment: number;
  private isLeftToRight = true;
  private units: Units | null = null;

  /**
   * constructor for the SAFontInfo object.
   * @param cssId
   */
  constructor(cssId: string) {
    this.height = 0;
    this.capHeight = 0;
    this.capXHeight = 0;
    this.baseline = 0;
    this.xHeight = 0;
    this.descent = 0;
    this.ascent = 0;
    this.top = 0;
    this.bottom = 0;
    this.tittle = 0;
    this.lineHeight = 0;
    this.adjustment = 0;
    this.isLeftToRight = true;
    this.units = Units.getInstance();

    this.font = this.signFonts.FontDataByCssId(cssId);
    this.cssId = cssId;
    if (this.font) {
      // for all glyphs in a font y coordinates start from the bottom left and go up.

      this.height = this.font.ascender - this.font.descender;
      this.capHeight = this.font
        .charToGlyph(SAFontInfo.capHeightChar)
        .getMetrics().yMax;
      this.capXHeight = this.font
        .charToGlyph(SAFontInfo.capHeightChar)
        .getMetrics().yMax;
      if (this.capXHeight === 0) {
        // then a non latin language
        this.capXHeight = this.font
          .charToGlyph(SAFontInfo.alephChar)
          .getMetrics().yMax;
        this.isLeftToRight = false;

        if (this.capXHeight === 0) {
          this.capXHeight = this.font.tables["os2"].sCapHeight;
        }
      }
      this.baseline = 0.0;
      this.xHeight = this.font
        .charToGlyph(SAFontInfo.xHeightChar)
        .getMetrics().yMax;
      this.descent = this.font
        .charToGlyph(SAFontInfo.descentChar)
        .getMetrics().yMin;
      this.bottom = this.font.descender;
      this.ascent = this.font
        .charToGlyph(SAFontInfo.ascentChar)
        .getMetrics().yMax;
      this.top = this.ascent;
      this.tittle = this.font
        .charToGlyph(SAFontInfo.tittleChar)
        .getMetrics().yMax;
      this.top = this.font.ascender;
      this.lineHeight = this.top - this.bottom;
      this.adjustment = this.font.unitsPerEm - this.lineHeight;
    } else {
      console.log("SAFontInfo: no font");
    }

    /*
    console.debug(`top:         ${this.top}`);
    console.debug(`capHeight:   ${this.capHeight}`);
    console.debug(`capXHeight:  ${this.capXHeight}`);
    console.debug(`ascent:      ${this.ascent}`);
    console.debug(`baseline:    ${this.baseline}`);
    console.debug(`descent:     ${this.descent}`);
    console.debug(`bottom:      ${this.bottom}`);
    console.debug(`dist top and bottom: ${this.top - this.bottom}`);
    */
  }

  /**
   *
   * @returns string - the fontFamily name
   */

  fontFamily(): string {
    const familyName = this.font?.names.fontFamily.en;
    return familyName ? familyName : "";
  }

  /**
   *
   * @returns number: the font weight
   */
  fontWeight(): FontWeight {
    const weightStr = this.svgCss?.getClassVariableValue(
      this.cssId,
      "font-weight"
    );
    let weight: FontWeight = "normal";

    if (weightStr !== undefined) {
      const num = Number(weightStr);
      weight = isNaN(num) ? weightStr : num;
    }
    return weight;
  }

  /**
   *
   */
  fontSize(): string {
    const size = this.svgCss?.getClassVariableValue(this.cssId, "font-size");
    return size ? size : "";
  }

  stroke(): string {
    const strokeVal = this?.svgCss?.getClassVariableValue(this.cssId, "stroke");
    return strokeVal ? strokeVal : "";
  }

  strokeWidth(): number {
    return Number(
      this?.svgCss?.getClassVariableValue(this.cssId, "stroke-width")
    );
  }

  fill(): string {
    const retval = this?.svgCss?.getClassVariableValue(this.cssId, "fill");
    return retval ? retval : "#fff";
  }

  fontSizeInPixels(): number {
    const fontSizeStr = this.fontSize();
    Units.UnitReg.lastIndex = 0;
    const fontSizeUnits = Units.parseUnitValue(fontSizeStr);
    const retval = this?.units?.normalizeMeasure(
      fontSizeUnits.value,
      fontSizeUnits.unit
    );

    return retval !== undefined ? retval : NaN;
  }

  /**
   * returns the FontMetric data
   */
  fontMetric(): FontMetric {
    const fontSize = this.fontSizeInPixels();
    let unitsPerEm = 1000;

    if (this?.font?.unitsPerEm) {
      unitsPerEm = this.font.unitsPerEm;
    }

    const scaleFactor = fontSize / unitsPerEm;
    return {
      fontFamily: this.fontFamily(),
      scale: scaleFactor,
      unitsPerEm: unitsPerEm,
      fontWeight: this.fontWeight(),
      fontSize: fontSize,
      fill: this.fill(),
      capXHeight: this.capXHeight * scaleFactor,
      capHeight: this.capHeight * scaleFactor,
      baseline: this.baseline * scaleFactor,
      xHeight: this.xHeight * scaleFactor,
      descent: this.descent * scaleFactor,
      bottom: this.bottom * scaleFactor,
      ascent: this.ascent * scaleFactor,
      tittle: this.tittle * scaleFactor,
      top: this.top * scaleFactor,
      stroke: this.stroke(),
      strokeWidth: this.strokeWidth(),
      lineHeight: this.lineHeight * scaleFactor,
      lineGap: this?.font?.tables.os2.sTypoLineGap * scaleFactor,
    };
  }
}
