
























































import Vue from "vue";
import CollapsibleSection from "../CollapsibleSection.vue";
//import { IFile } from "../../types";

//import { uuidv4 } from "@/features/SignDesigner/utilities";

export default Vue.extend({
  components: { CollapsibleSection },
  data: function () {
    return {};
  },
  methods: {
    /**
     * replaceTemplate
     * replace the current template with a new one
     */
    replaceTemplate: function () {
      this.$store.commit("setShowFileModal", true);
    },
    /**
     * clearTemplate
     * clear the current template
     */
    clearTemplate: function () {
      // clear all designer data
      this.$store.commit("clearSignTemplate", true);
    },
    /**
     * show the add file modal
     */
    addFile: function () {
      this.$store.commit("setShowFileModal", true);
    },
    /**
     * delete the selected file
     */
    deleteFile: function () {
      this.$store.commit("deleteSelectedFile");
    },
    download: function (filename: string, text: Blob) {
      var element = document.createElement("a");
      element.style.display = "none";
      document.body.appendChild(element);
      let url = window.URL.createObjectURL(text);
      element.href = url;
      element.download = filename;
      element.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(element);
    },
    /**
     * download the file
     */
    downloadFile: function () {
      // let reader = new FileReader();
      // reader.onload = () => {
      //   if (reader.result) {
      //     this.download(
      //       this.$store.getters.selectedFile.name,
      //       reader.result.toString()
      //     );
      //   }
      // };
      // reader.readAsText(this.$store.getters.selectedFile.blob);
      this.download(
        this.$store.getters.selectedFile.name,
        this.$store.getters.selectedFile.blob
      );
    },
  },
  computed: {
    /**
     * show we show the section expand/collapse caret
     * @returns {boolean} True if we should show the caret
     */
    showCaret: function (): boolean {
      return this.$store.getters.isFileSelected;
    },
    /**
     * should we show the minus sign for deleting files
     * @returns {boolean} True if we should show the minus sign
     */
    showTrash: function (): boolean {
      if (this.$store.getters.selectedFile) {
        return true;
      }
      return false;
    },
    /**
     * should the section be shown open
     * @returns {boolean} True if the section should be open
     */
    openSection: function (): boolean {
      if (this.$store.getters.selectedFile) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * getter/setter for file name
     */
    fileName: {
      get() {
        return this.$store.getters.selectedFile.name;
      },
      set(value: string) {
        this.$store.commit("updateSelectedFileName", value);
      },
    },
  },
  watch: {},
});
