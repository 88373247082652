























//import { iSignChild } from "../../types";
import Vue from "vue";
import CollapsibleSection from "../CollapsibleSection.vue";

import { IFile } from "../../types";

//import { uuidv4 } from "../../utilities";

export default Vue.extend({
  components: { CollapsibleSection },
  props: {},
  methods: {
    /**
     * select a file element
     * @param {IFile} file - the file that was clicked on
     */
    selectFile(file: IFile) {
      this.$store.commit("selectFileById", file.id);
    },
    /**
     * show the file modal so the user can add a file
     */
    addFile() {
      this.$store.commit("setShowFileModal", true);
    },
  },
  computed: {
    /**
     * determine if section should be show open
     * @returns {boolean} true if section should be open
     */
    openSection: function (): boolean {
      //if we have any files defined then the section should be open
      if (this.$store.getters.files.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
});
