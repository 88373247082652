


























import Vue from "vue";
import CollapsibleSection from "../CollapsibleSection.vue";

export default Vue.extend({
  components: { CollapsibleSection },
  props: {},
  methods: {
    selectMarketplaceConnection: function (id: string) {
      this.$store.commit("selectMarketplaceConnection", id);
    },
  },
  computed: {
    /**
     * should the section be displayed open
     */
    openSection: function () {
      return true;
    },
    /**
     * is the section openable
     */
    isOpenable: function () {
      return true;
    },
  },
  watch: {},
});
