












import Vue from "vue";
import OrderDetails from "./views/OrderDetailsView.vue";
import MapViewLayout from "@/components/layouts/MapViewLayout.vue";

export default Vue.extend({
  components: { OrderDetails, MapViewLayout },
});
