


































































































import Vue from "vue";

import SignTypesList from "../SignTypesList.vue";
import { featureAvailable } from "../../utilities";

import CollapsibleSection from "../CollapsibleSection.vue";
import ColorSection from "../panelsections/ColorSection.vue";
// import ComponentSection from "../panelsections/ComponentSection.vue";
import VisualSection from "../panelsections/VisualSection.vue";
import RepeatSection from "../panelsections/RepeatSection.vue";
import AlignmentSection from "../panelsections/AlignmentSection.vue";
import SidesSection from "../panelsections/SidesSection.vue";
import MarketplaceSection from "../panelsections/MarketplaceSection.vue";

export default Vue.extend({
  name: "assets-panel",
  components: {
    CollapsibleSection,
    // ComponentSection,
    ColorSection,
    VisualSection,
    RepeatSection,
    AlignmentSection,
    SignTypesList,
    SidesSection,
    MarketplaceSection,
  },
  props: {
    signData: {
      type: Object,
      required: true,
    },
    // componentData: {
    //   type: Array,
    //   required: true,
    // },
  },
  data: function () {
    return {
      // signData: {},
      components: [],
    };
  },
  methods: {
    /**
     * Switch the active panel by setting the current sign type uuid to blank
     * if the currentSignTypeUUID is blank then the signTypesList component is displayed
     */
    switchActivePanel: function () {
      //save the current sign type uuid so we know if the user has selected the same sign in the sign types list
      this.$store.commit(
        "setPreviousSignTypeUUID",
        this.$store.getters.currentSignTypeUUID
      );
      this.$store.commit("setCurrentSignTypeUUID", "");
    },
    /**
     * setting the setShowAddColorsModal to true will display the Show Colors Modal
     */
    showAddColorsModal() {
      this.$store.commit("setShowAddColorsModal", true);
    },
    showAddVisualsModal() {
      this.$store.commit("setShowAddVisualsModal", true);
    },
    featureAccess: function (featureName: string): boolean {
      return featureAvailable(this, featureName);
    },
  },
  computed: {
    featureAccessRepeats: function (): boolean {
      return featureAvailable(this, "repeats");
    },
  },
  watch: {},
});
