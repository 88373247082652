































































































































import Vue from "vue";
import MapViewLayout from "@/components/layouts/MapViewLayout.vue";

import draggable from "vuedraggable";
import ColumnCard from "../components/ProjectColumnCard.vue";
import {
  IKanbanSignQL,
  IKanbanSignType,
  IKanbanSignTypeQL,
  ISigntypeColumn,
} from "../types";

import { randomRGB } from "@/features/SmartStates/utilities";

const STATE_TYPES = {
  DESIGN: "0",
  ORDER: "1",
  REVIEW: "2",
};

export default Vue.extend({
  components: { draggable, ColumnCard, MapViewLayout },
  props: {},
  data: function () {
    return {
      columns: [] as Array<ISigntypeColumn>,
      showModal: false,
      column: {
        name: "",
      },
      workflow: "",
      saveError: false,
      saveSuccess: false,
      errorMessage: "",
    };
  },
  mounted: function () {
    this.fetchStates();
  },
  methods: {
    /**
     * checkMove - called when vue-draggable column is moved, determines if
     *             move should be allowed or not
     * @param e - vue-draggable event
     * @returns boolean - true if the drag is to be allowed
     */
    checkMove: function (e: {
      draggedContext: {
        index: number;
        element: HTMLElement;
        futureIndex: number;
      };
    }) {
      // don't allow user to drag a column before the first column or after the last.
      // the first and last states are smart states and must remain in the first and last position
      if (
        e.draggedContext.futureIndex === 0 ||
        e.draggedContext.futureIndex >= this.columns.length - 1
      ) {
        return false;
      }

      // don't allow user to move the first or last state - these are smart states and must
      // remain in the first and last position
      if (
        e.draggedContext.index === 0 ||
        e.draggedContext.index === this.columns.length - 1
      ) {
        return false;
      }

      //otherwise all the drag and drop
      return true;
    },
    showPermissions: function () {
      // made this an any type because I wasn't able to figure out the typeof globalThis
      /* eslint-disable-next-line */
      const globalThis = window.globalThis as any;
      globalThis.sa_vue.showPermissionsUI({
        model: "project",
        id: Number(this.$route.params.id),
      });
    },
    /**
     * redirect user back to kanban board
     * this is done by stripping the last part of the url out
     */
    returnToKanban: function () {
      // get the full path
      const fullPath = this.$route.fullPath;
      //remove final /
      let path = fullPath.substring(0, fullPath.lastIndexOf("/"));
      // remove final section of path, making sure we have a trailing /
      path = path.substring(0, path.lastIndexOf("/")) + "/";
      //redirect user
      location.href = path;
    },
    /**
     * Run a graphQL query
     * @param query string - the graphQL query to run
     * @returns string - a JSON string containing the result data
     */
    runQuery: async function (query: string) {
      const response = await fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      });

      const result = await response.json();
      return result;
    },
    /**
     * columnChanged - called whenever a column is dropped
     */
    columnChanged: function () {
      this.$store.commit("setShowLoadingSpinner", true);
      let queries = [] as Array<string>;
      // the columns array has already been updated with the new positions at this point
      // so we can just read through that array and update the state's order id with the new position
      this.columns.forEach((column, columnIndex) => {
        // we don't have to process the first or last column because they can't be moved
        // These are the smart states
        if (
          column.orderId !== 0 &&
          column.orderId !== this.columns.length - 1
        ) {
          const query = JSON.stringify({
            query: `mutation updateRightColumns {
              mutateState(input:{
                id: "${column.stateId}",
                stateType: "${STATE_TYPES.DESIGN}",
                workflow: "${this.workflow}",
                name: "${column.stateName}",
                hexColor: "${column.hexColor}",
                orderId: ${columnIndex},
                approved: "Approved",
                rejected: "Rejected",
                needsReviewed: "Review"}) {
                  state{
                    id: contentObjectId
                    name
                    orderId
                  }
                  errors {
                    messages
                  }
                }
              }`,
          });

          queries.push(query);
        }
      });

      // run all the queries
      Promise.all(queries.map(this.runQuery)).then(() => {
        this.$store.commit("setShowLoadingSpinner", false);
        location.reload();
      });
    },
    showError(message: string) {
      this.errorMessage = message;
    },
    getOrCreateSignType: function (
      signType: IKanbanSignTypeQL,
      signTypes: Array<IKanbanSignType>
    ): number {
      const index = signTypes.findIndex((type) => {
        return type.id === signType.id;
      });

      if (index === -1) {
        // sign type not found so we need to create it
        const newSignType = {
          id: signType.id,
          name: signType.name,
          shortCode: signType.shortCode,
          hexColor: signType.hexColor,
          quantity: 1,
          signs: [],
          isExpanded: false,
        };
        signTypes.push(newSignType);
        return signTypes.length - 1;
      } else {
        return index;
      }
    },
    fetchStates: function () {
      const query = JSON.stringify({
        query: `query get_project {
          project (id: ${this.$route.params.id}) {
            name
            workflows {
              edges {
                node {
                  id: contentObjectId
                }
              }
            }
            states {
              edges {
                node {
                  id: contentObjectId
                  uuid
                  name
                  hexColor
                  smartStateStatus
                  approved
                  rejected
                  needsReviewed
                  stateType
                  orderId
                  signs {
                    edges {
                      node {
                        id: contentObjectId
                        uuid
                        signId
                        quantity
                        facingDirection
                        signType {
                          id: contentObjectId
                          name
                          shortCode
                          hexColor
                        }

                      }
                    }
                  }
                }
              }
            }
          }
        }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.errors) {
            alert(result.errors);
          } else {
            this.workflow = result.data.project.workflows.edges[0].node.id;

            result.data.project.states.edges.forEach(
              (state: {
                node: {
                  id: number;
                  uuid: string;
                  name: string;
                  hexColor: string;
                  smartStateStatus: string;
                  approved: string;
                  rejected: string;
                  needsReviewed: string;
                  stateType: string;
                  orderId: number;
                  signs: { edges: Array<{ node: IKanbanSignQL }> };
                };
              }) => {
                const newColumn = {} as ISigntypeColumn;
                newColumn.stateId = state.node.id;
                newColumn.stateName = state.node.name;
                newColumn.stateUUID = state.node.uuid;
                newColumn.workflow = this.workflow;
                newColumn.hexColor = state.node.hexColor;
                newColumn.approved = state.node.approved;
                newColumn.rejected = state.node.rejected;
                newColumn.needsReviewed = state.node.needsReviewed;
                newColumn.stateType = state.node.stateType;
                newColumn.orderId = state.node.orderId;
                newColumn.signTypes = [];

                state.node.signs.edges.forEach(
                  (sign: { node: IKanbanSignQL }) => {
                    let signTypeIndex = this.getOrCreateSignType(
                      sign.node.signType,
                      newColumn.signTypes
                    );
                    newColumn.signTypes[signTypeIndex].signs.push({
                      signId: sign.node.signId,
                      id: sign.node.id,
                      facingDirection: sign.node.facingDirection,
                      quantity: sign.node.quantity,
                      imageURL: "/sign/" + sign.node.id + "/svg_as_png/",
                      messages: [],
                      details: [],
                    });
                  }
                );

                this.columns.push(newColumn);
              }
            );
          }
        });
    },
    showAddColumnModal: function () {
      // create the state via graphQL
      this.showModal = true;
      // this.$store.commit("addColumn", this.$route.params.id);
    },
    closeModal: function () {
      this.showModal = false;
    },
    /**
     * saveColumn - moves the last column over one to the right and inserts
     *              the new column in the second last position. We do this
     *              because the last state is a smart state and must remain
     *              the last state
     */
    saveColumn: function () {
      this.$store.commit("setShowLoadingSpinner", true);
      // first move the last column over one position to the right
      let queryString = `mutation StateMutation {
        mutateState(input: {
          id: ${this.columns[this.columns.length - 1].stateId}
          stateType: "${STATE_TYPES.DESIGN}",
          workflow: "${this.workflow}",
          name: "${this.columns[this.columns.length - 1].stateName}",
          hexColor: "${this.columns[this.columns.length - 1].hexColor}",
          orderId: ${this.columns.length},
          approved: "Approved",
          rejected: "Rejected",
          needsReviewed: "Review"}) {
          state{
            id: contentObjectId
          }
        }
      }`;

      let query = JSON.stringify({ query: queryString });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.errors) {
            this.saveError = true;
            this.saveSuccess = false;
            this.errorMessage = result.errors[0].message;
            this.$store.commit("setShowLoadingSpinner", false);
          } else {
            // now we can insert the new column
            queryString = `mutation StateMutation{
                  mutateState(input:{
                    stateType: "${STATE_TYPES.DESIGN}",
                    workflow: "${this.workflow}",
                    name: "${this.column.name}",
                    hexColor: "${randomRGB()}",
                    orderId: ${this.columns.length - 1},
                    approved: "Approved",
                    rejected: "Rejected",
                    needsReviewed: "Review"}) {
                      state{
                        id: contentObjectId
                      }
                    }
                  }`;

            query = JSON.stringify({ query: queryString });

            fetch("/graphql/", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: query,
            })
              .then((res) => res.json())
              .then((result) => {
                if (result.errors) {
                  this.saveError = true;
                  this.saveSuccess = false;
                  this.errorMessage = result.errors[0].message;
                  this.$store.commit("setShowLoadingSpinner", false);
                } else {
                  this.saveSuccess = true;
                  this.saveError = false;
                  this.$store.commit("setShowLoadingSpinner", false);
                  location.reload();
                }
              });
          }
        });
    },
  },
  computed: {},
});
