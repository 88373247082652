var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"dashboard_inner_table"},[_c('tbody',[_c('tr',[_c('td',[_c('div',{staticClass:"height_wrapper"},[_c('a',{staticClass:"star_favorite",on:{"click":function($event){return _vm.favoriteProject()}}},[_c('i',{staticClass:"fa-star",class:_vm.isFavorite ? 'fas star_yellow' : 'far star_grey'})])])]),_c('td',{staticStyle:{"width":"100%"}},[_c('span',{class:_vm.project.type === '0'
              ? 'jstree-pill bg-design'
              : _vm.project.type === '1'
              ? 'jstree-pill bg-build'
              : _vm.project.type === '2'
              ? 'jstree-pill bg-build'
              : _vm.project.type === '3'
              ? 'jstree-pill bg-manage'
              : '',staticStyle:{"cursor":"pointer","color":"#fff"}},[_c('a',{staticStyle:{"color":"#fff"},attrs:{"href":_vm.phaseNameURL()}},[_vm._v(_vm._s(_vm.project.name))])])]),_c('td',[_c('div',{staticClass:"height_wrapper",staticStyle:{"width":"24px"}},[_c('user-popup-widget',{attrs:{"groupList":_vm.membersAndViewers(_vm.project.companies),"showGroups":['member']}})],1)]),_c('td',[_c('div',{staticClass:"height_wrapper"},[_c('div',{staticClass:"dropdown show"},[_c('a',{staticClass:"dropdown-toggle",staticStyle:{"color":"inherit"},attrs:{"role":"button","href":"#","id":'dropdown_project_' + _vm.project.id,"data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_c('i',{staticClass:"far fa-ellipsis-v"})]),_c('ul',{staticClass:"dropdown-menu",attrs:{"role":"menu","aria-labelledby":'dropdown_project_' + _vm.project.id}},[_c('li',[_c('a',{on:{"click":function($event){return _vm.favoriteProject()}}},[_vm._v(" "+_vm._s(_vm.isFavorite ? "Unfavorite" : "Favorite")+" ")])]),(_vm.isBuilder())?[_c('li',[_c('a',{attrs:{"href":'/project/' + _vm.project.id + '/kanban/'}},[_vm._v("Kanban")])]),(_vm.hasChangePermProject() && _vm.projectHasNoSigns())?_c('li',[_c('a',{on:{"click":_vm.archiveProject}},[_vm._v(" "+_vm._s(_vm.project.archived ? "Unarchive" : "Archive")+" ")])]):_vm._e()]:(_vm.hasChangePermProject())?[_c('li',[_c('a',{on:{"click":_vm.archiveProject}},[_vm._v(" "+_vm._s(_vm.project.archived ? "Unarchive" : "Archive")+" ")])]),_c('li',[_c('a',{attrs:{"href":'/project/' + _vm.project.id + '/edit/'}},[_vm._v("Edit")])]),_c('li',[_c('a',{attrs:{"href":'/project/' + _vm.project.id + '/edit/',"target":"_blank"}},[_vm._v("Add User")])]),_c('li',[_c('a',{attrs:{"href":'/project/' + _vm.project.id + '/'}},[_vm._v("View Overview")])])]:_vm._e()],2)])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }