
































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import ModalLayout from "@/components/layouts/ModalLayout.vue";
import eventBus from "../EventBus";
import getInitialData from "./api/get-initial-data";
import getSearchResults from "./api/get-search-results";
import savePermissions from "./api/save-permissions";
import {
  MODELS,
  ModelKey,
  isModelKey,
  UserPerm,
  PermObject,
  SearchResult,
} from "./types";
import { userSortFunction, validateEmail } from "./utilities";

import SearchInputGroup from "./components/SearchInputGroup.vue";
import UserPermissionListItem from "./components/UserPermissionListItem.vue";

@Component({
  components: {
    ModalLayout,
    UserPermissionListItem,
    SearchInputGroup,
  },
})
export default class PermissionsUI extends Vue {
  @Prop({
    type: String,
    required: true,
    validator: isModelKey,
  })
  readonly model!: ModelKey;

  @Prop({ type: Number, required: true })
  readonly id!: number;

  readonly MODELS = MODELS;

  $refs!: {
    modal: HTMLFormElement;
    saveButton: HTMLButtonElement;
  };

  errorMessages: null | string[] = null;
  state: "loading" | "ready" | "saving" | "cancelling" | "done" = "loading";
  permObjects: PermObject[] | null = null;
  showInherited = false;
  searchText = "";
  searchTargetObject: PermObject | null = null;
  searchResults: null | SearchResult[] = null;
  searchTruncated = false;
  searchError: null | string = null;

  /** Called when component is initialized */
  mounted(): void {
    // load data from API
    getInitialData(this.model, this.id)
      .then((permObjects) => {
        this.state = "ready";
        this.permObjects = permObjects;
        this.searchTargetObject = this.permObjects ? this.permObjects[0] : null;
      })
      .catch((err) => {
        alert(err.message);
        this.$refs.modal.$emit("close");
      });
  }

  /**
   * Are there unsaved changes?
   * @returns true if there are unsaved changes, false otherwise
   */
  get hasUnsavedChanges(): boolean {
    if (this.permObjects === null) return false;

    for (const permObject of this.permObjects) {
      if (!permObject.canEdit) continue;
      for (const userPerm of permObject.userPerms) {
        if (userPerm.permission !== userPerm.original_permission) return true;
      }
    }
    return false;
  }

  /**
    Do any inherited permObjects have permUsers
    @returns true if yes, false otherwise
  */
  get hasUsersWithInheritedPerms(): boolean {
    if (this.permObjects === null) return false;

    for (const permObject of this.permObjects.slice(1)) {
      if (permObject.userPerms.length) return true;
    }
    return false;
  }

  /**
    Get inherited permObjects which aren't empty
    @returns filtered array of PermObjects
  */
  get nonEmptyInheritedPerms(): PermObject[] {
    const ret: PermObject[] = [];
    if (this.permObjects === null) return ret;

    for (const permObject of this.permObjects.slice(1)) {
      if (permObject.userPerms.length) ret.push(permObject);
    }
    return ret;
  }

  /**
    How many inherited unsaved changes are there? (for the badge when inherited permObjects are hidden)
    @returns the count of inherited unsaved changes
  */
  get numInheritedUnsavedChanges(): number {
    if (this.permObjects === null) return 0;

    let changeCount = 0;
    for (const permObject of this.permObjects.slice(1)) {
      if (!permObject.canEdit) continue;
      for (const userPerm of permObject.userPerms) {
        if (userPerm.permission !== userPerm.original_permission) changeCount++;
      }
    }
    return changeCount;
  }

  /**
    Should we display the loading spinner, the saving spinner, or neither?
    @returns "loading", "saving", or null
  */
  get whichSpinner(): null | "loading" | "saving" {
    if (this.permObjects === null) {
      return "loading";
    } else if (["loading", "saving", "done"].includes(this.state)) {
      return "saving";
    } else {
      return null;
    }
  }

  /**
    Apply the search string to new users we've created (to combine with the regular SearchResults)
    @returns SearchResult[] of new users matching searchText
  */
  get searchNewUsers(): SearchResult[] {
    if (this.permObjects === null) return [];
    const ret = [];
    const searchString = this.searchText.toLocaleLowerCase();

    for (const permObject of this.permObjects) {
      if (!permObject.canEdit) continue;
      for (const userPerm of permObject.userPerms) {
        if (
          userPerm.userId === null &&
          userPerm.email.toLocaleLowerCase().includes(searchString)
        ) {
          const searchResult: SearchResult = {
            id: userPerm.userId,
            firstName: userPerm.firstName,
            lastName: userPerm.lastName,
            email: userPerm.email,
            imageUrl: userPerm.imageUrl,
          };
          ret.push(searchResult);
        }
      }
    }
    return ret;
  }

  /**
    Generate a structure for looking up UserPerms by email address and PermObject
    @returns an object of maps such that [email].get(permObject) returns any existing userPermission for that email and PermObject
  */
  get userMap(): null | { [email: string]: Map<PermObject, UserPerm> } {
    if (this.permObjects === null) {
      return null;
    }

    const ret: { [email: string]: Map<PermObject, UserPerm> } = {};
    for (const permObject of this.permObjects) {
      for (const userPerm of permObject.userPerms) {
        if (!(userPerm.email in ret)) {
          ret[userPerm.email] = new Map();
        }
        ret[userPerm.email].set(permObject, userPerm);
      }
    }
    return ret;
  }

  /**
    Apply the searchTargetObject to searchResults to get applicable UserPerms
    @returns an array of <{userPerm,otherPerms}> objects for each user matching the search query string where:
      - `userPerm` is the user record from the searchTargetObject
      - `otherPerms` is a PermObject -> UserPerm map of the user's other permissions
  */
  get searchUserResults(): null | Array<{
    userPerm: UserPerm;
    otherPerms: Map<PermObject, UserPerm>;
  }> {
    if (this.searchResults === null || this.searchTargetObject === null) {
      return null;
    }

    let foundEmailMatch = false;
    const emailToMatch = validateEmail(this.searchText)
      ? this.searchText.toLocaleLowerCase()
      : null;

    const ret = Array<{
      userPerm: UserPerm;
      otherPerms: Map<PermObject, UserPerm>;
    }>();

    const searchResults = [...this.searchNewUsers, ...this.searchResults];

    if (searchResults.length > 0) {
      const userMap = this.userMap;
      if (userMap === null || searchResults === null) {
        return null;
      }

      for (const result of searchResults) {
        if (
          !foundEmailMatch &&
          emailToMatch !== null &&
          emailToMatch === result.email.toLocaleLowerCase()
        ) {
          foundEmailMatch = true;
        }
        const userPerm: UserPerm = userMap[result.email]?.get(
          this.searchTargetObject
        ) || {
          userId: result.id,
          email: result.email,
          firstName: result.firstName,
          lastName: result.lastName,
          imageUrl: result.imageUrl,
          original_permission: null,
          permission: null,
          error: null,
        };

        const otherPerms: Map<PermObject, UserPerm> = new Map();
        if (result.email in userMap) {
          for (const [permObject, userPerm] of userMap[
            result.email
          ].entries()) {
            if (permObject != this.searchTargetObject) {
              otherPerms.set(permObject, userPerm);
            }
          }
        }

        ret.push({ userPerm, otherPerms });
      }
    }

    if (emailToMatch !== null && !foundEmailMatch) {
      ret.unshift({
        userPerm: {
          userId: null,
          email: this.searchText,
          firstName: null,
          lastName: null,
          imageUrl: null,
          original_permission: null,
          permission: null,
          error: null,
        },
        otherPerms: new Map(),
      });
    }

    return ret;
  }

  /**
   * Listener for when the user modifies searchText
   * @param text - the value of searchText
   * @param oldText - the previous value of searchText
   */
  @Watch("searchText")
  onSearchTextChanged(text: string, oldText: string): void {
    if (text == oldText) return;

    this.searchResults = null;
    this.searchTruncated = false;
    this.searchError = null;

    setTimeout(() => {
      if (text === this.searchText) {
        this.doSearch();
      }
    }, 1000);
  }

  /**
   * Perform a user search against searchText
   */
  doSearch(): void {
    const searchText = this.searchText;

    const isRelevantFn = () =>
      this.searchResults === null && searchText === this.searchText;
    if (!isRelevantFn()) return;

    this.searchResults = null;
    this.searchTruncated = false;
    this.searchError = null;

    getSearchResults(searchText, isRelevantFn)
      .then((results) => {
        const { searchResults, searchTruncated } = results;
        if (isRelevantFn()) {
          this.searchResults = searchResults;
          this.searchTruncated = searchTruncated;
          this.searchError = null;
        }
      })
      .catch((err) => {
        if (isRelevantFn()) {
          this.searchResults = null;
          this.searchTruncated = false;
          this.searchError = err.message;
        }
        return null;
      });
  }

  /**
   * Handle an attempted modal close
   */
  onModalClosed(event: Event): void {
    if (this.state === "ready" && this.searchText) {
      // escape search results
      event.preventDefault();
      event.stopPropagation();
      this.searchText = "";
      this.searchResults = null;
    } else if (
      (this.state === "ready" && this.hasUnsavedChanges) ||
      this.state === "saving"
    ) {
      // user needs to press save
      event.preventDefault();
      event.stopPropagation();
      this.$refs.saveButton.scrollIntoView({
        block: "nearest",
        behavior: "smooth",
      });
    } else if (this.state === "ready") {
      eventBus.$emit("closingPermissionsUI");
      this.state = "cancelling";
    }
  }

  /**
   * User clicked the cancel (Take Me Back) button
   */
  clickCancelButton(): void {
    if (this.state === "ready") {
      this.state = "cancelling";
      this.$refs.modal.$emit("close");
      eventBus.$emit("closingPermissionsUI");
    } else if (this.state === "loading") {
      this.$refs.modal.$emit("close");
    }
  }

  /**
   * User clicked the Save button
   */
  clickSaveButton(): void {
    if (this.state !== "ready") {
      return;
    }
    if (!this.hasUnsavedChanges) {
      this.state = "cancelling";
      eventBus.$emit("closingPermissionsUI");
      this.$refs.modal.$emit("close");
      return;
    }
    if (this.permObjects === null) return;

    this.state = "saving";
    savePermissions(this.permObjects)
      .then(() => {
        this.errorMessages = null;
        this.state = "done";
        eventBus.$emit("closingPermissionsUI");
        this.$refs.modal.$emit("close");
      })
      .catch((err: string[] | Error) => {
        if (Array.isArray(err)) {
          this.errorMessages = err;
        } else {
          this.errorMessages = [err.message];
        }
        this.$refs.modal.scrollTop = 0;
        this.state = "ready";
      });
  }

  /**
   * User changed a user permission
   */
  changedUserPermission(userPerm: UserPerm, newPermission: string): void {
    userPerm.permission = newPermission;
    if (
      userPerm.original_permission !== null ||
      this.searchTargetObject === null
    ) {
      return;
    }

    const userPermIndex = this.searchTargetObject.userPerms.indexOf(userPerm);
    if (newPermission === null && userPermIndex !== -1) {
      // remove userPerm
      this.searchTargetObject.userPerms.splice(userPermIndex, 1);
    } else if (newPermission !== null && userPermIndex === -1) {
      // add userPerm
      this.searchTargetObject.userPerms.push(userPerm);
      this.searchTargetObject.userPerms.sort(userSortFunction);
    }
  }
}
