

























































import Vue from "vue";
import { ICompany } from "../types";
import { StripeAddressElement } from "@stripe/stripe-js";

export default Vue.extend({
  data: function () {
    return {
      loaded: false,
      company: this.$store.getters.company,
      addressElement: {} as StripeAddressElement,
      success: false,
      errorsFound: false,
      errorMessage: "",
    };
  },
  mounted: async function () {
    this.getCompanyInfo();
  },
  methods: {
    getCompanyInfo: function () {
      let localCompany = {} as ICompany;

      fetch("/graphql/", {
        method: "POST",
        body: JSON.stringify({
          query: `
            query CompanyData {
              company(id:${Number(this.$route.params.id)}) {
                contentObjectId
                canEdit
                name
                address1
                address2
                postalCode
                city
                state
                countryCode
                phone
                salesCompany
                whiteLabelCompany
                billing {
                  isBilledOffline
                }
              }
            }
          `,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.errors) {
            this.errorsFound = true;
            this.errorMessage = data.errors[0].message;
          } else {
            this.errorsFound = false;
            localCompany.id = data.data.company.contentObjectId;
            localCompany.customerNo = data.data.company.contentObjectId;
            localCompany.name = data.data.company.name;
            localCompany.canEdit = data.data.company.canEdit;
            localCompany.street1 = data.data.company.address1;
            localCompany.street2 = data.data.company.address2;
            localCompany.postalCode = data.data.company.postalCode;
            localCompany.city = data.data.company.city;
            localCompany.state = data.data.company.state;
            localCompany.countryCode = data.data.company.countryCode;
            localCompany.phone = data.data.company.phone;
            localCompany.isSalesCompany = data.data.company.salesCompany;
            localCompany.isWhiteLabelCompany =
              data.data.company.whiteLabelCompany;
            localCompany.isOnlineBillingCompany = data.data.company.billing
              ? !data.data.company.billing.isBilledOffline
              : null;
            this.company = localCompany;

            if (this.$store.getters.stripe) {
              // have to use any type on next line because Stripe's typescript support
              // is missing this type
              const appearance = {
                appearance: {
                  theme: "stripe",
                  variables: {
                    fontFamily:
                      "'Suisse', 'open sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
                    colorText: "#333333",
                    fontSizeBase: "13px",
                    fontSizeSm: "13px",
                    fontWeightNormal: 500,
                  },
                },
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
              } as any;

              const elements = this.$store.getters.stripe.elements(appearance);

              //const googleAPI = process.env.VUE_APP_GOOGLE_MAPS_KEY as string;

              if (elements) {
                // Create and mount the Address Element in shipping mode
                this.addressElement = elements.create("address", {
                  mode: "billing",
                  display: {
                    name: "organization",
                  },
                  // autocomplete: {
                  //   mode: "google_maps_api",
                  //   apiKey: googleAPI,
                  // },
                  fields: {
                    phone: "always",
                  },
                  defaultValues: {
                    name: data.data.company.name,
                    address: {
                      line1: data.data.company.address1,
                      line2: data.data.company.address2,
                      city: data.data.company.city,
                      state: data.data.company.state,
                      postal_code: data.data.company.postalCode,
                      country: data.data.company.countryCode,
                    },
                    phone: data.data.company.phone,
                  },
                });

                if (localCompany.canEdit) {
                  this.addressElement.mount("#address-element");
                }
              }
            }
          }
          this.loaded = true;
        });
    },
    saveChanges: function () {
      this.addressElement.getValue().then((value) => {
        if (value.complete) {
          //mutate the company here
          const query = `
                mutation updateCompany {
                  mutateCompany (input: {
                    id:"${this.company.id}",
                    name:"${value.value.name}",
                    address1: "${value.value.address.line1}",
                    address2: "${value.value.address.line2 ?? ""}",
                    city: "${value.value.address.city}",
                    state:"${value.value.address.state}",
                    postalCode: "${value.value.address.postal_code}",
                    countryCode:"${value.value.address.country}",
                    phone: "${value.value.phone}",
                  }) {
                    errors { messages }
                    company {
                      name
                    }
                  }
                }`;

          fetch("/graphql/", {
            method: "POST",
            body: JSON.stringify({
              query: query,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.errors) {
                alert(data.errors[0].message);
              } else if (data.data.mutateCompany?.errors.length > 0) {
                alert(data.data.mutateCompany.errors[0].messages.join(", "));
              } else {
                this.success = true;
              }
            });
        }
      });

      const company: ICompany = {
        /*
        id: this.$store.getters.company.id,
        customerNo: this.customerNo,
        name: this.name,
        street1: this.address1,
        street2: this.address2,
        postalCode: this.postalCode,
        city: this.city,
        state: this.state,
        countryCode: this.countryCode,
        discountCodeId: this.$store.getters.company.discountCodeId,
        currency: this.$store.getters.company.currency,
        */
        id: this.company.id,
        customerNo: this.company.customerNo,
        name: this.company.name,
        canEdit: this.company.canEdit,
        street1: this.company.address1,
        street2: this.company.address2,
        postalCode: this.company.postalCode,
        city: this.company.city,
        state: this.company.state,
        countryCode: this.company.countryCode,
        phone: this.company.phone,
        discountCodeId: this.company.discountCodeId,
        isSalesCompany: this.company.isSalesCompany,
        isWhiteLabelCompany: this.company.isWhiteLabelCompany,
        billingOwnerId: this.company.billingOwnerId,
        isOnlineBillingCompany: this.company.isOnlineBillingCompany,
      };
      this.$store.commit("setCompany", company);
    },
  },
  computed: {},
  watch: {},
});
