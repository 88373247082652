































































import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    /**
     * set the zoom percentage
     * @param {number} zoomValue - the zoom percentage
     */
    setZoom: function (zoomValue: number) {
      this.$store.commit("setZoomPercentage", zoomValue);
      this.$store.commit("setShowZoomMenu", false);
    },
    /**
     * zoom in clicked
     */
    zoomIn: function () {
      this.$store.commit("setZoomIndex", this.$store.getters.zoomIndex - 1);
      this.$store.commit(
        "setZoomPercentage",
        this.$store.getters.zoomFactors[this.$store.getters.zoomIndex]
      );
    },
    /**
     * zoom out clicked
     */
    zoomOut: function () {
      this.$store.commit("setZoomIndex", this.$store.getters.zoomIndex + 1);
      this.$store.commit(
        "setZoomPercentage",
        this.$store.getters.zoomFactors[this.$store.getters.zoomIndex]
      );
    },
    /**
     * fit all clicked
     */
    fitAll: function () {
      document.dispatchEvent(
        new KeyboardEvent("keydown", {
          key: "1",
          ctrlKey: true,
          bubbles: true,
          metaKey: true,
        })
      );
      this.$store.commit("setShowZoomMenu", false);
    },
    /**
     * fit selection clicked
     */
    fitSelection: function () {
      document.dispatchEvent(
        new KeyboardEvent("keydown", {
          key: "2",
          ctrlKey: true,
          bubbles: true,
          metaKey: true,
        })
      );
      this.$store.commit("setShowZoomMenu", false);
    },
    /**
     * zoom icon clicked
     */
    whenClicked: function () {
      this.$store.commit("setShowZoomMenu", !this.$store.getters.showZoomMenu);
    },
  },
  computed: {
    sortedFactors: function () {
      const newZoomFactors = this.$store.getters.zoomFactors.slice();
      return newZoomFactors.sort(function (a: number, b: number) {
        return a - b;
      });
    },
  },
});
