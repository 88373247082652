




import { Component, Prop, Vue } from "vue-property-decorator";

/**
 * A convenience component to set document.title
 */
@Component
export default class PageTitle extends Vue {
  /**
   * The desired window title string, will have ` - SignAgent` appended ("" is allowed)
   */
  @Prop({
    type: String,
    default: null,
  })
  readonly value!: string | null;

  /**
   * Set the document title, but don't create any elements
   */
  render(): null {
    if (!this.value) {
      document.title = "SignAgent";
    } else {
      document.title = `${this.value} - SignAgent`;
    }
    return null;
  }
}
