var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"panel-heading",staticStyle:{"background-color":"#fff"}},[(_vm.$store.getters.order.id !== '')?_c('div',{staticClass:"panel-group"},[_c('table',{staticClass:"table table-striped order-details-table"},[_vm._m(0),_c('tbody',{style:('max-height: ' + _vm.panelHeight + 'px')},[_vm._l((_vm.removedSignTypes()),function(orderItem){return [_c('tr',{key:orderItem.id},[_c('td',[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"padding":"5px","padding-top":"0px"}},[_c('i',{staticClass:"fa",class:orderItem.isRemoveExpanded
                            ? 'fa-caret-down'
                            : 'fa-caret-right',on:{"click":function($event){return _vm.$store.commit('toggleIsRemoveExpanded', orderItem)}}})]),_c('div',{staticStyle:{"flex-grow":"1"}},[_c('span',{staticStyle:{"padding":"5px","margin-right":"5px","text-align":"center"},style:({
                          backgroundColor: '#' + orderItem.hexColor,
                          color: _vm.getTextColor(orderItem.hexColor),
                        })},[_vm._v(_vm._s(orderItem.shortCode.trim()))]),_vm._v(" "+_vm._s(orderItem.name)+" ")])])])]),_c('div',{key:'detail' + orderItem.id,staticClass:"panel-body",class:orderItem.isRemoveExpanded ? '' : 'collapse',attrs:{"id":'panel_' + orderItem.id}},_vm._l((_vm.chunkSignsArray(
                    _vm.signsInReviewState(orderItem.signs),
                    _vm.gridSize
                  )),function(chunk){return _c('div',{key:chunk.itemId,staticClass:"row"},_vm._l((chunk),function(sign){return _c('div',{key:sign.id,class:'col-md-' + 12 / _vm.gridSize},[_c('div',{staticStyle:{"text-align":"left","padding":"5px","margin-bottom":"5px"},style:({
                        backgroundColor: '#' + orderItem.hexColor,
                        color: _vm.getTextColor(orderItem.hexColor),
                      })},[_vm._v(" "+_vm._s(sign.signId)+" ")]),_c('img',{staticStyle:{"width":"100%","border":"1px solid #e8e8e8"},attrs:{"src":'/sign/' + sign.id + '/svg_as_png/',"alt":sign.id},on:{"click":function($event){return _vm.showDetails(orderItem.signs, sign.signId)}}}),(
                        _vm.$store.getters.order.status === _vm.ORDER_STATUS.DRAFT
                      )?_c('i',{staticClass:"fa fa-plus",staticStyle:{"font-size":"20px","position":"absolute","top":"-10px","right":"0px"},on:{"click":function($event){return _vm.toggleReviewState(sign)}}}):_vm._e()])}),0)}),0)]})],2)])]):_vm._e()])]),_c('sign-detail-modal',{attrs:{"signs":_vm.selectedItems,"selectedSignId":_vm.selectedSignId,"caller":"order"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Product")])])])}]

export { render, staticRenderFns }