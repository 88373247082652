








































































































import Vue from "vue";
import { PropType } from "vue";
import { IDashboardCompany, IDashboardProject, IDashboardSign } from "../types";

import UserPopupWidget from "./UserPopupWidget.vue";

export default Vue.extend({
  components: { UserPopupWidget },
  props: {
    project: {
      type: Object as PropType<IDashboardProject>,
      required: true,
    },
  },
  data: function () {
    return {};
  },
  methods: {
    favoriteProject: function () {
      //first update the Vue data
      // if project is already favorited then unfavorite it,
      if (this.isFavorite) {
        const index = this.project.favoriteUsers.findIndex((user) => {
          return user.userId === this.$store.state.session.userId;
        });
        if (index !== -1) {
          this.project.favoriteUsers.splice(index, 1);
        }
      } else {
        if (this.project.favoriteUsers) {
          this.project.favoriteUsers.push({
            userId: this.$store.state.session.userId,
          });
        } else {
          this.project.favoriteUsers = [this.$store.state.session.userId];
        }
      }
      //update the backend
      fetch("/project/" + this.project.id + "/favorite/", {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
      }).then((data) => data.json());
    },
    archiveProject: function (e: Event) {
      e.preventDefault();
      //first update the Vue data
      // toggle the archive flag
      this.project.archived = !this.project.archived;
      //update the backend
      fetch("/project/" + this.project.id + "/archive/", {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
      }).then((data) => data.json());
    },
    /**
     * determine if project has any sign in it
     * @returns boolean - true if there are no signs in the project
     */
    projectHasNoSigns: function (): boolean {
      let count = 0;

      this.project.states.forEach((state) => {
        count += state.signs.length;
      });

      if (count === 0) {
        return true;
      } else {
        return false;
      }
    },
    isBuilder: function (): boolean {
      if (
        ["1", "2"].includes(this.project.type) &&
        this.hasChangePermProject()
      ) {
        return true;
      } else {
        return false;
      }
    },
    hasChangePermProject: function (): boolean {
      return this.project.canEdit;
    },
    phaseNameURL: function (): string {
      if (this.isBuilder()) {
        return "/builder/kanban/" + this.project.id + "/";
      } else if (this.firstSignNeedingReview()) {
        return "/sign/" + this.firstSignNeedingReview()?.id + "/edit/";
      } else {
        return "/project/" + this.project.id + "/edit/";
      }
    },
    firstSignNeedingReview: function (): IDashboardSign | null {
      for (const state of this.project.states) {
        for (const sign of state.signs) {
          if (sign.reviewState === "N") {
            return sign;
          }
        }
      }

      return null;
    },
    membersAndViewers: function (companies: Array<IDashboardCompany>) {
      return companies.filter((company) =>
        company.groups.some(
          (group) =>
            group.name.toLowerCase() === "member" ||
            group.name.toLowerCase() === "assigned"
        )
      );
    },
  },
  computed: {
    isFavorite: function (): boolean {
      return this.project.favoriteUsers.some((user) => {
        return user.userId === this.$store.state.session.userId;
      });
    },
  },
});
