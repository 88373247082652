












































import Vue from "vue";

import { STATE_TYPE } from "@/features/SmartStates/utilities";

export default Vue.extend({
  components: {},
  data: function () {
    return {
      showDetails: false,
      showModal: false,
      hover: false,
      showEditor: false,
      originalName: "",
      saveError: false,
      saveSuccess: false,
      errorMessage: "",
    };
  },
  props: {
    column: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    startEditor: function () {
      this.originalName = this.column.stateName;
      this.showEditor = true;
    },
    saveEditor: function () {
      // TODO: update the state through graphQL
      const query = JSON.stringify({
        query: `
          mutation StateMutation{
            mutateState(input:{
              id: ${this.column.stateId},
              stateType: "${STATE_TYPE.DESIGN}",
              workflow: "${this.column.workflow}",
              name: "${this.column.stateName}",
              hexColor: "${this.column.hexColor}",
              approved: "Approved",
              rejected: "Rejected",
              orderId: ${this.column.orderId},
              needsReviewed: "Review"}) {
                state{
                  id: contentObjectId
                }
              }
            }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.errors) {
            this.$emit("error", result.errors.message);
          } else {
            this.$emit("saved", "Saved");
            location.reload();
          }
        });
      this.showEditor = false;
    },
    cancelEditor: function () {
      this.column.stateName = this.originalName;
      this.showEditor = false;
    },
    removeColumn: function () {
      // delete the state using graphQL
      if (this.column.signTypes.length > 0) {
        this.$emit("error", "Cannot delete a column that has signs in it.");
      } else {
        const query = JSON.stringify({
          query: `mutation deleteState {
            deleteState(input:{id:${this.column.stateId}}) {
              success
            }
          }`,
        });

        fetch("/graphql/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: query,
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.errors) {
              this.$emit("error", result.errors[0].message);
            } else {
              this.$emit("saved", "Saved");
              location.reload();
            }
          });
      }
    },
  },
  computed: {},
  watch: {
    showEditor: function (newValue: boolean) {
      if (newValue) {
        this.$nextTick(() => {
          (this.$refs.columnName as HTMLElement).focus();
        });
      }
    },
  },
});
