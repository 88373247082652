



































import Vue from "vue";
import { PropType } from "vue";

import $ from "jquery";

import EditPaymentInfoModal from "../modals/EditPaymentInfoModal.vue";

import { IPaymentMethod, IMember } from "../types";

export default Vue.extend({
  props: {
    companyId: {
      type: Number,
    },
    paymentMethods: {
      type: Array as PropType<IPaymentMethod[]>,
    },
  },
  components: {
    EditPaymentInfoModal,
  },
  data: function () {
    return {};
  },
  methods: {
    editPaymentInfo: function () {
      $("#edit-payment-info-modal").modal({ show: true });
    },
    /**
     * get the signature to use on the mailto: links
     * @returns string - containing the signature in HTML-entity format
     */
    currentUserSignature: function () {
      // find the user if they are part of the current company
      const user = this.$store.getters.userById(
        this.$store.state.session.userId
      );
      if (user) {
        // found a user so return the signature
        return `${user.firstName} ${user.lastName}%0D%0A${user.email}%0D%0A`;
      } else {
        // the current user is a super user so send this request from the billing owner
        // of the current company
        const user = this.$store.getters.users.find((user: IMember) => {
          return user.role === "BILLING_OWNER";
        });
        if (user) {
          return `${user.firstName} ${user.lastName}%0D%0A${user.email}%0D%0A`;
        } else {
          // no billing owner was found so return Unknown user
          return `Unknown user`;
        }
      }
    },
  },
  computed: {},
  watch: {},
});
