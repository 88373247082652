




































//import { iSignChild } from "../../types";
import Vue from "vue";
import { IProductFamily, IProductCategory, IProductType } from "../../types";
// import CollapsibleSection from "../CollapsibleSection.vue";
// import PriceSection from "../panelsections/PriceSection.vue";
// import ProductCategorySection from "../panelsections/ProductCategorySection.vue";
// import ProductFamilySection from "../panelsections/ProductFamilySection.vue";
// import SidesSection from "../panelsections/SidesSection.vue";
// import SigntypeSection from "../panelsections/SigntypeSection.vue";
import Marketplace from "../Marketplace.vue";

export default Vue.extend({
  name: "assets-panel",
  components: {
    // CollapsibleSection,
    // PriceSection,
    // ProductCategorySection,
    // ProductFamilySection,
    // SidesSection,
    // SigntypeSection,
    Marketplace,
  },
  props: {
    signData: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      showModal: false,
    };
  },
  methods: {
    familyClicked: function (family: IProductFamily) {
      this.$store.commit("toggleSelectedProductFamilyById", family);
    },
    categoryClicked: function (category: IProductCategory) {
      this.$store.commit("toggleSelectedProductCategoryById", category);
    },
    signtypeClicked: function (type: IProductType) {
      this.$store.commit("toggleSelectedProductTypeById", type);
    },
    /**
     * close the modal
     * @param {Event} e - the event that triggered the close
     */
    closeModal: function (e: Event) {
      e.preventDefault();
      e.stopPropagation();
      this.$store.commit("setShowMarketPlaceModal", false);
    },
  },
  computed: {},
  watch: {
    /**
     * when showFileModal from the store changes
     * @param {boolean} newValue - the value of the showFileModal from the store
     */
    "$store.state.signDesigner.showMarketPlaceModal": function (
      newValue: boolean
    ) {
      //   const this = this;
      this.showModal = newValue;
    },
  },
});
