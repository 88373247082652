


















































































































































import {
  IProductCategory,
  IProductFamily,
  IProductType,
  iCatalog,
} from "@/features/SignDesigner/types";
import Vue from "vue";

export default Vue.extend({
  data: function () {
    return {};
  },
  methods: {
    removeFamily: function (family: IProductFamily) {
      this.$store.commit("deselectProductFamilyByUUID", family.uuid);
    },
    removeCategory: function (category: IProductCategory) {
      this.$store.commit("deselectProductCategoryByUUID", category.uuid);
    },
    removeType: function (type: IProductType) {
      this.$store.commit("deselectProductTypeByUUID", type.uuid);
    },
    typeClicked: function (type: IProductType) {
      this.$store.commit("selectProductTypeByUUID", type);
    },
    categoryClicked: function (category: IProductCategory) {
      this.$store.commit("selectProductCategoryByUUID", category);
    },
    /**
     * return a portion of an array
     * @param {Array<iCatalog>} array - the full array
     * @param {number} size - the number of elements per "chunk"
     * @returns {Array<iCatalog>} an array of iCatalog that is "size" elements long
     */
    chunkArray: function (array: Array<iCatalog>, size: number) {
      let result = [];
      for (let i = 0; i < array.length; i += size) {
        let chunk = array.slice(i, i + size);
        result.push(chunk);
      }
      return result;
    },
    showDetailsForItem(item: iCatalog) {
      console.log("family page item clicked");
      console.log(item);
      this.$store.commit("selectProductByUUID", item);
      console.log(this.$store.getters.selectedProduct);
      this.$nextTick(function () {
        this.$store.commit("setShowProductModal", true);
      });
    },
    getResponse: function (url: string): string {
      let resultData = "empty";

      const base64 = this.getImageBase64(url);
      base64.then((result) => {
        resultData = result;
      });
      return resultData;
      //   const result = this.getImageBase64(url);

      //   result.then((response) => {
      //     return response.Data;
      //   });
    },
    getImageBase64: async function (url: string): Promise<string> {
      // const response = await fetch(url);
      // const blob = await response.blob();
      // return new Promise((onSuccess, onError) => {
      //   try {
      //     const reader = new FileReader();
      //     reader.onload = function () {
      //       onSuccess(this.result);
      //     };
      //     reader.readAsDataURL(blob);
      //   } catch (e) {
      //     onError(e);
      //   }
      // });
      let dataString = "";
      await fetch(url)
        .then((response) => {
          return response.json();
        })
        .then(
          (data: {
            Message: string;
            Status: string;
            StatusCode: number;
            Data: string;
          }) => {
            dataString = data.Data;
          }
        );
      return dataString;
    },
  },
  computed: {
    filteredProductCategories: function (): Array<IProductCategory> {
      let result = [];
      for (let i = 0; i < this.$store.getters.productCategories.length; i++) {
        let category = this.$store.getters.productCategories[i];
        for (let j = 0; j < this.$store.getters.productFamilies.length; j++) {
          const family = this.$store.getters.productFamilies[j];
          if (family.isSelected) {
            if (category.familyIds.includes(family.uuid)) {
              result.push(category);
            }
          }
        }
      }
      return result;
    },
    filteredProductTypes: function (): Array<IProductType> {
      let result = [];
      if (this.$store.getters.isProductCategorySelected) {
        for (let i = 0; i < this.$store.getters.productTypes.length; i++) {
          let type = this.$store.getters.productTypes[i];
          for (
            let j = 0;
            j < this.$store.getters.productCategories.length;
            j++
          ) {
            const category = this.$store.getters.productCategories[j];
            if (category.isSelected) {
              if (type.categoryIds.includes(category.uuid)) {
                result.push(type);
              }
            }
          }
        }
      } else {
        result = this.$store.getters.productTypes;
      }
      return result;
    },
    filteredCatalog: function () {
      let result = [] as Array<iCatalog>;

      for (let i = 0; i < this.$store.getters.catalog.length; i++) {
        let item = this.$store.getters.catalog[i];
        if (this.$store.getters.isProductTypeSelected) {
          if (
            this.$store.getters.isProductFamilySelected &&
            this.$store.getters.isProductCategorySelected
          ) {
            if (
              this.$store.getters.selectedProductFamilyNames.includes(
                item.family
              ) &&
              this.$store.getters.selectedProductCategoryNames.includes(
                item.category
              ) &&
              this.$store.getters.selectedProductTypeNames.includes(item.type)
            ) {
              result.push(item);
            }
          } else if (this.$store.getters.isProductFamilySelected) {
            if (
              this.$store.getters.selectedProductFamilyNames.includes(
                item.family
              ) &&
              this.$store.getters.selectedProductTypeNames.includes(item.type)
            ) {
              result.push(item);
            }
          } else if (this.$store.getters.isProductCategorySelected) {
            if (
              this.$store.getters.selectedProductCategoryNames.includes(
                item.category
              ) &&
              this.$store.getters.selectedProductTypeNames.includes(item.type)
            ) {
              result.push(item);
            }
          } else {
            if (
              this.$store.getters.selectedProductTypeNames.includes(item.type)
            ) {
              result.push(item);
            }
          }
        } else if (this.$store.getters.isProductCategorySelected) {
          if (this.$store.getters.isProductFamilySelected) {
            if (
              this.$store.getters.selectedProductFamilyNames.includes(
                item.family
              ) &&
              this.$store.getters.selectedProductCategoryNames.includes(
                item.category
              )
            ) {
              result.push(item);
            }
          } else {
            if (
              this.$store.getters.selectedProductCategoryNames.includes(
                item.category
              )
            ) {
              result.push(item);
            }
          }
        } else if (this.$store.getters.isProductFamilySelected) {
          if (
            this.$store.getters.selectedProductFamilyNames.includes(item.family)
          ) {
            result.push(item);
          }
        } else {
          result.push(item);
        }

        // for (let j = 0; j < this.$store.getters.productTypes.length; j++) {
        //   const type = this.$store.getters.productTypes[j];
        //   if (type.isSelected) {
        //     if (item.typeIds.includes(type.uuid)) {
        //       result.push(item);
        //     }
        //   }
        // }
      }

      return result;
    },
    families: function () {
      let result = [] as Array<IProductFamily>;
      this.$store.getters.productFamilies.forEach((family: IProductFamily) => {
        if (family.isSelected) {
          result.push(family);
        }
      });

      return result;
    },
    categories: function () {
      let result = [] as Array<IProductCategory>;
      this.$store.getters.productCategories.forEach(
        (category: IProductCategory) => {
          if (category.isSelected) {
            result.push(category);
          }
        }
      );

      return result;
    },
    types: function () {
      let result = [] as Array<IProductType>;
      this.$store.getters.productTypes.forEach((type: IProductType) => {
        if (type.isSelected) {
          result.push(type);
        }
      });

      return result;
    },
    family: function () {
      return this.$store.getters.selectedProductFamily;
    },
    categoryCount: function () {
      const cats: Array<IProductCategory> = this.filteredProductCategories;
      return cats.length;
    },
    typeCount: function () {
      const types: Array<IProductType> = this.filteredProductTypes;
      return types.length;
    },
    itemCount: function () {
      const catalog: Array<iCatalog> = this.filteredCatalog;
      return catalog.length;
    },
  },
  watch: {},
});
