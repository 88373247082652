import { Module } from "vuex";
import {
  getConversionForUnit,
  uuidv4,
  alignText,
  updateSVGDataName,
  removeSelectionHandles,
  calculateRepeatSize,
  saveState,
  getNoFillNoStrokeByElementId,
  isEmpty,
  convertUnitToPoints,
  updateSVGAttribute,
  hexString,
  convertPointsToUnit,
} from "./utilities";
//import Vue from "vue";

import {
  Component,
  iColor,
  IFonts,
  iSignData,
  iVisual,
  iSignChild,
  IField,
  IFile,
  IImage,
  IPart,
  IMaterial,
  IRepeat,
  ISignType,
  iMessage,
  IAlignment,
  IProcess,
  IMember,
  IColorLibrary,
  IColorItem,
  ISignMaterial,
  ISignProcess,
  IProductFamily,
  IProductCategory,
  IProductType,
  ITranslationLanguage,
  iCatalog,
  ITemplate,
  IFeature,
  IHistory,
  IMarketplaceItem,
} from "./types";
import { RootState } from "../../store";
import Snap from "snapsvg-cjs-ts";
import { normalCaseToUnderscore } from "./include/Utils";

export interface SignDesignerState {
  canvas: Snap.Element;
  user: IMember;
  organizationId: string;
  selectedElements: Array<unknown>;
  selectedElementIds: string[];
  components: Array<{ isActive: boolean }>;
  fields: Array<IField>;
  files: Array<IFile>;
  images: Array<IImage>;
  parts: Array<IPart>;
  materials: Array<IMaterial>;
  signMaterials: Array<ISignMaterial>;
  processes: Array<IProcess>;
  signProcesses: Array<ISignProcess>;
  repeats: Array<IRepeat>;
  nextRepeatNumber: number;
  zoomPercentage: 0;
  activeTab: string;
  sidesTab: number;
  dataFields: Array<unknown>;
  treeData: Record<string, unknown>;
  componentData: Component;
  rerender: boolean;
  areElementsSelected: boolean;
  showMaterialsModal: boolean;
  showProcessesModal: boolean;
  showColorsModal: boolean;
  showAddColorsModal: boolean;
  showPickColorsModal: boolean;
  showAddSignTypeModal: boolean;
  showAddAlignmentModal: boolean;
  showPublishModal: boolean;
  showAddFieldModal: boolean;
  showAddFontModal: boolean;
  showAddSideModal: boolean;
  showFileModal: boolean;
  showImageModal: boolean;
  showConfirmModal: boolean;
  showSignTypesConfirmModal: boolean;
  showMissingFontsModal: boolean;
  showProductFamiliesModal: boolean;
  showProductCategoriesModal: boolean;
  showProductTypesModal: boolean;
  showProductModal: boolean;
  showLoadingSpinner: boolean;
  signData: iSignData;
  currentSide: number;
  messages: [];
  repeatingMessages: [];
  detailFields: [];
  activePanel: string;
  steps: number;
  fonts: IFonts;
  colors: Array<iColor>;
  visuals: Array<iVisual>;
  currentOrganizationUUID: string;
  currentSignTypeUUID: string;
  previousSignTypeUUID: string;
  signTypes: Array<ISignType>;
  signTypeName: string;
  alignments: Array<IAlignment>;
  signCount: number;
  isDraft: boolean;
  isChanged: boolean;
  productFamilies: Array<IProductFamily>;
  productCategories: Array<IProductCategory>;
  productTypes: Array<IProductType>;
  colorLibraries: Array<IColorLibrary>;
  translationLanguages: Array<ITranslationLanguage>;
  catalog: Array<iCatalog>;
  templates: Array<ITemplate>;
  zoomFactors: Array<number>;
  zoomIndex: number;
  showZoomMenu: boolean;
  showAddVisualsModal: boolean;
  showUnsupportedFeatureModal: boolean;
  unsupportedFeature: string;
  featureAccess: Array<IFeature>;
  features: Array<string>;
  showFeatureAccessModal: boolean;
  trackAddedField: boolean;
  addedFieldId: string;
  history: Array<IHistory>;
  redoHistory: Array<IHistory>;
  exitAfterPublish: boolean;
  showHistoryModal: boolean;
  showMarketPlaceModal: boolean;
  processingUndo: boolean;
  marketplaceConnections: Array<IMarketplaceItem>;
  processingRedo: boolean;
  commands: string;
}

const store: Module<SignDesignerState, RootState> = {
  state: (): SignDesignerState => ({
    canvas: {} as Snap.Element,
    user: {
      id: 0,
      firstName: "",
      lastName: "",
      email: "",
      userType: "builder",
    },
    organizationId: "",
    selectedElements: [],
    selectedElementIds: [],
    components: [],
    fields: [],
    files: [],
    images: [],
    parts: [],
    materials: [
      {
        id: "20020",
        uuid: "20-202-02-00",
        label: "Solid Paint",
        libraries: [
          {
            id: "20020",
            label: "Pantone",
            description: "",
            code: "pms",
            publish: "C",
            colors: [
              {
                uuid: "123-123-123",
                id: "1",
                name: "RAF Blue",
                description: "",
                gloss: "",
                included: true,
                hexCode: "#0176bc",
              },
              {
                uuid: "234-234-234",
                id: "2",
                name: "Red",
                description: "",
                gloss: "",
                included: false,
                hexCode: "#FF0000",
              },
            ],
          },
          {
            id: "20021",
            label: "Matthews",
            description: "",
            code: "matthews",
            publish: "C",
            colors: [],
          },
        ],
        supplier: null,
      },
      {
        id: "20028",
        uuid: "20-202-02-01",
        label: "Solid Paint + Ink",
        libraries: [],
        supplier: null,
      },
      {
        id: "2112",
        uuid: "20-202-02-02",
        label: "Metallic Paint",
        libraries: [],
        supplier: null,
      },
      {
        id: "2113",
        uuid: "20-202-02-03",
        label: "Aluminum",
        libraries: [],
        supplier: null,
      },
      {
        id: "2114",
        uuid: "20-202-02-04",
        label: "Acrylic",
        libraries: [],
        supplier: null,
      },
      {
        id: "20021",
        uuid: "20-202-02-05",
        label: "Paperflex",
        libraries: [],
        supplier: null,
      },
      {
        id: "20022",
        uuid: "20-202-02-06",
        label: "Marker Board",
        libraries: [],
        supplier: null,
      },
      {
        id: "20023",
        uuid: "20-202-02-07",
        label: "Sintra, 1mm",
        libraries: [],
        supplier: null,
      },
      {
        id: "20024",
        uuid: "20-202-02-08",
        label: "Rowmark Acrylic",
        libraries: [],
        supplier: null,
      },
      {
        id: "20025",
        uuid: "20-202-02-09",
        label: "Sealant (Clean Room)",
        libraries: [],
        supplier: null,
      },
      {
        id: "20026",
        uuid: "20-202-02-10",
        label: "Vinyl (Opaque)",
        libraries: [],
        supplier: null,
      },
      {
        id: "20027",
        uuid: "20-202-02-11",
        label: "Vinyl (Translucent)",
        libraries: [],
        supplier: null,
      },
    ],
    signMaterials: [],
    processes: [
      {
        id: "",
        uuid: "4fb12917-602e-42f1-9127-767af7ba7c7a",
        label: "Tactile",
        description: "",
        sourceMaterial: "20-202-02-08",
        targetMaterials: [{ id: "20-202-02-03", label: "Aluminum" }],
        exportLayer: "20 Tactile",
      },
      {
        id: "",
        uuid: "202877ea-064f-4eb7-abbf-2768e9fac03f",
        label: "Braille",
        description: "",
        sourceMaterial: "20-202-02-08",
        targetMaterials: [{ id: "20-202-02-03", label: "Aluminum" }],
        exportLayer: "",
      },
      {
        id: "",
        uuid: "c6a47d4e-a54d-41ab-8cc6-968687adfdb4",
        label: "Eco Print",
        description: "",
        sourceMaterial: "20-202-02-00",
        targetMaterials: [
          { id: "20-202-02-03", label: "Aluminum" },
          { id: "20-202-02-04", label: "Acrylic" },
        ],
        exportLayer: "29 ECO Print",
      },
      {
        id: "",
        uuid: "2129b9e0-897e-4593-a423-41859c3c79fb",
        label: "ECO Print White 1",
        description: "",
        sourceMaterial: "20-202-02-00",
        targetMaterials: [
          { id: "20-202-02-03", label: "Aluminum" },
          { id: "20-202-02-04", label: "Acrylic" },
        ],
        exportLayer: "30 ECO Print White 1",
      },
      {
        id: "",
        uuid: "ecbc8fd6-c6aa-4e14-96a3-55aee1318200",
        label: "UV Print Panel",
        description: "",
        sourceMaterial: "",
        targetMaterials: [{ id: "20-202-02-03", label: "Aluminum" }],
        exportLayer: "31 UV Print Panel",
      },
      {
        id: "",
        uuid: "da8dd7a4-6a9f-4c0e-86f0-eb37f6976e89",
        label: "UV Print Panel White 1",
        description: "",
        sourceMaterial: "",
        targetMaterials: [{ id: "20-202-02-03", label: "Aluminum" }],
        exportLayer: "32 UV Print Panel White 1",
      },
      {
        id: "",
        uuid: "13268996-29ff-4c91-89a4-0cbac95c8d17",
        label: "UV Print Varnish",
        description: "",
        sourceMaterial: "20-202-02-00",
        targetMaterials: [{ id: "20-202-02-03", label: "Aluminum" }],
        exportLayer: "33 UV Print Varnish",
      },
      {
        id: "",
        uuid: "b60ba2b6-a244-484c-8c91-f30b58abf94f",
        label: "Vinyl",
        description: "",
        sourceMaterial: "",
        targetMaterials: [
          { id: "20-202-02-10", label: "Vinyl (Opaque)" },
          { id: "20-202-02-11", label: "Vinyl (Translucent)" },
        ],
        exportLayer: "50 Vinyl",
      },
    ],
    signProcesses: [],
    repeats: [],
    nextRepeatNumber: 1,
    zoomPercentage: 0,
    activeTab: "",
    sidesTab: 0,
    dataFields: [],
    treeData: {},
    componentData: {} as Component,
    rerender: false,
    areElementsSelected: false,
    showMaterialsModal: false,
    showProcessesModal: false,
    showColorsModal: false,
    showAddColorsModal: false,
    showPickColorsModal: false,
    showAddSignTypeModal: false,
    showAddAlignmentModal: false,
    showPublishModal: false,
    showAddFieldModal: false,
    showAddFontModal: false,
    showAddSideModal: false,
    showFileModal: false,
    showImageModal: false,
    showConfirmModal: false,
    showSignTypesConfirmModal: false,
    showMissingFontsModal: false,
    showProductFamiliesModal: false,
    showProductCategoriesModal: false,
    showProductTypesModal: false,
    showProductModal: false,
    showLoadingSpinner: false,
    signData: {
      id: "",
      uuid: "",
      folderId: "",
      libraryId: "",
      name: "",
      shortCode: "test",
      details: "",
      hexColor: "",
      unitCost: "",
      numberOfSides: 0,
      numberOfColumns: 0,
      numberOfMessages: 0,
      isDirectional: false,
      markerId: "",
      useScaleInMessageSchedule: false,
      scale: 0,
      publish: false,
      markerNoScalePercent: 0,
      markerScaleId: "",
      markerScaleWidth: 0,
      markerScaleWidthUnit: "",
      price: "",
      priceCurrency: "",
      areSidesIdentical: false,
      width: 0,
      height: 0,
      measurementUnit: { unit: "", conversion: 0 },
      templateName: "",
      svgUrl: "",
      sides: [{ id: "", name: "", children: [] }],
    },
    currentSide: 0,
    messages: [],
    repeatingMessages: [],
    detailFields: [],
    activePanel: "layers",
    steps: 1,
    fonts: {},
    colors: [{ id: "", name: "", hex: "", rgb: { r: 0, g: 0, b: 0 } }],
    visuals: [
      {
        id: "",
        name: "",
        imageUrl: "",
        isMarker: false,
        useDarkBackground: false,
      },
    ],
    currentOrganizationUUID: "",
    currentSignTypeUUID: "empty", // need this set to something other than empty so the initial change event is triggered
    previousSignTypeUUID: "empty",
    signTypes: [],
    signTypeName: "Untitled",
    alignments: [],
    signCount: 0,
    isDraft: false,
    isChanged: false,
    productFamilies: [],
    productCategories: [],
    productTypes: [],
    colorLibraries: [],
    translationLanguages: [],
    catalog: [],
    templates: [],
    zoomFactors: [
      64000, 51000, 34000, 25500, 17000, 12750, 8500, 6400, 4800, 3200, 2400,
      1600, 1200, 800, 600, 400, 300, 200, 150, 100, 66.67, 50, 33.33, 25,
      16.67, 12.5, 8.33, 6.25, 4.17, 3.13,
    ],
    zoomIndex: 19,
    showZoomMenu: false,
    showAddVisualsModal: false,
    showUnsupportedFeatureModal: false,
    unsupportedFeature: "",
    featureAccess: [
      { feature: "admin", user: "bernie@signagent.com" },
      { feature: "admin", user: "nicholas@signagent.com" },
      { feature: "marketplace", user: "nicholas@signagent.com" },
      { feature: "marketplace", user: "bernie@signagent.com" },
      { feature: "multi-sided signs", user: "bernie.signagent.com" },
      { feature: "multi-sided signs", user: "nicholas@signagent.com" },
      { feature: "history", user: "bernie@signagent.com" },
      { feature: "history", user: "dev@signagent.com" },
      { feature: "history", user: "vivek@signagent.com" },
      { feature: "repeats", user: "bernie@signagent.com" },
      { feature: "repeats", user: "*@signagent.com" },
      { feature: "marketplace", user: "*@signagent.com" },
    ],
    features: [
      "marketplace",
      "admin",
      "multi-sided signs",
      "repeats",
      "history",
    ],
    showFeatureAccessModal: false,
    trackAddedField: false,
    addedFieldId: "",
    history: [],
    redoHistory: [],
    exitAfterPublish: false,
    showHistoryModal: false,
    showMarketPlaceModal: false,
    processingUndo: false,
    marketplaceConnections: [],
    processingRedo: false,
    commands: "",
  }),

  mutations: {
    setState(state, payload) {
      $.extend(true, state, payload);
      // state = JSON.parse(JSON.stringify(payload));
    },
    //eslint-disable-next-line
    captureState(state, payload : {description: string; undoMutations: Array<{mutationName: string, undoValue: any}>, redoMutations: Array<{mutationName: string; redoValue: any}>}) {
      // saveState(state, $("#signdesigner_snap").html(), mutationName);
      saveState(
        state,
        payload.description,
        payload.undoMutations,
        payload.redoMutations
      );
    },
    setProcessingUndo(state, payload) {
      state.processingUndo = payload;
    },
    setProcessingRedo(state, payload) {
      state.processingRedo = payload;
    },
    setCanvas(state, payload) {
      state.canvas = payload;
    },
    updateCanvasPaper(state, payload) {
      const snap = state.canvas.select("*");
      snap.paper = payload;
    },
    // addSelectedElement(state, payload) {
    //   state.selectedElements.push(payload);
    // },
    // clearSelectedElements(state) {
    //   state.selectedElements = [];
    // },
    setUser(state, payload) {
      state.user = payload;
    },
    setOrganizationId(state, payload) {
      state.organizationId = payload;
    },
    addComponent(state, payload) {
      state.components.forEach(function (component) {
        component.isActive = false;
      });

      payload.isActive = true;
      saveState(
        state,
        "Add Component",
        [
          {
            mutationName: "setComponents",
            undoValue: state.components,
          },
        ],
        [{ mutationName: "addComponent", redoValue: payload }]
      );
      // saveState(state, $("#signdesigner_snap").html(), "Add Component");

      state.components.push(payload);
    },
    setComponents(state, payload) {
      state.components = payload;
    },
    clearComponents(state) {
      state.components = [];
    },
    // setField(state, payload) {
    //   state.fields.push({'name': payload.name, 'id': payload.id})
    // },
    setZoomPercentage(state, payload) {
      state.zoomPercentage = payload;
    },
    setActiveTab(state, payload) {
      state.activeTab = payload;
    },
    setSidesTab(state, payload) {
      state.sidesTab = payload;
    },
    // addDataField(state, payload ) {
    //   state.dataFields.push(payload)
    // },
    setTreeData(state, payload) {
      state.treeData = payload;
    },
    // updateTreeDataByIndex(state, index: number, payload: any) {
    //   state.treeData[index] = payload
    // },
    setComponentData(state, payload) {
      state.componentData = payload;
    },
    setRerender(state, payload) {
      state.rerender = payload;
    },
    setAreElementsSelected(state, payload) {
      state.areElementsSelected = payload;
    },
    setCatalog(state, payload) {
      state.catalog = payload;
    },
    updateCatalogByUUID(state, payload: iCatalog) {
      for (let i = 0; i < state.catalog.length; i++) {
        const item = state.catalog[i];
        if (item.uuid === payload.uuid) {
          state.catalog[i] = payload;
          break;
        }
      }
    },
    setTemplates(state, payload) {
      state.templates = payload;
    },
    inactivateAllComponents(state) {
      state.components.forEach(function (component) {
        component.isActive = false;
      });
    },
    setShowMaterialsModal(state, payload) {
      state.showMaterialsModal = payload;
    },
    setShowProcessesModal(state, payload) {
      state.showProcessesModal = payload;
    },
    setShowColorsModal(state, payload) {
      state.showColorsModal = payload;
    },
    setShowAddColorsModal(state, payload) {
      state.showAddColorsModal = payload;
    },
    setShowPickColorsModal(state, payload) {
      state.showPickColorsModal = payload;
    },
    setShowAddSignTypeModal(state, payload) {
      state.showAddSignTypeModal = payload;
    },
    setShowAddAlignmentModal(state, payload) {
      state.showAddAlignmentModal = payload;
    },
    setShowPublishModal(state, payload) {
      state.showPublishModal = payload;
    },
    setShowFileModal(state, payload) {
      state.showFileModal = payload;
    },
    setShowConfirmModal(state, payload) {
      state.showConfirmModal = payload;
    },
    setShowSignTypesConfirmModal(state, payload) {
      state.showSignTypesConfirmModal = payload;
    },
    setShowMissingFontsModal(state, payload) {
      state.showMissingFontsModal = payload;
    },
    setShowProductFamiliesModal(state, payload) {
      state.showProductFamiliesModal = payload;
    },
    setShowProductCategoriesModal(state, payload) {
      state.showProductCategoriesModal = payload;
    },
    setShowProductTypesModal(state, payload) {
      state.showProductTypesModal = payload;
    },
    setShowImageModal(state, payload) {
      state.showImageModal = payload;
    },
    setShowAddFieldModal(state, payload) {
      state.showAddFieldModal = payload;
    },
    setShowAddFontModal(state, payload) {
      state.showAddFontModal = payload;
    },
    setShowAddSideModal(state, payload) {
      state.showAddSideModal = payload;
    },
    setShowProductModal(state, payload) {
      state.showProductModal = payload;
    },
    setShowLoadingSpinner(state, payload) {
      state.showLoadingSpinner = payload;
    },
    addColor(state, payload) {
      state.colors.push(payload);
    },
    setSignData(state, payload) {
      state.signData = payload;
    },
    setSignDataShortCode(state, payload) {
      saveState(
        state,
        "Update Short Code",
        [
          {
            mutationName: "setSignDataShortCode",
            undoValue: state.signData.shortCode,
          },
        ],
        [{ mutationName: "setSignDataShortCode", redoValue: payload }]
      );
      // saveState(state, $("#signdesigner_snap").html(), "Set Sign Short Code");
      // update the signData and maintain reactivity
      state.signData = { ...state.signData, shortCode: payload };
      // state.signData.shortCode = payload;
    },
    setSignDataName(state, payload) {
      saveState(
        state,
        "Set Sign Name",
        [
          {
            mutationName: "setSignDataName",
            undoValue: state.signData.name,
          },
        ],
        [{ mutationName: "setSignDataName", redoValue: payload }]
      );
      // saveState(state, $("#signdesigner_snap").html(), "Set Sign Name");
      state.signData = { ...state.signData, name: payload };
    },
    setSignDataTemplateName(state, templateName: string) {
      state.signData.templateName = templateName;
    },
    addSideToSignData(state, payload) {
      const signData = { ...state.signData };

      signData.sides.push({
        id: "",
        name: payload.name,
        children: [],
      });
      signData.numberOfSides++;
      state.signData = signData;
      // // const svg = state.canvas.select("svg").children() as Array<Snap.Element>;
      // // const copiedElements = [] as Array<Snap.Element>;

      // // for (let i = 0; i < svg.length; i++) {
      // //   const el = svg[i];
      // //   switch (el.type) {
      // //     case "svg":
      // //     case "defs":
      // //     case "#text":
      // //       // do nothing
      // //       break;
      // //     default:
      // //       // add to copiedElements array
      // //       try {
      // //         copiedElements.push(el.clone());
      // //         copiedElements[copiedElements.length - 1].attr({
      // //           id: el.attr("id") + "_2",
      // //         });
      // //       } catch (e) {
      // //         console.log(el);
      // //       }
      // //   }
      // }
      // const side = state.canvas.select("*").paper?.g();
      // if (side) {
      //   for (let i = 0; i < copiedElements.length; i++) {
      //     side.append(copiedElements[i]);
      //   }
      //   side.attr({ id: payload.id });
      // }
    },
    setCurrentSide(state, payload) {
      state.currentSide = payload;
    },
    removeSide(state, sideNumber) {
      state.signData.sides.splice(sideNumber, 1);
      const signData = { ...state.signData };
      signData.numberOfSides = state.signData.sides.length;
      state.signData = signData;
      state.sidesTab = 0;
    },
    setSignMeasurementUnit(state, payload) {
      saveState(
        state,
        "Set Sign Measurement Unit",
        [
          {
            mutationName: "setSignMeasurementUnit",
            undoValue: state.signData.measurementUnit.unit,
          },
        ],
        [{ mutationName: "setSignMeasurementUnit", redoValue: payload }]
      );
      // saveState(state, $("#signdesigner_snap").html(), "Set Measurement Unit");
      state.signData.measurementUnit.unit = payload;
      state.signData.measurementUnit.conversion = getConversionForUnit(payload);
    },
    setMessages(state, payload) {
      state.messages = payload;
    },
    setRepeatingMessages(state, payload) {
      state.repeatingMessages = payload;
    },
    setDetailFields(state, payload) {
      state.detailFields = payload;
    },
    setSelectedElements(state, payload) {
      state.selectedElements = payload;
    },
    setSelectedElementIds(state, payload) {
      state.selectedElementIds = payload;
    },
    setActivePanel(state, payload) {
      state.activePanel = payload;
    },
    //deselectAllComponents(state) {
    //state.components.forEach(function (component) {
    //component.isSelected = false;
    //});
    //},
    addStep(state) {
      saveState(
        state,
        "Add Step",
        [
          {
            mutationName: "setSteps",
            undoValue: state.steps,
          },
        ],
        [{ mutationName: "addStep", redoValue: state.steps++ }]
      );
      // saveState(state, $("#signdesigner_snap").html(), "Add Step");
      state.steps++;
      state.isChanged = true;
    },
    setSteps(state, payload) {
      state.steps = payload;
    },
    setFonts(state, payload) {
      state.fonts = payload;
    },
    setColors(state, payload) {
      state.colors = payload;
    },
    setVisuals(state, payload) {
      state.visuals = payload;
    },
    setFields(state, payload) {
      state.fields = payload;
    },
    /**
     * add a field to the fields array without setting the changed flag
     * @param {SignDesignerState} state - the state object
     * @param {IField} payload - the IField object to add
     */
    loadField(state, payload: IField) {
      state.fields.push(payload);
    },
    /**
     * add a field to the fields array
     * @param {SignDesignerState} state - the state object
     * @param {IField} payload - the IField object to add
     */
    addField(state, payload: IField) {
      saveState(
        state,
        "Add Field",
        [
          {
            mutationName: "selectFieldById",
            undoValue: payload.id,
          },
          { mutationName: "deleteSelectedField", undoValue: "" },
        ],
        [{ mutationName: "addField", redoValue: payload }]
      );
      // saveState(state, $("#signdesigner_snap").html(), "Add Field");
      let foundIndex = -1;
      if (state.processingUndo) {
        for (let i = 0; i < state.fields.length; i++) {
          if (state.fields[i].name === payload.name) {
            foundIndex = i;
          }
        }
        state.fields.splice(foundIndex, 1);
      } else {
        state.fields.push(payload);
      }
      state.isChanged = true;
    },
    deselectFieldByName(state, fieldName) {
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].name === fieldName) {
          state.fields[i].isSelected = false;
          state.fields[i].isUsed = false;
        }
      }
    },
    deselectFieldById(state, id) {
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].id === id) {
          state.fields[i].isSelected = false;
        }
      }
    },
    updateSelectedField(state, payload) {
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].isSelected) {
          saveState(
            state,
            "Update Field",
            [
              {
                mutationName: "selectFieldById",
                undoValue: state.fields[i].id,
              },
              {
                mutationName: "updateSelectedField",
                undoValue: state.fields[i],
              },
            ],
            [
              {
                mutationName: "selectFieldById",
                redoValue: state.fields[i].id,
              },
              { mutationName: "updateSelectedField", redoValue: payload },
            ]
          );
          // saveState(state, $("#signdesigner_snap").html(), "Update Field");
          state.fields[i] = payload;
          state.isChanged = true;
        }
      }
    },
    updateSelectedFieldName(state, payload) {
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].name === payload) {
          state.fields[i].isSelected = true;
          state.fields[i].isUsed = true;
          state.isChanged = true;
        }
      }
    },
    updateSelectedFieldType(state, payload) {
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].isSelected) {
          saveState(
            state,
            "Update Field Type",
            [
              {
                mutationName: "selectFieldById",
                undoValue: state.fields[i].id,
              },
              {
                mutationName: "updateSelectedFieldType",
                undoValue: state.fields[i].type,
              },
            ],
            [
              {
                mutationName: "selectFieldById",
                redoValue: state.fields[i].id,
              },
              { mutationName: "updateSelectedFieldType", redoValue: payload },
            ]
          );
          // saveState(state, $("#signdesigner_snap").html(), "Add Field Type");
          state.fields[i].type = payload;
          state.isChanged = true;
        }
      }
    },
    updateSelectedFieldX(state, payload) {
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].isSelected) {
          state.fields[i].x = parseFloat(payload);
          state.isChanged = true;
        }
      }
    },
    updateSelectedFieldWidth(state, widthInUnits: string) {
      // saveState(state, $("#signdesigner_snap").html(), "Update Field Width");

      let selectedField = {} as IField;
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].isSelected) {
          selectedField = state.fields[i];
          break;
        }
      }

      saveState(
        state,
        "Update Field Width",
        [
          {
            mutationName: "selectFieldById",
            undoValue: selectedField.id,
          },
          {
            mutationName: "updateSelectedFieldWidth",
            undoValue: convertPointsToUnit(
              selectedField.width,
              state.signData.measurementUnit.unit
            ),
          },
        ],
        [
          {
            mutationName: "selectFieldById",
            redoValue: selectedField.id,
          },
          { mutationName: "updateSelectedFieldWidth", redoValue: widthInUnits },
        ]
      );

      const size = convertUnitToPoints(
        parseFloat(widthInUnits),
        state.signData.measurementUnit.unit
      );

      const el = state.canvas.select(
        "[sa-data-id='" + selectedField.elementIds[0] + "']"
      );

      if (selectedField.horizontalAlignment === "center") {
        // if the field is centered then adjust the position so that we expand the width in both directions
        // x = x - ((user-entered width - current width) / 2)
        const x = selectedField.x - (size - selectedField.width) / 2;

        updateSVGAttribute(
          selectedField.elementIds[0],
          selectedField.type,
          "x",
          x.toString(),
          state.canvas
        );
        selectedField.x = x;
      }

      if (selectedField.horizontalAlignment === "right") {
        // if the field is right aligned then adjust the position so that we expand the width in left direction
        const x = selectedField.x - (size - selectedField.width);

        // updateSVGAttribute(
        //   this.$store.getters.selectedField.elementIds[0],
        //   this.$store.getters.selectedField.type,
        //   "x",
        //   x.toString(),
        //   this.$store.getters.canvas
        // );
        selectedField.x = x;
        alignText(selectedField.horizontalAlignment, el);
      }

      updateSVGAttribute(
        selectedField.elementIds[0],
        selectedField.type,
        "width",
        size.toString(),
        state.canvas
      );

      selectedField.width = size;
      state.isChanged = true;

      // for (let i = 0; i < state.fields.length; i++) {
      //   if (state.fields[i].isSelected) {
      //     saveState(
      //       state,
      //       $("#signdesigner_snap").html(),
      //       "Update Field Width"
      //     );

      //     state.fields[i].width = parseFloat(payload);
      //     state.isChanged = true;

      //     const el = state.canvas.select(
      //       "[sa-data-id='" + state.fields[i].elementIds[0] + "']"
      //     );
      //     const parent = el.parent();
      //     const rectEl = parent.select("rect");

      //     console.log("el when updating field width....");
      //     console.log(rectEl);
      //     // put this back: rectEl.attr({ width: parseFloat(payload) });

      //   }
      // }
    },
    updateSelectedFieldHeight(state, payload) {
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].isSelected) {
          saveState(
            state,
            "Update Field Height",
            [
              {
                mutationName: "selectFieldById",
                undoValue: state.fields[i].id,
              },
              {
                mutationName: "updateSelectedFieldHeight",
                undoValue: state.fields[i].height,
              },
            ],
            [
              {
                mutationName: "selectFieldById",
                redoValue: state.fields[i].id,
              },
              { mutationName: "updateSelectedFieldHeight", redoValue: payload },
            ]
          );
          //     saveState(
          //   state,
          //   $("#signdesigner_snap").html(),
          //   "Update Field Height"
          // );
          state.fields[i].height = parseFloat(payload);
          state.isChanged = true;
        }
      }
    },
    updateSelectedFieldPlaceholderText(state, payload) {
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].isSelected) {
          const el = state.canvas.select(
            "[sa-data-id='" + state.fields[i].elementIds[0] + "']"
          );

          saveState(
            state,
            "Update Placeholder Text",
            [
              {
                mutationName: "selectFieldById",
                undoValue: state.fields[i].id,
              },
              {
                mutationName: "updateSelectedFieldPlaceholderText",
                undoValue: el.node.textContent,
              },
            ],
            [
              {
                mutationName: "selectFieldById",
                redoValue: state.fields[i].id,
              },
              {
                mutationName: "updateSelectedFieldPlaceholderText",
                redoValue: payload,
              },
            ]
          );

          el.node.textContent = payload;
          const el1 = $.extend(true, {}, el);
          alignText(state.fields[i].horizontalAlignment, el1);
        }
      }
    },
    updateSelectedFieldHasPlaceholderImage(state, payload) {
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].isSelected) {
          saveState(
            state,
            "Update Field Has Placeholder Image",
            [
              {
                mutationName: "selectFieldById",
                undoValue: state.fields[i].id,
              },
              {
                mutationName: "updateSelectedFieldHasPlaceholderImage",
                undoValue: state.fields[i].hasPlaceholderImage,
              },
            ],
            [
              {
                mutationName: "selectFieldById",
                redoValue: state.fields[i].id,
              },
              {
                mutationName: "updateSelectedFieldHasPlaceholderImage",
                redoValue: payload,
              },
            ]
          );
          //     saveState(
          //   state,
          //   $("#signdesigner_snap").html(),
          //   "Update Placeholder Image"
          // );
          state.fields[i].hasPlaceholderImage = payload;
          state.isChanged = true;
        }
      }
    },
    updateSelectedFieldPlaceholderImageUrl(state, payload) {
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].isSelected) {
          state.fields[i].placeholderImageUrl = payload;
          state.isChanged = true;
        }
      }
    },
    updateSelectedFieldLines(state, payload) {
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].isSelected) {
          // saveState(state, $("#signdesigner_snap").html(), "Add Field Lines");
          saveState(
            state,
            "Update Field Lines",
            [
              {
                mutationName: "selectFieldById",
                undoValue: state.fields[i].id,
              },
              {
                mutationName: "updateSelectedFieldLines",
                undoValue: state.fields[i].lines,
              },
            ],
            [
              {
                mutationName: "selectFieldById",
                redoValue: state.fields[i].id,
              },
              { mutationName: "updateSelectedFieldLines", redoValue: payload },
            ]
          );

          let selectedField = {} as IField;
          for (let i = 0; i < state.fields.length; i++) {
            if (state.fields[i].isSelected) {
              selectedField = state.fields[i];
              break;
            }
          }

          //get the no-fill, no-stroke rectangle so we can set it's height attribute
          const rect = getNoFillNoStrokeByElementId(
            selectedField.elementIds[0],
            state.canvas
          );
          //set the height of the no-fill, no-stroke rectangle
          const height = selectedField.leading * payload;

          rect.attr({ height: height });

          state.fields[i].lines = payload;
          state.isChanged = true;
        }
      }
    },
    updateSelectedFieldLeading(state, payload) {
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].isSelected) {
          saveState(
            state,
            "Update Field Leading",
            [
              {
                mutationName: "selectFieldById",
                undoValue: state.fields[i].id,
              },
              {
                mutationName: "updateSelectedFieldLeading",
                undoValue: state.fields[i].leading,
              },
            ],
            [
              {
                mutationName: "selectFieldById",
                redoValue: state.fields[i].id,
              },
              {
                mutationName: "updateSelectedFieldLeading",
                redoValue: payload,
              },
            ]
          );
          //     saveState(
          //   state,
          //   $("#signdesigner_snap").html(),
          //   "Update Field Leading"
          // );

          // update the svg
          updateSVGDataName(
            state.fields[i].elementIds[0],
            state.fields[i].type,
            "leading: " + state.fields[i].leading,
            " leading: " + payload + " " + state.signData.measurementUnit.unit,
            state.canvas
          );

          state.fields[i].leading = payload;
          state.isChanged = true;
        }
      }
    },
    updateSelectedFieldBBox(state, bbox) {
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].isSelected) {
          state.fields[i].height = bbox.height;
          state.fields[i].width = bbox.width;
          state.fields[i].x = bbox.x;
          state.fields[i].y = bbox.y;
          state.isChanged = true;
        }
      }
    },
    updateSelectedFieldVerticalAlignment(state, alignment) {
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].isSelected) {
          saveState(
            state,
            "Update Field Vertical Alignment",
            [
              {
                mutationName: "selectFieldById",
                undoValue: state.fields[i].id,
              },
              {
                mutationName: "updateSelectedFieldVerticalAlignment",
                undoValue: state.fields[i].verticalAlignment,
              },
            ],
            [
              {
                mutationName: "selectFieldById",
                redoValue: state.fields[i].id,
              },
              {
                mutationName: "updateSelectedFieldVerticalAlignment",
                redoValue: alignment,
              },
            ]
          );
          //     saveState(
          //   state,
          //   $("#signdesigner_snap").html(),
          //   "Update Vertical Alignment"
          // );

          updateSVGDataName(
            state.fields[i].elementIds[0],
            state.fields[i].type,
            state.fields[i].verticalAlignment,
            alignment,
            state.canvas
          );

          state.fields[i].verticalAlignment = alignment;
          state.isChanged = true;
        }
      }
    },
    updateSelectedFieldHorizontalAlignment(state, alignment) {
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].isSelected) {
          saveState(
            state,
            "Update Field Horizontal Alignment",
            [
              {
                mutationName: "selectFieldById",
                undoValue: state.fields[i].id,
              },
              {
                mutationName: "updateSelectedFieldHorizontalAlignment",
                undoValue: state.fields[i].horizontalAlignment,
              },
            ],
            [
              {
                mutationName: "selectFieldById",
                redoValue: state.fields[i].id,
              },
              {
                mutationName: "updateSelectedFieldHorizontalAlignment",
                redoValue: alignment,
              },
            ]
          );
          //     saveState(
          //   state,
          //   $("#signdesigner_snap").html(),
          //   "Update Horizontal Alignment"
          // );

          updateSVGDataName(
            state.fields[i].elementIds[0],
            state.fields[i].type,
            state.fields[i].horizontalAlignment,
            alignment,
            state.canvas
          );

          state.fields[i].horizontalAlignment = alignment;
          state.isChanged = true;
          const el = state.canvas.select(
            "[sa-data-id='" + state.fields[i].elementIds[0] + "']"
          );
          alignText(alignment, el);
        }
      }
    },
    changeSelectedField(state, newFieldName) {
      let selectedField: IField | null = null;
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].isSelected) {
          selectedField = state.fields[i];
          state.fields[i].isSelected = false;
          state.fields[i].isUsed = false;
          state.fields[i].elementIds = [];
          break;
        }
      }

      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].name === newFieldName) {
          if (selectedField) {
            state.fields[i].isSelected = true;
            state.fields[i].isUsed = true;
            state.fields[i].elementIds = state.selectedElementIds;
            state.fields[i].x = selectedField.x;
            state.fields[i].y = selectedField.y;
            state.fields[i].width = selectedField.width;
            state.fields[i].height = selectedField.height;
            state.isChanged = true;
            break;
          }
        }
      }
    },
    updateField(state, field) {
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].id === field.id) {
          state.fields.splice(i, 1, field);
        }
      }
    },
    updateFieldIsSelected(state, field) {
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].id === field.id) {
          state.fields[i].isSelected = field.isSelected;
        }
      }
    },
    deselectAllFields(state) {
      state.fields.forEach((field) => {
        field.isSelected = false;
      });
    },
    deselectAllUsedFields(state) {
      state.fields.forEach((field) => {
        field.isUsed = false;
      });
    },
    selectFieldById(state, fieldId) {
      //deselect all fields
      state.fields.forEach((field) => {
        field.isSelected = false;
        if (field.id === fieldId) {
          field.isSelected = true;

          //select all SVG elements that are associated with this field
          state.selectedElementIds = [];
          field.elementIds.forEach((elementId) => {
            state.selectedElementIds.push(elementId);
          });
        }
      });
    },
    // eslint-disable-next-line
    createFieldByName(state, fieldName) {
      //deselect all fields
      state.fields.forEach((field) => {
        field.isSelected = false;

        if (field.name === fieldName) {
          saveState(
            state,
            "Add Field",
            [
              { mutationName: "selectFieldById", undoValue: field.id },
              { mutationName: "deleteSelectedField", undoValue: "" },
            ],
            [
              {
                mutationName: "createFieldByName",
                redoValue: fieldName,
              },
            ]
          );
          field.isSelected = true;
          field.isUsed = true;
          field.elementIds = state.selectedElementIds;

          const el: Snap.Element = state.canvas.select(
            "[sa-data-id='" + state.selectedElementIds[0] + "']"
          );
          const bb = el.getBBox();

          let fieldType = "text";

          if (field.type.toLowerCase().startsWith("color")) {
            fieldType = "color";
          } else if (field.type.toLowerCase().startsWith("icon")) {
            fieldType = "visual";
          } else {
            fieldType = "text";
          }
          //create the field svg elements
          const gFragment = Snap.parse(
            "<g id='" + normalCaseToUnderscore(field.name) + "'>" + "</g>"
          );
          el.parent().append(gFragment as Snap.Element);
          const gElement = el
            .parent()
            .select("g#" + normalCaseToUnderscore(field.name));
          // move the new parent before the selected element so that we maintain drawing order
          el.before(gElement);
          gElement.append(el);
          if (fieldType === "text") {
            // have to select the svg not the div for the rect command to work
            const snap = Snap("#signdesigner_snap svg");
            const noFillRect = snap.rect(bb.x, bb.y, bb.width, bb.height);

            noFillRect.attr({ fill: "none" });
            noFillRect.attr({ id: "left_top" });
            noFillRect.attr({ "data-name": "left, top" });

            // need to prepend the no fill/ no stroke rect so it appears before the text element
            gElement.prepend(noFillRect);
          } else if (fieldType === "visual") {
            if (el.type === "rect") {
              el.attr({ fill: "none" });
              el.attr({ stroke: "none" });
              el.attr({ id: "center_middle" });
              el.attr({ "data-name": "center, middle" });
            } else {
              // have to select the svg not the div for the rect command to work
              const snap = Snap("#signdesigner_snap svg");
              const noFillRect = snap.rect(bb.x, bb.y, bb.width, bb.height);
              noFillRect.attr({ fill: "none" });
              noFillRect.attr({ stroke: "none" });
              noFillRect.attr({ id: "center_middle" });
              noFillRect.attr({ "data-name": "center, middle" });
              // hide the original graphic
              el.attr({ display: "none" });
              // el.unclick();
              // need to prepend the no fill/ no stroke rect so it appears before the element
              gElement.prepend(noFillRect);
              // noFillRect.click((e: MouseEvent) => {
              //   // eslint-disable-next-line
              //   (payload.thisFromVue.$root.$refs.SVGRenderer as any).selectElement(noFillRect, e);
              // });
            }
          } else {
            el.attr({
              id: "color: {" + normalCaseToUnderscore(field.name) + "}",
              "data-name":
                "color: {" + normalCaseToUnderscore(field.name) + "}",
            });
          }
        }
      });
    },
    selectFirstFieldOfType: (state, elementType: string) => {
      //element type should be one of "text", "visual" or "color"
      const textFieldTypes = ["text", "char", "char_x", "date", "trans"];
      const visualFieldTypes = ["icon", "icon_t"];
      const colorFieldTypes = ["color", "color_x", "color_t"];

      for (let i = 0; i < state.fields.length; i++) {
        if (
          elementType === "text" &&
          textFieldTypes.includes(state.fields[i].type.toLowerCase()) &&
          !state.fields[i].isUsed
        ) {
          state.fields[i].isSelected = true;
          state.fields[i].isUsed = true;
          state.fields[i].elementIds = state.selectedElementIds;
          break;
        } else if (
          elementType === "visual" &&
          visualFieldTypes.includes(state.fields[i].type.toLowerCase()) &&
          !state.fields[i].isUsed
        ) {
          state.fields[i].isSelected = true;
          state.fields[i].isUsed = true;
          state.fields[i].elementIds = state.selectedElementIds;
          break;
        } else if (
          elementType === "color" &&
          colorFieldTypes.includes(state.fields[i].type.toLowerCase()) &&
          !state.fields[i].isUsed
        ) {
          state.fields[i].isSelected = true;
          state.fields[i].isUsed = true;
          state.fields[i].elementIds = state.selectedElementIds;
          break;
        }
      }
    },
    deleteSelectedField(state) {
      let index = -1;
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].isSelected) {
          index = i;
          break;
        }
      }
      if (index > -1) {
        saveState(
          state,
          "Delete Field",
          [
            {
              mutationName: "selectFieldById",
              undoValue: state.fields[index].id,
            },
            { mutationName: "addField", undoValue: state.fields[index] },
          ],
          [
            {
              mutationName: "selectFieldById",
              redoValue: state.fields[index].id,
            },
            { mutationName: "deleteSelectedField", redoValue: "" },
          ]
        );
        // saveState(state, $("#signdesigner_snap").html(), "Delete Field");

        // remove the signagent markup for the field
        const el = state.canvas.select(
          "[sa-data-id='" + state.fields[index].elementIds[0] + "']"
        );
        el.attr({ id: "", "data-name": "" });
        const parentEl: Snap.Element = el.parent();
        const hiddenElements = parentEl.selectAll("[style*='display: none']");
        hiddenElements.attr({ display: "" });
        //move the all the child elements outside of the parent
        parentEl.children().forEach((child: Snap.Element) => {
          if (child.type !== "#text") {
            // don't move the no-fill/no-stroke rectangle
            if (
              child.type === "rect" &&
              child.attr("stroke") === "none" &&
              child.attr("fill") === "none"
            ) {
              //pass
            } else {
              // also skip any place holder images
              if (!child.hasClass("placeholder-image")) {
                child.insertBefore(parentEl);
              }
            }
          }
        });

        //remove the parent element
        parentEl.remove();

        state.fields[index].isSelected = false;
        state.fields[index].isUsed = false;
        state.fields[index].elementIds = [];
      }
    },
    setParts(state, payload) {
      state.parts = payload;
    },
    addPart(state, payload) {
      state.parts.push(payload);
      state.isChanged = true;
    },
    updateSelectedPart(state, payload) {
      for (let i = 0; i < state.parts.length; i++) {
        if (state.parts[i].isSelected) {
          state.parts[i] = payload;
        }
      }
    },
    updateSelectedPartName(state, payload) {
      for (let i = 0; i < state.parts.length; i++) {
        if (state.parts[i].isSelected) {
          state.parts[i].name = payload;
          state.isChanged = true;
        }
      }
    },
    updateSelectedPartNumber(state, payload) {
      for (let i = 0; i < state.parts.length; i++) {
        if (state.parts[i].isSelected) {
          state.parts[i].itemNumber = payload;
          state.isChanged = true;
        }
      }
    },
    updateSelectedPartDescription(state, payload) {
      for (let i = 0; i < state.parts.length; i++) {
        if (state.parts[i].isSelected) {
          state.parts[i].description = payload;
          state.isChanged = true;
        }
      }
    },
    updateSelectedPartPartFamily(state, payload) {
      for (let i = 0; i < state.parts.length; i++) {
        if (state.parts[i].isSelected) {
          state.parts[i].productFamily = payload;
          state.isChanged = true;
        }
      }
    },
    updateSelectedPartPartCategory(state, payload) {
      for (let i = 0; i < state.parts.length; i++) {
        if (state.parts[i].isSelected) {
          state.parts[i].productCategory = payload;
          state.isChanged = true;
        }
      }
    },
    updateSelectedPartPartType(state, payload) {
      for (let i = 0; i < state.parts.length; i++) {
        if (state.parts[i].isSelected) {
          state.parts[i].productType = payload;
          state.isChanged = true;
        }
      }
    },
    // updateSelectedPartCategory(state, payload) {
    //   for (let i = 0; i < state.parts.length; i++) {
    //     if (state.parts[i].isSelected) {
    //       state.parts[i].category = payload;
    //       state.isChanged = true;
    //     }
    //   }
    // },
    // updateSelectedPartCanCustomizeColors(state, payload) {
    //   for (let i = 0; i < state.parts.length; i++) {
    //     if (state.parts[i].isSelected) {
    //       state.parts[i].canCustomizeColors = payload;
    //       state.isChanged = true;
    //     }
    //   }
    // },
    // updateSelectedPartGraphicFileRequired(state, payload) {
    //   for (let i = 0; i < state.parts.length; i++) {
    //     if (state.parts[i].isSelected) {
    //       state.parts[i].graphicFileRequired = payload;
    //       state.isChanged = true;
    //     }
    //   }
    // },
    updatePart(state, part) {
      for (let i = 0; i < state.parts.length; i++) {
        if (state.parts[i].id === part.id) {
          state.parts.splice(i, 1, part);
          // state.isChanged = true;
        }
      }
    },
    deselectAllParts(state) {
      state.parts.forEach((part) => {
        part.isSelected = false;
      });
    },
    deselectPartById(state, id) {
      for (let i = 0; i < state.parts.length; i++) {
        if (state.parts[i].id === id) {
          state.parts[i].isSelected = false;
        }
      }
    },
    selectPartById(state, partId) {
      //deselect all parts
      state.parts.forEach((part) => {
        part.isSelected = false;
        if (part.id === partId) {
          part.isSelected = true;

          //how can I now select all SVG elements that are associated with this field?
          state.selectedElementIds = [];
          part.elementIds.forEach((elementId) => {
            state.selectedElementIds.push(elementId);
          });
        }
      });
    },
    deleteSelectedPart(state) {
      let index = -1;
      for (let i = 0; i < state.parts.length; i++) {
        if (state.parts[i].isSelected) {
          index = i;
          break;
        }
      }
      if (index > -1) {
        state.parts.splice(index, 1);
      }
    },
    setMaterials(state, payload) {
      state.materials = payload;
    },
    addMaterial(state, payload) {
      state.materials.push(payload);
      state.isChanged = true;
    },
    deselectAllSignMaterials(state) {
      state.signMaterials.forEach((signMaterial) => {
        signMaterial.isSelected = false;
      });
    },
    updateSignMaterial(state, signMaterial) {
      for (let i = 0; i < state.signMaterials.length; i++) {
        if (state.signMaterials[i].uuid === signMaterial.uuid) {
          state.signMaterials.splice(i, 1, signMaterial);
        }
      }
    },
    updateSelectedSignMaterialMaterials(state, materials) {
      for (let i = 0; i < state.signMaterials.length; i++) {
        if (state.signMaterials[i].isSelected) {
          saveState(
            state,
            "Update Sign Material Materials",
            [
              {
                mutationName: "updateSelectedSignMaterialMaterials",
                undoValue: state.signMaterials[i].materials,
              },
            ],
            [
              {
                mutationName: "updateSelectedSignMaterialMaterials",
                redoValue: materials,
              },
            ]
          );
          // saveState(state, $("#signdesigner_snap").html(), "Add Sign Material");
          state.signMaterials[i].materials = materials;
          state.isChanged = true;
        }
      }
    },
    deleteMaterialByIndex(state, index) {
      state.materials.splice(index, 1);
      state.isChanged = true;
    },
    updateMaterialIdByIndex(state, payload) {
      state.materials[payload.index].id = payload.value;
      state.isChanged = true;
    },
    updateMaterialLabelByIndex(state, payload) {
      state.materials[payload.index].label = payload.value;
      state.isChanged = true;
    },
    updateMaterialLibrariesByIndex(state, payload) {
      state.materials[payload.index].libraries = payload.value;
      state.isChanged = true;
    },
    removeMaterialLibraryByIndex(state, payload) {
      state.materials[payload.materialIndex].libraries.splice(
        payload.libraryIndex,
        1
      );
      state.isChanged = true;
    },
    createMaterialColorLibrary(state, materialIndex) {
      const library: IColorLibrary = {
        id: "",
        label: "New Library",
        description: "",
        code: "",
        publish: "C",
        colors: [],
      };
      state.materials[materialIndex].libraries.push(library);
      state.isChanged = true;
    },
    addMaterialColorLibrary(state, payload) {
      state.materials[payload.materialIndex].libraries.push(payload.library);
      state.isChanged = true;
    },
    updateMaterialColorLibraryId(state, payload) {
      state.materials[payload.materialIndex].libraries[
        payload.libraryIndex
      ].id = payload.value;
      state.isChanged = true;
    },
    /**
     * Update the color library's label
     * @param state the state
     * @param payload an Object containing materialIndex, libraryIndex and value
     */
    updateMaterialColorLibraryLabel(state, payload) {
      state.materials[payload.materialIndex].libraries[
        payload.libraryIndex
      ].label = payload.value;
      state.isChanged = true;
    },
    updateMaterialColorLibraryDescription(state, payload) {
      state.materials[payload.materialIndex].libraries[
        payload.libraryIndex
      ].description = payload.value;
      state.isChanged = true;
    },
    updateMaterialColorLibraryColorId(state, payload) {
      state.materials[payload.materialIndex].libraries[
        payload.libraryIndex
      ].colors[payload.colorIndex].id = payload.value;
      state.isChanged = true;
    },
    updateMaterialColorLibraryColorName(state, payload) {
      state.materials[payload.materialIndex].libraries[
        payload.libraryIndex
      ].colors[payload.colorIndex].name = payload.value;
      state.isChanged = true;
    },
    updateMaterialColorLibraryColorDescription(state, payload) {
      state.materials[payload.materialIndex].libraries[
        payload.libraryIndex
      ].colors[payload.colorIndex].description = payload.value;
      state.isChanged = true;
    },
    updateMaterialColorLibraryColorHexcode(state, payload) {
      state.materials[payload.materialIndex].libraries[
        payload.libraryIndex
      ].colors[payload.colorIndex].hexCode = payload.value;
      state.isChanged = true;
    },
    /**
     * create a color in the materials color library
     * @param state - the store state
     * @param payload - an object containing materialIndex and libraryIndex fields
     */
    createMaterialColorLibraryColor(state, payload) {
      const color: IColorItem = {
        uuid: uuidv4(),
        id: "",
        name: "New Color",
        description: "",
        gloss: "",
        included: true,
        hexCode: "000000",
      };
      state.materials[payload.materialIndex].libraries[
        payload.libraryIndex
      ].colors.push(color);
      state.isChanged = true;
    },
    toggleMaterialColorLibraryColorInclude(state, payload) {
      payload.colorIndexes.forEach((colorIndex: number) => {
        state.materials[payload.materialIndex].libraries[
          payload.libraryIndex
        ].colors[colorIndex].included =
          !state.materials[payload.materialIndex].libraries[
            payload.libraryIndex
          ].colors[colorIndex].included;
      });

      // state.materials[payload.materialIndex].libraries[
      //   payload.libraryIndex
      // ].colors[payload.colorIndex].included =
      //   !state.materials[payload.materialIndex].libraries[payload.libraryIndex]
      //     .colors[payload.colorIndex].included;
      state.isChanged = true;
    },
    setSignMaterials(state, payload) {
      state.signMaterials = payload;
    },
    addSignMaterial(state, materialId) {
      state.signMaterials.push(materialId);
      state.isChanged = true;
    },
    deleteSignMaterialByIndex(state, index) {
      state.signMaterials.splice(index, 1);
      state.isChanged = true;
    },
    updateSignMaterialByIndex(state, payload) {
      state.signMaterials[payload.index] = payload.materialUUID;
      state.isChanged = true;
    },
    setProcesses(state, payload) {
      state.processes = payload;
    },
    addProcess(state, payload) {
      state.processes.push(payload);
      state.isChanged = true;
    },
    deleteProcessByIndex(state, index) {
      state.processes.splice(index, 1);
      state.isChanged = true;
    },
    updateProcessIdByIndex(state, payload) {
      state.processes[payload.index].id = payload.value;
      state.isChanged = true;
    },
    updateProcessLabelByIndex(state, payload) {
      state.processes[payload.index].label = payload.value;
      state.isChanged = true;
    },
    updateProcessDescriptionByIndex(state, payload) {
      state.processes[payload.index].description = payload.value;
      state.isChanged = true;
    },
    updateProcessSourceMaterialByIndex(state, payload) {
      state.processes[payload.index].sourceMaterial = payload.value;
      state.isChanged = true;
    },
    updateProcessTargetMaterialsByIndex(state, payload) {
      state.processes[payload.index].targetMaterials = payload.value;
      state.isChanged = true;
    },
    addSignProcess(state, processId) {
      state.signProcesses.push(processId);
      state.isChanged = true;
    },
    clearAllSignProcesses(state) {
      state.signProcesses = [];
      state.isChanged = true;
    },
    updateSignProcess(state, signProcess) {
      for (let i = 0; i < state.signProcesses.length; i++) {
        if (state.signProcesses[i].uuid === signProcess.uuid) {
          state.signProcesses.splice(i, 1, signProcess);
        }
      }
    },
    setSignProcesses(state, payload) {
      state.signProcesses = payload;
    },
    updateSelectedSignProcessProcesses(state, processes) {
      for (let i = 0; i < state.signProcesses.length; i++) {
        if (state.signProcesses[i].isSelected) {
          state.signProcesses[i].processes = processes;
          state.isChanged = true;
        }
      }
    },
    deselectAllSignProcesses(state) {
      state.signProcesses.forEach((signProcess) => {
        signProcess.isSelected = false;
      });
    },
    /**
     * add alignment to Alignments without updating isChanged flag
     * @param {SignDesignerState} state - the sign designer state
     * @param {IAlignment} payload  - the alignment to load
     */
    loadAlignment(state, payload: IAlignment) {
      state.alignments.push(payload);
    },
    /**
     * add an alignment to the Alignments array
     * @param {SignDesignerState} state - the sign designer state
     * @param {IAlignment} payload - the alignment to add
     */
    addAlignment(state, payload: IAlignment) {
      state.showAddAlignmentModal = false;
      saveState(
        state,
        "Add Alignment",
        [{ mutationName: "deleteAlignmentByUUID", undoValue: payload.uuid }],
        [{ mutationName: "addAlignment", redoValue: payload }]
      );
      // saveState(state, $("#signdesigner_snap").html(), "Add Alignment");
      let alignmentIndex = -1;
      for (let i = 0; i < state.alignments.length; i++) {
        if (state.alignments[i].sourceField === payload.sourceField) {
          alignmentIndex = i;
          break;
        }
      }
      if (alignmentIndex !== -1) {
        state.alignments[alignmentIndex].targets.push(payload.targets[0]);
      } else {
        state.alignments.push(payload);
      }
      state.isChanged = true;
    },
    /**
     * replace all alignments with a new array
     * @param {SignDesignerState} state - the sign designer state
     * @param {Array<IAlignment>} payload - an array of IAlignments
     */
    setAlignments(state, payload) {
      state.alignments = payload;
    },
    /**
     * select an alignment by UUID.
     * @param {SignDesignerState} state  - the sign designer state
     * @param {string} uuid - the uuid to select
     */
    selectAlignmentByUUID(state, uuid) {
      //deselect all parts
      state.alignments.forEach((alignment) => {
        alignment.isSelected = false;
        if (alignment.uuid === uuid) {
          alignment.isSelected = true;

          state.selectedElementIds = [];
          alignment.elementIds.forEach((elementId) => {
            state.selectedElementIds.push(elementId);
          });
        }
      });
    },
    /**
     * select a target alignment by UUID.
     * @param {SignDesignerState} state  - the sign designer state
     * @param {string} uuid - the uuid to select
     */
    selectTargetAlignmentByUUID(state, uuid) {
      //first get the selected alignment
      let alignmentIndex = -1;
      for (let i = 0; i < state.alignments.length; i++) {
        if (state.alignments[i].isSelected) {
          alignmentIndex = i;
          break;
        }
      }
      // if we found a selected alignment
      if (alignmentIndex > -1) {
        //select the target with the matching uuid
        state.alignments[alignmentIndex].targets.forEach((target) => {
          //deselect each target
          target.isSelected = false;
          if (target.uuid === uuid) {
            target.isSelected = true;
          }
        });
      }
    },
    /**
     * select a target alignment by UUID.
     * @param {SignDesignerState} state  - the sign designer state
     * @param {string} uuid - the uuid to select
     */
    selectAlignmentTargetByIndex(state, index: number) {
      //first get the selected alignment
      let alignmentIndex = -1;
      for (let i = 0; i < state.alignments.length; i++) {
        if (state.alignments[i].isSelected) {
          alignmentIndex = i;
          break;
        }
      }
      // if we found a selected alignment
      if (alignmentIndex > -1) {
        //select the target with the matching uuid
        for (
          let i = 0;
          i < state.alignments[alignmentIndex].targets.length;
          i++
        ) {
          if (i === index) {
            state.alignments[alignmentIndex].targets[i].isSelected = true;
          } else {
            state.alignments[alignmentIndex].targets[i].isSelected = false;
          }
        }
      }
    },
    updateAlignment(state, alignment) {
      for (let i = 0; i < state.alignments.length; i++) {
        if (state.alignments[i].uuid === alignment.uuid) {
          state.alignments.splice(i, 1, alignment);
        }
      }
    },
    updateAlignmentTarget(state, alignment) {
      state.showAddAlignmentModal = false;
      // saveState(
      //   state,
      //   $("#signdesigner_snap").html(),
      //   "Update Alignment Target"
      // );
      for (let i = 0; i < state.alignments.length; i++) {
        if (state.alignments[i].uuid === alignment.uuid) {
          saveState(
            state,
            "Update Alignment Target",
            [
              {
                mutationName: "updateAlignmentTarget",
                undoValue: state.alignments[i],
              },
            ],
            [{ mutationName: "updateAlignmentTarget", redoValue: alignment }]
          );
          //we have an alignment so we now need to find the target
          for (let j = 0; j < state.alignments[i].targets.length; j++) {
            if (state.alignments[i].targets[j].isSelected) {
              state.alignments[i].targets.splice(j, 1, alignment.targets[0]);
            }
          }
        }
      }
    },
    deselectAllAlignmentTargets(state) {
      for (let i = 0; i < state.alignments.length; i++) {
        for (let j = 0; j < state.alignments[i].targets.length; j++) {
          state.alignments[i].targets[j].isSelected = false;
        }
      }
    },
    deleteAlignmentByUUID(state, uuid) {
      let index = -1;
      for (let i = 0; i < state.alignments.length; i++) {
        if (state.alignments[i].uuid === uuid) {
          index = i;
          break;
        }
      }
      if (index > -1) {
        state.alignments.splice(index, 1);
        state.isChanged = true;
      }
    },
    deleteAlignmentTargetByUUID(state, uuid) {
      let alignmentIndex = -1;
      for (let i = 0; i < state.alignments.length; i++) {
        if (state.alignments[i].isSelected) {
          alignmentIndex = i;
          break;
        }
      }
      if (alignmentIndex > -1) {
        let targetIndex = -1;
        for (
          let i = 0;
          i < state.alignments[alignmentIndex].targets.length;
          i++
        ) {
          if (state.alignments[alignmentIndex].targets[i].uuid === uuid) {
            targetIndex = i;
            break;
          }
        }
        if (targetIndex > -1) {
          state.alignments[alignmentIndex].targets.splice(targetIndex, 1);
          state.isChanged = true;
        }
        //if there are no more targets on the selected alignment then delete the alignment as well
        if (state.alignments[alignmentIndex].targets.length === 0) {
          state.alignments.splice(alignmentIndex, 1);
        }
      }
    },
    deleteSelectedAlignment(state) {
      let index = -1;
      for (let i = 0; i < state.alignments.length; i++) {
        if (state.alignments[i].isSelected) {
          index = i;
          break;
        }
      }
      if (index > -1) {
        state.alignments.splice(index, 1);
        state.isChanged = true;
      }
    },
    deselectAllAlignments(state) {
      state.alignments.forEach((alignment) => {
        alignment.isSelected = false;
      });
    },
    updateSelectedAlignmentSourceField(state, payload) {
      for (let i = 0; i < state.alignments.length; i++) {
        if (state.alignments[i].isSelected) {
          // saveState(state, $("#signdesigner_snap").html(), "Add Source Field");
          saveState(
            state,
            "Update Alignment Source",
            [
              {
                mutationName: "updateAlignmentSourceField",
                undoValue: state.alignments[i].sourceField,
              },
            ],
            [
              {
                mutationName: "updateAlignmentSourceField",
                redoValue: payload,
              },
            ]
          );
          state.alignments[i].sourceField = payload;
          state.isChanged = true;
        }
      }
    },
    // updateSelectedAlignmentSourceAlignment(state, payload) {
    //   for (let i = 0; i < state.alignments.length; i++) {
    //     if (state.alignments[i].isSelected) {
    //       state.alignments[i].sourceAlignment = payload;
    //       state.isChanged = true;
    //     }
    //   }
    // },
    // updateTargetAlignmentSourceAlignment(state, payload) {
    //   for (let i = 0; i < state.alignments.length; i++) {
    //     if (state.alignments[i].isSelected) {
    //       if (state.alignments[i].targetField === payload.targetField) {
    //         state.alignments[i].sourceAlignment = payload.sourceAlignment;
    //         state.isChanged = true;
    //       }
    //     }
    //   }
    // },
    // updateSelectedAlignmentTargetRow(state, payload) {
    //   for (let i = 0; i < state.alignments.length; i++) {
    //     if (state.alignments[i].isSelected) {
    //       state.alignments[i].targetRow = payload;
    //       state.isChanged = true;
    //     }
    //   }
    // },
    // updateSelectedAlignmentTargetField(state, payload) {
    //   for (let i = 0; i < state.alignments.length; i++) {
    //     if (state.alignments[i].isSelected) {
    //       state.alignments[i].targetField = payload;
    //       state.isChanged = true;
    //     }
    //   }
    // },
    // updateSelectedAlignmentTargetAlignment(state, payload) {
    //   for (let i = 0; i < state.alignments.length; i++) {
    //     if (state.alignments[i].isSelected) {
    //       state.alignments[i].targetAlignment = payload;
    //       state.isChanged = true;
    //     }
    //   }
    // },
    // updateSelectedAlignmentOffset(state, payload) {
    //   for (let i = 0; i < state.alignments.length; i++) {
    //     if (state.alignments[i].isSelected) {
    //       state.alignments[i].offset = payload;
    //       state.isChanged = true;
    //     }
    //   }
    // },
    // updateTargetAlignmentTargetAlignment(state, payload) {
    //   for (let i = 0; i < state.alignments.length; i++) {
    //     if (state.alignments[i].isSelected) {
    //       if (state.alignments[i].targetField === payload.targetField) {
    //         state.alignments[i].targetAlignment = payload.targetAlignment;
    //         state.isChanged = true;
    //       }
    //     }
    //   }
    // },
    // updateTargetAlignmentOffset(state, payload) {
    //   for (let i = 0; i < state.alignments.length; i++) {
    //     if (state.alignments[i].isSelected) {
    //       if (state.alignments[i].targetField === payload.targetField) {
    //         state.alignments[i].offset = payload.offset;
    //         state.isChanged = true;
    //       }
    //     }
    //   }
    // },
    setRepeats(state, payload) {
      state.repeats = payload;
    },
    addRepeat(state, payload) {
      //      this.commit(state, "Add Repeat");
      // saveState(state, $("#signdesigner_snap").html(), "Add Repeat");
      saveState(
        state,
        "Add Repeat",
        [{ mutationName: "removeRepeat", undoValue: payload }],
        [{ mutationName: "addRepeat", redoValue: payload }]
      );

      const repeat = payload;

      let lowestX = 99999;
      let lowestY = 99999;

      repeat.elementIds.forEach((elementId: string) => {
        // find the field that corresponds to each element id
        let repeatField = null as IField | null;
        for (let i = 0; i < state.fields.length; i++) {
          if (state.fields[i].elementIds[0] === elementId) {
            repeatField = state.fields[i];
            break;
          }
        }
        // const repeatField = JSON.parse(
        //   JSON.stringify(this.getters.fieldByElementId(state, elementId))
        // );
        // set the belongsToRepeatId field equal to the repeat id
        if (repeatField) {
          repeatField.belongsToRepeatId = repeat.id;
          // update the field
          for (let i = 0; i < state.fields.length; i++) {
            if (state.fields[i].id === repeatField.id) {
              state.fields[i].belongsToRepeatId = repeat.id;
            }
          }
          //determine the lowest X and Y so that we can update the repeat with them
          lowestX = repeatField.x < lowestX ? repeatField.x : lowestX;
          lowestY = repeatField.y < lowestY ? repeatField.y : lowestY;
        }
      });

      repeat.x = lowestX;
      repeat.y = lowestY;

      //        if ("thisFromVue" in payload) {
      //          calculateRepeatSize(payload.thisFromVue, repeat.id);
      //        } else {
      state.repeats.push(repeat);
      calculateRepeatSize(this, repeat.id);
      // let rowHeight = 0;
      // let rowWidth = 0;

      // state.fields.forEach((field: IField) => {
      //   if (field.belongsToRepeatId === repeat.id) {
      //     if (field.height > rowHeight) {
      //       rowHeight = field.height;
      //     }
      //     if (field.x + field.width > rowWidth) {
      //       // if the row Width is 0 then this is the first time through.
      //       // we don't need to add the x we only need the width
      //       if (rowWidth === 0) {
      //         rowWidth = field.width;
      //       } else {
      //         rowWidth = field.x + field.width;
      //       }
      //     }
      //   }
      // });

      // // repeat height hasn't been set yet so set it.
      // console.log(`repeat height: ${repeat.height}`);
      // if (repeat.height === 0) {
      //   repeat.height = rowHeight;
      //   repeat.width = rowWidth;
      // }
      // repeat.steps = Math.floor(repeat.height / rowHeight);
      // repeat.offset = rowHeight;

      //        }
      // get the updated repeat
      // for (let i = 0; i < state.repeats.length; i++) {
      //   if (state.repeats[i].id === repeat.id) {
      //     repeat = state.repeats[i];
      //     break;
      //   }
      // }
      // repeat = JSON.parse(
      //   JSON.stringify(this.$store.getters.repeatById(repeat.id))
      // );
      const gElement = Snap.parse("<g id='column'></g>");
      const columnRect = Snap.parse(
        "<rect x='" +
          repeat.x +
          "' y='" +
          repeat.y +
          "' width='" +
          repeat.width +
          "' height='" +
          repeat.height +
          "' style='fill: none'></rect>"
      );
      const heightRect = Snap.parse(
        "<g id='height'><rect x='0' y='0' width='" +
          repeat.width +
          "' height='" +
          repeat.offset +
          "' style='fill: none'></rect></g>"
      );
      const repeatRect = Snap.parse("<g id='repeat'></g>");
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: No overload matches this call
      state.canvas.select("svg").append(gElement);
      const columnElement = state.canvas.select("g#column");
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: No overload matches this call
      columnElement.append(columnRect);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: No overload matches this call
      columnElement.append(repeatRect);

      // move all the repeat elements into the repeat rect
      const repeatElement = columnElement.select("g#repeat");
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: No overload matches this call
      repeatElement.append(heightRect);
      // add repeat elements ordered by how they appear in the SVG
      const elements = state.canvas.selectAll("*");
      elements.forEach((element: Snap.Element) => {
        if (repeat.elementIds.includes(element.attr("sa-data-id"))) {
          const el = state.canvas.select(
            "[sa-data-id='" + element.attr("sa-data-id") + "']"
          );
          repeatElement.append(el.parent());
        }
      });

      // repeat.elementIds.forEach((elementId: string) => {
      //   let el = this.$store.getters.canvas.select(
      //     "[sa-data-id='" + elementId + "']"
      //   );

      //   repeatElement.append(el.parent());
      // });
      removeSelectionHandles(state.canvas);
      state.isChanged = true;
    },
    updateSelectedRepeat(state, payload) {
      for (let i = 0; i < state.repeats.length; i++) {
        if (state.repeats[i].isSelected) {
          // saveState(state, $("#signdesigner_snap").html(), "Update Repeat");
          saveState(
            state,
            "Update Repeat",
            [
              {
                mutationName: "selectRepeatById",
                undoValue: state.repeats[i].id,
              },
              {
                mutationName: "updateSelectedRepeat",
                undoValue: state.repeats[i],
              },
            ],
            [
              {
                mutationName: "selectRepeatById",
                redoValue: state.repeats[i].id,
              },
              { mutationName: "updateSelectedRepeat", redoValue: payload },
            ]
          );
          state.repeats[i] = payload;
          state.isChanged = true;
        }
      }
    },
    updateSelectedRepeatName(state, payload) {
      for (let i = 0; i < state.repeats.length; i++) {
        if (state.repeats[i].isSelected) {
          // saveState(
          //   state,
          //   $("#signdesigner_snap").html(),
          //   "Update Repeat Name"
          // );
          saveState(
            state,
            "Update Repeat Name",
            [
              {
                mutationName: "selectRepeatById",
                undoValue: state.repeats[i].id,
              },
              {
                mutationName: "updateSelectedRepeatName",
                undoValue: state.repeats[i].name,
              },
            ],
            [
              {
                mutationName: "selectRepeatById",
                redoValue: state.repeats[i].id,
              },
              { mutationName: "updateSelectedRepeatName", redoValue: payload },
            ]
          );
          state.repeats[i].name = payload;
          state.isChanged = true;
        }
      }
    },
    // eslint-disable-next-line
    updateSelectedRepeatSteps(state, steps: number) {
      for (let i = 0; i < state.repeats.length; i++) {
        if (state.repeats[i].isSelected) {
          // saveState(
          //   state,
          //   $("#signdesigner_snap").html(),
          //   "Update Repeat Steps"
          // );
          saveState(
            state,
            "Update Repeat Steps",
            [
              {
                mutationName: "selectRepeatById",
                undoValue: state.repeats[i].id,
              },
              {
                mutationName: "updateSelectedRepeatSteps",
                undoValue: state.repeats[i].steps,
              },
            ],
            [
              {
                mutationName: "selectRepeatById",
                redoValue: state.repeats[i].id,
              },
              {
                mutationName: "updateSelectedRepeatSteps",
                redoValue: steps,
              },
            ]
          );
          state.repeats[i].steps = steps;
          state.isChanged = true;
          // drawRepeatElements(payload.thisFromVue);
        }
      }
    },
    updateSelectedRepeatOffset(state, payload) {
      for (let i = 0; i < state.repeats.length; i++) {
        if (state.repeats[i].isSelected) {
          // saveState(
          //   state,
          //   $("#signdesigner_snap").html(),
          //   "Update Repeat Height"
          // );
          saveState(
            state,
            "Update Repeat Height",
            [
              {
                mutationName: "selectRepeatById",
                undoValue: state.repeats[i].id,
              },
              {
                mutationName: "updateSelectedRepeatOffset",
                undoValue: state.repeats[i].offset,
              },
            ],
            [
              {
                mutationName: "selectRepeatById",
                redoValue: state.repeats[i].id,
              },
              {
                mutationName: "updateSelectedRepeatOffset",
                redoValue: payload,
              },
            ]
          );
          state.repeats[i].offset = payload;
          state.isChanged = true;
        }
      }
    },
    updateSelectedRepeatWidth(state, payload) {
      for (let i = 0; i < state.repeats.length; i++) {
        if (state.repeats[i].isSelected) {
          // saveState(
          //   state,
          //   $("#signdesigner_snap").html(),
          //   "Update Repeat Width"
          // );
          saveState(
            state,
            "Update Repeat Width",
            [
              {
                mutationName: "selectRepeatById",
                undoValue: state.repeats[i].id,
              },
              {
                mutationName: "updateSelectedRepeatWidth",
                undoValue: state.repeats[i].width,
              },
            ],
            [
              {
                mutationName: "selectRepeatById",
                redoValue: state.repeats[i].id,
              },
              { mutationName: "updateSelectedRepeatWidth", redoValue: payload },
            ]
          );
          state.repeats[i].width = payload;
          state.isChanged = true;
        }
      }
    },
    updateSelectedRepeatHeight(state, payload) {
      for (let i = 0; i < state.repeats.length; i++) {
        if (state.repeats[i].isSelected) {
          if (state.repeats[i].height !== payload) {
            // I wouldn't save state here as it causes extra history entries to be added to the stack
            // which we don't need
            state.repeats[i].height = payload;
            state.isChanged = true;
          }
        }
      }
    },
    updateRepeat(state, repeat) {
      for (let i = 0; i < state.repeats.length; i++) {
        if (state.repeats[i].id === repeat.id) {
          state.repeats.splice(i, 1, repeat);
          state.isChanged = true;
        }
      }
    },
    deselectAllRepeats(state) {
      state.repeats.forEach((repeat) => {
        repeat.isSelected = false;
      });
    },
    deselectRepeatById(state, id) {
      for (let i = 0; i < state.repeats.length; i++) {
        if (state.repeats[i].id === id) {
          state.repeats[i].isSelected = false;
        }
      }
    },
    selectRepeatById(state, repeatId) {
      //deselect all repeats
      state.repeats.forEach((repeat) => {
        repeat.isSelected = false;
        if (repeat.id === repeatId) {
          repeat.isSelected = true;

          // we don't want to select the child elements of a repeat
          // state.selectedElementIds = [];
          // repeat.elementIds.forEach((elementId) => {
          //   state.selectedElementIds.push(elementId);
          // });
        }
      });
    },
    selectRepeatByName(state, repeatName) {
      //deselect all repeats
      state.repeats.forEach((repeat) => {
        repeat.isSelected = false;
        if (repeat.name === repeatName) {
          repeat.isSelected = true;
        }
      });
    },
    removeRepeat(state, repeat) {
      // this one shouldn't save state, it is a reverse action
      for (let i = 0; i < state.repeats.length; i++) {
        if (state.repeats[i].id === repeat.id) {
          const columnElement = state.canvas.select("g#column") as Snap.Element;
          const parentElement = columnElement.parent();

          //move the fields outside of the repeat structure
          state.repeats[i].elementIds.forEach((elementId: string) => {
            const el = state.canvas.select("[sa-data-id='" + elementId + "']");
            parentElement.append(el.parent());
          });
          //remove the repeat structure

          for (let i = 0; i < columnElement.children.length; i++) {
            columnElement.children()[i].remove();
          }
          columnElement.remove();

          removeSelectionHandles(state.canvas);
          /* eslint-disable-next-line */
          // (this.$root.$refs.SVGRenderer as any).processSVG(false, true);

          state.repeats.splice(i, 1);

          // get rid of the cloned elements
          state.canvas
            .selectAll(".repeat-clone")
            .forEach((el: Snap.Element) => {
              el.remove();
            });

          break;
        }
      }
    },
    deleteSelectedRepeat(state) {
      let index = -1;
      for (let i = 0; i < state.repeats.length; i++) {
        if (state.repeats[i].isSelected) {
          index = i;
          break;
        }
      }
      if (index > -1) {
        state.repeats.splice(index, 1);
      }
    },
    deleteSelectedRepeatClonedElementIds(state) {
      let index = -1;
      for (let i = 0; i < state.repeats.length; i++) {
        if (state.repeats[i].isSelected) {
          index = i;
          break;
        }
      }
      if (index > -1) {
        state.repeats[index].clonedElementIds = [];
      }
    },
    addSelectedRepeatClonedElementId(state, id: string) {
      let index = -1;
      for (let i = 0; i < state.repeats.length; i++) {
        if (state.repeats[i].isSelected) {
          index = i;
          break;
        }
      }
      if (index > -1) {
        state.repeats[index].clonedElementIds.push(id);
      }
    },
    incrementNextRepeatNumber(state) {
      state.nextRepeatNumber++;
    },
    setNextRepeatNumber(state, nextRepeatNumber: number) {
      state.nextRepeatNumber = nextRepeatNumber;
    },
    clearSelectedElementIds(state) {
      state.selectedElementIds = [];
    },
    removeSelectedElementId(state, id) {
      for (let i = 0; i < state.selectedElementIds.length; i++) {
        if (state.selectedElementIds[i] === id) {
          state.selectedElementIds.splice(i, 1);
          break;
        }
      }
    },
    addSelectedElementId(state, elementId) {
      state.selectedElementIds.push(elementId);
    },
    setCurrentOrganizationUUID(state, payload) {
      state.currentOrganizationUUID = payload;
    },
    setCurrentSignTypeUUID(state, payload) {
      state.currentSignTypeUUID = payload;
    },
    setPreviousSignTypeUUID(state, payload) {
      state.previousSignTypeUUID = payload;
    },
    setFiles(state, payload) {
      state.files = payload;
    },
    addFile(state, payload) {
      // saveState(state, $("#signdesigner_snap").html(), "Add File");
      saveState(
        state,
        "Add File",
        [{ mutationName: "setFiles", undoValue: state.files }],
        [{ mutationName: "addFile", redoValue: payload }]
      );
      state.files.push(payload);
      state.isChanged = true;
    },
    updateSelectedFile(state, payload) {
      for (let i = 0; i < state.files.length; i++) {
        if (state.files[i].isSelected) {
          // saveState(state, $("#signdesigner_snap").html(), "Update File");
          saveState(
            state,
            "Update File",
            [
              {
                mutationName: "selectFileById",
                undoValue: state.files[i].id,
              },
              { mutationName: "updateSelectedFile", undoValue: state.files[i] },
            ],
            [
              {
                mutationName: "selectFileById",
                redoValue: state.files[i].id,
              },
              { mutationName: "updateSelectedFile", redoValue: payload },
            ]
          );
          state.files[i] = payload;
          state.isChanged = true;
        }
      }
    },
    updateSelectedFileName(state, payload) {
      for (let i = 0; i < state.files.length; i++) {
        if (state.files[i].isSelected) {
          // saveState(state, $("#signdesigner_snap").html(), "Update File Name");
          saveState(
            state,
            "Update File Name",
            [
              {
                mutationName: "selectFileById",
                undoValue: state.files[i].id,
              },
              {
                mutationName: "updateSelectedFileName",
                undoValue: state.files[i].name,
              },
            ],
            [
              {
                mutationName: "selectFileById",
                redoValue: state.files[i].id,
              },
              { mutationName: "updateSelectedFileName", redoValue: payload },
            ]
          );
          state.files[i].name = payload;
          state.isChanged = true;
        }
      }
    },
    updateSelectedFilePath(state, payload) {
      for (let i = 0; i < state.files.length; i++) {
        if (state.files[i].isSelected) {
          // saveState(state, $("#signdesigner_snap").html(), "Update File Path");
          saveState(
            state,
            "Update File Path",
            [
              {
                mutationName: "selectFileById",
                undoValue: state.files[i].id,
              },
              {
                mutationName: "updateSelectedFilePath",
                undoValue: state.files[i].path,
              },
            ],
            [
              {
                mutationName: "selectFileById",
                redoValue: state.files[i].id,
              },
              { mutationName: "updateSelectedFilePath", redoValue: payload },
            ]
          );
          state.files[i].path = payload;
          state.isChanged = true;
        }
      }
    },
    updateFile(state, file) {
      for (let i = 0; i < state.files.length; i++) {
        if (state.files[i].id === file.id) {
          // saveState(state, $("#signdesigner_snap").html(), "Update File");
          saveState(
            state,
            "Update File",
            [{ mutationName: "setFiles", undoValue: state.files }],
            [{ mutationName: "updateFile", redoValue: file }]
          );
          state.files.splice(i, 1, file);
          state.isChanged = true;
        }
      }
    },
    deselectAllFiles(state) {
      state.files.forEach((file) => {
        file.isSelected = false;
      });
    },
    selectFileById(state, fileId) {
      //deselect all fields
      state.files.forEach((file) => {
        file.isSelected = false;
        if (file.id === fileId) {
          file.isSelected = true;

          //how can I now select all SVG elements that are associated with this field?
          state.selectedElementIds = [];
        }
      });
    },
    deleteSelectedFile(state) {
      let index = -1;
      for (let i = 0; i < state.files.length; i++) {
        if (state.files[i].isSelected) {
          index = i;
          break;
        }
      }
      if (index > -1) {
        // saveState(state, $("#signdesigner_snap").html(), "Delete File");
        saveState(
          state,
          "Delete File",
          [
            {
              mutationName: "selectFileById",
              undoValue: state.files[index].id,
            },
            { mutationName: "setFiles", undoValue: state.files },
          ],
          [
            {
              mutationName: "selectFileById",
              redoValue: state.files[index].id,
            },
            { mutationName: "addFile", redoValue: state.files[index] },
          ]
        );
        state.files.splice(index, 1);
        //remove the svg data
        state.canvas.select("*").remove();
        // state.canvas = null;
      }
    },
    restoreSignTemplate(state, payload) {
      state.files = payload.files;
      state.parts = payload.parts;
      state.signMaterials = payload.signMaterials;
      state.signProcesses = payload.signProcesses;
      state.repeats = payload.repeats;
      state.alignments = payload.alignments;
      state.selectedElementIds = payload.selectedElementIds;
      state.fields = payload.fields;
      state.signData = payload.signData;
      state.canvas = payload.canvas;

      $("#signdesigner_snap").html(payload.svgString);

      //remove all click handlers set flags so that click handlers will be attached
      state.canvas
        .selectAll("[click-handler='set']")
        .forEach((el: Snap.Element) => {
          el.attr({ "click-handler": "" });
        });
    },
    clearSignTemplate(state, clearSignData: boolean) {
      // saveState(state, $("#signdesigner_snap").html(), "Clear Template");
      const savedState = {
        files: state.files,
        parts: state.parts,
        signMaterials: state.signMaterials,
        signProcesses: state.signProcesses,
        repeats: state.repeats,
        alignments: state.alignments,
        selectedElementIds: state.selectedElementIds,
        fields: state.fields,
        signData: state.signData,
        canvas: state.canvas,
        svgString: $("#signdesigner_snap").html(),
      };
      saveState(
        state,
        "Clear Sign Template",
        [{ mutationName: "restoreSignTemplate", undoValue: savedState }],
        [{ mutationName: "clearSignTemplate", redoValue: clearSignData }]
      );

      state.files = [];
      state.parts = [];
      state.signMaterials = [];
      state.signProcesses = [];
      state.repeats = [];
      state.alignments = [];
      state.selectedElementIds = [];
      // deselect all fields and used fields
      state.fields.forEach((field) => {
        field.isSelected = false;
        field.isUsed = false;
      });

      if (clearSignData) {
        const signData = JSON.parse(JSON.stringify(state.signData));
        signData.sides[0].children = [];
        state.signData = signData;
      }

      if (!isEmpty(state.canvas)) {
        state.canvas.select("*")?.remove();
        // state.canvas.clear();
      }
    },
    setImages(state, payload) {
      state.images = payload;
    },
    addImage(state, payload) {
      // saveState(state, $("#signdesigner_snap").html(), "Add Image");
      saveState(
        state,
        "Add Image",
        [{ mutationName: "setImages", undoValue: state.images }],
        [{ mutationName: "addImage", redoValue: payload }]
      );
      state.images.push(payload);
      state.isChanged = true;
    },
    updateSelectedImage(state, payload) {
      for (let i = 0; i < state.images.length; i++) {
        if (state.images[i].isSelected) {
          // saveState(state, $("#signdesigner_snap").html(), "Update Image");
          saveState(
            state,
            "Update Image",
            [{ mutationName: "setImages", undoValue: state.images }],
            [{ mutationName: "updateSelectedImage", redoValue: payload }]
          );
          state.images[i] = payload;
          state.isChanged = true;
        }
      }
    },
    updateSelectedImageName(state, payload) {
      for (let i = 0; i < state.images.length; i++) {
        if (state.images[i].isSelected) {
          // saveState(state, $("#signdesigner_snap").html(), "Update Image Name");
          saveState(
            state,
            "Update Image Name",
            [
              {
                mutationName: "updateSelectedImageName",
                undoValue: state.images[i].name,
              },
            ],
            [{ mutationName: "updateSelectedImageName", redoValue: payload }]
          );
          state.images[i].name = payload;
          state.isChanged = true;
        }
      }
    },
    updateSelectedImagePath(state, payload) {
      for (let i = 0; i < state.images.length; i++) {
        if (state.images[i].isSelected) {
          // saveState(state, $("#signdesigner_snap").html(), "Update Image Path");
          saveState(
            state,
            "Update Image Path",
            [
              {
                mutationName: "updateSelectedImagePath",
                undoValue: state.images[i].path,
              },
            ],
            [{ mutationName: "updateSelectedImagePath", redoValue: payload }]
          );
          state.images[i].path = payload;
          state.isChanged = true;
        }
      }
    },
    updateImage(state, image) {
      for (let i = 0; i < state.images.length; i++) {
        if (state.images[i].id === image.id) {
          // saveState(state, $("#signdesigner_snap").html(), "Update Image");
          saveState(
            state,
            "Update Image",
            [{ mutationName: "setImages", undoValue: state.images }],
            [{ mutationName: "updateImage", redoValue: image }]
          );
          state.images.splice(i, 1, image);
          state.isChanged = true;
        }
      }
    },
    deselectAllImages(state) {
      state.images.forEach((image) => {
        image.isSelected = false;
      });
    },
    selectImageById(state, imageId) {
      //deselect all fields
      state.images.forEach((image) => {
        image.isSelected = false;
        if (image.id === imageId) {
          image.isSelected = true;

          //how can I now select all SVG elements that are associated with this field?
          state.selectedElementIds = [];
        }
      });
    },
    deleteSelectedImage(state) {
      let index = -1;
      for (let i = 0; i < state.images.length; i++) {
        if (state.images[i].isSelected) {
          index = i;
          break;
        }
      }
      if (index > -1) {
        // saveState(state, $("#signdesigner_snap").html(), "Delete Image");
        saveState(
          state,
          "Delete Image",
          [
            {
              mutationName: "selectImageById",
              undoValue: state.images[index].id,
            },
            { mutationName: "setImages", undoValue: state.images },
          ],
          [
            {
              mutationName: "selectImageById",
              redoValue: state.images[index].id,
            },
            { mutationName: "deleteSelectedImage", redoValue: null },
          ]
        );
        state.images.splice(index, 1);
      }
    },
    setSignTypes(state, payload) {
      state.signTypes = payload;
    },
    addSignType(state, payload) {
      // in order to ensure that SignTypes is reactive we push the new sign type to
      // a temporary array and then overwrite signTypes with it.
      const tempArr = state.signTypes;
      tempArr.push(payload);
      state.signTypes = tempArr;
    },
    selectSignTypeById(state, id) {
      state.signTypes.forEach((signType) => {
        signType.isSelected = false;
        if (signType.id === id) {
          signType.isSelected = true;
        }
      });
    },
    setSignTypeName(state, payload) {
      state.signTypeName = payload;
    },
    clearSignTypes(state) {
      state.signTypes = [];
    },
    setSignCount(state, payload) {
      state.signCount = payload;
    },
    updateElementFillColorById(
      state,
      payload: { elementId: string; hex: string }
    ) {
      function findElementById(
        element: iSignChild,
        id: string
      ): iSignChild | null {
        if (element.id == id) {
          return element;
        } else if (element.children != null) {
          let result = {} as iSignChild | null;
          for (let i = 0; result == null && i < element.children.length; i++) {
            result = findElementById(element.children[i], id);
          }
          return result;
        }
        return null;
      }

      for (
        let i = 0;
        i < state.signData.sides[state.currentSide].children.length;
        i++
      ) {
        const result = findElementById(
          state.signData.sides[state.currentSide].children[i],
          payload.elementId
        );
        if (result) {
          if (result.id === payload.elementId) {
            const selectedElement = result;
            const color = payload.hex;
            const el = state.canvas.select(
              "[sa-data-id='" + selectedElement.svgId + "']"
            );
            if (selectedElement.fieldType.toLowerCase() === "color") {
              if (el.select("rect")) {
                el.select("rect").attr({
                  style: "fill: " + hexString(color),
                });
              } else {
                el.attr({ style: "fill: " + hexString(color) });
              }
            } else if (selectedElement.fieldType.toLowerCase() === "text") {
              if (el.select("text")) {
                el.select("text").attr({
                  style: "fill: " + hexString(color),
                });
              } else {
                el.attr({
                  style: "fill: " + hexString(color),
                });
              }
            } else {
              el.attr({ style: "fill: " + hexString(color) });
            }

            result.fill = payload.hex;
            state.signData.sides[state.currentSide].children[i].fill =
              payload.hex;
            break;
          }
        } else {
          console.log("element not found");
        }
      }
    },
    setIsDraft(state, payload) {
      state.isDraft = payload;
    },
    setIsChanged(state, payload) {
      state.isChanged = payload;
    },
    setProductFamilies(state, payload) {
      state.productFamilies = payload;
    },
    addProductFamily(state, payload) {
      // saveState(state, $("#signdesigner_snap").html(), "Add Product Family");
      saveState(
        state,
        "Add Product Family",
        [
          {
            mutationName: "setProductFamilies",
            undoValue: state.productFamilies,
          },
        ],
        [{ mutationName: "addProductFamily", redoValue: payload }]
      );
      state.productFamilies.push(payload);
    },
    updateProductFamilyNameByIndex(state, payload) {
      state.productFamilies[payload.index].name = payload.value;
      state.isChanged = true;
    },
    updateProductFamilyDescriptionByIndex(state, payload) {
      state.productFamilies[payload.index].description = payload.value;
      state.isChanged = true;
    },
    updateProductFamilyImageURLByIndex(state, payload) {
      state.productFamilies[payload.index].imageURL = payload.value;
      state.isChanged = true;
    },
    selectProductFamilyById(state, family: IProductFamily) {
      for (let i = 0; i < state.productFamilies.length; i++) {
        //deselect all other families
        state.productFamilies[i].isSelected = false;
        if (state.productFamilies[i].uuid === family.uuid) {
          state.productFamilies[i].isSelected = true;
        }
      }
    },
    toggleSelectedProductFamilyById(state, family: IProductFamily) {
      for (let i = 0; i < state.productFamilies.length; i++) {
        if (state.productFamilies[i].uuid === family.uuid) {
          state.productFamilies[i].isSelected =
            !state.productFamilies[i].isSelected;
        }
      }
    },
    selectProductCategoryByUUID(state, category: IProductCategory) {
      for (let i = 0; i < state.productCategories.length; i++) {
        //deselect all other categories
        state.productCategories[i].isSelected = false;
        if (state.productCategories[i].uuid === category.uuid) {
          state.productCategories[i].isSelected = true;
        }
      }
    },
    toggleSelectedProductCategoryById(state, category: IProductCategory) {
      for (let i = 0; i < state.productCategories.length; i++) {
        if (state.productCategories[i].uuid === category.uuid) {
          state.productCategories[i].isSelected =
            !state.productCategories[i].isSelected;
        }
      }
    },
    toggleSelectedProductTypeById(state, type: IProductType) {
      for (let i = 0; i < state.productTypes.length; i++) {
        if (state.productTypes[i].uuid === type.uuid) {
          state.productTypes[i].isSelected = !state.productTypes[i].isSelected;
        }
      }
    },
    selectProductTypeByUUID(state, type: IProductType) {
      for (let i = 0; i < state.productTypes.length; i++) {
        //deselect all other categories
        state.productTypes[i].isSelected = false;
        if (state.productTypes[i].uuid === type.uuid) {
          state.productTypes[i].isSelected = true;
        }
      }
    },
    deselectAllProductFamilies(state) {
      for (let i = 0; i < state.productFamilies.length; i++) {
        //deselect all other families
        state.productFamilies[i].isSelected = false;
      }
    },
    deselectProductFamilyByUUID(state, uuid: string) {
      for (let i = 0; i < state.productFamilies.length; i++) {
        if (state.productFamilies[i].uuid === uuid) {
          //deselect it
          state.productFamilies[i].isSelected = false;
        }
      }
    },
    deleteProductFamilyByIndex(state, index) {
      // saveState(state, $("#signdesigner_snap").html(), "Delete Product Family");
      saveState(
        state,
        "Delete Product Family",
        [
          {
            mutationName: "setProductFamilies",
            undoValue: state.productFamilies,
          },
        ],
        [{ mutationName: "deleteProductFamilyByIndex", redoValue: index }]
      );
      state.productFamilies.splice(index, 1);
      state.isChanged = true;
    },
    setProductCategories(state, payload) {
      state.productCategories = payload;
    },
    addProductCategory(state, payload) {
      // saveState(state, $("#signdesigner_snap").html(), "Add Product Category");
      saveState(
        state,
        "Add Product Category",
        [
          {
            mutationName: "setProductCategories",
            undoValue: state.productCategories,
          },
        ],
        [{ mutationName: "addProductCategory", redoValue: payload }]
      );
      state.productCategories.push(payload);
    },
    deselectProductCategoryByUUID(state, uuid: string) {
      for (let i = 0; i < state.productCategories.length; i++) {
        if (state.productCategories[i].uuid === uuid) {
          //deselect it
          state.productCategories[i].isSelected = false;
        }
      }
    },
    updateProductCategory(state, category: IProductCategory) {
      for (let i = 0; i < state.productCategories.length; i++) {
        if (state.productCategories[i].uuid === category.uuid) {
          state.productCategories[i] = category;
          break;
        }
      }
    },
    updateProductCategoryNameByIndex(state, payload) {
      state.productCategories[payload.index].name = payload.value;
      state.isChanged = true;
    },
    updateProductCategoryImageURLByIndex(state, payload) {
      state.productCategories[payload.index].imageURL = payload.value;
      state.isChanged = true;
    },
    deleteProductCategoryByIndex(state, index) {
      state.productCategories.splice(index, 1);
      state.isChanged = true;
    },
    setProductTypes(state, payload) {
      state.productTypes = payload;
    },
    addProductType(state, payload) {
      // saveState(state, $("#signdesigner_snap").html(), "Add Product Type");
      saveState(
        state,
        "Add Product Type",
        [{ mutationName: "setProductTypes", undoValue: state.productTypes }],
        [{ mutationName: "addProductType", redoValue: payload }]
      );
      state.productTypes.push(payload);
    },
    deselectProductTypeByUUID(state, uuid: string) {
      for (let i = 0; i < state.productTypes.length; i++) {
        if (state.productTypes[i].uuid === uuid) {
          //deselect it
          state.productTypes[i].isSelected = false;
        }
      }
    },
    updateProductTypeNameByIndex(state, payload) {
      state.productTypes[payload.index].name = payload.value;
      state.isChanged = true;
    },
    updateProductTypeImageURLByIndex(state, payload) {
      state.productTypes[payload.index].imageURL = payload.value;
      state.isChanged = true;
    },
    deleteProductTypeByIndex(state, index) {
      state.productTypes.splice(index, 1);
      state.isChanged = true;
    },
    selectProductByUUID(state, product: iCatalog) {
      for (let i = 0; i < state.catalog.length; i++) {
        //deselect all other families
        state.catalog[i].isSelected = false;
        if (state.catalog[i].uuid === product.uuid) {
          state.catalog[i].isSelected = true;
          break;
        }
      }
    },
    setColorLibraries(state, payload) {
      state.colorLibraries = payload;
    },
    setTranslationLanguages(state, payload) {
      state.translationLanguages = payload;
    },
    setZoomIndex(state, payload) {
      if (payload < 0) {
        state.zoomIndex = 0;
      } else if (payload >= state.zoomFactors.length) {
        state.zoomIndex = state.zoomFactors.length - 1;
      } else {
        state.zoomIndex = payload;
      }
    },
    setShowZoomMenu(state, payload) {
      state.showZoomMenu = payload;
    },
    setShowAddVisualsModal(state, payload) {
      state.showAddVisualsModal = payload;
    },
    setShowUnsupportedFeatureModal(state, payload) {
      state.showUnsupportedFeatureModal = payload;
    },
    setUnsupportedFeature(state, payload) {
      state.unsupportedFeature = payload;
    },
    /**
     * update the code editor by toggling the selected field
     * @param state The vuex state
     */
    refreshCodeEditor(state) {
      // NOTE: the code editor component has a watcher on the selectedField
      // and when the selectedField changes it updates the code editor field
      // So by toggling the selected field on and off we force an update
      // track whether or not we found a selected field
      let fieldFound = false;

      // if we have a selected field then toggle it's isSelected value
      // to force a rerender of the code editor field
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].isSelected) {
          fieldFound = true;
          state.fields[i].isSelected = false;
          state.fields[i].isSelected = true;
        }
      }

      // if we don't have a selected field toggle the first field
      // that isn't used on the sign template to true
      // which will force a rerender of the code editor field
      // this is the case when we are working with an element that
      // doesn't have a field defined on it. For example: a background color
      if (!fieldFound) {
        for (let i = 0; i < state.fields.length; i++) {
          // if the field doesn't have any elements associated with it then it isn't used on the template
          if (state.fields[i].elementIds.length === 0) {
            state.fields[0].isSelected = true;
          }
        }
      }
    },
    setFeatureAccess(state, featureAccess) {
      this.featureAccess = featureAccess;
    },
    addFeatureAccess(state, payload) {
      // saveState(state, $("#signdesigner_snap").html(), "Add Feature Access");
      saveState(
        state,
        "Add Feature Access",
        [{ mutationName: "setFeatureAccess", undoValue: state.featureAccess }],
        [{ mutationName: "addFeatureAccess", redoValue: payload }]
      );
      state.featureAccess.push(payload);
    },
    removeFeatureAccess(state, payload) {
      const index = state.featureAccess.indexOf(payload);
      // saveState(state, $("#signdesigner_snap").html(), "Remove feature Access");
      saveState(
        state,
        "Remove Feature Access",
        [{ mutationName: "setFeatureAccess", undoValue: state.featureAccess }],
        [{ mutationName: "removeFeatureAccess", redoValue: payload }]
      );
      state.featureAccess.splice(index, 1);
    },
    updateFeatures(state, payload) {
      // saveState(state, $("#signdesigner_snap").html(), "Update Features");
      saveState(
        state,
        "Update Features",
        [{ mutationName: "setFeaturesAccess", undoValue: state.featureAccess }],
        [{ mutationName: "updateFeatures", redoValue: payload }]
      );
      state.features.push(payload);
    },
    setShowFeatureAccessModal(state, payload) {
      state.showFeatureAccessModal = payload;
    },
    setTrackAddedField(state, payload) {
      state.trackAddedField = payload;
    },
    setAddedFieldId(state, fieldId) {
      state.addedFieldId = fieldId;
    },
    saveHistory(state, payload) {
      state.history.push(payload);
    },
    saveRedoHistory(state, payload) {
      state.redoHistory.push(payload);
    },
    setExitAfterPublish(state, payload) {
      state.exitAfterPublish = payload;
    },
    popHistory(state) {
      if (state.history.length > 0) {
        state.history.pop();
      }
    },
    popRedoHistory(state) {
      if (state.redoHistory.length > 0) {
        state.redoHistory.pop();
      }
    },
    setHistory(state, payload) {
      state.history = payload;
    },
    setRedoHistory(state, payload) {
      state.redoHistory = payload;
    },
    setShowHistoryModal(state, payload) {
      state.showHistoryModal = payload;
    },
    setShowMarketPlaceModal(state, payload) {
      // again make sure to specify the correct variable name
      state.showMarketPlaceModal = payload;
    },
    addMarketplaceConnection(state, item: IMarketplaceItem) {
      state.marketplaceConnections.push(item);
    },
    selectMarketplaceConnection(state, id: string) {
      for (let i = 0; i < state.marketplaceConnections.length; i++) {
        state.marketplaceConnections[i].isSelected = false;
        if (state.marketplaceConnections[i].id === id) {
          state.marketplaceConnections[i].isSelected = true;
        }
      }
    },
    deselectAllMarketplaceConnections(state) {
      state.marketplaceConnections.forEach((connection) => {
        connection.isSelected = false;
      });
    },
    deleteMarketplaceConnection(state, id: string) {
      for (let i = 0; i < state.marketplaceConnections.length; i++) {
        if (state.marketplaceConnections[i].id === id) {
          state.marketplaceConnections.splice(i, 1);
          break;
        }
      }
    },
    captureRedoState(state, payload) {
      const newChange = {
        description: payload.description,
        undoMutation: payload.undoMutation,
        redoMutation: payload.redoMutation,
      } as IHistory;
      state.redoHistory.push(newChange);
    },
    updateCodeEditor(state, commands: string) {
      saveState(
        state,
        "Update Code Editor",
        [{ mutationName: "updateCodeEditor", undoValue: state.commands }],
        [{ mutationName: "updateCodeEditor", redoValue: commands }]
      );
      state.commands = commands;

      // find the selected field
      let selectedField = {} as IField;
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].isSelected) {
          selectedField = state.fields[i];
          break;
        }
      }

      if (commands !== "") {
        let rect = {} as Snap.Element;
        if (selectedField) {
          rect = getNoFillNoStrokeByElementId(
            selectedField.elementIds[0],
            state.canvas
          );
        } else {
          rect = state.canvas.select(
            "[sa-data-id='" + state.selectedElementIds[0] + "']"
          );
        }
        rect.attr({ "data-name": commands });
        rect.attr({
          id: normalCaseToUnderscore(commands.split(",").join(" ")),
        });
      }
    },
    updateCommands(state, commands) {
      state.commands = commands;
    },
  },

  getters: {
    processingUndo(state) {
      return state.processingUndo;
    },
    canvas(state) {
      return state.canvas;
    },
    user(state) {
      return state.user;
    },
    organizationId(state) {
      return state.organizationId;
    },
    selectedElements(state) {
      return state.selectedElements;
    },
    selectedElementIds(state) {
      return state.selectedElementIds;
    },
    fields(state) {
      //return fields sorted by name
      return state.fields;
    },
    selectedField(state) {
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].isSelected) {
          return state.fields[i];
        }
      }
      return null;
    },
    selectSignChild(state) {
      for (
        let i = 0;
        i < state.signData.sides[state.currentSide].children.length;
        i++
      ) {
        if (state.signData.sides[state.currentSide].children[i].isSelected) {
          return state.signData.sides[state.currentSide].children[i];
        }
      }
    },
    isFieldSelected(state) {
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].isSelected) {
          return true;
        }
      }
      return false;
    },
    usedFields(state) {
      const arr = [] as Array<IField>;
      state.fields.forEach((field) => {
        if (field.isUsed) {
          arr.push(field);
        }
      });
      return arr;
    },
    unUsedFields(state) {
      const arr = [] as Array<IField>;
      state.fields.forEach((field) => {
        if (!field.isUsed) {
          arr.push(field);
        }
      });
      return arr;
    },
    fieldByElementId: (state) => (elementId: string) => {
      const retval: IField = {
        id: "",
        isSelected: false,
        isUsed: false,
        name: "",
        type: "",
        x: NaN,
        y: NaN,
        width: NaN,
        height: NaN,
        capHeight: NaN,
        leading: NaN,
        lines: NaN,
        letterSpacing: "",
        horizontalAlignment: "left",
        verticalAlignment: "top",
        hasPlaceholderImage: false,
        placeholderImageUrl: "",
        belongsToRepeatId: null,
        elementIds: [],
      };

      for (let i = 0; i < state.fields.length; i++) {
        for (let j = 0; j < state.fields[i].elementIds.length; j++) {
          if (state.fields[i].elementIds[j] === elementId) {
            return state.fields[i];
          }
        }
      }
      return retval;
    },
    fieldByName: (state) => (fieldName: string) => {
      for (let i = 0; i < state.fields.length; i++) {
        if (state.fields[i].name.toLowerCase() === fieldName.toLowerCase()) {
          return state.fields[i];
        }
      }
      return null;
    },
    parts(state) {
      return state.parts;
    },
    selectedPart(state) {
      for (let i = 0; i < state.parts.length; i++) {
        if (state.parts[i].isSelected) {
          return state.parts[i];
        }
      }
      return null;
    },
    isPartSelected(state) {
      for (let i = 0; i < state.parts.length; i++) {
        if (state.parts[i].isSelected) {
          return true;
        }
      }
      return false;
    },
    materials(state) {
      return state.materials;
    },
    signMaterials(state) {
      return state.signMaterials;
    },
    selectedSignMaterial(state) {
      for (let i = 0; i < state.signMaterials.length; i++) {
        if (state.signMaterials[i].isSelected) {
          return state.signMaterials[i];
        }
      }
      return null;
    },
    isSignMaterialSelected(state) {
      for (let i = 0; i < state.signMaterials.length; i++) {
        if (state.signMaterials[i].isSelected) {
          return true;
        }
      }
      return false;
    },
    processes(state) {
      return state.processes;
    },
    signProcesses(state) {
      return state.signProcesses;
    },
    isSignProcessSelected(state) {
      for (let i = 0; i < state.signProcesses.length; i++) {
        if (state.signProcesses[i].isSelected) {
          return true;
        }
      }
      return false;
    },
    selectedSignProcess(state) {
      for (let i = 0; i < state.signProcesses.length; i++) {
        if (state.signProcesses[i].isSelected) {
          return state.signProcesses[i];
        }
      }
      return null;
    },
    repeats(state) {
      return state.repeats;
    },
    selectedRepeat(state) {
      for (let i = 0; i < state.repeats.length; i++) {
        if (state.repeats[i].isSelected) {
          return state.repeats[i];
        }
      }
      return null;
    },
    isRepeatSelected(state) {
      for (let i = 0; i < state.repeats.length; i++) {
        if (state.repeats[i].isSelected) {
          return true;
        }
      }
      return false;
    },
    repeatById: (state) => (id: string) => {
      for (let i = 0; i < state.repeats.length; i++) {
        if (state.repeats[i].id === id) {
          return state.repeats[i];
        }
      }
      return null;
    },
    repeatExists: (state) => (selectedElementIds: Array<string>) => {
      let repeatFound = false;

      for (let i = 0; i < state.repeats.length; i++) {
        if (state.repeats[i].elementIds.length !== selectedElementIds.length) {
          repeatFound = false;
        } else {
          repeatFound = state.repeats[i].elementIds.every(
            (elem, index) => elem === selectedElementIds[index]
          );
        }
      }

      return repeatFound;
    },
    nextRepeatNumber(state) {
      return state.nextRepeatNumber;
    },
    files(state) {
      return state.files;
    },
    selectedFile(state) {
      for (let i = 0; i < state.files.length; i++) {
        if (state.files[i].isSelected) {
          return state.files[i];
        }
      }
      return null;
    },
    isFileSelected(state) {
      for (let i = 0; i < state.files.length; i++) {
        if (state.files[i].isSelected) {
          return true;
        }
      }
      return false;
    },
    images(state) {
      return state.images;
    },
    selectedImage(state) {
      for (let i = 0; i < state.images.length; i++) {
        if (state.images[i].isSelected) {
          return state.images[i];
        }
      }
      return null;
    },
    isImageSelected(state) {
      for (let i = 0; i < state.images.length; i++) {
        if (state.images[i].isSelected) {
          return true;
        }
      }
      return false;
    },
    alignments(state) {
      return state.alignments;
    },
    selectedAlignment(state) {
      for (let i = 0; i < state.alignments.length; i++) {
        if (state.alignments[i].isSelected) {
          return state.alignments[i];
        }
      }
      return null;
    },
    selectedAlignments(state) {
      const alignments = [] as Array<IAlignment>;
      for (let i = 0; i < state.alignments.length; i++) {
        if (state.alignments[i].isSelected) {
          alignments.push(state.alignments[i]);
        }
      }
      return alignments;
    },
    isAlignmentSelected(state) {
      for (let i = 0; i < state.alignments.length; i++) {
        if (state.alignments[i].isSelected) {
          return true;
        }
      }
      return false;
    },
    isTargetAlignmentSelected(state) {
      for (let i = 0; i < state.alignments.length; i++) {
        if (state.alignments[i].isSelected) {
          for (let j = 0; j < state.alignments[i].targets.length; j++) {
            if (state.alignments[i].targets[j].isSelected) {
              return true;
            }
          }
        }
      }
      return false;
    },
    alignmentByUUID: (state) => (uuid: string) => {
      for (let i = 0; i < state.alignments.length; i++) {
        if (state.alignments[i].uuid === uuid) {
          return state.alignments[i];
        }
      }
    },
    zoomPercentage(state) {
      return state.zoomPercentage;
    },
    activeTab(state) {
      return state.activeTab;
    },
    sidesTab(state) {
      return state.sidesTab;
    },
    // components(state) {
    //   return state.components;
    // },
    selectedComponents(state) {
      let components = [] as Array<{ isActive: boolean }>;
      components = state.components.filter(function (component) {
        return component.isActive === true;
      });

      return components;
    },
    dataFields(state) {
      return state.dataFields;
    },
    treeData(state) {
      return state.treeData;
    },
    componentData(state) {
      return state.componentData;
    },
    rerender(state) {
      return state.rerender;
    },
    areElementsSelected(state) {
      return state.signData.sides[state.currentSide].children.some(function (
        el
      ) {
        return el.isSelected;
      });
      //return state.areElementsSelected;
    },
    showMaterialsModal(state) {
      return state.showMaterialsModal;
    },
    showProcessesModal(state) {
      return state.showProcessesModal;
    },
    showColorsModal(state) {
      return state.showColorsModal;
    },
    showAddColorsModal(state) {
      return state.showAddColorsModal;
    },
    showPickColorsModal(state) {
      return state.showPickColorsModal;
    },
    showAddSignTypeModal(state) {
      return state.showAddSignTypeModal;
    },
    showAddAlignmentModal(state) {
      return state.showAddAlignmentModal;
    },
    showPublishModal(state) {
      return state.showPublishModal;
    },
    showFileModal(state) {
      return state.showFileModal;
    },
    showConfirmModal(state) {
      return state.showConfirmModal;
    },
    showSignTypesConfirmModal(state) {
      return state.showSignTypesConfirmModal;
    },
    showMissingFontsModal(state) {
      return state.showMissingFontsModal;
    },
    showProductFamiliesModal(state) {
      return state.showProductFamiliesModal;
    },
    showProductCategoriesModal(state) {
      return state.showProductCategoriesModal;
    },
    showProductTypesModal(state) {
      return state.showProductTypesModal;
    },
    showLoadingSpinner(state) {
      return state.showLoadingSpinner;
    },
    showAddFieldModal(state) {
      return state.showAddFieldModal;
    },
    showAddFontModal(state) {
      return state.showAddFontModal;
    },
    showAddSideModal(state) {
      return state.showAddSideModal;
    },
    showProductModal(state) {
      return state.showProductModal;
    },
    signData(state) {
      return state.signData;
    },
    currentSide(state) {
      return state.currentSide;
    },
    messages(state) {
      return state.messages;
    },
    messagesByName(state, name) {
      state.messages.forEach((message: iMessage) => {
        if (message.name === name) {
          return message;
        }
      });
      return null;
    },
    repeatingMessages(state) {
      return state.repeatingMessages;
    },
    detailFields(state) {
      return state.detailFields;
    },
    activePanel(state) {
      return state.activePanel;
    },
    components(state) {
      const components = state.signData.sides[
        state.currentSide
      ].children.filter(function (item: iSignChild) {
        return item.fieldType == "component";
      });
      return components;
    },
    steps(state) {
      return state.steps;
    },
    fonts(state) {
      return state.fonts;
    },
    colors(state) {
      return state.colors;
    },
    visuals(state) {
      return state.visuals;
    },
    visualsWithoutMarkers(state) {
      const result = [] as Array<iVisual>;
      for (let i = 0; i < state.visuals.length; i++) {
        if (state.visuals[i].isMarker === false) {
          result.push(state.visuals[i]);
        }
      }
      return result;
    },
    currentOrganizationUUID(state) {
      return state.currentOrganizationUUID;
    },
    currentSignTypeUUID(state) {
      return state.currentSignTypeUUID;
    },
    previousSignTypeUUID(state) {
      return state.previousSignTypeUUID;
    },
    signTypes(state) {
      return state.signTypes;
    },
    selectedSignType(state) {
      for (let i = 0; i < state.signTypes.length; i++) {
        if (state.signTypes[i].isSelected === true) {
          return state.signTypes[i];
        }
      }
      return null;
    },
    signTypeName(state) {
      return state.signTypeName;
    },
    signCount(state) {
      return state.signCount;
    },
    isDraft(state) {
      return state.isDraft;
    },
    isChanged(state) {
      return state.isChanged;
    },
    productFamilies(state) {
      return state.productFamilies;
    },
    isProductFamilySelected(state) {
      for (let i = 0; i < state.productFamilies.length; i++) {
        if (state.productFamilies[i].isSelected) {
          return true;
        }
      }
      return false;
    },
    /**
     * Returns an array containing the name of each selected family
     * @param {state} state - the store state
     * @returns {Array<string>} - an array containing the name of each selected family
     */
    selectedProductFamilyNames(state) {
      const result = [] as Array<string>;

      for (let i = 0; i < state.productFamilies.length; i++) {
        if (state.productFamilies[i].isSelected) {
          result.push(state.productFamilies[i].name);
        }
      }
      return result;
    },
    /**
     * Returns an array containing the name of each selected category
     * @param {state} state - the store state
     * @returns {Array<string>} - an array containing the name of each selected category
     */
    selectedProductCategoryNames(state) {
      const result = [] as Array<string>;

      for (let i = 0; i < state.productCategories.length; i++) {
        if (state.productCategories[i].isSelected) {
          result.push(state.productCategories[i].name);
        }
      }
      return result;
    },
    /**
     * Returns an array containing the name of each selected type
     * @param {state} state - the store state
     * @returns {Array<string>} - an array containing the name of each selected type
     */
    selectedProductTypeNames(state) {
      const result = [] as Array<string>;

      for (let i = 0; i < state.productTypes.length; i++) {
        if (state.productTypes[i].isSelected) {
          result.push(state.productTypes[i].name);
        }
      }
      return result;
    },
    selectedProduct(state) {
      for (let i = 0; i < state.catalog.length; i++) {
        if (state.catalog[i].isSelected) {
          return state.catalog[i];
        }
      }
      return {};
    },
    isProductCategorySelected(state) {
      for (let i = 0; i < state.productCategories.length; i++) {
        if (state.productCategories[i].isSelected) {
          return true;
        }
      }
      return false;
    },
    selectedProductFamily(state) {
      for (let i = 0; i < state.productFamilies.length; i++) {
        if (state.productFamilies[i].isSelected) {
          return state.productFamilies[i];
        }
      }
      return {};
    },
    productCategories(state) {
      return state.productCategories;
    },
    productTypes(state) {
      return state.productTypes;
    },
    isProductTypeSelected(state) {
      for (let i = 0; i < state.productTypes.length; i++) {
        if (state.productTypes[i].isSelected) {
          return true;
        }
      }
      return false;
    },
    colorLibraries(state) {
      return state.colorLibraries;
    },
    translationLanguages(state) {
      return state.translationLanguages;
    },
    catalog(state) {
      return state.catalog;
    },
    templates(state) {
      return state.templates;
    },
    zoomFactors(state) {
      return state.zoomFactors;
    },
    zoomIndex(state) {
      return state.zoomIndex;
    },
    showZoomMenu(state) {
      return state.showZoomMenu;
    },
    showAddVisualsModal(state) {
      return state.showAddVisualsModal;
    },
    showUnsupportedFeatureModal(state) {
      return state.showUnsupportedFeatureModal;
    },
    unsupportedFeature(state) {
      return state.unsupportedFeature;
    },
    featureAccess(state) {
      return state.featureAccess;
    },
    features(state) {
      return state.features;
    },
    showFeatureAccess(state) {
      return state.showFeatureAccessModal;
    },
    trackAddedField(state) {
      return state.trackAddedField;
    },
    addedFieldId(state) {
      return state.addedFieldId;
    },
    exitAfterPublish(state) {
      return state.exitAfterPublish;
    },
    showHistoryModal(state) {
      return state.showHistoryModal;
    },
    history(state) {
      return state.history;
    },
    redoHistory(state) {
      return state.redoHistory;
    },
    showMarketPlaceModal(state) {
      // make sure to replace the variable name here
      return state.showMarketPlaceModal;
    },
    marketplaceConnections(state) {
      return state.marketplaceConnections;
    },
  },
};

export default store;
