import { ModelKey, MODELS, PermObject } from "./types";

/**
 * Determine if a given string is a valid email address
 *
 * @param testString - string to validate
 * @returns true if email is a valid email
 */
export function validateEmail(testString: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(testString);
}

/**
 * Returns a sort() function for objects where the target object always appears first
 *
 * @param targetModel - The ModelKey for the currently displayed object
 * @param targetId - The Id of the currently displayed object
 * @returns An (a, b)=>number sort function which sorts the target object first, followed by inherited objects in a nice order
 */
export function objectSortFunctionForObject(
  targetModel: ModelKey,
  targetId: number
): (a: PermObject, b: PermObject) => number {
  return (a: PermObject, b: PermObject) => {
    // main object is always first
    if (a.objectType == targetModel && a.objectId == String(targetId)) {
      return -1;
    } else if (b.objectType == targetModel && b.objectId == String(targetId)) {
      return 1;
    }

    // match `inheritsPermsOf` order
    const a_typeOrder = MODELS[targetModel].inheritsPermsOf.indexOf(
      a.objectType
    );
    const b_typeOrder = MODELS[targetModel].inheritsPermsOf.indexOf(
      b.objectType
    );
    if (a_typeOrder != b_typeOrder) {
      return a_typeOrder - b_typeOrder;
    }

    // sort by name
    if ((a.name || "") > (b.name || "")) {
      return 1;
    } else if ((a.name || "") < (b.name || "")) {
      return -1;
    } else {
      return 0;
    }
  };
}

/**
 * A sort() function to sort users by name/email
 *
 * @param a - comparison user a
 * @param b - comparison user b
 * @returns -1 if a should be before b, 1 if a should be after b, or 0 i they're equal
 */
export function userSortFunction(
  a: { firstName: null | string; lastName: null | string; email: string },
  b: { firstName: null | string; lastName: null | string; email: string }
): number {
  const string_generator = function* (user: {
    firstName: null | string;
    lastName: null | string;
    email: string;
  }): Generator<string> {
    if (user.firstName && user.lastName) {
      yield `${user.firstName.toLocaleLowerCase()} ${user.lastName.toLocaleLowerCase()}`;
    } else if (user.firstName || user.lastName) {
      yield String(user.firstName).toLocaleLowerCase() ||
        String(user.lastName).toLocaleLowerCase();
    }
    if (user.email) {
      yield user.email.toLocaleLowerCase();
    }
  };

  const a_generator = string_generator(a);
  const b_generator = string_generator(b);
  let a_result, b_result;
  do {
    a_result = a_generator.next();
    b_result = b_generator.next();
    if (a_result.done && b_result.done) return 0;
    else if (a_result.done) return -1;
    else if (b_result.done) return 1;
    else if (b_result.value > a_result.value) return -1;
    else if (a_result.value > b_result.value) return 1;
  } while (!a_result.done && !b_result.done);

  return 0;
}
