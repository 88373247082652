

































































































































































































































































































import Vue from "vue";
import $ from "jquery";

import {
  STATE_TYPE,
  SMART_STATE_STATUS,
} from "@/features/SmartStates/utilities";
import { IKanbanProjectUser } from "../types";

export default Vue.extend({
  components: {},
  props: {
    column: {
      type: Object,
      default: () => ({}),
    },
    isSmartState: { type: Boolean, default: false },
  },
  data: function () {
    return {
      showDetails: false,
      showModal: false,
      hover: false,
      showEditor: false,
      originalName: "",
      saveError: false,
      saveSuccess: false,
      errorMessage: "",
      selectedColumnIndex: 0,
    };
  },
  methods: {
    startEditor: function () {
      this.originalName = this.column.state.name;
      this.showEditor = true;
    },
    saveEditor: function () {
      // TODO: update the state through graphQL
      const query = JSON.stringify({
        query: `
          mutation StateMutation{
            mutateState(input:{
              id: ${this.column.state.id},
              stateType: "${STATE_TYPE.DESIGN}",
              workflow: "${this.column.state.workflow}",
              name: "${this.column.state.name}",
              hexColor: "${this.column.state.hexColor}",
              approved: "Approved",
              rejected: "Rejected",
              orderId: ${this.column.orderId},
              needsReviewed: "Review"}) {
                state{
                  id: contentObjectId
                }
                errors {
                  messages
                }
              }
            }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.errors) {
            this.$emit("error", result.errors.message);
          } else {
            this.$emit("saved", "Saved");
            location.reload();
          }
        });
      this.showEditor = false;
    },
    cancelEditor: function () {
      this.column.state.name = this.originalName;
      this.showEditor = false;
    },
    editColumn: function (columnIndex: number) {
      this.selectedColumnIndex = columnIndex;
      $("#editProjectColumnModal").modal("show");
    },
    removeColumn: function () {
      // delete the state using graphQL
      if (this.column.signTypes.length > 0) {
        this.$emit("error", "Cannot delete a column that has signs in it.");
      } else {
        const query = JSON.stringify({
          query: `mutation deleteState {
            deleteState(input:{id:${this.column.state.id}}) {
              success
            }
          }`,
        });

        fetch("/graphql/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: query,
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.errors) {
              this.$emit("error", result.errors[0].message);
            } else {
              this.$emit("saved", "Saved");
              location.reload();
            }
          });
      }
    },
    directStatePerms: function (
      groupUsers: Array<IKanbanProjectUser>
    ): Array<IKanbanProjectUser> {
      let result = [] as Array<IKanbanProjectUser>;

      if (groupUsers) {
        groupUsers.forEach((user) => {
          if (user.contentType.toLowerCase() === "state") {
            result.push(user);
          }
        });
        return result;
      } else {
        return [];
      }
    },
    showColumnPermissions: function () {
      // made this an any type because I wasn't able to figure out the typeof globalThis
      /* eslint-disable-next-line */
      const globalThis = window.globalThis as any;
      globalThis.sa_vue.showPermissionsUI({
        model: "state",
        id: Number(this.column.state.id),
      });
    },
  },
  computed: {
    SMART_STATE_STATUS: function (): typeof SMART_STATE_STATUS {
      return SMART_STATE_STATUS;
    },
  },
  watch: {
    showEditor: function (newValue: boolean) {
      if (newValue) {
        this.$nextTick(() => {
          (this.$refs.columnName as HTMLElement).focus();
        });
      }
    },
  },
});
