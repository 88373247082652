











































import Vue from "vue";
import Snap from "snapsvg-cjs-ts";

import { IField } from "../../types";
import { removeSelectionHandles } from "../../utilities";

// import { ISignType, iSignData } from "../../types";
// import { uuidv4 } from "../../utilities";
// import matthews from "./assets/matthews.json";

export default Vue.extend({
  components: {},
  data: function () {
    return {
      showModal: false,
      signCount: 7,
      files: {} as FileList,
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showPublishModal;
  },
  methods: {
    /**
     * save changes
     */
    saveChanges: function () {
      // turn off selection handles
      this.$store.commit("setShowLoadingSpinner", true);

      removeSelectionHandles(this.$store.getters.canvas);
      //remove all elements with the repeat-clone class
      this.$store.getters.canvas
        .selectAll(".repeat-clone")
        .forEach((el: Snap.Element) => {
          el.remove();
        });

      this.$store.commit("deselectAllRepeats");

      // remove the extra style tags that were added by SAFonts
      let defs = this.$store.getters.canvas.select("defs");
      let defStyles = defs.select("style");
      let styleStyles = defStyles.selectAll("style");
      styleStyles.remove();

      //reset the sign height and width to the original measurements
      let svg: Snap.Element = this.$store.getters.canvas.select("svg");
      //save the current size so that we can restore it after save is complete
      const savedWidth = svg.attr("width");
      const savedHeight = svg.attr("height");

      //set the width and height to the original sign dimensions
      svg.attr({
        width:
          this.$store.getters.signData.width +
          this.$store.getters.signData.measurementUnit.unit,
      });
      svg.attr({
        height:
          this.$store.getters.signData.height +
          this.$store.getters.signData.measurementUnit.unit,
      });

      // write changes to the backend
      const users: Array<string> = [];
      users.push(this.$store.getters.user.email);

      // let materialIds = this.$store.getters.signMaterials.map(
      //   (material: IMaterial) => {
      //     return material.id;
      //   }
      // );
      // let processIds = this.$store.getters.signProcesses.map(
      //   (process: IProcess) => {
      //     return process.id;
      //   }
      // );

      let messageIds = [] as Array<string>;
      this.$store.getters.usedFields.forEach((message: IField) => {
        if (!message.belongsToRepeatId) {
          messageIds.push(message.id);
        }
      });

      let repeatingIds = [] as Array<string>;
      this.$store.getters.usedFields.forEach((message: IField) => {
        if (message.belongsToRepeatId) {
          repeatingIds.push(message.id);
        }
      });

      let metaIds = this.$store.getters.detailFields.map((message: IField) => {
        return message.id;
      });

      let operations = {
        query: `mutation updateSignType ($input: UpdateSignTemplateInput!){
                  updateSignType (updateSignTypeInput: $input) {
                    signTemplate {
                      name
                    }
                  }
                }`,
        variables: {
          input: {
            folder: this.$store.getters.signData.folderId,
            name: this.$store.getters.signData.name,
            shortCode: this.$store.getters.signData.shortCode,
            details: this.$store.getters.signData.details,
            hexColor: this.$store.getters.signData.hexColor,
            unitCost: Number(this.$store.getters.signData.unitCost),
            numberOfSides: String(this.$store.getters.signData.numberOfSides),
            numberOfColumns: this.$store.getters.signData.numberOfColumns,
            numberOfMessages: this.$store.getters.repeats[0]
              ? this.$store.getters.repeats[0].steps
              : 0,
            isDirectional: this.$store.getters.signData.isDirectional,
            marker: this.$store.getters.signData.markerId,
            useScaleInMessageSchedule:
              this.$store.getters.signData.useScaleInMessageSchedule,
            scale: this.$store.getters.signData.scale,
            publish: this.$store.getters.signData.publish,
            markerNoScalePercent:
              this.$store.getters.signData.markerNoScalePercent,
            markerScale: "",
            markerScaleWidth: this.$store.getters.signData.markerScaleWidth,
            markerScaleWidthUnit:
              this.$store.getters.signData.markerScaleWidthUnit,
            messageAttributes: messageIds,
            repeatingAttributes: repeatingIds,
            metaAttributes: metaIds,
            emails: users,
            id: this.$store.getters.signData.id,
            svgFile: null,
          },
        },
      };

      let map = {
        "0": ["variables.input.svgFile"],
      };

      let fd = new FormData();

      // remove the click handler from the SVG code before saving
      // we add them back after the save
      let clickHandlers = svg.selectAll("[click-handler]");
      clickHandlers.forEach((fragment: Snap.Element) => {
        fragment.attr({ "click-handler": "" });
      });

      let svgCode = this.$store.getters.canvas.select("svg").outerSVG();
      const fileName = this.$store.getters.signData.templateName + ".svg";

      fd.append("operations", JSON.stringify(operations));
      fd.append("map", JSON.stringify(map));
      fd.append("0", new File([new Blob([svgCode])], fileName));

      fetch("/graphql/", {
        method: "POST",
        body: fd,
      })
        .then((res) => res.json())
        .then((result) => {
          // reset the sign size
          svg.attr({ width: savedWidth });
          svg.attr({ height: savedHeight });
          // put back one of the font-face definitions
          let defs = svg.select("defs");
          defs.prepend(styleStyles[0]);

          if (result.errors) {
            alert(result.errors[0].message);
            this.$store.commit("setShowLoadingSpinner", false);
          } else {
            //clear the revision json field
            fetch("/graphql/", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                query: `
                  mutation updateSignTypeRevisionJson($json: UpdateSignTypeRevisionJsonInput!){
                    updateSignTypeRevisionJson(input: $json) {
                      signType{
                        revisionJson
                      }
                    }
                  }
                `,
                variables: JSON.stringify({
                  json: {
                    uuid: this.$store.getters.signData.uuid,
                    revisionJson: "",
                  },
                }),
              }),
            })
              .then((res) => res.json())
              .then((result) => {
                if (this.$store.getters.exitAfterPublish) {
                  //eslint-disable-next-line
                  (this.$root.$refs.signDesigner.$refs.modallayout as any).$emit("close");
                }
                this.$store.commit("setShowLoadingSpinner", false);

                return result;
              });
          }
        });

      // add back the click handlers to the SVG
      clickHandlers.forEach((fragment: Snap.Element) => {
        fragment.attr({ "click-handler": "set" });
      });

      // clear the history
      this.$store.commit("setHistory", []);

      //close the window
      this.$store.commit("setShowPublishModal", false);
      this.$store.commit("setIsChanged", false);
      this.$emit("publishClicked");
    },
    /**
     * close the modal
     * @param {Event} e - the event that triggered the close
     */
    closeModal: function (e: Event) {
      e.preventDefault();
      e.stopPropagation();
      this.$store.commit("setShowPublishModal", false);
      this.$emit("cancelClicked");
    },
  },
  computed: {},
  watch: {
    /**
     * when showPublishModal from the store changes
     * @param {boolean} newValue - the value of showPublishModal from the store
     */
    "$store.state.signDesigner.showPublishModal": function (newValue: boolean) {
      //   const this = this;
      this.showModal = newValue;
    },
  },
});
