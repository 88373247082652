




































































import Vue from "vue";
import draggable from "vuedraggable";

import MapViewLayout from "@/components/layouts/MapViewLayout.vue";

import { IState } from "@/features/SmartStates/types";
import {
  ISigntypeColumn,
  IKanbanSignQL,
  IKanbanSignTypeQL,
  IKanbanSignType,
  IKanbanSign,
} from "../types";
import KanbanCard from "../components/ProjectKanbanCard.vue";

export default Vue.extend({
  components: { KanbanCard, MapViewLayout, draggable },
  data: function () {
    return {
      states: [] as Array<IState>,
      columns: [] as Array<ISigntypeColumn>,
      columnIndex: 0,
      projectName: "",
    };
  },
  mounted: function () {
    this.fetchData();
  },
  methods: {
    moveAllToNextColumn: function (columnIndex: number) {
      this.columnIndex = columnIndex + 1;
      this.columns[columnIndex].signTypes.forEach((signType) => {
        // if this is the added action and the element is a sign type (i.e., the element has a signs property)
        this.$store.commit("setShowLoadingSpinner", true);
        Promise.all(signType.signs.map(this.moveSign)).then(() => {
          this.$store.commit("setShowLoadingSpinner", false);
          location.reload();
        });
      });
    },
    calculateSignCount: function (columnIndex: number): number {
      let totalSigns = 0;
      this.columns[columnIndex].signTypes.forEach((signType) => {
        signType.signs.forEach((sign) => {
          totalSigns += sign.quantity;
        });
      });

      return totalSigns;
    },
    getOrCreateSignType: function (
      signType: IKanbanSignTypeQL,
      signTypes: Array<IKanbanSignType>
    ): number {
      const index = signTypes.findIndex((type) => {
        return type.id === signType.id;
      });

      if (index === -1) {
        // sign type not found so we need to create it
        const newSignType = {
          id: signType.id,
          name: signType.name,
          shortCode: signType.shortCode,
          hexColor: signType.hexColor,
          quantity: 1,
          signs: [],
          isExpanded: false,
        };
        signTypes.push(newSignType);
        return signTypes.length - 1;
      } else {
        return index;
      }
    },
    moveSign: async function (sign: IKanbanSign): Promise<void> {
      const query = JSON.stringify({
        query: `mutation moveSignToState {
              mutateSign(input:{
                id:"${sign.id}",
                facingDirection: ${sign.facingDirection},
                stateUuid: "${this.columns[this.columnIndex].stateUUID}"
              }) {
                errors {
                  messages
                }
                sign {
                  state{
                    name
                  }
                }
              }
            }
            `,
      });

      const response = await fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      });

      const result = await response.json();
      return result;
    },
    dragSignTypeEnd: function (
      e: { added: { element: IKanbanSignType; newIndex: number } },
      columnIndex: number
    ) {
      // only move the signs if they have been dragged to a new column
      if (this.columnIndex !== columnIndex) {
        this.columnIndex = columnIndex;
        // if this is the added action and the element is a sign type (i.e., the element has a signs property)
        if ("added" in e && "signs" in e.added.element) {
          this.$store.commit("setShowLoadingSpinner", true);
          Promise.all(e.added.element.signs.map(this.moveSign)).then(() => {
            this.$store.commit("setShowLoadingSpinner", false);
            location.reload();
          });
        }
      }
      if ("added" in e && "signId" in e.added.element) {
        this.$store.commit("setShowLoadingSpinner", true);
        this.moveSign(e.added.element).then(() => {
          this.$store.commit("setShowLoadingSpinner", false);
          location.reload();
        });
      }
    },

    fetchData: function () {
      const query = JSON.stringify({
        query: `query get_project {
          project (id: ${this.$route.params.id}) {
            name
            states {
              edges {
                node {
                  id: contentObjectId
                  uuid
                  name
                  smartStateStatus
                  signs {
                    pageInfo {
                      hasNextPage
                    }
                    edges {
                      node {
                        id: contentObjectId
                        uuid
                        signId
                        quantity
                        facingDirection
                        signType {
                          id: contentObjectId
                          name
                          shortCode
                          hexColor
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.errors) {
            alert(result.errors);
          } else {
            this.projectName = result.data.project.name;
            result.data.project.states.edges.forEach(
              (state: {
                node: {
                  id: number;
                  uuid: string;
                  name: string;
                  smartStateStatus: string;
                  signs: { edges: Array<{ node: IKanbanSignQL }> };
                };
              }) => {
                const newColumn = {} as ISigntypeColumn;
                newColumn.stateId = state.node.id;
                newColumn.stateName = state.node.name;
                newColumn.stateUUID = state.node.uuid;
                newColumn.signTypes = [];

                state.node.signs.edges.forEach(
                  (sign: { node: IKanbanSignQL }) => {
                    let signTypeIndex = this.getOrCreateSignType(
                      sign.node.signType,
                      newColumn.signTypes
                    );
                    newColumn.signTypes[signTypeIndex].signs.push({
                      signId: sign.node.signId,
                      id: sign.node.id,
                      facingDirection: sign.node.facingDirection,
                      quantity: sign.node.quantity,
                      imageURL: "/sign/" + sign.node.id + "/svg_as_png/",
                      messages: [],
                      details: [],
                    });
                  }
                );

                this.columns.push(newColumn);
              }
            );
          }
        });
    },
  },
});
