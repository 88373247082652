



































import Vue from "vue";

export default Vue.extend({
  components: {},
  props: {
    feature: { type: String },
  },
  data: function () {
    return {
      showModal: false,
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showUnsupportedFeatureModal;
  },
  methods: {
    /**
     * yes clicked
     */
    acceptClicked: function () {
      //### perform accept processing here ###
      //close the modal
      this.$store.commit("setShowUnsupportedFeatureModal", false);
    },
    /**
     * close the modal
     * @param {Event} e - the event that triggered the close
     */
    closeClicked: function () {
      // close the modal
      this.$store.commit("setShowUnsupportedFeatureModal", false);
      // exit the designer
      // eslint-disable-next-line
      (this.$root.$refs.signDesigner as any).closeModalWithoutSaving();
    },
  },
  computed: {},
  watch: {
    /**
     * when showConfirmModal from the store changes
     * @param {boolean} newValue - the value of the showConfirmModal from the store
     */
    "$store.state.signDesigner.showUnsupportedFeatureModal": function (
      newValue: boolean
    ) {
      //   const this = this;
      this.showModal = newValue;
    },
  },
});
