














import Vue from "vue";
import CollapsibleSection from "../CollapsibleSection.vue";
import CodeEditor from "../CodeEditor.vue";

export default Vue.extend({
  components: { CollapsibleSection, CodeEditor },
  data: function () {
    return {};
  },
  methods: {},
  computed: {
    /**
     * Should we show the plus sign for adding alignments
     * @returns {boolean}
     */
    showPlusSign: function () {
      return false;
    },
    /**
     * Should the section be shown open
     * @returns {boolean}
     */
    openSection: function () {
      // if we have a selected field or repeat then return true
      if (
        this.$store.getters.selectedField ||
        this.$store.getters.selectedElementIds.length > 0 ||
        this.$store.getters.selectedRepeat
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {},
});
