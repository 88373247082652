
































import Vue from "vue";
import { PropType } from "vue";
import { IDashboardState } from "../types";

import DateWidget from "./DateWidget.vue";

export default Vue.extend({
  components: { DateWidget },
  props: {
    states: {
      type: Array as PropType<IDashboardState[]>,
      required: true,
    },
  },
  data: function () {
    return {};
  },
  methods: {
    updateEndDate: function (stateId: string, newDate: Date) {
      const state = this.states.find((state) => {
        return state.id === stateId;
      });

      if (state) {
        const query = JSON.stringify({
          query: `
            mutation {
              mutateState(
                input: {
                  id: ${stateId},
                  endDate: "${newDate}"
                  workflow: ${state.workflow},
                  name: "${state.name}",
                  hexColor:"${state.hexColor}",
                  approved:"${state.approved}",
                  rejected:"${state.rejected}",
                  needsReviewed:"${state.needsReviewed}",
                  stateType:"${state.type}",
                  orderId: ${state.orderId}
                }
              )
              {
                state {
                  name
                }
              }
            }`,
        });

        fetch("/graphql/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: query,
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.errors) {
              this.$emit("error", result.errors[0].message);
            } else {
              this.$emit("dateUpdated", state.id, newDate);
            }
          });
      }
    },
    endDateStyle: function (state: IDashboardState): string {
      const today = new Date();

      if (state.archived) {
        return "";
      } else if (state.endDate && today > state.endDate) {
        //red color
        return "color: #ED5565; font-weight: bold";
      } else if (state.startDate && today >= state.startDate) {
        //green color
        return "color: #48B02C; font-weight: bold";
      } else if (state.startDate) {
        // default color
        return "";
      } else {
        return "";
      }
    },
    userHasChangePerms: function (state: IDashboardState) {
      return state.canEdit;
    },
  },
});
