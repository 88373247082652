













































































































import Vue from "vue";

import { IProductFamily } from "../../types";
import { uuidv4 } from "../../utilities";

export default Vue.extend({
  components: {},
  data: function () {
    return {
      showModal: false,
      selectedProductFamilyIndex: -1,
      editMode: "edit",
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showProductFamiliesModal;
  },
  methods: {
    /**
     * add a product family
     * called when the user clicks the Add product family button
     */
    addProductFamily: function () {
      const productFamily = {
        id: "",
        uuid: uuidv4(),
        name: "New product family",
        description: "",
        imageURL: "",
      } as IProductFamily;
      this.$store.commit("addProductFamily", productFamily);
      this.editMode = "add";
      this.selectedProductFamilyIndex =
        this.$store.getters.productFamilies.length - 1;
    },
    /**
     * delete the selected product family
     * called when the trash icon is clicked
     */
    deleteProductFamily(index: number) {
      this.$store.commit("deleteProductFamilyByIndex", index);
    },
    /**
     * handle the Ok button clicked event
     */
    saveChanges: function () {
      //if we are on the details screen
      if (this.selectedProductFamilyIndex >= 0) {
        // write to the back end
        if (this.editMode === "add") {
          fetch("/graphql/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              query: `
                mutation addPartFamily{
                  addSignTypePartFamily(addSignTypePartFamilyInput: {name: "${
                    this.$store.getters.productFamilies[
                      this.selectedProductFamilyIndex
                    ].name
                  }", organizationId: ${this.$store.getters.organizationId}}) {
                    partFamily {
                      id: contentObjectId
                      uuid
                      name
                    }
                  }
                }
              `,
            }),
          }).then((result) => result.json());
        } else {
          fetch("/graphql/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              query: `
                mutation updatePartFamily {
                  updateSignTypePartFamily(updateSignTypePartFamilyInput: {id:"${
                    this.$store.getters.productFamilies[
                      this.selectedProductFamilyIndex
                    ].id
                  }", name:"${
                this.$store.getters.productFamilies[
                  this.selectedProductFamilyIndex
                ].name
              }"}) {
                    partFamily {
                      name
                    }
                  }
                }
              `,
            }),
          }).then((result) => result.json());
        }

        this.editMode = "edit";
        //move back to list screen, don't close modal
        this.selectedProductFamilyIndex = -1;
      } else {
        //otherwise we are on the list screen, close modal
        this.$store.commit("setShowProductFamiliesModal", false);
      }
    },
    /**
     * close the entire modal
     */
    closeModal: function () {
      this.selectedProductFamilyIndex = -1;
      this.$store.commit("setShowProductFamiliesModal", false);
    },
    /**
     * close a page
     */
    closePage: function () {
      //if we are on the details screen
      if (this.selectedProductFamilyIndex >= 0) {
        //move back to list screen, don't close modal
        this.selectedProductFamilyIndex = -1;
      } else {
        //otherwise we are on the list screen, close modal
        this.$store.commit("setShowProductFamiliesModal", false);
      }
    },
    /**
     * set the selected product family index
     * @param {number} productFamilyIndex - the array index of the product family to edit
     */
    editProductFamily: function (productFamilyIndex: number) {
      this.editMode = "edit";
      this.selectedProductFamilyIndex = productFamilyIndex;
    },
  },
  computed: {
    /**
     * getter/setter for name
     */
    name: {
      get(): string {
        return this.$store.getters.productFamilies[
          this.selectedProductFamilyIndex
        ].name;
      },
      set(value: string) {
        this.$store.commit("updateProductFamilyNameByIndex", {
          value: value,
          index: this.selectedProductFamilyIndex,
        });
      },
    },
    /**
     * getter/setter for name
     */
    description: {
      get(): string {
        return this.$store.getters.productFamilies[
          this.selectedProductFamilyIndex
        ].description;
      },
      set(value: string) {
        this.$store.commit("updateProductFamilyDescriptionByIndex", {
          value: value,
          index: this.selectedProductFamilyIndex,
        });
      },
    },
    /**
     * getter/setter for image URL
     */
    imageURL: {
      get(): string {
        return this.$store.getters.productFamilies[
          this.selectedProductFamilyIndex
        ].imageURL;
      },
      set(value: string) {
        this.$store.commit("updateProductFamilyImageURLByIndex", {
          value: value,
          index: this.selectedProductFamilyIndex,
        });
      },
    },
  },
  watch: {
    /**
     * watch showProductFamiliesModal from the store
     * @param {boolean} newValue - the value of showProductFamiliesModal from the store
     */
    "$store.state.signDesigner.showProductFamiliesModal": function (newValue) {
      //   const this = this;
      this.showModal = newValue;
    },
  },
});
