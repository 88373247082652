











































































































import Vue from "vue";

import Snap from "snapsvg-cjs-ts";
import { SAFonts } from "@/features/SignDesigner/include/SAFonts";

import { iDetailField, IField } from "../types";

import TopbarZoomMenu from "./TopbarZoomMenu.vue";
import TopbarSettingsMenu from "./TopbarSettingsMenu.vue";
import PublishModal from "./modals/PublishModal.vue";
import {
  removeSelectionHandles,
  featureAvailable,
  drawRepeatElements,
} from "../utilities";
import {
  IDetailFieldEdge,
  IMessageFieldEdge,
  IRepeatingMessageFieldEdge,
  IPartEdge,
  IPartNode,
  IMaterialEdge,
  IMaterialNode,
  IProcessEdge,
  IProcessNode,
  IFieldEdge,
  IFieldNode,
} from "../GraphqlTypes";

import FeatureAccessModal from "./modals/FeatureAccessModal.vue";
import HistoryModal from "./modals/HistoryModal.vue";

export default Vue.extend({
  components: {
    TopbarZoomMenu,
    TopbarSettingsMenu,
    PublishModal,
    FeatureAccessModal,
    HistoryModal,
  },
  data() {
    return {};
  },
  mounted: function () {
    //set the default tab to the design tab
    this.$root.$refs.TopBar = this;
    this.tabChanged("template");
  },
  methods: {
    undoClicked: function () {
      const lastChange = this.$store.getters.history.length - 1;
      const rollback = this.$store.getters.history[lastChange];
      this.$store.commit("setProcessingUndo", true);
      this.$store.commit("popHistory");

      // eslint-disable-next-line
      rollback.undoMutation.forEach((mutation: { mutationName: string; undoValue: any }) => {
          this.$store.commit(mutation.mutationName, mutation.undoValue);
        }
      );

      // capture the redo info
      this.$store.commit("captureRedoState", rollback);
      if (this.$store.getters.isRepeatSelected) {
        drawRepeatElements(this);
      }
      // const svgState = $("#signdesigner_snap").html();
      // const vueState = this.$store.state.signDesigner;
      // const svgString = this.$store.getters.history[lastChange].svgState;
      // $("#signdesigner_snap").html(svgString);

      // //remove all click handlers
      // this.$store.getters.canvas
      //   .selectAll("[click-handler='set']")
      //   .forEach((el: Snap.Element) => {
      //     el.attr({ "click-handler": "" });
      //   });

      // this.$store.replaceState({
      //   signDesigner: this.$store.getters.history[lastChange].vueState,
      // });
      // this.$store.commit("captureRedoState", {
      //   svgState: svgState,
      //   vueState: vueState,
      // });
      // // this.$store.commit("saveRedoHistory", addRedoitem);

      // let snap = Snap("#signdesigner_snap");
      // const frag: Snap.Fragment = Snap.parse(svgString);
      // const el = frag.select("svg");
      // snap.append(el);
      // this.$store.commit("setCanvas", snap);

      //close the modal
      this.$nextTick(() => {
        this.$store.commit("setShowHistoryModal", false);

        /* eslint-disable-next-line */
        (this.$root.$refs.SVGRenderer as any).processSVG(false, false);
        this.$store.commit("setProcessingUndo", false);
      });
    },
    redoClicked: function () {
      const lastChange = this.$store.getters.redoHistory.length - 1;
      const rollback = this.$store.getters.redoHistory[lastChange];
      this.$store.commit("popRedoHistory");

      // eslint-disable-next-line
      rollback.redoMutation.forEach((mutation: { mutationName: string; redoValue: any }) => {
          this.$store.commit(mutation.mutationName, mutation.redoValue);
        }
      );

      if (this.$store.getters.isRepeatSelected) {
        drawRepeatElements(this);
      }

      this.$nextTick(() => {
        /* eslint-disable-next-line */
        (this.$root.$refs.SVGRenderer as any).processSVG(false, false);
      });
      // const lastChange = this.$store.getters.redoHistory.length - 1;
      // // const addRedoitem = $.extend(
      // //   true,
      // //   {},
      // //   this.$store.getters.redoHistory[lastChange]
      // // );
      // // const svgState = $("#signdesigner_snap").html();
      // // const vueState = this.$store.state;
      // const svgString = this.$store.getters.redoHistory[lastChange].svgState;
      // $("#signdesigner_snap").html(svgString);
      // //remove all click handlers
      // this.$store.getters.canvas
      //   .selectAll("[click-handler='set']")
      //   .forEach((el: Snap.Element) => {
      //     el.attr({ "click-handler": "" });
      //   });
      // this.$store.replaceState({
      //   signDesigner: this.$store.getters.redoHistory[lastChange].vueState,
      // });

      // let snap = Snap("#signdesigner_snap");
      // const frag: Snap.Fragment = Snap.parse(svgString);
      // const el = frag.select("svg");
      // snap.append(el);
      // this.$store.commit("setCanvas", snap);

      // //close modal

      // this.$nextTick(() => {
      //   this.$store.commit("setProcessingRedo", true);
      //   this.$store.commit("setShowHistoryModal", false);

      //   /* eslint-disable-next-line */
      //   (this.$root.$refs.SVGRenderer as any).processSVG(false, false);

      //   this.$store.commit("setProcessingRedo", false);
      // });
    },
    /**
     * handle the tab changed event
     * @param {string} key - the key of the new tab
     */
    tabChanged: function (key: string) {
      this.$store.commit("setActiveTab", key);
    },
    /**
     * show the feature access modal
     */
    showFeatureAccess: function () {
      this.$store.commit("setShowFeatureAccessModal", true);
    },
    /**
     * show the history modal
     */
    showHistoryModal: function () {
      this.$store.commit("setShowHistoryModal", true);
    },
    /**
     * show the publish modal
     */
    showPublishModal: function () {
      // if there are missing fonts then prevent the user from publishing the sign
      if (SAFonts.getInstance().fontsNotLoaded.length > 0) {
        this.$store.commit("setShowMissingFontsModal", true);
      } else {
        this.$store.commit("setShowPublishModal", true);
      }
    },
    /**
     * save the sign type
     */
    async saveSignType() {
      removeSelectionHandles(this.$store.getters.canvas);
      this.$store.commit("deselectAllParts");
      this.$store.commit("deselectAllSignMaterials");
      this.$store.commit("deselectAllSignProcesses");
      this.$store.commit("deselectAllRepeats");

      //reset the sign height and width to the original measurements
      let svg: Snap.Element = this.$store.getters.canvas.select("svg");
      //save the current size so that we can restore it after save is complete
      const savedWidth = svg.attr("width");
      const savedHeight = svg.attr("height");

      //set the width and height to the original sign dimensions
      svg.attr({
        width:
          this.$store.getters.signData.width +
          this.$store.getters.signData.measurementUnit.unit,
      });
      svg.attr({
        height:
          this.$store.getters.signData.height +
          this.$store.getters.signData.measurementUnit.unit,
      });

      // remove the click handler from the SVG code before saving
      // we add them back after the save
      let clickHandlers = svg.selectAll("[click-handler]");
      clickHandlers.forEach((fragment: Snap.Element) => {
        fragment.attr({ "click-handler": "" });
      });

      // we need to add the outer svg tags so that the loading parses correctly
      var encodedSVG = btoa(
        encodeURIComponent("<svg>" + svg.outerSVG() + "</svg>")
      );

      //build the repeating element nodes
      let detailNodes = [] as Array<IDetailFieldEdge>;
      this.$store.getters.detailFields.forEach((detailField: iDetailField) => {
        detailNodes.push({ node: detailField });
      });

      let messageNodes = [] as Array<IMessageFieldEdge>;
      let fieldNodes = [] as Array<IFieldEdge>;
      this.$store.getters.usedFields.forEach((messageField: IField) => {
        if (!messageField.belongsToRepeatId) {
          const fieldNode: IFieldNode = {
            id: messageField.id,
            uuid: messageField.id,
            fieldType: messageField.type,
            name: messageField.name,
          };
          fieldNodes.push({ node: fieldNode });
        }
      });

      let repeatingMessageNodes = [] as Array<IRepeatingMessageFieldEdge>;
      this.$store.getters.usedFields.forEach((messageField: IField) => {
        if (messageField.belongsToRepeatId) {
          const fieldNode: IFieldNode = {
            id: messageField.id,
            uuid: messageField.id,
            fieldType: messageField.type,
            name: messageField.name,
          };
          repeatingMessageNodes.push({ node: fieldNode });
        }
      });
      // this.$store.getters.repeatingMessages.forEach(
      //   (repeatingMessageField: iMessageField) => {
      //     repeatingMessageNodes.push({ node: repeatingMessageField });
      //   }
      // );

      let partNodes = [] as Array<IPartEdge>;
      this.$store.getters.parts.forEach((partField: IPartNode) => {
        partNodes.push({ node: partField });
      });

      let materialNodes = [] as Array<IMaterialEdge>;
      this.$store.getters.materials.forEach((materialField: IMaterialNode) => {
        materialNodes.push({ node: materialField });
      });

      let processNodes = [] as Array<IProcessEdge>;
      this.$store.getters.processes.forEach((processField: IProcessNode) => {
        processNodes.push({ node: processField });
      });

      //build the revision string
      let revision = {
        signType: {
          id: this.$store.getters.signData.id,
          uuid: this.$store.getters.currentSignTypeUUID,
          folder: { id: this.$store.getters.signData.folderId },
          library: { id: this.$store.getters.signData.libraryId },
          name: this.$store.getters.signData.name,
          shortCode: this.$store.getters.signData.shortCode,
          details: this.$store.getters.signData.details,
          hexColor: this.$store.getters.signData.hexColor,
          unitCost: this.$store.getters.signData.unitCost,
          numberOfSides: this.$store.getters.signData.numberOfSides,
          numberOfColumns: this.$store.getters.signData.numberOfColumns,
          numberOfMessages: this.$store.getters.repeats[0]
            ? this.$store.getters.repeats[0].steps
            : 0,
          isDirectional: this.$store.getters.signData.isDirectional,
          marker: { id: this.$store.getters.signData.markerId },
          useScaleInMessageSchedule:
            this.$store.getters.signData.useScaleInMessageSchedule,
          scale: this.$store.getters.signData.scale,
          publish: this.$store.getters.signData.publish,
          markerNoScalePercent:
            this.$store.getters.signData.markerNoScalePercent,
          markerScale: { id: this.$store.getters.signData.markerScaleId },
          markerScaleWidth: this.$store.getters.signData.markerScaleWidth,
          markerScaleWidthUnit:
            this.$store.getters.signData.markerScaleWidthUnit,
          svgUrl: this.$store.getters.signData.svgUrl,
          svgFile: this.$store.getters.signData.templateName,
          componentJson: this.$store.getters.signData.componentJson,
          messages: { edges: messageNodes },
          repeatingMessages: { edges: repeatingMessageNodes },
          detailFields: { edges: detailNodes },
          parts: { edges: partNodes },
          price: this.$store.getters.signData.price,
          priceCurrency: this.$store.getters.signData.priceCurrency,
          materials: { edges: materialNodes },
          signMaterials: { edges: this.$store.getters.signMaterials },
          processes: { edges: processNodes },
          signProcesses: { edges: this.$store.getters.signProcesses },
          svgData: encodedSVG,
        },
      };

      let signId = "";
      if (this.$store.getters.currentSignTypeUUID !== "") {
        signId = this.$store.getters.currentSignTypeUUID;
      } else {
        // we are switching signs so save the previous sign UUID
        signId = this.$store.getters.previousSignTypeUUID;
      }

      await fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
            mutation updateSignTypeRevisionJson($json: UpdateSignTypeRevisionJsonInput!){
              updateSignTypeRevisionJson(input: $json) {
                signType{
                  revisionJson
                }
              }
            }
          `,
          variables: JSON.stringify({
            json: {
              uuid: signId,
              revisionJson: JSON.stringify(revision),
            },
          }),
        }),
      })
        .then((res) => res.json())
        .then((result) => {
          svg.attr({ width: savedWidth });
          svg.attr({ height: savedHeight });
          return result;
        });

      // add back the click handlers to the SVG
      clickHandlers.forEach((fragment: Snap.Element) => {
        fragment.attr({ "click-handler": "set" });
      });

      // clear out undo history
      this.$store.commit("setHistory", []);

      // var unencoded = decodeURIComponent(atob(encoded));
      //clear the isChanged flag
      this.$store.commit("setIsChanged", false);
    },
  },
  computed: {
    featureAccessHistory: function (): boolean {
      return featureAvailable(this, "history");
    },
    featureAccessAdmin: function (): boolean {
      return featureAvailable(this, "admin");
    },
  },
});
