import { SearchResult } from "../types";
import { userSortFunction } from "../utilities";

/**
 * Search for users matching a given search string
 *
 * @param searchText - string of terms to search for
 * @param isRelevant - a function that we can call to ensure it's still worth processing the results
 * @returns A promise which returns a sorted array of SearchResults
 */
export default async function getSearchResults(
  searchText: string,
  isRelevant: () => boolean
): Promise<{ searchResults: SearchResult[]; searchTruncated: boolean }> {
  const variables = {
    filter: new URLSearchParams({ search: searchText }).toString(),
  };
  const query = `
    query Search($filter: String) {
      search: users(filter: $filter, first: 100) {
        pageInfo { hasNextPage }
        edges {
          node {
            id
            firstName
            lastName
            email
            pic
          }
        }
      }
    }
  `;

  const data = await (
    await fetch("/graphql/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        query,
        variables,
      }),
    })
  )
    .json()
    .catch((err) => {
      console.log(err); // TODO: handle better!!
      throw new Error("Unable to return search results.");
    });

  if (!isRelevant()) {
    throw new Error("These results are no longer needed, not processing.");
  }

  const searchResults = Array<SearchResult>();
  const searchTruncated: boolean = data.data?.search?.pageInfo?.hasNextPage;
  const queryResults = data.data?.search?.edges;
  if (!Array.isArray(queryResults)) {
    console.error(
      "Expected 'data.search.edge' to be an array, got",
      queryResults
    );
    throw new Error("Search returned invalid results.");
  }

  for (const resultNode of queryResults) {
    const result = resultNode?.node;

    if (typeof result !== "object") {
      console.error(
        "Expected items in 'data.search.edge.node[]' to be objects, got",
        result
      );
      continue;
    }

    const { id, firstName, lastName, email, pic: imageUrl } = result;

    if (typeof id != "string" || !id) {
      console.error(
        "Expected 'data.search.edge.node[].id' to be a non-empty string, got",
        id
      );
      continue;
    }

    if (
      firstName !== null &&
      !(typeof firstName == "string" && firstName != "")
    ) {
      console.error(
        "Expected 'data.search.edge.node[].firstName' to be null or a non-empty string, got",
        firstName
      );
      continue;
    }

    if (lastName !== null && !(typeof lastName == "string" && lastName != "")) {
      console.error(
        "Expected 'data.search.edge.node[].lastName' to be a non-empty string, got",
        lastName
      );
      continue;
    }

    if (typeof email != "string" || !email) {
      console.error(
        "Expected 'data.search.edge.node[].email' to be a non-empty string, got",
        email
      );
      continue;
    }

    if (typeof imageUrl != "string" || !imageUrl) {
      console.error(
        "Expected 'data.search.edge.node[].pic' to be a non-empty string, got",
        imageUrl
      );
      continue;
    }

    searchResults.push({
      id,
      firstName,
      lastName,
      email,
      imageUrl,
    });
  }

  searchResults.sort(userSortFunction);
  return { searchResults, searchTruncated };
}
