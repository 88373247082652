





























































import Vue from "vue";
import {
  iCatalog,
  ITemplate,
  IModulexTemplate,
  IFile,
  IMarketplaceItem,
} from "../../types";
import { uuidv4, base64ToArrayBuffer } from "../../utilities";

export default Vue.extend({
  components: {},
  data: function () {
    return {
      showModal: false,
      templates: [] as Array<ITemplate>,
      selectedProduct: {} as iCatalog,
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showProductModal;
  },
  methods: {
    /**
     * save changes
     */
    saveChanges: function () {
      /**
       * process the add to connections event
       */
      let selectedItem = this.selectedProduct;

      if (this.$store.getters.templates.length === 0) {
        this.$store.commit("setShowLoadingSpinner", true);
        fetch(
          "https://wotbackend.modulex.com/api/v1/Parameters/GraphicTemplates?ApiKey=4CA681E9-E58F-45D2-BEAC-07CCFE08A1D1"
        )
          .then(function (response) {
            return response.json();
          })
          .then((data) => {
            data.Data.forEach((template: IModulexTemplate) => {
              let templateItem = {
                id: template.Pk_GraphicTemplatesId,
                fileName: template.Filename,
                productIds: [],
              } as ITemplate;

              let foundId = "";
              template.PK_ProductID.forEach((productNo) => {
                templateItem.productIds.push(productNo.toString());
                if (productNo.toString() === selectedItem.templateId) {
                  foundId = templateItem.id.toString();
                }
              });
              this.templates.push(templateItem);

              // now get the template data
              if (foundId !== "") {
                fetch(
                  "https://wotbackend.modulex.com/api/v1/Parameters/GraphicTemplates/" +
                    foundId +
                    "?ApiKey=4CA681E9-E58F-45D2-BEAC-07CCFE08A1D1"
                )
                  .then(function (result) {
                    return result.json();
                  })
                  .then((template) => {
                    let bytes = base64ToArrayBuffer(template.Data);
                    let blob = new Blob([bytes], { type: "application/pdf" });
                    // const id = uuidv4();
                    // let f: IFile = {
                    //   id: id,
                    //   isSelected: true,
                    //   name: templateItem.fileName,
                    //   path: "",
                    //   blob: blob,
                    // };
                    // this.$store.commit("addFile", f);

                    const marketplaceConnection = {
                      id: uuidv4(),
                      name: selectedItem.name,
                      productNo: selectedItem.id,
                      description: "",
                      thumbnail: this.selectedProduct.thumbnail,
                      templateName: templateItem.fileName,
                      template: blob,
                      isSelected: true,
                    } as IMarketplaceItem;

                    this.$store.commit(
                      "addMarketplaceConnection",
                      marketplaceConnection
                    );

                    this.$store.commit("setShowLoadingSpinner", false);
                  });
              }
            });
            this.$store.commit("setTemplates", this.templates);
          });
      } else {
        this.$store.getters.templates.forEach((template: ITemplate) => {
          let templateItem = {
            id: template.id,
            fileName: template.fileName,
            productIds: template.productIds,
          } as ITemplate;

          let foundId = "";
          template.productIds.forEach((productNo) => {
            templateItem.productIds.push(productNo.toString());
            if (productNo.toString() === selectedItem.templateId) {
              foundId = templateItem.id.toString();
            }
          });
          this.templates.push(templateItem);

          // now get the template data
          if (foundId !== "") {
            fetch(
              "https://wotbackend.modulex.com/api/v1/Parameters/GraphicTemplates/" +
                foundId +
                "?ApiKey=4CA681E9-E58F-45D2-BEAC-07CCFE08A1D1"
            )
              .then(function (result) {
                return result.json();
              })
              .then((template) => {
                let bytes = base64ToArrayBuffer(template.Data);
                let blob = new Blob([bytes], { type: "application/pdf" });
                const id = uuidv4();
                let f: IFile = {
                  id: id,
                  isSelected: true,
                  name: templateItem.fileName,
                  path: "",
                  blob: blob,
                };
                this.$store.commit("addFile", f);

                this.$store.commit("deselectAllMarketplaceConnections");

                const marketplaceConnection = {
                  id: uuidv4(),
                  name: selectedItem.name,
                  productNo: selectedItem.id,
                  description: "",
                  thumbnail: this.selectedProduct.thumbnail,
                  templateName: templateItem.fileName,
                  template: blob,
                  isSelected: true,
                } as IMarketplaceItem;

                this.$store.commit(
                  "addMarketplaceConnection",
                  marketplaceConnection
                );
              });
          }
        });
      }

      this.$store.commit("setActivePanel", "assets");

      //close the window
      this.$store.commit("setShowProductModal", false);
    },
    /**
     * close the modal
     * @param {Event} e - the event that triggered the close
     */
    closeModal: function (e: Event) {
      e.preventDefault();
      e.stopPropagation();
      this.$store.commit("setShowProductModal", false);
    },
  },
  computed: {
    // selectedProduct: function (): iCatalog {
    //   console.log("this is in product modal....");
    //   console.log(this.$store.getters.selectedProduct);
    //   return JSON.parse(JSON.stringify(this.$store.getters.selectedProduct));
    // },
  },
  watch: {
    /**
     * when showProductModal from the store changes
     * @param {boolean} newValue - the value of showProductModal from the store
     */
    "$store.state.signDesigner.showProductModal": function (newValue: boolean) {
      //   const this = this;
      this.showModal = newValue;
      for (let i = 0; i < this.$store.getters.catalog.length; i++) {
        if (this.$store.getters.catalog[i].isSelected) {
          this.selectedProduct = this.$store.getters.catalog[i];
          break;
        }
      }
    },
  },
});
