




































































import Vue from "vue";
import Snap from "snapsvg-cjs-ts";

import { normalCaseToUnderscore } from "../../include/Utils";
import { iSignSide, iSignChild, IRepeat } from "../../types";
import { uuidv4 } from "../../utilities";

export default Vue.extend({
  components: {},
  data: function () {
    return {
      showModal: false,
      name: "Side",
      copyFromSide: -1,
      linkToSide: -1,
      repeatsMap: [] as Array<{
        oldId: string;
        newId: string;
      }>,
      repeatNamesMap: [] as Array<{ oldName: string; newName: string }>,
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showAddSideModal;
  },
  methods: {
    /**
     * create side 1 and add elements to it
     */
    createSide1: function () {
      // create a group for side 1 and move elements into it
      const svg = this.$store.getters.canvas
        .select("svg")
        .children() as Array<Snap.Element>;
      const side1 = this.$store.getters.canvas.select("*").paper?.g();

      if (side1) {
        for (let i = 0; i < svg.length; i++) {
          const el = svg[i];
          switch (el.type) {
            case "svg":
            case "defs":
            case "#text":
              // do nothing
              break;
            default: {
              // add to copiedElements array
              side1.append(el);
            }
          }
        }
        side1.attr({ id: "side_1" });
      }
    },
    /**
     * get next repeat name
     */
    getNextRepeatName: function (): string {
      let biggestRepeatNumber = 0;
      this.$store.getters.repeats.forEach((repeat: IRepeat) => {
        //Note: this will break if we have more than 9 repeats
        const repeatNumber = parseInt(
          repeat.name.substr(repeat.name.length - 1)
        );
        if (repeatNumber > biggestRepeatNumber) {
          biggestRepeatNumber = repeatNumber;
        }
      });
      return "Repeat" + (biggestRepeatNumber + 1);
    },
    /**
     * is in repeat names map
     */
    isInRepeatNamesMap: function (name: string) {
      let isFound = false;
      this.repeatNamesMap.forEach((repeatName) => {
        if (repeatName.oldName === name) {
          isFound = true;
        }
      });
      return isFound;
    },
    /**
     * get new repeat name
     */
    getNewRepeatName: function (oldRepeatName: string): string {
      let newName = "";

      this.repeatNamesMap.forEach((repeat) => {
        if (repeat.oldName === oldRepeatName) {
          newName = repeat.newName;
        }
      });
      return newName;
    },
    /**
     * save changes
     */
    saveChanges: function () {
      let payload = {
        id: normalCaseToUnderscore(this.name),
        name: this.name,
        copyFrom: this.copyFromSide,
      };

      let copyFrom = this.copyFromSide;

      if (this.$store.getters.signData.numberOfSides === 1) {
        this.createSide1();
      }

      this.$store.commit("addSideToSignData", payload);
      this.$nextTick(() => {
        this.copySide(copyFrom, this.$store.getters.signData.numberOfSides - 1);
        this.copyFields(
          this.$store.getters.canvas
            .select("#side_" + (copyFrom + 1))
            .children(),
          this.$store.getters.signData.numberOfSides - 1
        );

        this.$emit("signSideAdded");
        /* eslint-disable-next-line */
        (this.$root.$refs.SVGRenderer as any).processSVG(false, true);
        //close the window
        this.$store.commit("setShowAddSideModal", false);
      });
    },
    copySide: function (fromSide: number, toSide: number) {
      let localSignData = JSON.parse(
        JSON.stringify(this.$store.getters.signData)
      );
      this.copyElements(
        localSignData.sides[fromSide].children,
        localSignData.sides[toSide],
        toSide
      );
      this.copySVGElements(
        "side_" + (fromSide + 1),
        normalCaseToUnderscore(this.name),
        toSide
      );
      this.$store.commit("setSignData", localSignData);
    },
    copyElements: function (
      fromElements: Array<iSignChild>,
      toElement: iSignSide,
      newSideNumber: number
    ) {
      fromElements.forEach((element) => {
        const copyElement = {
          id: element.id + "_" + newSideNumber,
          alignment: element.alignment,
          content: element.content,
          css: element.css,
          cssClass: element.cssClass,
          elementType: element.elementType,
          fieldType: element.fieldType,
          fill: element.fill,
          stroke: element.stroke,
          fontInfo: element.fontInfo,
          height: element.height,
          width: element.width,
          x: element.x,
          y: element.y,
          isSelected: element.isSelected,
          isTargetted: element.isTargetted,
          isVisible: element.isVisible,
          name: element.name,
          svgId: element.svgId + "_" + newSideNumber,
          element: element.element,
          children: [],
        };
        if (element.fieldType === "repeat") {
          if (!this.isInRepeatNamesMap(element.name)) {
            copyElement.name = this.getNextRepeatName();
            this.repeatNamesMap.push({
              oldName: element.name,
              newName: copyElement.name,
            });
          }
        }
        toElement.children.push(copyElement);

        if (element.children.length > 0) {
          this.copyElements(
            element.children,
            toElement.children[toElement.children.length - 1],
            newSideNumber
          );
        }
      });
    },
    copySVGElements: function (
      fromSideId: string,
      toSideId: string,
      newSideNumber: number
    ) {
      const svg = this.$store.getters.canvas
        .select("#" + fromSideId)
        .children() as Array<Snap.Element>;
      const copiedElements = [] as Array<Snap.Element>;

      for (let i = 0; i < svg.length; i++) {
        const el = svg[i];
        switch (el.type) {
          case "svg":
          case "defs":
          case "#text":
            // do nothing
            break;
          default: {
            // add to copiedElements array
            const clonedEl = el.clone();
            // set the ids so they are unique
            if (el.attr("id")) {
              clonedEl.attr({ id: el.attr("id") + "_" + newSideNumber });
            }
            if (el.attr("sa-data-id")) {
              clonedEl.attr({
                "sa-data-id": el.attr("sa-data-id") + "_" + newSideNumber,
              });
            }
            this.fixElementIds(clonedEl.children(), newSideNumber);
            this.setClickEvents(clonedEl.children());

            copiedElements.push(clonedEl);
          }
          // if (copiedElements[copiedElements.length - 1].attr("id")) {
          //   copiedElements[copiedElements.length - 1].attr({
          //     id: el.attr("id") + "_" + newSideNumber,
          //     "sa-data-id": el.attr("sa-data-id") + "_" + newSideNumber,
          //   });
          // }
          // } catch (e) {
          // }
        }
      }
      const side = this.$store.getters.canvas.select("*").paper?.g();
      if (side) {
        for (let i = 0; i < copiedElements.length; i++) {
          side.append(copiedElements[i]);
        }
        side.attr({ id: toSideId });
      }
      //fix the element ids so they are unique
      //      this.fixElementIds(side.children(), newSideNumber);
    },
    copyFields: function (
      fromElements: Array<Snap.Element>,
      newSideNumber: number
    ) {
      fromElements.forEach((element: Snap.Element) => {
        const oldField = this.$store.getters.fieldByElementId(
          element.attr("sa-data-id")
        );
        if (!isNaN(oldField.height)) {
          let newField = JSON.parse(JSON.stringify(oldField));
          newField.name = oldField.name + "_" + newSideNumber;
          newField.elementIds = [];
          newField.id = uuidv4();
          oldField.elementIds.forEach((oldId: string) => {
            newField.elementIds.push(oldId + "_" + newSideNumber);
          });
          if (newField.belongsToRepeatId) {
            let foundRepeat = false;
            this.repeatsMap.forEach((repeat) => {
              if (repeat.oldId === newField.belongsToRepeatId) {
                newField.belongsToRepeatId = repeat.newId;
                //push this element id onto the elementsid of the repeat
                const newRepeat = JSON.parse(
                  JSON.stringify(this.$store.getters.repeatById(repeat.newId))
                );
                newRepeat.elementIds.push(newField.elementIds[0]);
                this.$store.commit("updateRepeat", newRepeat);
                foundRepeat = true;
              }
            });
            if (!foundRepeat) {
              //add a repeat
              const oldRepeat = this.$store.getters.repeatById(
                newField.belongsToRepeatId
              );

              const addRepeatId = uuidv4();
              //TODO: calculate the name from existing repeats
              const name = this.getNewRepeatName(oldRepeat.name);
              const addRepeat = {
                id: addRepeatId,
                isSelected: false,
                name: name,
                steps: oldRepeat.steps,
                offset: oldRepeat.offset,
                x: oldRepeat.x,
                y: oldRepeat.y,
                width: oldRepeat.width,
                height: oldRepeat.height,
                elementIds: [],
                clonedElementIds: [],
              } as IRepeat;
              addRepeat.elementIds.push(newField.elementIds[0]);

              this.$store.commit("addRepeat", addRepeat);
              // add repeatsMap
              this.repeatsMap.push({
                oldId: newField.belongsToRepeatId,
                newId: addRepeat.id,
              });
              //link newfield to new repeat
              newField.belongsToRepeatId = addRepeat.id;
            }
          }
          this.$store.commit("addField", newField);
        }

        if (element.children().length > 0) {
          this.copyFields(element.children(), newSideNumber);
        }
      });
    },
    fixElementIds: function (
      elements: Array<Snap.Element>,
      newSideNumber: number
    ) {
      elements.forEach((element) => {
        let originalEl = this.$store.getters.canvas.select(
          "[sa-data-id='" + element.attr("sa-data-id") + "']"
        );

        if (originalEl) {
          // if the element has a data-name attribute then don't rename the id
          if (
            originalEl.attr("data-name") &&
            originalEl.attr("data-name") !== ""
          ) {
            element.attr({
              id: originalEl.attr("id"),
              "sa-data-id": originalEl.attr("sa-data-id") + "_" + newSideNumber,
            });
          } else {
            if (originalEl.attr("id")) {
              element.attr({
                id: originalEl.attr("id") + "_" + newSideNumber,
                // "sa-data-id":
                //   originalEl.attr("sa-data-id") + "_" + newSideNumber,
              });
            }
          }
          if (originalEl.attr("sa-data-id")) {
            element.attr({
              "sa-data-id": originalEl.attr("sa-data-id") + "_" + newSideNumber,
            });
          }
        }

        if (element.children().length > 0) {
          this.fixElementIds(element.children(), newSideNumber);
        }
      });
    },
    setClickEvents: function (elements: Array<Snap.Element>) {
      elements.forEach((element) => {
        if (element.type !== "g" && element.type !== "#text") {
          element.click((e: MouseEvent) => {
            this.$root.$refs.SVGRenderer.selectElement(element, e);
          });
        }
        if (element.children().length > 0) {
          this.setClickEvents(element.children());
        }
      });
    },
    /**
     * close the modal
     * @param {Event} e - the event that triggered the close
     */
    closeModal: function (e: Event) {
      e.preventDefault();
      e.stopPropagation();
      this.$store.commit("setShowAddSideModal", false);
      this.$emit("cancelClicked");
    },
  },
  computed: {},
  watch: {
    /**
     * when showPublishModal from the store changes
     * @param {boolean} newValue - the value of showPublishModal from the store
     */
    "$store.state.signDesigner.showAddSideModal": function (newValue: boolean) {
      //   const this = this;
      this.name = "Side " + (this.$store.getters.signData.numberOfSides + 1);
      this.showModal = newValue;
    },
  },
});
