












































































import Vue from "vue";
import vSelect from "vue-select";

import FullPageLayout from "@/components/layouts/FullPageLayout.vue";
import { ICompany, IPricePlan } from "../types";

export default Vue.extend({
  components: { FullPageLayout, vSelect },
  data: function () {
    return {
      pricePlanNameInput: "",
      pricePlanTypeInput: "",
      newPricePlan: {} as IPricePlan,
      pricePlanCreated: 0, // 0 := not attempted, 1 := successfully created, -1 := attempted and failed
      privatePlan: false,
      selectedCompanies: [] as Array<{ id: string; label: string }>,
      companyList: [] as Array<{ id: string; label: string }>,
    };
  },
  methods: {
    createPricePlan() {
      if (this.pricePlanNameInput && this.pricePlanTypeInput) {
        fetch("/graphql/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            query: `
              mutation create_price_plan {
                mutatePricingPlan(input: {
                  company: ${this.$route.params.id},
                  name: "${this.pricePlanNameInput}",
                  planType: "${this.pricePlanTypeInput}",
                  isPrivatePlan: ${this.privatePlan},
                  assignedCompanies: [${this.selectedCompanies.map(
                    (company) => {
                      return company.id;
                    }
                  )}]
                }) {
                  errors { messages }
                  pricingPlan {
                    id: contentObjectId
                    name
                    company {
                      name
                      id: contentObjectId
                    }
                    planType
                  }
                }
              }`,
          }),
        })
          .then((result) => result.json())
          .then((data) => {
            if (data.errors) {
              alert(data.errors[0].message);
            } else if (data.data.mutatePricingPlan?.errors.length > 0) {
              alert(data.data.mutatePricingPlan.errors[0].messages.join(", "));
            } else {
              const localPricePlan = {
                id: data.data.mutatePricingPlan.pricingPlan.id,
                name: data.data.mutatePricingPlan.pricingPlan.name,
                planType: data.data.mutatePricingPlan.pricingPlan.planType,
                isPrivatePlan: false,
                assignedCompanies: [],
              };
              this.newPricePlan = localPricePlan;
              this.pricePlanCreated = 1;
            }
          });
      } else {
        this.pricePlanCreated = -1;
      }
      this.pricePlanNameInput = "";
      this.pricePlanTypeInput = "";
    },
    filterCompanies: function (search: string) {
      const query = JSON.stringify({
        query: `query {
        	companies (name_Icontains:"${search}") {
            edges {
              node {
                id:contentObjectId
                label: name
              }
            }
          }
        }`,
      });

      fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
      })
        .then((data) => data.json())
        .then((result) => {
          this.companyList = [];
          result.data.companies.edges.forEach(
            (company: { node: { id: string; label: string } }) => {
              this.companyList.push({
                id: company.node.id,
                label: company.node.label,
              });
            }
          );
        });
    },
  },
  computed: {
    companies: function (): Array<{ id: string; label: string }> {
      return this.$store.getters.companies.map((company: ICompany) => {
        return { id: company.id, label: company.name };
      });
    },
  },
  watch: {
    pricePlanCreated(newValue) {
      if (newValue === 1) {
        this.$router.push(this.newPricePlan.id + "/edit");
      }
    },
  },
});
