<template>
  <map-view-layout
    size="large"
    signbar="hidden"
    :loading="this.$store.getters.showLoadingSpinner"
    ref="modallayout"
    id="stateform"
  >
    <state-form></state-form>
  </map-view-layout>
</template>

<script>
import Vue from "vue";
import StateForm from "./views/StateForm.vue";
import MapViewLayout from "@/components/layouts/MapViewLayout.vue";

export default Vue.extend({
  components: { StateForm, MapViewLayout },
});
</script>

<style></style>
