/* eslint-disable-next-line */
export async function performQuery(query: string): Promise<any> {
  const response = await fetch("/graphql/", {
    method: "POST",
    body: query,
    headers: {
      "content-type": "application/json",
      Accept: "application/json",
    },
  });

  if (!response.ok) {
    const message = `An error has occurred: ${response.status} ${response.statusText}`;
    throw new Error(message);
  }

  return await response.json();
}

export function OrganizationUUIDFromIdQuery(id: string): string {
  return JSON.stringify({
    query: `query organization ($id: ID) {
        organization(id: $id) {
         uuid
        }
      }`,
    variables: { id: id },
  });
}
export function OrganizationIdFromSignTypeQuery(id: string): string {
  return JSON.stringify({
    query: `query signType ($id: ID ) {
        signType(id: $id) {
         uuid
          project {
            id: contentObjectId
            uuid
          }
        }
      }`,
    variables: { id: id },
  });
}
export function OrganizationDataQuery(uuid: string): string {
  return JSON.stringify({
    query: `
        query Organization ($uuid: String) {
          organization(uuid: $uuid) {
            id
            uuid
            fields {
              edges {
                node {
                  id: contentObjectId
                  uuid
                  fieldType
                  name
                }
              }
            }
            fonts {
              edges {
                node {
                  id
                  uuid
                  url
                  name
                }
              }
            }
            colors {
              edges {
                node {
                  id
                  uuid
                  name
                  color
                }
              }
            }
            visuals {
              edges {
                node {
                  id
                  uuid
                  imageUrl
                  isMarker
                  useDarkBackground
                }
              }
            }
            signTypes {
              edges {
                node {
                  id
                  uuid
                  name
                  shortCode
                  hexColor
                }
              }
            }
          }
        }
        `,
    variables: { uuid: uuid },
  });
}

export function TranslationLanguagesQuery(uuid: string): string {
  return JSON.stringify({
    query: `
        query Organization ($uuid: String) {
          organization(uuid: $uuid) {
            translationLanguages {
              edges {
                node {
                  id: contentObjectId
                  uuid
                  name
                }
              }
            }
          }
        }
        `,
    variables: { uuid: uuid },
  });
}

export function SignTypeQuery(uuid: string): string {
  return JSON.stringify({
    query: `query SignType ($uuid: String) {
        signType(uuid: $uuid) {
          id: contentObjectId
          uuid
          folder {
            id: contentObjectId
          }
          # library {
          #   id: contentObjectId
          # }
          name
          shortCode
          details
          hexColor
          unitCost
          numberOfSides
          numberOfColumns
          numberOfMessages
          isDirectional
          marker {
            id: contentObjectId
          }
          useScaleInMessageSchedule
          scale
          publish
          markerNoScalePercent
          markerScale {
            id: contentObjectId
          }
          markerScaleWidth
          markerScaleWidthUnit
          svgUrl
          svgFile
          # componentJson
          revisionJson
          messageFields {
            edges {
              node {
                id: contentObjectId
                uuid
                name
                fieldType
              }
            }
          }
          repeatingMessageFields {
            edges {
              node {
                id: contentObjectId
                uuid
                name
                fieldType
              }
            }
          }
          detailFields {
            edges {
              node {
                id: contentObjectId
                uuid
                name
                fieldType
              }
            }
          }
          # parts {
          #   edges {
          #     node {
          #       id: contentObjectId
          #       uuid
          #       name
          #     }
          #   }
          # }
          # price
          # priceCurrency
          # materials {
          #   edges {
          #     node {
          #       id: contentObjectId
          #       uuid
          #       name
          #     }
          #   }
          # }
          # processes {
          #   edges {
          #     node {
          #       id: contentObjectId
          #       uuid
          #       name
          #     }
          #   }
          # }
        }
      }`,
    variables: { uuid: uuid },
  });
}

export function SignCountQuery(uuid: string): string {
  return JSON.stringify({
    query: `query SignType ($uuid: String) {
      signType(uuid: $uuid) {
        signs {
          edges {
            node {
              quantity
            }
          }
        }
      }
    }`,
    variables: { uuid: uuid },
  });
}

export function UsersByIdQuery(id: string): string {
  const userList: Array<string> = [];
  userList.push(id);

  return JSON.stringify({
    query: `query User ($id: ID!) {
      user(id: $id) {
          id: userId
          firstName
          lastName
          email
        }
      }
    `,
    variables: { id: id },
  });
}

export function ColorLibraries(): string {
  return JSON.stringify({
    query: `query ColorLibraries {
      colorLibraries {
        edges {
          node {
            id
            uuid
            name
            publish
            colorTotalCount
            colors {
              edges {
                node {
                  contentObjectId
                  name
                  description
                  gloss
                  hexValue
                }
              }
            }
          }
        }
      }
    }`,
  });
}

export function SignFoldersList(uuid: string): string {
  return JSON.stringify({
    query: `query SignFoldersList ($uuid: String) {
      organization (uuid:$uuid) {
        signTypes {
          edges {
            node {
              id: contentObjectId
              uuid: contentObjectId
              name
              shortCode
              hexColor
              folder {
                id: contentObjectId
              }
            }
          }
        }
        signTypeFolders {
          edges {
            node {
              name
              orderId
              parent {
                id: contentObjectId
              }
              children {
                edges {
                  node {
                    id: contentObjectId
                    uuid: contentObjectId
                    name
                    children {
                      edges {
                        node {
                          id: contentObjectId
                          uuid: contentObjectId
                          name
                          children {
                            edges {
                              node {
                                id: contentObjectId
                                uuid: contentObjectId
                                name
                                children {
                                  edges {
                                    node {
                                      id: contentObjectId
                                      uuid: contentObjectId
                                      name
                                      children {
                                        edges {
                                          node {
                                            id: contentObjectId
                                            uuid: contentObjectId
                                            name
                                            children {
                                              edges {
                                                node {
                                                  id: contentObjectId
                                                  uuid: contentObjectId
                                                  name
                                                  children {
                                                    edges {
                                                      node {
                                                        id: contentObjectId
                                                        uuid: contentObjectId
                                                        name
                                                        children {
                                                          edges {
                                                            node {
                                                              id: contentObjectId
                                                              uuid: contentObjectId
                                                              name
                                                              children {
                                                                edges {
                                                                  node {
                                                                    id: contentObjectId
                                                                    uuid: contentObjectId
                                                                    name
                                                                    children {
                                                                      edges {
                                                                        node {
                                                                          id: contentObjectId
                                                                          uuid: contentObjectId
                                                                          name
                                                                        }
                                                                      }
                                                                    }
                                                                    signTypes {
                                                                      edges {
                                                                        node {
                                                                          id: contentObjectId
                                                                          uuid
                                                                          name
                                                                          shortCode
                                                                          hexColor
                                                                        }
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                              signTypes {
                                                                edges {
                                                                  node {
                                                                    id: contentObjectId
                                                                    uuid
                                                                    name
                                                                    shortCode
                                                                    hexColor
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                        signTypes {
                                                          edges {
                                                            node {
                                                              id: contentObjectId
                                                              uuid
                                                              name
                                                              shortCode
                                                              hexColor
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                  signTypes {
                                                    edges {
                                                      node {
                                                        id: contentObjectId
                                                        uuid
                                                        name
                                                        shortCode
                                                        hexColor
                                                      }
                                                    }
                                                  }
                                                }
                                              }
                                            }
                                            signTypes {
                                              edges {
                                                node {
                                                  id: contentObjectId
                                                  uuid
                                                  name
                                                  shortCode
                                                  hexColor
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                      signTypes {
                                        edges {
                                          node {
                                            id: contentObjectId
                                            uuid
                                            name
                                            shortCode
                                            hexColor
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                                signTypes {
                                  edges {
                                    node {
                                      id: contentObjectId
                                      uuid
                                      name
                                      shortCode
                                      hexColor
                                    }
                                  }
                                }
                                    }
                            }
                          }
                          signTypes {
                            edges {
                              node {
                                id: contentObjectId
                                uuid
                                name
                                shortCode
                                hexColor
                              }
                            }
                          }
                        }
                      }
                    }
                    signTypes {
                      edges {
                        node {
                          id: contentObjectId
                          uuid
                          name
                          shortCode
                          hexColor
                        }
                      }
                    }
                  }
                }
              }
              signTypes {
                edges {
                  node {
                    id: contentObjectId
                    uuid
                    name
                    shortCode
                    hexColor
                  }
                }
              }
            }
          }
        }
      }
    }`,
    variables: { uuid: uuid },
  });
}
