














































import Vue from "vue";
import Snap from "snapsvg-cjs-ts";

import { IFile } from "../../types";
import { uuidv4, clearSignTemplate } from "../../utilities";
// import matthews from "./assets/matthews.json";

export default Vue.extend({
  components: {},
  data: function () {
    return {
      showModal: false,
      files: null as FileList | null,
      uploadedSVG: {} as Snap.Element,
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showFileModal;
  },
  methods: {
    /**
     * triggered when the file input changes
     * @param {Event} e - the event that triggered the change event
     */
    fileChanged: function (e: Event) {
      const target = e.target as HTMLInputElement;
      this.files = target.files as FileList;
    },
    /**
     * test if an object is empty
     * @param {any} obj - the object to test
     * @returns {boolean} - True if object is empty
     */
    // eslint-disable-next-line
    isEmpty: function (obj: any): boolean {
      // console.debug(`isEmpty: ${sequenceNum++}`);
      if (obj === null) {
        return true;
      } else {
        return (
          obj && Object.keys(obj).length === 0 && obj.constructor === Object
        );
      }
    },
    /**
     * save changes
     */
    saveChanges: function () {
      if (this.files) {
        // clear any existing templates
        clearSignTemplate(this);

        // if the canvas is empty then this is the first file loaded
        if (this.isEmpty(this.$store.getters.canvas)) {
          for (let i = 0; i < this.files.length; i++) {
            const file: File = this.files[i];
            const extension = file.name.split(".").pop();
            const id = uuidv4();
            let f: IFile = {
              id: id,
              isSelected: true,
              name: file.name,
              path: URL.createObjectURL(file),
              blob: file,
            };
            this.$store.commit("addFile", f);
            //update the signData template name with the filename (without the extension)
            this.$store.commit(
              "setSignDataTemplateName",
              file.name.replace(/\.[^/.]+$/, "")
            );
            if (extension === "svg") {
              if (
                this &&
                this.$root &&
                this.$root.$refs &&
                this.$root.$refs.SVGRenderer
              ) {
                /* eslint-disable-next-line */
                (this.$root.$refs.SVGRenderer as any).loadSVG(f.path);
              }
            }
          }
        } else {
          //1. update the defs with the defs from the uploaded file
          //2. get the differences between the uploaded file and the data on the canvas
          //3. add the differences to the SVG canvas
          const extension = this.files[0].name.split(".").pop();
          if (extension === "svg") {
            const path = URL.createObjectURL(this.files[0]);
            Snap.load(path, (fragment: Snap.Fragment) => {
              this.uploadedSVG = fragment.select("*");
              const newDefs = this.uploadedSVG.select("defs");
              let oldDefs = this.$store.getters.canvas.select("defs");
              if (oldDefs) {
                oldDefs.node.innerHTML = newDefs.node.innerHTML;
              } else {
                oldDefs = newDefs;
              }

              let svgElem = this.uploadedSVG.select("svg");
              this.$store.getters.canvas.append(svgElem);

              /* eslint-disable-next-line */
              (this.$root.$refs.SVGRenderer as any).processSVG(false, true);
            });
          }
        }
      }
      //clear the canvas
      // try {
      //   //        this.$store.getters.canvas.select("svg").remove();
      // } catch (err) {
      // }
      this.clearModal();
      //close the window
      this.$store.commit("setShowFileModal", false);
    },
    /**
     * close the modal
     * @param {Event} e - the event that triggered the close
     */
    closeModal: function (e: Event) {
      this.clearModal();
      e.preventDefault();
      e.stopPropagation();
      this.$store.commit("setShowFileModal", false);
    },
    clearModal: function () {
      let fileElement = document.getElementById("filePath") as HTMLInputElement;
      if (fileElement) {
        fileElement.value = "";
      }
    },
  },
  computed: {
    /**
     * getter/setter for fileName
     */
    fileName: {
      get() {
        if (this.$store.getters.selectedFile) {
          return this.$store.getters.selectedFile.name;
        } else {
          return "";
        }
      },
      set(value) {
        this.$store.commit("updateSelectedFileName", value);
      },
    },
    /**
     * getter/setter for filePath
     */
    filePath: {
      get() {
        if (this.$store.getters.selectedFile) {
          return this.$store.getters.selectedFile.path;
        } else {
          return "";
        }
      },
      set(value) {
        this.$store.commit("updateSelectedFilePath", value);
      },
    },
  },
  watch: {
    /**
     * when showFileModal from the store changes
     * @param {boolean} newValue - the value of the showFileModal from the store
     */
    "$store.state.signDesigner.showFileModal": function (newValue: boolean) {
      //   const this = this;
      this.showModal = newValue;
    },
  },
});
