import { ModelKey, PermObject } from "../types";

/**
 * Save any changes the user has made to PermObjects[]
 *
 * @param permObjects - Array of PermObjects to search for changes that we want to save
 * @returns A promise which completes on successful save
 */
export default async function savePermissions(
  permObjects: PermObject[]
): Promise<void> {
  const permOperations = Array<{
    success: () => void;
    fail: (msg: string) => void;
    key: string;
    data: {
      objectType: ModelKey;
      objectId: string;
      groupName: string;
      userEmails: string;
    };
  }>();

  {
    let count = 0;
    for (const permObject of permObjects) {
      if (!permObject.canEdit) continue;
      for (const userPerm of permObject.userPerms) {
        if (userPerm.permission !== userPerm.original_permission) {
          permOperations.push({
            success: () => {
              if (userPerm.permission) {
                userPerm.original_permission = userPerm.permission;
              } else {
                const index = permObject.userPerms.indexOf(userPerm);
                if (index == -1) {
                  console.error("Expected user to exist in array");
                  return;
                }
                permObject.userPerms.splice(index, 1);
              }
            },
            fail: (message: string) => (userPerm.error = message),
            key: `op${count++}`,
            data: {
              objectType: permObject.objectType,
              objectId: permObject.objectId,
              groupName: userPerm.permission || "none",
              userEmails: userPerm.email,
            },
          });
        }
      }
    }
  }

  const mutationData = Object.fromEntries(
    permOperations.map((e) => [e.key, e.data])
  );
  const mutation = `
    mutation SavePermissions(
      ${permOperations
        .map(
          (e) => `
          $${e.key}: GroupUsersMutationInput!
        `
        )
        .join("")}
    ){
    ${permOperations
      .map(
        (e) => `
        ${e.key}: updateGroupUsers(input:$${e.key})
          {
            errors {
              messages
            }
          }
      `
      )
      .join("")}
  }`;

  const data = await (
    await fetch("/graphql/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        query: mutation,
        variables: mutationData,
      }),
    })
  )
    .json()
    .catch((err) => {
      console.log(err); // TODO: handle better!!
      throw new Error("Failed to save changes.");
    });

  let numErrors = 0;
  const errorMessages = Array<string>();
  if (Array.isArray(data.errors)) {
    for (const error of data.errors) {
      if (typeof error.message === "string") {
        errorMessages.push(error.message);
      }
    }
  }
  for (const permOperation of permOperations) {
    const opData = data.data[permOperation.key];
    if (opData === null && !data.errors) {
      continue;
    } else if (!opData || typeof opData !== "object") {
      console.log(`Expected "${permOperation.key}" to be in returned data`);
      continue;
    }
    const errors = data.data[permOperation.key]?.errors;
    if (!Array.isArray(errors)) {
      console.log(`Expected data.${permOperation.key}.errors to be an array`);
      continue;
    } else if (errors.length == 0) {
      permOperation.success();
    } else {
      numErrors++;
      console.log(`${permOperation.key} failed with`, errors);
      const ret = Array<string>();
      for (const error of errors) {
        if (!Array.isArray(error.messages)) {
          console.log(
            "expected errors to contain messages array, got",
            error.messages
          );
          continue;
        }
        ret.push(error.messages.join(" "));
      }
      permOperation.fail(ret.join(" "));
    }
  }

  if (numErrors === 0) {
    // pass
  } else if (numErrors === permOperations.length) {
    errorMessages.unshift("We were not able to save your changes.");
  } else {
    errorMessages.unshift(
      `${numErrors} of your ${permOperations.length} changes could not be saved.`
    );
  }

  if (errorMessages.length) {
    throw errorMessages;
  }
}
