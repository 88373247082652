import { Module } from "vuex";
import { RootState } from "../../store";

import {
  ICompany,
  IPricePlan,
  ISubscription,
  IPriceTier,
  IBillingCycle,
  IMember,
  IPlan,
  IPlanUsers,
  IUserLicense,
  IInvoice,
  IPaymentMethod,
} from "./types";
import Stripe from "stripe";

export interface CompanySettingsState {
  company: ICompany;
  pricePlans: Array<IPricePlan>;
  priceTiers: Array<IPriceTier>;
  billingCycles: Array<IBillingCycle>;
  subscription: ISubscription;
  plans: Array<IPlan>;
  planUsers: Array<IPlanUsers>;
  users: Array<IMember>;
  userLicenses: Array<IUserLicense>;
  invoices: Array<IInvoice>;
  paymentMethods: Array<IPaymentMethod>;
  members: Array<IMember>;
  dataIsLoaded: boolean;
  stripe: Stripe | null;
  showLoadingSpinner: boolean;
}

const store: Module<CompanySettingsState, RootState> = {
  state: (): CompanySettingsState => ({
    company: {
      id: 1,
      customerNo: "",
      name: "",
      canEdit: false,
      street1: "",
      street2: "",
      postalCode: "",
      city: "",
      state: "",
      countryCode: "",
      phone: "",
      discountCodeId: 0,
      isSalesCompany: false,
      isWhiteLabelCompany: false,
      billingOwnerId: "",
      isOnlineBillingCompany: null,
    },
    paymentMethods: [],
    pricePlans: [],
    priceTiers: [],
    billingCycles: [
      { id: 1, name: "Monthly" },
      { id: 2, name: "Annual" },
    ],
    subscription: {} as ISubscription,
    plans: [],
    planUsers: [
      {
        id: 1,
        planId: 1,
        userId: 1,
      },
    ],
    users: [
      // {
      //   id: 1,
      //   firstName: "Bernie",
      //   lastName: "Blakeney",
      //   email: "bernie@signagent.com",
      //   phone: "",
      //   image: "",
      //   role: "Member",
      //   twoFAMethod: "email",
      //   emailFrequency: "hourly",
      // },
      // {
      //   id: 2,
      //   firstName: "Katy",
      //   lastName: "Blakeney",
      //   email: "katyblakeneyis@hotmail.com",
      //   phone: "",
      //   image: "",
      //   role: "Member",
      //   twoFAMethod: "email",
      //   emailFrequency: "hourly",
      // },
    ],
    userLicenses: [],
    invoices: [
      // {
      //   id: 1,
      //   created: new Date("2024-03-30"),
      //   stripeId: "in_1239983487903",
      //   status: "paid",
      //   total: 10,
      //   paidAt: new Date("2024-03-30"),
      //   paymentMethod: {
      //     cardType: "Visa",
      //     last4: "4242",
      //   },
      //   invoiceUrl:
      //     "https://drive.google.com/file/d/1-5eympyZIqDKGtel-tRbKIS1VsVmurCQ/view?usp=share_link",
      //   items: [
      //     {
      //       id: 1,
      //       amount: 10,
      //       currency: "USD",
      //       description: "Designer Standard",
      //     },
      //   ],
      // },
      // {
      //   id: 2,
      //   created: new Date("2024-02-28"),
      //   stripeId: "in_1239983487902",
      //   status: "paid",
      //   total: 10,
      //   paidAt: new Date("2024-02-28"),
      //   paymentMethod: {
      //     cardType: "Visa",
      //     last4: "4242",
      //   },
      //   invoiceUrl: null,
      //   items: [
      //     {
      //       id: 1,
      //       amount: 10,
      //       currency: "USD",
      //       description: "Designer Standard",
      //     },
      //   ],
      // },
      // {
      //   id: 3,
      //   created: new Date("2024-01-30"),
      //   stripeId: "in_1239983487901",
      //   status: "paid",
      //   total: 10,
      //   paidAt: new Date("2024-01-30"),
      //   paymentMethod: {
      //     cardType: "Visa",
      //     last4: "4242",
      //   },
      //   invoiceUrl: null,
      //   items: [
      //     {
      //       id: 1,
      //       amount: 10,
      //       currency: "USD",
      //       description: "Designer Standard",
      //     },
      //   ],
      // },
    ],
    members: [],
    dataIsLoaded: false,
    stripe: null,
    showLoadingSpinner: false,
  }),
  mutations: {
    setCompany(state, payload) {
      state.company = payload;
    },
    setStripe(state, payload) {
      state.stripe = payload;
    },
    addPricePlan(state, payload) {
      state.pricePlans.push(payload);
    },
    addPriceTier(state, payload) {
      const result = payload as IPriceTier;
      const previousTier = state.priceTiers
        .filter(
          (tier: IPriceTier) =>
            tier.billingCycle === result.billingCycle &&
            tier.pricePlanId === result.pricePlanId
        )
        .pop();
      if (previousTier) {
        result.price += previousTier.price;
      }
      state.priceTiers.push(result);
    },
    setSubscription(state, payload: ISubscription) {
      state.subscription = payload;
    },
    setPlans(state, payload) {
      state.plans = payload;
    },
    setPricePlans(state, payload) {
      state.pricePlans = payload;
    },
    setInvoices(state, payload) {
      state.invoices = payload;
    },
    setUsers(state: CompanySettingsState, payload: Array<IMember>) {
      state.users = payload;
    },
    setSubscriptionBillingOwner(state: CompanySettingsState, payload: number) {
      state.subscription.billingOwnerId = Number(payload);
    },
    setPaymentMethods(state, payload) {
      state.paymentMethods = payload;
    },
    addUserLicense(state, payload) {
      state.userLicenses.push(payload);
    },
    setMembers(state, payload) {
      state.members = payload;
    },
    setSelectedMemberFirstName(
      state,
      json: { memberId: number; value: string }
    ) {
      const member = state.members.find((member) => {
        return member.id === json.memberId;
      });

      if (member) {
        member.firstName = json.value;
      }
    },
    dataIsLoaded(state, payload) {
      state.dataIsLoaded = payload;
    },
    setShowLoadingSpinner(state, payload: boolean) {
      state.showLoadingSpinner = payload;
    },
  },
  getters: {
    company(state) {
      return state.company;
    },
    stripe(state) {
      return state.stripe;
    },
    subscription(state) {
      return state.subscription;
    },
    users(state) {
      return state.users;
    },
    userLicenses(state) {
      return state.userLicenses;
    },
    plans(state) {
      return state.plans;
    },
    pricePlans(state) {
      return state.pricePlans;
    },
    priceTiers(state) {
      return state.priceTiers;
    },
    invoices(state) {
      return state.invoices;
    },
    paymentMethods(state) {
      return state.paymentMethods;
    },
    priceTierById(state) {
      return function (id: string) {
        return state.priceTiers.find((priceTier) => {
          return priceTier.id === parseInt(id);
        });
      };
    },
    pricePlanById(state) {
      return function (id: string) {
        return state.pricePlans.find((pricePlan) => {
          return pricePlan.id === parseInt(id);
        });
      };
    },
    plansForSubscription(state) {
      return function (subscriptionId: string) {
        return state.plans.filter((plan) => {
          return plan.subscriptionId === parseInt(subscriptionId);
        });
      };
    },
    billingCycleById(state) {
      return function (id: string) {
        return state.billingCycles.find((billingCycle) => {
          return billingCycle.id === parseInt(id);
        });
      };
    },
    // planUsersByPlanId(state) {
    //   return function (planId: string) {
    //     return state.planUsers.filter((planUser) => {
    //       return planUser.planId === parseInt(planId);
    //     });
    //   };
    // },
    planById(state) {
      return function (id: string) {
        return state.plans.find((plan) => {
          return plan.id === parseInt(id);
        });
      };
    },
    userById(state) {
      return function (id: string) {
        return state.users.find((user) => {
          return user.id === parseInt(id);
        });
      };
    },
    memberById(state) {
      return function (id: string) {
        return state.members.find((member) => {
          return member.id === parseInt(id);
        });
      };
    },
    priceTiersForPricePlanAndBillingCycle(state) {
      return function (planId: string, cycleId: string) {
        return state.priceTiers.filter((priceTier) => {
          return (
            priceTier.pricePlanId === parseInt(planId) &&
            priceTier.billingCycle === cycleId
          );
        });
      };
    },
    getAnnualPriceTier(state) {
      return function (currentTier: IPriceTier) {
        return state.priceTiers.find((priceTier) => {
          return (
            priceTier.pricePlanId === currentTier.pricePlanId &&
            priceTier.numberOfUsers === currentTier.numberOfUsers &&
            priceTier.billingCycle === "YEAR"
          );
        });
      };
    },
    getMonthlyPriceTier(state) {
      return function (currentTier: IPriceTier) {
        return state.priceTiers.find((priceTier) => {
          return (
            priceTier.pricePlanId === currentTier.pricePlanId &&
            priceTier.numberOfUsers === currentTier.numberOfUsers &&
            priceTier.billingCycle === "MONTH"
          );
        });
      };
    },
    // invoiceById(state) {
    //   return function (id: string) {
    //     return state.invoices.find((invoice) => {
    //       return invoice.id === parseInt(id);
    //     });
    //   };
    // },
    priceTierIdForPlanCycleAndQuantity(state) {
      return function (planId: string, cycle: string, upTo: number) {
        const foundTier = state.priceTiers.find((tier) => {
          // stripe requires us to have a tier with null for numberOfUsers to handle the case for unlimited users
          // if the numberOfUsers is null skip this tier
          if (tier.numberOfUsers) {
            return (
              tier.pricePlanId === parseInt(planId) &&
              tier.billingCycle === cycle &&
              tier.numberOfUsers >= upTo
            );
          } else {
            return false;
          }
        });

        return foundTier?.id;
      };
    },
    priceTierByStripeId(state) {
      return function (stripePriceId: string) {
        return state.priceTiers.find((priceTier) => {
          return priceTier.stripePriceId === stripePriceId;
        });
      };
    },
    defaultPaymentMethod(state) {
      return state.paymentMethods.find((paymentMethod) => {
        return paymentMethod.default;
      });
    },
    activeUserLicenses(state) {
      const currentDate = new Date(Date());
      return state.userLicenses.filter((userLicense) => {
        return currentDate < userLicense.endDate;
      });
    },
    usersForPlanType(state) {
      return function (planType: string) {
        // owen_TODO: get rid of all instances where "O" and "M" are used for plan types
        let licenceType = "O";
        if (planType === "MOBILE") {
          licenceType = "M";
        }
        return state.users.filter((user) => {
          if (user.companyLicenses) {
            return user.companyLicenses.some((license) => {
              return license.type === licenceType;
            });
          }
          return false; // If user.companyLicenses doesn't exist or is empty
        });
      };
    },
    members(state) {
      return state.members;
    },
    dataIsLoaded(state) {
      return state.dataIsLoaded;
    },
    showLoadingSpinner(state) {
      return state.showLoadingSpinner;
    },
  },
};

export default store;
