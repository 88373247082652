
























































































































































































































import Vue from "vue";
//import VueSelect from "vue-select";
import ColorLibraryEditor from "../ColorLibraryEditor.vue";
import ColorEditor from "../ColorEditor.vue";

import { IColorLibrary, IColorItem, IMaterial } from "../../types";
import { uuidv4 } from "../../utilities";

export default Vue.extend({
  components: { ColorLibraryEditor, ColorEditor },
  data: function () {
    return {
      showModal: false,
      selectedMaterialIndex: -1,
      selectedColorLibraryIndex: -1,
      selectedColorIndex: -1,
      showColorLibraryEditor: false,
      showColorEditor: false,
      colorPickerTop: 100,
      colors: "#0176bc",
      // libraries: [
      //   { label: "Pantone", code: "pms" },
      //   { label: "Matthews", code: "matthews" },
      //   { label: "Modulex", code: "modulex" },
      //   { label: "Rowmark", code: "rowmark" },
      // ],
      suppliers: [] as Array<string>,
    };
  },
  mounted: function () {
    // read the color libraries from graphQL
    this.showModal = this.$store.getters.showMaterialsModal;
  },
  methods: {
    /**
     * get color count for a library
     * @param {number}
     */
    getColorCount: function (libraryIndex: number) {
      const includedColorCount = this.$store.getters.materials[
        this.selectedMaterialIndex
      ].libraries[libraryIndex].colors.filter((color: IColorItem) => {
        return color.included;
      }).length;
      if (
        this.$store.getters.materials[this.selectedMaterialIndex].libraries[
          libraryIndex
        ].colors.length === includedColorCount
      ) {
        return "All";
      } else {
        return (
          includedColorCount +
          "/" +
          this.$store.getters.materials[this.selectedMaterialIndex].libraries[
            libraryIndex
          ].colors.length
        );
      }
    },
    /**
     * toggle the color editor
     * @param {number} colorIndex - the index of the color the user selected
     */
    toggleColorEditor: function (colorIndex: number) {
      this.showColorLibraryEditor = false;
      this.showColorEditor = true;
      this.selectedColorIndex = colorIndex;
    },
    /**
     * add a material
     * called when the user clicks the Add Material button
     */
    addMaterial: function () {
      const material = {
        id: "",
        uuid: uuidv4(),
        label: "New Material",
        libraries: [],
        supplier: { id: "", name: "" },
      } as IMaterial;
      this.$store.commit("addMaterial", material);
      this.selectedMaterialIndex = this.$store.getters.materials.length - 1;
    },
    /**
     * delete the selected material
     * called when the trash icon is clicked
     */
    deleteMaterial(index: number) {
      this.$store.commit("deleteMaterialByIndex", index);
    },
    /**
     * push a new supplier onto the suppliers array
     * called when a new supplier is added to vue-select
     */
    pushSupplier: function (newOption: string) {
      this.suppliers.push(newOption);
    },
    /**
     * handle the Ok button clicked event
     */
    saveChanges: function () {
      //return to the list screen
      if (this.showColorLibraryEditor) {
        this.showColorLibraryEditor = false;
      } else if (this.showColorEditor) {
        this.showColorEditor = false;
        this.showColorLibraryEditor = true;
      } else {
        //if we are on the details screen
        if (this.selectedMaterialIndex >= 0) {
          //move back to list screen, don't close modal
          this.selectedMaterialIndex = -1;
        } else {
          //otherwise we are on the list screen, close modal
          this.$store.commit("setShowMaterialsModal", false);
        }
      }
    },
    /**
     * close the entire modal
     */
    closeModal: function () {
      this.showColorLibraryEditor = false;
      this.showColorEditor = false;
      this.selectedMaterialIndex = -1;
      this.$store.commit("setShowMaterialsModal", false);
    },
    /**
     * close a page
     */
    closePage: function () {
      if (this.showColorLibraryEditor) {
        this.showColorLibraryEditor = false;
      } else if (this.showColorEditor) {
        this.showColorEditor = false;
        this.showColorLibraryEditor = true;
      } else {
        //if we are on the details screen
        if (this.selectedMaterialIndex >= 0) {
          //move back to list screen, don't close modal
          this.selectedMaterialIndex = -1;
        } else {
          //otherwise we are on the list screen, close modal
          this.$store.commit("setShowMaterialsModal", false);
        }
      }
    },
    /**
     * set the selected material index
     * @param {number} materialIndex - the array index of the material to edit
     */
    editMaterial: function (materialIndex: number) {
      this.selectedMaterialIndex = materialIndex;
    },
    /**
     * show the color picker to edit the color
     * @param {Event} e - the event that triggered this call
     */
    editColorLibrary: function (index: number) {
      this.selectedColorLibraryIndex = index;
      this.showColorLibraryEditor = true;
    },
    /**
     * create a new color library
     */
    createColorLibrary: function () {
      this.$store.commit(
        "createMaterialColorLibrary",
        this.selectedMaterialIndex
      );
      this.selectedColorLibraryIndex =
        this.$store.getters.materials[this.selectedMaterialIndex].libraries
          .length - 1;
      this.showColorLibraryEditor = true;
    },
    /**
     * remove a color library
     * @param {number} index - the index of the library to remove
     */
    removeColorLibrary: function (index: number) {
      this.$store.commit("removeMaterialLibraryByIndex", {
        materialIndex: this.selectedMaterialIndex,
        libraryIndex: index,
      });
    },
    addColorLibrary: function (colorLibrary: string) {
      const params = {
        materialIndex: this.selectedMaterialIndex,
        library: colorLibrary,
      };
      this.$store.commit("addMaterialColorLibrary", params);
    },
  },
  computed: {
    /**
     * getter/setter for id
     */
    id: {
      get(): string {
        return this.$store.getters.materials[this.selectedMaterialIndex].id;
      },
      set(value: string) {
        this.$store.commit("updateMaterialIdByIndex", {
          value: value,
          index: this.selectedMaterialIndex,
        });
      },
    },
    /**
     * getter/setter for label
     */
    label: {
      get(): string {
        return this.$store.getters.materials[this.selectedMaterialIndex].label;
      },
      set(value: string) {
        this.$store.commit("updateMaterialLabelByIndex", {
          value: value,
          index: this.selectedMaterialIndex,
        });
      },
    },
    /**
     * getter/setter for libraries
     */
    libraries: {
      get(): Array<IColorLibrary> {
        return this.$store.getters.materials[this.selectedMaterialIndex]
          .libraries;
      },
      set(value: Array<IColorLibrary>) {
        this.$store.commit("updateMaterialLibrariesByIndex", {
          value: value,
          index: this.selectedMaterialIndex,
        });
      },
    },
  },
  watch: {
    /**
     * watch showMaterialsModal from the store
     * @param {boolean} newValue - the value of showMaterialsModal from the store
     */
    "$store.state.signDesigner.showMaterialsModal": function (newValue) {
      //   const this = this;
      this.showModal = newValue;
    },
  },
});
