















import Vue from "vue";
import { hexString } from "../../utilities";

export default Vue.extend({
  methods: {
    getHexString: function (hexCode: string): string {
      return hexString(hexCode);
    },
    getTextColor: function (hexColor: string) {
      if (hexColor.substr(0, 1) === "#") {
        hexColor = hexColor.slice(1);
      }
      const r = parseInt(hexColor.slice(0, 2), 16);
      const g = parseInt(hexColor.slice(2, 4), 16);
      const b = parseInt(hexColor.slice(4, 6), 16);
      if (0.213 * r + 0.715 * g + 0.072 * b > 127) {
        return "000000";
      } else {
        return "FFFFFF";
      }
    },
  },
});
