


































































































































import Vue from "vue";
import { PropType } from "vue";

import { IKanbanSignType, IKanbanSign } from "../types";
import { IOrderDetailSign } from "@/features/Orders/types";

export default Vue.extend({
  components: {},
  data: function () {
    return {
      gridSize: 2,
      showModal: false,
      selectedItems: [] as Array<IOrderDetailSign>,
      selectedSignId: "",
    };
  },
  props: {
    signType: {
      type: Object as PropType<IKanbanSignType>,
    },
    columnIndex: {
      type: Number,
    },
    sectionIndex: {
      type: Number,
      default: -1,
    },
    stateId: {
      type: String,
    },
    projectName: {
      type: String,
      required: true,
    },
    showReviewStates: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    expandSigntype: function () {
      this.$emit(
        "expand",
        this.columnIndex,
        this.signType.id,
        this.sectionIndex
      );
    },
    checkArtworkDownloaded: function () {
      if (window.onload) {
        this.$store.commit("setShowLoadingSpinner", false);
      } else {
        setTimeout(() => {
          this.checkArtworkDownloaded();
        }, 1000);
      }
    },
    moveAllSigns: function (signTypeId: string) {
      this.$emit(
        "moveAllSignsForSignType",
        this.columnIndex,
        signTypeId,
        this.sectionIndex
      );
    },
    downloadSigns: async function (signTypeId: string) {
      this.$store.commit("setShowLoadingSpinner", true);

      try {
        // Make backend request to prepare the file
        const response = await fetch(
          `/state/download_signs_artworks/?sign_type=${signTypeId}&state=${this.stateId}/`
        );
        if (!response.ok) {
          throw new Error("Failed to prepare file");
        }

        // Read the response as Blob
        const blob = await response.blob();

        // Hide loading spinner
        this.$store.commit("setShowLoadingSpinner", false);

        // Trigger file download
        this.downloadFile(blob);
      } catch (error) {
        // Handle error
        console.error("Error preparing file:", error);
        this.$store.commit("setShowLoadingSpinner", false);
      }
    },
    downloadFile: function (blob: Blob) {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const fileName =
        this.projectName.split(" ").join("_") +
        "_" +
        this.signType.shortCode +
        "_" +
        this.signType.name.split(" ").join("_") +
        "_" +
        new Date().toISOString().substring(0, 10) +
        ".zip";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
    dragStart: function (event: DragEvent, sign: IKanbanSign) {
      const dataTransfer = event.dataTransfer;
      // Set the data to the value of the card which is gotten from props.
      // dataTransfer.setData(CardDataType, this.card.value);
      if (dataTransfer) {
        dataTransfer.setData("whatAreWeDragging", "sign");
        dataTransfer.setData("columnIndex", this.columnIndex.toString());
        dataTransfer.setData("sign", JSON.stringify(sign));
        dataTransfer.setData(
          "signtypeexpanded",
          this.signType.isExpanded.toString()
        );
      }
    },
    showSignDetails: function (
      items: Array<IOrderDetailSign>,
      selectedSignId: string
    ) {
      this.$emit("click", {
        items: items,
        signId: selectedSignId,
        showReviewStates: this.showReviewStates,
      });
    },
    getTextColor: function (hexColor: string) {
      if (hexColor.substr(0, 1) === "#") {
        hexColor = hexColor.slice(1);
      }
      const r = parseInt(hexColor.slice(0, 2), 16);
      const g = parseInt(hexColor.slice(2, 4), 16);
      const b = parseInt(hexColor.slice(4, 6), 16);
      if (0.213 * r + 0.715 * g + 0.072 * b > 127) {
        return "#000000";
      } else {
        return "#FFFFFF";
      }
    },
    /**
     * return a portion of an array
     * @param {Array<IKanbanSign>} array - the full array
     * @param {number} size - the number of elements per "chunk"
     * @returns {Array} an array of items that is "size" elements long
     */
    chunkSignsArray: function (
      array: Array<IKanbanSign>,
      size: number
    ): Array<Array<IKanbanSign>> {
      let result = [];
      let sortedArray = Array.from(array);
      sortedArray.sort((a, b) => {
        return Number(a.id) - Number(b.id);
      });

      for (let i = 0; i < sortedArray.length; i += size) {
        let chunk = sortedArray.slice(i, i + size);
        result.push(chunk);
      }
      return result;
    },
    calculateSignCount: function (): number {
      let totalSigns = 0;
      this.signType.signs.forEach((sign) => {
        totalSigns += sign.quantity;
      });
      return totalSigns;
    },
  },
  computed: {},
  watch: {},
});
