























































































































import { iSignChild } from "../../types";
import Vue from "vue";
import Snap from "snapsvg-cjs-ts";

//import CollapsibleSection from "./CollapsibleSection.vue";
import SignTree from "../SignTree.vue";
import SignTypesList from "../SignTypesList.vue";
import { removeSelectionHandles } from "../../utilities";

export default Vue.extend({
  name: "layers-panel",
  components: { SignTree, SignTypesList },
  props: {},
  data: function () {
    return {};
  },
  methods: {
    /**
     * Switch the active panel by setting the current sign type uuid to blank
     * if the currentSignTypeUUID is blank then the signTypesList component is displayed
     */
    switchActivePanel: function () {
      //save the current sign type uuid so we know if the user has selected the same sign in the sign types list
      this.$store.commit(
        "setPreviousSignTypeUUID",
        this.$store.getters.currentSignTypeUUID
      );
      this.$store.commit("setCurrentSignTypeUUID", "");
    },
    /**
     * pass the element visisbility toggled event up the tree
     * @param {iSignChild} item - the item on which the eyeball icon was clicked
     */
    elementVisibilityToggled: function (item: iSignChild) {
      this.$emit("elementVisibilityToggled", item);
    },
    /**
     * pass the treeItemClicked event up the tree
     * @param {Event} event - the click event which was triggered
     */
    treeItemClicked: function (event: Event) {
      console.log(event);
    },
    /**
     * pass the bus event up the tree
     * @param {Event} event - the event that triggered this
     */
    treeBus: function (event: Event) {
      this.$emit("bus", event);
    },
    tabChanged: function (tabNumber: number) {
      removeSelectionHandles(this.$store.getters.canvas);
      /* eslint-disable-next-line */
      (this.$root.$refs.konva as any).deselectAllRepeats();
      //hide all sides
      this.$store.getters.canvas
        .selectAll("[id ^= 'side']")
        .forEach((el: Snap.Element) => {
          el.attr({
            visibility: "hidden",
          });
        });
      // show the current side
      this.$store.getters.canvas
        .selectAll("#side_" + (tabNumber + 1))
        .forEach((el: Snap.Element) => {
          el.attr({
            visibility: "visible",
          });
        });

      this.activeTab = tabNumber;
      this.$store.commit("setCurrentSide", tabNumber);
    },
  },
  computed: {
    activeTab: {
      get: function () {
        return this.$store.getters.sidesTab;
      },
      set: function (value) {
        this.$store.commit("setSidesTab", value);
      },
    },
  },
});
