






import { Component, Vue } from "vue-property-decorator";

import MapViewLayout from "@/components/layouts/MapViewLayout.vue";

import PathNotFound from "./PathNotFound.vue";

@Component({
  components: {
    MapViewLayout,
    PathNotFound,
  },
})
export default class PathNotFound_MapView extends Vue {}
