













































































































import Vue from "vue";
// import VueSelect from "vue-select";

import { IField, iSignData, ITranslationLanguage } from "../../types";
// import { uuidv4 } from "../../utilities";
// import matthews from "./assets/matthews.json";

export default Vue.extend({
  components: {},
  data: function () {
    return {
      showModal: false,
      fieldName: "",
      fieldGroup: "message",
      details: "",
      fieldType: "char",
      isGlobal: false,
      isInherited: false,
      translationLanguages: [],
      signData: {} as iSignData,
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showAddFieldModal;
  },
  methods: {
    /**
     * save changes
     */
    saveChanges: function () {
      // const users: Array<string> = [];
      // users.push(this.$store.getters.user.email);
      const languages = this.translationLanguages.map(
        (language: ITranslationLanguage) => {
          return language.id;
        }
      );

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
            mutation addField{
              mutateField(input: {
                name: "${this.fieldName}",
                group: "${this.fieldGroup}",
                details: "${this.details}",
                fieldType: "${this.fieldType}",
                isGlobal: ${this.isGlobal},
                isInheritable: ${this.isInherited},
                languagesToTranslate: [${languages}],
                project: ${this.$store.getters.organizationId}})
                {
                  attribute {
                    id: contentObjectId
                    uuid
                    name
                  }
                }
              }
            `,
        }),
      })
        .then((result) => result.json())
        .then((data) => {
          const field: IField = {
            id: data.data.mutateField.attribute.id,
            isSelected: false,
            isUsed: false,
            name: this.fieldName,
            type: this.fieldType,
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            capHeight: 0,
            leading: 0,
            lines: 1,
            letterSpacing: "0",
            horizontalAlignment: "left",
            verticalAlignment: "top",
            hasPlaceholderImage: false,
            placeholderImageUrl: "",
            belongsToRepeatId: null,
            elementIds: [],
          };
          if (this.$store.getters.trackAddedField) {
            field.elementIds = this.$store.getters.selectedElementIds;
          }
          this.$store.commit("addField", field);
          if (this.$store.getters.trackAddedField) {
            this.$store.commit(
              "setAddedFieldId",
              data.data.mutateField.attribute.id
            );
          }
          //close the window
          this.$store.commit("setShowAddFieldModal", false);
        });
      this.reset();
    },
    /**
     * close the modal
     * @param {Event} e - the event that triggered the close
     */
    closeModal: function (e: Event) {
      e.preventDefault();
      e.stopPropagation();
      if (this.$store.getters.trackAddedField) {
        this.$store.commit("setAddedFieldId", "");
      }
      this.$store.commit("setShowAddFieldModal", false);
      this.reset();
    },
    /**
     * reset the modal fields
     */
    reset: function () {
      this.fieldName = "";
      this.fieldGroup = "message";
      this.details = "";
      this.fieldType = "char";
      this.isGlobal = false;
      this.isInherited = false;
    },
  },
  computed: {},
  watch: {
    /**
     * watch showAddFieldModal from the store
     * @param {boolean} newValue - the value of showAddFieldModal from the store
     */
    "$store.state.signDesigner.showAddFieldModal": function (newValue) {
      //   const this = this;
      this.showModal = newValue;
    },
  },
});
