import Vue from "vue";
import Vuex from "vuex";

import session, { SessionState } from "./modules/session";
import signDesigner, {
  SignDesignerState,
} from "../features/SignDesigner/store";
import companySettings, {
  CompanySettingsState,
} from "@/features/CompanySettings/store";

import kanban from "../features/BuilderKanban/store";

import state from "../features/SmartStates/store";
import order, { OrderState } from "../features/Orders/store";

Vue.use(Vuex);

export interface RootState {
  session: SessionState;
  signDesigner: SignDesignerState;
  companySettings: CompanySettingsState;
  order: OrderState;
}

export default new Vuex.Store<RootState>({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    session,
    signDesigner,
    companySettings,
    kanban,
    state,
    order,
  },
  // actions: {},
  // getters: {}
  // actions: {}
});

/*
  component might have
    mapState({localName: 'stateName' | state=>state.stateName })
    mapGetters({localName: 'getterName',...})
*/
