





























import Vue from "vue";
import { PropType } from "vue";

import { IDashboardState } from "../types";

export default Vue.extend({
  props: {
    state: Object as PropType<IDashboardState>,
  },
  computed: {
    acceptedPercentage: function (): number {
      const reviewCount = this.state.signs.filter((sign) => {
        return sign.reviewState === "R";
      }).length;

      const approvedCount = this.state.signs.filter((sign) => {
        return sign.reviewState === "A";
      }).length;

      const rejectedCount = this.state.signs.filter((sign) => {
        return sign.reviewState === "R";
      }).length;

      const total = reviewCount + approvedCount + rejectedCount;

      if (total === 0) {
        return 0;
      } else {
        return (100 * approvedCount) / total;
      }
    },
    rejectedPercentage: function (): number {
      const reviewCount = this.state.signs.filter((sign) => {
        return sign.reviewState === "R";
      }).length;

      const approvedCount = this.state.signs.filter((sign) => {
        return sign.reviewState === "A";
      }).length;

      const rejectedCount = this.state.signs.filter((sign) => {
        return sign.reviewState === "R";
      }).length;

      const total = reviewCount + approvedCount + rejectedCount;

      if (total === 0) {
        return 0;
      } else {
        return (100 * rejectedCount) / total + this.acceptedPercentage;
      }
    },
  },
});
