













































import Vue from "vue";
import { SAFonts } from "@/features/SignDesigner/include/SAFonts";

export default Vue.extend({
  components: {},
  props: {},
  data: function () {
    return {
      showModal: false,
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showMissingFontsModal;
  },
  methods: {
    /**
     * yes clicked
     */
    okClicked: function () {
      this.$store.commit("setShowMissingFontsModal", false);
    },
    /**
     * close the modal
     * @param {Event} e - the event that triggered the close
     */
    noClicked: function (e: Event) {
      e.preventDefault();
      e.stopPropagation();
      this.$store.commit("setShowMissingFontsModal", false);
    },
  },
  computed: {
    unusedFonts: function () {
      return SAFonts.getInstance().fontsNotLoaded;
    },
  },
  watch: {
    /**
     * when showConfirmModal from the store changes
     * @param {boolean} newValue - the value of the showConfirmModal from the store
     */
    "$store.state.signDesigner.showMissingFontsModal": function (
      newValue: boolean
    ) {
      this.showModal = newValue;
    },
  },
});
