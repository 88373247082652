import Snap from "snapsvg-cjs-ts";
import { SignTreeData } from "../types";

export class TreeDataFactory {
  public static dataNode(
    tree: SignTreeData,
    element: Snap.Element
  ): SignTreeData {
    return {
      id: element.attr("id"),
      name: element.attr("id"),
      type: element.type,
      textContent: element.attr("id")
        ? element.attr("id")
        : "<" + element.type + ">",
      isSelected: false,
      isTargetted: false,
      isOrderable: false,
      isOpen: false,
      isNoFillNoStroke: false,
      isVisible: true,
      isActive: true,
      saDataId: element.attr("id"),
      parent: tree.saDataId,
      children: [],
    };
  }

  public static textNode(
    tree: SignTreeData,
    element: Snap.Element
  ): SignTreeData {
    const retval = TreeDataFactory.dataNode(tree, element);
    retval.textContent = element.node.textContent
      ? element.node.textContent
      : "";
    return retval;
  }
}
