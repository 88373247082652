var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(this.$store.getters.currentSignTypeUUID !== '')?_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticStyle:{"display":"flex","padding":"5px","align-items":"center","border":"1px solid #cccccc"}},[_c('span',{staticStyle:{"margin-right":"5px","cursor":"pointer"},on:{"click":_vm.switchActivePanel}},[_c('i',{staticClass:"fa fa-chevron-left"})]),_c('span',{staticStyle:{"flex-grow":"1","font-weight":"bold"}},[(_vm.$store.getters.signData.shortCode)?_c('span',{style:({
            backgroundColor: '#' + _vm.$store.getters.signData.hexColor,
            color: '#ffffff',
            paddingLeft: '3px',
            paddingRight: '3px',
            marginRight: '5px',
            fontWeight: 'normal',
          })},[_vm._v(_vm._s(_vm.$store.getters.signData.shortCode))]):_vm._e(),_vm._v(" "+_vm._s(_vm.$store.getters.signData.name)+" ")])]),_vm._m(0),_c('marketplace-section'),(_vm.featureAccessRepeats)?_c('repeat-section'):_vm._e(),_c('alignment-section'),(_vm.featureAccess('multi-sided signs'))?_c('collapsible-section',{attrs:{"title":'Side Order',"showPlusSign":true,"initiallyOpen":true}},[_c('sides-section')],1):_vm._e(),_c('collapsible-section',{attrs:{"title":'Visuals',"showPlusSign":false,"initiallyOpen":true}},[_c('visual-section')],1),_c('collapsible-section',{attrs:{"title":'Colors',"showPlusSign":true,"initiallyOpen":true},on:{"plusClicked":_vm.showAddColorsModal}},[_c('color-section')],1)],1):_vm._e(),(_vm.$store.getters.currentSignTypeUUID === '')?_c('div',[_c('sign-types-list')],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel-header",staticStyle:{"display":"flex","align-items":"center","border-bottom":"1px solid #cccccc"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","width":"100%"}},[_c('span',{staticClass:"panel-header-section-title"},[_vm._v("ASSETS")])])])}]

export { render, staticRenderFns }