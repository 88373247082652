





















































































































































import Vue from "vue";
import VueSelect from "vue-select";

import { IProcess, IMaterial } from "../../types";
import { uuidv4 } from "../../utilities";

export default Vue.extend({
  components: { VueSelect },
  data: function () {
    return {
      showModal: false,
      selectedProcessIndex: -1,
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showProcessesModal;
  },
  methods: {
    /**
     * add a process
     * called when the user clicks the Add Process button
     */
    addProcess: function () {
      const process = {
        id: "",
        uuid: uuidv4(),
        label: "New Process",
        description: "",
        sourceMaterial: "",
        targetMaterials: [],
        exportLayer: "",
      } as IProcess;
      this.$store.commit("addProcess", process);
      this.selectedProcessIndex = this.$store.getters.processes.length - 1;
    },
    /**
     * delete the selected process
     * called when the trash icon is clicked
     */
    deleteProcess(index: number) {
      this.$store.commit("deleteProcessByIndex", index);
    },
    /**
     * handle the Ok button clicked event
     */
    saveChanges: function () {
      //if we are on the details screen
      if (this.selectedProcessIndex >= 0) {
        //move back to list screen, don't close modal
        this.selectedProcessIndex = -1;
      } else {
        //otherwise we are on the list screen, close modal
        this.$store.commit("setShowProcessesModal", false);
      }
    },
    /**
     * close the entire modal
     */
    closeModal: function () {
      this.selectedProcessIndex = -1;
      this.$store.commit("setShowProcessesModal", false);
    },
    /**
     * close a page
     */
    closePage: function () {
      //if we are on the details screen
      if (this.selectedProcessIndex >= 0) {
        //move back to list screen, don't close modal
        this.selectedProcessIndex = -1;
      } else {
        //otherwise we are on the list screen, close modal
        this.$store.commit("setShowProcessesModal", false);
      }
    },
    /**
     * set the selected process index
     * @param {number} processIndex - the array index of the process to edit
     */
    editProcess: function (processIndex: number) {
      this.selectedProcessIndex = processIndex;
    },
  },
  computed: {
    allMaterials: function (): Array<{ id: string; label: string }> {
      const arr: Array<{ id: string; label: string }> = [];
      this.$store.getters.materials.forEach((material: IMaterial) => {
        arr.push({ id: material.uuid, label: material.label });
      });
      return arr;
    },
    /**
     * getter/setter for id
     */
    id: {
      get(): string {
        return this.$store.getters.processes[this.selectedProcessIndex].id;
      },
      set(value: string) {
        this.$store.commit("updateProcessIdByIndex", {
          value: value,
          index: this.selectedProcessIndex,
        });
      },
    },
    /**
     * getter/setter for label
     */
    label: {
      get(): string {
        return this.$store.getters.processes[this.selectedProcessIndex].label;
      },
      set(value: string) {
        this.$store.commit("updateProcessLabelByIndex", {
          value: value,
          index: this.selectedProcessIndex,
        });
      },
    },
    /**
     * getter/setter for description
     */
    description: {
      get(): string {
        return this.$store.getters.processes[this.selectedProcessIndex]
          .description;
      },
      set(value: string) {
        this.$store.commit("updateProcessDescriptionByIndex", {
          value: value,
          index: this.selectedProcessIndex,
        });
      },
    },
    /**
     * getter/setter for source material
     */
    sourceMaterial: {
      get(): string {
        return this.$store.getters.processes[this.selectedProcessIndex]
          .sourceMaterial;
      },
      set(value: string) {
        this.$store.commit("updateProcessSourceMaterialByIndex", {
          value: value,
          index: this.selectedProcessIndex,
        });
      },
    },
    /**
     * getter/setter for target materials
     */
    targetMaterials: {
      get(): Array<string> {
        return this.$store.getters.processes[this.selectedProcessIndex]
          .targetMaterials;
      },
      set(value: Array<IMaterial>) {
        this.$store.commit("updateProcessTargetMaterialsByIndex", {
          value: value,
          index: this.selectedProcessIndex,
        });
      },
    },
    /**
     * getter/setter for export layer
     */
    exportLayer: {
      get(): string {
        return this.$store.getters.processes[this.selectedProcessIndex]
          .exportLayer;
      },
      set(value: string) {
        this.$store.commit("updateProcessExportLayerByIndex", {
          value: value,
          index: this.selectedProcessIndex,
        });
      },
    },
  },
  watch: {
    /**
     * watch showProcessesModal from the store
     * @param {boolean} newValue - the value of showProcessesModal from the store
     */
    "$store.state.signDesigner.showProcessesModal": function (newValue) {
      //   const this = this;
      this.showModal = newValue;
    },
  },
});
