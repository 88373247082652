


































































































































































































































































































































































import Vue from "vue";
import {
  IOrderDetailSign,
  IOrderDetail,
  IOrderSignType,
  IOrder,
  ORDER_STATUS,
  IOrderSignTypeSign,
} from "../types";
import { toCurrency } from "../utilities";
import signDetailModal from "../modals/signDetailModal.vue";

export default Vue.extend({
  components: { signDetailModal },
  props: {
    order: Object,
    allowQuote: Boolean,
    includeSignsForReviewStates: Array,
    gridSize: Number,
  },
  data: function () {
    return {
      localOrder: {} as IOrder,
      selectedItems: [] as Array<IOrderDetailSign>,
      selectedSignId: "",
      panelHeight: 0,
      isDirty: false,
    };
  },
  mounted: function () {
    // initial resize
    this.fitPanelIntoParentContainer();

    this.localOrder = JSON.parse(JSON.stringify(this.order));

    // add event listener for resize event
    window.addEventListener("resize", this.fitPanelIntoParentContainer);
  },
  methods: {
    selectText: function (event: FocusEvent) {
      (event.target as HTMLInputElement).select();
    },
    actualPriceUpdated: function (price: number, signTypeId: string) {
      this.isDirty = true;
      this.$store.commit("updateSignTypeSignPrice", {
        price: price,
        signTypeId: signTypeId,
      });
      this.$emit("fieldChanged");
    },
    installPriceUpdated: function (price: number, signTypeId: string) {
      this.isDirty = true;
      this.$store.commit("updateSignTypeInstallPrice", {
        price: price,
        signTypeId: signTypeId,
      });
      this.$emit("fieldChanged");
    },
    showOrderFooter: function () {
      // don't show footer if we are in DRAFT status
      if (this.$store.getters.order.status === ORDER_STATUS.DRAFT) {
        return false;
      }
      // don't show the footer if we are in QUOTING status and the user manager
      if (
        this.$store.getters.order.status === ORDER_STATUS.QUOTING &&
        this.$store.getters.order.manager.id ===
          this.$store.state.session.userId
      ) {
        return false;
      }
      return true;
    },
    panelBackground: function (orderItemId: number) {
      const el = document.getElementById("row_" + orderItemId);
      if (el) {
        return window.getComputedStyle(el, null)["backgroundColor"];
      } else {
        return "#fff";
      }
    },
    /**
     * determines if pricing should be shown to the user
     * @returns boolean - true if pricing should be displayed
     */
    showPricing: function () {
      // Don't show pricing if:
      //    order is isn't in QUOTING status
      //    order is in QUOTING status and the user isn't the builder or a super user
      if (this.$store.getters.order.status !== ORDER_STATUS.QUOTING) {
        return false;
      }

      if (this.$store.getters.order.status === ORDER_STATUS.QUOTING) {
        if (this.$store.state.session.isSuperUser) {
          return true;
        }
        if (!this.$store.getters.order.userIsBuilder) {
          return false;
        }
      }

      return true;
    },
    /**
     * get a color for text that is a good contrast for the hexColor provided.
     * @param hexColor - the background color
     * @returns string - a hex code string - either black or white
     */
    getTextColor: function (hexColor: string) {
      if (hexColor.substr(0, 1) === "#") {
        hexColor = hexColor.slice(1);
      }
      const r = parseInt(hexColor.slice(0, 2), 16);
      const g = parseInt(hexColor.slice(2, 4), 16);
      const b = parseInt(hexColor.slice(4, 6), 16);
      if (0.213 * r + 0.715 * g + 0.072 * b > 127) {
        return "#000000";
      } else {
        return "#FFFFFF";
      }
    },
    includeSignTypes: function (): Array<IOrderSignType> {
      return this.order.signTypes;
    },
    toggleOrderExpanded: function (itemId: string) {
      this.$store.commit("toggleOrderItemExpanded", itemId);
    },
    toggleReviewState: function (sign: IOrderSignTypeSign) {
      if (sign.reviewState === "A") {
        sign.reviewState = "R";
        const data = { signId: sign.id, reviewState: "R" };
        this.$store.commit("updateReviewStateForSignId", data);
        this.$emit("signChanged", sign);
      } else {
        sign.reviewState = "A";
        const data = { signId: sign.id, reviewState: "A" };
        this.$store.commit("updateReviewStateForSignId", data);
        this.$emit("signChanged", sign);
      }
    },
    signsInReviewState: function (
      signs: Array<{
        id: string;
        quantity: number;
        reviewState: string;
        signId: string;
      }>
    ) {
      const IncludeBuild = signs.filter((sign) => {
        return this.includeSignsForReviewStates.includes(sign.reviewState);
      });
      return IncludeBuild;
    },
    fitPanelIntoParentContainer: function () {
      this.panelHeight = window.innerHeight - 616;

      if (this.panelHeight < 100) {
        this.panelHeight = 100;
      }
    },
    calculateQuantity: function (orderItem: IOrderSignType): number {
      let total = 0;
      orderItem.signs.forEach((sign) => {
        // only include Approved signs in the total calculation
        if (sign.reviewState === "A") {
          total = total + sign.quantity;
        }
      });
      return total;
    },
    toggleDetailField: function (orderItem: IOrderDetail) {
      orderItem.isDetailExpanded = !orderItem.isDetailExpanded;
    },
    convertCurrency: function (value: number, currencyCode: string) {
      return toCurrency(value, currencyCode);
    },
    /**
     * return a portion of an array
     * @param {Array<iCatalog>} array - the full array
     * @param {number} size - the number of elements per "chunk"
     * @returns {Array} an array of items that is "size" elements long
     */
    chunkSignsArray: function (array: Array<IOrderDetailSign>, size: number) {
      let result = [];
      for (let i = 0; i < array.length; i += size) {
        let chunk = array.slice(i, i + size);
        result.push(chunk);
      }
      return result;
    },
    showDetails: function (
      items: Array<IOrderDetailSign>,
      selectedSignId: string
    ) {
      this.selectedItems = items;
      this.selectedSignId = selectedSignId;

      this.$store.commit("setShowSignDetailsModal", true);
    },
  },
  computed: {
    ORDER_STATUS: function () {
      return ORDER_STATUS;
    },
    signTypes: function (): IOrderSignType[] {
      // Get the signTypes from the store
      const signTypes = this.$store.getters.order.signTypes;

      // Make a deep copy to avoid mutating the original array in the store
      const sortedSignTypes = JSON.parse(JSON.stringify(signTypes));

      sortedSignTypes.sort((a: IOrderSignType, b: IOrderSignType) => {
        return a.globalOrderIdVal.localeCompare(b.globalOrderIdVal);
      });
      return sortedSignTypes;
    },
    userIsBuilder: function (): boolean {
      if (this.$store.getters.order) {
        return this.$store.getters.order.userIsBuilder;
      } else {
        return false;
      }
    },
    itemTotal: function (): number {
      let value = 0;
      if (this.$store.getters.order) {
        value =
          this.itemSubtotal +
          Number(this.$store.getters.order.shipping) +
          Number(this.$store.getters.order.taxes.amount);
      }
      return value;
    },

    itemSubtotal: function (): number {
      let grandTotal = 0;

      if (this.$store.getters.order?.signTypes) {
        this.$store.getters.order.signTypes.forEach(
          (signType: IOrderSignType) => {
            // the HTMLInputElement is returning a string so when we do an addition the string is concatenated giving us strange results
            // here we are checking the typeof the var and doing a parseFloat if it is a string.  We can't just do a parseFloat because
            // typescript won't let us, it throws a:
            //             "Argument of type 'number' is not assignable to parameter of type 'string'.Vetur(2345)"
            // error
            let total = 0;
            if (typeof signType.actual_price === "number") {
              total += signType.actual_price;
            } else {
              total += parseFloat(signType.actual_price);
            }
            // doing the same as above with the install price field
            if (typeof signType.install_price === "number") {
              total += signType.install_price;
            } else {
              total += parseFloat(signType.install_price);
            }

            total = total * signType.quantity;
            grandTotal += total;
          }
        );
      }
      return grandTotal;
    },
    shipping: {
      get() {
        return this.$store.getters.order.shipping;
      },
      set(value: number) {
        this.isDirty = true;
        this.$store.commit("setOrderShipping", value);
        this.$emit("fieldChanged");
      },
    },
  },
  watch: {
    localOrder: {
      handler: function () {
        // this.$store.commit("setOrder", newVal);
      },
      deep: true,
    },
    order: function () {
      this.localOrder = JSON.parse(JSON.stringify(this.order));
    },
    isDirty: function () {
      this.$store.commit("updateOrderTaxName", "");
      this.$store.commit("updateOrderTaxAmount", 0);
      this.isDirty = false;
    },
  },
});
