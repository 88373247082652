




















































import { Component, Vue, Prop } from "vue-property-decorator";
import $ from "jquery";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as _bootstrap from "bootstrap";

import { mountAsModalIdentifier } from "@/mounters/mountAsModal";

export const modalLayoutIdentifier = {};

/**
 * Template for a Modal
 *
 * @remarks
 * To open a modal, emit an "open" event (see below). Do not use v-if to trigger visibility as this will not animate correctly. Note that the mountAsModal function automatically opens the modal, and destroys its contents when the modal is closed.
 *
 * The host component control the modal be emitting events. For example:
 *   - `$refs.modal.$emit('open')`: show the modal (only necessary when not using mountAsModal)
 *   - `$refs.modal.$emit('close')`: to begin the process of closing/hiding the modal
 *   - `$refs.modal.$emit('scrollToTop')`: to scroll the modal to the top
 *
 * The component also emits events back to the host:
 *   - `@closing`: triggered when the user presses `esc`, clicks the "x", clicks the the backdrop, or when `close` (above) is emitted.
 *     NOTE: cancel the closing process with `event.preventDefault(); event.stopPropagation();`
 *   - `@closed`: triggered when the closing animation has completed
 *     NOTE: mountAsModal automatically picks up on this event and destroys the component
 */
@Component
export default class ModalLayout extends Vue {
  /**
   * String of the title for the modal, not used if "header" slot is provided
   */
  @Prop({ type: String, default: "" })
  readonly title!: string;

  /**
   * Boolean indicates if a loader animation should cover the modal
   */
  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean;

  /**
   * Boolean to enable or disable the close button (clicking the backdrop or pressing ESC still triggers a cancellable  `closing` event)
   */
  @Prop({ type: Boolean, default: true })
  readonly showCloseButton!: boolean;

  /**
   * String specifies the width of the modal: "small", "medium" (default), "large or "custom"
   */
  @Prop({
    type: String,
    default: "medium",
    validator: (size) =>
      ["small", "medium", "large", "custom"].indexOf(size) !== -1,
  })
  readonly size!: string;

  /**
   * Number specifies the height of the modal in pixels. Used in conjunction with the custom size
   */
  @Prop({
    type: Number,
  })
  readonly height!: number;

  /**
   * Number specifies the width of the modal in pixels. Used in conjunction with the custom size
   */
  @Prop({
    type: Number,
  })
  readonly width!: number;

  /**
   * Number specifies the padding of the modal content in pixels.
   */
  @Prop({
    type: Number,
    default: null,
  })
  readonly padding!: number;

  $refs!: {
    modal: HTMLFormElement;
  };

  /** An object used to identify another ModalLayout component */
  readonly modalLayoutIdentifier = modalLayoutIdentifier;

  /** Identify if the component has finished loading (to avoid emitting configuration-generated events) */
  isInitialized = false;

  /**
   * Called when component is initialized
   */
  mounted(): void {
    this.$on("open", () => {
      $(this.$refs.modal).modal("show");
    });

    this.$on("close", () => {
      $(this.$refs.modal).modal("hide");
    });

    this.$on("scrollToTop", () => {
      this.$refs.modal.scrollTop = 0;
    });

    $(this.$el)
      // allow component to listen for (and cancel) a Modal close event using `this.$on("closing", ...)`
      .on("hide.bs.modal", (event) => {
        if (this.isInitialized && event.target === this.$refs.modal) {
          this.$emit("closing", event);
        }
      })
      .on("hidden.bs.modal", (event) => {
        if (this.isInitialized && event.target === this.$refs.modal) {
          this.$emit("closed", event);
          if (this.isManaged) {
            this.$root.$emit("closed");
          }
        }
      })
      .modal(this.isManaged ? "show" : "hide");

    this.isInitialized = true;
  }

  /**
   * User clicked the close button
   */
  clickCloseButton(): void {
    this.$emit("close");
  }

  /**
   * Determine if this ModalLayout needs to report back to a mountAsModal component
   */
  get isManaged(): boolean {
    // it's not managed if this.$root isn't a mountAsModal component
    if (
      (this.$root as { mountAsModalIdentifier?: unknown })
        .mountAsModalIdentifier !== mountAsModalIdentifier
    ) {
      return false;
    }

    // it's not managed if an ancestor is a ModalLayout component
    let thisModal = this.$parent;
    while (thisModal) {
      if (
        (thisModal as { modalLayoutIdentifier?: unknown })
          .modalLayoutIdentifier === modalLayoutIdentifier
      ) {
        return false;
      }
      thisModal = thisModal.$parent;
    }

    // then it must be managed
    return true;
  }
}
