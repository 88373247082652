
























import Vue from "vue";
import $ from "jquery";

import { asDate } from "@/filters/common";
import { IInvoice } from "@/features/CompanySettings/types";

import PricingBreakdownModal from "../modals/PricingBreakdownModal.vue";
import ViewLatestInvoiceModal from "../modals/ViewLatestInvoiceModal.vue";
import ViewInvoiceHistoryModal from "../modals/ViewInvoiceHistoryModal.vue";

export default Vue.extend({
  filters: { asDate },
  components: {
    PricingBreakdownModal,
    ViewLatestInvoiceModal,
    ViewInvoiceHistoryModal,
  },
  data: function () {
    return {
      companyId: this.$route.params.id,
      latestInvoice: {} as IInvoice,
    };
  },
  methods: {
    getLatestInvoice(companyId: string) {
      fetch("/graphql/", {
        method: "POST",
        body: JSON.stringify({
          query: `query get_latest_invoice{
            company(id: ${companyId}) {
              subscriptions {
                edges {
                  node {
                    latestInvoice {
                      invoiceUrl,
                      currency,
                      number,
                      amountPaid,
                      paidAt: issueDate,
                      status,
                      paymentMethod {
                        brand,
                        last4
                      }
                    }
                  }
                }
              }
            }
          }`,
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then((result) => result.json())
        .then((data) => {
          const invoiceData =
            data.data.company.subscriptions.edges[0].node.latestInvoice;
          let cardType = "";
          let last4 = "";
          if (invoiceData.paymentMethod) {
            cardType = invoiceData.paymentMethod.brand;
            last4 = invoiceData.paymentMethod.last4;
          }
          const localLatestInvoice = {
            invoiceUrl: invoiceData.invoiceUrl,
            currency: invoiceData.currency,
            invoiceNumber: invoiceData.number,
            status: invoiceData.status,
            amountPaid: invoiceData.amountPaid,
            paidAt: new Date(invoiceData.paidAt),
            paymentMethod: {
              cardType: cardType,
              last4: last4,
            },
          } as IInvoice;
          this.latestInvoice = localLatestInvoice;
        });
    },
    getInvoices(companyId: string) {
      fetch("/graphql/", {
        method: "POST",
        body: JSON.stringify({
          query: `query get_invoices{
            company(id: ${companyId}) {
              subscriptions {
                edges {
                  node {
                    invoices {
                      invoiceUrl,
                      currency,
                      invoiceNumber: number,
                      status,
                      amountPaid,
                      issueDate
                    }
                  }
                }
              }
            }
          }`,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((result) => result.json())
        .then((data) => {
          const localInvoices = [] as IInvoice[];
          data.data.company.subscriptions.edges[0].node.invoices.forEach(
            (invoice: IInvoice) =>
              localInvoices.push({
                invoiceUrl: invoice.invoiceUrl,
                currency: invoice.currency,
                invoiceNumber: invoice.invoiceNumber,
                status: invoice.status,
                amountPaid: invoice.amountPaid,
                issueDate: new Date(invoice.issueDate),
              })
          );
          this.$store.commit("setInvoices", localInvoices);
        });
    },
    viewBreakdown: function () {
      $("#pricing-breakdown-modal").modal({ show: true });
    },
    viewLatest: function () {
      this.getLatestInvoice(this.companyId);
      $("#view-latest-invoice-modal").modal({ show: true });
    },
    viewHistory: function () {
      this.getInvoices(this.companyId);
      $("#view-invoice-history-modal").modal({ show: true });
    },
  },
  computed: {},
});
