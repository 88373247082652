










































//import { iSignChild } from "../../types";
import Vue from "vue";
import { IProductFamily, IProductCategory, IProductType } from "../../types";
import CollapsibleSection from "../CollapsibleSection.vue";
import PriceSection from "../panelsections/PriceSection.vue";
import ProductCategorySection from "../panelsections/ProductCategorySection.vue";
import ProductFamilySection from "../panelsections/ProductFamilySection.vue";
import SidesSection from "../panelsections/SidesSection.vue";
import SigntypeSection from "../panelsections/SigntypeSection.vue";

export default Vue.extend({
  name: "assets-panel",
  components: {
    CollapsibleSection,
    PriceSection,
    ProductCategorySection,
    ProductFamilySection,
    SidesSection,
    SigntypeSection,
  },
  props: {
    signData: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {};
  },
  methods: {
    familyClicked: function (family: IProductFamily) {
      this.$store.commit("toggleSelectedProductFamilyById", family);
    },
    categoryClicked: function (category: IProductCategory) {
      this.$store.commit("toggleSelectedProductCategoryById", category);
    },
    signtypeClicked: function (type: IProductType) {
      this.$store.commit("toggleSelectedProductTypeById", type);
    },
  },
  computed: {},
  watch: {},
});
