












import Vue from "vue";
import { PropType } from "vue";

export default Vue.extend({
  components: {},
  props: {
    date: {
      type: Date as PropType<Date>,
    },
    dateStyle: {
      type: String,
    },
    stateId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {};
  },
  methods: {},
  computed: {
    formattedDate: {
      get: function (): string {
        if (this.date) {
          return new Date(this.date).toISOString().substring(0, 10);
        } else {
          return "";
        }
      },
      set(value: Date) {
        this.$emit("dateUpdated", this.stateId, value);
      },
    },
  },
});
