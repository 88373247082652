





































































import Vue from "vue";
import $ from "jquery";

import {
  Stripe,
  StripeAddressElement,
  StripeElements,
} from "@stripe/stripe-js";

export default Vue.extend({
  data: function () {
    return {
      showModal: false,
      stripe: null as Stripe | null,
      addressElement: {} as StripeAddressElement,
      success: false,
    };
  },
  mounted: function () {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.closeModal();
      }
    });
  },
  methods: {
    closeModal: function () {
      this.showModal = false;
      this.$store.commit("setShowBillingAddressModal", false);
      $("#billingAddressModal").modal("hide");
    },
    loadAddressElement: async function () {
      if (!this.stripe) {
        if (window.Stripe) {
          if (process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY) {
            this.stripe = window.Stripe(
              process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
            );
          }
        }
      }

      // have to use any type on next line because Stripe's typescript support
      // is missing this type
      const appearance = {
        appearance: {
          theme: "stripe",
          variables: {
            fontFamily:
              "'Suisse', 'open sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
            colorText: "#333333",
            fontSizeBase: "13px",
            fontSizeSm: "13px",
            fontWeightNormal: 500,
          },
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any;

      let elements = {} as StripeElements;

      if (this.stripe) {
        elements = this.stripe.elements(appearance);
      }

      if (elements) {
        // Create and mount the Address Element in billing mode
        this.addressElement = elements.create("address", {
          mode: "billing",
          display: {
            name: "organization",
          },
          defaultValues: {
            name: this.$store.getters.order.billingContact,
            address: {
              line1: this.$store.getters.order.billingStreet1,
              line2: this.$store.getters.order.billingStreet2,
              city: this.$store.getters.order.billingCity,
              state: this.$store.getters.order.billingState,
              postal_code: this.$store.getters.order.billingPostalCode,
              country: this.$store.getters.order.billingCountry,
            },
          },
        });
        this.addressElement.mount("#billing-address-element");
      }
    },
    saveChanges: function () {
      let localOrder = JSON.parse(JSON.stringify(this.$store.getters.order));
      this.addressElement.getValue().then((value) => {
        if (value.complete) {
          localOrder.billingContact = value.value.name;
          localOrder.billingStreet1 = value.value.address.line1;
          if (value.value.address.line2) {
            localOrder.billingStreet2 = value.value.address.line2;
          } else {
            localOrder.billingStreet2 = "";
          }
          localOrder.billingCity = value.value.address.city;
          localOrder.billingState = value.value.address.state;
          localOrder.billingPostalCode = value.value.address.postal_code;
          localOrder.billingCountry = value.value.address.country;

          this.$store.commit("setOrder", localOrder);
          this.$store.commit("setShowBillingAddressModal", false);
        }
      });
    },
  },
  watch: {
    "$store.state.order.showBillingAddressModal": function (newValue) {
      if (newValue) {
        this.showModal = newValue;
        $("#billingAddressModal").modal("show");
        this.loadAddressElement();
      } else {
        this.showModal = newValue;
        $("#billingAddressModal").modal("hide");
      }
    },
  },
});
