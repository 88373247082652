










































































import Vue from "vue";
//eslint-disable-next-line
import { SignTreeData, iSignChild } from '../types'

export default Vue.extend({
  name: "sign-list-tree",
  props: { item: Object, indent: Number },
  data: function () {
    return {
      isOpen: true,
      isDragging: false,
    };
  },
  methods: {
    /**
     * handle the bus event
     * @param {SignTreeData} item - the item that was clicked on
     */
    bus: function (item: SignTreeData) {
      this.$emit("bus", item);
    },
    /**
     * handle the tree item clicked event
     * @param {SignTreeData} item - the item that was clicked on
     */
    treeItemClicked: function (item: SignTreeData) {
      this.$emit("bus", item);
    },
    /**
     * toggle the folder open or closed
     */
    toggle: function () {
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
      }
    },
  },
  computed: {
    /**
     * is the item a folder?
     * @returns {boolean} True if item is a folder (i.e. has children)
     */
    isFolder: function (): boolean {
      if (this.item.children && this.item.children.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {},
});
