


































































import Vue from "vue";
import Snap from "snapsvg-cjs-ts";

import CollapsibleSection from "../CollapsibleSection.vue";

export default Vue.extend({
  components: { CollapsibleSection },
  methods: {
    deleteSide: function () {
      const elNumber = this.$store.getters.currentSide + 1;
      let svgSide = this.$store.getters.canvas.select("#side_" + elNumber);
      svgSide.remove();
      this.$store.commit("removeSide", this.$store.getters.currentSide);
      //set the current side to the first side
      this.$store.commit("setCurrentSide", 0);
      //make the new current side visible
      this.$store.getters.canvas
        .selectAll("#side_" + (this.$store.getters.currentSide + 1))
        .forEach((el: Snap.Element) => {
          el.attr({
            visibility: "visible",
          });
        });
      // renumber the side elements so they are sequential
      this.$store.getters.canvas
        .selectAll("g[id^='side_']")
        .forEach((el: Snap.Element, index: number) => {
          el.attr({ id: "side_" + (index + 1) });
        });
    },
    addSide: function () {
      this.$store.commit("setShowAddSideModal", true);
    },
  },
  computed: {
    numberOfSides: {
      get: function () {
        return this.$store.getters.signData.numberOfSides;
      },
      set: function (value) {
        console.log(value);
      },
    },
    /**
     * should we show the section expend/collapse caret
     * @returns {boolean} True if caret should be displayed
     */
    showCaret: function (): boolean {
      return this.$store.getters.signData.sides.length > 0;
    },
    /**
     * should we show the plus sign
     * @returns {boolean} True if plus sign should be displayed
     */
    showPlusSign: function () {
      return true;
    },
    /**
     * should we show the minus sign
     * @returns {boolean} True if the minus sign should be displayed
     */
    showTrash: function () {
      return false;
    },
    /**
     * should the section be displayed open
     * @returns {boolean} True if section should be displayed open
     */
    openSection: function () {
      return false;
    },
    /**
     * returns current side
     * @returns {number}
     */
    currSide: function (): number {
      return this.$store.getters.currentSide;
    },
  },
});
