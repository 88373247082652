import Snap from "snapsvg-cjs-ts";
import { iSignChild } from "../types";
import { SvgCss } from "./SvgCss";
import {
  makeFloat,
  elementCssName,
  getElementName,
  getChildElementOfType,
} from "./Utils";

export class SignChildFactory {
  /**
   *
   * @param field
   * @param element
   */
  public static fromField(
    field: iSignChild,
    element: Snap.Element
  ): iSignChild | null {
    const cssClass = elementCssName(element);
    const rectElement = getChildElementOfType(element.parent(), "rect");
    const textElement = getChildElementOfType(element.parent(), "text");
    const attributes = getElementName(rectElement);
    const svgCss = SvgCss.getInstance();
    let signChild: iSignChild | null = {
      id: element.attr("id"),
      name: field.name,
      svgId: textElement ? textElement.attr("sa-data-id") : field.name,
      elementType: "",
      //element: element,
      fieldType: field.fieldType,
      content: "",
      x: makeFloat(element.attr("x")),
      y: makeFloat(element.attr("y")),
      width: makeFloat(element.attr("width")),
      height: makeFloat(element.attr("height")),
      alignment: attributes,
      css: "",
      cssClass: "",
      fill: "",
      stroke: "",
      element: element, // save the associated snap element.
      children: [],
      isSelected: false,
      isTargetted: false,
      isVisible: true,
      fontInfo: { fontFamily: "", fontSize: "", fontWeight: "" },
    };

    switch (field.fieldType.toUpperCase()) {
      case "icon_t":
      case "icon":
        break;
      case "color_x":
      case "text":
      case "char_x":
      case "char":
      case "trans":
        {
          if (svgCss) {
            const fill = svgCss.getClassVariableValue(cssClass, "fill");
            const stroke = svgCss.getClassVariableValue(cssClass, "stroke");
            signChild.fill = fill ? fill : "";
            signChild.stroke = stroke ? stroke : "";
          }
        }
        break;
      default:
        signChild = null;
        break;
    }

    return signChild;
  }

  public static fromElement(element: Snap.Element): iSignChild | null {
    const rectElement = getChildElementOfType(element.parent(), "rect");
    const attributes = getElementName(rectElement);
    let elementName = "";
    let displayValue = "";
    try {
      displayValue = element.attr("display");
    } catch {
      displayValue = "";
    }
    const svgCss = SvgCss.getInstance();
    let signChild: iSignChild | null = {
      id: element.attr("id"),
      name: element.attr("id"),
      svgId: element.attr("id"),
      elementType: "",
      //element: element,
      fieldType: "",
      content: "",
      x: makeFloat(element.attr("x")),
      y: makeFloat(element.attr("y")),
      width: makeFloat(element.attr("width")),
      height: makeFloat(element.attr("height")),
      alignment: attributes,
      css: "",
      cssClass: "",
      fill: "",
      stroke: "",
      children: [],
      isSelected: false,
      isTargetted: false,
      isVisible: displayValue.toLowerCase() === "none" ? false : true,
      fontInfo: { fontFamily: "", fontSize: "", fontWeight: "" },
      element: element,
    };
    let cssClass = "";
    switch (element.type) {
      case "g":
        cssClass = "";
        elementName = getElementName(element).trim();
        if (elementName.indexOf("repeat") >= 0) {
          signChild.elementType = "repeat";
          signChild.fieldType = "repeat";
          signChild.fill = "transparent";
          signChild.stroke = "none";
        } else {
          let elementId = elementName;
          if (elementId === "") {
            elementId = "<group>";
          }

          signChild.svgId = element.attr("sa-data-id");
          signChild.id = element.attr("sa-data-id");
          signChild.name = elementId;
          signChild.elementType = "g";
          signChild.fieldType = "g";
          signChild.x = 0;
          signChild.y = 0;
          signChild.width = 0;
          signChild.height = 0;
          signChild.fill = "transparent";
          signChild.stroke = "none";
        }
        break;

      case "rect":
        cssClass = elementCssName(element);
        signChild.svgId = element.attr("sa-data-id");
        signChild.id = element.attr("sa-data-id");
        signChild.name = "<rectangle>";
        signChild.elementType = "rect";
        signChild.fieldType = "rect";
        signChild.alignment = elementName;
        {
          if (svgCss) {
            const fill = svgCss.getClassVariableValue(cssClass, "fill");
            const stroke = svgCss.getClassVariableValue(cssClass, "stroke");
            signChild.fill = fill ? fill : "";
            signChild.stroke = stroke ? stroke : "";
          }
        }
        break;

      case "text":
        cssClass = elementCssName(element);
        signChild.svgId = element.attr("sa-data-id");
        signChild.id = element.attr("sa-data-id");
        signChild.name = element.attr("text");
        signChild.elementType = "text";
        signChild.fieldType = "text";
        signChild.alignment = elementName;
        {
          if (svgCss) {
            const fontFamily = svgCss.getClassVariableValue(
              cssClass,
              "font-family"
            );
            const fontWeight = svgCss.getClassVariableValue(
              cssClass,
              "font-weight"
            );
            const fontSize = svgCss.getClassVariableValue(
              cssClass,
              "font-size"
            );
            signChild.fontInfo.fontFamily = fontFamily ? fontFamily : "";
            signChild.fontInfo.fontWeight = fontWeight ? fontWeight : "";
            signChild.fontInfo.fontSize = fontSize ? fontSize : "";
          }
        }
        break;

      case "polygon":
        cssClass = elementCssName(element);
        signChild.svgId = element.attr("sa-data-id");
        signChild.id = element.attr("sa-data-id");
        signChild.name = "<polygon>";
        signChild.elementType = "polygon";
        signChild.fieldType = "polygon";
        signChild.alignment = elementName;
        break;

      case "polyline":
        cssClass = elementCssName(element);
        signChild.svgId = element.attr("sa-data-id");
        signChild.id = element.attr("sa-data-id");
        signChild.name = "<polyline>";
        signChild.elementType = "polyline";
        signChild.fieldType = "polyline";
        signChild.alignment = elementName;
        break;

      case "image":
        cssClass = elementCssName(element);
        signChild.svgId = element.attr("sa-data-id");
        signChild.id = element.attr("sa-data-id");
        signChild.name = "<image>";
        signChild.elementType = "image";
        signChild.fieldType = "image";
        signChild.alignment = elementName;
        break;

      case "path":
        cssClass = elementCssName(element);
        signChild.svgId = element.attr("sa-data-id");
        signChild.id = element.attr("sa-data-id");
        signChild.name = "<path>";
        signChild.elementType = "path";
        signChild.fieldType = "path";
        signChild.alignment = elementName;
        break;

      case "ellipse":
        cssClass = elementCssName(element);
        signChild.svgId = element.attr("sa-data-id");
        signChild.id = element.attr("sa-data-id");
        signChild.name = "<ellipse>";
        signChild.elementType = "ellipse";
        signChild.fieldType = "ellipse";
        signChild.alignment = elementName;
        break;

      case "circle":
        cssClass = elementCssName(element);
        signChild.svgId = element.attr("sa-data-id");
        signChild.id = element.attr("sa-data-id");
        signChild.name = "<circle>";
        signChild.elementType = "circle";
        signChild.fieldType = "circle";
        signChild.alignment = elementName;
        break;
      default:
        signChild = null;
        break;
    }
    if (cssClass !== "" && signChild && svgCss) {
      const fill = svgCss.getClassVariableValue(cssClass, "fill");
      const stroke = svgCss.getClassVariableValue(cssClass, "stroke");
      signChild.fill = fill ? fill : "";
      signChild.stroke = stroke ? stroke : "";
    }

    return signChild;
  }
}
