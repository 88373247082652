var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$store.getters.currentSignTypeUUID !== '')?_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},[_c('div',{staticStyle:{"display":"flex","padding":"5px","align-items":"center","border":"1px solid #cccccc"}},[_c('span',{staticStyle:{"margin-right":"5px","cursor":"pointer"},on:{"click":_vm.switchActivePanel}},[_c('i',{staticClass:"fa fa-chevron-left"})]),_c('span',{staticStyle:{"flex-grow":"1","font-weight":"600"}},[(_vm.$store.getters.signData.shortCode)?_c('span',{style:({
            backgroundColor: '#' + _vm.$store.getters.signData.hexColor,
            color: '#ffffff',
            paddingLeft: '3px',
            paddingRight: '3px',
            marginRight: '5px',
            fontWeight: 'normal',
          })},[_vm._v(_vm._s(_vm.$store.getters.signData.shortCode))]):_vm._e(),_vm._v(" "+_vm._s(_vm.$store.getters.signData.name)+" ")])]),_vm._m(0),_c('div',{staticClass:"panel-body",staticStyle:{"padding":"5px","border-bottom":"1px solid #cccccc","background-color":"#f5f5f5"}},[_c('div',{staticStyle:{"margin-left":"0px","padding":"5px","font-weight":"500","cursor":"default"}},[(_vm.$store.getters.signData.sides[0].id === '')?_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" To get started with this sign type you can"),_c('br'),_c('button',{staticClass:"btn btn-primary",staticStyle:{"margin-top":"10px","margin-bottom":"10px"},on:{"click":function($event){return _vm.$store.commit('setShowFileModal', true)}}},[_vm._v(" Upload Artwork ")])]):_c('div',_vm._l((_vm.$store.getters.signData.sides[_vm.activeTab].children),function(child){return _c('sign-tree',{key:child.id,attrs:{"item":child,"indent":0},on:{"bus":_vm.treeBus,"treeItemClicked":_vm.treeItemClicked,"elementVisibilityToggled":_vm.elementVisibilityToggled}})}),1)])])]):_vm._e(),(_vm.$store.getters.currentSignTypeUUID === '')?_c('div',[_c('sign-types-list')],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel-header"},[_c('div',{staticStyle:{"display":"flex","align-items":"center","width":"100%"}},[_c('span',{staticClass:"panel-header-section-title"},[_vm._v("LAYERS")])])])}]

export { render, staticRenderFns }