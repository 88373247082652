export function toCurrency(value: number, currencyCode: string): string {
  if (typeof value !== "number") {
    return value;
  }
  // if no currency code passed in default to USD
  if (currencyCode === "" || !currencyCode) {
    currencyCode = "USD";
  }

  const formatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currencyCode.toUpperCase(),
  });
  return formatter.format(value);
}
