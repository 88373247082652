










import Vue from "vue";
import $ from "jquery";

import { IProjectListProject } from "../types";
import Dashboard from "../components/Dashboard.vue";

export default Vue.extend({
  components: { Dashboard },
  data: function () {
    return {
      projects: [] as Array<IProjectListProject>,
      sortColumn: "name",
      sortDirection: "up",
      searchText: "",
      showOrgUserPopover: [] as Array<boolean>,
    };
  },
  mounted: function () {
    // this.fetchProjects();
    $('[data-toggle="popover"]').popover();
  },
});
