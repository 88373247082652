













































































































































































































































































































































































































































































































































































































































































import {
  IAttachment,
  IOrderDetailSign,
  IOrderSignTypeSign,
  ISignMessageField,
  IRepeatingMessageField,
  ORDER_STATUS,
} from "../types";
import Vue from "vue";
import { toCurrency } from "../utilities";

import $ from "jquery";
import { PropType } from "vue";

export default Vue.extend({
  components: {},
  props: {
    signs: {
      type: Array as PropType<Array<IOrderDetailSign>>,
    },
    selectedSignId: String,
    caller: String, // either order or kanban
    showReviewStates: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      showModal: false,
      selectedIndex: 0,
      selectedImageIndex: 0,
      showAttachmentModal: false,
      isDirty: false,
    };
  },
  mounted: function () {
    $("#signDetailsModal").focus();
    const modal = document.getElementById("signDetailsAttachmentModal");
    modal?.addEventListener("click", (e) => {
      if (e.target) {
        if (!(e.target as Element).closest(".modal-content")) {
          this.showAttachmentModal = false;
        }
      }
    });

    // make sure we set the showSignDetailsModal to false when modal is closed.
    // If we don't do this then the user is unable to open the modal a second time
    $("#signDetailsModal").on("hidden.bs.modal", () => {
      this.$store.commit("setShowSignDetailsModal", false);
    });
  },
  methods: {
    readSign: function () {
      const query = JSON.stringify({
        query: `query getSignDetails {
          sign (id:${this.signs[this.selectedIndex].id}) {
            id:contentObjectId
            details
            state {
              needsReviewed
              approved
              rejected
            }
            signMessageFields {
              attribute {
                name
                fieldType
              }
              charValue
              textValue
              dateValue
              colorX {
                name
                color
              }
              icon {
                name
                imageUrl
              }
            }
            repeatingMessageFields: repeatingSignMessageFields {
              field {
                attribute {
                  name
                  fieldType
                  isInheritable
                  isGlobal
                }
                charValue
                textValue
                colorX {
                  name
                  color
                }
                icon {
                  name
                  imageUrl
                }
                dateValue
              }
            }
          }
        }`,
      });

      this.$store.commit("setShowLoadingSpinner", true);
      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          this.signs[this.selectedIndex].state = {
            needsReviewed: result.data.sign.state.needsReviewed,
            approved: result.data.sign.state.approved,
            rejected: result.data.sign.state.rejected,
          };

          this.signs[this.selectedIndex].details = JSON.parse(
            result.data.sign.details
          );
          this.signs[this.selectedIndex].signMessageFields =
            this.mapSignMessageFields(result.data.sign.signMessageFields);
          this.signs[this.selectedIndex].repeatingMessageFields =
            this.mapRepeatingMessageFields(
              result.data.sign.repeatingMessageFields
            );
          this.$store.commit("setShowLoadingSpinner", false);
        });
    },
    setPillColor: function (reviewState: string) {
      switch (reviewState) {
        case "N":
          return "bg-default";
        case "A":
          return "bg-approved";
        case "R":
          return "bg-rejected";
      }
    },
    getReviewStateText: function (sign: IOrderDetailSign) {
      switch (sign.reviewState) {
        case "N":
          return sign.state.needsReviewed;
        case "A":
          return sign.state.approved;
        case "R":
          return sign.state.rejected;
      }
    },
    mapSignMessageFields: function (
      signMessageFields: Array<ISignMessageField>
    ): Array<{ name: string; fieldType: string; value: string }> {
      let result = [] as Array<{
        name: string;
        fieldType: string;
        value: string;
      }>;
      if (signMessageFields) {
        signMessageFields.forEach((message) => {
          let name = message.attribute.name;
          const fieldType = message.attribute.fieldType;

          let value = "";
          switch (message.attribute.fieldType) {
            case "char":
              value = message.charValue;
              break;
            case "char_x":
            case "trans":
            case "text":
              value = message.textValue;
              break;
            case "date":
              value = message.dateValue;
              break;
            case "icon":
            case "icon_t":
            case "icon_x":
              if (message.icon) {
                value = message.icon.imageUrl;
              } else {
                value = "";
              }
              break;
            case "color":
            case "color_t":
            case "color_x":
              name = message.colorX.name;
              value = message.colorX.color;
          }

          result.push({ name: name, fieldType: fieldType, value: value });
        });
      }
      return result;
    },
    mapRepeatingMessageFields: function (
      repeatingMessageFields: Array<IRepeatingMessageField>
    ): Array<{ name: string; fieldType: string; value: string }> {
      let result = [] as Array<{
        name: string;
        fieldType: string;
        value: string;
      }>;
      if (repeatingMessageFields) {
        repeatingMessageFields.forEach((message) => {
          if (message.field) {
            let name = message.field.attribute.name;
            const fieldType = message.field.attribute.fieldType;

            let value = "";
            switch (message.field.attribute.fieldType) {
              case "char":
                value = message.field.charValue;
                break;
              case "char_x":
              case "trans":
              case "text":
                value = message.field.textValue;
                break;
              case "date":
                value = message.field.dateValue;
                break;
              case "icon":
              case "icon_t":
              case "icon_x":
                if (message.field.icon) {
                  value = message.field.icon.imageUrl;
                } else {
                  value = "";
                }
                break;
              case "color":
              case "color_t":
              case "color_x":
                name = message.field.colorX.name;
                value = message.field.colorX.color;
            }

            result.push({ name: name, fieldType: fieldType, value: value });
          }
        });
      }
      return result;
    },

    nextSign: function () {
      this.selectedIndex++;
      this.readSign();
    },
    previousSign: function () {
      this.selectedIndex--;
      this.readSign();
    },
    showMessages: function (sign: IOrderSignTypeSign): boolean {
      let result = false;

      if (sign.artwork) {
        result = false;
      } else if (sign.signMessageFields && sign.signMessageFields.length > 0) {
        result = true;
      } else if (
        sign.repeatingMessageFields &&
        sign.repeatingMessageFields.length > 0
      ) {
        result = true;
      }
      return result;
    },
    convertCurrency: function (value: number, currencyCode: string) {
      return toCurrency(value, currencyCode);
    },
    actualPriceUpdated: function (price: number, signTypeId: string) {
      //update the price for all signs with the same sign type as the updated sign
      this.signs.forEach((sign) => {
        if (sign.signType.id === this.signs[this.selectedIndex].signType.id) {
          sign.signType.signPrice = price;
        }
      });
      this.signs[this.selectedIndex].signType.signPrice = price;
      this.$emit("signPriceChanged", price, signTypeId);
    },
    installPriceUpdated: function (price: number, signTypeId: string) {
      //update the price for all signs with the same sign type as the updated sign
      this.signs.forEach((sign) => {
        if (sign.signType.id === this.signs[this.selectedIndex].signType.id) {
          sign.signType.installPrice = price;
        }
      });
      this.signs[this.selectedIndex].signType.installPrice = price;
      this.$emit("installPriceChanged", price, signTypeId);
    },
    /**
     * determines if pricing should be shown to the user
     * @returns boolean - true if pricing should be displayed
     */
    showPricing: function () {
      // Don't show pricing if:
      //    order is in DRAFT status
      //    order is in QUOTING status and the user isn't the builder
      if (this.$store.getters.order.status === ORDER_STATUS.DRAFT) {
        return false;
      }

      if (this.$store.getters.order.status === ORDER_STATUS.QUOTING) {
        if (!this.$store.getters.order.userIsBuilder) {
          return false;
        }
      }

      return true;
    },
    selectText: function (event: FocusEvent) {
      (event.target as HTMLInputElement).select();
    },
    calculateQuantity: function (sign: IOrderDetailSign): number {
      let total = 0;
      // only include Approved signs in the total calculation
      if (sign.reviewState === "A") {
        total = total + sign.quantity;
      }
      return total;
    },
    setBackgroundImage: function (): string {
      if (this.signs[this.selectedIndex].artwork) {
        return "url(" + this.signs[this.selectedIndex].artwork + ")";
      } else if (this.signs[this.selectedIndex].attachments.length > 0) {
        return (
          "url(" +
          this.signs[this.selectedIndex].attachments[0].attachment +
          ")"
        );
      } else {
        return "url(" + require("../assets/no_image_placeholder.png") + ")";
      }
    },
    onKeyDown: function (event: KeyboardEvent) {
      // Check for the Escape key
      if (event.key === "Escape" || event.keyCode === 27) {
        this.$store.commit("setShowSignDetailsModal", false);
      }

      // Check for the Left arrow key
      if (event.key === "ArrowLeft" || event.keyCode === 37) {
        if (this.selectedIndex === 0) {
          this.selectedIndex = this.signs.length - 1;
        } else {
          this.selectedIndex--;
        }
      }

      // Check for the Right arrow key
      if (event.key === "ArrowRight" || event.keyCode === 39) {
        if (this.selectedIndex === this.signs.length - 1) {
          this.selectedIndex = 0;
        } else {
          this.selectedIndex++;
        }
      }
    },
    formatCurrency: function (value: number, currencyCode: string) {
      return toCurrency(value, currencyCode);
    },
    // fitImage: function (image: string) {
    //   let width = "100%";
    //   let height = "100%";
    //   let squareSize = 200;
    //   const squareContainer = document.getElementById(
    //     "signDetailsSquareContainer"
    //   );
    //   if (squareContainer) {
    //     squareSize = squareContainer.clientWidth;
    //   }
    //   if (image) {
    //     if (image.naturalWidth > image.naturalHeight) {
    //       width = squareSize + "px";
    //       height = "auto";
    //     } else {
    //       width = "auto";
    //       height = image.naturalHeight + "px";
    //     }
    //   }
    //   return { width: width, height: height };
    // },
    clickCarousel: function (fileIndex: number) {
      this.selectedImageIndex = fileIndex;
      this.showAttachmentModal = true;
    },
    closeModal: function () {
      this.showModal = false;
      this.$store.commit("setShowSignDetailsModal", false);
      $("#signDetailsModal").modal("hide");
    },
    closeAttachmentModal: function () {
      this.showAttachmentModal = false;
      document.getElementById("signDetailsModal")?.focus();
    },
    /**
     * get a color for text that is a good contrast for the hexColor provided.
     * @param hexColor - the background color
     * @returns string - a hex code string - either black or white
     */
    getTextColor: function (hexColor: string) {
      if (hexColor) {
        if (hexColor.substring(0, 1) === "#") {
          hexColor = hexColor.slice(1);
        }
        const r = parseInt(hexColor.slice(0, 2), 16);
        const g = parseInt(hexColor.slice(2, 4), 16);
        const b = parseInt(hexColor.slice(4, 6), 16);
        if (0.213 * r + 0.715 * g + 0.072 * b > 127) {
          return "#000000";
        } else {
          return "#FFFFFF";
        }
      } else {
        return "#FFFFFF";
      }
    },
    getLastPartOfUrl: function (url: string): string {
      // Split the URL string by "/"
      const parts = url.split("/");

      // Get the last part after splitting
      const lastPart = parts.pop();

      // Split the last part by "?" to remove any query parameters
      const lastPartWithoutParams = lastPart?.split("?")[0];

      return lastPartWithoutParams || "";
    },
  },
  computed: {
    ORDER_STATUS: function (): typeof ORDER_STATUS {
      return ORDER_STATUS;
    },
    signPrice: {
      get(): number {
        return this.signs[this.selectedIndex].signType.signPrice;
      },
      set(value: number): void {
        this.isDirty = true;
        this.$store.commit("updateSignTypeSignPrice", {
          signTypeId: this.signs[this.selectedIndex].signType.id,
          price: value,
        });
        // we have to set all signs installPrice to the new value
        this.signs.forEach((sign) => {
          sign.signType.signPrice = value;
        });
      },
    },
    installPrice: {
      get(): number {
        return this.signs[this.selectedIndex].signType.installPrice;
      },
      set(value: number): void {
        this.isDirty = true;
        this.$store.commit("updateSignTypeInstallPrice", {
          signTypeId: this.signs[this.selectedIndex].signType.id,
          price: value,
        });
        // we have to set all signs installPrice to the new value
        this.signs.forEach((sign) => {
          sign.signType.installPrice = value;
        });
      },
    },
    imageFiles: function (): Array<IAttachment> {
      const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"]; // Add more image extensions if needed

      if (this.signs.length > 0) {
        if (this.signs[this.selectedIndex].attachments) {
          return this.signs[this.selectedIndex].attachments.filter(
            (attachment: IAttachment) => {
              const extension = this.getLastPartOfUrl(
                attachment.attachmentFileName
              )
                .split(".")
                .pop()
                ?.toLowerCase(); // Get the file extension
              return extension && imageExtensions.includes(`.${extension}`); // Check if it's an image extension
            }
          );
        } else {
          return [];
        }
      } else {
        return [];
      }
    },
  },
  watch: {
    "$store.state.order.showSignDetailsModal": function (newValue: boolean) {
      // if the sign details modal is about to be shown
      if (newValue) {
        // find the index that matches the sign the user clicked on
        let signIndex = 0;
        for (let i = 0; i < this.signs.length; i++) {
          if (this.signs[i].signId === this.selectedSignId) {
            signIndex = i;
            break;
          }
        }
        // set the selectedIndex so that the sign the user clicked on is displayed
        this.selectedIndex = signIndex;
        this.showModal = newValue;
        $("#signDetailsModal").modal("show");
        this.$nextTick(() => {
          document.getElementById("signDetailsModal")?.focus();
        });
        // document.querySelector(".modal")?.focus();
        // document.getElementById("closeSignDetailsModal")?.focus();
        this.readSign();
      } else {
        this.selectedIndex = 0;
        this.showModal = newValue;
        $("#signDetailsModal").modal("hide");
      }
    },
  },
});
