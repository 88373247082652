




































































































/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-this-alias */

import Vue from "vue";
//eslint-disable-next-line
import { SignTreeData, iSignChild } from '../types'
import { underscoreToNormalCase } from "@/features/SignDesigner/include/Utils";

export default Vue.extend({
  name: "sign-tree",
  props: { item: Object, indent: Number },
  data: function () {
    return {
      isOpen: true,
      isDragging: false,
    };
  },
  methods: {
    /**
     * toggle the visibility of an item
     * @param {iSignChild} item - the iSignChild on which the visibility is being toggled
     */
    toggleVisibility: function (item: iSignChild) {
      this.$nextTick(function () {
        this.$emit("elementVisibilityToggled", item);
      });
    },
    /**
     * handle the treeItemClicked event
     * @param {SignTreeData} item - the item that was clicked on
     * @param {Event} event - the event that triggered this call
     */
    treeItemClicked: function (item: SignTreeData, event: Event) {
      this.$emit("bus", { element: item, event: event });
    },
    /**
     * handle the bus event
     * @param {any} data - an object containing the item that was clicked and the event that triggered this
     */
    bus: function (data: any) {
      this.$emit("bus", data);
    },
    /**
     * toggle the parent element closed
     */
    toggle: function () {
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
      }
    },

    isSelected: function (item: SignTreeData) {
      for (let i = 0; i < this.$store.getters.selectedElementIds.length; i++) {
        if (this.$store.getters.selectedElementIds[i] === item.id) {
          return true;
        }
      }
      return false;
    },

    getIcon: function (fieldType: string) {
      switch (fieldType) {
        case "text":
        case "svgtext":
          return "fa fa-text";
        case "rect":
          return "far fa-square";
        case "ellipse":
        case "circle":
          return "far fa-circle";
        case "visual":
          return "fa fa-image";
        case "color":
          return "fa fa-tint";
        case "repeat":
          return "fa fa-bars";
        case "component":
          return "far fa-diamond";
        case "part":
          return "fas fa-diamond";
      }
    },
    /**
     * return a normal case string from an underscored string
     * @param {string} name - the underscored string
     * @returns {string} the normal case string
     */
    normalCase: function (name: string): string {
      return underscoreToNormalCase(name);
    },
  },
  computed: {
    /**
     * is this item a folder?
     * @returns {boolean} True if this item is considered a folder (i.e. it has children)
     */
    isFolder: function () {
      if (this.item.children && this.item.children.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {},
});
