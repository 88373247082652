import { Module } from "vuex";

import { RootState } from "..";

export interface SessionCompanyState {
  id: number;
  name: string;
  isSalesCompany: boolean;
}

export interface SessionState {
  isAuthenticated: null | boolean;
  userId: null | string;
  company: null | SessionCompanyState;
  featureFlags: string[];
  isBuilder: boolean;
  isInstaller: boolean;
  isDesigner: boolean;
  isSuperUser: boolean;
  isOnBuildFreeTrial: boolean;
}

const store: Module<SessionState, RootState> = {
  state: (): SessionState => ({
    isAuthenticated: null,
    userId: null,
    company: null,
    featureFlags: [],
    isBuilder: false,
    isInstaller: false,
    isDesigner: false,
    isSuperUser: false,
    isOnBuildFreeTrial: false,
  }),
  mutations: {
    setSessionIsAuthenticated(state, isAuthenticated: null | boolean) {
      state.isAuthenticated = isAuthenticated;
    },
    setSessionUserId(state, userId: null | string) {
      state.userId = userId;
    },
    setSessionCompany(state, company: null | SessionCompanyState) {
      state.company = company;
    },
    setSessionFeatureFlags(state, featureFlags: string[]) {
      state.featureFlags = featureFlags;
    },
    setIsBuilder(state, isBuilder: boolean) {
      state.isBuilder = isBuilder;
    },
    setIsInstaller(state, isInstaller: boolean) {
      state.isInstaller = isInstaller;
    },
    setIsDesigner(state, isDesigner: boolean) {
      state.isDesigner = isDesigner;
    },
    setIsSuperUser(state, isSuperUser: boolean) {
      state.isSuperUser = isSuperUser;
    },
    setIsOnBuildFreeTrial(state, isOnBuildFreeTrial: boolean) {
      state.isOnBuildFreeTrial = isOnBuildFreeTrial;
    },
  },
  getters: {
    sessionIsAuthenticated(state) {
      return state.isAuthenticated;
    },
    sessionUserId(state) {
      return state.userId;
    },
    sessionCompany(state) {
      return state.company;
    },
    sessionFeatureFlags(state) {
      return state.featureFlags;
    },
    isBuilder(state) {
      return state.isBuilder;
    },
    isInstaller(state) {
      return state.isInstaller;
    },
    isDesigner(state) {
      return state.isDesigner;
    },
    isSuperUser(state) {
      return state.isSuperUser;
    },
    isOnBuildFreeTrial(state) {
      return state.isOnBuildFreeTrial;
    },
  },
};

export default store;
