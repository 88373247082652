




























































import Vue from "vue";

export default Vue.extend({
  props: {
    enabled: { type: Boolean },
    selectedImage: { type: String },
  },
  data: function () {
    return {
      dropdownOpen: false,
    };
  },
  mounted: function () {
    if (this.$store.getters.selectedField) {
      if (this.$store.getters.selectedField.placeholderImageUrl === "") {
        this.$emit(
          "input",
          this.$store.getters.visualsWithoutMarkers[0].imageUrl
        );
      } else {
        this.$emit(
          "input",
          this.$store.getters.selectedField.placeholderImageUrl
        );
      }
    }
  },
  methods: {
    /**
     * toggle the dropdown
     */
    toggleDropdown: function () {
      //don't toggle if component is disabled
      if (this.enabled) {
        this.dropdownOpen = !this.dropdownOpen;
      }
    },
    /**
     * an image was clicked on
     * @param {string} imageUrl - the url of the image that was clicked on
     */
    selectImage: function (imageUrl: string) {
      this.dropdownOpen = false;
      //this.selectedImage = imageUrl;
      this.$emit("input", imageUrl);
    },
  },
  computed: {},
  watch: {
    enabled: function (newValue) {
      if (newValue) {
        if (!this.$store.getters.selectedField?.placeholderImageUrl) {
          this.$emit(
            "input",
            this.$store.getters.visualsWithoutMarkers[0].imageUrl
          );
        } else {
          this.$emit(
            "input",
            this.$store.getters.selectedField.placeholderImageUrl
          );
        }
      }
    },
  },
});
