















































































































































































import Vue from "vue";
import Snap from "snapsvg-cjs-ts";
import $ from "jquery";

import CollapsibleSection from "../CollapsibleSection.vue";
import { IField } from "../../types";
import VisualSelect from "../VisualSelect.vue";

import { removeSelectionHandles } from "@/features/SignDesigner/utilities";

export default Vue.extend({
  components: { CollapsibleSection, VisualSelect },
  data: function () {
    return {
      horizontalAlignment: "left",
      verticalAlignment: "top",
      showFieldChooser: true,
      showSection: false,
      plusWasClicked: false,
    };
  },
  methods: {
    /**
     * Add a new field
     */
    addField: function () {
      this.showFieldChooser = true;
      this.showSection = true;
      this.plusWasClicked = true;
    },
    selectField: function (fieldName: string) {
      let el: Snap.Element = this.$store.getters.canvas.select(
        "[sa-data-id='" + this.$store.getters.selectedElementIds[0] + "']"
      );
      // if (el) {
      //   if (el.type === "g") {
      //     el = this.$store.getters.canvas.select(
      //       "[sa-data-id='" + this.$store.getters.selectedElementIds[1] + "']"
      //     );
      //   }
      // }
      // extend the el so that we don't get vuex mutation error
      const el1 = $.extend(true, {}, el);
      let bb = el1.getBBox();
      // this.$store.commit("selectFirstFieldOfType", fieldType);
      this.$store.commit("updateSelectedFieldBBox", bb);
      this.$store.commit("createFieldByName", fieldName);

      // if (
      //   this.$store.getters.selectedField.type.toLowerCase().startsWith("color")
      // ) {
      //   fieldType = "color";
      // } else if (
      //   this.$store.getters.selectedField.type.toLowerCase().startsWith("icon")
      // ) {
      //   fieldType = "visual";
      // } else {
      //   fieldType = "text";
      // }
      // //create the field svg elements
      // let gFragment = Snap.parse(
      //   "<g id='" +
      //     normalCaseToUnderscore(this.$store.getters.selectedField.name) +
      //     "'>" +
      //     "</g>"
      // );
      // el.parent().append(gFragment as Snap.Element);
      // let gElement = el
      //   .parent()
      //   .select(
      //     "g#" + normalCaseToUnderscore(this.$store.getters.selectedField.name)
      //   );
      // // move the new parent before the selected element so that we maintain drawing order
      // el.before(gElement);
      // gElement.append(el);
      // if (fieldType === "text") {
      //   // have to select the svg not the div for the rect command to work
      //   let snap = Snap("#signdesigner_snap svg");
      //   let noFillRect = snap.rect(bb.x, bb.y, bb.width, bb.height);
      //   noFillRect.attr({ fill: "none" });
      //   noFillRect.attr({ id: "left_top" });
      //   noFillRect.attr({ "data-name": "left, top" });

      //   // need to prepend the no fill/ no stroke rect so it appears before the text element
      //   gElement.prepend(noFillRect);
      // } else if (fieldType === "visual") {
      //   console.log("el.type: " + el.type);
      //   if (el.type === "rect") {
      //     el.attr({ fill: "none" });
      //     el.attr({ stroke: "none" });
      //     el.attr({ id: "center_middle" });
      //     el.attr({ "data-name": "center, middle" });
      //   } else {
      //     // have to select the svg not the div for the rect command to work
      //     let snap = Snap("#signdesigner_snap svg");
      //     let noFillRect = snap.rect(bb.x, bb.y, bb.width, bb.height);
      //     noFillRect.attr({ fill: "none" });
      //     noFillRect.attr({ stroke: "none" });
      //     noFillRect.attr({ id: "center_middle" });
      //     noFillRect.attr({ "data-name": "center, middle" });
      //     // hide the original graphic
      //     el.attr({ display: "none" });
      //     el.unclick();
      //     // need to prepend the no fill/ no stroke rect so it appears before the element
      //     gElement.prepend(noFillRect);
      //     noFillRect.click((e: MouseEvent) => {
      //       // eslint-disable-next-line
      //       (this.$root.$refs.SVGRenderer as any).selectElement(noFillRect, e);
      //     });
      //   }
      // } else {
      //   el.attr({
      //     id:
      //       "color: {" +
      //       normalCaseToUnderscore(this.$store.getters.selectedField.name) +
      //       "}",
      //     "data-name":
      //       "color: {" +
      //       normalCaseToUnderscore(this.$store.getters.selectedField.name) +
      //       "}",
      //   });
      // }
      removeSelectionHandles(this.$store.getters.canvas);
      /* eslint-disable-next-line */
      (this.$root.$refs.SVGRenderer as any).processSVG(false, false);
      // this.$nextTick(function () {
      //   /* eslint-disable-next-line */
      //   (this.$root.$refs.SVGRenderer as any).drawSelectionHandlesFromIds();
      // });
    },
    /**
     * delete the selected field
     */
    deleteField: function () {
      this.$store.commit("deleteSelectedField");
      //this.removeSAMarkupForField(this.$store.getters.selectedField);
      //removeSelectionHandles(this.$store.getters.canvas);
      /* eslint-disable-next-line */
      (this.$root.$refs.SVGRenderer as any).processSVG(false, false);
      this.showFieldChooser = true;
      this.showSection = false;
    },
    /**
     * remove the sign agent markup from a field
     */
    removeSAMarkupForField: function (field: IField) {
      let el = this.$store.getters.canvas.select(
        "[sa-data-id='" + field.elementIds[0] + "']"
      );
      el.attr({ id: "", "data-name": "" });
      let parentEl: Snap.Element = el.parent();
      const hiddenElements = parentEl.selectAll("[style*='display: none']");
      hiddenElements.attr({ display: "" });
      //move the all the child elements outside of the parent
      parentEl.children().forEach((child: Snap.Element) => {
        if (child.type !== "#text") {
          // don't move the no-fill/no-stroke rectangle
          if (
            child.type === "rect" &&
            child.attr("stroke") === "none" &&
            child.attr("fill") === "none"
          ) {
            //pass
          } else {
            // also skip any place holder images
            if (!child.hasClass("placeholder-image")) {
              child.insertBefore(parentEl);
            }
          }
        }
      });

      //remove the parent element
      parentEl.remove();
    },
    removeImage: function () {
      let el = this.$store.getters.canvas.select(
        "[sa-data-id='" + this.$store.getters.selectedField.elementIds[0] + "']"
      );
      let parentElement = el.parent();
      let existingImage = parentElement.select("image");
      if (existingImage) {
        existingImage.remove();
      }
    },
    replaceImage: function () {
      this.removeImage();
      let el = this.$store.getters.canvas.select(
        "[sa-data-id='" + this.$store.getters.selectedField.elementIds[0] + "']"
      );
      let parentElement = el.parent();

      const imageRect = parentElement.select("rect:not([id='spacer'])");

      let imageString =
        '<image class="placeholder-image" href="' +
        this.placeholderImageUrl +
        '" width="' +
        imageRect.attr("width") +
        '" height="' +
        imageRect.attr("height") +
        '" x="' +
        imageRect.attr("x") +
        '" y="' +
        imageRect.attr("y") +
        '"></image>';

      // we want to prepend the image to the parent so that the click events
      // on the element still work.
      // because we are parsing a string, snapSVG requires us to first append the image
      // and then prepend it
      let imageFrag = Snap.parse(imageString);
      parentElement.append(imageFrag);
      const imageEl = parentElement.select("image");
      imageEl.click((e: MouseEvent) => {
        // eslint-disable-next-line
        (this.$root.$refs.SVGRenderer as any).selectElement(imageEl.parent().select("rect"), e);
      });
      // let w = parentElement.select("image");
      // parentElement.prepend(w);
    },
  },
  computed: {
    /**
     * should we show the section expend/collapse caret
     * @returns {boolean} True if caret should be displayed
     */
    showCaret: function (): boolean {
      return this.$store.getters.isFieldSelected;
    },
    /**
     * should we show the plus sign
     * @returns {boolean} True if plus sign should be displayed
     */
    showPlusSign: function () {
      //don't show the plus sign if we already have a field selected
      if (this.$store.getters.selectedField) {
        return false;
      } else {
        if (this.$store.getters.selectedElementIds.length > 0) {
          return true;
        }
      }
      return false;
    },
    /**
     * should we show the minus sign
     * @returns {boolean} True if the minus sign should be displayed
     */
    showTrash: function () {
      if (this.$store.getters.selectedField) {
        return true;
      }
      return false;
    },
    /**
     * should the section be displayed open
     * @returns {boolean} True if section should be displayed open
     */
    openSection: function (): boolean {
      return (
        this.plusWasClicked &&
        this.showSection &&
        this.$store.getters.selectedElementIds.length > 0
      );
      // return false;
    },
    /**
     * return an array of all text fields from organization's field list
     * @returns {Array<IField>}
     */
    availableTextFields: function (): Array<IField> {
      let results = [] as Array<IField>;
      const textTypes = ["text", "char", "char_x", "trans", "date"];

      this.$store.getters.fields.forEach((field: IField) => {
        if (textTypes.includes(field.type.toLowerCase())) {
          results.push(field);
        }
      });

      //sort results by name
      return results.sort((a, b) =>
        a.name > b.name ? 1 : a.name < b.name ? -1 : 0
      );
    },
    /**
     * return an array of all visual fields from organization's field list
     * @returns {Array<IField>}
     */
    availableVisualFields: function (): Array<IField> {
      let results = [] as Array<IField>;
      const visualTypes = ["icon", "icon_t", "color", "color_t", "color_x"];

      this.$store.getters.fields.forEach((field: IField) => {
        if (visualTypes.includes(field.type.toLowerCase())) {
          results.push(field);
        }
      });

      //sort results by name
      return results.sort((a, b) =>
        a.name > b.name ? 1 : a.name < b.name ? -1 : 0
      );
    },
    /**
     * return an array of all color fields from organization's field list
     * @returns {Array<IField>}
     */
    availableColorFields: function (): Array<IField> {
      let results = [] as Array<IField>;
      const visualTypes = ["color", "color_t", "color_x"];

      this.$store.getters.fields.forEach((field: IField) => {
        if (visualTypes.includes(field.type.toLowerCase())) {
          results.push(field);
        }
      });

      //sort results by name
      return results.sort((a, b) =>
        a.name > b.name ? 1 : a.name < b.name ? -1 : 0
      );
    },
    /**
     * getter/setter for fieldName
     */
    fieldName: {
      get() {
        if (this.$store.getters.selectedField) {
          return this.$store.getters.selectedField.name;
        } else {
          return "";
        }
      },
      set(value: string) {
        if (value === "") {
          // we have set the value to Choose Field so there is nothing to do
        } else if (value === "new") {
          // track the added field
          this.$store.commit("setTrackAddedField", true);
          // show the New field Dialog
          this.$store.commit("setShowAddFieldModal", true);
          this.showFieldChooser = true;
        } else {
          this.showFieldChooser = false;
          this.selectField(value);
          // //deselect the old field
          // let el = this.$store.getters.canvas.select(
          //   "[sa-data-id='" + this.$store.getters.selectedElementIds[0] + "']"
          // );
          // el.parent().attr({ id: normalCaseToUnderscore(value) });

          // this.$store.commit("changeSelectedField", value);
          // removeSelectionHandles(this.$store.getters.canvas);
          // /* eslint-disable-next-line */
          // (this.$root.$refs.SVGRenderer as any).processSVG(false, true);
          // // this.$store.commit("updateSelectedFieldName", value);
        }
      },
    },
    /**
     * getter/setter for fieldType
     */
    fieldType: {
      get() {
        if (this.$store.getters.selectedField) {
          return this.$store.getters.selectedField.type.toLowerCase();
        } else {
          if (this.$store.getters.canvas) {
            let fieldType = "text";
            try {
              let el: Snap.Element = this.$store.getters.canvas.select(
                "[sa-data-id='" +
                  this.$store.getters.selectedElementIds[0] +
                  "']"
              );
              if (el) {
                if (el.type === "g") {
                  el = this.$store.getters.canvas.select(
                    "[sa-data-id='" +
                      this.$store.getters.selectedElementIds[1] +
                      "']"
                  );
                }
                if (el.type.toLowerCase() === "text") {
                  fieldType = "text";
                } else {
                  fieldType = "icon";
                }
              }
            } catch (e) {
              console.debug(e);
            }
            return fieldType;
          } else {
            return "text";
          }
        }
      },
      set(value: string) {
        this.$store.commit("updateSelectedFieldType", value);
      },
    },
    /**
     * getter/setter for field width
     */
    fieldWidth: {
      get() {
        return this.$store.getters.selectedField.width;
      },
      set(value: string) {
        this.$store.commit("updateSelectedFieldWidth", value);
        // let el = this.$store.getters.canvas.select(
        //   "[sa-data-id='" + this.$store.getters.selectedElementIds[0] + "']"
        // );
        // el.attr({ width: parseFloat(value) });
        // this.$store.commit("setRerender", !this.$store.getters.rerender);
      },
    },
    /**
     * getter/setter for placeholder text
     */
    placeholderText: {
      get() {
        if (this.$store.getters.selectedField) {
          // return the text of the element
          let el = this.$store.getters.canvas.select(
            "[sa-data-id='" +
              this.$store.getters.selectedField.elementIds[0] +
              "']"
          );

          return el.node.textContent;
        } else {
          return "";
        }
      },
      set(value) {
        // this.$store.commit("captureState", {description: "Update Placeholder Text", mutationName: "");
        this.$store.commit("updateSelectedFieldPlaceholderText", value);
        // let el = this.$store.getters.canvas.select(
        //   "[sa-data-id='" +
        //     this.$store.getters.selectedField.elementIds[0] +
        //     "']"
        // );

        // el.node.textContent = value;
        // const el1 = $.extend(true, {}, el);
        // alignText(this.$store.getters.selectedField.horizontalAlignment, el1);
      },
    },
    /**
     * getter/setter for field height
     */
    fieldHeight: {
      get() {
        return this.$store.getters.selectedField.height;
      },
      set(value: string) {
        this.$store.commit("updateSelectedFieldHeight", value);
      },
    },
    /**
     * getter/setter for hasPlaceholderImage
     */
    hasPlaceholderImage: {
      get() {
        if (this.$store.getters.selectedField) {
          return this.$store.getters.selectedField.hasPlaceholderImage;
        } else {
          return false;
        }
      },
      set(value: boolean) {
        this.$store.commit("updateSelectedFieldHasPlaceholderImage", value);
        if (value) {
          this.replaceImage();
        } else {
          this.removeImage();
        }
      },
    },
    /**
     * getter/setter for placeholder image url
     */
    placeholderImageUrl: {
      get() {
        if (this.$store.getters.selectedField) {
          return this.$store.getters.selectedField.placeholderImageUrl;
        } else {
          return "";
        }
      },
      set(value: string) {
        this.$store.commit("updateSelectedFieldPlaceholderImageUrl", value);
        if (this.hasPlaceholderImage) {
          this.replaceImage();
        }
      },
    },
    isOpenable: function (): boolean {
      return this.plusWasClicked;
    },
  },
  watch: {
    "$store.state.signDesigner.selectedElementIds": function () {
      if (this.$store.getters.selectedField) {
        this.showFieldChooser = false;
        this.showSection = true;
        this.plusWasClicked = true;
      } else {
        this.plusWasClicked = false;
        this.showFieldChooser = true;
        this.showSection = false;
      }
    },
    "$store.state.signDesigner.showAddFieldModal": function (value) {
      if (!value) {
        if (this.$store.getters.trackAddedField) {
          if (this.$store.getters.addedFieldId !== "") {
            this.$store.commit(
              "selectFieldById",
              this.$store.getters.addedFieldId
            );
          }
          this.$store.commit("setTrackAddedField", false);
          this.selectField(this.$store.getters.selectedField.name);
        }
      }
    },
  },
});
