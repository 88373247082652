






















import { iVisual } from "../../types";
import Vue from "vue";

export default Vue.extend({
  methods: {
    /**
     * return a portion of an array
     * @param {Array<iVisual>} array - the full array
     * @param {number} size - the number of elements per "chunk"
     * @returns {Array<iVisual>} an array of iVisual that is "size" elements long
     */
    chunkArray: function (array: Array<iVisual>, size: number) {
      let result = [];
      for (let i = 0; i < array.length; i += size) {
        let chunk = array.slice(i, i + size);
        result.push(chunk);
      }
      return result;
    },
  },
  computed: {},
});
