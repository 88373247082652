




import Vue from "vue";

import Order from "@/features/Orders/views/OrderDetailsView.vue";

export default Vue.extend({
  components: { Order },
});
