





















import Vue from "vue";

export default Vue.extend({
  props: {
    signData: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {};
  },
  methods: {},
  computed: {},
});
