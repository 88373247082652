

























































import Vue from "vue";
import Snap from "snapsvg-cjs-ts";

import CollapsibleSection from "../CollapsibleSection.vue";
import MeasurementInput from "../MeasurementInput.vue";
import { IRepeat } from "../../types";
import { drawRepeatElements, removeSelectionHandles } from "../../utilities";

import {
  uuidv4,
  updateSVGAttribute,
  convertPointsToUnit,
  convertUnitToPoints,
} from "@/features/SignDesigner/utilities";

export default Vue.extend({
  components: { CollapsibleSection, MeasurementInput },
  data: function () {
    return {
      nextRepeat: 1,
    };
  },
  methods: {
    /**
     * validateNumeric
     * @param {KeyboardEvent} event - the keyboard event
     */
    validateNumeric: function (event: KeyboardEvent): boolean {
      // prevent input if the keypress isn't numeric or
      // an acceptable special key
      if (
        event.key !== "Backspace" &&
        event.key !== "Delete" &&
        event.key !== "ArrowLeft" &&
        event.key !== "ArrowRight" &&
        isNaN(Number(event.key))
      ) {
        event.preventDefault();
        return false;
      } else {
        return true;
      }
    },
    /**
     * add a repeat
     */
    addRepeat: function () {
      this.$store.commit("deselectAllRepeats");
      let name = "Repeat_" + this.nextRepeat;
      this.nextRepeat++;
      let repeat = {
        id: uuidv4(),
        isSelected: true,
        name: name,
        steps: 0,
        offset: 0,
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        elementIds: this.$store.getters.selectedElementIds,
        clonedElementIds: [],
      } as IRepeat;

      // let lowestX = 99999;
      // let lowestY = 99999;

      // repeat.elementIds.forEach((elementId: string) => {
      //   // find the field that corresponds to each element id
      //   let repeatField = JSON.parse(
      //     JSON.stringify(this.$store.getters.fieldByElementId(elementId))
      //   );
      //   // set the belongsToRepeatId field equal to the repeat id
      //   if (repeatField) {
      //     repeatField.belongsToRepeatId = repeat.id;
      //   }
      //   this.$store.commit("updateField", repeatField);

      //   //determine the lowest X and Y so that we can update the repeat with them
      //   lowestX = repeatField.x < lowestX ? repeatField.x : lowestX;
      //   lowestY = repeatField.y < lowestY ? repeatField.y : lowestY;
      // });

      // repeat.x = lowestX;
      // repeat.y = lowestY;
      this.$store.commit("addRepeat", repeat);
      removeSelectionHandles(this.$store.getters.canvas);
      this.$nextTick(() => {
        /* eslint-disable-next-line */
        (this.$root.$refs.SVGRenderer as any).processSVG(false, true);
      });

      // calculateRepeatSize(this, repeat.id);

      // // get the updated repeat
      // repeat = JSON.parse(
      //   JSON.stringify(this.$store.getters.repeatById(repeat.id))
      // );
      // let gElement = Snap.parse("<g id='column'></g>");
      // let columnRect = Snap.parse(
      //   "<rect x='" +
      //     repeat.x +
      //     "' y='" +
      //     repeat.y +
      //     "' width='" +
      //     repeat.width +
      //     "' height='" +
      //     repeat.height +
      //     "' style='fill: none'></rect>"
      // );
      // let heightRect = Snap.parse(
      //   "<g id='height'><rect x='0' y='0' width='" +
      //     repeat.width +
      //     "' height='" +
      //     repeat.offset +
      //     "' style='fill: none'></rect></g>"
      // );
      // let repeatRect = Snap.parse("<g id='repeat'></g>");
      // this.$store.getters.canvas.select("svg").append(gElement);
      // let columnElement = this.$store.getters.canvas.select("g#column");
      // columnElement.append(columnRect);
      // columnElement.append(repeatRect);

      // // move all the repeat elements into the repeat rect
      // let repeatElement = columnElement.select("g#repeat");
      // repeatElement.append(heightRect);
      // // add repeat elements ordered by how they appear in the SVG
      // const elements = this.$store.getters.canvas.selectAll("*");
      // elements.forEach((element: Snap.Element) => {
      //   if (repeat.elementIds.includes(element.attr("sa-data-id"))) {
      //     let el = this.$store.getters.canvas.select(
      //       "[sa-data-id='" + element.attr("sa-data-id") + "']"
      //     );
      //     repeatElement.append(el.parent());
      //   }
      // });

      // // repeat.elementIds.forEach((elementId: string) => {
      // //   let el = this.$store.getters.canvas.select(
      // //     "[sa-data-id='" + elementId + "']"
      // //   );

      // //   repeatElement.append(el.parent());
      // // });
      // removeSelectionHandles(this.$store.getters.canvas);
      // // this.$nextTick(() => {
      // //   /* eslint-disable-next-line */
      // //   (this.$root.$refs.SVGRenderer as any).processSVG(false, true);
      // // });
    },
    /**
     * delete the selected repeat
     */
    deleteRepeat: function () {
      //remove the SVG elements
      let columnElement = this.$store.getters.canvas.select(
        "g#column"
      ) as Snap.Element;
      let parentElement = columnElement.parent();

      //move the fields outside of the repeat structure
      this.$store.getters.selectedRepeat.elementIds.forEach(
        (elementId: string) => {
          let el = this.$store.getters.canvas.select(
            "[sa-data-id='" + elementId + "']"
          );
          parentElement.append(el.parent());
        }
      );
      //remove the repeat structure

      for (let i = 0; i < columnElement.children.length; i++) {
        columnElement.children()[i].remove();
      }
      columnElement.remove();

      removeSelectionHandles(this.$store.getters.canvas);
      /* eslint-disable-next-line */
      // (this.$root.$refs.SVGRenderer as any).processSVG(false, true);

      this.$store.commit("deleteSelectedRepeat");
      // get rid of the cloned elements
      this.$store.getters.canvas
        .selectAll(".repeat-clone")
        .forEach((el: Snap.Element) => {
          el.remove();
        });
      this.$nextTick(() => {
        /* eslint-disable-next-line */
        (this.$root.$refs.SVGRenderer as any).processSVG(false, true);
      });
    },
  },
  computed: {
    /**
     * should we show the plus sign
     * @returns {boolean} True if the plus sign should be shown
     */
    showPlusSign: function (): boolean {
      //don't show the plus sign if we already have a repeat selected
      if (this.$store.getters.selectedRepeat) {
        return false;
      } else {
        if (this.$store.getters.selectedElementIds.length > 0) {
          return true;
        }
      }
      return false;
    },
    /**
     * should we show the minus sign
     * @returns {boolean} True if the minus sign should be shown
     */
    showTrash: function (): boolean {
      if (this.$store.getters.selectedRepeat) {
        return true;
      }
      return false;
    },
    /**
     * should the section be shown open
     * @returns {boolean} True if the section should be shown open
     */
    openSection: function (): boolean {
      if (this.$store.getters.selectedRepeat) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * getter/setter for repeatName
     */
    repeatName: {
      get() {
        return this.$store.getters.selectedRepeat.name;
      },
      set(value: string) {
        this.$store.commit("updateSelectedRepeatName", value);
      },
    },
    /**
     * getter/setter for repeatSteps
     */
    repeatSteps: {
      get() {
        if (this.$store.getters.selectedRepeat) {
          return this.$store.getters.selectedRepeat.steps;
        } else {
          return 0;
        }
      },
      set(value: number) {
        this.$store.commit("updateSelectedRepeatSteps", value);
        drawRepeatElements(this);
        // this.$emit("repeatStepsChanged");
      },
    },
    /**
     * getter/setter for repeatOffset
     */
    repeatOffset: {
      get(): number {
        if (this.$store.getters.selectedRepeat) {
          return convertPointsToUnit(
            this.$store.getters.selectedRepeat.offset,
            this.$store.getters.signData.measurementUnit.unit
          );
        } else {
          return 0;
        }
      },
      set(value: string) {
        const offset = convertUnitToPoints(
          parseFloat(value),
          this.$store.getters.signData.measurementUnit.unit
        );
        updateSVGAttribute(
          this.$store.getters.selectedRepeat.elementIds[0],
          "repeat",
          "offset",
          offset.toString(),
          this.$store.getters.canvas
        );

        this.$store.commit("updateSelectedRepeatOffset", offset);
        this.$emit("repeatStepsChanged");
      },
    },
    /**
     * getter/setter for width
     */
    width: {
      get() {
        return convertPointsToUnit(
          this.$store.getters.selectedRepeat.width,
          this.$store.getters.signData.measurementUnit.unit
        );

        //return this.$store.getters.selectedRepeat.width;
      },
      set(value: string) {
        const width = convertUnitToPoints(
          parseFloat(value),
          this.$store.getters.signData.measurementUnit.unit
        );
        updateSVGAttribute(
          this.$store.getters.selectedRepeat.elementIds[0],
          "repeat",
          "width",
          width.toString(),
          this.$store.getters.canvas
        );

        this.$store.commit("updateSelectedRepeatWidth", width);
        // redraw the bounding box
        // eslint-disable-next-line
        (this.$root.$refs.SVGRenderer as any).drawSelectionHandlesFromIds();
      },
    },
    /**
     * getter/setter for height
     */
    height: {
      get() {
        return convertPointsToUnit(
          this.$store.getters.selectedRepeat.height,
          this.$store.getters.signData.measurementUnit.unit
        );
        // return this.$store.getters.selectedRepeat.height;
      },
      set(value: string) {
        const height = convertUnitToPoints(
          parseFloat(value),
          this.$store.getters.signData.measurementUnit.unit
        );
        if (this.$store.getters.selectedRepeat) {
          updateSVGAttribute(
            this.$store.getters.selectedRepeat.elementIds[0],
            "repeat",
            "height",
            height.toString(),
            this.$store.getters.canvas
          );

          this.$store.commit("updateSelectedRepeatHeight", height);
        }
        // redraw the bounding box
        // eslint-disable-next-line
        (this.$root.$refs.SVGRenderer as any).drawSelectionHandlesFromIds();
      },
    },
  },
  watch: {
    repeatSteps: function (newValue: number, oldValue: number) {
      if (oldValue !== newValue) {
        if (!isNaN(newValue)) {
          this.height = newValue * (this.repeatOffset as number);
        } else {
          this.height = 0;
        }
      }
    },
    repeatOffset: function (newValue: number) {
      this.height = newValue * (this.repeatSteps as number);
    },
  },
});
