


























































































import { Component, Vue, Prop } from "vue-property-decorator";

import { MODELS, PermObject } from "../types";

let uuid = 0;

@Component
export default class SearchInputGroup extends Vue {
  @Prop({ type: Array, required: true })
  readonly permObjects!: PermObject[];

  @Prop({ type: Object, required: true })
  readonly targetPermObject!: PermObject;

  @Prop({ type: String, required: true })
  readonly value!: string;

  $refs!: {
    search_textbox: HTMLFormElement;
  };

  readonly MODELS = MODELS;
  uuid = 0;

  beforeCreate(): void {
    this.uuid = uuid;
    uuid++;
  }

  /**
   * Focus the search textbox
   */
  focus(): void {
    this.$refs.search_textbox.focus();
  }
}
