


























































import Vue from "vue";
import { PropType } from "vue";

import { IDashboardCompany, IDashboardCompanyGroup } from "../types";

import { capitalize } from "@/filters/common";

export default Vue.extend({
  props: {
    groupList: {
      type: Array as PropType<IDashboardCompany[]>,
    },
    showGroups: {
      type: Array as PropType<string[]>,
      default: ["all"],
    },
  },
  filters: { capitalize },
  data: function () {
    return {
      showMenu: false,
    };
  },
  methods: {
    firstUserInGroups: function (): string {
      let result = "";
      this.groupList.forEach((groupList) => {
        groupList.groups.forEach((group) => {
          if (result === "") {
            if (this.showGroups.includes(group.name)) {
              result = group.users[0].image;
            }
          }
        });
      });
      return result;
    },
    filteredGroups: function (
      company: IDashboardCompany
    ): Array<IDashboardCompanyGroup> {
      return company.groups.filter((group) => {
        return this.showGroups.includes(group.name);
      });
    },
  },
  computed: {
    filteredGroupList: function (): Array<IDashboardCompany> {
      return this.groupList.filter((groupList) => {
        if (this.showGroups[0] === "all") {
          return true;
        } else {
          return groupList.groups.some((group) => {
            return this.showGroups.includes(group.name);
          });
        }
      });
    },
  },
  watch: {},
});
