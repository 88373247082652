



















































































































import Vue from "vue";
import VueSelect from "vue-select";
import { IField } from "../../types";

export default Vue.extend({
  components: {
    VueSelect,
  },
  props: {},
  data: function () {
    return {
      showModal: false,
      name: "",
      files: null as FileList | null,
      isMarker: false,
      selectedFields: [] as Array<string>,
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showAddVisualsModal;
  },
  methods: {
    /**
     * yes clicked
     */
    acceptClicked: function () {
      //### perform accept processing here ###
      //close the modal
      this.$store.commit("setShowAddVisualsModal", false);
    },
    /**
     * close the modal
     * @param {Event} e - the event that triggered the close
     */
    closeClicked: function () {
      // close the modal
      this.$store.commit("setShowAddVisualsModal", false);
    },
    fileChanged: function (e: Event) {
      const target = e.target as HTMLInputElement;
      this.files = target.files as FileList;
    },
    saveChanges: function () {
      console.log("save");
    },
  },
  computed: {
    isSVGFile: function () {
      if (this.files) {
        if (this.files[0].name.split(".").pop() === "svg") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    visualFields: function () {
      const arr: Array<{ id: string; label: string }> = [];
      this.$store.getters.fields.forEach((field: IField) => {
        if (
          field.type.toLowerCase() === "icon" ||
          field.type.toLowerCase() === "icon_t"
        ) {
          arr.push({ id: field.id, label: field.name });
        }
      });
      return arr;
    },
  },
  watch: {
    /**
     * when showConfirmModal from the store changes
     * @param {boolean} newValue - the value of the showConfirmModal from the store
     */
    "$store.state.signDesigner.showAddVisualsModal": function (
      newValue: boolean
    ) {
      //   const this = this;
      this.showModal = newValue;
    },
  },
});
