






























































import Vue from "vue";
//eslint-disable-next-line
import { IField, iSignChild } from '../types'
import { featureAvailable } from "../utilities";
import CollapsibleSection from "./CollapsibleSection.vue";
import SignInspector from "./inspectors/SignInspector.vue";
import SidesInspector from "./inspectors/SidesInspector.vue";
import TextInspector from "./inspectors/TextInspector.vue";
// import FieldInspector from "./inspectors/FieldInspector.vue";
import FieldInspectorTwo from "./inspectors/FieldInspector2.vue";
import AppearanceInspector from "./inspectors/AppearanceInspector.vue";
// import ComponentInspector from "./inspectors/ComponentInspector.vue";
// import PartInspector from "./inspectors/PartInspector.vue";
// import MaterialInspector from "./inspectors/MaterialInspector.vue";
import RepeatInspector from "./inspectors/RepeatInspector.vue";
import FileInspector from "./inspectors/FileInspector.vue";
//import ImageInspector from "./inspectors/ImageInspector.vue";
// import ProcessInspector from "./inspectors/ProcessInspector.vue";
import VisualInspector from "./inspectors/VisualInspector.vue";
import ColorInspector from "./inspectors/ColorInspector.vue";
import AlignmentInspector from "./inspectors/AlignmentInspector.vue";
import CodeInspector from "./inspectors/CodeInspector.vue";
import MarketplaceInspector from "./inspectors/MarketplaceInspector.vue";

export default Vue.extend({
  components: {
    CollapsibleSection,
    SignInspector,
    SidesInspector,
    TextInspector,
    // FieldInspector,
    FieldInspectorTwo,
    AppearanceInspector,
    // ComponentInspector,
    // PartInspector,
    // MaterialInspector,
    RepeatInspector,
    FileInspector,
    // ImageInspector,
    // ProcessInspector,
    VisualInspector,
    ColorInspector,
    AlignmentInspector,
    CodeInspector,
    MarketplaceInspector,
  },
  props: {
    treeData: Object,
    // componentData: Object,
    signData: Object,
    panelHeight: Number,
  },

  data() {
    return {
      selectedMaterial: {
        value: "20020",
        label: "Solid Paint",
      },
      selectedMaterialOption: {},
      current_panel: "details",
      processOptions: [
        { code: "none", label: "None" },
        { code: "varnish", label: "Varnish" },
        { code: "cutInVinyl", label: "Cut In Vinyl" },
        { code: "printOnVinyl", label: "Print On Vinyl" },
        { code: "printOnPanel", label: "Print On Panel" },
        { code: "tactile", label: "Tactile" },
        { code: "Braille", label: "Braille" },
        { code: "texture", label: "Texture" },
        { code: "panelShape", label: "Panel Shape" },
      ],
      materialOptions: [
        {
          value: "20020",
          label: "Solid Paint",
          children: [{ value: "MP1082", label: "MP 1082", color: "#0176bc" }],
        },
        { value: "20028", label: "Solid Paint + Ink" },
        {
          value: "2112",
          label: "Metallic Paint",
          children: [
            { value: "1111", label: "3M 100-120" },
            { value: "1112", label: "3M 100-130" },
            { value: "1113", label: "3M 100-140" },
          ],
        },
        {
          value: "2113",
          label: "Aluminum",
          children: [
            { value: "1111", label: "3M 100-120" },
            { value: "1112", label: "3M 100-130" },
            { value: "1113", label: "3M 100-140" },
          ],
        },
        {
          value: "2114",
          label: "Acrylic",
          children: [
            { value: "1111", label: "White Underpaint" },
            { value: "1112", label: "No White Underpaint" },
          ],
        },
        { value: "20021", label: "Paper" },
        { value: "20022", label: "Marker Board" },
        { value: "20023", label: "Sintra, 1mm" },
        {
          value: "20024",
          label: "Rowmark Acrylic",
          children: [
            { value: "1", label: "3X1-301 Gray", color: "#e8e8e8" },
            { value: "2", label: "3X1-204 Bright White", color: "#ffffff" },
            { value: "3", label: "3X1-601 Red", color: "#ff0000" },
          ],
        },
        { value: "20025", label: "Sealant (Clean Room)" },
        { value: "20026", label: "Vinyl (Opaque)" },
        { value: "20027", label: "Vinyl (Translucent)" },
      ],
      finishOptions: [
        { value: "Powdercoat", label: "Powder Coated" },
        { value: "Paint", label: "Painted" },
      ],
      materialTag: "",
      materialTags: [],
      finishTag: "",
      finishTags: [],
      processTag: "",
      processTags: [],
      notes: "",
      component: {} as iSignChild,
      nextComponentNumber: 1,
    };
  },
  methods: {
    /**
     * emit the addProcessClicked event
     */
    addProcess() {
      this.$emit("addProcessClicked");
    },
    /**
     * get the selected child elements
     * @param {Array<iSignChild>} elements - the elements to look through
     * @param {Array<iSignChild>} selectedElements - the elements that have been selected so far
     * @returns {Array<iSignChild>} an array of iSignChild that have selected set to true
     */
    getSelectedChildElements: function (
      elements: Array<iSignChild>,
      selectedElements: Array<iSignChild>
    ) {
      elements.forEach((element: iSignChild) => {
        if (element.isSelected) {
          selectedElements.push(element);
        }
        if (element.children && element.children.length > 0) {
          this.getSelectedChildElements(element.children, selectedElements);
        }
      });
      return selectedElements;
    },
    /**
     * find all iSignChild elements that have selected set to true
     * @returns {Array<iSignChild>} an array of iSignChild
     */
    getSelectedElements: function () {
      let selectedElements: Array<iSignChild> = [];
      this.getSelectedChildElements(
        this.signData.sides[this.$store.getters.currentSide].children,
        selectedElements
      );

      return selectedElements;
    },
    featureAccess: function (featureName: string): boolean {
      return featureAvailable(this, featureName);
    },
  },
  computed: {},
  watch: {},
});
