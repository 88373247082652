









































































































































import Vue from "vue";
import FullPageLayout from "@/components/layouts/FullPageLayout.vue";
import { IPricePlan, IGraphPricePlans } from "../types";

export default Vue.extend({
  components: { FullPageLayout },
  data: function () {
    return {
      pricingPlans: [] as Array<IPricePlan>,
      companyName: "",
      companyId: this.$route.params.id,
      errorMessage: "",
      selectedPricePlan: {},
    };
  },
  mounted: function () {
    // get list of price plans from backend
    let localPricingPlans = [] as Array<IPricePlan>;

    fetch("/graphql/", {
      method: "POST",
      body: JSON.stringify({
        query: `query get_company {
          company(id: ${this.$route.params.id}) {
            name
          }
        }`,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        this.companyName = data.data.company.name;
      })
      .then(() => {
        fetch("/graphql/", {
          method: "POST",
          body: JSON.stringify({
            query: `query get_pricing_plans {
              pricingPlans(company: ${this.$route.params.id}) {
                edges {
                  node {
                    name
                    id: contentObjectId
                    planType
                    isPrivatePlan
                    assignedCompanies {
                      edges {
                        node {
                          id: contentObjectId
                          name
                        }
                      }
                    }
                    activeSubscriptions
                    prices {
                      edges {
                        node {
                          id: contentObjectId
                          stripePriceId
                          billingCycle
                          tiers {
                            edges {
                              node {
                                amount
                                upTo
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }`,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => response.json())
          .then((result: IGraphPricePlans) => {
            // iterate over each plan
            result.data.pricingPlans.edges.forEach((node) => {
              localPricingPlans.unshift({
                id: Number(node.node.id),
                name: node.node.name,
                planType: node.node.planType,
                isPrivatePlan: node.node.isPrivatePlan,
                assignedCompanies: [],
                activeSubscriptions: node.node.activeSubscriptions,
              });
            });
            // sort pricing plans by name, alphabetically
            localPricingPlans.sort(function (a, b) {
              let previousName = a.name.toLowerCase();
              let currentName = b.name.toLowerCase();
              if (previousName < currentName) {
                return -1;
              }
              if (previousName > currentName) {
                return 1;
              }
              return 0;
            });
            this.pricingPlans = localPricingPlans;
          });
      });
  },
  methods: {
    capitalizeFirstLetter(word: string) {
      return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();
    },
    deletePricePlan(plan: IPricePlan) {
      const index = this.pricingPlans.indexOf(plan);
      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
            mutation remove_price_plan {
              deletePricingPlan(input: {
                id: ${plan.id}
              }) {
                # errors { messages }
                success
              }
            }`,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.errors) {
            this.errorMessage = `Could not delete price plan "${plan.name}". ${result.errors[0].message}`;
          }
          // else if (result.data.deletePricingPlan?.errors.length > 0) {
          //   this.errorMessage = `Could not delete price plan "${
          //     plan.name
          //   }". ${result.data.deletePricingPlan.errors[0].messages.join(", ")}`;
          else {
            // get index of deleted plan and remove
            this.pricingPlans.splice(index, 1);
          }
        });
    },
  },
});
