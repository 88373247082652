












































import Vue from "vue";

import { ISignType, iSignData } from "../../types";
import { uuidv4 } from "../../utilities";
// import matthews from "./assets/matthews.json";

export default Vue.extend({
  components: {},
  data: function () {
    return {
      showModal: false,
      signTypeName: "",
      signTypeShortCode: "",
      signData: {} as iSignData,
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showAddSignTypeModal;
  },
  methods: {
    /**
     * test if a iSignData object is empty
     * @param {iSignData} obj - an iSignData object
     */
    isEmpty: function (obj: iSignData): boolean {
      if (obj === null) {
        return true;
      } else {
        return (
          obj && Object.keys(obj).length === 0 && obj.constructor === Object
        );
      }
    },
    /**
     * save changes
     */
    saveChanges: function () {
      //clear the canvas
      if (!this.isEmpty(this.$store.getters.canvas)) {
        try {
          this.$store.getters.canvas.select("*").remove();
          this.$store.commit("setCanvas", null);
        } catch (err) {
          console.log(err);
        }
      }

      // generate a random color that isn't too light or dark
      const r = Math.floor(Math.random() * (200 - 50 + 1) + 50);
      const g = Math.floor(Math.random() * (200 - 50 + 1) + 50);
      const b = Math.floor(Math.random() * (200 - 50 + 1) + 50);
      const rgb = r.toString(16) + g.toString(16) + b.toString(16);

      const id = uuidv4();
      const signType: ISignType = {
        id: id,
        uuid: id,
        isSelected: true,
        name: this.signTypeName,
        fullPath: "",
        shortCode: this.signTypeShortCode,
        hexColor: rgb,
      };

      // reset all the data
      this.$store.commit("setFiles", []);
      this.$store.commit("setParts", []);
      this.$store.commit("setSignMaterials", []);
      this.$store.commit("setSignProcesses", []);
      this.$store.commit("setRepeats", []);
      this.$store.commit("setAlignments", []);
      this.$store.commit("setSelectedElementIds", []);
      this.$store.commit("deselectAllFields");
      this.$store.commit("deselectAllUsedFields");

      this.signData = {} as iSignData;
      this.signData.id = signType.id;
      this.signData.name = this.signTypeName;
      this.signData.shortCode = this.signTypeShortCode;
      this.signData.hexColor = rgb;
      this.signData.sides = [];
      this.signData.width = 0;
      this.signData.height = 0;
      this.signData.numberOfSides = 1;
      this.signData.areSidesIdentical = true;
      this.signData.svgUrl = "";
      this.signData.measurementUnit = { unit: "", conversion: 0 };
      let side = { id: "", name: "Side 1", children: [] };
      this.signData.sides.push(side);
      this.$store.commit("setSignData", this.signData);
      //close the window
      this.$store.commit("setShowAddSignTypeModal", false);

      this.$store.commit("addSignType", signType);
      this.$store.commit("setCurrentSignTypeUUID", id);
      this.$store.commit("selectSignTypeById", id);
      this.$store.commit("setSignTypeName", this.signTypeName);
      this.$store.commit("setSignDataShortCode", this.signTypeShortCode);

      const users: Array<string> = [];
      users.push(this.$store.getters.user.email);

      let operations = {
        query: `mutation addSignType ($input: AddSignTemplateInput!){
                  addSignType (addSignTypeInput: $input) {
                    signTemplate {
                      name
                    }
                  }
                }`,
        variables: {
          input: {
            organizationId: this.$store.getters.organizationId,
            folder: this.$store.getters.signData.folderId,
            name: this.$store.getters.signData.name,
            shortCode: this.$store.getters.signData.shortCode,
            details: "",
            hexColor: rgb,
            unitCost: 0,
            numberOfSides: 0,
            numberOfColumns: 0,
            numberOfMessages: 0,
            isDirectional: false,
            marker: this.$store.getters.signData.markerId,
            useScaleInMessageSchedule: false,
            scale: 100,
            publish: false,
            markerNoScalePercent: 100,
            markerScale: null,
            markerScaleWidth: 0,
            markerScaleWidthUnit: "in",
            messageAttributes: [],
            repeatingAttributes: [],
            metaAttributes: [],
            emails: users,
            svgFile: null,
          },
        },
      };

      let fd = new FormData();

      fd.append("operations", JSON.stringify(operations));

      fetch("/graphql/", {
        method: "POST",
        body: fd,
      })
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
        });
    },
    /**
     * close the modal
     * @param {Event} e - the event that triggered the close
     */
    closeModal: function (e: Event) {
      e.preventDefault();
      e.stopPropagation();
      this.$store.commit("setShowAddSignTypeModal", false);
    },
  },
  computed: {},
  watch: {
    /**
     * when showAddSignTypeModal from the store changes
     * @param {boolean} newValue - the value of showAddSignTypeModal from the store
     */
    "$store.state.signDesigner.showAddSignTypeModal": function (
      newValue: boolean
    ) {
      //   const this = this;
      this.showModal = newValue;
    },
  },
});
