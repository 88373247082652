

































































































































































































































































import Vue from "vue";
import CollapsibleSection from "../CollapsibleSection.vue";

import { IAlignment, IAlignmentTarget } from "../../types";

import {
  updateSVGDataName,
  buildAlignmentString,
  buildRepeatAlignmentStrings,
} from "@/features/SignDesigner/utilities";
import {
  normalCaseToUnderscore,
  capitalizeWord,
} from "@/features/SignDesigner/include/Utils";

export default Vue.extend({
  components: { CollapsibleSection },
  data: function () {
    return {
      nextAlignment: 1,
    };
  },
  methods: {
    getAlignmentIcon: function (alignmentName: string) {
      switch (alignmentName.toLowerCase()) {
        case "top": {
          return "fa-objects-align-top";
        }
        case "bottom": {
          return "fa-objects-align-bottom";
        }
        case "middle": {
          return "fa-objects-align-center-vertical";
        }
        case "left": {
          return "fa-objects-align-left";
        }
        case "right": {
          return "fa-objects-align-right";
        }
        case "center": {
          return "fa-objects-align-center-horizontal";
        }
      }
    },
    selectTarget: function (target: IAlignmentTarget) {
      this.$store.commit("selectTargetAlignmentByUUID", target.uuid);
    },
    editAlignment: function (target: IAlignmentTarget) {
      this.$store.commit("selectTargetAlignmentByUUID", target.uuid);
      this.$store.commit("setShowAddAlignmentModal", true);
    },
    /**
     * Delete a target alignment
     * @param {string} uuid - the uuid of the target to delete
     */
    deleteTarget: function (uuid: string) {
      const alignment = this.$store.getters.selectedAlignment;
      let targetIndex = -1;
      for (let i = 0; i < alignment.targets.length; i++) {
        if (alignment.targets[i].uuid === uuid) {
          targetIndex = i;
          break;
        }
      }
      // update the SVGs alignment string
      if (alignment.targets[targetIndex].row === "current") {
        updateSVGDataName(
          alignment.elementIds[0],
          this.$store.getters.selectedField
            ? this.$store.getters.selectedField.type
            : "",
          buildAlignmentString(
            alignment.targets[targetIndex].sourceAlignment,
            alignment.targets[targetIndex].targetAlignment,
            normalCaseToUnderscore(alignment.targets[targetIndex].field),
            alignment.targets[targetIndex].offset
          ),
          "",
          this.$store.getters.canvas
        );
      } else {
        // this is a repeat alignment
        this.updateRepeatAlignment(alignment.elementIds[0]);
      }
      this.$store.commit("deleteAlignmentTargetByUUID", uuid);

      // update the code editor with the latest changes
      this.$store.commit("refreshCodeEditor");
    },
    /**
     * Display the Add Alignment modal
     */
    addAlignment: function () {
      // deselect all alignments
      // this.$store.commit("deselectAllAlignments");
      this.$store.commit("deselectAllAlignmentTargets");
      this.$store.commit("setShowAddAlignmentModal", true);
    },
    /**
     * Delete the selected alignment
     */
    deleteAlignment: function () {
      const alignment = this.$store.getters.selectedAlignment;

      // update the SVGs alignment string
      if (alignment.targetRow === "current") {
        // this is not a repeat alignment
        updateSVGDataName(
          alignment.elementIds[0],
          this.$store.getters.selectedField
            ? this.$store.getters.selectedField.type
            : "",
          buildAlignmentString(
            alignment.sourceAlignment,
            alignment.targetAlignment,
            normalCaseToUnderscore(alignment.targetField),
            alignment.offset
          ),
          "",
          this.$store.getters.canvas
        );
      } else {
        // this is a repeat alignment
        this.updateRepeatAlignment(alignment.elementIds[0]);
      }
      this.$store.commit("deleteSelectedAlignment");
      // update the code editor with the latest changes
      this.$store.commit("refreshCodeEditor");
    },
    /**
     * Update the repeat alignments string
     * @param {string} elementId - the element to update
     */
    updateRepeatAlignment: function (elementId: string) {
      // make sure Vue has updated the store before continuing
      Vue.nextTick(() => {
        //1. get all repeats that target previous rows
        //2. order all the source alignments together
        //3. order all the destination alignments together
        //4. process them all
        if (
          this.$store.getters.selectedAlignment &&
          this.$store.getters.selectedAlignment.targets &&
          this.$store.getters.selectedAlignment.targets.length > 0
        ) {
          let previousRowAlignments: Array<IAlignmentTarget> =
            this.$store.getters.selectedAlignment.targets.filter(
              (target: IAlignmentTarget) => {
                return target.row === "previous";
              }
            );
          // order all the source alignments together
          // order all the destination alignments together
          previousRowAlignments.sort(
            (a, b) =>
              a.sourceAlignment.localeCompare(b.sourceAlignment) ||
              a.targetAlignment.localeCompare(b.targetAlignment)
          );

          let alignmentStrings = [] as Array<string>;
          if (previousRowAlignments.length > 0) {
            alignmentStrings = buildRepeatAlignmentStrings(
              this.$store.getters.selectedAlignment,
              previousRowAlignments
            );
          }
          //update the repeat element
          let repeatId = this.getAncestorRepeat(elementId);
          let el = this.$store.getters.canvas.select(
            "[sa-data-id='" + repeatId + "']"
          );

          el.attr({ "data-name": "repeat " + alignmentStrings.join(",") });
          el.attr({
            id: normalCaseToUnderscore("repeat " + alignmentStrings.join(",")),
          });
        }
        // update the code editor with the latest changes
        this.$store.commit("refreshCodeEditor");
      });
    },
    /**
     * Returns a string with the first word capitalized
     * @param {string} value - the string to capitalize
     * @returns {string} The capitalized string
     */
    capitalized: function (value: string): string {
      return capitalizeWord(value);
    },
    /**
     * Get the first ancestor element that is a repeat
     * @param {string} svgId - the svgId of the svg element to start at
     * @returns {string} the svgId of the first repeat ancestor
     */
    getAncestorRepeat: function (svgId: string): string {
      let el = this.$store.getters.canvas.select(
        "[sa-data-id='" + svgId + "']"
      );
      if (el) {
        let parent = el.parent();
        if (parent) {
          if (parent.attr("data-name")) {
            if (parent.attr("data-name").substring(0, 6) === "repeat") {
              return parent.attr("sa-data-id");
            } else if (parent.parent()) {
              return this.getAncestorRepeat(parent.attr("sa-data-id"));
            }
          } else {
            return this.getAncestorRepeat(parent.attr("sa-data-id"));
          }
        }
      }
      return "";
    },
    /**
     * Returns the target element that matches the selected target field
     * or if none found then returns the selected alignment
     * @returns {IAlignment} The found alignment
     */
    getTargetAlignment: function (): IAlignment {
      // for (let i = 0; i < this.$store.getters.selectedAlignments.length; i++) {
      //   let targetAlignment = this.$store.getters.selectedAlignments[i];
      //   if (targetAlignment.targetField === this.selectedTarget) {
      //     return targetAlignment;
      //   }
      // }
      // return this.$store.getters.selectedAlignments[0];
      return this.$store.getters.selectedAlignment;
    },
    /**
     * Returns the index of the target element that matches the selected target field
     * @returns {number} The index of found alignment
     */
    getTargetAlignmentIndex: function (): number {
      for (
        let i = 0;
        i < this.$store.getters.selectedAlignment.targets.length;
        i++
      ) {
        let targetAlignment = this.$store.getters.selectedAlignment.targets[i];
        if (targetAlignment.isSelected) {
          return i;
        }
      }
      // return this.$store.getters.selectedAlignments[0];
      return -1;
    },
    /**
     * update the target alignment
     */
    updateTargetAlignment: function (alignment: IAlignment, event: Event) {
      let ta = JSON.parse(JSON.stringify(alignment));
      let value = (event.target as HTMLInputElement).value;
      if (ta.targetRow === "current") {
        updateSVGDataName(
          ta.elementIds[0],
          this.$store.getters.selectedField
            ? this.$store.getters.selectedField.type
            : "",
          buildAlignmentString(
            ta.sourceAlignment,
            ta.targetAlignment,
            normalCaseToUnderscore(ta.targetField),
            ta.offset
          ),
          buildAlignmentString(
            ta.sourceAlignment,
            value,
            normalCaseToUnderscore(ta.targetField),
            ta.offset
          ),
          this.$store.getters.canvas
        );
      } else {
        // this is a repeat alignment
        this.updateRepeatAlignment(ta.elementIds[0]);
      }
      this.$store.commit("updateTargetAlignmentTargetAlignment", {
        targetField: ta.targetField,
        targetAlignment: value,
      });
    },
  },
  computed: {
    selectedTarget: function () {
      const alignment = this.$store.getters.selectedAlignment;
      for (let i = 0; i < alignment.targets.length; i++) {
        if (alignment.targets[i].isSelected) {
          return alignment.targets[i];
        }
      }
      // we don't have a selected target so make the first target selected
      // this.$store.commit(
      //   "selectTargetAlignmentByUUID",
      //   alignment.targets[0].uuid
      // );
      return alignment.targets[0];
    },
    /**
     * do we have current row alignments
     */
    haveCurrentRows: function () {
      for (
        let i = 0;
        i < this.$store.getters.selectedAlignment.targets.length;
        i++
      ) {
        if (
          this.$store.getters.selectedAlignment.targets[i].row.toLowerCase() ===
          "current"
        ) {
          return true;
        }
      }
      return false;
    },
    /**
     * do we have previous row alignments
     */
    havePreviousRows: function () {
      for (
        let i = 0;
        i < this.$store.getters.selectedAlignment.targets.length;
        i++
      ) {
        if (
          this.$store.getters.selectedAlignment.targets[i].row.toLowerCase() ===
          "previous"
        ) {
          return true;
        }
      }
      return false;
    },
    /**
     * Should we show the expand/collapse caret
     * @returns {boolean}
     */
    showCaret: function (): boolean {
      return this.$store.getters.isAlignmentSelected;
    },
    /**
     * Return the target alignment options for the selected source alignment
     * @returns {Array<{value:string, text:string}>} The list of available options
     */
    targetOptions: function () {
      let selectedTarget = this.selectedTarget();
      let sourceAlignment = selectedTarget.sourceAlignment.toLowerCase();
      if (
        sourceAlignment === "top" ||
        sourceAlignment === "middle" ||
        sourceAlignment === "bottom"
      ) {
        return [
          {
            value: "top",
            text: "Top",
          },
          {
            value: "middle",
            text: "Middle",
          },
          {
            value: "bottom",
            text: "Bottom",
          },
        ];
      } else {
        return [
          { value: "left", text: "Left" },
          { value: "center", text: "Center" },
          { value: "right", text: "Right" },
        ];
      }
    },
    /**
     * Should we show the plus sign for adding alignments
     * @returns {boolean}
     */
    showPlusSign: function () {
      // we must have at least one element selected to show the plus sign
      if (this.$store.getters.selectedElementIds.length > 0) {
        // if we are in a repeat then we can have multiple alignments
        if (
          this.$store.getters.selectedAlignment &&
          this.$store.getters.selectedAlignment.isInRepeat
        ) {
          return true;
        } else {
          // if we aren't in a repeat we can only have one target alignment
          // so don't show the plus sign if there is a selected alignment
          if (this.$store.getters.selectedAlignment) {
            return false;
          } else {
            // if we are not in a repeat and we have less than two fields defined
            // then we can't add an alignment (you need at least 2 fields to make an alignment)
            // so return false
            if (this.$store.getters.usedFields.length < 2) {
              return false;
            } else {
              return true;
            }
          }
        }
      } else {
        return false;
      }
    },
    /**
     * Should the section be shown open
     * @returns {boolean}
     */
    openSection: function () {
      // if we have a selected alignment then return true
      if (this.$store.getters.selectedAlignment) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * Getter/Setter for the sourceField
     */
    sourceField: {
      get() {
        // return the source field
        return this.$store.getters.selectedAlignment.sourceField;
      },
      set(value: string) {
        // commit the source field to the store
        this.$store.commit("updateSelectedAlignmentSourceField", value);
      },
    },
  },
  watch: {
    "$store.state.signDesigner.showAddAlignmentModal": function (
      newValue: boolean
    ) {
      if (!newValue) {
        if (this.$store.getters.selectedAlignment) {
          this.$store.commit(
            "selectAlignmentTargetByIndex",
            this.$store.getters.selectedAlignment.targets.length - 1
          );
        }
      }
    },
    // "$store.state.signDesigner.alignments": function (newValue) {
    //   // if alignments have changed then we need to set the selectedTarget to the
    //   // first selected alignment in the list.
    //   for (let i = 0; i < newValue.length; i++) {
    //     if (newValue[i].isSelected) {
    //       //selectedTarget = newValue[i].targetField;
    //       break;
    //     }
    //   }
    // },
  },
});
