
























































































































import Vue from "vue";

//import { Matthews } from "../types";
//import { Compact } from "vue-color"
import hexcodes from "../../assets/hexcodes.json";
import matthews from "../../assets/matthews.json";

import { iColor, iHexCode, iMatthews } from "../../types";
import { rgbToHex } from "../../utilities";

// import matthews from "./assets/matthews.json";

export default Vue.extend({
  components: {},
  data: function () {
    return {
      showModal: false,
      searchText: "",
      selectedLibrary: "hexcodes",
      selectedColors: [] as Array<iColor>,
      colorLibraries: [
        { id: 0, name: "Custom" },
        { id: 1, name: "Matthews Paint" },
        { id: 2, name: "Pantone PMS" },
        { id: 3, name: "Rowmark" },
      ],
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showColorsModal;
  },
  methods: {
    /**
     * return a css string with a hash character prepended (if needed) so that it can be used as a backgroundColor
     * @param {string} color - a css hex color string (i.e. "#0176bc" or "0176bc")
     * @returns {string} the hex string with the hash character prepended if needed
     */
    hexString(color: string) {
      if (color) {
        if (color.charAt(0) !== "#") {
          color = "#" + color;
        }
      }
      return color;
    },
    /**
     * Add the clicked on color to the selected colors list
     * @param {iColor && iHexCode && iMatthews} color - the clicked on color
     */
    addToSelectedColors(color: iColor & iHexCode & iMatthews) {
      let newColor = {} as iColor;

      if (this.selectedLibrary === "hexcodes") {
        newColor.id = color.id;
        newColor.name = color.name;
        newColor.hex = color.hex;
      } else if (this.selectedLibrary === "matthews") {
        newColor.id = color.id;
        newColor.name = color.name;
        newColor.hex = rgbToHex(color.r, color.g, color.b);
      }
      this.selectedColors.push(newColor);
    },
    /**
     * remove the clicked on color from the selected colors list
     * @param {number} color_index - the selected colors list index
     */
    removeFromSelectedColors(color_index: number) {
      this.selectedColors.splice(color_index, 1);
    },
    /**
     * save changes
     */
    saveChanges: function () {
      //close the window

      this.selectedColors.forEach((color: iColor): void => {
        this.$store.commit("addColor", color);
      });
      this.$store.commit("setShowAddColorsModal", false);
    },
    /**
     * close the modal
     * @param {Event} e - the event that triggered the close
     */
    closeModal: function (e: Event) {
      e.preventDefault();
      e.stopPropagation();
      this.$store.commit("setShowAddColorsModal", false);
    },
    /**
     * return the hexstring of a set of r, g, b colors
     * @param {number} r - the red color value
     * @param {number} g - the green color value
     * @param {number} b - the blue color value
     * @returns {string} a hex code string with the leading hash (i.e., #0176bc)
     */
    getRGBToHex: function (r: number, g: number, b: number): string {
      return rgbToHex(r, g, b);
    },
  },
  computed: {
    /**
     * return the colors for the selected color library
     */
    getLibraryColors: function () {
      if (this.selectedLibrary === "hexcodes") {
        if (this.searchText === "") {
          return hexcodes;
        } else {
          return hexcodes.filter((hexcode): boolean => {
            return hexcode.name
              .toLowerCase()
              .includes(this.searchText.toLowerCase());
          });
        }
      } else if (this.selectedLibrary === "matthews") {
        if (this.searchText === "") {
          return matthews;
        } else {
          return matthews.filter((item): boolean => {
            return item.name
              .toLowerCase()
              .includes(this.searchText.toLowerCase());
          });
        }
      }
      return [];
    },
  },
  watch: {
    /**
     * when the showAddColorsModal in the store changes
     * @param {boolean} newValue - the value of the showAddColorsModal from the store
     */
    "$store.state.signDesigner.showAddColorsModal": function (newValue) {
      this.showModal = newValue;
    },
  },
});
