





























































import Vue from "vue";
import $ from "jquery";

import OrderSummaryPanel from "../components/OrderSummaryPanel.vue";
import { IOrderSignType } from "../types";

export default Vue.extend({
  components: { OrderSummaryPanel },
  props: {
    showModal: Boolean,
  },
  data: function () {
    return {
      isChecked: false,
      isExpanded: false,
      allowZeroPrices: false,
      displayZeroPricePage: true,
    };
  },
  methods: {
    /**
     * should the Confirm button be disabled
     * @return boolean - true if the button should be disabled
     */
    disableConfirmButton: function (): boolean {
      // always allow the confirm button - this may change in the future
      return false;
    },
    /**
     * test to see if all sign types have an install price and actual price
     * @returns boolean - true if all signs types have prices
     */
    allSignTypesHavePrices: function () {
      let result = true;
      if (this.$store.getters.order) {
        this.$store.getters.order.signTypes.forEach(
          (signType: IOrderSignType) => {
            if (
              this.$store.getters.order.IncludeBuild &&
              Number(signType.actual_price) === 0
            ) {
              result = false;
            }

            if (
              this.$store.getters.order.includeInstall &&
              Number(signType.install_price) === 0
            ) {
              result = false;
            }
          }
        );
      }

      return result;
    },
    /**
     * confirm buttom is clicked.
     */
    confirmClicked: function () {
      // if all signs has prices and the confirmation checkbox is clicked - submit the order
      if (this.allSignTypesHavePrices()) {
        this.$emit("submitClicked");
      } else {
        // otherwise we are on the missing prices warning and the user is ok with zero prices
        // we set allowZeroPrices to true which will display the Order Confirmation info
        this.allowZeroPrices = true;
        this.displayZeroPricePage = false;
      }
    },
    /**
     * close the modal
     */
    closeModal: function () {
      this.$emit("closeClicked");
      this.allowZeroPrices = false;
    },
  },
  watch: {
    /**
     * watch the showModal property
     * @param newVal boolean - true if we are shoing the modal
     */
    showModal: function (newVal) {
      //if we are displaying the modal
      if (newVal) {
        $("#submitReviewConfirmModal").on("hidden.bs.modal", () => {
          this.closeModal();
        });
        $("#submitReviewConfirmModal").modal("show");
        // determine if all signs have prices
        if (this.allSignTypesHavePrices()) {
          // if they do then don't display the zero price warning page
          this.displayZeroPricePage = false;
        } else {
          // otherwise some sign types have a zero price so display the zero price warning page
          this.displayZeroPricePage = true;
        }
      } else {
        $("#submitReviewConfirmModal").off("hidden.bs.modal");
        $("#submitReviewConfirmModal").modal("hide");
      }
    },
  },
});
