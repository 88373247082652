

















































































































import { IProductCategory, IProductType } from "@/features/SignDesigner/types";
import Vue from "vue";

export default Vue.extend({
  data: function () {
    return {};
  },
  methods: {
    categoryClicked: function (category: IProductCategory) {
      this.$store.commit("selectProductCategoryByUUID", category);
    },
    typeClicked: function (type: IProductType) {
      this.$store.commit("selectProductTypeByUUID", type);
    },
  },
  computed: {
    familyCount: function () {
      return this.$store.getters.productFamilies.length;
    },
    categoryCount: function () {
      return this.$store.getters.productCategories.length;
    },
    typeCount: function () {
      return this.$store.getters.productTypes.length;
    },
    itemCount: function () {
      return this.$store.getters.catalog.length;
    },
  },
  watch: {},
});
