export interface ModelPermissions {
  readonly name: string;
  readonly modalTitle: string;
  readonly extraQuery: string;
  readonly sectionTitle?: (
    contentObject: Record<string, unknown>
  ) => string | null;
  readonly getUrl?: (contentObject: Record<string, unknown>) => string | null;
  readonly inheritsPermsOf: Readonly<ModelKey[]>;
  readonly permissions: ObjectPermissions;
}

export type ObjectPermissions = Map<string, ObjectPermission>;

export interface ObjectPermission {
  readonly name: string;
  readonly preposition?: string; // default is "of", "to" is an alternative
  readonly info: string;
}

export type ModelKey =
  | "color"
  | "document"
  | "field"
  | "font"
  // | "location"
  | "organization"
  | "project"
  | "sign"
  | "signType"
  | "state"
  | "translationLanguage"
  | "visual";

/**
 * Test if a given string is a ModelKey
 *
 * @param testKey - the string to test against ModelKey
 * @returns true if testKey is a ModelKey, false otherwise
 */
export function isModelKey(testKey: string | number): testKey is ModelKey {
  return Object.keys(MODELS).includes(String(testKey));
}

export interface UserPerm {
  readonly userId: null | string;
  readonly email: string;
  readonly firstName: null | string;
  readonly lastName: null | string;
  readonly imageUrl: null | string;
  original_permission: null | string;
  permission: null | string;
  error: null | string;
}

export interface PermObject {
  object: Record<string, unknown>;
  objectType: ModelKey;
  objectId: string;
  groups: string[];
  canEdit: boolean;
  name: string | null; // from MODELS...sectionTitle()
  url: string | null;
  userPerms: UserPerm[];
}

export interface SearchResult {
  id: null | string;
  firstName: null | string;
  lastName: null | string;
  email: string;
  imageUrl: null | string;
}

export const MODELS: Readonly<{ [key in ModelKey]: ModelPermissions }> = {
  color: {
    name: "Color",
    modalTitle: "Color Permissions",
    extraQuery: "... on ColorNode { canEdit, editUrl }",
    getUrl: (contentObject: { canEdit?: unknown; editUrl?: unknown }) =>
      contentObject.canEdit && contentObject.editUrl
        ? String(contentObject.editUrl)
        : null,
    inheritsPermsOf: ["organization"],
    permissions: new Map([
      [
        "editor",
        {
          name: "Editor",
          info: "Has permission to edit this color.",
        },
      ],
    ]),
  },

  document: {
    // Internal Name: MessageSchedule
    name: "Document",
    modalTitle: "Document Permissions",
    extraQuery: "... on DocumentNode { canEdit, editUrl }",
    getUrl: (contentObject: { canEdit?: unknown; editUrl?: unknown }) =>
      contentObject.canEdit && contentObject.editUrl
        ? String(contentObject.editUrl)
        : null,
    inheritsPermsOf: ["organization"],
    permissions: new Map([
      [
        "editor",
        {
          name: "Editor",
          info: "Has permission to edit this document.",
        },
      ],
    ]),
  },

  field: {
    // Internal Name: SignAttribute
    name: "Field",
    modalTitle: "Field Permissions",
    extraQuery: "... on FieldNode { canEdit, editUrl }",
    getUrl: (contentObject: { canEdit?: unknown; editUrl?: unknown }) =>
      contentObject.canEdit && contentObject.editUrl
        ? String(contentObject.editUrl)
        : null,
    inheritsPermsOf: ["organization"],
    permissions: new Map([
      [
        "editor",
        {
          name: "Editor",
          info: "Has permission to edit this field.",
        },
      ],
    ]),
  },

  font: {
    name: "Font",
    modalTitle: "Font Permissions",
    extraQuery: "... on FontNode { canEdit, editUrl }",
    getUrl: (contentObject: { canEdit?: unknown; editUrl?: unknown }) =>
      contentObject.canEdit && contentObject.editUrl
        ? String(contentObject.editUrl)
        : null,
    inheritsPermsOf: ["organization"],
    permissions: new Map([
      [
        "editor",
        {
          name: "Editor",
          info: "Has permission to edit this font.",
        },
      ],
    ]),
  },

  // location: {
  //   // Internal Name: Zone
  //   name: "Location",
  //   modalTitle: "Location Permissions",
  //   extraQuery: "... on LocationNode { canEdit, editUrl }",
  //   getUrl: (contentObject: { canEdit?: unknown; editUrl?: unknown }) =>
  //     contentObject.canEdit && contentObject.editUrl
  //       ? String(contentObject.editUrl)
  //       : null,
  //   inheritsPermsOf: ["organization",...],
  //   permissions: new Map([...
  //     [
  //       "editor",
  //       {
  //         name: "Editor",
  //         info: "Has permission to edit this location.",
  //       },
  //     ],
  //   ]),
  // },

  organization: {
    // Internal Name: Project, SignProject
    name: "Organization",
    modalTitle: "Organization Permissions",
    extraQuery: "... on OrganizationNode {canEdit, name, editUrl}",
    sectionTitle: (contentObject: { name?: unknown }) =>
      contentObject.name ? String(contentObject.name) : null,
    getUrl: (contentObject: { canEdit?: unknown; editUrl?: unknown }) =>
      contentObject.canEdit && contentObject.editUrl
        ? String(contentObject.editUrl).replace("/map/", "/edit/")
        : null,
    inheritsPermsOf: [],
    permissions: new Map([
      [
        "admin",
        {
          name: "Admin",
          info: "Has full access to this organization.",
        },
      ],
      [
        "viewer",
        {
          name: "Viewer",
          info: "Has full view access to this organization.",
        },
      ],
    ]),
  },

  project: {
    // Internal name: Phase
    name: "Project",
    modalTitle: "Project Permissions",
    extraQuery: "... on ProjectNode { canEdit, name, editUrl }",
    sectionTitle: (contentObject: { name?: unknown }) =>
      contentObject.name ? String(contentObject.name) : null,
    getUrl: (contentObject: { canEdit?: unknown; editUrl?: unknown }) =>
      contentObject.canEdit && contentObject.editUrl
        ? String(contentObject.editUrl)
        : null,
    inheritsPermsOf: ["organization"],
    permissions: new Map([
      [
        "member",
        {
          name: "Member",
          info: "Can view and edit signs in this project.",
        },
      ],
      [
        "viewer",
        {
          name: "Viewer",
          info: "Can view signs in this project",
        },
      ],
    ]),
  },

  sign: {
    name: "Sign",
    modalTitle: "Sign Permissions",
    extraQuery: "... on SignNode { canEdit, editUrl, shortCode }",
    sectionTitle: (contentObject: { shortCode?: unknown }) =>
      contentObject.shortCode ? String(contentObject.shortCode) : null,
    getUrl: (contentObject: { canEdit?: unknown; editUrl?: unknown }) =>
      contentObject.canEdit && contentObject.editUrl
        ? String(contentObject.editUrl)
        : null,
    inheritsPermsOf: ["organization", "project", "state"],
    permissions: new Map([
      [
        "editor",
        {
          name: "Editor",
          info: "Has direct permission to edit this sign.",
        },
      ],
      [
        "assignedEditor",
        {
          name: "Editor, Assigned",
          preposition: "to",
          info: "Has direct permission to edit and review this sign.",
        },
      ],
      [
        "assigned",
        {
          name: "Assigned",
          preposition: "to",
          info: "Has direct permission to review this sign.",
        },
      ],
      [
        "viewer",
        {
          name: "Viewer",
          info: "Has direct permission to view this sign.",
        },
      ],
    ]),
  },

  signType: {
    // Internal Name: SignTemplate
    name: "Sign Type",
    modalTitle: "Sign Type Permissions",
    extraQuery: "... on SignTypeNode { canEdit, editUrl }",
    getUrl: (contentObject: { canEdit?: unknown; editUrl?: unknown }) =>
      contentObject.canEdit && contentObject.editUrl
        ? String(contentObject.editUrl)
        : null,
    inheritsPermsOf: ["organization"],
    permissions: new Map([
      [
        "editor",
        {
          name: "Editor",
          info: "Has permission to edit this sign type.",
        },
      ],
    ]),
  },

  state: {
    name: "State",
    modalTitle: "State Permissions",
    extraQuery: "... on StateNode { canEdit, name, editUrl }",
    sectionTitle: (contentObject: { name?: unknown }) =>
      contentObject.name ? String(contentObject.name) : null,
    getUrl: (contentObject: { canEdit?: unknown; editUrl?: unknown }) =>
      contentObject.canEdit && contentObject.editUrl
        ? String(contentObject.editUrl)
        : null,
    inheritsPermsOf: ["organization", "project"],
    permissions: new Map([
      [
        "assigned",
        {
          name: "Assigned",
          preposition: "to",
          info: "Can view signs in this state and perform state actions.",
        },
      ],
    ]),
  },

  translationLanguage: {
    name: "Language",
    modalTitle: "Language Permissions",
    extraQuery: "... on TranslationLanguageNode { canEdit, editUrl }",
    getUrl: (contentObject: { canEdit?: unknown; editUrl?: unknown }) =>
      contentObject.canEdit && contentObject.editUrl
        ? String(contentObject.editUrl)
        : null,
    inheritsPermsOf: ["organization"],
    permissions: new Map([
      [
        "editor",
        {
          name: "Editor",
          info: "Has permission to edit this language.",
        },
      ],
    ]),
  },

  visual: {
    // Internal Name: Icon
    name: "Visual",
    modalTitle: "Visual Permissions",
    extraQuery: "... on VisualNode { canEdit, editUrl }",
    getUrl: (contentObject: { canEdit?: unknown; editUrl?: unknown }) =>
      contentObject.canEdit && contentObject.editUrl
        ? String(contentObject.editUrl)
        : null,
    inheritsPermsOf: ["organization"],
    permissions: new Map([
      [
        "editor",
        {
          name: "Editor",
          info: "Has permission to edit this visual.",
        },
      ],
    ]),
  },
} as const;
