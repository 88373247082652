































import Vue from "vue";

export default Vue.extend({
  components: {},
  props: {
    title: {
      type: String,
      default: "Confirmation",
    },
    body: {
      type: String,
    },
  },
  data: function () {
    return {
      showModal: false,
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showSignTypesConfirmModal;
  },
  methods: {
    /**
     * yes clicked
     */
    yesClicked: function () {
      this.$emit("yesClicked");
      this.$store.commit("setShowSignTypesConfirmModal", false);
    },
    /**
     * close the modal
     * @param {Event} e - the event that triggered the close
     */
    noClicked: function () {
      this.$emit("noClicked");
      this.$store.commit("setShowSignTypesConfirmModal", false);
    },
  },
  computed: {},
  watch: {
    /**
     * when showSignTypesConfirmModal from the store changes
     * @param {boolean} newValue - the value of the showSignTypesConfirmModal from the store
     */
    "$store.state.signDesigner.showSignTypesConfirmModal": function (
      newValue: boolean
    ) {
      //   const this = this;
      this.showModal = newValue;
    },
  },
});
