

































































import Vue from "vue";

export default Vue.extend({
  props: {
    showModal: {
      type: Boolean,
    },
  },
  data: function () {
    return {};
  },
  mounted: function () {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.closeModal();
      }
    });
  },
  methods: {
    closeModal: function () {
      this.$emit("cancel");
    },
    saveChanges: function () {
      this.$emit("confirm");
    },
  },
  watch: {},
});
