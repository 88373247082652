




























































import Vue from "vue";
import { IFeature } from "../../types";

export default Vue.extend({
  components: {},
  props: {},
  data: function () {
    return {
      showModal: false,
      selectedFeature: "",
      userEmail: "",
      currUserEmail: "",
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showFeatureAccessModal;
  },
  methods: {
    /**
     * yes clicked
     */
    acceptClicked: function () {
      // ### perform accept processing here ###
      //close the modal
      this.$store.commit("setShowFeatureAccessModal", false);
    },
    /**
     * close the modal
     * @param {Event} e - the event that triggered the close
     */
    closeClicked: function () {
      // close the modal
      this.$store.commit("setShowFeatureAccessModal", false);
    },
    /**
     * tool icon clicked
     */
    whenClicked: function () {
      this.$store.commit(
        "setShowFeatureAccessModal",
        !this.$store.getters.showFeatureAccessModal
      );
    },
    addUser: function () {
      const newUser: IFeature = {
        feature: this.selectedFeature,
        user: this.userEmail,
      };
      this.$store.commit("addFeatureAccess", newUser);
    },
    removeUser: function () {
      const newUser: IFeature = {
        feature: this.selectedFeature,
        user: this.currUserEmail,
      };
      this.$store.commit("removeFeatureAccess", newUser);
    },
  },
  computed: {},
  watch: {
    /**
     * when showConfirmModal from the store changes
     * @param {boolean} newValue - the value of the showConfirmModal from the store
     */
    "$store.state.signDesigner.showFeatureAccessModal": function (
      newValue: boolean
    ) {
      //   const this = this;
      this.showModal = newValue;
    },
  },
});
