
















































































import { IQuoter } from "../types";
import Vue from "vue";

export default Vue.extend({
  components: {},
  props: {
    item: Object,
  },
  data: function () {
    return {
      showModal: false,
      modalEmail: "",
      quoters: [
        {
          id: 1,
          email: "bernie@signagent.com",
          sentAt: "Nov 19, 2022",
        },
      ],
    };
  },
  methods: {
    removeQuoter: function (quoter: IQuoter) {
      for (let i = 0; i < this.quoters.length; i++) {
        if (this.quoters[i].id === quoter.id) {
          this.quoters.splice(i, 1);
        }
      }
    },
    addQuoter() {
      this.quoters.push({
        id: this.quoters.length + 1,
        email: this.modalEmail,
        sentAt: "",
      });
      this.modalEmail = "";
    },
    closeModal: function () {
      this.showModal = false;
      this.$store.commit("setShowInviteQuotersModal", false);
    },
  },
  computed: {},
  watch: {
    "$store.state.showInviteQuotersModal": function (newValue) {
      //   const this = this;
      this.showModal = newValue;
    },
  },
});
