var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('collapsible-section',{attrs:{"title":'Field',"initiallyOpen":false,"showPlusSign":_vm.showPlusSign,"showTrash":false,"open":_vm.openSection,"showCaret":_vm.showCaret,"isOpenable":_vm.isOpenable},on:{"plusClicked":_vm.addField,"trashClicked":_vm.deleteField}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.plusWasClicked),expression:"plusWasClicked"}]},[(_vm.showFieldChooser)?_c('div',[(
          _vm.fieldType === 'text' ||
          _vm.fieldType === 'char' ||
          _vm.fieldType === 'char_x' ||
          _vm.fieldType === 'trans' ||
          _vm.fieldType === 'date'
        )?_c('div',{staticStyle:{"display":"flex","flex-direction":"column","margin-bottom":"5px"}},[_c('div',{staticStyle:{"margin-bottom":"5px"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldName),expression:"fieldName"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.fieldName=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}},[_vm._v("Choose Field")]),_c('option',{attrs:{"disabled":""}},[_vm._v("──────────")]),_c('option',{attrs:{"value":"new"}},[_vm._v("New Field...")]),_c('option',{attrs:{"disabled":""}},[_vm._v("──────────")]),_vm._l((_vm.availableTextFields),function(field){return _c('option',{key:field.id},[_vm._v(" "+_vm._s(field.name)+" ")])})],2)])]):(
          _vm.fieldType === 'icon' ||
          _vm.fieldType === 'icon_t' ||
          _vm.fieldType === 'icon_x'
        )?_c('div',{staticStyle:{"display":"flex","flex-direction":"column","margin-bottom":"5px"}},[_c('div',{staticStyle:{"margin-bottom":"5px"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldName),expression:"fieldName"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.fieldName=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":""}},[_vm._v("Choose Field")]),_c('option',{attrs:{"disabled":""}},[_vm._v("──────────")]),_c('option',{attrs:{"value":"new"}},[_vm._v("New Field...")]),_c('option',{attrs:{"disabled":""}},[_vm._v("──────────")]),_vm._l((_vm.availableVisualFields),function(field){return _c('option',{key:field.id,attrs:{"disabled":field.type.toLowerCase().startsWith('color')}},[_vm._v(" "+_vm._s(field.name)+" ")])})],2)])]):(
          _vm.fieldType === 'color' ||
          _vm.fieldType === 'color_x' ||
          _vm.fieldType === 'color_t'
        )?_c('div',{staticStyle:{"display":"flex","flex-direction":"column","margin-bottom":"5px"}},[_c('div',{staticStyle:{"margin-bottom":"5px"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.fieldName),expression:"fieldName"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.fieldName=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.availableVisualFields),function(field){return _c('option',{key:field.id,attrs:{"disabled":field.type.toLowerCase().startsWith('color')}},[_vm._v(" "+_vm._s(field.name)+" ")])}),0)])]):_vm._e()]):_vm._e(),(!_vm.showFieldChooser)?_c('div',[(
          _vm.fieldType === 'text' ||
          _vm.fieldType === 'char' ||
          _vm.fieldType === 'char_x' ||
          _vm.fieldType === 'trans' ||
          _vm.fieldType === 'date'
        )?_c('div',{staticStyle:{"display":"flex","flex-direction":"column","margin-bottom":"5px"}},[_c('div',{staticStyle:{"margin-bottom":"5px"}},[_c('label',[_vm._v("Name")]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticStyle:{"flex-grow":"1","margin-right":"5px"}},[_c('input',{staticClass:"form-control",attrs:{"disabled":""},domProps:{"value":_vm.fieldName}})]),_c('i',{staticClass:"fas fa-trash",on:{"click":_vm.deleteField}})])]),_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Placeholder Text")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.placeholderText),expression:"placeholderText"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.placeholderText)},on:{"input":function($event){if($event.target.composing){ return; }_vm.placeholderText=$event.target.value}}})])]):(
          _vm.fieldType === 'icon' ||
          _vm.fieldType === 'icon_t' ||
          _vm.fieldType === 'icon_x'
        )?_c('div',{staticStyle:{"display":"flex","flex-direction":"column","margin-bottom":"5px"}},[_c('div',{staticStyle:{"margin-bottom":"5px"}},[_c('b',[_vm._v("Name")]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticStyle:{"flex-grow":"1","margin-right":"5px"}},[_c('input',{staticClass:"form-control",attrs:{"disabled":""},domProps:{"value":_vm.fieldName}})]),_c('i',{staticClass:"fas fa-trash",on:{"click":_vm.deleteField}})])]),_c('div',{staticStyle:{"margin-bottom":"5px"}},[_c('label',{staticStyle:{"margin-right":"10px"}},[_vm._v("Placeholder Image")]),_c('div',{staticStyle:{"display":"flex"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hasPlaceholderImage),expression:"hasPlaceholderImage"}],staticStyle:{"margin-right":"10px"},attrs:{"type":"radio"},domProps:{"value":false,"checked":_vm._q(_vm.hasPlaceholderImage,false)},on:{"change":function($event){_vm.hasPlaceholderImage=false}}}),_vm._v(" None ")]),_c('div',{staticStyle:{"display":"flex"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.hasPlaceholderImage),expression:"hasPlaceholderImage"}],staticStyle:{"margin-right":"10px"},attrs:{"type":"radio"},domProps:{"value":true,"checked":_vm._q(_vm.hasPlaceholderImage,true)},on:{"change":function($event){_vm.hasPlaceholderImage=true}}}),_c('visual-select',{attrs:{"selectedImage":_vm.placeholderImageUrl,"enabled":_vm.hasPlaceholderImage},model:{value:(_vm.placeholderImageUrl),callback:function ($$v) {_vm.placeholderImageUrl=$$v},expression:"placeholderImageUrl"}})],1)])]):(
          _vm.fieldType === 'color' ||
          _vm.fieldType === 'color_x' ||
          _vm.fieldType === 'color_t'
        )?_c('div',{staticStyle:{"display":"flex","flex-direction":"column","margin-bottom":"5px"}},[_c('div',{staticStyle:{"margin-bottom":"5px"}},[_c('b',[_vm._v("Name")]),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticStyle:{"flex-grow":"1","margin-right":"5px"}},[_c('input',{staticClass:"form-control",attrs:{"disabled":""},domProps:{"value":_vm.fieldName}})]),_c('i',{staticClass:"fas fa-trash",on:{"click":_vm.deleteField}})])])]):_vm._e()]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }