















































































import Vue from "vue";
import PaymentInfoTile from "../components/PaymentInfoTile.vue";

export default Vue.extend({
  components: { PaymentInfoTile },
  data: function () {
    return {
      user: {
        firstName: "Bern",
        lastName: "BillingOwner",
      },
      companyId: this.$route.params.id,
      billingOwnerId: 0,
      paymentMethods: [],
      last5Transactions: [],
      inGoodStanding: false,
      subscriptionIds: [] as Array<string>,
    };
  },
  mounted: function () {
    this.fetchData();
  },
  methods: {
    retryTransaction: function (subscriptionId: string) {
      const query = JSON.stringify({
        query: `mutation retryTransaction {
          retryFailedSubscriptionPayment(input: {id: "${subscriptionId}"}) {
            success
          }
        }
        `,
      });

      alert(query);
    },
    cardAdded: function () {
      // refresh the payment methods
      this.fetchData();
    },
    fetchData: function () {
      const query = JSON.stringify({
        query: `query get_billing_info{
                        company(id: ${this.companyId}) {
                          billingOwner {
                            id: userId
                          }
                          billing {
                            paymentMethods {
                              cardType:brand
                              last4
                              default
                              expYear
                              expMonth
                              stripeId
                            }
                          }
                          last5Transactions {
                            id
                            created
                            status
                            amount
                          }
                          subscriptions {
                            edges {
                              node {
                                id: contentObjectId
                                stripeSubscriptionId
                              }
                            }
                          }
                        }
                      }`,
      });
      fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((result) => result.json())
        .then((data) => {
          console.log(data);
          this.billingOwnerId = data.data.company.billingOwner.id;
          this.paymentMethods = data.data.company.billing.paymentMethods;
          this.subscriptionIds = data.data.company.subscriptions.edges.map(
            (subscription: {
              node: { id: string; subscriptionId: string };
            }) => {
              return subscription.node.subscriptionId;
            }
          );
          this.last5Transactions = data.data.company.last5Transactions.map(
            (transaction: {
              id: string;
              created: number;
              status: string;
              amount: number;
            }) => {
              return {
                id: transaction.id,
                created: new Date(transaction.created * 1000),
                status: transaction.status,
                amount: transaction.amount / 100,
              };
            }
          );

          // get user info so we can determine if they are in good standing
          const query2 = JSON.stringify({
            query: `query get_user_info{
                        user(id: ${this.$store.state.session.userId}) {
                          inGoodStanding
                        }
                      }`,
          });

          fetch("/graphql/", {
            method: "POST",
            body: query2,
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then((result) => result.json())
            .then((data) => {
              this.inGoodStanding = data.data.user.inGoodStanding;
            });
        });
    },
  },
  computed: {
    userIsAdmin: function (): boolean {
      console.log("session user: " + this.$store.state.session.userId);
      return this.billingOwnerId === this.$store.state.session.userId;
    },
  },
});
