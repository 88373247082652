





































































































































































































































































import Vue from "vue";
import { IColorItem } from "../types";
import { hexString } from "../utilities";

// import hexcodes from "../assets/hexcodes.json";

export default Vue.extend({
  components: {},
  props: {
    materialIndex: {
      type: Number,
      required: true,
    },
    libraryIndex: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      allSearch: "",
      includedSearch: "",
      excludedSearch: "",
      pageSize: 100,
      allPage: 1,
      includedPage: 1,
      excludedPage: 1,
      filteredAllColorsLength: 0,
      includedColorsLength: 0,
      excludedColorsLength: 0,
    };
  },
  methods: {
    /**
     * the all items paginator page has changed
     */
    allPageChanged: function (page: number) {
      this.allPage = page;
    },
    /**
     * the included items paginator page has changed
     */
    includedPageChanged: function (page: number) {
      this.includedPage = page;
    },
    /**
     * the excluded items paginator page has changed
     */
    excludedPageChanged: function (page: number) {
      this.excludedPage = page;
    },
    /**
     * get color index
     */
    getColorIndex: function (color: IColorItem): number {
      const colors =
        this.$store.getters.materials[this.materialIndex].libraries[
          this.libraryIndex
        ].colors;

      for (let i = 0; i < colors.length; i++) {
        const colorToCheck: IColorItem = colors[i];
        if (colorToCheck.uuid === color.uuid) {
          return i;
        }
      }

      return -1;
    },
    /**
     * toggle all included colors
     */
    toggleAllIncluded: function () {
      let payload = {
        materialIndex: this.materialIndex,
        libraryIndex: this.libraryIndex,
        colorIndexes: [] as Array<number>,
      };

      for (
        let i = 0;
        i <
        this.$store.getters.materials[this.materialIndex].libraries[
          this.libraryIndex
        ].colors.length;
        i++
      ) {
        const colorToCheck: IColorItem =
          this.$store.getters.materials[this.materialIndex].libraries[
            this.libraryIndex
          ].colors[i];
        if (colorToCheck.included) {
          payload.colorIndexes.push(i);
          // const payload = {
          //   materialIndex: this.materialIndex,
          //   libraryIndex: this.libraryIndex,
          //   colorIndex: i,
          // };
          //this.$store.commit("toggleMaterialColorLibraryColorInclude", payload);
        }
      }
      this.$store.commit("toggleMaterialColorLibraryColorInclude", payload);
    },
    /**
     * toggle all excluded colors
     */
    toggleAllExcluded: function () {
      let payload = {
        materialIndex: this.materialIndex,
        libraryIndex: this.libraryIndex,
        colorIndexes: [] as Array<number>,
      };

      for (
        let i = 0;
        i <
        this.$store.getters.materials[this.materialIndex].libraries[
          this.libraryIndex
        ].colors.length;
        i++
      ) {
        const colorToCheck: IColorItem =
          this.$store.getters.materials[this.materialIndex].libraries[
            this.libraryIndex
          ].colors[i];
        if (!colorToCheck.included) {
          payload.colorIndexes.push(i);
          // const payload = {
          //   materialIndex: this.materialIndex,
          //   libraryIndex: this.libraryIndex,
          //   colorIndex: i,
          // };
          //this.$store.commit("toggleMaterialColorLibraryColorInclude", payload);
        }
      }
      this.$store.commit("toggleMaterialColorLibraryColorInclude", payload);
    },
    /**
     * get the checked status of a color
     * @param {IColorItem} color - the color to check the status of
     * @returns {boolean} true if color is included in library
     */
    getCheckedStatus: function (color: IColorItem): boolean {
      const colors =
        this.$store.getters.materials[this.materialIndex].libraries[
          this.libraryIndex
        ].colors;

      for (let i = 0; i < colors.length; i++) {
        const colorToCheck: IColorItem = colors[i];
        if (colorToCheck.uuid === color.uuid) {
          return colorToCheck.included;
        }
      }
      return false;
    },
    /**
     * toggle color include flag
     * @param {IColorItem} color - the color to toggle
     */
    toggleInclude: function (color: IColorItem) {
      //first find the index of the color
      let colorIndex = this.getColorIndex(color);

      // now we can update the color's include flag
      if (colorIndex > -1) {
        const payload = {
          materialIndex: this.materialIndex,
          libraryIndex: this.libraryIndex,
          colorIndexes: [colorIndex],
        };
        this.$store.commit("toggleMaterialColorLibraryColorInclude", payload);
      }
    },
    /**
     * get the hex string for a hexCode
     * @param {string} hexCode - the hex code string with or without the leading #
     * @returns {string} - the hex code with the leading # included
     */
    getHexString: function (hexCode: string) {
      return hexString(hexCode);
    },
    /**
     * edit the selected color
     * @param {iColorItem} color - the color that was clicked on
     */
    editColor: function (color: IColorItem) {
      const colorIndex = this.getColorIndex(color);

      // now we can update the color's include flag
      if (colorIndex > -1) {
        this.$emit("editColor", colorIndex);
      }
    },
    /**
     * create a color
     */
    createColor: function () {
      const payload = {
        materialIndex: this.materialIndex,
        libraryIndex: this.libraryIndex,
      };
      this.$store.commit("createMaterialColorLibraryColor", payload);
      this.$emit(
        "editColor",
        this.$store.getters.materials[this.materialIndex].libraries[
          this.libraryIndex
        ].colors.length - 1
      );
    },
  },
  computed: {
    /**
     * included colors
     * @returns {Array<IColorItem>} - an array of colors that are included in the library
     */
    includedColors: function (): Array<IColorItem> {
      return this.$store.getters.materials[this.materialIndex].libraries[
        this.libraryIndex
      ].colors.filter((color: IColorItem) => {
        return color.included;
      });
    },
    /**
     * excluded colors
     * @returns {Array<IColorItem>} - an array of colors that are excluded from the library
     */
    excludedColors: function (): Array<IColorItem> {
      return this.$store.getters.materials[this.materialIndex].libraries[
        this.libraryIndex
      ].colors.filter((color: IColorItem) => {
        return !color.included;
      });
    },
    /**
     * filtered all colors
     * @returns {Array<IColorItem>} - an array of all the colors that match the search criteria the user entered
     */
    filteredAllColors: function (): Array<IColorItem> {
      const colors =
        this.$store.getters.materials[this.materialIndex].libraries[
          this.libraryIndex
        ].colors;

      let filteredColors = [];

      if (this.allSearch === "") {
        const start = (this.allPage - 1) * this.pageSize;
        /* eslint-disable-next-line */
        this.filteredAllColorsLength = colors.length;
        return colors.slice(start, start + this.pageSize);
      } else {
        for (let i = 0; i < colors.length; i++) {
          if (
            colors[i].description
              .toLowerCase()
              .includes(this.allSearch.toLowerCase())
          ) {
            filteredColors.push(colors[i]);
          }
        }
      }
      /* eslint-disable-next-line */
      this.filteredAllColorsLength = filteredColors.length;
      return filteredColors.slice(0, this.pageSize);

      // return this.$store.getters.materials[this.materialIndex].libraries[
      //   this.libraryIndex
      // ].colors.filter((color: IColorItem) => {
      //   if (this.allSearch === "") {
      //     return color;
      //   } else {
      //     return color.name
      //       .toLowerCase()
      //       .includes(this.allSearch.toLowerCase());
      //   }
      // });
    },
    /**
     * filtered included colors
     * @returns {Array<IColorItem>} - an array of all the included colors that match the search criteria the user entered
     */
    filteredIncludedColors: function (): Array<IColorItem> {
      const colors = this.$store.getters.materials[
        this.materialIndex
      ].libraries[this.libraryIndex].colors.filter((color: IColorItem) => {
        if (this.includedSearch === "") {
          return color.included;
        } else {
          return (
            color.included &&
            color.description
              .toLowerCase()
              .includes(this.includedSearch.toLowerCase())
          );
        }
      });

      /* eslint-disable-next-line */
      this.includedColorsLength = colors.length;
      const start = (this.includedPage - 1) * this.pageSize;
      return colors.slice(start, start + this.pageSize);
    },
    /**
     * filtered excluded colors
     * @returns {Array<IColorItem>} - an array of all the excluded colors that match the search criteria the user entered
     */
    filteredExcludedColors: function (): Array<IColorItem> {
      const colors = this.$store.getters.materials[
        this.materialIndex
      ].libraries[this.libraryIndex].colors.filter((color: IColorItem) => {
        if (this.excludedSearch === "") {
          return !color.included;
        } else {
          return (
            !color.included &&
            color.description
              .toLowerCase()
              .includes(this.excludedSearch.toLowerCase())
          );
        }
      });

      /* eslint-disable-next-line */
      this.excludedColorsLength = colors.length;
      const start = (this.excludedPage - 1) * this.pageSize;
      return colors.slice(start, start + this.pageSize);
    },
    /**
     * getter/setter for id
     */
    id: {
      get(): string {
        return this.$store.getters.materials[this.materialIndex].libraries[
          this.libraryIndex
        ].id;
      },
      set(value: string) {
        const params = {
          materialIndex: this.materialIndex,
          libraryIndex: this.libraryIndex,
          value: value,
        };

        this.$store.commit("updateMaterialColorLibraryId", params);
      },
    },
    /**
     * getter/setter for label
     */
    label: {
      get(): string {
        return this.$store.getters.materials[this.materialIndex].libraries[
          this.libraryIndex
        ].label;
      },
      set(value: string) {
        const params = {
          materialIndex: this.materialIndex,
          libraryIndex: this.libraryIndex,
          value: value,
        };

        this.$store.commit("updateMaterialColorLibraryLabel", params);
      },
    },
    /**
     * getter/setter for description
     */
    description: {
      get(): string {
        return this.$store.getters.materials[this.materialIndex].libraries[
          this.libraryIndex
        ].description;
      },
      set(value: string) {
        const params = {
          materialIndex: this.materialIndex,
          libraryIndex: this.libraryIndex,
          value: value,
        };

        this.$store.commit("updateMaterialColorLibraryDescription", params);
      },
    },
  },
});
