import Snap from "snapsvg-cjs-ts";
import { ClassDefinition } from "@/features/SignDesigner/include/ClassDefinition";
/**
 * Allow the manipulation of the SVG class within
 *
 */

export class SvgCss {
  /**
   *
   */
  private stylesElement: Snap.Element;
  classDefinitions: ClassDefinition[];
  static instance: SvgCss | null = null;

  /**
   *
   * @param styleElement
   */
  public static getInstance(
    styleElement: Snap.Element | null = null
  ): SvgCss | null {
    if (SvgCss.instance === null && styleElement !== null) {
      SvgCss.instance = new SvgCss(styleElement);
    } else if (SvgCss.instance !== null && styleElement !== null) {
      console.error(
        "SvgCss already defined, please ensure that a call to removeInstance is made first"
      );
    }
    return SvgCss.instance;
  }
  public static removeInstance(): SvgCss | null {
    if (SvgCss.instance) {
      SvgCss.instance = null;
    }
    return SvgCss.instance;
  }

  /**
   *
   * @param styleElement
   * @private
   */
  private constructor(styleElement: Snap.Element) {
    this.stylesElement = styleElement;
    this.classDefinitions = [];
    this.parseClassDefinitions(this.stylesElement.node.innerHTML);
  }

  /**
   * parse the class definitions
   * @param strStyle
   */

  private parseClassDefinitions(strStyle: string): void {
    let startIdx = 0;

    do {
      startIdx = strStyle.indexOf(".", startIdx);
      const closeCurlyIdx = strStyle.indexOf("}", startIdx);
      if (startIdx >= 0) {
        if (closeCurlyIdx >= 0) {
          const classDefinition = new ClassDefinition(
            strStyle,
            startIdx,
            closeCurlyIdx
          );
          this.classDefinitions.push(classDefinition);
          startIdx = closeCurlyIdx + 1;
        } else {
          throw "SVG Css class definition missing closing curly brace";
        }
      }
    } while (startIdx >= 0);
  }

  /**
   * Get an array of classDefinitions that contain the specified value name
   * @param valueName
   * @returns {Array}
   */
  public findClassDefinitionsThatHave(valueName: string): ClassDefinition[] {
    const retval = [];
    // the classDefinition has the specified valueName
    for (let i = 0; i < this.classDefinitions.length; i++) {
      if (this.classDefinitions[i].hasValueAt(valueName)) {
        retval.push(this.classDefinitions[i]);
      }
    }
    return retval;
  }

  /** update the style element with these updated values
   *
   */
  public save(): void {
    this.stylesElement.node.innerHTML = this.toString();
  }

  /**
   * Get the class Definitions for the specified className amd variable Name
   * @param className
   * @param variableName
   */

  public getClassDefinition(
    className: string,
    variableName: string
  ): ClassDefinition | null {
    let retval = null;
    for (let i = 0; i < this.classDefinitions.length; i++) {
      if (
        this.classDefinitions[i].hasClassAt(className) &&
        this.classDefinitions[i].hasValueAt(variableName)
      ) {
        retval = this.classDefinitions[i];
        break;
      }
    }
    return retval;
  }
  /**
   *
   * @param className - The className to do the lookup on. Note if the className is not prepended with "." then a "." is prepended.
   * @param variableName - The name of the variable to return the value of
   * @returns the value of the variable
   */
  public getClassVariableValue(
    className: string,
    variableName: string
  ): string | undefined {
    const classDefinition = this.getClassDefinition(className, variableName);
    return classDefinition !== null
      ? classDefinition.valueAt(variableName)
      : "";
  }

  /**
   *
   * @param className - The className to do the lookup on. Note if the className is not prepended with "." then a "." is prepended.
   * @param variableName - The name of the variable to return the value of
   * @returns the value of the variable
   */
  public setClassVariableValue(
    className: string,
    variableName: string,
    value: string
  ): string | undefined {
    const classDefinition = this.getClassDefinition(className, variableName);
    if (classDefinition) {
      classDefinition.setValueAt(variableName, value);
    }
    return classDefinition !== null
      ? classDefinition.valueAt(variableName)
      : "";
  }

  /**
   * return the next available cls number
   * @returns the next number
   */
  public getNextClsNumber(): number {
    let highestNumber = 0;
    this.classDefinitions.forEach((cls) => {
      cls.classNamesArray().forEach((className: string) => {
        if (className.substring(0, 5) === ".cls-") {
          const clsNumber = parseInt(className.substring(5));
          if (clsNumber > highestNumber) {
            highestNumber = clsNumber;
          }
        }
      });
    });
    return highestNumber + 1;
  }

  public addNextClsClass(
    snap: Snap.Element,
    attributeName: string,
    attributeValue: string
  ): string {
    const className = "cls-" + this.getNextClsNumber();
    //write class definition to svg

    const defs = snap.select("defs");
    const styleElement = defs.select("style");
    const styleText =
      "." + className + " { " + attributeName + ": " + attributeValue + "px; }";
    if (styleElement?.node?.innerHTML) {
      styleElement.node.innerHTML = styleElement.node.innerHTML + styleText;
    }

    // const frag = Snap.parse(".cls-7 { font-size: 72px; }");
    // const f = frag.select("*");
    // styleElement.append(f);

    return className;
    // this.setClassVariableValue(className, "font-size", "72px");
  }

  public toString(): string {
    let retval = "";

    for (let i = 0; i < this.classDefinitions.length; i++) {
      retval += this.classDefinitions[i].toString();
    }
    return retval;
  }
}
