



































import Vue from "vue";

import CollapsibleSection from "../CollapsibleSection.vue";
import { IMarketplaceItem } from "../../types";

export default Vue.extend({
  components: { CollapsibleSection },
  props: {},
  data: function () {
    return {};
  },
  methods: {
    downloadTemplate: function (item: IMarketplaceItem) {
      // do some downloading here
      //this.$store.commit("selectMarketplaceConnection", item);
      var element = document.createElement("a");
      element.style.display = "none";
      document.body.appendChild(element);
      let url = window.URL.createObjectURL(item.template);
      element.href = url;
      element.download = item.templateName;
      element.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(element);
    },
    deleteMarketplaceConnection: function (connection: IMarketplaceItem) {
      this.$store.commit("deleteMarketplaceConnection", connection.id);
    },
  },
  computed: {
    selectedConnection: function (): Array<IMarketplaceItem> {
      return this.$store.getters.marketplaceConnections.filter(
        (connection: IMarketplaceItem) => {
          return connection.isSelected;
        }
      );
    },
    /**
     * Determine if section should be displayed open or not
     * @returns {boolean} True if section should be displayed as open
     */
    openSection: function () {
      // implement your logic here. return true if the section should open,
      // false if it should be closed
      if (this.$store.getters.marketplaceConnections.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {},
});
