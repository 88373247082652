









































































































import Vue from "vue";
import { PropType } from "vue";

import $ from "jquery";

import { IPlanAndTiers, ISubscription, ISubscriptionItem } from "../types";

export default Vue.extend({
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    subscription: {
      type: Object as PropType<ISubscription>,
      required: true,
    },
    subscriptionItem: {
      type: Object as PropType<ISubscriptionItem>,
      required: true,
    },
  },
  data: function () {
    return {
      newTier: 0,
      newTotal: 0,
      saveInProgress: false,
      plan: {
        name: "",
        stripePriceId: "",
        tiers: [],
      } as IPlanAndTiers,
    };
  },
  methods: {
    fetchPlanDetails: function () {
      if (this.subscriptionItem.plan) {
        const query = JSON.stringify({
          query: `
          query getPricePlan {
            pricingPlan (id: ${this.subscriptionItem.plan.id}) {
              id: contentObjectId
              name
              prices {
                edges {
                  node {
                    billingCycle
                    stripePriceId
                    tiers {
                      edges {
                        node {
                          upTo
                          amount
                          currency
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        });

        fetch("/graphql/", {
          method: "POST",
          body: query,
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
          },
        })
          .then((data) => data.json())
          .then((result) => {
            if (result.errors) {
              alert(result.errors[0].message);
            } else {
              this.plan.name = result.data.pricingPlan.name;
              this.plan.tiers = [];

              result.data.pricingPlan.prices.edges.forEach(
                (price: {
                  node: {
                    billingCycle: string;
                    stripePriceId: string;
                    tiers: {
                      edges: Array<{
                        node: {
                          upTo: number;
                          amount: number;
                          currency: string;
                        };
                      }>;
                    };
                  };
                }) => {
                  // Stripe stores the price tier amounts differently then we display
                  // them on the front end.  Stripe stores the price as the difference - so
                  // to get the total for the second tier you add the first tier and the
                  // second tier amounts together.  tierTotal is the variable we store the
                  // total in
                  let tierTotal = 0;
                  price.node.tiers.edges.forEach((tier) => {
                    // update the tierTotal
                    tierTotal += tier.node.amount;
                    this.plan.tiers.push({
                      cycle: price.node.billingCycle,
                      upTo: tier.node.upTo,
                      amount: tierTotal,
                      currency: tier.node.currency,
                      stripeId: price.node.stripePriceId,
                    });
                  });
                }
              );
            }
          });
      }
    },
    changeToAnnual: function () {
      const items = [];
      this.$store.commit("setShowLoadingSpinner", true);
      for (const plan of this.subscription.items) {
        // const priceTier = this.$store.getters.priceTierById(plan.priceTierId);
        const annualTier = this.getAnnualPlan(this.subscriptionItem.upTo);
        if (!annualTier) {
          throw new Error("No annual tier found");
        }
        items.push({
          id: plan.id,
          stripeId: plan.stripeSubscriptionId,
          price: annualTier.stripeId,
          quantity: annualTier.upTo,
        });
      }

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          variables: {
            mutateSubscriptionInput: {
              company: this.$route.params.id,
              cancel: false,
              id: this.subscription.id,
              items: JSON.stringify(items),
            },
          },
          query: `
            mutation changeToAnnualPlan (
              $mutateSubscriptionInput: MutateSubscriptionInput!
            ) {
              mutateSubscription(input: $mutateSubscriptionInput) {
                errors { messages }
                subscription {
                  id
                  stripeSubscriptionId
                }
              }
            }`,
        }),
      })
        .then((data) => data.json())
        .then((result) => {
          this.$store.commit("setShowLoadingSpinner", false);

          if (result.errors) {
            alert(result.errors[0].message);
          } else if (result.data.mutateSubscription?.errors.length > 0) {
            alert(result.data.mutateSubscription.errors[0].messages.join(", "));
          } else {
            $("#changeToAnnualPlanModal").modal("hide");
            this.$emit("closed");
            location.reload();
          }
        });
    },
    annualPlanAsArray: function (): Array<{
      cycle: string;
      upTo: number;
      amount: number;
      currency: string;
      stripeId: string;
    }> {
      const annualPlan = this.getAnnualPlan(this.subscriptionItem.upTo);
      return [annualPlan];
    },
    getAnnualPlan: function (upTo: number): {
      cycle: string;
      upTo: number;
      amount: number;
      currency: string;
      stripeId: string;
    } {
      let result = {} as {
        cycle: string;
        upTo: number;
        amount: number;
        currency: string;
        stripeId: string;
      };

      for (let i = 0; i <= this.plan.tiers.length; i++) {
        const tier = this.plan.tiers[i];
        if (tier) {
          if (tier.cycle === "YEAR" && tier.upTo >= upTo) {
            result = tier;
            break;
          }
        }
      }
      return result;
    },
    /**
     * checks if an IPricePlan object is empty
     * @param {IPricePlan} obj - the price plan we wish to check
     * @returns {boolean} - true if object is empty
     */
    isEmpty: function (obj: {
      cycle: string;
      upTo: number;
      amount: number;
      currency: string;
      stripeId: string;
    }): boolean {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    },
  },
  computed: {
    savingPercent: function (): string {
      const percentage =
        (this.totalCurrentPlan - this.totalAnnualPlan) / this.totalCurrentPlan;
      return (percentage * 100).toFixed(2);
    },
    totalCurrentPlan: function (): number {
      let total = 0;
      if (this.subscription.items) {
        this.subscription.items.forEach((item) => {
          total += item.amount * 12;
        });
      }
      return total;
    },
    totalAnnualPlan: function (): number {
      let total = 0;

      if (this.subscription.items) {
        this.subscription.items.forEach((item) => {
          const annualPlan = this.getAnnualPlan(item.upTo);
          total += annualPlan.amount;
        });
      }
      return total;
    },
    numberOfUsers: function (): number {
      return this.subscriptionItem.upTo - this.subscriptionItem.remainingSeats;
    },
  },
  watch: {
    subscription: function () {
      this.fetchPlanDetails();
    },
    newTier: function (newValue) {
      // if newValue is undefined then we are on the high tier
      // so we don't need to set newTotal
      if (newValue) {
        this.newTotal = newValue.amount;
      }
    },
    showModal: function (newValue: boolean) {
      if (newValue) {
        this.fetchPlanDetails();
        $("#changeToAnnualPlanModal").modal("show");
      } else {
        $("#changeToAnnualPlanModal").modal("hide");
      }
    },
  },
});
