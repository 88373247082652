









































































import Vue from "vue";
import { IOrderSignType } from "@/features/Orders/types";
import { toCurrency } from "../utilities";

export default Vue.extend({
  components: {},
  props: {},
  data: function () {
    return {
      showModal: false,
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showPaymentModal;
  },
  methods: {
    /**
     * calls the toCurrency utility class.
     * We need this because the linter won't let us do a clean compile because it thinks
     * the toCurrency function is never used if it only used in the Vue template.
     * Putting it in a method makes the linter happy
     * @param value number - the amount
     * @param currencyCode string - An ISO compliant currency code (i.e. CAD, USD, etc.)
     * @returns string - A formatted string with the proper currency symbol and punctuation
     */
    convertCurrency: function (value: number, currencyCode: string) {
      return toCurrency(value, currencyCode);
    },

    /**
     * close the modal
     * @param {Event} e - the event that triggered the close
     */
    payNow: function () {
      // close the modal
      console.debug("pay invoice now - link to payment processor");
      this.$store.commit("setShowPaymentModal", false);
      this.$emit("pay-invoice-now", "payInvoiceNow");
    },
    /**
     * close the modal
     * @param {Event} e - the event that triggered the close
     */
    createInoice: function () {
      // close the modal
      console.debug("create a PDF of the invoice");
      this.$store.commit("setShowPaymentModal", false);
      this.$emit("pay-invoice-later", "payInvoiceLater");
    },
    /**
     * calculate the total quantity of all the signs in a sign type
     * @param orderItem IOrderSignType - a sign type
     * @returns number - the total quantity of all the "approved" signs in this sign type
     */
    calculateQuantity: function (orderItem: IOrderSignType): number {
      let total = 0;
      orderItem.signs.forEach((sign) => {
        // only include Approved signs in the total calculation
        if (sign.reviewState === "A") {
          total = total + sign.quantity;
        }
      });
      return total;
    },
  },
  computed: {
    /**
     * calculate the total amount for all sign types on the order
     * @returns Number total - the total of all the rows on the order
     */
    itemSubtotal: function (): number {
      let grandTotal = 0;

      if (this.$store.getters.order && this.$store.getters.order.signTypes) {
        this.$store.getters.order.signTypes.forEach(
          (signType: IOrderSignType) => {
            // the HTMLInputElement is returning a string so when we do an addition the string is concatenated giving us strange results
            // here we are checking the typeof the var and doing a parseFloat if it is a string.  We can't just do a parseFloat because
            // typescript won't let us, it throws a:
            //             "Argument of type 'number' is not assignable to parameter of type 'string'.Vetur(2345)"
            // error
            let total = 0;
            if (typeof signType.actual_price === "number") {
              total += signType.actual_price;
            } else {
              total += parseFloat(signType.actual_price);
            }
            // doing the same as above with the install price field
            if (typeof signType.install_price === "number") {
              total += signType.install_price;
            } else {
              total += parseFloat(signType.install_price);
            }

            total = total * this.calculateQuantity(signType);
            grandTotal += total;
          }
        );
      }
      return grandTotal;
    },
    rows: function (): Array<{ label: string; amount: number }> {
      const subTotal = this.itemSubtotal;
      const shipping = this.$store.getters.order.shipping;

      const theRows = [
        { label: "Items Subtotal", amount: subTotal },
        { label: "Shipping and Handling", amount: shipping },
      ];
      return theRows;
    },
    rowTotal: function (): Array<{ label: string; amount: number }> {
      const subTotal = this.itemSubtotal;
      const shipping = this.$store.getters.order.shipping;
      const total = subTotal + shipping;

      const theRows = [{ label: "Total", amount: total }];
      return theRows;
    },
  },
  watch: {
    /**
     * when showConfirmModal from the store changes
     * @param {boolean} newValue - the value of the showConfirmModal from the store
     */
    "$store.state.order.showPaymentModal": function (newValue: boolean) {
      //   const this = this;
      this.showModal = newValue;
    },
  },
});
