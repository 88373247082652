

















































import Vue from "vue";
import MapViewLayout from "@/components/layouts/MapViewLayout.vue";

import { IOrder } from "../types";

export default Vue.extend({
  components: { MapViewLayout },
  data: function () {
    return {
      order: {} as IOrder,
      showSignTypes: false,
    };
  },
  mounted: function () {
    this.fetchOrder();
  },
  methods: {
    /**
     * get the order data from graphQL
     */
    fetchOrder: function () {
      const query = JSON.stringify({
        query: `query get_order {
          order(id: ${this.$route.params.id}) {
            id: contentObjectId
            uuid
            name
            includeInstall
            includeBuild
            projects {
              name
              phaseType
            }
          }
        }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          let builderId = "";
          const newOrder: IOrder = {
            id: result.data.order.id,
            uuid: result.data.order.uuid,
            name: result.data.order.name,
            submittedDate: new Date(),
            dueDate: new Date(),
            amount: 0,
            currency: "",
            stateId: 0,
            stateName: "",
            organization: {
              id: "",
              name: "",
            },
            status: "",
            poNumber: "",
            lastSaved: null,
            manager: {
              id: "",
              firstName: "",
              lastName: "",
              email: "",
              image: "",
            },
            builder: {
              id: builderId,
              name: "",
              email: "",
            },
            builderOrder: {
              id: "",
            },
            userIsBuilder: false,
            signTypes: [],
            itemSubtotal: 0,
            shipping: 0,
            taxes: {
              name: "HST",
              amount: 0,
            },
            shippingStreet1: "",
            shippingStreet2: "",
            shippingCity: "",
            shippingState: "",
            shippingPostalCode: "",
            shippingCountry: "",
            shippingContact: "",
            billingSameAsShipping: false,
            billingStreet1: "",
            billingStreet2: "",
            billingCity: "",
            billingState: "",
            billingPostalCode: "",
            billingCountry: "",
            billingContact: "",
            includeInstall: result.data.order.includeInstall,
            IncludeBuild: result.data.order.includeBuild,
            comments: [],
            projects: result.data.order.projects,
          };
          this.order = newOrder;
        });
    },
  },
  computed: {},
});
