














































import Vue from "vue";
import $ from "jquery";

export default Vue.extend({
  props: ["selectedPlan"],
  data: function () {
    return {
      reason: "",
    };
  },
  methods: {
    cancelPlan: function () {
      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          variables: {
            mutateSubscriptionInput: {
              company: this.$route.params.id,
              cancel: true,
              id: this.selectedPlan.subscriptionId,
            },
          },
          query: `
            mutation cancelPlan (
              $mutateSubscriptionInput: MutateSubscriptionInput!
            ) {
              mutateSubscription(input: $mutateSubscriptionInput) {
                errors { messages }
                subscription {
                  id
                  stripeSubscriptionId
                }
              }
            }`,
        }),
      })
        .then((data) => data.json())
        .then((result) => {
          if (result.errors) {
            alert(result.errors[0].message);
          } else if (result.data.mutateSubscription?.errors.length > 0) {
            alert(result.data.mutateSubscription.errors[0].messages.join(", "));
          }
          $("#cancel-plan-modal").modal("hide");
          // location.reload();
        });
    },
  },
  computed: {},
  watch: {},
});
