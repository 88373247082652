































































































































import Vue from "vue";
import vSelect from "vue-select";

import FullPageLayout from "@/components/layouts/FullPageLayout.vue";
import PriceTierList from "../components/PriceTierList.vue";
import {
  IPricePlan,
  IPriceTier,
  IConsolidatedPriceTier,
  IGraphPricePlan,
  ICompany,
} from "../types";

export default Vue.extend({
  components: { FullPageLayout, PriceTierList, vSelect },
  data: function () {
    return {
      companyId: this.$route.params.id,
      pricePlan: {} as IPricePlan,
      priceTiers: [] as IPriceTier[],
      changesSaved: false,
      privatePlan: false,
      selectedCompanies: [] as Array<{ id: string; label: string }>,
      companyList: [] as Array<{ id: string; label: string }>,
    };
  },
  mounted: function () {
    // set the ref
    this.$root.$refs.PricePlanEdit = this;

    // load price plan info from backend
    let localPriceTiers = [] as IPriceTier[];

    fetch("/graphql/", {
      method: "POST",
      body: JSON.stringify({
        query: `query get_price_plan {
          pricingPlan(id: ${this.$route.params.pricePlanId}) {
            id: contentObjectId
            name
            planType
            isPrivatePlan
            assignedCompanies {
              edges {
                node {
                  id: contentObjectId
                  name
                }
              }
            }
            prices {
              edges {
                node {
                  id: contentObjectId
                  stripePriceId
                  billingCycle
                  currency
                  tiers {
                    edges {
                      node {
                        upTo
                        amount
                        inUse
                      }
                    }
                  }
                }
              }
            }
          }
        }`,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result: IGraphPricePlan) => {
        const assignedCompanies =
          result.data.pricingPlan.assignedCompanies.edges.map((company) => {
            return { id: company.node.id.toString(), label: company.node.name };
          });

        const localPricePlan = {
          id: Number(result.data.pricingPlan.id),
          name: result.data.pricingPlan.name,
          planType: result.data.pricingPlan.planType,
          isPrivatePlan: result.data.pricingPlan.isPrivatePlan,
          assignedCompanies: assignedCompanies,
        } as IPricePlan;

        this.privatePlan = localPricePlan.isPrivatePlan;
        this.selectedCompanies = localPricePlan.assignedCompanies;

        // load tiers
        result.data.pricingPlan.prices.edges.forEach((price) => {
          let monthlySum = 0;
          let yearlySum = 0;
          if (price.node.tiers) {
            price.node.tiers.edges.forEach((tier) => {
              const newTier = {
                id: Number(price.node.id),
                billingCycle: price.node.billingCycle,
                numberOfUsers: tier.node.upTo,
                price: tier.node.amount,
                pricePlanId: localPricePlan.id,
                stripePriceId: price.node.stripePriceId,
                currency: price.node.currency,
                inUse: tier.node.inUse,
              } as IPriceTier;
              if (newTier.numberOfUsers) {
                if (newTier.billingCycle === "MONTH") {
                  newTier.price += monthlySum;
                  monthlySum = newTier.price;
                } else {
                  newTier.price += yearlySum;
                  yearlySum = newTier.price;
                }
              }
              localPriceTiers.push(newTier);
            });
          }
        });
        this.priceTiers = localPriceTiers;
        this.pricePlan = localPricePlan; // as IPricePlan;
      });
  },
  methods: {
    /**
     * save changes to backend
     */
    async saveChanges() {
      this.$store.commit("setShowLoadingSpinner", true);
      try {
        // check if tiers (other than "unlimited" tier) exist
        let localConsolidatedPriceTiers = null;
        if (this.consolidatedPriceTiers.length > 0) {
          // create local tier list so to ensure proper sorting
          localConsolidatedPriceTiers = this.consolidatedPriceTiers;
          this.sortConsolidatedTiers(localConsolidatedPriceTiers);
          // convert to incremental prices
          localConsolidatedPriceTiers = this.toIncrementalPrices(
            localConsolidatedPriceTiers
          );
        }

        // first update general info
        const updateGeneralPlanInfoResponse = await (
          await fetch("/graphql/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              query: `
                mutation update_general_plan_info(
                  $mutatePricingPlanInput: MutatePricingPlanInput!,
                ) {
                  mutatePricingPlan(input: $mutatePricingPlanInput) {
                    errors { messages }
                    pricingPlan {
                      name
                      id: contentObjectId
                    }
                  }
                }`,
              variables: {
                mutatePricingPlanInput: {
                  name: this.pricePlan.name,
                  planType: this.pricePlan.planType,
                  company: this.companyId,
                  id: this.pricePlan.id,
                  isPrivatePlan: this.privatePlan,
                  assignedCompanies: this.selectedCompanies.map((company) => {
                    return company.id;
                  }),
                },
              },
            }),
          })
        ).json();
        if (updateGeneralPlanInfoResponse.errors) {
          this.$store.commit("setShowLoadingSpinner", false);
          throw new Error(updateGeneralPlanInfoResponse.errors[0].message);
        } else if (
          updateGeneralPlanInfoResponse.data.mutatePricingPlan?.errors.length >
          0
        ) {
          this.$store.commit("setShowLoadingSpinner", false);
          throw new Error(
            updateGeneralPlanInfoResponse.data.mutatePricingPlan.errors[0].messages.join(
              ", "
            )
          );
        }

        // check if tiers (other than "unlimited" tier) exist
        if (localConsolidatedPriceTiers !== null) {
          // add/update monthly plan prices
          let monthlyPlanInput = {
            pricingPlan: this.$route.params.pricePlanId,
            billingCycle: "MONTH",
            tiers: this.convertToTiersInput(
              localConsolidatedPriceTiers,
              "MONTH"
            ),
          } as {
            id?: number;
            pricingPlan: string;
            billingCycle: string;
            tiers: string;
          };

          // is this a new or existing record?
          if (this.monthlyPriceTiers.length > 0) {
            // if existing then set the plan price id
            monthlyPlanInput.id = this.monthlyPriceTiers[0].id;
          }

          const editMonthlyPlanPriceResponse = await (
            await fetch("/graphql/", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify({
                query: `
                  mutation edit_monthly_plan_price(
                    $mutatePlanPriceInput: MutatePlanPriceInput!
                  ) {
                    mutatePlanPrice(input: $mutatePlanPriceInput) {
                      errors { messages }
                      planPrice {
                        id: contentObjectId
                      }
                    }
                  }`,
                variables: {
                  mutatePlanPriceInput: monthlyPlanInput,
                },
              }),
            })
          ).json();
          if (editMonthlyPlanPriceResponse.errors) {
            this.$store.commit("setShowLoadingSpinner", false);
            throw new Error(editMonthlyPlanPriceResponse.errors[0].message);
          } else if (
            editMonthlyPlanPriceResponse.data.mutatePlanPrice?.errors.length > 0
          ) {
            this.$store.commit("setShowLoadingSpinner", false);
            throw new Error(
              editMonthlyPlanPriceResponse.data.mutatePlanPrice.errors[0].messages.join(
                ", "
              )
            );
          }

          let yearlyPlanInput = {
            pricingPlan: this.$route.params.pricePlanId,
            billingCycle: "YEAR",
            tiers: this.convertToTiersInput(
              localConsolidatedPriceTiers,
              "YEAR"
            ),
          } as {
            id?: number;
            pricingPlan: string;
            billingCycle: string;
            tiers: string;
          };

          // is this a new or existing record?
          if (this.yearlyPriceTiers.length > 0) {
            // if existing then set the plan price id
            yearlyPlanInput.id = this.yearlyPriceTiers[0].id;
          }

          // add/update yearly plan prices
          const editYearlyPlanPriceResponse = await (
            await fetch("/graphql/", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: JSON.stringify({
                query: `
                  mutation edit_yearly_plan_price(
                    $mutatePlanPriceInput: MutatePlanPriceInput!
                  ) {
                    mutatePlanPrice(input: $mutatePlanPriceInput) {
                      errors { messages }
                      planPrice {
                        id: contentObjectId
                      }
                    }
                  }`,
                variables: {
                  mutatePlanPriceInput: yearlyPlanInput,
                },
              }),
            })
          ).json();
          if (editYearlyPlanPriceResponse.errors) {
            this.$store.commit("setShowLoadingSpinner", false);
            throw new Error(editYearlyPlanPriceResponse.errors[0].message);
          } else if (
            editYearlyPlanPriceResponse.data.mutatePlanPrice?.errors.length > 0
          ) {
            this.$store.commit("setShowLoadingSpinner", false);
            throw new Error(
              editYearlyPlanPriceResponse.data.mutatePlanPrice.errors[0].messages.join(
                ", "
              )
            );
          }
        }
        this.$store.commit("setShowLoadingSpinner", false);
        this.changesSaved = true;
      } catch (e) {
        this.$store.commit("setShowLoadingSpinner", false);
        alert(e);
      }
    },
    /**
     * converts array of IConsolidatedPriceTier objects into a string which is needed to write changes to backend
     * @param {Array<IConsolidatedPriceTier>} tierList - array of price tiers
     * @param {string} period - the period which we wish to apply the function to (accepts "MONTH" or "YEAR")
     * @returns {string} - formatted string which can be passed into a mutatePlanPrice query
     */
    convertToTiersInput(
      // this can be immensely simplified using JSON.stringify, will fix if I have time - Owen
      tierList: IConsolidatedPriceTier[],
      period: string
    ): string {
      const ret: Array<{ up_to: number | null; flat_amount: number }> = [];
      if (period === "MONTH") {
        for (const tier of tierList) {
          ret.push({ up_to: tier.upTo, flat_amount: tier.monthlyAmount });
        }
      } else if (period === "YEAR") {
        for (const tier of tierList) {
          ret.push({ up_to: tier.upTo, flat_amount: tier.yearlyAmount });
        }
      }
      return JSON.stringify(ret);
    },
    /**
     * checks if an IPricePlan object is empty
     * @param {IPricePlan} obj - the price plan we wish to check
     * @returns {boolean} - true if object is empty
     */
    isEmpty: function (obj: IPricePlan): boolean {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    /**
     * sorts a list of IConsolidatedPriceTier objects
     */
    sortConsolidatedTiers(consolidatedTierList: IConsolidatedPriceTier[]) {
      consolidatedTierList.sort((a, b) => {
        if (a.upTo === null) {
          return 1;
        } else if (b.upTo === null) {
          return -1;
        }
        return Number(a.upTo) - Number(b.upTo);
      });
    },
    toIncrementalPrices(consolidatedTierList: IConsolidatedPriceTier[]) {
      // assume input is sorted by upTo values
      const result = [] as IConsolidatedPriceTier[];
      let monthlySum = 0;
      let yearlySum = 0;
      // get first and last tiers, they will not change
      const firstTier = consolidatedTierList[0];
      const lastTier = consolidatedTierList.slice(-1)[0];
      // push first tier
      result.push(firstTier as IConsolidatedPriceTier);
      monthlySum += firstTier.monthlyAmount;
      yearlySum += firstTier.yearlyAmount;
      // convert other tiers (excluding last)
      consolidatedTierList
        .slice(1, -1)
        .forEach((tier: IConsolidatedPriceTier) => {
          const newTier = {
            upTo: tier.upTo,
            monthlyAmount: tier.monthlyAmount - monthlySum,
            yearlyAmount: tier.yearlyAmount - yearlySum,
          } as IConsolidatedPriceTier;
          if (newTier.monthlyAmount < 0) {
            throw new Error("Monthly price can't be negative");
          }
          if (newTier.yearlyAmount < 0) {
            throw new Error("Yearly price can't be negative");
          }
          result.push(newTier);
          monthlySum += newTier.monthlyAmount;
          yearlySum += newTier.yearlyAmount;
        });
      // add last tier
      result.push(lastTier);
      return result;
    },
    filterCompanies: function (search: string) {
      const query = JSON.stringify({
        query: `query {
        	companies (name_Icontains:"${search}") {
            edges {
              node {
                id:contentObjectId
                label: name
              }
            }
          }
        }`,
      });

      fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
      })
        .then((data) => data.json())
        .then((result) => {
          this.companyList = [];
          result.data.companies.edges.forEach(
            (company: { node: { id: string; label: string } }) => {
              this.companyList.push({
                id: company.node.id,
                label: company.node.label,
              });
            }
          );
        });
    },
  },
  computed: {
    companies: function (): Array<{ id: string; label: string }> {
      return this.$store.getters.companies.map((company: ICompany) => {
        return { id: company.id, label: company.name };
      });
    },
    monthlyPriceTiers(): IPriceTier[] {
      return this.priceTiers.filter((price) => price.billingCycle === "MONTH");
    },
    yearlyPriceTiers(): IPriceTier[] {
      return this.priceTiers.filter((price) => price.billingCycle === "YEAR");
    },
    /**
     * list of all price tiers in consolidated form (converted into the IConsolidatedPriceTier type)
     */
    consolidatedPriceTiers(): IConsolidatedPriceTier[] {
      let result = [] as IConsolidatedPriceTier[];
      if (this.priceTiers.length > 0) {
        const numUsersValues = new Set(
          this.priceTiers.map((tier) => tier.numberOfUsers)
        );
        for (let val of numUsersValues) {
          result.push(
            !isNaN(val)
              ? {
                  upTo: val,
                  monthlyAmount: this.monthlyPriceTiers.filter(
                    (tier) => tier.numberOfUsers === val
                  )[0].price,
                  yearlyAmount: this.yearlyPriceTiers.filter(
                    (tier) => tier.numberOfUsers === val
                  )[0].price,
                  inUse: this.monthlyPriceTiers.filter(
                    (tier) => tier.numberOfUsers === val
                  )[0].inUse,
                }
              : {
                  upTo: null,
                  monthlyAmount: this.monthlyPriceTiers.filter((tier) =>
                    isNaN(tier.numberOfUsers)
                  )[0].price,
                  yearlyAmount: this.yearlyPriceTiers.filter((tier) =>
                    isNaN(tier.numberOfUsers)
                  )[0].price,
                  inUse: true,
                }
          );
        }
      }
      return result;
    },
  },
  watch: {},
});
