





















































































































































































































































































































































































import Vue from "vue";
import $ from "jquery";

import { ORDER_STATUS, IOrderManager } from "../types";

import ShippingAddressModal from "../modals/shippingAddressModal.vue";
import BillingAddressModal from "../modals/billingAddressModal.vue";
import ChangeManagerModal from "../modals/changeManagerModal.vue";

export default Vue.extend({
  components: { ShippingAddressModal, BillingAddressModal, ChangeManagerModal },
  props: {
    order: Object,
    errorField: String,
    errorMessage: String,
  },
  data: function () {
    return {
      localOrder: this.order,
      builder: "",
      validEmailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      billingAddressHeight: 0,
      orgAdmins: [] as Array<IOrderManager>,
      showChangeManagerModal: false,
      previousManager: {} as IOrderManager,
    };
  },
  mounted: function () {
    this.setBillingAddressHeight();
    if (this.$store.getters.order.status === ORDER_STATUS.DRAFT) {
      this.fetchOrgAdmins();
    }
    $('[data-toggle="popover"]').popover();
  },
  methods: {
    changeManagerWarning: function () {
      this.showChangeManagerModal = true;
    },
    cancelChangeManager: function () {
      // set the manager back to the previous value
      this.$store.commit("updateOrderManager", this.previousManager);
      this.showChangeManagerModal = false;
    },
    changeManager: function () {
      const manager = this.orgAdmins.find((admin) => {
        return admin.id === this.$store.getters.order.manager.id;
      });

      this.$store.commit("updateOrderManager", manager);

      // update the back end
      const query = JSON.stringify({
        query: `mutation {
              mutateOrder (input: {
                id:${this.$store.getters.order.id},
                status: "${this.$store.getters.order.status}",
                manager: "${manager?.id}"})
                {
                  order {
                    id: contentObjectId
                  }
                  errors {
                    messages
                  }
                }
              }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.errors) {
            this.$store.commit("setShowLoadingSpinner", false);
          } else {
            this.$store.commit("setShowLoadingSpinner", false);
          }
        });

      this.showChangeManagerModal = false;
    },
    fetchOrgAdmins: function () {
      const query = JSON.stringify({
        query: `query {
          state (id: ${this.$store.getters.order.stateId}) {
            organization:project {
              groupUsers(groupNames:["admin"]) {
                edges {
                  node {
                    user {
                      id: userId
                      email
                      firstName
                      lastName
                      image
                    }
                  }
                }
              }
            }
          }
        }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          result.data.state.organization.groupUsers.edges.forEach(
            (node: {
              node: {
                user: {
                  id: string;
                  email: string;
                  firstName: string;
                  lastName: string;
                  image: string;
                };
              };
            }) => {
              this.orgAdmins.push({
                id: node.node.user.id,
                email: node.node.user.email,
                firstName: node.node.user.firstName,
                lastName: node.node.user.lastName,
                image: node.node.user.image,
              });
            }
          );
        });
    },
    disableField: function () {
      // if we are a super user don't disable the order
      if (this.$store.state.session.isSuperUser) {
        return false;
      }

      //if the order status is draft then don't disable the field
      if (this.$store.getters.order.status !== ORDER_STATUS.DRAFT) {
        // if the user is the builder and the status is QUOTING then don't disable the field
        return true;
      } else if (
        this.$store.getters.order.manager.id ===
        this.$store.state.session.userId
      ) {
        return false;
      } else {
        return true;
      }
    },
    /**
     * disableOrderName: should we disable the order name field?
     * @returns boolean - true if the order name field should be disabled
     */
    disableOrderName: function () {
      // if we are a super user don't disable the order
      if (this.$store.state.session.isSuperUser) {
        return false;
      }

      //if the order status is draft then don't disable the field
      if (this.$store.getters.order.status != ORDER_STATUS.DRAFT) {
        // if the user is the builder and the status is QUOTING then don't disable the field
        if (
          this.$store.getters.order.userIsBuilder &&
          this.$store.getters.order.status === ORDER_STATUS.QUOTING
        ) {
          return false;
        } else {
          // otherwise disable the order name field
          return true;
        }
      } else if (
        this.$store.getters.order.manager.id ===
        this.$store.state.session.userId
      ) {
        return false;
      } else {
        return true;
      }
    },
    /**
     * set the billing address element to the same height as the shipping address element
     */
    setBillingAddressHeight: function () {
      const shippingAddress = document.getElementById("shippingAddress");
      if (shippingAddress) {
        this.billingAddressHeight = (
          shippingAddress as HTMLElement
        ).clientHeight;
      }
    },
    /**
     * return the appropriate class depending on if we have a valid email address or not
     * Note: this only tests the syntax of the address.
     * @param {string} email - the email to test
     * @returns {string} - empty if the email is empty, 'has-success' if email passes regex test, otherwise 'has-error'
     */
    setEmailClass: function (email: string): string {
      return email == ""
        ? ""
        : this.validEmailReg.test(email)
        ? "has-success"
        : "has-error";
    },
    /**
     * toggle the display of the shipping address modal
     */
    toggleShippingAddressModal: function () {
      this.$store.commit("setShowShippingAddressModal", true);
      this.$emit("fieldChanged");
    },
    /**
     * toggle the display of the billing address modal
     */
    toggleBillingAddressModal: function () {
      this.$store.commit("setShowBillingAddressModal", true);
      this.$emit("fieldChanged");
    },
    /**
     * test if shipping address is the same as the billing address
     */
    shippingAddressSameAsBillingAddress: function () {
      const order = this.$store.getters.order;

      if (order.shippingContact !== order.billingContact) {
        return false;
      }

      if (order.shippingStreet1 !== order.billingStreet1) {
        return false;
      }

      if (order.shippingStreet2 !== order.billingStreet2) {
        return false;
      }

      if (order.shippingCity !== order.billingCity) {
        return false;
      }

      if (order.shippingState !== order.billingState) {
        return false;
      }

      if (order.shippingCountry !== order.billingCountry) {
        return false;
      }

      if (order.shippingPostalCode !== order.billingPostalCode) {
        return false;
      }

      return true;
    },
  },
  computed: {
    includeInstall: {
      get(): boolean {
        if (this.$store.getters.order) {
          return this.$store.getters.order.includeInstall;
        } else {
          return false;
        }
      },
      set(value: boolean) {
        this.$store.commit("updateOrderIncludeInstall", value);
        this.$emit("fieldChanged");
        this.$emit("clearError");
      },
    },
    includeBuild: {
      get(): boolean {
        if (this.$store.getters.order) {
          return this.$store.getters.order.IncludeBuild;
        } else {
          return false;
        }
      },
      set(value: boolean) {
        this.$store.commit("updateOrderIncludeBuild", value);
        this.$emit("fieldChanged");
        this.$emit("clearError");
      },
    },
    manager: {
      get(): string {
        if (this.$store.getters.order) {
          return this.$store.getters.order.manager.id;
        } else {
          return "";
        }
      },
      set(value: string) {
        this.previousManager = this.$store.getters.order.manager;
        // the value contains the manager id
        const manager = this.orgAdmins.find((admin) => {
          return admin.id === value;
        });
        if (manager) {
          this.$store.commit("updateOrderManager", manager);
          this.$emit("fieldChanged");
        }
      },
    },
    addressBackground: {
      get: function (): string {
        return this.$store.getters.order.status !== ORDER_STATUS.DRAFT
          ? "#eee"
          : "#fff";
      },
    },
    builderEmail: {
      get(): string {
        return this.$store.getters.order.builder.email;
      },
      set(value: string) {
        this.builder = value;
        if (!value || this.setEmailClass(value) === "has-success") {
          this.$store.commit("updateOrderBuilderEmail", value);
          this.$emit("fieldChanged");
        }
      },
    },
    sameBillingAddress: {
      get(): boolean {
        if (this.$store.getters.order) {
          return this.$store.getters.order.billingSameAsShipping;
        } else {
          return false;
        }
      },
      set(value: boolean) {
        this.$store.commit("setBillingSameAsShipping", value);
        this.$emit("fieldChanged");
      },
    },
    orderName: {
      get(): string {
        if (this.$store.getters.order) {
          return this.$store.getters.order.name;
        } else {
          return "";
        }
      },
      set(value: string) {
        this.$store.commit("setOrderNameForOrder", value);
        this.$emit("fieldChanged");
      },
    },
    issuedDate: {
      get(): string {
        if (this.$store.getters.order) {
          const myDate = new Date(this.$store.getters.order.submittedDate);
          return myDate.toLocaleDateString("en-CA");
        } else {
          return "";
        }
      },
      set(value: string) {
        this.$store.commit("setIssuedDateForOrder", value);
        this.$emit("fieldChanged");
      },
    },
    dueDate: {
      get(): string {
        if (this.$store.getters.order) {
          if (this.$store.getters.order.dueDate) {
            const myDate = new Date(this.$store.getters.order.dueDate);
            return myDate.toISOString().substring(0, 10);
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      set: function (value: string) {
        this.$store.commit("updateOrderDueDate", value);
        this.$emit("fieldChanged");
      },
    },
    poNumber: {
      get(): string {
        if (this.$store.getters.order) {
          return this.$store.getters.order.poNumber;
        } else {
          return "";
        }
      },
      set(value: string) {
        this.$store.commit("updateOrderPONumber", value);
        this.$emit("fieldChanged");
      },
    },
    orderCurrency: {
      get(): string {
        if (this.$store.getters.order) {
          return this.$store.getters.order.currency;
        } else {
          return "USD";
        }
      },
      set(value: string) {
        this.$store.commit("updateOrderCurrency", value);
        this.$emit("fieldChanged");
      },
    },
    ORDER_STATUS: function () {
      return ORDER_STATUS;
    },
  },

  watch: {
    order: function () {
      this.$nextTick(() => {
        this.setBillingAddressHeight();
        // if the shipping address isn't equal to the billing address
        if (!this.shippingAddressSameAsBillingAddress()) {
          // and if the same billing address checkbox is checked
          if (this.sameBillingAddress) {
            // then set the billing address equal to the shipping address
            let localOrder = JSON.parse(
              JSON.stringify(this.$store.getters.order)
            );

            localOrder.billingContact = localOrder.shippingContact;
            localOrder.billingStreet1 = localOrder.shippingStreet1;
            localOrder.billingStreet2 = localOrder.shippingStreet2;
            localOrder.billingCity = localOrder.shippingCity;
            localOrder.billingState = localOrder.shippingState;
            localOrder.billingCountry = localOrder.shippingCountry;
            localOrder.billingPostalCode = localOrder.shippingPostalCode;

            this.$store.commit("setOrder", localOrder);
          }
        }
      });
    },
    sameBillingAddress(newVal: boolean) {
      if (newVal) {
        let localOrder = JSON.parse(JSON.stringify(this.$store.getters.order));

        localOrder.billingContact = localOrder.shippingContact;
        localOrder.billingStreet1 = localOrder.shippingStreet1;
        localOrder.billingStreet2 = localOrder.shippingStreet2;
        localOrder.billingCity = localOrder.shippingCity;
        localOrder.billingState = localOrder.shippingState;
        localOrder.billingCountry = localOrder.shippingCountry;
        localOrder.billingPostalCode = localOrder.shippingPostalCode;

        this.$store.commit("setOrder", localOrder);
      }
    },
  },
});
