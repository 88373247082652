













































import Vue from "vue";

import { IImage } from "../../types";
import { uuidv4 } from "../../utilities";
// import matthews from "./assets/matthews.json";

export default Vue.extend({
  components: {},
  data: function () {
    return {
      showModal: false,
      images: null as FileList | null,
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showImageModal;
  },
  methods: {
    /**
     * triggered when the image input changes
     * @param {Event} e - the event that triggered the change
     */
    imageChanged: function (e: Event) {
      const target = e.target as HTMLInputElement;
      this.images = target.files as FileList;
    },
    /**
     * save changes
     */
    saveChanges: function () {
      if (this.images) {
        for (let i = 0; i < this.images.length; i++) {
          const image: File = this.images[i];
          const id = uuidv4();
          let f: IImage = {
            id: id,
            isSelected: false,
            name: image.name,
            path: URL.createObjectURL(image),
          };
          this.$store.commit("addImage", f);
        }
      }
      //close the window
      this.$store.commit("setShowImageModal", false);
    },
    /**
     * close the modal
     * @param {Event} e - the event that triggered the change
     */
    closeModal: function (e: Event) {
      e.preventDefault();
      e.stopPropagation();
      this.$store.commit("setShowImageModal", false);
    },
  },
  computed: {
    /**
     * getter/setter for imageName
     */
    imageName: {
      get() {
        if (this.$store.getters.selectedImage) {
          return this.$store.getters.selectedImage.name;
        } else {
          return "";
        }
      },
      set(value) {
        this.$store.commit("updateSelectedImageName", value);
      },
    },
    /**
     * getter/setter for imagePath
     */
    imagePath: {
      get() {
        if (this.$store.getters.selectedImage) {
          return this.$store.getters.selectedImage.path;
        } else {
          return "";
        }
      },
      set(value) {
        this.$store.commit("updateSelectedImagePath", value);
      },
    },
  },
  watch: {
    /**
     * when showImageModel from the store changes
     * @param {boolean} newValue - the value of showImageModal from the store
     */
    "$store.state.signDesigner.showImageModal": function (newValue: boolean) {
      //   const this = this;
      this.showModal = newValue;
    },
  },
});
