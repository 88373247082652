/**
 * Manage css class definitions
 *
 */

export class ClassDefinition {
  private classNames: Set<string>;
  private values: Map<string, string>;
  /**
   *
   * @param strStyle
   * @param startIdx
   * @param closeCurlyIdx
   */
  public constructor(
    strStyle: string,
    startIdx: number,
    closeCurlyIdx: number
  ) {
    this.classNames = new Set<string>();
    this.values = new Map<string, string>();
    const openCurlyIdx: number = strStyle.indexOf("{", startIdx);
    let classNamesStr = strStyle.substring(startIdx, openCurlyIdx);
    classNamesStr = classNamesStr.trim(); // remove leading and training whitespace"
    const classNames = classNamesStr.split(",");

    for (let i = 0; i < classNames.length; i++) {
      const className: string = classNames[i].trim();

      if (className.length > 0 && !this.classNames.has(className)) {
        this.classNames.add(className);
      }
    }

    const valuesStr = strStyle.substring(openCurlyIdx + 1, closeCurlyIdx);
    const values = valuesStr.split(";");

    for (let i = 0; i < values.length; i++) {
      const item = values[i].split(":");
      if (item.length === 2) {
        const varName = item[0].trim();
        const value = item[1].trim();
        if (varName.length > 0 && !this.values.has(varName)) {
          this.values.set(varName, value);
        }
      }
    }
  }

  /**
   *
   * @param classId
   */

  private properClassId(classId: string): string {
    return classId.substring(0, 1) === "." ? classId : "." + classId;
  }

  /**
   *  does the class definition have classId?
   * @param classId
   * @returns boolean
   */
  public hasClassAt(classId: string): boolean {
    const className = this.properClassId(classId);
    return this.classNames.has(className);
  }

  /**
   *
   * @param classId = Add the classId to the listof classNames
   */
  public setClassAt(classId: string): void {
    const className = this.properClassId(classId);
    if (className.length > 0 && !this.classNames.has(className)) {
      this.classNames.add(className);
    }
  }

  /**
   * Get the value for the specified value name
   * @param valueName
   * @returns string | undefined
   */
  public valueAt(valueName: string): string | undefined {
    return this.values.get(valueName);
  }

  /**
   * Is the specified valueName exist?
   *
   * @param valueName
   */

  public hasValueAt(valueName: string): boolean {
    return this.values.has(valueName);
  }

  /**
   * Set the value for the specified valueName
   * @param valueName
   * @param value
   */
  public setValueAt(valueName: string, value: string): void {
    this.values.set(valueName, value);
  }

  /**
   * Generate a list of classNamesStr for the ClassDefinition
   * @return string
   */
  public classNamesStr(): string {
    const classNames: string[] = this.classNamesArray();
    return classNames.join(", ");
  }

  /**
   * return an array of class Names for the classDefinition
   * @return string[]
   */
  public classNamesArray(): string[] {
    const classNames: string[] = [];
    for (const className of this.classNames) {
      classNames.push(className);
    }
    return classNames;
  }

  /**
   * return a string of classNames and values
   * @returns string
   */

  public ValuesStr(): string {
    let retval = "";

    for (const [key, value] of this.values) {
      retval += ` ${key}: ${value};`;
    }
    return retval;
  }

  /**
   * returns a list of class names and values enclosed in {}
   */
  public toString(): string {
    // emit the list of class names
    return this.classNamesStr() + " {" + this.ValuesStr() + "}";
  }
}
