



















































//import SaTree from "./SATree"
import Vue from "vue";
//eslint-disable-next-line
import { Component, SignTreeData, iSignChild } from '../types'
//import CollapsibleSection from "./CollapsibleSection.vue";

//import SaComponentTree from "./SAComponentTree.vue";
//import SaTree from "./SATree.vue";
import LayersPanel from "./panels/LayersPanel.vue";
import AssetsPanel from "./panels/AssetsPanel.vue";
import ConnectionsPanel from "./panels/ConnectionsPanel.vue";
import MarketplacePanel from "./panels/MarketplacePanel.vue";

import { featureAvailable, uuidv4 } from "../utilities";

export default Vue.extend({
  components: {
    LayersPanel,
    AssetsPanel,
    ConnectionsPanel,
    MarketplacePanel,
  },
  props: {
    componentData: Array,
    treeData: Object,
    signData: Object,
  },
  data: function () {
    return {};
  },
  methods: {
    /**
     * handle the process selected event
     */
    onProcessSelected(process: iSignChild) {
      this.$emit("processSelected", process);
    },
    /**
     * handle the tree item clicked event
     * @param {Event} event - the event which triggered this
     */
    treeItemClicked: function (event: Event) {
      this.$emit("elementSelected", event);
    },
    /**
     * handle the bus event
     * @param {Event} event - the event which triggered this
     */
    treeBus: function (event: Event) {
      this.$emit("elementSelected", event);
    },
    /**
     * process the element visibility toggled event
     */
    elementVisibilityToggled: function (event: iSignChild) {
      this.$emit("elementVisibilityToggled", event);
    },
    /**
     * process the add component event
     */
    addComponent: function () {
      //get an array of the selected elements saDataId's
      let elements = [] as Array<SignTreeData>;
      this.getSelectedElements(this.$store.getters.treeData.children, elements);

      //            this.$store.commit('inactivateAllComponents')
      this.$store.commit("addComponent", {
        id: uuidv4(),
        name: "Component 1",
        isActive: true,
        isEditing: true,
        isVisual: true,
        elements: elements,
      });
    },
    /**
     * return an array of selected elements
     * @param {Array<SignTreeData>} items - the sign tree data to search through
     * @param {Array<SignTreeData} elementsArray - the array of elements that are selected
     */
    getSelectedElements: function (
      items: SignTreeData[],
      elementsArray: Array<SignTreeData>
    ) {
      items.forEach((item) => {
        if (item.isSelected) {
          elementsArray.push(item);
        }
        if (item.children && item.children.length > 0) {
          this.getSelectedElements(item.children, elementsArray);
        }
      });
    },
  },
  computed: {
    marketplaceAvailable: function (): boolean {
      return featureAvailable(this, "marketplace");
    },
  },

  watch: {},
});
