



















































































































import Vue from "vue";
import {
  IOrderDetailSign,
  IOrderDetail,
  IOrderSignType,
  ORDER_STATUS,
} from "../types";
import { toCurrency } from "../utilities";
import signDetailModal from "../modals/signDetailModal.vue";

export default Vue.extend({
  components: { signDetailModal },
  props: {
    order: Object,
    allowQuote: Boolean,
    includeSignsForReviewStates: Array, // an array of 'N','A', and/or 'R' which are our three review states. Only signs with a review state matching one in the array will be displayed
    gridSize: Number,
  },
  data: function () {
    return {
      localOrder: this.order,
      selectedItems: [] as Array<IOrderDetailSign>,
      selectedSignId: "",
      panelHeight: 0,
    };
  },
  mounted: function () {
    // initial resize
    this.fitPanelIntoParentContainer();

    this.localOrder = this.order;

    // add event listener for resize event
    window.addEventListener("resize", this.fitPanelIntoParentContainer);
  },
  methods: {
    getTextColor: function (hexColor: string) {
      if (hexColor.substr(0, 1) === "#") {
        hexColor = hexColor.slice(1);
      }
      const r = parseInt(hexColor.slice(0, 2), 16);
      const g = parseInt(hexColor.slice(2, 4), 16);
      const b = parseInt(hexColor.slice(4, 6), 16);
      if (0.213 * r + 0.715 * g + 0.072 * b > 127) {
        return "#000000";
      } else {
        return "#FFFFFF";
      }
    },
    toggleReviewState: function (sign: {
      id: string;
      quantity: number;
      reviewState: string;
    }) {
      if (sign.reviewState === "A") {
        const data = { signId: sign.id, reviewState: "R" };
        this.$store.commit("updateReviewStateForSignId", data);
      } else {
        const data = { signId: sign.id, reviewState: "A" };
        this.$store.commit("updateReviewStateForSignId", data);
      }
    },
    signsInReviewState: function (
      signs: Array<{
        id: string;
        quantity: number;
        reviewState: string;
        signId: string;
      }>
    ) {
      const IncludeBuild = signs.filter((sign) => {
        return this.includeSignsForReviewStates.includes(sign.reviewState);
      });
      return IncludeBuild;
    },
    removedSignTypes: function (): Array<IOrderSignType> {
      let arr = [] as Array<IOrderSignType>;

      this.$store.getters.order.signTypes.forEach(
        (signType: IOrderSignType) => {
          //only add sign types that have signs associated with them
          if (this.signsInReviewState(signType.signs).length > 0) {
            arr.push(signType);
          }
        }
      );

      return arr;
    },
    fitPanelIntoParentContainer: function () {
      this.panelHeight = window.innerHeight - 530;

      if (this.panelHeight < 100) {
        this.panelHeight = 100;
      }
    },
    calculateQuantity: function (orderItem: IOrderSignType): number {
      let total = 0;
      orderItem.signs.forEach((sign) => {
        // only include Approved signs in the total calculation
        if (sign.reviewState === "A") {
          total += sign.quantity;
        }
      });
      return total;
    },
    toggleDetailField: function (orderItem: IOrderDetail) {
      orderItem.isDetailExpanded = !orderItem.isDetailExpanded;
    },
    convertCurrency: function (value: number, currencyCode: string) {
      return toCurrency(value, currencyCode);
    },
    /**
     * return a portion of an array
     * @param {Array<iCatalog>} array - the full array
     * @param {number} size - the number of elements per "chunk"
     * @returns {Array} an array of items that is "size" elements long
     */
    chunkSignsArray: function (array: Array<IOrderDetailSign>, size: number) {
      let result = [];
      for (let i = 0; i < array.length; i += size) {
        let chunk = array.slice(i, i + size);
        result.push(chunk);
      }
      return result;
    },
    showDetails: function (
      items: Array<IOrderDetailSign>,
      selectedSignId: string
    ) {
      this.selectedItems = items;
      this.selectedSignId = selectedSignId;

      this.$store.commit(
        "setShowSignDetailsModal",
        !this.$store.getters.showSignDetailsModal
      );
    },
  },
  computed: {
    ORDER_STATUS: function () {
      return ORDER_STATUS;
    },
  },
});
