













































































































































































































































































































import Vue from "vue";
import { IOrderSignType, ORDER_STATUS } from "../types";
import { toCurrency } from "../utilities";

export default Vue.extend({
  props: {
    order: Object,
    showSignTypes: Boolean,
  },
  data: function () {
    return {
      expandOrderSummary: false,
    };
  },
  mounted: function () {
    this.expandOrderSummary = this.showSignTypes;
  },
  methods: {
    showOrderFooter: function () {
      // don't show footer if we are in DRAFT status
      if (this.$store.getters.order.status === ORDER_STATUS.DRAFT) {
        return false;
      }
      // don't show the footer if we are in QUOTING status and the user manager
      if (
        this.$store.getters.order.status === ORDER_STATUS.QUOTING &&
        this.$store.getters.order.manager.id ===
          this.$store.state.session.userId
      ) {
        return false;
      }
      return true;
    },
    /**
     * calls the toCurrency utility class.
     * We need this because the linter won't let us do a clean compile because it thinks
     * the toCurrency function is never used if it only used in the Vue template.
     * Putting it in a method makes the linter happy
     * @param value number - the amount
     * @param currencyCode string - An ISO compliant currency code (i.e. CAD, USD, etc.)
     * @returns string - A formatted string with the proper currency symbol and punctuation
     */
    convertCurrency: function (value: number, currencyCode: string) {
      return toCurrency(value, currencyCode);
    },
    getTextColor: function (hexColor: string) {
      if (hexColor.substr(0, 1) === "#") {
        hexColor = hexColor.slice(1);
      }
      const r = parseInt(hexColor.slice(0, 2), 16);
      const g = parseInt(hexColor.slice(2, 4), 16);
      const b = parseInt(hexColor.slice(4, 6), 16);
      if (0.213 * r + 0.715 * g + 0.072 * b > 127) {
        return "#000000";
      } else {
        return "#FFFFFF";
      }
    },
    /**
     * calculate the total quantity of all the signs in a sign type
     * @param orderItem IOrderSignType - a sign type
     * @returns number - the total quantity of all the "approved" signs in this sign type
     */
    calculateQuantity: function (orderItem: IOrderSignType): number {
      let total = 0;
      orderItem.signs.forEach((sign) => {
        // only include Approved signs in the total calculation
        // if (sign.reviewState === "A") {
        total = total + sign.quantity;
        // }
      });
      return total;
    },
    displayPricing: function () {
      // Don't show pricing if:
      //    order is in DRAFT status
      //    order is in QUOTING status and the user isn't the builder
      if (this.$store.getters.order.status === ORDER_STATUS.DRAFT) {
        return false;
      }

      if (this.$store.getters.order.status === ORDER_STATUS.QUOTING) {
        if (!this.$store.getters.order.userIsBuilder) {
          return false;
        }
      }

      return true;
    },
  },
  computed: {
    /**
     * calculate the total amount for all sign types on the order
     * @returns Number total - the total of all the rows on the order
     */
    itemSubtotal: function (): number {
      let grandTotal = 0;

      if (this.order && this.order.signTypes) {
        this.order.signTypes.forEach((signType: IOrderSignType) => {
          // the HTMLInputElement is returning a string so when we do an addition the string is concatenated giving us strange results
          // here we are checking the typeof the var and doing a parseFloat if it is a string.  We can't just do a parseFloat because
          // typescript won't let us, it throws a:
          //             "Argument of type 'number' is not assignable to parameter of type 'string'.Vetur(2345)"
          // error
          let total = 0;
          if (typeof signType.actual_price === "number") {
            total += signType.actual_price;
          } else {
            total += parseFloat(signType.actual_price);
          }
          // doing the same as above with the install price field
          if (typeof signType.install_price === "number") {
            total += signType.install_price;
          } else {
            total += parseFloat(signType.install_price);
          }

          total = total * this.calculateQuantity(signType);
          grandTotal += total;
        });
      }
      return grandTotal;
    },
    ORDER_STATUS: function () {
      return ORDER_STATUS;
    },
    itemTotal: function (): number {
      let value = 0;
      if (this.$store.getters.order) {
        value =
          this.itemSubtotal +
          Number(this.$store.getters.order.shipping) +
          Number(this.$store.getters.order.taxes.amount);
      }
      return value;
    },
    sortedSignTypes: function (): Array<IOrderSignType> {
      const signTypes = JSON.parse(
        JSON.stringify(this.$store.getters.order.signTypes)
      );
      signTypes.sort((a: IOrderSignType, b: IOrderSignType) => {
        return a.globalOrderIdVal.localeCompare(b.globalOrderIdVal);
      });
      return signTypes;
    },
  },
});
