





































































































import Vue from "vue";

import { IProductCategory } from "../../types";
import { uuidv4 } from "../../utilities";

export default Vue.extend({
  components: {},
  data: function () {
    return {
      showModal: false,
      selectedProductCategoryIndex: -1,
      editMode: "edit",
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showProductCategoriesModal;
  },
  methods: {
    /**
     * add a product category
     * called when the user clicks the Add product category button
     */
    addProductCategory: function () {
      const productCategory = {
        id: "",
        uuid: uuidv4(),
        name: "New product category",
      } as IProductCategory;
      this.$store.commit("addProductCategory", productCategory);
      this.editMode = "add";
      this.selectedProductCategoryIndex =
        this.$store.getters.productCategories.length - 1;
    },
    /**
     * delete the selected product category
     * called when the trash icon is clicked
     */
    deleteProductCategory(index: number) {
      this.$store.commit("deleteProductCategoryByIndex", index);
    },
    /**
     * handle the Ok button clicked event
     */
    saveChanges: function () {
      //if we are on the details screen
      if (this.selectedProductCategoryIndex >= 0) {
        // write to the back end
        if (this.editMode === "add") {
          fetch("/graphql/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              query: `
                mutation addPartCategory{
                  addSignTypePartCategory(addSignTypePartCategoryInput: {name: "${
                    this.$store.getters.productCategories[
                      this.selectedProductCategoryIndex
                    ].name
                  }", organizationId: ${this.$store.getters.organizationId}}) {
                    partCategory {
                      id: contentObjectId
                      uuid
                      name
                    }
                  }
                }
              `,
            }),
          }).then((result) => result.json());
        } else {
          fetch("/graphql/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              query: `
                mutation updatePartCategory {
                  updateSignTypePartCategory(updateSignTypePartCategoryInput: {id:"${
                    this.$store.getters.productCategories[
                      this.selectedProductCategoryIndex
                    ].id
                  }", name:"${
                this.$store.getters.productCategories[
                  this.selectedProductCategoryIndex
                ].name
              }"}) {
                    partCategory {
                      name
                    }
                  }
                }
              `,
            }),
          }).then((result) => result.json());
        }

        this.editMode = "edit";
        //move back to list screen, don't close modal
        this.selectedProductCategoryIndex = -1;
      } else {
        //otherwise we are on the list screen, close modal
        this.$store.commit("setShowProductCategoriesModal", false);
      }
    },
    /**
     * close the entire modal
     */
    closeModal: function () {
      this.selectedProductCategoryIndex = -1;
      this.$store.commit("setShowProductCategoriesModal", false);
    },
    /**
     * close a page
     */
    closePage: function () {
      //if we are on the details screen
      if (this.selectedProductCategoryIndex >= 0) {
        //move back to list screen, don't close modal
        this.selectedProductCategoryIndex = -1;
      } else {
        //otherwise we are on the list screen, close modal
        this.$store.commit("setShowProductCategoriesModal", false);
      }
    },
    /**
     * set the selected product category index
     * @param {number} productCategoryIndex - the array index of the product category to edit
     */
    editProductCategory: function (productCategoryIndex: number) {
      this.editMode = "edit";
      this.selectedProductCategoryIndex = productCategoryIndex;
    },
  },
  computed: {
    /**
     * getter/setter for name
     */
    name: {
      get(): string {
        return this.$store.getters.productCategories[
          this.selectedProductCategoryIndex
        ].name;
      },
      set(value: string) {
        this.$store.commit("updateProductCategoryNameByIndex", {
          value: value,
          index: this.selectedProductCategoryIndex,
        });
      },
    },
    /**
     * getter/setter for image URL
     */
    imageURL: {
      get(): string {
        return this.$store.getters.productCategories[
          this.selectedProductCategoryIndex
        ].imageURL;
      },
      set(value: string) {
        this.$store.commit("updateProductCategoryImageURLByIndex", {
          value: value,
          index: this.selectedProductCategoryIndex,
        });
      },
    },
  },
  watch: {
    /**
     * watch showProductCategoriesModal from the store
     * @param {boolean} newValue - the value of showProductCategoriesModal from the store
     */
    "$store.state.signDesigner.showProductCategoriesModal": function (
      newValue
    ) {
      //   const this = this;
      this.showModal = newValue;
    },
  },
});
