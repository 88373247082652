




















































import Vue from "vue";
import { Chrome } from "vue-color";
import { STATE_TYPE } from "../utilities";

export default Vue.extend({
  components: { ChromePicker: Chrome },
  props: ["color", "stateName", "overrideColor", "stateType"],
  data: function () {
    return {
      colors: {
        hex: "#194d33",
      },
      colorValue: "",
      displayPicker: false,
      localName: "",
      localOverride: false,
    };
  },
  mounted() {
    this.setColor(this.color || "#000000");
  },
  methods: {
    nameChanged: function () {
      this.$emit("update:stateName", this.localName);
    },
    overrideColorChanged: function () {
      this.$emit("update:overrideColor", this.localOverride);
    },
    setColor(color: string) {
      this.updateColors(color);
      this.colorValue = color;
    },
    updateColors(color: string) {
      if (color.slice(0, 1) == "#") {
        this.colors = {
          hex: color,
        };
      } else if (color.slice(0, 4) == "rgba") {
        var rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(","),
          hex =
            "#" +
            (
              (1 << 24) +
              (parseInt(rgba[0]) << 16) +
              (parseInt(rgba[1]) << 8) +
              parseInt(rgba[2])
            )
              .toString(16)
              .slice(1);
        this.colors = {
          hex: hex,
        };
      }
    },
    showPicker() {
      document.addEventListener("click", this.documentClick);
      this.displayPicker = true;
    },
    hidePicker() {
      document.removeEventListener("click", this.documentClick);
      this.displayPicker = false;
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
    },
    updateFromInput() {
      // this.updateColors(this.colorValue);
    },
    updateFromPicker(color: {
      rgba: { a: number; r: number; g: number; b: number };
      hsv: { h: number; s: number; v: number; a: number };
      hsl: { h: number; s: number; l: number; a: number };
      hex: string;
      a: number;
    }) {
      this.colors = color;
      if (color.rgba.a == 1) {
        this.colorValue = color.hex;
      } else {
        this.colorValue =
          "rgba(" +
          color.rgba.r +
          ", " +
          color.rgba.g +
          ", " +
          color.rgba.b +
          ", " +
          color.rgba.a +
          ")";
      }
    },
    documentClick(e: Event) {
      const el = this.$refs.colorpicker as Node;
      const target = e.target as Node;

      if (el !== target && !el?.contains(target)) {
        // && !el?.contains(target)) {
        this.hidePicker();
      }
    },
  },
  computed: {
    STATE_TYPE: function () {
      return STATE_TYPE;
    },
    sName: {
      get(): string {
        return this.stateName;
      },
      set(value: string) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    colorValue(val) {
      if (val) {
        this.updateColors(val);
        this.$emit("update:color", val);
        //document.body.style.background = val;
      }
    },
    stateName(val) {
      this.localName = val;
    },
    overrideColor(val) {
      this.localOverride = val;
    },
    color(val) {
      this.colorValue = val;
    },
  },
});
