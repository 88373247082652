



























































































import Vue from "vue";
import $ from "jquery";

import OrderSummaryPanel from "../components/OrderSummaryPanel.vue";

export default Vue.extend({
  components: { OrderSummaryPanel },
  props: {
    showModal: Boolean,
    email: String,
  },
  data: function () {
    return {
      builderEmail: "",
      saveError: false,
      errorMessage: "",
      validEmailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  methods: {
    /**
     * return the appropriate class depending on if we have a valid email address or not
     * Note: this only tests the syntax of the address.
     * @param {string} email - the email to test
     * @returns {string} - empty if the email is empty, 'has-success' if email passes regex test, otherwise 'has-error'
     */
    setEmailClass: function (email: string): string {
      return email === ""
        ? "has-error"
        : this.validEmailReg.test(email)
        ? "has-success"
        : "has-error";
    },
    disableConfirmButton: function () {
      if (this.builderEmail.length === 0) {
        return true;
      } else if (!this.validEmailReg.test(this.builderEmail)) {
        return true;
      }
      return false;
    },
    confirmClicked: function () {
      if (this.setEmailClass(this.builderEmail) === "has-error") {
        this.saveError = true;
        this.errorMessage = "Invalid email address entered";
      } else {
        $("#submitQuoteConfirmedModal").modal("hide");
        this.$emit("submitClicked", this.builderEmail);
      }
    },
    closeModal: function () {
      $("#submitQuoteConfirmedModal").modal("hide");
      this.$emit("closeClicked");
    },
  },
  watch: {
    showModal: function (newValue) {
      if (newValue) {
        this.builderEmail = this.$store.getters.order.builder.email
          ? this.$store.getters.order.builder.email
          : this.email;
        $("#submitQuoteConfirmedModal").on("hidden.bs.modal", () => {
          this.closeModal();
        });
        $("#submitQuoteConfirmedModal").modal("show");
      } else {
        $("#submitQuoteConfirmedModal").off("hidden.bs.modal");
        $("#submitQuoteConfirmedModal").modal("hide");
      }
    },
  },
});
