






































































































































































































































































import Vue from "vue";

import { SvgCss } from "../../include/SvgCss";
import { iSignChild } from "../../types";
import { convertPointsToUnit, convertUnitToPoints } from "../../utilities";

import CollapsibleSection from "../CollapsibleSection.vue";
import MeasurementInput from "../MeasurementInput.vue";

export default Vue.extend({
  components: { CollapsibleSection, MeasurementInput },
  data: function () {
    return {
      SvgCss: {},
      minLines: 1,
      maxLines: 99,
    };
  },
  methods: {
    /**
     * find the signChild element by id
     * @param {iSignChild} element - the iSignChild element to start searching the tree from
     * @param {string} id - the sign child id we are looking for
     * @returns {iSignChild | null} - the found iSignChild or null if none found
     */
    findElementById: function (
      element: iSignChild,
      id: string
    ): iSignChild | null {
      if (element.id == id) {
        return element;
      } else if (element.children != null) {
        var i;
        var result = null;
        for (i = 0; result == null && i < element.children.length; i++) {
          result = this.findElementById(element.children[i], id);
        }
        return result;
      }
      return null;
    },
    /**
     * set the vertical alignment
     * @param {string} alignment - Vertical alignment string (bottom | middle | top)
     */
    setVerticalAlignment: function (alignment: string) {
      this.verticalAlignment = alignment;
      this.$store.commit("setRerender", !this.$store.getters.rerender);
    },
    /**
     * set the horizontal alignment
     * @param {string} alignment - Horizontal alignment string (left | center | right)
     */
    setHorizontalAlignment: function (alignment: string) {
      this.horizontalAlignment = alignment;
    },
  },
  computed: {
    /**
     * getter/setter for fontFamily
     */
    fontFamily: {
      get(): string {
        let el = this.findElementById(
          this.$store.getters.signData.sides[this.$store.getters.currentSide],
          this.$store.getters.selectedElementIds[0]
        );
        //use the first font-family name
        if (el) {
          return el.fontInfo.fontFamily.split(",")[0];
        } else {
          return "";
        }
      },
      set(value: string) {
        let el = this.findElementById(
          this.$store.getters.signData.sides[this.$store.getters.currentSide],
          this.$store.getters.selectedElementIds[0]
        );
        if (el) {
          el.fontInfo.fontFamily = value;
        }
        let textElement = this.$store.getters.canvas.select(
          "[sa-data-id='" + this.$store.getters.selectedElementIds[0] + "']"
        );
        // .attr({ "font-family": value });
        let className = textElement.attr("class");
        let svgCSS = SvgCss.getInstance();
        if (svgCSS) {
          svgCSS.setClassVariableValue(className, "font-family", value);
        }
      },
    },
    /**
     * getter/setter for fontWeight
     */
    fontWeight: {
      get(): string {
        let el = this.findElementById(
          this.$store.getters.signData.sides[this.$store.getters.currentSide],
          this.$store.getters.selectedElementIds[0]
        );
        if (el) {
          if (el.fontInfo.fontWeight) {
            return el.fontInfo.fontWeight;
          } else {
            return "normal";
          }
        } else {
          return "normal";
        }
      },
      set(value: string) {
        let el = this.findElementById(
          this.$store.getters.signData.sides[this.$store.getters.currentSide],
          this.$store.getters.selectedElementIds[0]
        );
        if (el) {
          el.fontInfo.fontWeight = value;
        }
        this.$store.getters.canvas
          .select(
            "[sa-data-id='" + this.$store.getters.selectedElementIds[0] + "']"
          )
          .attr({ "font-weight": value });
      },
    },
    /**
     * getter/setter for fontSize
     */
    fontSize: {
      get(): number {
        let el = this.findElementById(
          this.$store.getters.signData.sides[this.$store.getters.currentSide],
          this.$store.getters.selectedElementIds[0]
        );
        // let el = this.$store.getters.canvas.select(
        //   "[sa-data-id='" + this.$store.getters.selectedElementIds[0] + "']"
        // );
        if (el) {
          //use the first font-family name
          return convertPointsToUnit(
            parseFloat(el.fontInfo.fontSize),
            this.$store.getters.signData.measurementUnit.unit
          );
        } else {
          return convertPointsToUnit(
            parseFloat("12px"),
            this.$store.getters.signData.measurementUnit.unit
          );
        }
      },
      set(value: string) {
        const size = convertUnitToPoints(
          parseFloat(value),
          this.$store.getters.signData.measurementUnit.unit
        );

        //update font size in the elements font info
        let el = this.findElementById(
          this.$store.getters.signData.sides[this.$store.getters.currentSide],
          this.$store.getters.selectedElementIds[0]
        );

        // const myElement = this.$store.getters.canvas.select(
        //   "[sa-data-id='" + this.$store.getters.selectedElementIds[0] + "']"
        // );

        if (el) {
          el.fontInfo.fontSize = size.toString() + "px";
        }
        let svgCSS = SvgCss.getInstance();
        let className = "";
        if (svgCSS) {
          className = svgCSS.addNextClsClass(
            this.$store.getters.canvas,
            "font-size",
            size.toString()
          );
        }

        const existingClass = this.$store.getters.canvas
          .select(
            "[sa-data-id='" + this.$store.getters.selectedElementIds[0] + "']"
          )
          .attr("class");

        this.$store.getters.canvas
          .select(
            "[sa-data-id='" + this.$store.getters.selectedElementIds[0] + "']"
          )
          .attr("class", className + " " + existingClass);
      },
    },
    capHeight: {
      get(): number {
        let el = this.findElementById(
          this.$store.getters.signData.sides[this.$store.getters.currentSide],
          this.$store.getters.selectedElementIds[0]
        );
        // let el = this.$store.getters.canvas.select(
        //   "[sa-data-id='" + this.$store.getters.selectedElementIds[0] + "']"
        // );
        if (el) {
          //use the first font-family name
          return convertPointsToUnit(
            parseFloat(el.fontInfo.fontSize),
            this.$store.getters.signData.measurementUnit.unit
          );
        } else {
          return convertPointsToUnit(
            parseFloat("12px"),
            this.$store.getters.signData.measurementUnit.unit
          );
        }
      },
    },
    leading: {
      get(): number {
        if (this.$store.getters.selectedField) {
          if (!this.$store.getters.selectedField.leading) {
            return this.capHeight;
          } else {
            return convertPointsToUnit(
              parseFloat(this.$store.getters.selectedField.leading),
              this.$store.getters.signData.measurementUnit.unit
            );
          }
        } else {
          return this.capHeight;
        }
      },
      set(value: number) {
        // ensure that whatever the user entered is in range
        this.$store.commit(
          "updateSelectedFieldLeading",
          convertUnitToPoints(
            value,
            this.$store.getters.signData.measurementUnit.unit
          )
        );

        // // update the svg
        // updateSVGDataName(
        //   this.$store.getters.selectedField.elementIds[0],
        //   this.$store.getters.selectedField.type,
        //   "leading: " + this.$store.getters.selectedField.leading,
        //   " leading: " +
        //     value +
        //     " " +
        //     this.$store.getters.signData.measurementUnit.unit,
        //   this.$store.getters.canvas
        // );

        this.$store.commit("refreshCodeEditor");

        this.$nextTick(function () {
          this.$store.commit("setRerender", !this.$store.getters.rerender);
        });
      },
    },
    lines: {
      get(): number {
        if (this.$store.getters.selectedField) {
          return this.$store.getters.selectedField.lines;
        } else {
          return 1;
        }
      },
      set(value: number) {
        this.$store.commit("updateSelectedFieldLines", value);
        this.$nextTick(function () {
          this.$store.commit("setRerender", !this.$store.getters.rerender);
        });
      },
    },
    /**
     * getter/setter for letter spacing
     */
    letterSpacing: {
      get() {
        if (this.$store.getters.selectedField) {
          return this.$store.getters.selectedField.letterSpacing;
        } else {
          return "0";
        }
      },
    },
    /**
     * getter/setter for verticalAlignment
     */
    verticalAlignment: {
      get(): string {
        if (this.$store.getters.selectedField) {
          return this.$store.getters.selectedField.verticalAlignment;
        } else {
          return "top";
        }
      },
      set(value: string) {
        // update the svg
        // updateSVGDataName(
        //   this.$store.getters.selectedField.elementIds[0],
        //   this.$store.getters.selectedField.type,
        //   this.$store.getters.selectedField.verticalAlignment,
        //   value,
        //   this.$store.getters.canvas
        // );
        this.$store.commit("updateSelectedFieldVerticalAlignment", value);
        this.$store.commit("refreshCodeEditor");
      },
    },
    /**
     * getter/setter for horizontalAlignment
     */
    horizontalAlignment: {
      get(): string {
        if (this.$store.getters.selectedField) {
          return this.$store.getters.selectedField.horizontalAlignment;
        } else {
          return "left";
        }
      },
      set(value: string) {
        // const el = this.$store.getters.canvas.select(
        //   "[sa-data-id='" +
        //     this.$store.getters.selectedField.elementIds[0] +
        //     "']"
        // );

        // make a deep copy of the element so we don't get vuex mutation errors
        // const el1 = $.extend(true, {}, el);
        // alignText(value, el1);

        // updateSVGDataName(
        //   this.$store.getters.selectedField.elementIds[0],
        //   this.$store.getters.selectedField.type,
        //   this.$store.getters.selectedField.horizontalAlignment,
        //   value,
        //   this.$store.getters.canvas
        // );
        this.$store.commit("updateSelectedFieldHorizontalAlignment", value);
        this.$store.commit("refreshCodeEditor");
      },
    },
    /**
     * getter/setter for width
     */
    width: {
      get(): number {
        if (this.$store.getters.selectedField) {
          return convertPointsToUnit(
            this.$store.getters.selectedField.width,
            this.$store.getters.signData.measurementUnit.unit
          );
        } else {
          return 0;
        }
      },
      set(value: string) {
        //const size = convertUnitToPoints(
        //   parseFloat(value),
        //   this.$store.getters.signData.measurementUnit.unit
        // );

        // const el = this.$store.getters.canvas.select(
        //   "[sa-data-id='" +
        //     this.$store.getters.selectedField.elementIds[0] +
        //     "']"
        // );

        // if (
        //   this.$store.getters.selectedField.horizontalAlignment === "center"
        // ) {
        //   // if the field is centered then adjust the position so that we expand the width in both directions
        //   // x = x - ((user-entered width - current width) / 2)
        //   const x =
        //     this.$store.getters.selectedField.x -
        //     (size - this.$store.getters.selectedField.width) / 2;

        //   updateSVGAttribute(
        //     this.$store.getters.selectedField.elementIds[0],
        //     this.$store.getters.selectedField.type,
        //     "x",
        //     x.toString(),
        //     this.$store.getters.canvas
        //   );
        //   this.$store.commit("updateSelectedFieldX", x);
        // }

        // if (this.$store.getters.selectedField.horizontalAlignment === "right") {
        //   // if the field is right aligned then adjust the position so that we expand the width in left direction
        //   const x =
        //     this.$store.getters.selectedField.x -
        //     (size - this.$store.getters.selectedField.width);

        //   // updateSVGAttribute(
        //   //   this.$store.getters.selectedField.elementIds[0],
        //   //   this.$store.getters.selectedField.type,
        //   //   "x",
        //   //   x.toString(),
        //   //   this.$store.getters.canvas
        //   // );
        //   this.$store.commit("updateSelectedFieldX", x);
        //   alignText(this.$store.getters.selectedField.horizontalAlignment, el);
        // }

        // updateSVGAttribute(
        //   this.$store.getters.selectedField.elementIds[0],
        //   this.$store.getters.selectedField.type,
        //   "width",
        //   size.toString(),
        //   this.$store.getters.canvas
        // );
        this.$store.commit("updateSelectedFieldWidth", value);
        this.$store.commit("setRerender", !this.$store.getters.rerender);
      },
    },
    /**
     * get the signChild element for the first selected element id
     * @returns {iSignChild | null} the found signChild or null if none found
     */
    element: function (): iSignChild | null {
      return this.findElementById(
        this.$store.getters.signData.sides[this.$store.getters.currentSide],
        this.$store.getters.selectedElementIds[0]
      );
    },
    /**
     * should the section be shown open?
     * @returns {boolean} True if section should be shown open
     */
    openSection: function () {
      if (this.element) {
        if (this.element.fieldType === "text") {
          return true;
        }
      }
      return false;
    },
  },
  watch: {},
});
