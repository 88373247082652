

















import { Component, Vue, Prop, Watch } from "vue-property-decorator";

// use these globals from Django
declare global {
  const set_details_size: (size: string) => void;
  const set_signbar_size: (size: string) => void;
}

/**
 * Template for a full page route
 */
@Component
export default class MapViewLayout extends Vue {
  /**
   * The desired view size: "small" (default), or "large")
   */
  @Prop({
    type: String,
    default: "small",
    validator: (size) => ["small", "large"].includes(size),
  })
  readonly size!: "small" | "large";

  /**
   * The desired signbar size: "hidden", "small" (default), or "large"
   */
  @Prop({
    type: String,
    default: "small",
    validator: (size) => ["hidden", "small", "large"].includes(size),
  })
  readonly signbar!: "hidden" | "small" | "large";

  /**
   * Should the loader be displayed: true or false (default)
   */
  @Prop({
    type: Boolean,
    default: false,
  })
  readonly loading!: boolean;

  /**
   * Called when component is initialized
   */
  mounted(): void {
    // eslint-disable-next-line no-undef
    set_details_size(this.size);

    // eslint-disable-next-line no-undef
    set_signbar_size(this.signbar);

    document
      .getElementById("details")
      ?.classList.toggle("details--waiting", this.loading);
  }

  /**
   * Update Django-side JS if we want to change the view size
   */
  @Watch("size")
  onSizeChanged(size: string): void {
    // eslint-disable-next-line no-undef
    set_details_size(size);
  }

  /**
   * Update Django-side JS if we want to change the signbar size/visibility
   */
  @Watch("signbar")
  onSignbarChanged(signbar: string): void {
    // eslint-disable-next-line no-undef
    set_signbar_size(signbar);
  }

  /**
   * Toggle the loader
   */
  @Watch("loading")
  onLoadingChanged(loading: boolean): void {
    document
      .getElementById("details")
      ?.classList.toggle("details--waiting", loading);
  }
}
