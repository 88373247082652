import { isModelKey, ModelKey, MODELS, PermObject, UserPerm } from "../types";
import { objectSortFunctionForObject, userSortFunction } from "../utilities";

/**
 * Load initial data from GraphQL
 *
 * @param model - the ModelKey of the object we want to display
 * @param id  - the id of the object we want to display
 * @returns A Promise which completes when the data is loaded. The Promise returns a sorted array of PermObjects
 */
export default async function getInitialData(
  model: ModelKey,
  id: number
): Promise<Array<PermObject>> {
  const extraModelQueries = [];
  for (const modelPermission of Object.values(MODELS)) {
    if (modelPermission.extraQuery) {
      extraModelQueries.push(modelPermission.extraQuery);
    }
  }
  const query = `
    query Permissions($objectId: ID) {
      permissions: ${model}(id: $objectId) {
        groups {
          pageInfo { hasNextPage }
          edges {
            node {
              name
              contentType
              contentObjectId
              ${
                extraModelQueries.length
                  ? `contentObject {
                ${extraModelQueries.join(`
                `)} }`
                  : ""
              }
            }
          }
        }
        groupUsers {
          pageInfo { hasNextPage }
          edges {
            node {
              user {
                userId: id
                firstName
                lastName
                email
                pic
              }
              group {
                name
                contentType
                contentObjectId
              }
            }
          }
        }
      }
    }
  `;

  const data = await (
    await fetch("/graphql/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        query,
        variables: { objectId: id },
      }),
    })
  )
    .json()
    .catch((err) => {
      console.log(err);
      throw new Error("Error downloading data");
    });

  const permObjectDict: {
    [modelKey in ModelKey]?: { [modelId: string]: PermObject };
  } = {};

  if (data.errors) {
    console.error("Expected no errors, got", data.errors);
    throw new Error("Error downloading data");
  }

  if (data.data?.permissions?.groups?.pageInfo?.hasNextPage) {
    console.error(
      "Expected 'data.permissions.groups.pageInfo.hasNextPage' to be false, got",
      data.data?.permissions?.groups?.pageInfo?.hasNextPage
    );
    throw new Error("Error downloading data");
  }

  const returnedObjects = Object.freeze(data.data?.permissions?.groups?.edges);
  if (!Array.isArray(returnedObjects)) {
    console.error(
      "Expected 'data.permissions.groups.edges' to be an array, got",
      returnedObjects
    );
    throw new Error("Error downloading data");
  }

  for (const returnedObjectNode of returnedObjects) {
    const returnedObject = returnedObjectNode?.node;

    if (typeof returnedObject !== "object") {
      console.error(
        "Expected items in 'data.permissions.groups.edges.node[]' to be objects, got",
        returnedObject
      );
      continue;
    }

    const { name, contentType, contentObjectId, contentObject } =
      returnedObject;

    if (typeof name != "string" || !name) {
      console.error(
        "Expected 'data.permissions.groups.edges.node[].name' to be a non-empty string, got",
        name
      );
      continue;
    }

    if (typeof contentType != "string" || !isModelKey(contentType)) {
      console.error(
        "Expected 'data.permissions.groups.edges.node[].contentType' to be a ModelKey, got",
        contentType
      );
      continue;
    }

    if (typeof contentObjectId != "string" || !contentObjectId) {
      console.error(
        "Expected 'data.permissions.groups.edges.node[].contentObjectId' to be a non-empty string, got",
        contentObjectId
      );
      continue;
    }

    if (typeof contentObject != "object" || !contentObject) {
      console.error(
        "Expected 'data.permissions.groups.edges.node[].contentObject' to be an object, got",
        contentObject
      );
      continue;
    }

    if (typeof contentObject.canEdit != "boolean") {
      console.error(
        "Expected 'data.permissions.groups.edges.node[].contentObject.canEdit' to be boolean, got",
        contentObject.canEdit
      );
      continue;
    }
    const canEdit: boolean = returnedObject.contentObject.canEdit;

    // permission name must be defined in MODELS
    if (typeof name != "string" || !MODELS[contentType].permissions.has(name)) {
      console.error(
        `Expected 'data.permissions.groups.edges.node[].name' to be a permission of "${contentType}", got`,
        name
      );
      continue;
    }

    if (!permObjectDict[contentType]?.[contentObjectId]) {
      let name = null;
      const sectionTitleFunction = MODELS[contentType].sectionTitle;
      if (typeof sectionTitleFunction == "function") {
        name = sectionTitleFunction(contentObject) || null;
      }

      let url: string | null = null;
      const getUrlFunction = MODELS[contentType].getUrl;
      if (typeof getUrlFunction == "function") {
        url = getUrlFunction(contentObject) || null;
      }

      if (!permObjectDict[contentType]) {
        permObjectDict[contentType] = {};
      }
      const permObjectDict_type: { [modelId: string]: PermObject } =
        permObjectDict[contentType] || {};
      permObjectDict_type[contentObjectId] = {
        object: contentObject,
        objectType: contentType,
        objectId: contentObjectId,
        groups: Array<string>(),
        canEdit: canEdit,
        name: name,
        url: url,
        userPerms: Array<UserPerm>(),
      };
    }

    const permObject =
      permObjectDict[contentType]?.[returnedObject.contentObjectId];
    permObject?.groups.push(returnedObject.name);
  }

  // Loop through actual permissions
  if (data.data?.permissions?.groupUsers?.pageInfo?.hasNextPage) {
    console.error(
      "Expected 'data.permissions.groupUsers.pageInfo.hasNextPage' to be false, got",
      data.data?.permissions?.groupUsers?.pageInfo?.hasNextPage
    );
    throw new Error("Error downloading data");
  }

  const returnedPerms = Object.freeze(data.data.permissions?.groupUsers?.edges);

  if (!Array.isArray(returnedPerms)) {
    console.error(
      "Expected 'data.permissions.groupUsers.edges' to be an array, got",
      returnedPerms
    );
  }

  for (const returnedPermNode of returnedPerms) {
    const returnedPerm = returnedPermNode?.node;

    if (typeof returnedPerm !== "object") {
      console.error(
        "Expected items in 'data.permissions.groupUsers.edges.node[]' to be objects, got",
        returnedPerm
      );
      continue;
    }

    if (typeof returnedPerm.user !== "object") {
      console.error(
        "Expected items in 'data.permissions.groupUsers.edges.node[].user' to be objects, got",
        returnedPerm.user
      );
      continue;
    }

    if (typeof returnedPerm.group !== "object") {
      console.error(
        "Expected items in 'data.permissions.groupUsers.edges.node[].group' to be objects, got",
        returnedPerm.group
      );
      continue;
    }

    const { userId, firstName, lastName, email, pic } = returnedPerm.user;

    if (typeof userId != "string" || !userId) {
      console.error(
        "Expected 'data.permissions.groupUsers.edges.node[].user.userId' to be a non-empty string, got",
        userId
      );
      continue;
    }

    if (typeof firstName != "string" && firstName !== null) {
      console.error(
        "Expected 'data.permissions.groupUsers.edges.node[].user.firstName' to be a string, got",
        firstName
      );
      continue;
    }

    if (typeof lastName != "string" && lastName !== null) {
      console.error(
        "Expected 'data.permissions.groupUsers.edges.node[].user.lastName' to be a string, got",
        lastName
      );
      continue;
    }

    if (typeof email != "string" || !email) {
      console.error(
        "Expected 'data.permissions.groupUsers.edges.node[].user.email' to be a non-empty string, got",
        email
      );
      continue;
    }

    if (typeof pic != "string") {
      console.error(
        "Expected 'data.permissions.groupUsers.edges.node[].user.pic' to be a string, got",
        pic
      );
      continue;
    }

    const {
      contentType,
      contentObjectId,
      name: permissionGroup,
    } = returnedPerm.group;

    if (typeof contentType != "string" || !isModelKey(contentType)) {
      console.error(
        "Expected 'data.permissions.groupUsers.edges.node[].group.contentType' to be a ModelKey string, got",
        contentType
      );
      continue;
    } else if (!(contentType in permObjectDict)) {
      console.error(
        "Expected 'data.permissions.groupUsers.edges.node[].group.contentType' to be from data.permissions.groups.edges.node[], got",
        contentType
      );
      continue;
    }

    if (typeof contentObjectId != "string" || !contentObjectId) {
      console.error(
        "Expected 'data.permissions.groupUsers.edges.node[].group.contentObjectId' to be a non-empty string, got",
        contentObjectId
      );
      continue;
    } else if (!(contentObjectId in (permObjectDict[contentType] || {}))) {
      console.error(
        "Expected 'data.permissions.groupUsers.edges.node[].group.contentObjectId' to be from data.permissions.groups.edges.node[], got",
        contentObjectId
      );
      continue;
    }

    if (typeof permissionGroup != "string" || !permissionGroup) {
      console.error(
        "Expected 'data.permissions.groupUsers.edges.node[].group.permissionGroup' to be a non-empty string, got",
        permissionGroup
      );
      continue;
    } else if (!MODELS[contentType].permissions.has(permissionGroup)) {
      console.error(
        `Expected 'data.permissions.groupUsers.edges.node[].group.name' to be defined in MODELS.${contentType}.permissions, got`,
        permissionGroup
      );
      continue;
    }

    const permObject = permObjectDict[contentType]?.[contentObjectId];
    if (typeof permObject != "object") {
      console.error(
        `Expected permObjectDict.${contentType}.${contentObjectId} to exist!!!`
      );
      continue;
    }

    permObject.userPerms.push({
      userId: userId,
      email: email,
      firstName: firstName || "",
      lastName: lastName || "",
      imageUrl: pic,
      original_permission: permissionGroup,
      permission: permissionGroup,
      error: null,
    });
  }

  // convert permObjectDict to an array
  const permObjectArray = Array<PermObject>();
  for (const contentTypeid in permObjectDict) {
    if (!isModelKey(contentTypeid)) {
      continue;
    }
    for (const contentObjectId in permObjectDict[contentTypeid]) {
      const permObject = permObjectDict[contentTypeid]?.[contentObjectId];
      if (permObject) {
        permObjectArray.push(permObject);
      }
    }
  }

  // sort objects
  permObjectArray.sort(objectSortFunctionForObject(model, id));

  // sort userPerms
  for (const permObject of permObjectArray) {
    permObject.userPerms.sort(userSortFunction);
  }

  return permObjectArray;
}
