





































import Vue from "vue";
import { SAFonts } from "../../include/SAFonts";
import CollapsibleSection from "../CollapsibleSection.vue";

export default Vue.extend({
  components: { CollapsibleSection },
  methods: {
    /**
     * show the Add Fonts Modal so the user can add a font
     */
    addFont: function () {
      this.$store.commit("setShowAddFontModal", true);
    },
  },
  computed: {
    /**
     * return a list of all the organization fonts
     * @returns {Array<OpenTypeFonts>} an array of open type fonts
     */
    signFonts: function () {
      let saFonts = SAFonts.getInstance();
      return saFonts.fonts;
    },
    /**
     * returns a list of fonts that are on the sign but not in the organization's fonts
     * @returns {Array<string>} an array of font names
     */
    unloadedFonts: function () {
      let saFonts = SAFonts.getInstance();
      return saFonts.fontsNotLoaded;
    },
    /**
     * determine to show the section open or not
     * @returns {boolean} True if section should be displayed open
     */
    openSection: function (): boolean {
      if (this.$store.getters.fonts.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
});
