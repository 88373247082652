





















































































import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      showSettingsMenu: false,
    };
  },
  methods: {
    /**
     * toggle the settings menu
     */
    toggleMenu: function () {
      this.showSettingsMenu = !this.showSettingsMenu;
    },
    /**
     * show the materials modal
     */
    showMaterialsModal: function () {
      this.$store.commit("setShowMaterialsModal", true);
      this.showSettingsMenu = !this.showSettingsMenu;
      // let currentZoom = this.$store.getters.canvas.getZoom()
      // this.$store.getters.canvas.setZoom(currentZoom-.05, true)
      // window.fireEvent("resize")
    },
    /**
     * show the materials modal
     */
    showProcessesModal: function () {
      this.$store.commit("setShowProcessesModal", true);
      this.showSettingsMenu = !this.showSettingsMenu;
      // let currentZoom = this.$store.getters.canvas.getZoom()
      // this.$store.getters.canvas.setZoom(currentZoom-.05, true)
      // window.fireEvent("resize")
    },
    /**
     * show the colors modal
     */
    showColorsModal: function () {
      this.$store.commit("setShowColorsModal", true);
      this.showSettingsMenu = !this.showSettingsMenu;
      // let currentZoom = this.$store.getters.canvas.getZoom()
      // this.$store.getters.canvas.setZoom(currentZoom-.05, true)
      // window.fireEvent("resize")
    },
    /**
     * show the product families modal
     */
    showProductFamiliesModal: function () {
      this.$store.commit("setShowProductFamiliesModal", true);
      this.showSettingsMenu = !this.showSettingsMenu;
    },
    /**
     * show the product categories modal
     */
    showProductCategoriesModal: function () {
      this.$store.commit("setShowProductCategoriesModal", true);
      this.showSettingsMenu = !this.showSettingsMenu;
    },
    /**
     * show the product types modal
     */
    showProductTypesModal: function () {
      this.$store.commit("setShowProductTypesModal", true);
      this.showSettingsMenu = !this.showSettingsMenu;
    },
  },
});
