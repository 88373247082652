


















import Vue from "vue";
import { PropType } from "vue";
import { IDashboardState } from "../types";

export default Vue.extend({
  props: {
    state: {
      type: Object as PropType<IDashboardState>,
      required: true,
    },
  },
  data: function () {
    return {
      isHovering: false,
    };
  },
  methods: {
    archiveState: function (e: Event) {
      e.preventDefault();
      //first update the Vue data
      // toggle the archive flag
      this.state.archived = !this.state.archived;
      //update the backend
      fetch("/state/" + this.state.id + "/archive/", {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Accept: "application/text",
        },
      }).then((data) => data);
    },
  },
  computed: {
    userHasChangePerms: function (): boolean {
      return this.state.canEdit;
    },
    circleClass: function (): string {
      let cssClass = "";
      if (this.userHasChangePerms) {
        if (this.state.archived) {
          cssClass = "fas circle_green";
        } else {
          cssClass = "fal circle_grey";
        }
      } else {
        if (this.state.archived) {
          cssClass = "fas circle_green";
        } else {
          cssClass = "fal circle_grey";
        }
      }
      return cssClass;
    },
    hoverClass: function (): string {
      let cssClass = "";
      if (this.userHasChangePerms) {
        if (this.state.archived) {
          cssClass = "fal circle_grey";
        } else {
          cssClass = "fas circle_green";
        }
      } else {
        if (this.state.archived) {
          cssClass = "fas circle_green";
        } else {
          cssClass = "fal circle_grey";
        }
      }
      return cssClass;
    },
  },
});
