
































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";

import { ObjectPermissions, MODELS, UserPerm, PermObject } from "../types";

@Component
export default class UserPermissionListItem extends Vue {
  @Prop({ type: Object, required: true })
  userPerm!: UserPerm;

  @Prop({ type: Map, required: true })
  permissions!: ObjectPermissions;

  @Prop({ type: Boolean, required: true })
  editable!: boolean;

  @Prop({ type: Map })
  otherPerms!: Map<PermObject, UserPerm>;

  @Prop({ type: String })
  otherPermsHint!: string | null;

  imageFailed = false;
  imageFailCount = 0;

  readonly MODELS = MODELS;
}
