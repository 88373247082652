










































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import PageTitle from "@/mounters/components/PageTitle.vue";

declare global {
  const initializeSidebar: () => void;
}

/**
 * Template for a full page route
 */
@Component({
  components: {
    PageTitle,
  },
})
export default class FullPageLayout extends Vue {
  /**
   * The desired window title string, will have ` - SignAgent` appended
   */
  @Prop({
    type: String,
    default: null,
  })
  readonly title!: string | null;

  isSidebarEnabled: boolean | null = null;
  /**
   * Boolean indicates if a loader animation should cover the modal
   */
  @Prop({ type: Boolean, default: false })
  readonly loading!: boolean;
  /**
   * Return true if the sidebar slot is defined, false otherwise, and update isSidebarEnabled
   */
  isSidebarDefined(): boolean {
    // note: this isn't a computed value because $slots isn't a value that triggers a compute
    this.isSidebarEnabled = Boolean(this.$slots.sidebar);
    return this.isSidebarEnabled;
  }

  /**
   * initialize sidebar when isSidebarEnabled changes
   */
  @Watch("isSidebarEnabled")
  onSidebarDefinedChanged(): void {
    // note: we're counting on the watcher executing after the render function because initializeSidebar() uses #sidebar's existance to toggle the sidebar classes
    // eslint-disable-next-line no-undef
    initializeSidebar();
  }
}
