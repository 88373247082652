import { Module } from "vuex";
import { IState } from "./types";
import { RootState } from "../../store";

export interface StateState {
  state: IState | null;
}

const store: Module<StateState, RootState> = {
  state: (): StateState => ({
    state: null,
  }),
  mutations: {
    setState(state, payload) {
      state.state = payload;
    },
  },
  getters: {
    state(state) {
      return state.state;
    },
  },
};

export default store;
