import Vue from "vue";
import store from "@/store";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { VueConstructor } from "vue/types/umd";

export const mountAsModalIdentifier = {};

/**
 * Returns a function which loads a given Vue component as a Bootstrap Modal (assumes the component implements the ModalLayout component).
 *
 * @param VueConstructor - The Vue component to mount as a modal. May accept/require props
 * @returns A function which takes one optional argument: an object whose properties will be passed to the Vue component as props
 *
 * @remarks
 * The ModalLayout component could alternatively be used within other mounters. The mountAsModal mounter only adds the mounting and unmounting functionality.
 */
export default function mountAsModal(VueConstructor: VueConstructor) {
  return function (props = {}): void {
    const component = new Vue({
      store,
      data: {
        mountAsModalIdentifier,
      },
      render: (createElement) =>
        createElement(VueConstructor, {
          props: props,
        }),
    })
      // we're done with this component when ModalLayout tells us the modal is `closed`, destroy everything
      .$on("closed", function (this: Vue) {
        this.$el.remove();
        this.$destroy();
      })

      // mount and render the component off-document
      .$mount();

    document.body.append(component.$el);
  };
}
