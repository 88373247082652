











































import Vue from "vue";
import $ from "jquery";
import { IMember } from "../types";

export default Vue.extend({
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    memberId: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      member: {} as IMember,
      companyName: "",
    };
  },
  methods: {
    removeMember: function () {
      // POST a graphQL request to remove the member from the company
      const query = JSON.stringify({
        query: `
          mutation remove_profile {
            removeProfileFromCompanyMutation(input: {
              profile: ${this.member.profileId},
            }) {
              errors { messages }
            }
          }`,
      });
      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.errors) {
            alert(data.errors[0].message);
          } else if (
            data.data.removeProfileFromCompanyMutation.errors?.length > 0
          ) {
            alert(
              data.data.removeProfileFromCompanyMutation.errors[0].messages[0]
            );
          } else {
            this.$emit("closed");
          }
        });
    },
    fetchData: function () {
      // load member
      const query = JSON.stringify({
        query: `query get_member {
          user (id: ${this.memberId}){
            company {
              name
            }
            id: userId
            profileId: contentObjectId
            firstName
            lastName
            email
            phoneNumber
            image
            role: companyRole
            twoFAMethod: preferred2faMethod
            emailFrequency: reviewerEmailFrequency
            agreesToTerms
          }
        }
      `,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((result) => result.json())
        .then((data) => {
          console.log(data);
          const { company, ...member } = data.data.user;
          this.companyName = company.name;
          this.member = member;
        });
    },
  },
  watch: {
    showModal: function (newValue: boolean) {
      if (newValue) {
        this.fetchData();
        $("#removeMemberModal").modal("show");
      } else {
        $("#removeMemberModal").modal("hide");
      }
    },
  },
});
