





































































import Vue from "vue";
import { IAlignment } from "../../types";
// import { normalCaseToUnderscore } from "../../include/Utils";

import CollapsibleSection from "../CollapsibleSection.vue";
// import { buildRepeatAlignmentStrings } from "../../utilities";

export default Vue.extend({
  components: { CollapsibleSection },
  props: {},
  methods: {
    /**
     * select an alignment
     * @param {IAlignment} alignment - the alignment that was clicked on
     */
    selectAlignment(alignment: IAlignment) {
      this.$store.commit("selectAlignmentByUUID", alignment.uuid);
      this.$nextTick(function () {
        // force a rerender in SVGRenderer
        this.$store.commit("setRerender", !this.$store.getters.rerender);
      });
    },
    /**
     * start drag operation
     * @param {DragEvent} event - the drag event
     * @param {IAlignment} item - the alignment being dragged
     */
    startDrag: function (event: DragEvent, item: IAlignment) {
      if (event.dataTransfer) {
        event.dataTransfer.dropEffect = "move";
        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.setData("uuid", item.uuid);
      } else {
        console.log("Start Transfer no dataTransfer");
      }
    },
    /**
     * the drop operation
     * @param {DragEvent} - the drag event
     */
    // onDrop: function (event: DragEvent) {
    //   const target = event.target as HTMLElement;
    //   const targetAlignment = this.$store.getters.alignments.find(function (
    //     alignment: IAlignment
    //   ) {
    //     return alignment.uuid === target.id;
    //   });
    //   const index = targetAlignment.sortIndex;

    //   if (event.dataTransfer) {
    //     const uuid = event.dataTransfer.getData("uuid");

    //     //increment sort index on alignments that have a sortIndex greater than the drop index
    //     //this will create a spot for us to place the dragged aligment
    //     let items = JSON.parse(
    //       JSON.stringify(this.$store.getters.alignments)
    //     ).filter((alignment: IAlignment) => {
    //       return alignment.targetRow === "previous" && alignment.isInRepeat;
    //     });
    //     items.sort(function (a: IAlignment, b: IAlignment) {
    //       return b.sortIndex - a.sortIndex;
    //     });

    //     items.forEach((item: IAlignment) => {
    //       if (item.sortIndex >= index) {
    //         item.sortIndex++;
    //         this.$store.commit("updateAlignment", item);
    //       }
    //     });

    //     //now that we have a space to put the dragged alignment let's put it there
    //     //by setting it's sort index
    //     const item = JSON.parse(
    //       JSON.stringify(this.$store.getters.alignments)
    //     ).find(function (item: IAlignment) {
    //       return item.uuid === uuid;
    //     });

    //     item.sortIndex = index;
    //     this.$store.commit("updateAlignment", item);
    //     //update the SVGs repeat alignment text
    //     this.updateRepeatAlignment(item.elementIds[0]);
    //   } else {
    //     console.log("No data transfer");
    //   }
    // },
    // updateRepeatAlignment: function (elementId: string) {
    //   // make sure Vue has updated the store before continuing
    //   Vue.nextTick(() => {
    //     //get all repeats that target previous rows
    //     let previousRowAlignments: Array<IAlignment> =
    //       this.$store.getters.alignments.filter((alignment: IAlignment) => {
    //         let result = false;
    //         for (let i = 0; i < alignment.targets.length; i++) {
    //           if (alignment.targets[i].row === "previous") {
    //             result = true;
    //           }
    //         }
    //         return result;
    //       });
    //     //order them by sortIndex
    //     previousRowAlignments.sort((a, b) => a.sortIndex - b.sortIndex);
    //     //build the alignment strings
    //     console.log("previousRowAlignments....");
    //     console.log(previousRowAlignments);

    //     let alignmentStrings = buildRepeatAlignmentStrings(
    //       this.$store.getters.selectedAlignment,
    //       previousRowAlignments
    //     );
    //     //update the repeat element in the SVG
    //     let repeatId = this.getAncestorRepeat(elementId);
    //     let el = this.$store.getters.canvas.select(
    //       "[sa-data-id='" + repeatId + "']"
    //     );

    //     // set the data-name attribute
    //     el.attr({ "data-name": "repeat " + alignmentStrings.join(",") });
    //     // set the id attribute
    //     el.attr({
    //       id: normalCaseToUnderscore("repeat " + alignmentStrings.join(",")),
    //     });
    //   });
    // },
    /**
     * get the ancestor element that is a repeat
     * @param {string} svgId - the SVG id for this element
     * @returns {string} - the SVG id of the ancestor element
     */
    getAncestorRepeat: function (svgId: string): string {
      //get the Snap element for the SVG canvas
      let el = this.$store.getters.canvas.select(
        "[sa-data-id='" + svgId + "']"
      );
      if (el) {
        let parent = el.parent();
        if (parent) {
          if (parent.attr("data-name")) {
            if (parent.attr("data-name").substring(0, 6) === "repeat") {
              return parent.attr("sa-data-id");
            } else if (parent.parent()) {
              return this.getAncestorRepeat(parent.attr("sa-data-id"));
            }
          } else {
            return this.getAncestorRepeat(parent.attr("sa-data-id"));
          }
        }
      }
      return "";
    },
  },
  computed: {
    /**
     * do we have any current row alignments
     */
    haveCurrentRows: function () {
      for (let i = 0; i < this.$store.getters.alignments.length; i++) {
        // we must be in a repeat to test the row value
        if (this.$store.getters.alignments[i].isInRepeat) {
          for (
            let j = 0;
            j < this.$store.getters.alignments[i].targets.length;
            j++
          ) {
            if (
              this.$store.getters.alignments[i].targets[j].row.toLowerCase() ===
              "current"
            ) {
              return true;
            }
          }
        }
      }
      return false;
    },
    /**
     * do we have any current row alignments
     */
    havePreviousRows: function () {
      for (let i = 0; i < this.$store.getters.alignments.length; i++) {
        // we must be in a repeat to test the row value
        if (this.$store.getters.alignments[i].isInRepeat) {
          for (
            let j = 0;
            j < this.$store.getters.alignments[i].targets.length;
            j++
          ) {
            if (
              this.$store.getters.alignments[i].targets[j].row.toLowerCase() ===
              "previous"
            ) {
              return true;
            }
          }
        }
      }
      return false;
    },
    /**
     * should the section be displayed open
     */
    openSection: function () {
      if (this.$store.getters.alignments.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    isOpenable: function () {
      return this.$store.getters.alignments.length > 0;
    },
    /**
     * return all the alignments that are not in a repeat
     * @returns {Array<IAligment>} an array of IAlignments
     */
    fieldAlignments: function () {
      return this.$store.getters.alignments.filter((alignment: IAlignment) => {
        return alignment.isInRepeat === false;
      });
    },
    /**
     * return all the alignments that are in a repeat and target the current row
     * @returns {Array<IAlignment>} an array of IAlignments
     */
    currentRowAlignments: function () {
      let currentAlignments = [] as Array<{
        uuid: string;
        isSelected: boolean;
        sourceField: string;
        targetField: string;
      }>;
      for (let i = 0; i < this.$store.getters.alignments.length; i++) {
        const alignment = this.$store.getters.alignments[i];
        for (let j = 0; j < alignment.targets.length; j++) {
          if (alignment.targets[j].row.toLowerCase() === "current") {
            currentAlignments.push({
              uuid: alignment.uuid,
              isSelected: alignment.isSelected,
              sourceField: alignment.sourceField,
              targetField: alignment.targets[j].field,
            });
          }
        }
      }
      return currentAlignments;
      // return this.$store.getters.alignments.filter((alignment: IAlignment) => {
      //   return alignment.targetRow === "current" && alignment.isInRepeat;
      // });
    },
    /**
     * return all the alignments that are in a repeat and target the previous row
     * @returns {Array<IAlignment>} an array of IAlignments sorted by sortIndex
     */
    previousRowAlignments: function () {
      let currentAlignments = [] as Array<{
        uuid: string;
        isSelected: boolean;
        sourceField: string;
        targetField: string;
      }>;
      for (let i = 0; i < this.$store.getters.alignments.length; i++) {
        const alignment = this.$store.getters.alignments[i];
        for (let j = 0; j < alignment.targets.length; j++) {
          if (alignment.targets[j].row.toLowerCase() === "previous") {
            currentAlignments.push({
              uuid: alignment.uuid,
              isSelected: alignment.isSelected,
              sourceField: alignment.sourceField,
              targetField: alignment.targets[j].field,
            });
          }
        }
      }
      return currentAlignments;
      // let items = this.$store.getters.alignments.filter(
      //   (alignment: IAlignment) => {
      //     return alignment.targetRow === "previous" && alignment.isInRepeat;
      //   }
      // );
      // items.sort(function (a: IAlignment, b: IAlignment) {
      //   return a.sortIndex - b.sortIndex;
      // });

      // return items;
    },
  },
  watch: {},
});
