


















































import Vue from "vue";

export default Vue.extend({
  data: function () {
    return {
      companyName: "",
      taxType: "au_abn",
      taxId: "",
      taxTypes: [
        {
          value: "au_abn",
          text: "Australian Business Number (AU ABN)",
        },
        {
          value: "au_arn",
          text: "Australian Taxation Office Reference Number",
        },
        {
          value: "br_cnpj",
          text: "Brazilian CNPJ number",
        },
        {
          value: "br_cpf",
          text: "Brazilian CPF number",
        },
        {
          value: "bg_uic",
          text: "Bulgaria Unified Identification Code",
        },
        {
          value: "ca_bn",
          text: "Canadian BN",
        },
        {
          value: "ca_gst_hst",
          text: "Canadian GST/HST number",
        },
        {
          value: "ca_pst_bc",
          text: "Canadian PST number (British Columbia)",
        },
        {
          value: "ca_pst_mb",
          text: "Canadian PST number (Manitoba)",
        },
        {
          value: "ca_pst_sk",
          text: "Canadian PST number (Saskatchewan)",
        },
        {
          value: "ca_qst",
          text: "Canadian QST number (Québec)",
        },
        {
          value: "cl_tin",
          text: "Chilean TIN",
        },
        {
          value: "eg_tin",
          text: "Egyptian Tax Identification Number",
        },
        {
          value: "eu_oss_vat",
          text: "European One Stop Shop VAT number for non-Union scheme",
        },
        {
          value: "eu_vat",
          text: "European VAT number",
        },
        {
          value: "ge_vat",
          text: "Georgian VAT",
        },
        {
          value: "hk_br",
          text: "Hong Kong BR number",
        },
        {
          value: "hu_tin",
          text: "Hungary tax number (adószám)",
        },
        {
          value: "is_vat",
          text: "Icelandic VAT",
        },
        {
          value: "in_gst",
          text: "Indian GST number",
        },
        {
          value: "id_npwp",
          text: "Indonesian NPWP number",
        },
        {
          value: "il_vat",
          text: "Israel VAT",
        },
        {
          value: "jp_cn",
          text: "Japanese Corporate Number (*Hōjin Bangō*)",
        },
        {
          value: "jp_rn",
          text: "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)",
        },
        {
          value: "jp_trn",
          text: "Japanese Tax Registration Number (*Tōroku Bangō*)",
        },
        {
          value: "ke_pin",
          text: "Kenya Revenue Authority Personal Identification Number",
        },
        {
          value: "li_uid",
          text: "Liechtensteinian UID number",
        },
        {
          value: "my_frp",
          text: "Malaysian FRP number",
        },
        {
          value: "my_itn",
          text: "Malaysian ITN",
        },
        {
          value: "my_sst",
          text: "Malaysian SST number",
        },
        {
          value: "mx_rfc",
          text: "Mexican RFC number",
        },
        {
          value: "nz_gst",
          text: "New Zealand GST number",
        },
        {
          value: "no_vat",
          text: "Norwegian VAT number",
        },
        {
          value: "ph_tin",
          text: "Philippines Tax Identification Number",
        },
        {
          value: "ru_inn",
          text: "Russian INN",
        },
        {
          value: "ru_kpp",
          text: "Russian KPP",
        },
        {
          value: "sa_vat",
          text: "Saudi Arabia VAT",
        },
        {
          value: "sg_gst",
          text: "Singaporean GST",
        },
        {
          value: "sg_uen",
          text: "Singaporean UEN",
        },
        {
          value: "si_tin",
          text: "Slovenia tax number (davčna številka)",
        },
        {
          value: "za_vat",
          text: "South African VAT number",
        },
        {
          value: "kr_brn",
          text: "Korean BRN",
        },
        {
          value: "es_cif",
          text: "Spanish NIF number (previously Spanish CIF number)",
        },
        {
          value: "ch_vat",
          text: "Switzerland VAT number",
        },
        {
          value: "tw_vat",
          text: "Taiwanese VAT",
        },
        {
          value: "th_vat",
          text: "Thai VAT",
        },
        {
          value: "tr_tin",
          text: "Turkish Tax Identification Number",
        },
        {
          value: "ua_vat",
          text: "Ukrainian VAT",
        },
        {
          value: "ae_trn",
          text: "United Arab Emirates TRN",
        },
        {
          value: "gb_vat",
          text: "United Kingdom VAT number",
        },
        {
          value: "us_ein",
          text: "United States EIN",
        },
      ],
    };
  },
  methods: {
    saveChanges: function (e: Event) {
      e.preventDefault();
    },
  },
  computed: {
    // validPriceTiers: function (): Array<IPriceTier> {
    //   if (this.plan.pricePlanId) {
    //     const pricePlan = this.$store.getters.pricePlanById(
    //       this.plan.pricePlanId
    //     );
    //     const currentTier = this.$store.getters.priceTierById(
    //       this.plan.priceTierId
    //     );
    //     const priceTiers = this.$store.getters
    //       .priceTiersForPricePlanAndBillingCycle(
    //         pricePlan.id,
    //         this.$store.getters.subscription.billingCycleId
    //       )
    //       .filter((priceTier: IPriceTier) => {
    //         return (
    //           priceTier.numberOfUsers < currentTier.numberOfUsers &&
    //           this.numberOfUsers <= priceTier.numberOfUsers
    //         );
    //       });
    //     return priceTiers;
    //   } else {
    //     return [];
    //   }
    // },
    // numberOfUsers: function (): number {
    //   if (this.plan.id) {
    //     return this.$store.getters.activeUserLicenses.length;
    //   } else {
    //     return 0;
    //   }
    // },
  },
  watch: {
    // plan: function () {
    //   this.newTier = this.validPriceTiers[0].id;
    // },
    // newTier: function (newValue) {
    //   this.newTotal = this.$store.getters.priceTierById(newValue).price;
    // },
  },
});
