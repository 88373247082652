


















































































































































import Vue from "vue";
import $, { isEmptyObject } from "jquery";

import { PropType } from "vue";
import {
  IPlanAndTiers,
  ITier,
  ISubscription,
  ISubscriptionItem,
} from "../types";

interface IPricePlansQL {
  data: {
    pricingPlans: {
      edges: Array<{
        node: {
          id: number;
          name: string;
          billingCycle: string;
          stripePriceId: string;
          prices: {
            edges: Array<{
              node: {
                billingCycle: string;
                stripePriceId: string;
                tiers: {
                  edges: Array<{
                    node: {
                      cycle: string;
                      upTo: number;
                      amount: number;
                      currency: string;
                      stripePriceId: string;
                    };
                  }>;
                };
              };
            }>;
          };
        };
      }>;
    };
  };
}

export default Vue.extend({
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    subscription: {
      type: Object as PropType<ISubscription>,
    },
    subscriptionItem: {
      type: Object as PropType<ISubscriptionItem>,
    },
    licenseType: {
      type: String,
      default: "O",
    },
    editMode: {
      type: String,
      default: "edit",
      validator: function (value: string) {
        // only allow the strings 'edit' or 'add'
        return value === "edit" || value === "add";
      },
    },
  },
  data: function () {
    return {
      userCount: 0,
      billingCycle: "",
      emptyPricePlan: {
        id: -1,
        name: "",
        stripePriceId: "",
        tiers: [],
      } as IPlanAndTiers,
      emptyPriceTier: {
        cycle: "",
        upTo: 0,
        amount: 0,
        currency: "",
        stripeId: "",
      } as ITier,
      selectedPlanAndTier: null as IPlanAndTiers | null,
      plans: [] as Array<IPlanAndTiers>,
      plan: {} as IPlanAndTiers,
      showLoadingSpinner: false,
    };
  },
  mounted: function () {
    this.userCount =
      this.subscriptionItem.upTo - this.subscriptionItem.remainingSeats;
    this.billingCycle = this.subscriptionItem.billingCycle;
  },
  methods: {
    // allow any type as this function can be called on different object types
    // eslint-disable-next-line
    isObjectEmpty: function (obj: any): boolean {
      return Object.keys(obj).length === 0;
    },
    fetchData: function () {
      // get price plans
      this.showLoadingSpinner = true;
      this.plans = [];

      const query = JSON.stringify({
        query: `
          query get_pricing_plans {
            pricingPlans {
              edges {
                node {
                  id: contentObjectId
                  name
                  prices {
                    edges {
                      node {
                        id: contentObjectId
                        stripePriceId
                        billingCycle
                        tiers {
                          edges {
                            node {
                              upTo
                              amount
                              currency
                              inUse
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
      });

      fetch("/graphql/", {
        method: "POST",
        body: query,
        headers: {
          "content-type": "application/json",
          Accept: "application/json",
        },
      })
        .then((data) => data.json())
        .then((result: IPricePlansQL) => {
          this.showLoadingSpinner = false;
          result.data.pricingPlans.edges.forEach((plan) => {
            const pricePlan = {
              name: plan.node.name,
              stripePriceId:
                plan.node.prices.edges.length > 0
                  ? plan.node.prices.edges[0].node.stripePriceId
                  : "",
              tiers: [],
            } as IPlanAndTiers;

            plan.node.prices.edges.forEach((price) => {
              // Stripe stores the price tier amounts differently then we display
              // them on the front end.  Stripe stores the price as the difference - so
              // to get the total for the second tier you add the first tier and the
              // second tier amounts together.  tierTotal is the variable we store the
              // total in
              let tierTotal = 0;
              if (price.node.tiers) {
                console.log("price.node.tiers");
                console.log(price.node.tiers);
                price.node.tiers.edges.forEach((tier) => {
                  // update the tierTotal
                  tierTotal += tier.node.amount;
                  pricePlan.tiers.push({
                    cycle: price.node.billingCycle,
                    upTo: tier.node.upTo,
                    amount: tierTotal,
                    currency: tier.node.currency,
                    stripeId: price.node.stripePriceId,
                  });
                });
              }
            });
            this.plans.push(pricePlan);
          });
        });
    },
    closeModal: function () {
      this.resetPlanData();
      this.$emit("closed");
    },
    /**
     * resets data to default values
     */
    resetPlanData() {
      this.userCount =
        this.subscriptionItem.upTo - this.subscriptionItem.remainingSeats;
      this.billingCycle = this.subscriptionItem.billingCycle;
      if (this.subscription.id === 0) {
        this.selectedPlanAndTier = null;
      } else {
        this.selectedPlanAndTier = {
          name: this.subscriptionItem.plan.name,
          stripePriceId: this.subscriptionItem.stripePriceId,
          tiers: [
            {
              cycle: this.subscriptionItem.billingCycle,
              upTo: this.subscriptionItem.upTo,
              amount: this.subscriptionItem.amount,
              currency: "USD",
              stripeId: this.subscriptionItem.stripePriceId,
            },
          ],
        };
      }
    },
    /**
     * get appropriate tier for a given price plan and billing cycle, as well as the expected number of users
     * @param {number} pricePlanId - price plan id of the tier we are looking for
     * @param {string} billingCycle - billing cycle of the tier we are looking for ("MONTH" or "YEAR")
     * @param {number} userCount - expected number of users (not the numberOfUsers value in the price tier)
     * @returns {IPriceTier} - price tier matching the parameters, else a price tier with empty/invalid values
     */
    tierForBillingCycleAndQuantity(
      plan: IPlanAndTiers,
      billingCycle: string,
      userCount: number
    ): ITier {
      // find smallest value in numUsersOptions that is > activeUsers
      const numUsersCeiling = this.numUsersOptionsForPricePlan(plan);
      console.log("numusersceiling....");
      console.log(numUsersCeiling);

      if (numUsersCeiling) {
        const tier = this.priceTierIdForPlanCycleAndQuantity(
          plan,
          billingCycle,
          // get the first numUsersCieling that has a value greater than userCount
          numUsersCeiling.filter((value) => value >= userCount)[0]
        );
        return tier;
      } else {
        return this.emptyPriceTier;
      }
    },
    priceTierIdForPlanCycleAndQuantity: function (
      plan: IPlanAndTiers,
      billingCycle: string,
      upTo: number
    ): ITier {
      let priceTier = this.emptyPriceTier as ITier;

      plan.tiers.forEach((tier) => {
        if (tier.cycle === billingCycle && tier.upTo === upTo) {
          priceTier = tier;
        }
      });
      return priceTier;
    },
    /**
     * selects new plan and tier but does not apply any changes to backend
     * @param {number} index - index of the new price plan in the available price plan list
     */
    selectNewPlanAndTier(plan: IPlanAndTiers) {
      const newPriceTier = this.tierForBillingCycleAndQuantity(
        plan,
        this.billingCycle,
        Number(this.userCount)
      ) as ITier;

      console.log("this is the price tier....");
      console.log(newPriceTier);

      this.selectedPlanAndTier = {
        name: plan.name,
        stripePriceId: plan.stripePriceId,
        tiers: [newPriceTier],
      } as IPlanAndTiers;
    },
    /**
     * applies plan changes and writes them to backend
     */
    applyChanges() {
      // check the route to see if we are editing an existing subscription or creating a new one
      if (this.$route.path.includes("billing")) {
        const companyId = this.$route.params.id;
        // const priceId = this.$store.getters.priceTierById(
        //   this.newPlan.priceTierId
        // ).stripePriceId;
        // const numberOfUsers = this.$store.getters.priceTierById(
        //   this.newPlan.priceTierId
        // ).numberOfUsers;

        fetch("/graphql/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            variables: {
              mutateSubscriptionInput: {
                company: companyId,
                items: JSON.stringify([
                  {
                    id: this.subscriptionItem.id,
                    stripeId: this.subscriptionItem.id,
                    price: this.selectedPlanAndTier
                      ? this.selectedPlanAndTier.stripePriceId
                      : 0,
                    quantity: this.selectedPlanAndTier
                      ? this.selectedPlanAndTier.tiers[0].upTo
                      : 0,
                  },
                ]),
              },
            },
            query: `
              mutation update_subscription(
                $mutateSubscriptionInput: MutateSubscriptionInput!
              ) {
                mutateSubscription(input: $mutateSubscriptionInput) {
                  errors { messages }
                  subscription {
                    id: contentObjectId
                    items {
                      edges {
                        node {
                          planPrice {
                            billingCycle
                          }
                        }
                      }
                    }
                    startPeriodDate
                    endPeriodDate
                  }
                }
              }`,
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((result) => {
            if (result.errors) {
              alert(result.errors[0].message);
            } else if (result.data.mutateSubscription?.errors.length > 0) {
              alert(
                result.data.mutateSubscription.errors[0].messages.join(", ")
              );
            } else {
              location.reload();
            }
          });
      } else {
        this.$emit(
          "applyButtonClicked",
          this.licenseType,
          this.selectedPlanAndTier
        );
      }
    },
    /**
     * list of the number of users for each tier of a given price plan
     * @param {number} pricePlanId - id of the price plan we wish to find the number of users options for
     * @returns {number[]} - number of users for each tier of the price plan
     */
    numUsersOptionsForPricePlan(plan: IPlanAndTiers): number[] {
      let priceTierUsers = [] as Array<number>;

      plan.tiers.forEach((tier) => {
        // don't include the tier with the null upto - it is there for stripe
        if (tier.upTo) {
          priceTierUsers.push(tier.upTo);
        }
      });
      return priceTierUsers;
    },
  },
  computed: {
    /**
     * the price tiers that match the current number of users and billing cycle
     */
    validPriceTiers: function (): Array<IPlanAndTiers> {
      let validPlans = [] as Array<IPlanAndTiers>;

      this.plans.forEach((plan) => {
        const numUsersCeiling = this.numUsersOptionsForPricePlan(plan);

        let tier = {} as ITier;

        if (numUsersCeiling !== undefined) {
          plan.tiers.forEach((planTier) => {
            if (
              planTier.cycle === this.billingCycle &&
              planTier.upTo ===
                numUsersCeiling.filter((value) => value >= this.userCount)[0]
            ) {
              tier = planTier;
            }
          });
        }

        if (tier.amount) {
          validPlans.push({
            name: plan.name,
            stripePriceId: plan.stripePriceId,
            tiers: [tier],
          });
        }
      });

      return validPlans;
    },
    /**
     * active users (i.e. users with a seat) on a plan
     * @returns {number} - number of users on selected plan
     */
    activeUsers(): number {
      if (this.selectedPlanAndTier) {
        // const planType = this.$store.getters.pricePlanById(
        //   this.selectedPlanAndTier.id
        // ).planType;
        // return this.$store.getters.usersForPlanType(planType).length;
        this.subscriptionItem.upTo - this.subscriptionItem.remainingSeats;
      }
      return this.userCount;
    },
    /**
     * only exists temporarily, before changes are applied
     * @returns {IPricePlan} price plan for the new (updated) plan
     */
    pricePlan(): IPlanAndTiers {
      if (this.selectedPlanAndTier) {
        return this.selectedPlanAndTier;
      } else {
        return this.emptyPricePlan;
      }
    },
    /**
     * only exists temporarily, before changes are applied
     * @returns {IPriceTier} price tier for the new (updated) plan
     */
    priceTier(): ITier {
      console.log("price tier....");
      console.log(this.selectedPlanAndTier);
      if (this.selectedPlanAndTier) {
        return this.selectedPlanAndTier.tiers[0];
      } else {
        return this.emptyPriceTier;
      }
    },
    /**
     * whether or not the user should be able to click the apply button
     * @returns {boolean} - true if certain conditions are met (depends on which view the modal is mounted on)
     */
    applyButtonIsDisabled(): boolean {
      if (this.$route.path.includes("billing")) {
        if (isEmptyObject(this.selectedPlanAndTier)) {
          return false;
        } else if (this.selectedPlanAndTier) {
          return false;
        } else {
          return true;
        }
      } else {
        return this.selectedPlanAndTier ? false : true;
      }
    },
  },
  watch: {
    showModal: function (newValue: boolean) {
      if (newValue) {
        this.fetchData();
        $("#managePlanModal").modal("show");
      } else {
        $("#managePlanModal").modal("hide");
      }
    },
    subscriptionItem: function (newValue: ISubscriptionItem) {
      this.userCount = newValue.upTo - newValue.remainingSeats;
      this.billingCycle = newValue.billingCycle;
      if (newValue.id !== 0) {
        this.selectedPlanAndTier = {
          name: newValue.plan.name,
          stripePriceId: newValue.stripePriceId,
          tiers: [
            {
              amount: newValue.amount,
              currency: newValue.currency,
              cycle: newValue.billingCycle,
              stripeId: newValue.stripeSubscriptionId,
              upTo: newValue.upTo,
            },
          ],
        };
      } else {
        this.selectedPlanAndTier = null;
      }
    },
  },
});
