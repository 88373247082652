


































































































































































































































































































































































































































































































//import $ from "jquery";
import Vue from "vue";
import $ from "jquery";

import {
  IOrderDetailSign,
  IOrder,
  IOrderSignType,
  IOrderSignTypeSign,
  IOrderItems,
  IReviewStateSign,
  ICommentQl,
  ORDER_STATUS,
  ISignMessageField,
  IRepeatingMessageField,
  ISignTypeQL,
} from "../types";

import inviteQuotersModal from "../modals/inviteQuotersModal.vue";
import OrderDetails from "../components/OrderDetailsTable.vue";
import OrderHeader from "../components/OrderHeader.vue";
import RemovedSigns from "../components/RemovedSigns.vue";
import { Stripe } from "@stripe/stripe-js";
import SubmitQuoteConfirmModal from "../modals/submitQuoteConfirmModal.vue";
import SubmitReviewConfirmModal from "../modals/submitReviewConfirmModal.vue";
import SubmitCheckoutConfirmModal from "../modals/submitCheckoutConfirmModal.vue";

import PaymentModal from "../components/PaymentModal.vue";
import { capitalize } from "@/filters/common";
// TODO: move these constants to the stpre.ts so that these constants are loaded from the GraphQL for status results

export default Vue.extend({
  components: {
    inviteQuotersModal,
    OrderDetails,
    OrderHeader,
    RemovedSigns,
    SubmitQuoteConfirmModal,
    SubmitReviewConfirmModal,
    SubmitCheckoutConfirmModal,
    PaymentModal,
  },
  filters: { capitalize },
  /**
   * Returns the initial data for the component.
   *
   * @returns {object} - An object containing the initial data.
   */

  data: function () {
    return {
      order: {} as IOrder,
      // panelHeight: 0,
      gridSize: 6,
      removedGridSize: 6,
      isDirty: false,
      selectedItems: [] as Array<IOrderDetailSign>,
      saveFailed: false,
      saveSucceeded: false,
      saveErrorMessage: "",
      stripe: null as Stripe | null,
      showQuoteConfirmModal: false,
      showReviewConfirmModal: false,
      showCheckoutConfirmModal: false,
      orderDetailsOpen: true,
      removedSignsOpen: true,
      orderItemsOpen: true,
      builderEmail: "",
      builderHasCardOnFile: false,
      containerHeight: 600,
      state_name: "",
      state_id: "",
      phase_name: "",
      phase_id: "",
      saveMessage: "",
      signsThatNeedSaving: [] as Array<IOrderSignTypeSign>,
      errorField: "",
      errorMessage: "",
      hasOrgOwner: false,
      loading: false,
    };
  },
  /**
   * Executes when the component is mounted.
   *
   * @async
   *
   * @returns {Promise<void>} A promise that resolves when the component is mounted.
   */
  mounted: async function () {
    this.$store.commit("setShowLoadingSpinner", true);
    // initial resize
    this.resized();

    //add event listener for resize event
    window.addEventListener("resize", this.resized);
    if (!this.stripe) {
      if (window.Stripe) {
        if (process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY) {
          this.stripe = window.Stripe(
            process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
          );
        }
      }
    }
  },
  /**
   * Fetches the data when the view is created.
   *
   * @memberOf yourClass
   * @function
   * @name created
   * @returns {void}
   */
  created() {
    // fetch the data when the view is created
    this.fetchData();
  },
  methods: {
    showManagerButtons: function (status: number) {
      if (
        this.$store.getters.order &&
        this.$store.getters.order.status === status
      ) {
        if (
          this.$store.getters.order.manager.id ===
          this.$store.state.session.userId
        ) {
          return true;
        }
        if (this.$store.state.session.isSuperUser) {
          return true;
        }

        return false;
      }

      return false;
    },
    showBuilderButtons: function (status: number) {
      if (
        this.$store.getters.order &&
        this.$store.getters.order.status === status
      ) {
        if (this.userIsBuilder) {
          return true;
        }
        if (this.$store.state.session.isSuperUser) {
          return true;
        }

        return false;
      }

      return false;
    },

    formatDateTime: function (date: Date) {
      // Pad single digit numbers with leading zero
      function pad(number: number) {
        return (number < 10 ? "0" : "") + number;
      }

      // Month names array
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const year = date.getFullYear();
      const monthName = monthNames[date.getMonth()]; // Get the month name
      const day = pad(date.getDate());

      let hours = date.getHours();
      const minutes = pad(date.getMinutes());

      // Determine AM/PM
      const ampm = hours >= 12 ? "p.m." : "a.m.";
      // Convert hours from 24-hour to 12-hour format
      hours = hours % 12;
      hours = hours ? hours : 12; // The hour '0' should be '12'
      const hoursStr = pad(hours);

      // Get the timezone
      const options: Intl.DateTimeFormatOptions = { timeZoneName: "short" };
      const formatter = new Intl.DateTimeFormat("en-US", options);
      const parts = formatter.formatToParts(date);
      const timezone =
        parts.find((part) => part.type === "timeZoneName")?.value || "";

      return `${monthName} ${day}, ${year}, ${hoursStr}:${minutes} ${ampm} ${timezone}`;
    },
    statusStyling: function () {
      if (this.$store.getters.order) {
        if (
          this.$store.getters.order.manager.id ===
          this.$store.state.session.userId
        ) {
          // we are the manager so we should update the pill layout if order is in DRAFT or IN_REVIEW
          if (
            this.$store.getters.order.status === ORDER_STATUS.DRAFT ||
            this.$store.getters.order.status === ORDER_STATUS.IN_REVIEW
          ) {
            return "background-color: #333; color: #fff";
          } else {
            // otherwise leave the pill layout the way it is
            return "";
          }
        } else {
          // we are the builder so update the pill layout if order is in QUOTING
          if (this.$store.getters.order.status === ORDER_STATUS.QUOTING) {
            return "background-color: #333; color: #fff";
          } else {
            // otherwise leave the pill layout the way it is
            return "";
          }
        }
      }
    },
    downloadQuote: function () {
      window.location.href = `/order/${this.$route.params.id}/client_invoice_pdf/`;
    },
    /**
     * return the text name of an order status given the value
     * @param {string} status - the order status's value (ie. "0", "1", etc.)
     * @returns {string | undefined} the text value of the order status (ie. "DRAFT", "QUOTING", etc.)
     */
    orderStatus: function (status: string): string | undefined {
      return Object.keys(ORDER_STATUS).find(
        (key) => ORDER_STATUS[key] === status
      );
    },
    /**
     * if the sign has been updated then save it to a list so that we can process the sign when the order is saved
     * @param sign: IOrderSignTypeSign - the sign that was updated
     */
    onSignChanged: function (sign: IOrderSignTypeSign) {
      if (
        !this.signsThatNeedSaving.find((signInList) => {
          signInList.id === sign.id;
        })
      ) {
        this.signsThatNeedSaving.push(sign);
        this.isDirty = true;
      }
    },
    /**
     * determine if the Save Draft button should be displayed
     * @returns {boolean} - true if button should be shown
     */
    showSaveDraftButton: function (): boolean {
      // we don't show this button if the order is in any other status than DRAFT or QUOTING
      let showButton = false;

      if (this.$store.getters.order) {
        // always show the button if we are in DRAFT status
        if (
          this.$store.getters.order.status === ORDER_STATUS.DRAFT &&
          this.$store.getters.order.manager.id ===
            this.$store.state.session.userId
        ) {
          showButton = true;
        }
        // If in QUOTING status the only show the button if the user is a builder
        if (
          this.$store.getters.order.status === ORDER_STATUS.QUOTING &&
          this.userIsBuilder
        ) {
          showButton = true;
        }

        // If in IN_REVIEW status the only show the button if the user is the manager
        if (
          this.$store.getters.order.status === ORDER_STATUS.IN_REVIEW &&
          this.$store.getters.order.manager.id ===
            this.$store.state.session.userId
        ) {
          showButton = true;
        }
      }

      return showButton;
    },
    /**
     * Resizes the container height based on the window height.
     * If the window height is less than 450, sets the container height to 450.
     * Otherwise, sets the container height to the window height minus 85.
     */
    resized: function () {
      if (window.innerHeight < 450) {
        this.containerHeight = 450;
      } else {
        this.containerHeight = window.innerHeight - 85;
      }
    },
    /**
     * Toggles the visibility of the order details section by collapsing or expanding it.
     * Updates the value of `this.orderDetailsOpen` to reflect the new state.
     *
     * @function collapseOrderDetails
     */
    collapseOrderDetails: function () {
      $("#collapseOrderDetails").collapse("toggle");
      this.orderDetailsOpen = !this.orderDetailsOpen;
    },
    /**
     * Toggles the visibility of the removed signs section by collapsing or expanding it.
     * Updates the value of `this.removedSignsOpen` to reflect the new state.
     *
     * @function collapseRemovedSigns
     */
    collapseRemovedSigns: function () {
      $("#collapseRemovedSigns").collapse("toggle");
      this.removedSignsOpen = !this.removedSignsOpen;
    },
    /**
     * Collapses or expands the order items section.
     * @returns {void}
     */
    collapseOrderItems: function () {
      $("#collapseOrderItems").collapse("toggle");
      this.orderItemsOpen = !this.orderItemsOpen;
    },
    /**
     * return all the signs that have the review state in a list of user provided states
     * @param Array<string> states - an array of strings where each element is a review state. Ex: ['A','N']
     * @returns Array<iSign> - an array of objects that contain the id, quantity, reviewState and signId of a sign
     */
    signsForReviewStates: function (
      states: Array<string>
    ): Array<IReviewStateSign> {
      let resultArr = [] as Array<IReviewStateSign>;
      if (this.$store.getters.order) {
        if (this.$store.getters.order.signTypes) {
          this.$store.getters.order.signTypes.forEach(
            (signType: IOrderSignType) => {
              signType.signs.forEach((sign) => {
                if (states.includes(sign.reviewState)) {
                  resultArr.push(sign);
                }
              });
            }
          );
        }
      }
      return resultArr;
    },
    /**
     * Rejects the order.
     *
     * @function rejectOrder
     * @memberof module:Order
     * @returns {void}
     */
    rejectOrder: function () {
      this.$store.commit("setShowLoadingSpinner", true);
      const query = JSON.stringify({
        query: `mutation {
              mutateOrder (input: {
                id:${this.$store.getters.order.id},
                status: "${ORDER_STATUS.CANCEL}",
                manager: "${this.$store.getters.order.manager.id}"})
                {
                  order {
                    id: contentObjectId
                  }
                  errors {
                    messages
                  }
                }
              }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (this.didDataSaveSucceed(result.errors)) {
            this.saveMessage = "Order cancelled";
            this.$store.commit("setShowLoadingSpinner", false);
            if (this.userIsBuilder) {
              window.location.href = `/builder/orders/`;
            } else {
              window.location.href = `/state/${this.$store.getters.order.stateId}/edit/`;
            }
          } else {
            this.saveErrorMessage = result.errors;
            this.saveFailed = true;
            this.saveSucceeded = false;
            this.$store.commit("setShowLoadingSpinner", false);
          }
        });
    },
    /**
     * send the order back to the builder by setting the order status to QUOTING
     */
    sendBackToBuilder: function () {
      this.$store.commit("setShowLoadingSpinner", true);
      const query = JSON.stringify({
        query: `mutation {
              mutateOrder (input: {
                id:${this.$store.getters.order.id},
                status: "${ORDER_STATUS.QUOTING}",
                manager: "${this.$store.getters.order.manager.id}"})
                {
                  order {
                    id: contentObjectId
                  }
                  errors {
                    messages
                  }
                }
              }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (this.didDataSaveSucceed(result.errors)) {
            this.saveMessage = "Order sent back to builder";
            this.$store.commit("setShowLoadingSpinner", false);
            window.location.reload();
          } else {
            this.saveErrorMessage = result.errors;
            this.saveFailed = true;
            this.saveSucceeded = false;
            this.$store.commit("setShowLoadingSpinner", false);
          }
        });
    },
    /**
     * reset the save flags to their default values
     */
    resetSaveFlags: function () {
      this.saveFailed = false;
      this.saveSucceeded = false;
      this.saveErrorMessage = "";
      this.saveMessage = "";
    },
    /**
     * show the invite quoters modal
     */
    showInviteQuotersModal: function () {
      this.$store.commit(
        "setShowInviteQuotersModal",
        !this.$store.getters.showInviteQuotersModal
      );
    },
    /**
     * Sets the field error message and updates the save status.
     *
     * @param {string} errMessage - The error message to be displayed.
     * @return {boolean} - Returns false.
     */
    fieldError(errField: string, errMessage: string): boolean {
      this.errorField = errField;
      this.errorMessage = errMessage;
      return true;
    },

    /**
     * Checks if a given field is not empty.
     *
     * @param {string} field - The field value to check.
     * @param {string} fieldName - The name of the field.
     * @returns {boolean} - True if the field is not empty, false otherwise.
     */
    isFieldEmpty: function (field: string, fieldName: string): boolean {
      if (field) {
        return false;
      }
      return this.fieldError(fieldName, fieldName + " is required.");
    },

    /**
     * Checks if the given field is a valid date.
     *
     * @param {string} field - The value of the field to check.
     * @param {string} fieldName - The name of the field being checked.
     * @returns {boolean} - True if the field is a valid date, False otherwise.
     */
    isDateFieldInvalid(field: string, fieldName: string): boolean {
      if (this.isFieldEmpty(field, fieldName)) {
        return true;
      }
      var timestamp = Date.parse(field);
      if (isNaN(timestamp)) {
        return this.fieldError(fieldName, fieldName + " is invalid");
      }
      return false;
    },

    /**
     * Validates the order before it is submitted.
     *
     * @returns {boolean} - Returns true if the order is valid, false otherwise.
     */
    validateOrder: function (): boolean {
      //order name required
      if (this.isFieldEmpty(this.$store.getters.order.name, "Order Name")) {
        this.errorField = "orderName";
        this.errorMessage = "Order name can't be empty";
        return false;
      }
      // order submitted date required and needs to be a date
      if (
        this.isDateFieldInvalid(
          this.$store.getters.order.submittedDate,
          "Issued Date"
        )
      ) {
        return false;
      }

      // the shipping address is required
      if (
        this.isFieldEmpty(
          this.$store.getters.order.shippingStreet1,
          "Shipping address"
        )
      ) {
        this.errorField = "shippingAddress";
        this.errorMessage = "Shipping address is required";
        return false;
      }

      // the shipping address is required
      if (
        this.isFieldEmpty(
          this.$store.getters.order.billingStreet1,
          "Billing Address"
        )
      ) {
        this.errorField = "billingAddress";
        this.errorMessage = "Billing address is required";
        return false;
      }

      // at least one of the include flags must be set
      if (
        !this.$store.getters.order.includeInstall &&
        !this.$store.getters.order.IncludeBuild
      ) {
        this.errorField = "includeFlags";
        this.errorMessage =
          "You need to include at least installation or signs. They can't both be unchecked";
        return false;
      }

      // there must be at least one sign on the order
      if (this.signsForReviewStates(["A"]).length === 0) {
        this.saveFailed = true;
        this.saveSucceeded = false;
        this.saveErrorMessage =
          "There are no signs on the order. Please add some signs to this order state";
        return false;
        // return this.fieldError(
        //   "There are no signs on the order. Please add some signs to this order state"
        // );
      }
      return true;
    },
    /**
     * Saves the draft order.
     * Updates the header changes.
     * Sets the isDirty property to false.
     *
     * @memberOf <componentName>
     */
    saveDraft: async function () {
      await this.saveHeaderChanges().then(() => {
        this.saveDraftOrder();
        this.isDirty = false;
      });
    },
    /**
     * show the submit for quote confirmation dialog before calling quoteConfirmed
     */
    submitForQuote: function () {
      if (this.validateOrder()) {
        this.showQuoteConfirmModal = true;
      }
    },
    closeSubmitForQuoteModal: function () {
      this.showQuoteConfirmModal = false;
    },
    /**
     * Checks if data saving succeeded or failed and updates the relevant variables.
     *
     * @param {any} errors - Any errors that occurred during the data saving process.
     * @param {string} message - Optional message to be set if data saving succeeded.
     * @returns {boolean} - Returns true if data saving succeeded, otherwise false.
     */
    didDataSaveSucceed: function (errors: Array<{ message: string }>): boolean {
      if (errors) {
        this.saveFailed = true;
        this.saveSucceeded = false;
        this.saveErrorMessage = errors[0].message;
        return false;
      } else {
        this.saveFailed = false;
        this.saveSucceeded = true;
        this.saveErrorMessage = "";
        this.isDirty = false;
      }
      return true;
    },

    /**
     * Save header changes to the backend.
     * @function saveHeaderChanges
     */
    saveHeaderChanges: async function () {
      this.$store.commit("setShowLoadingSpinner", true);

      const queryString = `mutation updateOrder {
        mutateOrder(input: {
          id: ${this.$store.getters.order.id},
          name: "${this.$store.getters.order.name}",
          shippingAttention: "${
            this.$store.getters.order.shippingContact
              ? this.$store.getters.order.shippingContact
              : ""
          }",
          shippingStreet1: "${
            this.$store.getters.order.shippingStreet1
              ? this.$store.getters.order.shippingStreet1
              : ""
          }",
          shippingStreet2: "${
            this.$store.getters.order.shippingStreet2
              ? this.$store.getters.order.shippingStreet2
              : ""
          }",
          shippingCity: "${
            this.$store.getters.order.shippingCity
              ? this.$store.getters.order.shippingCity
              : ""
          }",
          shippingState: "${
            this.$store.getters.order.shippingState
              ? this.$store.getters.order.shippingState
              : ""
          }",
          shippingZipCode: "${
            this.$store.getters.order.shippingPostalCode
              ? this.$store.getters.order.shippingPostalCode
              : ""
          }",
          shippingCountry: "${
            this.$store.getters.order.shippingCountry
              ? this.$store.getters.order.shippingCountry
              : ""
          }",
          billingSameAsShipping: ${
            this.$store.getters.order.billingSameAsShipping
          },
          billingAttention: "${
            this.$store.getters.order.billingContact
              ? this.$store.getters.order.billingContact
              : ""
          }",
          billingStreet1: "${
            this.$store.getters.order.billingStreet1
              ? this.$store.getters.order.billingStreet1
              : ""
          }",
          billingStreet2: "${
            this.$store.getters.order.billingStreet2
              ? this.$store.getters.order.billingStreet2
              : ""
          }",
          billingCity: "${
            this.$store.getters.order.billingCity
              ? this.$store.getters.order.billingCity
              : ""
          }",
          billingState: "${
            this.$store.getters.order.billingState
              ? this.$store.getters.order.billingState
              : ""
          }",
          billingZipCode: "${
            this.$store.getters.order.billingPostalCode
              ? this.$store.getters.order.billingPostalCode
              : ""
          }",
          billingCountry: "${
            this.$store.getters.order.billingCountry
              ? this.$store.getters.order.billingCountry
              : ""
          }",
          manager: ${this.$store.getters.order.manager.id},
          status: "${this.$store.getters.order.status}",
          shippingCost: "${Number(this.$store.getters.order.shipping)}",
          currency: "${this.$store.getters.order.currency}",
          poNumber: ${
            this.$store.getters.order.poNumber
              ? '"' + this.$store.getters.order.poNumber + '"'
              : null
          },
          dueDate: ${
            this.$store.getters.order.dueDate
              ? typeof this.$store.getters.order.dueDate === "string"
                ? '"' + this.$store.getters.order.dueDate + '"'
                : '"' + this.$store.getters.order.dueDate.toISOString() + '"'
              : null
          },
          includeInstall: ${this.$store.getters.order.includeInstall},
          includeBuild: ${this.$store.getters.order.IncludeBuild},
        })
        {
          order {
            id: contentObjectId
            lastSaved
          }
          errors {
            messages
          }
        }
      }`;

      const query = JSON.stringify({ query: queryString });

      if (this.$store.getters.order.status === ORDER_STATUS.DRAFT) {
        let queryString2 = `mutation DeleteBuilderOrderTest {
          deleteBuilderOrder(input:{id:${this.$store.getters.order.builderOrder.id}}){
            success
          }
        }`;

        if (this.$store.getters.order.builder.email !== "") {
          queryString2 = `mutation {
              mutateBuilderOrder(input:{order:${this.$store.getters.order.id}, assignedTo:["${this.$store.getters.order.builder.email}"]}){
                builderOrder{
                  contentObjectId
                  order{
                    contentObjectId
                  }
                  builder{
                    contentObjectId
                  }
                }
                errors {
                  messages
                }
              }
            }`;
        }

        const query2 = JSON.stringify({ query: queryString2 });

        await fetch("/graphql/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: query2,
        })
          .then((res) => res.json())
          .then((result) => {
            if (this.didDataSaveSucceed(result.errors)) {
              this.saveMessage = "Builder save successful";
            }
          });
      }

      await fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (this.didDataSaveSucceed(result.errors)) {
            this.saveMessage = "Save successful";
            this.$store.commit("setShowLoadingSpinner", false);
            this.$store.commit(
              "updateOrderLastSaved",
              new Date(result.data.mutateOrder.order.lastSaved)
            );
          } else {
            this.$store.commit("setShowLoadingSpinner", false);
          }
        });
    },

    /**
     * Submits a confirmed quote and updates the order status to "Quoting".
     * It also creates a builderOrder for the submitted quote.
     *
     * @param {string} builderEmail - The email of the builder assigned to the quote
     */
    submitQuoteConfirmed: function (builderEmail: string) {
      if (this.validateOrder()) {
        this.saveHeaderChanges().then(() => {
          this.$store.commit("setShowLoadingSpinner", true);
          const query = JSON.stringify({
            query: `mutation{
                  mutateBuilderOrder(input:{order:${this.$store.getters.order.id}, assignedTo:["${builderEmail}"]}){
                    builderOrder{
                      contentObjectId
                      order{
                        contentObjectId
                      }
                      builder{
                        contentObjectId
                      }
                    }
                    errors {
                      messages
                    }
                  }
                }`,
          });

          fetch("/graphql/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: query,
          })
            .then((res) => res.json())
            .then((result) => {
              if (this.didDataSaveSucceed(result.errors)) {
                this.$store.commit("setOrderStatus", {
                  orderId: this.$store.getters.order.id,
                  status: ORDER_STATUS.QUOTING,
                });
                const query2 = JSON.stringify({
                  query: `mutation {
                    mutateOrder (input: {
                      id:${this.$store.getters.order.id},
                      status: "${ORDER_STATUS.QUOTING}",
                      manager: "${this.$store.getters.order.manager.id}"})
                      {
                        order {
                          id: contentObjectId
                        }
                      }
                    }`,
                });

                fetch("/graphql/", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: query2,
                })
                  .then((res) => res.json())
                  .then((result) => {
                    if (this.didDataSaveSucceed(result.errors)) {
                      this.saveMessage = "Order submitted successfully";
                      this.saveDraftOrder();
                      window.location.reload();
                    } else {
                      console.error("order submission failed");
                      console.table(result.errors);
                      this.$store.commit("setShowLoadingSpinner", false);
                    }
                  });
              } else {
                this.$store.commit("setShowLoadingSpinner", false);
              }
            });
        });
      } else {
        // order validation failed
        this.showQuoteConfirmModal = false;
      }
    },
    openPermissionsUI: function () {
      console.debug("Need Permissions UI hook");
    },
    /**
     * Sets the flag to show the review confirmation modal.
     *
     * @function submitForReview
     * @memberof ClassName
     */
    submitForReview: function () {
      if (this.validateOrder()) {
        this.saveDraft();
        this.showReviewConfirmModal = true;
      }
    },
    /**
     * Sets the flag to show the review check out modal.
     *
     * @function submitForCheckout
     */
    submitForCheckout: function () {
      if (this.validateOrder()) {
        this.showCheckoutConfirmModal = true;
      }
    },
    /**
     * Save Signs
     */
    saveSigns: function () {
      this.signsThatNeedSaving.forEach((sign) => {
        const query = JSON.stringify({
          query: `mutation updateSign {
            mutateSign (input: {
              id: ${sign.id},
              facingDirection: ${sign.facingDirection},
              reviewState: "${sign.reviewState}",
            }) {
              sign {
                id
              }
            }
          }`,
        });

        fetch("/graphql/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: query,
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.errors) {
              alert(result.errors);
            }
          });
      });
    },
    /**
     * Saves the draft order by making two GraphQL mutations.
     * The first mutation updates the order's manager field.
     * The second mutation updates the order's quote with the prices of the order items.
     * If both mutations succeed, the page is reloaded.
     */
    saveDraftOrder: async function () {
      this.$store.commit("setShowLoadingSpinner", true);

      let orderItemsString = "{";
      this.$store.getters.order.signTypes.forEach((item: IOrderSignType) => {
        /* eslint-disable-next-line */
        orderItemsString += `\\"${item.orderLineItemId}\\":{\\"cost\\":${Number(item.actual_price)}, \\"install_cost\\":${Number(item.install_price)}, \\"cost_currency\\":\\"${item.currency}\\"},`;
      });
      // remove trailing comma
      orderItemsString = orderItemsString.slice(0, -1);
      orderItemsString += "}";

      if (
        this.$store.getters.order.status === ORDER_STATUS.QUOTING &&
        this.userIsBuilder
      ) {
        const query2 = JSON.stringify({
          query: `mutation{
            mutateQuote(input:{order:${
              this.$store.getters.order.id
            }, shippingCost: ${Number(
            this.$store.getters.order.shipping
          )} , orderItems: "${orderItemsString}"
          })
          {
            order
            taxName
            taxAmount
            errors {
              messages
            }
          }
        }`,
        });

        fetch("/graphql/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: query2,
        })
          .then((res) => res.json())
          .then((result) => {
            if (this.didDataSaveSucceed(result.errors)) {
              this.saveMessage = "Save successful";
              this.$store.commit(
                "updateOrderTaxName",
                result.data.mutateQuote.taxName
              );
              this.$store.commit(
                "updateOrderTaxAmount",
                Number(result.data.mutateQuote.taxAmount)
              );
              this.saveSigns();
            } else {
              this.saveErrorMessage = "Save failed";
            }
            this.$store.commit("setShowLoadingSpinner", false);
          });
      } else {
        this.saveMessage = "Save successful";
        this.$store.commit("setShowLoadingSpinner", false);
        this.saveSigns();
      }
    },

    /**
     * Submits the review confirmation for the order.
     */
    submitReviewConfirmed: function () {
      this.saveHeaderChanges().then(() => {
        this.$store.commit("setShowLoadingSpinner", true);
        const query = JSON.stringify({
          query: `mutation {
          mutateOrder (input: {
            id:${this.$store.getters.order.id},
            status: "${ORDER_STATUS.IN_REVIEW}",
            manager: "${this.$store.getters.order.manager.id}",
            })
            {
              order {
                id: contentObjectId
                status
              }
              errors {
                messages
              }
            }
          }`,
        });

        fetch("/graphql/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: query,
        })
          .then((res) => res.json())
          .then((result) => {
            if (this.didDataSaveSucceed(result.errors)) {
              // update the quote with the prices
              let orderItemsString = "{";
              this.$store.getters.order.signTypes.forEach(
                (item: IOrderSignType) => {
                  /* eslint-disable-next-line */
                orderItemsString += `\\"${item.orderLineItemId}\\":{\\"cost\\":${item.actual_price}, \\"install_cost\\":${item.install_price}, \\"cost_currency\\":\\"${item.currency}\\"},`;
                }
              );
              orderItemsString = orderItemsString.slice(0, -1);
              orderItemsString += "}";

              const query2 = JSON.stringify({
                query: `mutation{
 	                      mutateQuote(input:{order:${this.$store.getters.order.id}, shippingCost: ${this.$store.getters.order.shipping}, orderItems: "${orderItemsString}"
     	                 })
                       {
                          order
                          errors {
                            messages
                          }
                        }
                      }`,
              });

              fetch("/graphql/", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: query2,
              })
                .then((res) => res.json())
                .then((result) => {
                  if (this.didDataSaveSucceed(result.errors)) {
                    window.location.reload();
                  } else {
                    this.$store.commit("setShowLoadingSpinner", false);
                  }
                });
            } else {
              this.$store.commit("setShowLoadingSpinner", false);
            }
          });
      });
    },
    submitCheckoutConfirmed: function () {
      this.payInvoiceLater();
    },
    payInvoiceNow() {
      this.submitOrder();
    },
    payInvoiceLater() {
      this.$store.commit("setShowLoadingSpinner", true);
      const query = JSON.stringify({
        query: `mutation {
	                orderOfflinePayment(orderId: ${this.$store.getters.order.id}) {
                    success
                  }
                }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then(() => {
          // window.location.reload();
          window.location.href = `/order/${this.$store.getters.order.id}/confirmation/`;
        });
    },
    /**
     * Submits order details to the server and creates a Stripe checkout session for payment.
     */
    submitOrder: function () {
      const query = JSON.stringify({
        query: `mutation {
          mutateOrder (input: {
            id:${this.$store.getters.order.id},
            status: "${ORDER_STATUS.IN_REVIEW}",
            manager: "${this.$store.getters.order.manager.id}"})
            {
              order {
                id: contentObjectId
              }
            }
          }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (this.didDataSaveSucceed(result.errors)) {
            const query3 = JSON.stringify({
              query: `mutation{
              createStripeCheckoutSession(input:{orderId:"${this.$store.getters.order.id}"}){
                checkoutSession
              }
            }`,
            });

            fetch("/graphql/", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: query3,
            })
              .then((res) => res.json())
              .then((result) => {
                if (this.stripe) {
                  this.stripe.redirectToCheckout({
                    sessionId:
                      result.data.createStripeCheckoutSession.checkoutSession,
                  });
                }
              });
          }
        });
    },
    fetchSignTypeSigns: async function (
      signTypeId: string,
      cursor?: string
    ): Promise<ISignTypeQL> {
      const query = `
        query getOrderItemSigns ($id: ID, $cursor: String) {
          signOrderItem (id: $id) {
            id:contentObjectId
            signType: signTemplate {
              id: contentObjectId
              name
              shortCode
              hexColor
            }
            signs (first:100, after:$cursor) {
              edges {
                node {
                  id: contentObjectId
                  signId
                  number
                  quantity
                  facingDirection
                  reviewState
                  artwork
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        }
      `;

      const variables = cursor
        ? { id: signTypeId, cursor: cursor }
        : { id: signTypeId, cursor: "" };

      try {
        const response = await fetch("/graphql/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ query, variables }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        return result.data.signOrderItem;
      } catch (error) {
        console.error(
          "There has been a problem with your fetch operation:",
          error
        );
        throw error;
      }
    },
    async fetchAllSignTypeSigns(id: string): Promise<IOrderSignTypeSign[]> {
      let allSigns: IOrderSignTypeSign[] = [];
      let hasNextPage = true;
      let cursor: string | undefined = undefined;

      while (hasNextPage) {
        const signType: ISignTypeQL = await this.fetchSignTypeSigns(id, cursor);
        const signs = signType.signs.edges.map((edge) => edge.node);
        for (const sign of signs) {
          allSigns.push({
            id: sign.id.toString(),
            signId: sign.signId,
            artwork: sign.artwork,
            quantity: sign.quantity,
            number: sign.number,
            facingDirection: sign.facingDirection,
            reviewState: sign.reviewState,
            hexColor: signType.signType.hexColor,
            messages: "",
            repeatingMessages: "",
            signMessageFields: [],
            repeatingMessageFields: [],
            details: "",
            attachments: [],
            signType: {
              id: signType.signType.id.toString(),
              shortCode: signType.signType.shortCode,
              name: signType.signType.name,
              installPrice: 0,
              signPrice: 0,
              currency: "",
            },
            location: {
              id: "",
              shortCode: "",
              name: "",
            },
          });
        }
        hasNextPage = signType.signs.pageInfo.hasNextPage;
        cursor = signType.signs.pageInfo.endCursor;
      }

      return allSigns;
    },
    startLoadingSigns: async function () {
      for (const signType of this.$store.getters.order.signTypes) {
        let signTypeSigns = [] as Array<IOrderSignTypeSign>;
        try {
          signTypeSigns = await this.fetchAllSignTypeSigns(
            signType.orderItemId
          );
        } catch (error) {
          this.saveErrorMessage = error.message;
        } finally {
          this.loading = false;
        }

        this.$store.commit("setSignTypeSignsLoaded", {
          signTypeId: signType.id,
          value: true,
        });
        this.$store.commit("setSignTypeSigns", {
          signTypeId: signType.id,
          signs: signTypeSigns,
        });
      }
    },
    /**
     * read the order information from graphQL
     */
    fetchData() {
      // this.order = this.$store.getters.ordersById(this.$route.params.id);
      const query = JSON.stringify({
        query: `query get_order {
          order(id: ${this.$route.params.id}) {
            id: contentObjectId
            uuid
            name
            submittedDate
            dueDate
            currency
            poNumber
            lastSaved
            projects {
              id
              name
            }
            quote {
              taxName
              taxAmount
            }
            builders:assignedBuilders {
              edges {
                node {
                  id: contentObjectId
                  assignedTo{
                    email
                  }
                  builder {
                    id: contentObjectId
                  	name
                  	company {

                      billing {
                        paymentMethods {
                          brand
                          last4
                        }
	                    }
  	                }
                  }
                }
              }
            }
            comments {
              edges {
                node {
                  id
                  message
                  attachment
                  user {
                    userId
                    firstName
                    lastName
                    pic
                  }
                }
              }
            }
            state {
              id: contentObjectId
              name
              stateType
              phase{
                id:contentObjectId
                name
              }
              project {
                id: contentObjectId
                name
                hasOrgOwner
              }
            }
            status
            manager {
              id: userId
              firstName
              lastName
              email
              image
            }
            shippingAttention
            shippingStreet1
            shippingStreet2
            shippingCity
            shippingState
            shippingZipCode
            shippingCountry
            billingSameAsShipping
            billingAttention
            billingStreet1
            billingStreet2
            billingCity
            billingState
            billingZipCode
            billingCountry
            includeInstall
            includeBuild
            userIsBuilder
            shippingCost
            orderItems {
              edges {
                node {
                  orderLineItemId:contentObjectId
                  signorderitem {
                    id: contentObjectId
                    quantity
                    cost
                    installCost
                    costCurrency
                    signTemplate {
                      id:contentObjectId
                      name
                      shortCode
                      hexColor
                      orderId
                      globalOrderIdVal
                      folder {
                        orderId
                      }
                    }
                  }
                }
              }
            }
          }
        }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.data.order.state) {
            this.hasOrgOwner = result.data.order.state.project.hasOrgOwner;
            this.phase_name = result.data.order.state.phase.name;
            this.phase_id = result.data.order.state.phase.id;
            this.state_name = result.data.order.state.name;
            this.state_id = result.data.order.state.id;
          }

          let builderId = "";
          let builderName = "";
          let builderEmail = "";
          let builderOrderId = "";
          if (result.data.order.builders.edges.length > 0) {
            builderId = result.data.order.builders.edges[0].node.builder.id;
            builderName = result.data.order.builders.edges[0].node.builder.name;
            builderEmail =
              result.data.order.builders.edges[0].node.assignedTo.email;
            builderOrderId = result.data.order.builders.edges[0].node.id;
          }

          const newOrder: IOrder = {
            id: result.data.order.id,
            uuid: result.data.order.uuid,
            name: result.data.order.name,
            submittedDate: result.data.order.submittedDate,
            dueDate: result.data.order.dueDate,
            amount: 0,
            currency: result.data.order.currency,
            stateId: result.data.order.state.id,
            stateName: result.data.order.state.name,
            lastSaved: result.data.order.lastSaved
              ? new Date(result.data.order.lastSaved)
              : null,
            organization: {
              id: result.data.order.state.project.id,
              name: result.data.order.state.project.name,
            },
            status: result.data.order.status.substring(2),
            poNumber: result.data.order.poNumber,
            manager: {
              id: result.data.order.manager.id,
              firstName: result.data.order.manager.firstName,
              lastName: result.data.order.manager.lastName,
              email: result.data.order.manager.email,
              image: result.data.order.manager.image,
            },
            builder: {
              id: builderId,
              name: builderName,
              email: builderEmail,
            },
            builderOrder: {
              id: builderOrderId,
            },
            userIsBuilder: result.data.order.userIsBuilder,
            signTypes: this.parseSignTypes(result.data.order.orderItems),
            itemSubtotal: 0,
            shipping: result.data.order.shippingCost,
            taxes: {
              name: result.data.order.quote
                ? result.data.order.quote.taxName
                : "",
              amount: result.data.order.quote
                ? result.data.order.quote.taxAmount
                : 0,
            },
            shippingStreet1: result.data.order.shippingStreet1,
            shippingStreet2: result.data.order.shippingStreet2,
            shippingCity: result.data.order.shippingCity,
            shippingState: result.data.order.shippingState,
            shippingPostalCode: result.data.order.shippingZipCode,
            shippingCountry: result.data.order.shippingCountry,
            shippingContact: result.data.order.shippingAttention,
            billingSameAsShipping: result.data.order.billingSameAsShipping,
            billingStreet1: result.data.order.billingStreet1,
            billingStreet2: result.data.order.billingStreet2,
            billingCity: result.data.order.billingCity,
            billingState: result.data.order.billingState,
            billingPostalCode: result.data.order.billingZipCode,
            billingCountry: result.data.order.billingCountry,
            billingContact: result.data.order.billingAttention,
            includeInstall: result.data.order.includeInstall,
            IncludeBuild: result.data.order.includeBuild,
            comments: [],
            projects: result.data.order.projects,
          };
          result.data.order.comments.edges.forEach((comment: ICommentQl) => {
            newOrder.comments.push({
              commentId: comment.node.id,
              message: comment.node.message,
              userId: comment.node.user.userId,
              userFirstName: comment.node.user.firstName,
              userLastName: comment.node.user.lastName,
              userPic: comment.node.user.pic,
            });
          });
          // this.order = newOrder;
          this.$store.commit("setOrder", newOrder);

          // if this order is being quoted check to see if builder has a card on file
          if (
            this.$store.getters.order.status === ORDER_STATUS.QUOTING &&
            this.userIsBuilder
          ) {
            // if the builder has any paymentMethods then set builderHasCardOnFile to true
            if (
              result.data.order.builders.edges[0].node.builder.company.billing
                .paymentMethods
            ) {
              this.builderHasCardOnFile =
                result.data.order.builders.edges[0].node.builder.company.billing
                  .paymentMethods.length > 0;
            } else {
              this.builderHasCardOnFile = false;
            }

            // if the user is on free trial then set buildHasCardOnFile to true
            // so user doesn't need to enter address or credit card info
            if (this.$store.state.session.isOnBuildFreeTrial) {
              this.builderHasCardOnFile = true;
            }
          }
          this.$store.commit("setShowLoadingSpinner", false);

          this.startLoadingSigns();
        });
    },
    mapSignMessageFields: function (
      signMessageFields: Array<ISignMessageField>
    ): Array<{ name: string; fieldType: string; value: string }> {
      let result = [] as Array<{
        name: string;
        fieldType: string;
        value: string;
      }>;
      if (signMessageFields) {
        signMessageFields.forEach((message) => {
          let name = message.attribute.name;
          const fieldType = message.attribute.fieldType;

          let value = "";
          switch (message.attribute.fieldType) {
            case "char":
              value = message.charValue;
              break;
            case "char_x":
            case "trans":
            case "text":
              value = message.textValue;
              break;
            case "date":
              value = message.dateValue;
              break;
            case "icon":
            case "icon_t":
            case "icon_x":
              value = message.icon.imageUrl;
              break;
            case "color":
            case "color_t":
            case "color_x":
              name = message.colorX.name;
              value = message.colorX.color;
          }

          result.push({ name: name, fieldType: fieldType, value: value });
        });
      }
      return result;
    },
    mapRepeatingMessageFields: function (
      repeatingMessageFields: Array<IRepeatingMessageField>
    ): Array<{ name: string; fieldType: string; value: string }> {
      let result = [] as Array<{
        name: string;
        fieldType: string;
        value: string;
      }>;
      if (repeatingMessageFields) {
        repeatingMessageFields.forEach((message) => {
          if (message.field) {
            let name = message.field.attribute.name;
            const fieldType = message.field.attribute.fieldType;

            let value = "";
            switch (message.field.attribute.fieldType) {
              case "char":
                value = message.field.charValue;
                break;
              case "char_x":
              case "trans":
              case "text":
                value = message.field.textValue;
                break;
              case "date":
                value = message.field.dateValue;
                break;
              case "icon":
              case "icon_t":
              case "icon_x":
                if (message.field.icon) {
                  value = message.field.icon.imageUrl;
                } else {
                  value = "";
                }
                break;
              case "color":
              case "color_t":
              case "color_x":
                name = message.field.colorX.name;
                value = message.field.colorX.color;
            }

            result.push({ name: name, fieldType: fieldType, value: value });
          }
        });
      }
      return result;
    },
    /**
     * converts the list of signs with sign types into an array of sign types containing signs
     * this is useful because the order is grouped by sign type
     * @param signs IOrderSigns - a list of signs formatted as it comes from graphQL
     * @returns Array<IOrderSignType> - an array of IOrderSignType
     */
    parseSignTypes: function (orderItems: IOrderItems): Array<IOrderSignType> {
      let signTypes = [] as Array<IOrderSignType>;

      orderItems.edges.forEach((orderItem) => {
        const signType = signTypes.find((signType) => {
          return signType.id === orderItem.node.signorderitem.signTemplate.id;
        });

        if (!signType) {
          let newSignType: IOrderSignType = {
            id: orderItem.node.signorderitem.signTemplate.id,
            orderItemId: Number(orderItem.node.orderLineItemId),
            name: orderItem.node.signorderitem.signTemplate.name,
            shortCode: orderItem.node.signorderitem.signTemplate.shortCode,
            hexColor: orderItem.node.signorderitem.signTemplate.hexColor,
            isExpanded: false,
            isRemoveExpanded: false,
            install_price: orderItem.node.signorderitem.installCost,
            actual_price: orderItem.node.signorderitem.cost,
            currency: orderItem.node.signorderitem.costCurrency,
            orderLineItemId: orderItem.node.orderLineItemId,
            orderId: orderItem.node.signorderitem.signTemplate.orderId,
            globalOrderIdVal:
              orderItem.node.signorderitem.signTemplate.globalOrderIdVal,
            folderOrderId: orderItem.node.signorderitem.signTemplate.folder
              ? orderItem.node.signorderitem.signTemplate.folder.orderId
              : 0,
            quantity: orderItem.node.signorderitem.quantity,
            signs: [],
            signsLoaded: false,
          };

          // orderItem.node.signorderitem.signs.edges.forEach((sign) => {
          //   newSignType.signs.push({
          //     id: sign.node.id,
          //     signId: sign.node.signId,
          //     artwork: sign.node.artwork,
          //     quantity: orderItem.node.signorderitem.quantity,
          //     number: sign.node.number,
          //     facingDirection: sign.node.facingDirection,
          //     reviewState: sign.node.reviewState,
          //     hexColor: newSignType.hexColor,
          //     messages: "",
          //     repeatingMessages: "",
          //     signMessageFields: [],
          //     repeatingMessageFields: [],
          //     details: "",
          //     attachments: [],
          //     signType: {
          //       id: sign.node.signType.id,
          //       shortCode: sign.node.signType.shortCode,
          //       name: sign.node.signType.name,
          //       installPrice: orderItem.node.signorderitem.installCost,
          //       signPrice: orderItem.node.signorderitem.cost,
          //       currency: orderItem.node.signorderitem.costCurrency,
          //     },
          //     location: {
          //       id: sign.node.location.id,
          //       shortCode: sign.node.location.shortCode,
          //       name: sign.node.location.name,
          //     },
          //   });
          // });

          signTypes.push(newSignType);
        }
      });

      return signTypes;
    },
  },
  computed: {
    ORDER_STATUS: function () {
      return ORDER_STATUS;
    },
    userIsBuilder: function (): boolean {
      if (this.$store.getters.order) {
        return this.$store.getters.order.userIsBuilder;
      } else {
        return false;
      }
    },
  },
  /**
   * Watches the $route object and trigger the fetchData method whenever the route changes.
   *
   * @memberof VueComponent
   * @method watch$route
   */
  watch: {
    // call fetchData if the route changes
    $route: "fetchData",
  },
});
