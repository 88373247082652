






import { Component, Vue } from "vue-property-decorator";

import FullPageLayout from "@/components/layouts/FullPageLayout.vue";

import PathNotFound from "./PathNotFound.vue";

@Component({
  components: {
    FullPageLayout,
    PathNotFound,
  },
})
export default class PathNotFound_FullPage extends Vue {}
