

















































































































































































































































































































































































































































import {
  IState,
  IGroupUser,
  IGraphQLGroupUser,
  IEnumStateTypes,
} from "../types";
import Vue from "vue";
import $ from "jquery";

import eventBus from "@/features/EventBus";

import ColorPicker from "../components/StateColorPicker.vue";

import { STATE_TYPE, randomRGB } from "../utilities";
import { ORDER_STATUS } from "@/features/Orders/types";
interface IAdminMembers {
  id: string;
  email: string;
}

export default Vue.extend({
  components: { ColorPicker },
  data: function () {
    return {
      saveSucess: false,
      saveError: false,
      errorMessage: "",
      originalStateType: "",
      saState: {} as IState,
      displayColorPicker: false,
      assignedEmails: [] as Array<{ email: string }>,
      managerId: 0,
      members: [] as Array<IAdminMembers>,
      includeInstall: true,
      includeBuild: true,
      leftPanelHeight: 325,
      rightPanelHeight: 325,
      showIncludeWarning: false,
      stateTypes: [] as Array<IEnumStateTypes>,
      userMode: "edit",
      managerDetails: [],
    };
  },
  mounted: function () {
    // set the userMode
    // the path will be something like state/create/ or state/1/edit/
    // we need to find the last segment to determine what "mode" we are in.
    const segments = this.$route.path.split("/");
    const lastSegment = segments[segments.length - 2];

    this.userMode = lastSegment.toLowerCase();

    // load the state types from the backend
    this.loadStateTypes().then(() => {
      // if we are in create mode load the default state otherwise load the state
      this.userMode === "create" ? this.loadDefaultState() : this.loadState();
    });
  },
  created: function () {
    eventBus.$on("closingPermissionsUI", this.permissionsUIClosing);
  },
  methods: {
    permissionsUIClosing: function () {
      //reload the assignedUsers
      this.loadAssignedUsers();
    },
    showPermissions: function () {
      // made this an any type because I wasn't able to figure out the typeof globalThis
      /* eslint-disable-next-line */
      const globalThis = window.globalThis as any;
      globalThis.sa_vue.showPermissionsUI({
        model: "state",
        id: Number(this.saState.id),
      });
    },
    setHeaderColorClass: function () {
      const stateType = Number(this.saState.stateType);
      if (stateType === Number(STATE_TYPE.ORDER)) {
        return "panel-build";
      }

      if (stateType === Number(STATE_TYPE.REVIEW)) {
        return "panel-design";
      }

      return "panel-main";
    },
    loadOrgAdmins: async function () {
      const query = JSON.stringify({
        query: `query {
          workflow (id: "${this.saState.workflow}") {
            project {
              admins:groupUsers {
                edges {
                  node {
                    user {
                      id:contentObjectId
                      email
                    }
                  }
                }
              }
            }
          }
        }`,
      });

      await fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          result.data.workflow.project.admins.edges.forEach(
            (admin: { node: { user: { id: string; email: string } } }) => {
              this.members.push({
                id: admin.node.user.id,
                email: admin.node.user.email,
              });
            }
          );
        });
    },
    checkboxChanged: function () {
      // at least one of the include flags must be checked
      if (!this.includeInstall && !this.includeBuild) {
        this.showIncludeWarning = true;
        document.querySelector("#saveButton")?.setAttribute("disabled", "");

        return false;
      } else {
        this.showIncludeWarning = false;
        document.querySelector("#saveButton")?.removeAttribute("disabled");
      }
    },

    changeColor: function (value: {
      a: number;
      hex: string;
      hex8: string;
      hsl: { a: number; h: number; l: number; s: number };
      hsv: { a: number; h: number; s: number; v: number };
      oldHue: number;
      rgba: { a: number; b: number; g: number; r: number };
      source: string;
    }) {
      this.saState.hexColor = value.hex;
    },
    setWorkflowFromFolder: async function (folderId: string | null) {
      const query = JSON.stringify({
        query: `query getStateFolder {
        stateFolder (id:"${folderId}") {
          workflow {
            id:contentObjectId
          }
        }
      }`,
      });

      await fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          this.saState.workflow = result.data.stateFolder.workflow.id;
        });
    },
    loadStateTypes: async function () {
      const query = JSON.stringify({
        query: `query get_enum_values{
            __type(name:"StateStateStateTypeChoices"){
              name
              enumValues{
              name
              description
            }
          }
        }`,
      });
      await fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          result.data.__type.enumValues.forEach(
            (enumStateTypes: IEnumStateTypes) => {
              let tempType = enumStateTypes;
              // name is like `A_123`, so remove the `A_`
              tempType.name = tempType.name.substring(2);
              this.stateTypes.push(tempType);
            }
          );
        });
    },
    /**
     * load saState with default values.
     */
    loadDefaultState: function () {
      //generate RGB colors that are not too dark or too light
      const hexColor = randomRGB();

      // set up the default saState values
      this.saState = {
        id: 0,
        name: "",
        stateType: STATE_TYPE.DESIGN,
        workflow: "",
        project: "",
        projectName: "",
        folder: "",
        details: "",
        hexColor: "#" + hexColor,
        overrideTypeColor: false,
        startDate: "",
        endDate: "",
        orderId: 0,
        archived: false,
        approved: "Approved",
        rejected: "Rejected",
        needsReviewed: "Review",
        groupUsers: [],
        orders: [],
      };
      // when we are creating a state django sends either a workflow or folder query param
      // for example: /state/create?workflow=1 or /state/create/folder=35
      // if we are passed the workflow then we can just set the workflow from the value in the query string
      // however, if we are passed a folder then we must read the folder to get the workflow
      if (this.$route.query.workflow) {
        this.saState.workflow = this.$route.query.workflow.toString();
      } else if (this.$route.query.folder) {
        // get the folder record so we can read the workflow id
        this.saState.folder = this.$route.query.folder.toString();
        this.setWorkflowFromFolder(this.$route.query.folder.toString());
      } else {
        console.log("Missing required parameter");
      }
      this.loadOrgAdmins();
      this.updatePanelHeights(this.saState.stateType);
    },
    /**
     * load a state from the backend
     */
    loadState: function () {
      // we are in edit mode
      const query = JSON.stringify({
        query: `query getState {
        state(id: "${this.$route.params.id}") {
            id: contentObjectId
            name
            workflow{
              id:contentObjectId
              project {
                id: contentObjectId
              }
            }
            folder {
              id: contentObjectId
            }
            order {
              id: contentObjectId
              manager {
                id: userId
              }
              status
            }
            stateType
            details
            hexColor
            startDate
            endDate
            overrideTypeColor
            archived
            approved
            rejected
            needsReviewed
            orderId
            project {
              name
              admins: groupUsers(groupNames:["admin"]) {
                edges {
                  node {
                    user {
                      id:userId
                      email
                    }
                  }
                }
              }
            }
            groupUsers (inheritedGroups:false,groupNames:["assigned"]) {
              edges {
                node {
                  user {
                    email
                    firstName
                    lastName
                    pic
                  }
                  group {
                    name
                  }
                }
              }
            }
            admins:groupUsers(groupNames:["admin"]) {
              edges {
                node {
                  user {
                    id:contentObjectId
                    email
                  }
                }
              }
            }
          }
        }
      `,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          const dataState = JSON.parse(JSON.stringify(result.data.state));

          const groupUsers = [] as Array<IGroupUser>;

          dataState.groupUsers.edges.forEach((group: IGraphQLGroupUser) => {
            groupUsers.push({
              groupName: group.node.group.name,
              email: group.node.user.email,
              firstName: group.node.user.firstName,
              lastName: group.node.user.lastName,
              pic: group.node.user.pic,
            });
          });

          // set the manager Id
          if (dataState.order) {
            this.managerId = dataState.order.manager.id;
          } else {
            this.managerId = this.$store.state.session.userId;
          }

          this.saState = {
            id: dataState.id,
            name: dataState.name,
            stateType: dataState.stateType.substring(2),
            workflow: dataState.workflow.id,
            project: dataState.workflow.project.id,
            projectName: dataState.project.name,
            folder: dataState.folder ? dataState.folder.id : "",
            details: dataState.details,
            hexColor: "#" + dataState.hexColor,
            overrideTypeColor: dataState.overrideTypeColor,
            startDate: dataState.startDate,
            endDate: dataState.endDate,
            orderId: dataState.orderId,
            archived: dataState.archived,
            approved: dataState.approved,
            rejected: dataState.rejected,
            needsReviewed: dataState.needsReviewed,
            groupUsers: groupUsers,
            orders: [dataState.order],
          };
          this.setAssignedEmails();
          dataState.project.admins.edges.forEach(
            (admin: { node: { user: { id: string; email: string } } }) => {
              this.members.push({
                id: admin.node.user.id,
                email: admin.node.user.email,
              });
            }
          );
          this.originalStateType = this.saState.stateType;
          this.updatePanelHeights(this.saState.stateType);
        });
    },
    /**
     * load the assigned users from the backend
     */
    loadAssignedUsers: function () {
      this.$store.commit("setShowLoadingSpinner", true);
      const query = JSON.stringify({
        query: `query getState {
        state(id: "${this.$route.params.id}") {
            groupUsers (inheritedGroups:false,groupNames:["assigned"]) {
              edges {
                node {
                  user {
                    email
                    firstName
                    lastName
                    pic
                  }
                  group {
                    name
                  }
                }
              }
            }
          }
        }
      `,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          const dataState = JSON.parse(JSON.stringify(result.data.state));

          const groupUsers = [] as Array<IGroupUser>;

          dataState.groupUsers.edges.forEach((group: IGraphQLGroupUser) => {
            groupUsers.push({
              groupName: group.node.group.name,
              email: group.node.user.email,
              firstName: group.node.user.firstName,
              lastName: group.node.user.lastName,
              pic: group.node.user.pic,
            });
          });

          this.saState.groupUsers = groupUsers;

          this.setAssignedEmails();
          this.$store.commit("setShowLoadingSpinner", false);
        });
    },
    setAssignedEmails: function () {
      const users = this.saState.groupUsers.filter((user) => {
        return user.groupName.toLowerCase() === "assigned";
      });
      const emails = [] as Array<{ email: string }>;

      users.forEach(function (user) {
        emails.push({ email: user.email });
      });

      this.assignedEmails = emails;
    },
    confirmDelete: function () {
      $("#confirmStateDelete").modal("show");
    },
    deleteState: function () {
      const query = JSON.stringify({
        query: `mutation deleteState {
        deleteState(input: {id: ${this.saState.id}}) {
          success
        }
      }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.errors) {
            this.saveError = true;
            this.saveSucess = false;
            this.errorMessage = result.errors[0].message;
          } else {
            if (this.saState.folder !== "") {
              window.location.href =
                "/state/folder/" + this.saState.folder + "/edit/";
            } else {
              window.location.href =
                "/project/" + this.saState.project + "/edit/";
            }
          }
        });
    },
    saveChanges: function () {
      // make sure that required fields are filled in
      // this.saveButtonDisabled = true;
      if (this.saState.stateType === STATE_TYPE.ORDER) {
        if (this.managerId === 0) {
          this.saveError = true;
          this.errorMessage = "Manager is required to create an order state";
        }
      }

      if (!this.saveError) {
        this.$store.commit("setShowLoadingSpinner", true);

        // strip leading #'s from hexColor
        let hexColor = this.saState.hexColor;
        while (hexColor.charAt(0) === "#") {
          hexColor = hexColor.substring(1);
        }

        // reduce assigned users to an array of strings
        let emails = [] as Array<string>;
        this.assignedEmails.forEach((email) => {
          emails.push(email.email);
        });

        let queryString = `mutation StateMutation{
            mutateState(input:{
              stateType: "${this.saState.stateType}",
              workflow: "${this.saState.workflow}",
            `;

        if (this.saState.folder !== "") {
          queryString += `folder: "${this.saState.folder}",`;
        }
        if (this.saState.stateType === STATE_TYPE.ORDER) {
          queryString += `overrideTypeColor: false,`;
        } else {
          queryString += `overrideTypeColor: ${this.saState.overrideTypeColor},`;
        }

        if (this.userMode === "edit") {
          queryString += `id: ${this.saState.id},`;
        }

        queryString += `name: "${this.saState.name}",
              details: "${this.saState.details}",
              hexColor: "${hexColor}",
            `;
        if (this.saState.startDate) {
          queryString += `startDate: "${this.saState.startDate}"`;
        }
        if (this.saState.endDate) {
          queryString += `endDate: "${this.saState.endDate}"`;
        }

        queryString += `
              orderId: ${this.saState.orderId},
              approved: "${this.saState.approved}",
              rejected: "${this.saState.rejected}",
              needsReviewed: "${this.saState.needsReviewed}",
              archived: ${this.saState.archived}}) {
                state{
                  id: contentObjectId
                  order {
                    id: contentObjectId
                  }
                }
                errors {
                  messages
                }
              }
            }`;

        let query = JSON.stringify({ query: queryString });

        fetch("/graphql/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: query,
        })
          .then((res) => res.json())
          .then((result) => {
            if (result.errors) {
              this.saveError = true;
              this.saveSucess = false;
              this.errorMessage = result.errors[0].message;
              this.$store.commit("setShowLoadingSpinner", false);
            } else if (result.data.mutateState.errors.length > 0) {
              alert(result.data.mutateState.errors[0].messages[0]);
              this.$store.commit("setShowLoadingSpinner", false);
            } else {
              this.saveSucess = true;
              this.saveError = false;
              this.saState.id = result.data.mutateState.state.id;
              this.$store.commit("setShowLoadingSpinner", false);
              if (this.saState.stateType === STATE_TYPE.ORDER) {
                const orderId = result.data.mutateState.state.order.id;
                // update the order to set the includeBuild and includeInstall flags
                queryString = `mutation OrderMutation {
                  mutateOrder(input:{
                    id: ${orderId},
                    manager: ${this.managerId},
                    status: "${ORDER_STATUS.DRAFT}",
                    includeBuild: ${this.includeBuild},
                    includeInstall: ${this.includeInstall},
                    billingSameAsShipping: true,
                    name: "${this.saState.projectName} - ${this.saState.name}"
                  })
                  {
                    order {
                      id: contentObjectId
                    }
                    errors {
                      messages
                    }
                  }
                }`;

                query = JSON.stringify({ query: queryString });
                fetch("/graphql/", {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: query,
                })
                  .then((res) => res.json())
                  .then((result) => {
                    if (result.errors) {
                      alert(result.errors[0].message);
                      this.saveError = true;
                      this.saveSucess = false;
                      this.errorMessage = result.errors[0].message;
                      this.$store.commit("setShowLoadingSpinner", false);
                    } else {
                      this.saveSucess = true;
                      this.saveError = false;
                      window.location.href =
                        "/state/" + this.saState.id + "/edit/";
                    }
                  });
              } else {
                window.location.href = "/state/" + this.saState.id + "/edit/";
              }
            }
            this.originalStateType = this.saState.stateType;
          });
      }
    },
    updatePanelHeights: function (newVal: string) {
      // design
      if (newVal === STATE_TYPE.DESIGN) {
        this.leftPanelHeight = 335;
        this.rightPanelHeight = 335;
      }
      // order
      else if (newVal === STATE_TYPE.ORDER) {
        this.leftPanelHeight = 430;
        this.rightPanelHeight = 135;
      }
      // review
      else {
        this.leftPanelHeight = 659;
        this.rightPanelHeight = 135;
      }
    },
  },
  computed: {
    stateType: function (): string {
      return this.saState.stateType;
    },
    ORDER_STATUS: function () {
      return ORDER_STATUS;
    },
    STATE_TYPE: function () {
      return STATE_TYPE;
    },
  },
  watch: {
    stateType: function (newVal) {
      this.updatePanelHeights(newVal);
    },
  },
});
