




































import Vue from "vue";
import Snap from "snapsvg-cjs-ts";

import CollapsibleSection from "../CollapsibleSection.vue";
import PickColorsModal from "../modals/PickColorsModal.vue";
import { iSignChild, iColor } from "../../types";
import {
  hexString,
  findElementById,
  updateSVGDataName,
  getCommandByName,
} from "../../utilities";
import { normalCaseToUnderscore } from "../../include/Utils";

export default Vue.extend({
  components: { CollapsibleSection, PickColorsModal },
  props: {},
  data: function () {
    return {
      selectedElement: {} as iSignChild | null,
      fillFlag: true,
    };
  },
  mounted: function () {
    this.selectedElement = JSON.parse(JSON.stringify(this.element));
  },
  methods: {
    /**
     * show the colors modal
     */
    showColorsModal: function () {
      this.$store.commit("setShowPickColorsModal", true);
    },
    /**
     * on color selected
     * called when user selects a color on the pick-colors-modal
     * @param {iColor} color - the color the user selected from the pick colors modal
     */
    onColorSelected: function (color: iColor) {
      if (this.element) {
        this.$store.commit("setShowPickColorsModal", false);
        // get current color so we can save it in undo history
        // const el = this.$store.getters.canvas.select(
        //   "[sa-data-id='" + this.element.svgId + "']"
        // );

        const hex = this.element.fill;
        this.$store.commit("captureState", {
          description: "Update Fill Color",
          undoMutations: [
            {
              mutationName: "updateElementFillColorById",
              undoValue: { elementId: this.element.id, hex: hex },
            },
          ],
          redoMutations: [
            {
              mutationName: "updateElementFillColorById",
              redoValue: {
                elementId: this.element.id,
                hex: hexString(color.hex),
              },
            },
          ],
        });
        //this.element.fill = this.hexString(e.hex);
        this.$store.commit("updateElementFillColorById", {
          elementId: this.element.id,
          hex: hexString(color.hex),
        });
        // this.selectedElement = this.element;
        // let el = this.$store.getters.canvas.select(
        //   "[sa-data-id='" + this.element.svgId + "']"
        // );
        // if (this.element.fieldType.toLowerCase() === "color") {
        //   if (el.select("rect")) {
        //     el.select("rect").attr({
        //       style: "fill: " + hexString(color.hex),
        //     });
        //   } else {
        //     el.attr({ style: "fill: " + hexString(color.hex) });
        //   }
        // } else if (this.element.fieldType.toLowerCase() === "text") {
        //   if (el.select("text")) {
        //     el.select("text").attr({
        //       style: "fill: " + hexString(color.hex),
        //     });
        //   } else {
        //     el.attr({
        //       style: "fill: " + hexString(color.hex),
        //     });
        //   }
        // } else {
        //   el.attr({ style: "fill: " + hexString(color.hex) });
        // }
        this.element.fill = hexString(color.hex);
        this.$forceUpdate();
        this.$store.commit("setIsChanged", true);
      }
    },
    /**
     * on field selected
     * called when user selects a field on the pick-colors-modal
     * @param {iColor} field - the iColor object the user selected from the pick colors modal
     *                          the field's info is saved in the iColor object
     *                          the most important fields are:
     *                              id: contains the field's id
     *                              name: contains the field's name
     */
    onFieldSelected: function (field: iColor) {
      if (this.element) {
        this.selectedElement = this.element;
        // let el = this.$store.getters.canvas.select(
        //   "[sa-data-id='" + this.element.svgId + "']"
        // );

        const oldValue = getCommandByName(
          this.element,
          "color",
          this.$store.getters.canvas
        );
        const newValue = "color: {" + normalCaseToUnderscore(field.name) + "}";

        updateSVGDataName(
          this.element.svgId,
          this.element.fieldType,
          oldValue,
          newValue,
          this.$store.getters.canvas
        );

        this.$store.commit("refreshCodeEditor");
        this.$store.commit("setIsChanged", true);
      }
    },
    /**
     * Determine if the element has a fill value
     * @param {iSignChild}  element - the element to check
     * @returns {boolean} True if the element has a fill value
     */
    hasFill: function (element: iSignChild): boolean {
      if (element.fill === "transparent" || element.fill === "none") {
        return false;
      } else {
        return true;
      }
    },
    /**
     * add a CSS rule to the style string
     * @param {Snap.Element} el - the snap element we want to add the rule to
     * @param {string} ruleToAdd - the CSS rule to add - note only one rule can be added at a time
     * @returns {string}
     */
    addRuleToStyleString: function (
      el: Snap.Element,
      ruleToAdd: string
    ): string {
      let style = "";
      if (el.attr("style")) {
        style = el.attr("style").trim();
      }
      //first check to see if the rule is already specified
      const addingRule = ruleToAdd.split(":")[0];
      const existingRules = style.split(";");
      let ruleFound = false;
      for (let i = 0; i < existingRules.length; i++) {
        const rule = existingRules[i].split(":")[0];
        if (rule.toLowerCase() === addingRule.toLowerCase()) {
          ruleFound = true;
          break;
        }
      }
      // if the rule isn't specified then add it to the style string
      if (!ruleFound) {
        if (style.charAt(style.length - 1) === ";") {
          style = style + ruleToAdd;
        } else {
          style = style + "; " + ruleToAdd;
        }
      }
      return style;
    },
  },
  computed: {
    /**
     * Return the currently selected element
     * @returns {iSignChild | null} The found element or null
     */
    element: function (): iSignChild {
      return JSON.parse(
        JSON.stringify(
          findElementById(
            this.$store.getters.signData.sides[this.$store.getters.currentSide],
            this.$store.getters.selectedElementIds[0]
          )
        )
      );
    },
    /**
     * Determine if section should be displayed open or not
     * @returns {boolean} True if section should be displayed as open
     */
    openSection: function () {
      // if we have an element then the section should be open
      if (this.element) {
        return true;
      }
      return false;
    },
  },
  watch: {
    element: function (newValue: iSignChild) {
      if (this.element) {
        this.fillFlag = this.hasFill(newValue);
      } else {
        this.fillFlag = false;
      }
    },
    fillFlag: function (newValue: boolean) {
      if (this.element) {
        let el = this.$store.getters.canvas.select(
          "[sa-data-id='" + this.element.svgId + "']"
        );
        this.selectedElement = this.element;
        if (newValue) {
          if (this.element.fieldType.toLowerCase() === "color") {
            el.select("rect").attr({
              style: "fill: " + this.selectedElement.fill,
            });
          } else if (this.element.fieldType.toLowerCase() === "text") {
            if (el.type === "text") {
              el.attr({ style: "fill: " + this.selectedElement.fill });
            } else {
              el.select("text").attr({
                style: "fill: " + this.selectedElement.fill,
              });
            }
          } else {
            el.attr({ style: "fill: " + this.selectedElement.fill });
          }
        } else {
          if (this.element.fieldType.toLowerCase() === "color") {
            el.select("rect").attr({
              style: "fill: none",
            });
          } else if (this.element.fieldType.toLowerCase() === "text") {
            el.select("text").attr({
              style: "fill: none",
            });
          } else {
            let style = this.addRuleToStyleString(el, "fill: none");
            el.attr({ style: style });
          }
        }
      }
    },
  },
});
