




























import Vue from "vue";

import { hexString } from "../utilities";

export default Vue.extend({
  components: {},
  props: {
    materialIndex: {
      type: Number,
      required: true,
    },
    libraryIndex: {
      type: Number,
      required: true,
    },
    colorIndex: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {};
  },
  methods: {
    /**
     * get the hex string for a hexCode
     * @param {string} hexCode - the hex code string with or without the leading #
     * @returns {string} - the hex code with the leading # included
     */
    getHexString: function (hexCode: string): string {
      return hexString(hexCode);
    },
  },
  computed: {
    /**
     * the getter/setter for id
     */
    id: {
      get(): string {
        return this.$store.getters.materials[this.materialIndex].libraries[
          this.libraryIndex
        ].colors[this.colorIndex].id;
      },
      set(value: string) {
        const payload = {
          materialIndex: this.materialIndex,
          libraryIndex: this.libraryIndex,
          colorIndex: this.colorIndex,
          value: value,
        };
        this.$store.commit("updateMaterialColorLibraryColorId", payload);
      },
    },
    /**
     * the getter/setter for label
     */
    label: {
      get(): string {
        return this.$store.getters.materials[this.materialIndex].libraries[
          this.libraryIndex
        ].colors[this.colorIndex].name;
      },
      set(value: string) {
        const payload = {
          materialIndex: this.materialIndex,
          libraryIndex: this.libraryIndex,
          colorIndex: this.colorIndex,
          value: value,
        };
        this.$store.commit("updateMaterialColorLibraryColorName", payload);
      },
    },
    /**
     * the getter/setter for description
     */
    description: {
      get(): string {
        return this.$store.getters.materials[this.materialIndex].libraries[
          this.libraryIndex
        ].colors[this.colorIndex].description;
      },
      set(value: string) {
        const payload = {
          materialIndex: this.materialIndex,
          libraryIndex: this.libraryIndex,
          colorIndex: this.colorIndex,
          value: value,
        };
        this.$store.commit(
          "updateMaterialColorLibraryColorDescription",
          payload
        );
      },
    },
    /**
     * the getter/setter for hexCode
     */
    hexCode: {
      get(): string {
        return this.$store.getters.materials[this.materialIndex].libraries[
          this.libraryIndex
        ].colors[this.colorIndex].hexCode;
      },
      set(value: string) {
        const payload = {
          materialIndex: this.materialIndex,
          libraryIndex: this.libraryIndex,
          colorIndex: this.colorIndex,
          value: value,
        };
        this.$store.commit("updateMaterialColorLibraryColorHexcode", payload);
      },
    },
  },
});
