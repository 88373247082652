























import Vue from "vue";
import { IRepeat } from "../../types";

import CollapsibleSection from "../CollapsibleSection.vue";

export default Vue.extend({
  components: { CollapsibleSection },
  props: {
    // components: {
    //   type: Array as () => Array<iSignChild>,
    //   required: true,
    // },
    signData: Object,
  },
  methods: {
    /**
     * select the repeat
     * @param {IRepeat} repeat - the repeat that was clicked on
     */
    selectRepeat(repeat: IRepeat) {
      this.$store.commit("selectRepeatById", repeat.id);
      this.$nextTick(function () {
        //force a rerender of SVGRenderer
        this.$store.commit("setRerender", !this.$store.getters.rerender);
      });
    },
  },
  computed: {
    /**
     * should the section be displayed open?
     * @returns {boolean} True if section should be displayed open
     */
    openSection: function (): boolean {
      if (this.$store.getters.repeats.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {},
});
