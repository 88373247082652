























































































































































































































































































































































































































































import Vue from "vue";

import { IState } from "@/features/SmartStates/types";

import {
  ISigntypeColumn,
  IKanbanSignQL,
  IKanbanSignTypeQL,
  IKanbanSignType,
  IKanbanSign,
  IKanbanProject,
  IKanbanSection,
} from "../types";
import KanbanCard from "../components/ProjectKanbanCard.vue";
import signDetailModal from "@/features/Orders/modals/signDetailModal.vue";
import ProjectSettingsModal from "../modals/ProjectSettingsModal.vue";
import { IOrderDetailSign } from "@/features/Orders/types";
import TimedAlert from "@/features/Builder/components/TimedAlert.vue";

import "vue-select/dist/vue-select.css";
import { SMART_STATE_STATUS } from "@/features/SmartStates/utilities";

export default Vue.extend({
  components: { KanbanCard, signDetailModal, ProjectSettingsModal, TimedAlert },
  data: function () {
    return {
      states: [] as Array<IState>,
      columns: [] as Array<ISigntypeColumn>,
      filteredCount: [] as Array<number>,
      columnIndex: 0,
      signTypes: [] as Array<IKanbanSignType>,
      selectedSignType: "all",
      selectedItems: [] as Array<IOrderDetailSign>,
      selectedSignId: "",
      searchText: "",
      cardColumnWidth: 1000,
      errorMessage: "",
      showProjectSettings: false,
      workflow: "",
      project: {} as IKanbanProject,
      showSettingsMenu: false,
      showReviewStates: false,
      completeMessage: "",
      sections: [
        {
          reviewState: "N",
          name: "To Do",
          isExpanded: false,
          signs: [],
        },
        {
          reviewState: "A",
          name: "Complete",
          isExpanded: false,
          signs: [],
        },
        {
          reviewState: "R",
          name: "Not Complete",
          isExpanded: false,
          signs: [],
        },
      ],
    };
  },
  mounted: function () {
    this.fetchData();
    window.addEventListener("resize", this.setCardColumnWidth);
    document.addEventListener("click", this.clickOnPage);
    this.setCardColumnWidth();
  },
  beforeDestroy() {
    // Clean up: Remove event listener before component is destroyed
    document.removeEventListener("click", this.clickOnPage);
  },
  methods: {
    expandSignType: function (
      columnIndex: number,
      signTypeId: string,
      sectionIndex = -1
    ) {
      this.columns[columnIndex].signTypes.sort((a, b) => {
        return a.globalOrderIdVal.localeCompare(b.globalOrderIdVal);
      });

      let signTypeIndex = -1;
      if (this.stateHasAssignedUsers(this.columns[columnIndex])) {
        signTypeIndex = this.columns[columnIndex].sections[
          sectionIndex
        ].signTypes.findIndex((signType) => {
          return signType.id === signTypeId;
        });
        this.columns[columnIndex].sections[sectionIndex].signTypes[
          signTypeIndex
        ].isExpanded =
          !this.columns[columnIndex].sections[sectionIndex].signTypes[
            signTypeIndex
          ].isExpanded;
      } else {
        signTypeIndex = this.columns[columnIndex].signTypes.findIndex(
          (signType) => {
            return signType.id === signTypeId;
          }
        );

        this.columns[columnIndex].signTypes[signTypeIndex].isExpanded =
          !this.columns[columnIndex].signTypes[signTypeIndex].isExpanded;
      }
    },
    downloadSignsForState: async function (columnIndex: number) {
      this.$store.commit("setShowLoadingSpinner", true);

      const state = this.columns[columnIndex].state;

      try {
        // Make backend request to prepare the file
        const response = await fetch(
          `/state/download_signs_artworks/?state=${state.id}/`
        );
        if (!response.ok) {
          this.errorMessage = "Failed to create file.";
        }

        // Read the response as Blob
        const blob = await response.blob();

        // Hide loading spinner
        this.$store.commit("setShowLoadingSpinner", false);

        // Trigger file download
        this.downloadFile(blob, state.name);
      } catch (error) {
        // Handle error
        this.errorMessage = "Error preparing file";
        this.$store.commit("setShowLoadingSpinner", false);
      }
    },
    downloadFile: function (blob: Blob, stateName: string) {
      const now = new Date();

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      const fileName =
        this.project.name.split(" ").join("_") +
        "_" +
        stateName.split(" ").join("_") +
        "_" +
        now.toISOString().substring(0, 10) +
        "_" +
        now.toLocaleTimeString().split(" ").join("_") +
        ".zip";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },

    /**
     * Function to check if an element is a descendant of another
     * @param {HTMLElement} parent - the parent HTML element
     * @param { Node } child - the child node
     **/
    isDescendant: function (
      parent: HTMLElement | null,
      child: Node | null
    ): boolean {
      let node: Node | null | undefined = child?.parentNode;
      while (node != null) {
        if (node === parent) {
          return true;
        }
        node = node.parentNode;
      }
      return false;
    },
    /**
     * Determine if user has click somewhere on the page other than the settingsMenuButton
     * and if we have then close the settings menu
     * @param event MouseEvent
     */
    clickOnPage: function (event: MouseEvent) {
      const settingsButton = document.getElementById("settingsMenuButton");
      const settingsMenu = document.getElementById("settingsMenuButton");

      if (settingsMenu) {
        if (
          !settingsMenu.contains(event.target as Node) &&
          !this.isDescendant(settingsButton, event.target as Node)
        ) {
          this.showSettingsMenu = false;
        } else {
          this.showSettingsMenu = !this.showSettingsMenu;
        }
      }
    },
    /**
     * show the project settings modal and close the settings menu
     */
    showProjectSettingsModal: function () {
      this.showSettingsMenu = false;
      this.showProjectSettings = true;
    },
    /**
     * Determine if we should show the Move All button
     * @param columnIndex number - the column index
     * @returns boolean - true if we should show the Move All button
     */
    showMoveAll: function (columnIndex: number) {
      let result = false;
      // if we aren't on the last column
      if (columnIndex !== this.columns.length - 1) {
        // and we have some signs showing in the state
        if (this.calculateFilteredCount[columnIndex] !== 0) {
          // then show the move all button
          result = true;
        }
      } else {
        // this is the last column
        // if the smart state is set to Auto and we have signs in the column
        if (
          this.columns[columnIndex].state.smartStateIsAuto &&
          this.calculateFilteredCount[columnIndex] !== 0
        ) {
          // show the Move All button
          result = true;
        }
      }

      return result;
    },
    setPillColor: function (reviewState: string) {
      switch (reviewState) {
        case "N":
          return "bg-default";
        case "A":
          return "bg-approved";
        case "R":
          return "bg-rejected";
      }
    },
    stateHasAssignedUsers: function (column: ISigntypeColumn) {
      let result = false;
      column.groupUsers.forEach((group) => {
        if (group.groupName.toLowerCase() === "assigned") {
          result = true;
        }
      });
      return result;
    },
    setCardColumnWidth: function () {
      this.cardColumnWidth = window.innerWidth - 260;
    },
    dragStart(
      event: DragEvent,
      columnIndex: number,
      signType: IKanbanSignType,
      sectionIndex: number
    ) {
      //hide the settings menu
      this.showSettingsMenu = false;
      //Store the drag information in data transfer
      if (event.dataTransfer) {
        if (!event.dataTransfer.getData("whatAreWeDragging")) {
          event.dataTransfer.setData("whatAreWeDragging", "signType");
          event.dataTransfer.setData("columnIndex", columnIndex.toString());
          event.dataTransfer.setData("sectionIndex", sectionIndex.toString());
          event.dataTransfer.setData("signType", JSON.stringify(signType));
        }
      }
    },
    drop: function (
      event: DragEvent,
      newColumnIndex: number,
      newSectionIndex: number
    ) {
      const dataTransfer = event.dataTransfer;
      let signIndex = -1;
      let signTypeIndex = -1;

      if (dataTransfer) {
        const oldColumnIndex = Number(dataTransfer.getData("columnIndex"));
        const oldSectionIndex = Number(dataTransfer.getData("sectionIndex"));
        if (newColumnIndex !== oldColumnIndex) {
          if (dataTransfer.getData("whatAreWeDragging") === "signType") {
            const signType = JSON.parse(
              dataTransfer.getData("signType")
            ) as IKanbanSignType;
            // check if the sign type already exists in the new column
            let newSignTypeIndex = -1;
            if (this.stateHasAssignedUsers(this.columns[newColumnIndex])) {
              newSignTypeIndex = this.columns[
                newColumnIndex
              ].sections[0].signTypes.findIndex((newSignType) => {
                return newSignType.id === signType.id;
              });
            } else {
              newSignTypeIndex = this.columns[
                newColumnIndex
              ].signTypes.findIndex((newSignType) => {
                return newSignType.id === signType.id;
              });
            }

            // if we have an existing sign type in the new column then we just need to move the signs
            let signsToMove = [] as Array<IKanbanSign>;
            if (newSignTypeIndex !== -1) {
              signType.signs.forEach((sign: IKanbanSign) => {
                if (this.stateHasAssignedUsers(this.columns[newColumnIndex])) {
                  if (this.columns[newColumnIndex].sections.length === 0) {
                    this.columns[newColumnIndex].sections.push({
                      isExpanded: false,
                      name: "Review",
                      reviewState: "N",
                      signTypes: this.columns[this.columnIndex].signTypes,
                    });
                  } else {
                    signIndex = this.columns[
                      newColumnIndex
                    ].sections[0].signTypes[newSignTypeIndex].signs.findIndex(
                      (targetSign) => {
                        return targetSign.id === sign.id;
                      }
                    );
                    // if signIndex is -1 then the sign doesn't already exist in the new column
                    // so push it.
                    if (signIndex === -1) {
                      this.columns[newColumnIndex].sections[0].signTypes[
                        newSignTypeIndex
                      ].signs.push(sign);
                    }
                  }
                } else {
                  this.columns[newColumnIndex].signTypes[
                    newSignTypeIndex
                  ].signs.push(sign);

                  // this.columns[newColumnIndex].sections[0].signTypes =
                  // this.columns[this.columnIndex].signTypes;
                }
                signsToMove.push(sign);
              });
              this.moveListOfSigns(signsToMove, newColumnIndex);
            } else {
              // we don't have a corresponding signtype in the new column so move the entire sign type over
              if (this.stateHasAssignedUsers(this.columns[newColumnIndex])) {
                // if we don't have any sections in the new column and we have assigned users then
                // create one.
                // This should never happen because we are creating the three sections
                // when we load the data
                if (this.columns[newColumnIndex].sections.length === 0) {
                  this.columns[newColumnIndex].sections.push({
                    isExpanded: false,
                    name: this.columns[newColumnIndex].state.needsReviewed,
                    reviewState: "N",
                    signTypes: [signType],
                  });
                } else {
                  // add the signType to the first ("N") reviewState section
                  this.columns[newColumnIndex].sections[0].signTypes.push(
                    signType
                  );
                }
              } else {
                // no assigned users
                // add the signType to the signTypes of the new column
                this.columns[newColumnIndex].signTypes.push(signType);
              }

              this.moveListOfSigns(signType.signs, newColumnIndex);

              // Promise.all(
              //   signType.signs.map((sign: IKanbanSign) =>
              //     this.moveSign(sign, newColumnIndex)
              //   )
              // );
            }

            // remove the original sign type that we just dragged
            // if we have assigned users then remove the sign from the appropriate section
            if (this.stateHasAssignedUsers(this.columns[oldColumnIndex])) {
              // first we need to determine what section we are dragging from
              const oldSectionIndex = Number(
                dataTransfer.getData("sectionIndex")
              );
              const oldSignTypeIndex = this.columns[oldColumnIndex].sections[
                oldSectionIndex
              ].signTypes.findIndex((oldSignType) => {
                return oldSignType.id === signType.id;
              });

              if (oldSignTypeIndex !== -1) {
                this.columns[oldColumnIndex].sections[
                  oldSectionIndex
                ].signTypes.splice(oldSignTypeIndex, 1);
              }
            } else {
              const oldSignTypeIndex = this.columns[
                oldColumnIndex
              ].signTypes.findIndex((oldSignType) => {
                return oldSignType.id === signType.id;
              });
              this.columns[oldColumnIndex].signTypes.splice(
                oldSignTypeIndex,
                1
              );
            }

            // if this the user dropped signtypes into final column (smart state)
            // and the final state is an Auto state
            // then remove them because they have been moved on to the next smart state
            if (
              newColumnIndex === this.columns.length - 1 &&
              this.columns[newColumnIndex].state.smartStateIsAuto
            ) {
              let numberOfSigns = this.calculateSignTypeSignCount(
                this.columns[newColumnIndex].signTypes[
                  this.columns[newColumnIndex].signTypes.length - 1
                ]
              );

              this.completeMessage = `Congratulations! ${numberOfSigns} sign${
                numberOfSigns !== 1 ? "s were" : " was"
              } moved 🔥`;

              this.columns[newColumnIndex].signTypes = [];
            }
          } else {
            // we are dragging a single sign
            const signString = event.dataTransfer?.getData("sign");
            const signTypeExpanded =
              event.dataTransfer?.getData("signtypeexpanded") === "true";
            if (signString) {
              // we have a sign string so parse it into a JSON object
              const sign = JSON.parse(signString);

              // do we have the sign's sign type already in the new column?
              signTypeIndex = -1;
              if (this.stateHasAssignedUsers(this.columns[newColumnIndex])) {
                signTypeIndex = this.columns[newColumnIndex].sections[
                  newSectionIndex
                ].signTypes.findIndex((signType) => {
                  return signType.id === sign.signType.id;
                });
              } else {
                signTypeIndex = this.columns[
                  newColumnIndex
                ].signTypes.findIndex((signType) => {
                  return signType.id === sign.signType.id;
                });
              }

              if (signTypeIndex !== -1) {
                // we found the sign type already in the new column so add the sign to it
                // if we are moving to a state that has assigned Users then add the sign to the "N" state
                if (this.stateHasAssignedUsers(this.columns[newColumnIndex])) {
                  // update the reviewState of the sign
                  sign.reviewState = "N";
                  this.updateSign(sign, newColumnIndex);
                  signIndex = this.columns[
                    newColumnIndex
                  ].sections[0].signTypes[signTypeIndex].signs.findIndex(
                    (targetSign) => {
                      return targetSign.id === sign.id;
                    }
                  );
                  // if signIndex is -1 then the sign doesn't already exist in the new column
                  // so push it.
                  if (signIndex === -1) {
                    this.columns[newColumnIndex].sections[0].signTypes[
                      signTypeIndex
                    ].signs.push(sign);
                  }
                } else {
                  this.columns[newColumnIndex].signTypes[
                    signTypeIndex
                  ].signs.push(sign);
                  // update the back end with the signs new position
                  this.moveListOfSigns([sign], newColumnIndex);
                  // this.moveSign(sign, newColumnIndex);
                }
              } else {
                // the sign type wasn't found in the new column so we need to add it
                const newSignType: IKanbanSignType = {
                  id: sign.signType.id,
                  name: sign.signType.name,
                  shortCode: sign.signType.shortCode,
                  hexColor: sign.signType.hexColor,
                  quantity: sign.signType.quantity,
                  signs: [sign],
                  isExpanded: signTypeExpanded,
                  globalOrderIdVal: sign.signType.globalOrderIdVal,
                };
                if (this.stateHasAssignedUsers(this.columns[newColumnIndex])) {
                  this.columns[newColumnIndex].sections[0].signTypes.push(
                    newSignType
                  );
                } else {
                  this.columns[newColumnIndex].signTypes.push(newSignType);
                  this.moveListOfSigns([sign], newColumnIndex);
                  // this.moveSign(sign, newColumnIndex);
                }
              }

              //now we need to remove the sign from the old column
              if (this.stateHasAssignedUsers(this.columns[oldColumnIndex])) {
                signTypeIndex = this.columns[oldColumnIndex].sections[
                  oldSectionIndex
                ].signTypes.findIndex((signType) => {
                  return signType.id === sign.signType.id;
                });
              } else {
                signTypeIndex = this.columns[
                  oldColumnIndex
                ].signTypes.findIndex((signType) => {
                  return signType.id === sign.signType.id;
                });
              }
              // if this the user dropped signtypes into final column (smart state)
              // and final column is an Auto smart state
              // then remove them because they have been moved on to the next smart state
              if (
                newColumnIndex === this.columns.length - 1 &&
                this.columns[newColumnIndex].state.smartStateIsAuto
              ) {
                this.completeMessage = `Congratuations! 1 sign was moved 🔥`;

                this.columns[newColumnIndex].signTypes = [];
              }

              if (signTypeIndex !== -1) {
                signIndex = -1;
                if (this.stateHasAssignedUsers(this.columns[oldColumnIndex])) {
                  // get thie index of the sign that we are moving
                  signIndex = this.columns[oldColumnIndex].sections[
                    oldSectionIndex
                  ].signTypes[signTypeIndex].signs.findIndex((oldSign) => {
                    return oldSign.id === sign.id;
                  });

                  // if we found the sign
                  if (signIndex !== -1) {
                    // remove it from the array
                    this.columns[oldColumnIndex].sections[
                      oldSectionIndex
                    ].signTypes[signTypeIndex].signs.splice(signIndex, 1);
                  }

                  // if there are no signs left in the sign type, remove the sign type
                  if (
                    this.columns[oldColumnIndex].sections[oldSectionIndex]
                      .signTypes[signTypeIndex].signs.length === 0
                  ) {
                    this.columns[oldColumnIndex].sections[
                      oldSectionIndex
                    ].signTypes.splice(signTypeIndex, 1);
                  }
                } else {
                  // not a review state column
                  signIndex = -1;
                  signIndex = this.columns[oldColumnIndex].signTypes[
                    signTypeIndex
                  ].signs.findIndex((oldSign) => {
                    return oldSign.id === sign.id;
                  });

                  if (signIndex !== -1) {
                    this.columns[oldColumnIndex].signTypes[
                      signTypeIndex
                    ].signs.splice(signIndex, 1);
                  }

                  // if there are no signs left in the sign type, remove the sign type
                  if (
                    this.columns[oldColumnIndex].signTypes[signTypeIndex].signs
                      .length === 0
                  ) {
                    this.columns[oldColumnIndex].signTypes.splice(
                      signTypeIndex,
                      1
                    );
                  }
                }
              }
            }
          }
        } else {
          // we are dragging with a column
          let signsToUpdate = [] as Array<IKanbanSign>;
          const oldSectionIndex = Number(dataTransfer.getData("sectionIndex"));
          if (newSectionIndex !== oldSectionIndex) {
            if (dataTransfer.getData("whatAreWeDragging") === "signType") {
              const signType = JSON.parse(dataTransfer.getData("signType"));
              const oldSectionIndex = Number(
                dataTransfer.getData("sectionIndex")
              );

              // update review state on the all signs in the signType that is being dragged
              this.columns[oldColumnIndex].sections[
                oldSectionIndex
              ].signTypes.forEach((oldSignType) => {
                if (oldSignType.id === signType.id) {
                  oldSignType.signs.forEach((sign) => {
                    sign.reviewState =
                      this.columns[oldColumnIndex].sections[
                        newSectionIndex
                      ].reviewState;
                    signsToUpdate.push(sign);
                  });
                }
              });

              this.moveListOfSigns(signsToUpdate, newColumnIndex);

              // move the signType to the new section
              const oldSignTypeIndex = this.columns[oldColumnIndex].sections[
                oldSectionIndex
              ].signTypes.findIndex((oldSignType) => {
                return oldSignType.id === signType.id;
              });

              const newSignTypeIndex = this.columns[oldColumnIndex].sections[
                newSectionIndex
              ].signTypes.findIndex((oldSignType) => {
                return oldSignType.id === signType.id;
              });

              if (newSignTypeIndex !== -1) {
                // if the signType already exists then just push the signs to the existing signType
                this.columns[oldColumnIndex].sections[
                  oldSectionIndex
                ].signTypes[oldSignTypeIndex].signs.forEach((sign) => {
                  this.columns[oldColumnIndex].sections[
                    newSectionIndex
                  ].signTypes[newSignTypeIndex].signs.push(sign);
                });
              } else {
                // otherwise push a new signType to the new section
                this.columns[oldColumnIndex].sections[
                  newSectionIndex
                ].signTypes.push(
                  this.columns[oldColumnIndex].sections[oldSectionIndex]
                    .signTypes[oldSignTypeIndex]
                );
              }
              // remove the signType from the oldSection
              this.columns[oldColumnIndex].sections[
                oldSectionIndex
              ].signTypes.splice(oldSignTypeIndex, 1);
            } else {
              // we are dragging a single sign
              const signString = event.dataTransfer?.getData("sign");
              if (signString) {
                // we have a sign string so parse it into a JSON object
                const sign = JSON.parse(signString);

                // update the review state on the sign
                sign.reviewState =
                  this.columns[oldColumnIndex].sections[
                    newSectionIndex
                  ].reviewState;

                this.updateSign(sign, newColumnIndex);

                // move the sign to the new section
                // do we have an existing signType?
                signTypeIndex = -1;
                if (this.stateHasAssignedUsers(this.columns[newColumnIndex])) {
                  signTypeIndex = this.columns[newColumnIndex].sections[
                    newSectionIndex
                  ].signTypes.findIndex((signType) => {
                    return signType.id === sign.signType.id;
                  });
                } else {
                  signTypeIndex = this.columns[newColumnIndex].sections[
                    newSectionIndex
                  ].signTypes.findIndex((signType) => {
                    return signType.id === sign.signType.id;
                  });
                }

                if (signTypeIndex !== -1) {
                  // we found the sign type already in the new column so add the sign to it
                  this.columns[newColumnIndex].sections[
                    newSectionIndex
                  ].signTypes[signTypeIndex].signs.push(sign);
                } else {
                  // the sign type wasn't found in the new column so we need to add it
                  const newSignType: IKanbanSignType = {
                    id: sign.signType.id,
                    name: sign.signType.name,
                    shortCode: sign.signType.shortCode,
                    hexColor: sign.signType.hexColor,
                    quantity: sign.signType.quantity,
                    signs: [sign],
                    isExpanded: false,
                    globalOrderIdVal: sign.signType.globalOrderIdVal,
                  };
                  this.columns[newColumnIndex].sections[
                    newSectionIndex
                  ].signTypes.push(newSignType);
                }
                // remove sign from old section
                signIndex = -1;
                signTypeIndex = this.columns[oldColumnIndex].sections[
                  oldSectionIndex
                ].signTypes.findIndex((signType) => {
                  return signType.id === sign.signType.id;
                });

                this.columns[oldColumnIndex].sections[
                  oldSectionIndex
                ].signTypes[signTypeIndex].signs.forEach(
                  (oldSign, oldSignIndex) => {
                    if (oldSign.id === sign.id) {
                      signIndex = oldSignIndex;
                    }
                  }
                );

                if (signIndex !== -1) {
                  this.columns[oldColumnIndex].sections[
                    oldSectionIndex
                  ].signTypes[signTypeIndex].signs.splice(signIndex, 1);
                }

                // if all the signs for this sign type have been moved then remove
                // the sign type from the section
                if (
                  this.columns[oldColumnIndex].sections[oldSectionIndex]
                    .signTypes[signTypeIndex].signs.length === 0
                ) {
                  this.columns[oldColumnIndex].sections[
                    oldSectionIndex
                  ].signTypes.splice(signTypeIndex, 1);
                }
              }
            }
          }
        }
      }
    },
    calculateSignTypeSignCount(signType: IKanbanSignType): number {
      let result = 0;
      signType.signs.forEach((sign) => {
        result += sign.quantity;
      });

      return result;
    },
    showSignDetails: function (itemObject: {
      items: Array<IOrderDetailSign>;
      signId: string;
      showReviewStates: boolean;
    }) {
      this.selectedItems = itemObject.items;
      this.selectedSignId = itemObject.signId;
      this.showReviewStates = itemObject.showReviewStates;

      this.$store.commit("setShowSignDetailsModal", true);
    },
    manageProject: function () {
      this.showSettingsMenu = false;
      window.location.href =
        "/builder/" + this.$route.params.id + "/kanban/columns/";
    },
    moveCompletedToNextState: function (signTypeId = "") {
      const columnIndex = this.columns.length - 1;

      let query = "";
      let numberOfSigns = 0;

      if (signTypeId === "") {
        numberOfSigns = this.calculateColumnFilteredCount(columnIndex);
        query = JSON.stringify({
          query: `mutation{
            moveCompleteStateSigns(input:{id: ${this.columns[columnIndex].state.id}}){
              success
            }
          }`,
        });
      } else {
        this.columns[columnIndex].signTypes.forEach(
          (signType, signTypeIndex) => {
            if (signType.id === signTypeId) {
              numberOfSigns = this.calculateSignTypeSignCount(
                this.columns[columnIndex].signTypes[signTypeIndex]
              );
            }
          }
        );

        query = JSON.stringify({
          query: `mutation{
            moveCompleteStateSigns(input:{id: ${this.columns[columnIndex].state.id}, signType: ${signTypeId}}){
              success
            }
          }`,
        });
      }

      this.$store.commit("setShowLoadingSpinner", true);
      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.errors) {
            alert(result.errors);
            this.$store.commit("setShowLoadingSpinner", false);
          } else {
            // remove the sign types and sections from the complete column
            this.columns[columnIndex].signTypes = [];
            this.columns[columnIndex].sections = [];

            this.completeMessage = ""; // clear the message so we are certain to get the new one
            this.completeMessage = `Congratulations! ${numberOfSigns} sign${
              numberOfSigns !== 1 ? "s were" : " was"
            } moved 🔥`;

            this.$store.commit("setShowLoadingSpinner", false);
          }
        });
    },
    moveAllToNextColumn: function (columnIndex: number) {
      let signsToUpdate = [] as Array<IKanbanSign>;

      for (
        let index = this.columns[columnIndex].signTypes.length - 1;
        index >= 0;
        index--
      ) {
        let signType = this.columns[columnIndex].signTypes[index];
        signType.signs.forEach((sign) => {
          signsToUpdate.push(sign);
        });

        if (
          this.columns[columnIndex + 1].signTypes.filter((st) => {
            return st.id === signType.id;
          }).length > 0
        ) {
          // sign type exists in new column so add the signs from the old column to the sign type
          const newSignTypeIndex = this.getOrCreateSignType(
            signType,
            this.columns[columnIndex + 1].signTypes
          );
          for (let i = signType.signs.length - 1; i > 0; i--) {
            const sign = this.columns[columnIndex].signTypes[index].signs[i];
            this.columns[columnIndex + 1].signTypes[
              newSignTypeIndex
            ].signs.push(sign);
            signType.signs.splice(i, 1);
          }
          if (signType.signs.length === 0) {
            this.columns[columnIndex].signTypes.splice(index, 1);
          }
        } else {
          // sign type doesn't exist in new column so move the entire sign type to the new column
          this.columns[columnIndex + 1].signTypes.push(signType);
          this.columns[columnIndex + 1].sections[0].signTypes.push(signType);
          this.columns[columnIndex].signTypes.splice(index, 1);
        }
        // });
      }
      this.moveListOfSigns(signsToUpdate, columnIndex + 1);

      if (columnIndex + 1 === this.columns.length - 1) {
        this.moveCompletedToNextState();
      }
    },
    moveAllSignsForSignType: function (
      columnIndex: number,
      signTypeId: string,
      sectionIndex = -1
    ) {
      let signsToUpdate = [] as Array<IKanbanSign>;

      if (sectionIndex !== -1) {
        //move the signtype from this section
        this.columns[columnIndex].sections[sectionIndex].signTypes.forEach(
          (signType, index) => {
            if (signType.id === signTypeId) {
              signType.signs.forEach((sign) => {
                signsToUpdate.push(sign);
              });

              if (
                this.columns[columnIndex + 1].signTypes.filter((st) => {
                  return st.id === signType.id;
                }).length > 0
              ) {
                // sign type exists in new column so add the signs from the old column to the sign type
                const newSignTypeIndex = this.getOrCreateSignType(
                  signType,
                  this.columns[columnIndex + 1].signTypes
                );
                for (let i = signType.signs.length - 1; i > 0; i--) {
                  const sign =
                    this.columns[columnIndex].signTypes[index].signs[i];
                  this.columns[columnIndex + 1].signTypes[
                    newSignTypeIndex
                  ].signs.push(sign);
                  signType.signs.splice(i, 1);
                }
                if (signType.signs.length === 0) {
                  this.columns[columnIndex].sections[
                    sectionIndex
                  ].signTypes.splice(index, 1);
                }
              } else {
                // sign type doesn't exist in new column so move the entire sign type to the new column
                this.columns[columnIndex + 1].signTypes.push(signType);
                this.columns[columnIndex + 1].sections[0].signTypes.push(
                  signType
                );
                this.columns[columnIndex].sections[
                  sectionIndex
                ].signTypes.splice(index, 1);
              }

              this.moveListOfSigns(signType.signs, columnIndex + 1);
            }
          }
        );
      } else {
        this.columns[columnIndex].signTypes.forEach((signType, index) => {
          if (signType.id === signTypeId) {
            signType.signs.forEach((sign) => {
              signsToUpdate.push(sign);
            });

            // Promise.all(
            //   signType.signs.map((sign) => this.moveSign(sign, columnIndex + 1))
            // ).then(() => {
            if (
              this.columns[columnIndex + 1].signTypes.filter((st) => {
                return st.id === signType.id;
              }).length > 0
            ) {
              // sign type exists in new column so add the signs from the old column to the sign type
              const newSignTypeIndex = this.getOrCreateSignType(
                signType,
                this.columns[columnIndex + 1].signTypes
              );
              for (let i = signType.signs.length - 1; i > 0; i--) {
                const sign =
                  this.columns[columnIndex].signTypes[index].signs[i];
                this.columns[columnIndex + 1].signTypes[
                  newSignTypeIndex
                ].signs.push(sign);
                signType.signs.splice(i, 1);
              }
              if (signType.signs.length === 0) {
                this.columns[columnIndex].signTypes.splice(index, 1);
              }
            } else {
              // sign type doesn't exist in new column so move the entire sign type to the new column
              this.columns[columnIndex + 1].signTypes.push(signType);
              this.columns[columnIndex + 1].sections[0].signTypes.push(
                signType
              );
              this.columns[columnIndex].signTypes.splice(index, 1);
            }

            this.moveListOfSigns(signType.signs, columnIndex + 1);

            // });
          }
        });
      }

      if (columnIndex + 1 === this.columns.length - 1) {
        this.moveCompletedToNextState(signTypeId);
      }
    },
    calculateSignCount: function (columnIndex: number): number {
      let totalSigns = 0;
      if (this.stateHasAssignedUsers(this.columns[columnIndex])) {
        this.columns[columnIndex].sections.forEach((section) => {
          section.signTypes.forEach((signType) => {
            if (signType.signs) {
              signType.signs.forEach((sign) => {
                totalSigns += sign.quantity;
              });
            }
          });
        });
      } else {
        this.columns[columnIndex].signTypes.forEach((signType) => {
          if (signType.signs) {
            signType.signs.forEach((sign) => {
              totalSigns += sign.quantity;
            });
          }
        });
      }
      return totalSigns;
    },
    calculateSectionSignCount: function (
      columnIndex: number,
      sectionIndex: number
    ): number {
      let totalSigns = 0;
      this.columns[columnIndex].sections[sectionIndex].signTypes.forEach(
        (signType) => {
          if (signType.signs) {
            signType.signs.forEach((sign) => {
              totalSigns += sign.quantity;
            });
          }
        }
      );

      return totalSigns;
    },
    getOrCreateSignType: function (
      signType: IKanbanSignTypeQL,
      signTypes: Array<IKanbanSignType>
    ): number {
      const index = signTypes.findIndex((type) => {
        return type.id === signType.id;
      });

      if (index === -1) {
        // sign type not found so we need to create it
        const newSignType = {
          id: signType.id,
          name: signType.name,
          shortCode: signType.shortCode,
          hexColor: signType.hexColor,
          quantity: 1,
          signs: [],
          isExpanded: false,
          globalOrderIdVal: signType.globalOrderIdVal,
        };
        this.signTypes.push(newSignType);
        signTypes.push(newSignType);
        return signTypes.length - 1;
      } else {
        return index;
      }
    },
    getOrCreateSignTypeForSection: function (
      signType: IKanbanSignTypeQL,
      section: IKanbanSection
    ): number {
      const index = section.signTypes.findIndex((type) => {
        return type.id === signType.id;
      });

      if (index === -1) {
        // sign type not found so we need to create it
        const newSignType = {
          id: signType.id,
          name: signType.name,
          shortCode: signType.shortCode,
          hexColor: signType.hexColor,
          quantity: 1,
          signs: [],
          isExpanded: false,
          globalOrderIdVal: signType.globalOrderIdVal,
        };
        section.signTypes.push(newSignType);
        this.signTypes.push(newSignType);
        return section.signTypes.length - 1;
      } else {
        return index;
      }
    },
    moveListOfSigns: async function (
      signs: Array<IKanbanSign>,
      moveToColumnIndex: number
    ) {
      // build an array of operations we want to perform
      // the key is a unique string = op1, op2, etc.
      // that data is the data we need to pass to the graphQL mutation
      const operations = Array<{
        key: string;
        data: {
          id: string;
          facingDirection: number;
          stateUuid: string;
          reviewState: string;
          smartStateChange: boolean;
        };
      }>();

      let count = 0;
      signs.forEach((sign) => {
        operations.push({
          key: `op${count++}`,
          data: {
            id: sign.id,
            facingDirection: sign.facingDirection,
            stateUuid: this.columns[moveToColumnIndex].state.uuid,
            reviewState: "N",
            smartStateChange: true,
          },
        });
      });

      const mutationData = Object.fromEntries(
        operations.map((e) => [e.key, e.data])
      );

      const mutation = `
      mutation MoveSigns(
      ${operations
        .map(
          (e) => `
          $${e.key}: MutateSignInput!
        `
        )
        .join("")}
    ){
    ${operations
      .map(
        (e) => `
        ${e.key}: mutateSign(input:$${e.key})
          {
            errors {
              messages
            }
          }
      `
      )
      .join("")}
      }`;

      const data = await (
        await fetch("/graphql/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            query: mutation,
            variables: mutationData,
          }),
        })
      )
        .json()
        .catch((err) => {
          console.log(err); // TODO: handle better!!
          this.errorMessage = "Failed to save changes.";
        });

      if (data.errors) {
        // display the first error to the user
        this.errorMessage = data.errors[0].message;
      }
    },
    moveSign: async function (
      sign: IKanbanSign,
      columnIndex: number
    ): Promise<void> {
      const query = JSON.stringify({
        query: `mutation moveSignToState {
              mutateSign(input:{
                id:"${sign.id}",
                facingDirection: ${sign.facingDirection},
                stateUuid: "${this.columns[columnIndex].state.uuid}"
                reviewState: "N"
                smartStateChange: true
              }) {
                errors {
                  messages
                }
                sign {
                  state{
                    name
                  }
                }
              }
            }
            `,
      });

      const response = await fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      });

      const result = await response.json();
      if (result.errors) {
        this.errorMessage = result.errors[0].messages;
      }
      return result;
    },
    updateSign: async function (
      sign: IKanbanSign,
      columnIndex: number
    ): Promise<void> {
      const query = JSON.stringify({
        query: `mutation moveSignToState {
              mutateSign(input:{
                id:"${sign.id}",
                facingDirection: ${sign.facingDirection},
                stateUuid: "${this.columns[columnIndex].state.uuid}"
                reviewState: "${sign.reviewState}"
                smartStateChange: true
              }) {
                errors {
                  messages
                }
                sign {
                  state{
                    name
                  }
                }
              }
            }
            `,
      });

      const response = await fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      });

      const result = await response.json();
      if (result.errors) {
        this.errorMessage = result.errors[0].messages;
      }
      return result;
    },
    fetchData: function () {
      this.columns = [];

      const query = JSON.stringify({
        query: `query get_project {
          project (id: ${this.$route.params.id}) {
            id: contentObjectId
            name
            details
            budgetAmount
            budgetCurrency
            organization:project {
              id: contentObjectId
            }
            workflows {
              edges {
                node {
                  id: contentObjectId
                }
              }
            }
            groupUsers {
              edges {
                node {
                  user {
                    id:contentObjectId
                    firstName
                    lastName
                    email
                    image
                  }
                  group {
                    name
                    contentType
                  }
                }
              }
            }
            states {
              edges {
                node {
                  id: contentObjectId
                  uuid
                  name
                  smartStateStatus
                  smartStateIsAuto
                  nextStateAndProject
                  details
                  startDate
                  endDate
                  approved
                  rejected
                  needsReviewed
                  groupUsers {
                    edges {
                      node {
                        user {
                          id: contentObjectId
                          firstName
                          lastName
                          email
                          image
                        }
                        group {
                          name
                          contentType
                        }
                      }
                    }
                  }
                  signs {
                    pageInfo {
                      hasNextPage
                    }
                    edges {
                      node {
                        id: contentObjectId
                        uuid
                        signId
                        quantity
                        facingDirection
                        reviewState
                        artwork
                        messages
                        repeatingMessages
                        details
                        attachments:comments {
                          edges {
                            node {
                              message
                              attachment
                              attachmentFilename
                              user {
                                firstName
                                lastName
                              }
                              datetime
                            }
                          }
                        }
                        signType {
                          id: contentObjectId
                          name
                          shortCode
                          hexColor
                          globalOrderIdVal
                        }
                        location {
                          id: contentObjectId
                          name
                          shortCode
                        }
                        state {
                          needsReviewed
                          approved
                          rejected
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.errors) {
            alert(result.errors);
          } else {
            this.workflow = result.data.project.workflows.edges[0].node.id;
            this.project.id = result.data.project.id;
            this.project.name = result.data.project.name;
            this.project.details = result.data.project.details;
            this.project.budgetAmount = result.data.project.budgetAmount;
            this.project.budgetCurrency = result.data.project.budgetCurrency;
            this.project.organizationId = result.data.project.organization.id;
            this.project.groupUsers = [];

            result.data.project.groupUsers.edges.forEach(
              (user: {
                node: {
                  user: {
                    id: string;
                    firstName: string;
                    lastName: string;
                    email: string;
                    image: string;
                  };
                  group: {
                    name: string;
                    contentType: string;
                  };
                };
              }) => {
                this.project.groupUsers.push({
                  id: user.node.user.id,
                  firstName: user.node.user.firstName,
                  lastName: user.node.user.lastName,
                  email: user.node.user.email,
                  image: user.node.user.image,
                  contentType: user.node.group.contentType,
                  groupName: user.node.group.name,
                });
              }
            );
            result.data.project.states.edges.forEach(
              (state: {
                node: {
                  id: number;
                  uuid: string;
                  name: string;
                  smartStateStatus: string;
                  smartStateIsAuto: boolean;
                  nextStateAndProject: string;
                  details: string;
                  startDate: string;
                  endDate: string;
                  approved: string;
                  rejected: string;
                  needsReviewed: string;
                  groupUsers: {
                    edges: Array<{
                      node: {
                        user: {
                          id: string;
                          firstName: string;
                          lastName: string;
                          email: string;
                          image: string;
                        };
                        group: {
                          name: string;
                          contentType: string;
                        };
                      };
                    }>;
                  };
                  signs: { edges: Array<{ node: IKanbanSignQL }> };
                };
              }) => {
                const newColumn = { state: {} } as ISigntypeColumn;
                newColumn.state.id = state.node.id;
                newColumn.state.name = state.node.name;
                newColumn.state.details = state.node.details;
                newColumn.state.startDate = state.node.startDate;
                newColumn.state.endDate = state.node.endDate;
                newColumn.state.uuid = state.node.uuid;
                newColumn.state.approved = state.node.approved;
                newColumn.state.rejected = state.node.rejected;
                newColumn.state.needsReviewed = state.node.needsReviewed;
                newColumn.state.smartStateStatus =
                  state.node.smartStateStatus.substring(2);
                newColumn.state.smartStateIsAuto = state.node.smartStateIsAuto;
                if (state.node.nextStateAndProject !== "") {
                  const nextStateAndProject = JSON.parse(
                    state.node.nextStateAndProject
                  );
                  newColumn.state.nextState = nextStateAndProject.nextState;
                  newColumn.state.nextProject = nextStateAndProject.nextProject;
                }
                newColumn.groupUsers = [];
                newColumn.sections = [];
                newColumn.signTypes = [];

                state.node.groupUsers.edges.forEach(
                  (user: {
                    node: {
                      user: {
                        id: string;
                        firstName: string;
                        lastName: string;
                        email: string;
                        image: string;
                      };
                      group: {
                        name: string;
                        contentType: string;
                      };
                    };
                  }) => {
                    newColumn.groupUsers.push({
                      id: user.node.user.id,
                      firstName: user.node.user.firstName,
                      lastName: user.node.user.lastName,
                      email: user.node.user.email,
                      image: user.node.user.image,
                      contentType: user.node.group.contentType,
                      groupName: user.node.group.name,
                    });
                  }
                );

                // create the sections for each of the review states
                ["N", "A", "R"].forEach((reviewState, index) => {
                  if (!newColumn.sections[index]) {
                    newColumn.sections.push({
                      reviewState: reviewState,
                      name:
                        reviewState === "N"
                          ? newColumn.state.needsReviewed
                          : reviewState === "A"
                          ? newColumn.state.approved
                          : newColumn.state.rejected,
                      isExpanded: true,
                      signTypes: [],
                    });
                  }
                });

                state.node.signs.edges.forEach(
                  (sign: { node: IKanbanSignQL }) => {
                    let signTypeIndex = -1;
                    if (this.stateHasAssignedUsers(newColumn)) {
                      newColumn.sections.forEach((section, index) => {
                        if (!newColumn.sections[index]) {
                          newColumn.sections.push({
                            reviewState: section.reviewState,
                            name:
                              section.reviewState === "N"
                                ? newColumn.state.needsReviewed
                                : section.reviewState === "A"
                                ? newColumn.state.approved
                                : newColumn.state.rejected,
                            isExpanded: false,
                            signTypes: [],
                          });
                        }

                        // only add the sign if it matches the reviewState
                        if (sign.node.reviewState === section.reviewState) {
                          signTypeIndex = this.getOrCreateSignTypeForSection(
                            sign.node.signType,
                            newColumn.sections[index]
                          );

                          newColumn.sections[index].signTypes[
                            signTypeIndex
                          ].signs.push({
                            signId: sign.node.signId,
                            id: sign.node.id,
                            facingDirection: sign.node.facingDirection,
                            reviewState: sign.node.reviewState,
                            quantity: sign.node.quantity,
                            artwork: sign.node.artwork,
                            hexColor: sign.node.signType.hexColor,
                            imageURL: "/sign/" + sign.node.id + "/svg_as_png/",
                            messages: JSON.parse(sign.node.messages),
                            repeatingMessages: JSON.parse(
                              sign.node.repeatingMessages
                            ),
                            details: JSON.parse(sign.node.details),
                            attachments: sign.node.attachments.edges.map(
                              (edge) => {
                                return {
                                  attachment: edge.node.attachment,
                                  attachmentFileName:
                                    edge.node.attachmentFilename,
                                  user: edge.node.user,
                                  datetime: edge.node.datetime,
                                };
                              }
                            ),
                            signType: {
                              id: newColumn.sections[index].signTypes[
                                signTypeIndex
                              ].id,
                              shortCode:
                                newColumn.sections[index].signTypes[
                                  signTypeIndex
                                ].shortCode,
                              name: newColumn.sections[index].signTypes[
                                signTypeIndex
                              ].name,
                              hexColor:
                                newColumn.sections[index].signTypes[
                                  signTypeIndex
                                ].hexColor,
                              quantity:
                                newColumn.sections[index].signTypes[
                                  signTypeIndex
                                ].quantity,
                              globalOrderIdVal:
                                newColumn.sections[index].signTypes[
                                  signTypeIndex
                                ].globalOrderIdVal,
                              isExpanded:
                                newColumn.sections[index].signTypes[
                                  signTypeIndex
                                ].isExpanded,
                            },
                            location: {
                              id: sign.node.location.id,
                              shortCode: sign.node.location.shortCode,
                              name: sign.node.location.name,
                            },
                            state: {
                              needsReviewed: sign.node.state.needsReviewed,
                              approved: sign.node.state.approved,
                              rejected: sign.node.state.rejected,
                            },
                          });
                        }
                      });
                    } else {
                      signTypeIndex = this.getOrCreateSignType(
                        sign.node.signType,
                        newColumn.signTypes
                      );
                      newColumn.signTypes[signTypeIndex].signs.push({
                        signId: sign.node.signId,
                        id: sign.node.id,
                        facingDirection: sign.node.facingDirection,
                        reviewState: sign.node.reviewState,
                        quantity: sign.node.quantity,
                        artwork: sign.node.artwork,
                        hexColor: sign.node.signType.hexColor,
                        imageURL: "/sign/" + sign.node.id + "/svg_as_png/",
                        messages: JSON.parse(sign.node.messages),
                        repeatingMessages: JSON.parse(
                          sign.node.repeatingMessages
                        ),
                        details: JSON.parse(sign.node.details),
                        attachments: sign.node.attachments.edges.map((edge) => {
                          return {
                            attachment: edge.node.attachment,
                            attachmentFileName: edge.node.attachmentFilename,
                            user: edge.node.user,
                            datetime: edge.node.datetime,
                          };
                        }),
                        signType: {
                          id: newColumn.signTypes[signTypeIndex].id,
                          shortCode:
                            newColumn.signTypes[signTypeIndex].shortCode,
                          name: newColumn.signTypes[signTypeIndex].name,
                          hexColor: newColumn.signTypes[signTypeIndex].hexColor,
                          quantity: newColumn.signTypes[signTypeIndex].quantity,
                          globalOrderIdVal:
                            newColumn.signTypes[signTypeIndex].globalOrderIdVal,
                          isExpanded:
                            newColumn.signTypes[signTypeIndex].isExpanded,
                        },
                        location: {
                          id: sign.node.location.id,
                          shortCode: sign.node.location.shortCode,
                          name: sign.node.location.name,
                        },
                        state: {
                          needsReviewed: sign.node.state.needsReviewed,
                          approved: sign.node.state.approved,
                          rejected: sign.node.state.rejected,
                        },
                      });
                    }
                  }
                );

                this.columns.push(newColumn);
              }
            );
          }
        });
    },
    filteredSignTypes: function (columnIndex: number): Array<IKanbanSignType> {
      let result = [] as Array<IKanbanSignType>;
      let signTypes = [] as Array<IKanbanSignType>;

      if (this.selectedSignType) {
        if (this.selectedSignType.toLowerCase() === "all") {
          signTypes = this.columns[columnIndex].signTypes;
        } else {
          this.columns[columnIndex].signTypes.forEach((signType) => {
            if (signType.id === this.selectedSignType) {
              signTypes.push(signType);
            }
          });
        }
      } else {
        signTypes = [];
      }

      result = JSON.parse(JSON.stringify(signTypes));

      result.sort((a, b) => {
        return a.globalOrderIdVal.localeCompare(b.globalOrderIdVal);
      });

      if (this.searchText !== "") {
        result = result.filter((signType) => {
          return signType.name
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        });
      }
      return result;
    },
    filteredSectionSignTypes: function (
      columnIndex: number,
      reviewState: string
    ): Array<IKanbanSignType> {
      let result = [] as Array<IKanbanSignType>;
      let signTypes = [] as Array<IKanbanSignType>;

      const sectionIndex = this.columns[columnIndex].sections.findIndex(
        (section) => {
          return section.reviewState === reviewState;
        }
      );

      if (sectionIndex !== -1) {
        if (this.selectedSignType.toLowerCase() === "all") {
          signTypes =
            this.columns[columnIndex].sections[sectionIndex].signTypes;
        } else {
          signTypes = this.columns[columnIndex].sections[
            sectionIndex
          ].signTypes.filter((signType) => {
            if (signType.id === this.selectedSignType) {
              return signType;
            }
          });
        }
      } else {
        signTypes = [];
      }

      result = JSON.parse(JSON.stringify(signTypes));

      result.sort((a, b) => {
        return a.globalOrderIdVal.localeCompare(b.globalOrderIdVal);
      });

      if (this.searchText !== "") {
        result = result.filter((signType) => {
          return signType.name
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        });
      }

      return result;
    },
    calculateSectionFilteredCount: function (
      columnIndex: number,
      sectionIndex: number
    ): number {
      let result = 0;
      const filterSignTypes = this.filteredSectionSignTypes(
        columnIndex,
        this.columns[columnIndex].sections[sectionIndex].reviewState
      );

      filterSignTypes.forEach((signType) => {
        signType.signs.forEach((sign) => {
          result += sign.quantity;
        });
      });
      // this.columns[columnIndex].sections[sectionIndex].signTypes.forEach(
      //   (signType) => {
      //     if (this.selectedSignType.toLowerCase() === "all") {
      //       signType.signs.forEach((sign) => {
      //         result += sign.quantity;
      //       });
      //     } else {
      //       if (signType.id === this.selectedSignType) {
      //         signType.signs.forEach((sign) => {
      //           result += sign.quantity;
      //         });
      //       }
      //     }
      //   }
      // );

      return result;
    },
    calculateColumnFilteredCount: function (columnIndex: number): number {
      let result = 0;
      const filterSignTypes = this.filteredSignTypes(columnIndex);

      filterSignTypes.forEach((signType) => {
        signType.signs.forEach((sign) => {
          result += sign.quantity;
        });
      });

      return result;
    },
  },
  computed: {
    uniqueSignTypes: function (): Array<IKanbanSignType> {
      let uniqueSignTypes = [] as Array<IKanbanSignType>;
      this.signTypes.forEach((signType) => {
        if (
          uniqueSignTypes.filter((uniqueSignType) => {
            return uniqueSignType.id === signType.id;
          }).length === 0
        ) {
          uniqueSignTypes.push(signType);
        }
      });
      return uniqueSignTypes;
    },
    calculateFilteredCount: function (): Array<number> {
      let totalSigns = [] as Array<number>;

      this.columns.forEach((column, columnIndex) => {
        totalSigns[columnIndex] = 0;
        if (this.stateHasAssignedUsers(column)) {
          column.sections.forEach((section, sectionIndex) => {
            totalSigns[columnIndex] += this.calculateSectionFilteredCount(
              columnIndex,
              sectionIndex
            );
          });
        } else {
          totalSigns[columnIndex] +=
            this.calculateColumnFilteredCount(columnIndex);
        }
      });

      return totalSigns;
    },
    SMART_STATE_STATUS: function (): typeof SMART_STATE_STATUS {
      return SMART_STATE_STATUS;
    },
  },
  watch: {},
});
