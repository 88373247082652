












































import Vue from "vue";
import {
  Stripe,
  StripeAddressElement,
  StripeElements,
} from "@stripe/stripe-js";
import { IBuilder } from "@/features/Orders/types";

export default Vue.extend({
  data: function () {
    return {
      stripe: null as Stripe | null,
      addressElement: {} as StripeAddressElement,
      builder: {} as IBuilder,
      saveFailed: false,
      saveSucceeded: false,
      saveErrorMessage: "",
      saveErrorCode: 200,
      userMode: "edit",
    };
  },
  mounted: function () {
    const segments = this.$route.fullPath.split("/");
    const last = segments.pop() || segments.pop(); // Handle potential trailing slash

    if (last === "create") {
      this.userMode = "create";
    } else {
      this.userMode = "edit";
    }

    if (this.userMode === "edit") {
      const query = JSON.stringify({
        query: `
          query getBuilder {
            builder(id:${this.$route.params.id}) {
              name
              company {
                countryCode
                state
                address1
                address2
                city
                postalCode
              }
            }
          }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.errors) {
            this.saveFailed = true;
            this.saveSucceeded = false;
            this.saveErrorMessage = result.errors[0].message;
            this.saveErrorCode = result.errors[0].code;
          } else {
            this.saveFailed = false;
            this.saveSucceeded = true;
            this.saveErrorMessage = "";
            this.saveErrorCode = 200;
            this.builder.name = result.data.builder.name;
            this.builder.street1 = result.data.builder.company.address1;
            this.builder.street2 = result.data.builder.company.address2;
            this.builder.city = result.data.builder.company.city;
            this.builder.state = result.data.builder.company.state;
            this.builder.postalCode = result.data.builder.company.postalCode;
            this.builder.country = result.data.builder.company.country;
            this.builder.phone = result.data.builder.company.phone;

            this.loadAddressElement();
          }
        });
    } else {
      this.builder.name = "";
      this.builder.street1 = "";
      this.builder.street2 = "";
      this.builder.city = "";
      this.builder.state = "";
      this.builder.postalCode = "";
      this.builder.country = "US";
      this.builder.phone = "";

      this.loadAddressElement();
    }
  },
  methods: {
    saveChanges: function () {
      this.addressElement.getValue().then((value) => {
        if (value.complete) {
          this.builder.name = value.value.name;
          this.builder.street1 = value.value.address.line1;
          this.builder.street2 = value.value.address.line2;
          this.builder.city = value.value.address.city;
          this.builder.state = value.value.address.state;
          this.builder.postalCode = value.value.address.postal_code;
          this.builder.country = value.value.address.country;
          this.builder.phone = value.value.phone;

          if (!this.builder.street2) {
            this.builder.street2 = "";
          }

          let query = "";
          if (this.userMode === "edit") {
            query = JSON.stringify({
              query: `
                mutation {
                  mutateBuilder(input: {
                    id: ${this.$route.params.id},
                    name: "${this.builder.name}",
                    street1: "${this.builder.street1}",
                    street2: "${this.builder.street2}",
                    city: "${this.builder.city}",
                    postalCode: "${this.builder.postalCode}",
                    country: "${this.builder.country}",
                    state: "${this.builder.state}",
                    phone: "${this.builder.phone}",
                  }) {
                    builder {
                      name
                      stripeConnectAccountSetupUrl
                    }
                  }
                }
              `,
            });
          } else {
            query = JSON.stringify({
              query: `
                mutation {
                  mutateBuilder(input: {
                    name: "${this.builder.name}",
                    street1: "${this.builder.street1}",
                    street2: "${this.builder.street2}",
                    city: "${this.builder.city}",
                    postalCode: "${this.builder.postalCode}",
                    country: "${this.builder.country}",
                    state: "${this.builder.state}",
                    phone: "${this.builder.phone}",
                  }) {
                    builder {
                      name
                      stripeConnectAccountSetupUrl
                    }
                  }
                }
              `,
            });
          }

          fetch("/graphql/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: query,
          })
            .then((res) => res.json())
            .then((result) => {
              if (result.errors) {
                this.saveFailed = true;
                this.saveSucceeded = false;
                this.saveErrorMessage = result.errors[0].message;
                this.saveErrorCode = result.errors[0].code;
              } else {
                this.saveFailed = false;
                this.saveSucceeded = true;
                this.saveErrorMessage = "";
                this.saveErrorCode = 200;
                if (this.userMode === "create") {
                  window.location.href =
                    result.data.mutateBuilder.builder.stripeConnectAccountSetupUrl;
                }
              }
            });
        }
      });
    },
    loadAddressElement: async function () {
      if (!this.stripe) {
        if (window.Stripe) {
          if (process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY) {
            this.stripe = window.Stripe(
              process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
            );
          }
        }
      }

      const options = {};
      let elements = {} as StripeElements;

      if (this.stripe) {
        elements = this.stripe.elements(options);
      }

      if (elements) {
        // Create and mount the Address Element in shipping mode
        this.addressElement = elements.create("address", {
          mode: "billing",
          display: {
            name: "organization",
          },
          fields: {
            phone: "always",
          },
          defaultValues: {
            name: this.builder.name,
            address: {
              line1: this.builder.street1,
              line2: this.builder.street2,
              city: this.builder.city,
              state: this.builder.state,
              postal_code: this.builder.postalCode,
              country: this.builder.country,
            },
          },
        });
        this.addressElement.mount("#address-element");
      }
    },
  },
});
