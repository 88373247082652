



























































































import Vue from "vue";
import { PropType } from "vue";
import $ from "jquery";

import { asDate } from "@/filters/common";
import { IPlanAndTiers, ISubscriptionItem, ISubscription } from "../types";

export default Vue.extend({
  props: {
    subscriptionItem: {
      type: Object as PropType<ISubscriptionItem>,
      required: true,
    },
    subscription: {
      type: Object as PropType<ISubscription>,
    },
    showModal: {
      type: Boolean,
    },
  },
  filters: { asDate },
  data: function () {
    return {
      newTier: {} as {
        cycle: string;
        upTo: number;
        amount: number;
        currency: string;
      },
      newTotal: 0,
      saveInProgress: false,
      plan: {
        name: "",
        stripePriceId: "",
        tiers: [],
      } as IPlanAndTiers,
    };
  },
  methods: {
    addSeats: function () {
      // this.saveInProgress = true;
      // this.$forceUpdate();
      this.$store.commit("setShowLoadingSpinner", true);
      const companyId = this.$route.params.id;
      const subscriptionId = this.subscription.id;
      const vars = {
        company: companyId,
        cancel: false,
        id: subscriptionId,
        items: JSON.stringify([
          {
            id: this.subscriptionItem.id,
            stripeId: this.subscriptionItem.stripeSubscriptionId,
            price: this.subscriptionItem.stripePriceId,
            quantity: this.newTier.upTo,
          },
        ]),
      };

      // const newTier = this.$store.getters.priceTierById(this.newTier);
      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          variables: {
            mutateSubscriptionInput: vars,
          },
          query: `
            mutation updateSubscription(
              $mutateSubscriptionInput: MutateSubscriptionInput!
            ) {
              mutateSubscription(input: $mutateSubscriptionInput) {
                errors { messages }
                subscription {
                  id
                  stripeSubscriptionId
                }
              }
            }`,
        }),
      })
        .then((data) => data.json())
        .then((result) => {
          if (result.errors) {
            alert(result.errors[0].message);
          } else if (result.data.mutateSubscription?.errors.length > 0) {
            alert(result.data.mutateSubscription.errors[0].messages.join(", "));
          }
          $("#addSeatsModal").modal("hide");
          this.$store.commit("setShowLoadingSpinner", false);
          location.reload();
        });
    },
    fetchPlanDetails: function () {
      if (this.subscriptionItem.plan) {
        const query = JSON.stringify({
          query: `
          query getPricePlan {
            pricingPlan (id: ${this.subscriptionItem.plan.id}) {
              id: contentObjectId
              name
              prices {
                edges {
                  node {
                    billingCycle
                    stripePriceId
                    tiers {
                      edges {
                        node {
                          upTo
                          amount
                          currency
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        });

        fetch("/graphql/", {
          method: "POST",
          body: query,
          headers: {
            "content-type": "application/json",
            Accept: "application/json",
          },
        })
          .then((data) => data.json())
          .then((result) => {
            if (result.errors) {
              alert(result.errors[0].message);
            } else {
              this.plan.name = result.data.pricingPlan.name;
              this.plan.tiers = [];

              result.data.pricingPlan.prices.edges.forEach(
                (price: {
                  node: {
                    billingCycle: string;
                    stripePriceId: string;
                    tiers: {
                      edges: Array<{
                        node: {
                          upTo: number;
                          amount: number;
                          currency: string;
                        };
                      }>;
                    };
                  };
                }) => {
                  // Stripe stores the price tier amounts differently then we display
                  // them on the front end.  Stripe stores the price as the difference - so
                  // to get the total for the second tier you add the first tier and the
                  // second tier amounts together.  tierTotal is the variable we store the
                  // total in
                  let tierTotal = 0;
                  price.node.tiers.edges.forEach((tier) => {
                    // update the tierTotal
                    tierTotal += tier.node.amount;
                    this.plan.tiers.push({
                      cycle: price.node.billingCycle,
                      upTo: tier.node.upTo,
                      amount: tierTotal,
                      currency: tier.node.currency,
                      stripeId: price.node.stripePriceId,
                    });
                  });
                }
              );
              this.newTier = this.validPriceTiers[0];
            }
          });
      }
    },
    getTier: function (cycle: string, upTo: number) {
      this.plan.tiers.find((tier) => {
        if (tier.cycle === cycle) {
          if (tier.upTo === upTo) {
            return true;
          }
        }
      });
    },
  },
  computed: {
    validPriceTiers: function (): Array<{
      cycle: string;
      upTo: number;
      amount: number;
      currency: string;
    }> {
      let priceTiers = [] as Array<{
        cycle: string;
        upTo: number;
        amount: number;
        currency: string;
      }>;
      if (this.plan) {
        priceTiers = this.plan.tiers.filter((priceTier) => {
          let result = false;
          if (priceTier.cycle === this.subscriptionItem.billingCycle) {
            if (priceTier.upTo > this.subscriptionItem.upTo) {
              result = true;
            } else if (isNaN(priceTier.upTo)) {
              result = true;
            }
          }
          return result;
        });
      }
      return priceTiers;
    },
  },
  watch: {
    subscription: function () {
      this.fetchPlanDetails();
    },
    newTier: function (newValue) {
      // if newValue is undefined then we are on the high tier
      // so we don't need to set newTotal
      if (newValue) {
        this.newTotal = newValue.amount;
      }
    },
    showModal: function (newValue: boolean) {
      if (newValue) {
        this.fetchPlanDetails();
        $("#addSeatsModal").modal("show");
      } else {
        $("#addSeatsModal").modal("hide");
      }
    },
  },
});
