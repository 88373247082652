








import { Component, Vue } from "vue-property-decorator";

/**
 * Page to display when a route doesn't exist
 */
@Component
export default class PathNotFound extends Vue {}
