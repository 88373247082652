


























































































































import Vue from "vue";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import { iMatthews, iColor, iRGB } from "../../types";
import { rgbToHex } from "../../utilities";

//import { Compact } from "vue-color"
// import hexcodes from "./assets/hexcodes.json";
// import matthews from "./assets/matthews.json";

const columns = [
  {
    title: "Ref",
    dataIndex: "rgb",
    width: "40px",
    customCell: function (record: iColor) {
      return {
        style: {
          "background-color":
            "rgb(" +
            record.rgb.r +
            ", " +
            record.rgb.g +
            ", " +
            record.rgb.b +
            ")",
          width: "55px",
        },
      };
    },
  },
  {
    title: "Name",
    dataIndex: "label",
    key: "label",
  },
  {
    title: "Color To Match",
    dataIndex: "match",
    key: "match",
  },
  {
    title: "Action",
    key: "id",
    dataIndex: "id",
    width: "100px",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

export default Vue.extend({
  components: {
    "vue-select": VueSelect,
  },
  data: function () {
    return {
      columns: columns,
      selectedColorIndex: -1,
      showColorsModal: false,
      showColorPicker: false,
      colorPickerTop: 100,
      color: "#0176bc",
      colorLibrary: "Custom",
      selectedMPColor: {} as iMatthews,
      colorLibraries: [
        { id: 0, name: "Custom" },
        { id: 1, name: "Matthews Paint" },
        { id: 2, name: "Pantone PMS" },
        { id: 3, name: "Rowmark" },
      ],
      colorLibraryItems: {
        id: 1,
        library_id: 1,
      },
      colors: [
        {
          id: "20020",
          label: "Blue",
          library: "Custom",
          match: "Custom",
          rgb: {
            r: 1,
            g: 118,
            b: 188,
          },
        },
        {
          id: "20021",
          label: "Burgundy",
          library: "Matthews Paint",
          match: "MP5681",
          rgb: {
            r: 110,
            g: 18,
            b: 18,
          },
        },
      ],
    };
  },
  mounted: function () {
    this.showColorsModal = this.$store.getters.showColorsModal;
  },
  methods: {
    /**
     * return a hexcode string for a set of r, g, b  color values
     * @param {number} r - the red color value
     * @param {number} g - the green color value
     * @param {number} b - the blue color value
     * @returns {string} A hexcode string with the leading hash (i.e. "#0176bc")
     */
    getRGBToHex: function (r: number, g: number, b: number): string {
      return rgbToHex(r, g, b);
    },
    /**
     * match an iRGB value to the nearest Matthews Paint color
     * @param {Array<iMatthews>} arr - an array of Matthews Paint colors
     * @param {iRGB} rgb - an RGB color value
     */
    matchColor(arr: iMatthews[], rgb: iRGB): void {
      let bestMatch = {} as iMatthews;
      let lowestValue = 9999999;
      arr.forEach(function (item: iMatthews) {
        let newValue = Math.sqrt(
          Math.pow(rgb.r - item.r, 2) +
            Math.pow(rgb.g - item.g, 2) +
            Math.pow(rgb.b - item.b, 2)
        );
        if (newValue < lowestValue) {
          bestMatch = item;
          lowestValue = newValue;
        }
      });

      this.selectedMPColor = bestMatch;
    },
    /**
     * handle Ok click
     */
    handleOk: function () {
      //return to the list screen
      if (this.selectedColorIndex >= 0) {
        this.$store.getters.colors[this.selectedColorIndex].match =
          "Matthews " + this.selectedMPColor.id;
        this.selectedColorIndex = -1;
        this.showColorPicker = false;
      } else {
        //close the window
        this.$store.commit("setShowColorsModal", false);
      }
    },
    /**
     * close the modal
     * @param {Event} e - the event that triggered the close
     */
    closeModal: function (e: Event) {
      e.preventDefault();
      e.stopPropagation();
      if (this.selectedColorIndex >= 0) {
        //move back to list screen, don't close modal
        this.selectedColorIndex = -1;
        this.showColorsModal = true;
        this.$store.commit("setShowColorsModal", true);
      } else {
        //otherwise we are on the list screen, close modal
        this.$store.commit("setShowColorsModal", false);
      }
    },
    /**
     * handle click of the Edit color icon
     * @param {string} e - the color's index in the colors array
     */
    editColor: function (e: string) {
      this.selectedColorIndex = this.getColorIndexFromId(e);
      //this.selectedColorIndex = e
    },
    /**
     * get the color array index from the color's id
     * @param {string} id - the color's id
     */
    getColorIndexFromId: function (id: string) {
      for (let i = 0; i < this.$store.getters.colors.length; i++) {
        if (this.$store.getters.colors[i].id === id) {
          return i;
        }
      }
      return -1;
    },
  },
  watch: {
    /**
     * when the showColorsModal from the store changes show the modal
     * @param {boolean} newValue - the new value of showColorsModal from the store
     */
    "$store.state.signDesigner.showColorsModal": function (newValue: boolean) {
      this.showColorsModal = newValue;
    },
  },
});
