











































import Vue from "vue";
import InvoicesTile from "../components/InvoicesTile.vue";
import BillingOwnerTile from "../components/BillingOwnerTile.vue";
import PlanDetailsTile from "../components/PlanDetailsTilev2.vue";
import PaymentInfoTile from "../components/PaymentInfoTile.vue";
import SignupTile from "../components/SignUpTile.vue";
import { IPaymentMethod } from "../types";

export default Vue.extend({
  components: {
    PlanDetailsTile,
    InvoicesTile,
    BillingOwnerTile,
    PaymentInfoTile,
    SignupTile,
  },
  data: function () {
    return {
      isOnlineBillingCompany: true as boolean | null,
      paymentMethods: [] as Array<IPaymentMethod>,
    };
  },
  mounted: function () {
    this.fetchData();
  },
  methods: {
    fetchData: function () {
      const query = JSON.stringify({
        query: `query getBillingInfo {
          company(id: ${this.$route.params.id}) {
            billing {
              isBilledOffline
              paymentMethods {
                brand
                last4
                default
                expYear
                expMonth
                stripeId
              }
            }
          }
        }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((result) => result.json())
        .then((data) => {
          if (data.data.company.billing) {
            this.isOnlineBillingCompany =
              !data.data.company.billing.isBilledOffline;
            this.paymentMethods = data.data.company.billing.paymentMethods;
          } else {
            this.isOnlineBillingCompany = false;
            this.paymentMethods = [];
          }
        });
    },
  },
  computed: {},
  watch: {},
});
