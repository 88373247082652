








































































































































































import Vue from "vue";
import $ from "jquery";
import { IMember } from "../types";

export default Vue.extend({
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    memberId: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      member: {} as IMember,
      oldMemberData: {} as IMember,
      previewImage: null as string | ArrayBuffer | null,
      files: null as FileList | null,
    };
  },
  methods: {
    fetchData: function () {
      // load member
      const query = JSON.stringify({
        query: `query get_member {
          user (id: ${this.memberId}){
            id: userId
            profileId: contentObjectId
            firstName
            lastName
            email
            phoneNumber
            image
            role: companyRole
            twoFAMethod: preferred2faMethod
            emailFrequency: reviewerEmailFrequency
            agreesToTerms
          }
        }
      `,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((result) => result.json())
        .then((data) => {
          this.member = data.data.user;
        });
    },
    cancelMemberChanges: function () {
      this.member.firstName = this.oldMemberData.firstName;
      this.member.lastName = this.oldMemberData.lastName;
      this.member.email = this.oldMemberData.email;
      this.member.phone = this.oldMemberData.phone;
      this.member.image = this.oldMemberData.image;
      this.member.role = this.oldMemberData.role;
      this.member.twoFAMethod = this.oldMemberData.twoFAMethod;
      this.member.emailFrequency = this.oldMemberData.emailFrequency;
      this.$emit("closed");
    },
    updateMember: function () {
      const roleId = this.getRoleId(this.member.role.toUpperCase());

      const query = JSON.stringify({
        query: `
          mutation update_member {
            mutateProfile (input : {
              id: ${this.member.profileId},
              firstName: "${this.member.firstName}",
              lastName: "${this.member.lastName}",
              phoneNumber: "${this.member.phone}",
              companyRole: "${roleId}",
              preferred2faMethod: "${this.member.twoFAMethod}",
              agreesToTerms: ${this.member.agreesToTerms},
            })
            {
              errors { messages }
              profile {
                firstName
                lastName
              }
            }
          }`,
      });

      fetch("/graphql/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: query,
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.errors) {
            alert(data.errors[0].message);
            this.cancelMemberChanges();
            this.closeMemberSettings();
          } else if (data.data.mutateProfile?.errors.length > 0) {
            alert(data.data.mutateProfile.errors[0].messages.join(", "));
            this.cancelMemberChanges();
            this.closeMemberSettings();
          } else {
            this.closeMemberSettings();
            // window.location.href = "/company/" + this.companyId + "/members/";
          }
        });
    },
    closeMemberSettings: function () {
      this.$emit("closed");
    },
    getRoleId: function (roleName: string): string {
      switch (roleName) {
        case "A_1":
          return "1";
        case "A_3":
          return "3";
        case "A_4":
          return "4";
        default:
          return "2";
      }
    },
    /**
     * triggered when the file input changes
     * @param {Event} e - the event that triggered the change event
     */
    fileChanged: function (e: Event) {
      const target = e.target as HTMLInputElement;
      this.files = target.files as FileList;

      const image = this.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        if (e.target) {
          this.previewImage = e.target.result;
        }
      };
    },
  },
  computed: {
    firstName: {
      get(): string {
        return this.member.firstName;
      },
      set(value: string) {
        this.$store.commit("setMemberFirstName", {
          memberId: this.memberId,
          value: value,
        });
        // this.member.firstName = value;
      },
    },
    lastName: {
      get(): string {
        return this.member.lastName;
      },
      set(value: string) {
        this.member.lastName = value;
      },
    },
    email: {
      get(): string {
        return this.member.email;
      },
      set(value: string) {
        this.member.email = value;
      },
    },
    phone: {
      get(): string {
        return this.member.phone;
      },
      set(value: string) {
        this.member.phone = value;
      },
    },
    role: {
      get(): string {
        return this.getRoleId(this.member.role);
      },
      set(value: string) {
        this.member.role = value;
      },
    },
    twoFAMethod: {
      get(): string {
        return this.member.twoFAMethod;
      },
      set(value: string) {
        this.member.twoFAMethod = value;
      },
    },
    emailFrequency: {
      get(): string {
        return this.member.emailFrequency;
      },
      set(value: string) {
        this.member.emailFrequency = value;
      },
    },
  },
  watch: {
    showModal: function (newValue: boolean) {
      if (newValue) {
        this.fetchData();
        $("#editMemberModal").modal("show");
      } else {
        $("#editMemberModal").modal("hide");
      }
    },
  },
});
