





















import Vue from "vue";
import { IProductCategory } from "../../types";

export default Vue.extend({
  props: {
    signData: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      productCategories: [],
    };
  },
  methods: {},
  computed: {
    filteredProductCategories: function (): Array<IProductCategory> {
      let result = [];
      if (this.$store.getters.isProductFamilySelected) {
        for (let i = 0; i < this.$store.getters.productCategories.length; i++) {
          let category = this.$store.getters.productCategories[i];
          for (let j = 0; j < this.$store.getters.productFamilies.length; j++) {
            const family = this.$store.getters.productFamilies[j];
            if (family.isSelected) {
              if (category.familyIds.includes(family.uuid)) {
                result.push(category);
              }
            }
          }
        }
      } else {
        result = this.$store.getters.productCategories;
      }
      return result;
    },
  },
});
