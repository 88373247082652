
































































































import Vue from "vue";
import { SAFonts } from "../../include/SAFonts";

import { iFont, iSignData } from "../../types";
// import matthews from "./assets/matthews.json";

export default Vue.extend({
  components: {},
  data: function () {
    return {
      showModal: false,
      fieldName: "",
      fieldType: "text",
      signData: {} as iSignData,
      fontName: "",
      fontFile: "",
      agreeToRights: false,
      files: {} as FileList,
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showAddFontModal;
  },
  methods: {
    /**
     * triggered when the file input changes
     * @param {Event} e - the event that triggered the change event
     */
    fileChanged: function (e: Event) {
      const target = e.target as HTMLInputElement;
      this.files = target.files as FileList;
    },
    /**
     * save changes
     */
    saveChanges: function () {
      const users: Array<string> = [];
      users.push(this.$store.getters.user.id);
      let operations = {
        query: `mutation addFont($fontFile:Upload!,$userList:[String!]!,$fontName:String!,$organizationId:ID!,$agreeRights:Boolean!) {
              addFont(input:{fontFile:$fontFile,fontName:$fontName,organizationId:$organizationId,agreeRights:$agreeRights,userList:$userList}) {
                font {
                  id,
                  uuid,
                  name,
                  url,
                }
              }
            }`,
        variables: {
          fontFile: null,
          fontName: this.fontName,
          organizationId: this.$store.getters.organizationId,
          agreeRights: this.agreeToRights,
          userList: users,
        },
      };

      let map = {
        "0": ["variables.fontFile"],
      };

      let fd = new FormData();

      fd.append("operations", JSON.stringify(operations));
      fd.append("map", JSON.stringify(map));
      fd.append("0", this.files[0]);

      fetch("/graphql/", {
        method: "POST",
        body: fd,
      })
        .then((res) => res.json())
        .then((result) => {
          //reload the fonts so the newly added font shows up in the font section
          let fonts = JSON.parse(JSON.stringify(this.$store.getters.fonts));
          let font: iFont = {
            id: result.data.addFont.font.id,
            uuid: result.data.addFont.font.uuid,
            name: result.data.addFont.font.name,
            url: result.data.addFont.font.url,
          };
          fonts[font.name] = font;
          this.$store.commit("setFonts", fonts);
          const SAFont = SAFonts.getInstance();
          SAFont.removeFontNotLoaded(font.name);
          //eslint-disable-next-line
          (this.$root.$refs.SVGRenderer as any).processSVG(false, false);
        });

      this.clearModal();
      //close the window
      this.$store.commit("setShowAddFontModal", false);
    },
    /**
     * close the modal
     * @param {Event} e - the event that triggered the close
     */
    closeModal: function (e: Event) {
      this.clearModal();
      e.preventDefault();
      e.stopPropagation();
      this.$store.commit("setShowAddFontModal", false);
    },
    clearModal: function () {
      this.fontName = "";
      this.fontFile = "";
      this.agreeToRights = false;
      this.fieldName = "";
      let fileElement = document.getElementById(
        "id_original_file"
      ) as HTMLInputElement;
      if (fileElement) {
        fileElement.value = "";
      }
    },
  },
  computed: {},
  watch: {
    /**
     * when showAddFontModal from the store changes
     * @param {boolean} newValue - the value of showAddFontModal from the store
     */
    "$store.state.signDesigner.showAddFontModal": function (newValue: boolean) {
      //   const this = this;
      this.showModal = newValue;
    },
  },
});
