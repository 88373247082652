

























































































































import Vue from "vue";

import { iSignData, IField, IAlignment, IAlignmentTarget } from "../../types";
import {
  uuidv4,
  updateSVGDataName,
  buildAlignmentString,
} from "../../utilities";
import { normalCaseToUnderscore } from "../../include/Utils";

import MeasurementInput from "../MeasurementInput.vue";

// import matthews from "./assets/matthews.json";

export default Vue.extend({
  components: { MeasurementInput },
  data: function () {
    return {
      showModal: false,
      signTypeName: "",
      signData: {} as iSignData,
      alignment: {
        uuid: "",
        isSelected: false,
        isInRepeat: false,
        sortIndex: 0,
        sourceField: "",
        targets: [
          {
            uuid: "",
            isSelected: false,
            row: "current",
            field: "",
            sourceAlignment: "",
            targetAlignment: "",
            offset: "",
          },
        ],
        elementIds: [],
      } as IAlignment,
      originalAlignment: {} as IAlignment,
      mode: "add", //either edit or add depending on what the user is doing
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showAddSignTypeModal;
  },
  methods: {
    /**
     * save changes to the alignment
     */
    saveChanges: function () {
      if (this.mode === "add") {
        this.$store.commit("addAlignment", this.alignment);
        // update the SVG
        if (this.alignment.targets[0].row === "current") {
          updateSVGDataName(
            this.alignment.elementIds[0],
            this.$store.getters.selectedField
              ? this.$store.getters.selectedField.type
              : "",
            "",
            buildAlignmentString(
              this.alignment.targets[0].sourceAlignment,
              this.alignment.targets[0].targetAlignment,
              normalCaseToUnderscore(this.alignment.targets[0].field),
              this.alignment.targets[0].offset
            ),
            this.$store.getters.canvas
          );
        } else {
          // this is a repeat alignment
          this.updateRepeatAlignment(this.alignment.elementIds[0]);
        }
      } else {
        // edit mode
        this.$store.commit("updateAlignmentTarget", this.alignment);

        // update the SVG
        if (this.alignment.targets[0].row === "current") {
          updateSVGDataName(
            this.alignment.elementIds[0],
            this.$store.getters.selectedField
              ? this.$store.getters.selectedField.type
              : "",
            buildAlignmentString(
              this.originalAlignment.targets[0].sourceAlignment,
              this.originalAlignment.targets[0].targetAlignment,
              normalCaseToUnderscore(this.originalAlignment.targets[0].field),
              this.originalAlignment.targets[0].offset
            ),
            buildAlignmentString(
              this.alignment.targets[0].sourceAlignment,
              this.alignment.targets[0].targetAlignment,
              normalCaseToUnderscore(this.alignment.targets[0].field),
              this.alignment.targets[0].offset
            ),
            this.$store.getters.canvas
          );
        } else {
          // this is a repeat alignment
          this.updateRepeatAlignment(this.alignment.elementIds[0]);
        }
      }

      this.$store.commit("refreshCodeEditor");

      //close the window
      this.$store.commit("setShowAddAlignmentModal", false);
    },
    /**
     * update the repeat alignment on the SVG
     * @param {string} elementId - the element id we are updating
     */
    updateRepeatAlignment: function (elementId: string) {
      // make sure Vue has updated the store before continuing
      Vue.nextTick(() => {
        // get all repeats that target previous rows
        let previousRowAlignments: Array<IAlignmentTarget> =
          this.$store.getters.selectedAlignment.targets.filter(
            (target: IAlignmentTarget) => {
              return target.row === "previous";
            }
          );
        // order all the source alignments together
        // order all the destination alignments together
        previousRowAlignments.sort(
          (a, b) =>
            a.sourceAlignment.localeCompare(b.sourceAlignment) ||
            a.targetAlignment.localeCompare(b.targetAlignment)
        );
        // process them all
        let alignString = "";
        let alignmentStrings = [] as Array<string>;
        let oldSourceAlign = previousRowAlignments[0].sourceAlignment;
        let oldTargetAlign = previousRowAlignments[0].targetAlignment;
        for (let i = 0; i < previousRowAlignments.length; i++) {
          let target = previousRowAlignments[i];
          if (oldSourceAlign !== target.sourceAlignment) {
            alignString +=
              "align_" +
              oldSourceAlign +
              "_to_" +
              oldTargetAlign +
              " " +
              normalCaseToUnderscore(target.field);
            alignmentStrings.push(alignString);
            oldSourceAlign = target.sourceAlignment;
            alignString =
              normalCaseToUnderscore(
                this.$store.getters.selectedAlignment.sourceField
              ) +
              " " +
              target.offset +
              " ";
          } else if (oldTargetAlign !== target.targetAlignment) {
            alignString +=
              "align_" +
              oldSourceAlign +
              "_to_" +
              oldTargetAlign +
              " " +
              normalCaseToUnderscore(target.field);
            alignmentStrings.push(alignString);
            oldTargetAlign = target.targetAlignment;
            alignString =
              normalCaseToUnderscore(
                this.$store.getters.selectedAlignment.sourceField
              ) +
              " " +
              target.offset +
              " ";
          } else {
            alignString +=
              normalCaseToUnderscore(
                this.$store.getters.selectedAlignment.sourceField
              ) +
              " " +
              target.offset +
              " ";
          }
        } //);
        alignString +=
          "align_" +
          oldSourceAlign +
          "_to_" +
          oldTargetAlign +
          " " +
          normalCaseToUnderscore(
            previousRowAlignments[previousRowAlignments.length - 1].field
          );
        alignmentStrings.push(alignString);
        //update the repeat element
        let repeatId = this.getAncestorRepeat(elementId);
        let el = this.$store.getters.canvas.select(
          "[sa-data-id='" + repeatId + "']"
        );

        el.attr({ "data-name": "repeat " + alignmentStrings.join(",") });
        el.attr({
          id: normalCaseToUnderscore("repeat " + alignmentStrings.join(",")),
        });
      });
    },

    /**
     * Get the first ancestor element that is a repeat
     * @param {string} svgId - the svgId of the svg element to start at
     * @returns {string} the svgId of the first repeat ancestor
     */
    getAncestorRepeat: function (svgId: string): string {
      let el = this.$store.getters.canvas.select(
        "[sa-data-id='" + svgId + "']"
      );
      if (el) {
        let parent = el.parent();
        if (parent) {
          if (parent.attr("id").substring(0, 6) === "repeat") {
            return parent.attr("sa-data-id");
          } else if (parent.parent()) {
            return this.getAncestorRepeat(parent.attr("sa-data-id"));
          }
        }
      }
      return "";
    },

    /**
     * Close the modal
     * @param {Event} e - the event that triggered the close
     */
    closeModal: function (e: Event) {
      e.preventDefault();
      e.stopPropagation();
      //            this.showColorPicker = false

      //if we are on the details screen
      //otherwise we are on the list screen, close modal
      this.$store.commit("setShowAddAlignmentModal", false);
    },
  },
  computed: {
    /**
     * Getter/setter for offset
     */
    offset: {
      get(): number {
        return parseFloat(this.alignment.targets[0].offset);
      },
      set(value: string) {
        this.alignment.targets[0].offset =
          value + " " + this.$store.getters.signData.measurementUnit.unit;
      },
    },
    /**
     * Getter/setter for targetFields
     */
    targetFields: function () {
      let fields = [] as Array<IField>;
      let sourceField = this.alignment.sourceField;

      if (!this.alignment.isInRepeat) {
        this.$store.getters.usedFields.forEach((field: IField) => {
          if (sourceField !== field.name) {
            fields.push(field);
          }
        });
      } else {
        // this is a repeat so set the target fields based on target row selection
        if (this.alignment.targets[0].row === "current") {
          this.$store.getters.usedFields.forEach((field: IField) => {
            if (sourceField !== field.name) {
              fields.push(field);
            }
          });
        } else {
          // push all fields for previous row
          this.$store.getters.usedFields.forEach((field: IField) => {
            fields.push(field);
          });
        }
      }
      return fields;
    },
    /**
     * Getter/setter for targetOptions
     */
    targetOptions: function () {
      if (this.alignment.targets[0].sourceAlignment !== undefined) {
        let sourceAlignment =
          this.alignment.targets[0].sourceAlignment.toLowerCase();
        if (
          sourceAlignment === "top" ||
          sourceAlignment === "middle" ||
          sourceAlignment === "bottom"
        ) {
          return [
            { value: "top", text: "Top" },
            { value: "middle", text: "Middle" },
            { value: "bottom", text: "Bottom" },
          ];
        } else {
          return [
            { value: "left", text: "Left" },
            { value: "center", text: "Center" },
            { value: "right", text: "Right" },
          ];
        }
      } else {
        return [];
      }
    },
  },
  watch: {
    /**
     * Show the add alignment modal when the showAddAligmentModal in the store is updated
     * @param {boolean} newValue - the new value of the showAddAlignmentModal in the store
     */
    "$store.state.signDesigner.showAddAlignmentModal": function (
      newValue: boolean
    ) {
      //   const this = this;
      if (this.$store.getters.isTargetAlignmentSelected) {
        this.mode = "edit";
        let target: IAlignmentTarget = {
          uuid: "",
          isSelected: false,
          row: "",
          field: "",
          sourceAlignment: "",
          targetAlignment: "",
          offset: "",
        };
        // get the selected target
        for (
          var i = 0;
          i < this.$store.getters.selectedAlignment.targets.length;
          i++
        ) {
          if (this.$store.getters.selectedAlignment.targets[i].isSelected) {
            target = JSON.parse(
              JSON.stringify(this.$store.getters.selectedAlignment.targets[i])
            );
          }
        }
        this.alignment = {
          uuid: this.$store.getters.selectedAlignment.uuid,
          isSelected: this.$store.getters.selectedAlignment.isSelected,
          isInRepeat: this.$store.getters.selectedAlignment.isInRepeat,
          sortIndex: this.$store.getters.selectedAlignment.sortIndex,
          sourceField: this.$store.getters.selectedAlignment.sourceField,
          targets: [target],
          elementIds: this.$store.getters.selectedAlignment.elementIds,
        } as IAlignment;
      } else {
        this.mode = "add";
        this.alignment = {
          uuid: uuidv4(),
          isSelected: true,
          isInRepeat: this.$store.getters.selectedField.belongsToRepeatId
            ? true
            : false,
          sortIndex: this.$store.getters.alignments.length,
          sourceField: this.$store.getters.selectedField
            ? this.$store.getters.selectedField.name
            : "",
          targets: [
            {
              uuid: uuidv4(),
              isSelected: false,
              row: "current",
              field: this.targetFields[0].name,
              sourceAlignment: "top",
              targetAlignment: "bottom",
              offset: "1 " + this.$store.getters.signData.measurementUnit.unit,
            },
          ],
          elementIds: this.$store.getters.selectedElementIds,
        } as IAlignment;
      }
      this.originalAlignment = JSON.parse(JSON.stringify(this.alignment));
      this.showModal = newValue;
    },
  },
});
