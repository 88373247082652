import Snap from "snapsvg-cjs-ts";

export interface UnitValue {
  value: number;
  unit: string;
}

export const BadUnitValue: UnitValue = {
  value: NaN,
  unit: "",
};

// all units are normalized to points then have the pageScale applied
export class Units {
  private static instance: Units | null = null;
  public static inchToPoints = 72.0;
  public static feetToPoints = 864;
  public static yardToPoints = 2592;
  public static picaToPoints = 12.0;
  public static mmToPoints = 2.83465;
  public static cmToPoints = Units.mmToPoints * 10;
  public static meterToPoints = Units.cmToPoints * 10;

  public static UnitRex = /^(\w*)/gi;
  public static UnitReg = /([-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?)?\s*(\w*)/gi;
  public static UnitValueReg = /([-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?)/i;
  public static measurementUnits = [
    '"',
    "in",
    "inch",
    "'",
    "ft",
    "feet",
    "foot",
    "yard",
    "yd",
    "mm",
    "millimetre",
    "millimeter",
    "cm",
    "centimetre",
    "centimeter",
    "metre",
    "meter",
    "pi",
    "pica",
    "pc",
    "px",
    "pt",
    "point",
    "points",
  ];

  private _widthInPixels = NaN;
  public get widthInPixels(): number {
    return this._widthInPixels;
  }
  private _heightInPixels = NaN;
  public get heightInPixels(): number {
    return this._heightInPixels;
  }
  private _pageWidth: UnitValue = BadUnitValue;
  public get pageWidth(): UnitValue {
    return this._pageWidth;
  }
  private _pageHeight: UnitValue = BadUnitValue;
  public get pageHeight(): UnitValue {
    return this._pageHeight;
  }
  private _pageScale = NaN;
  public get pageScale(): number {
    return this._pageScale;
  }

  private constructor(rootSVG: Snap.Element) {
    this.setPageDimensions(rootSVG);
  }
  public setPageDimensions(rootSVG: Snap.Element): void {
    const svgBBox = rootSVG.getBBox();
    const widthReg = /(width=")(.*)(")/;
    const heightReg = /(height=")(.*)(")/;

    const innerHTML: string = rootSVG.node.innerHTML;
    let screenWidth = "";
    let screenHeight = "";

    const widthRes = widthReg.exec(innerHTML);
    const heightRes = heightReg.exec(innerHTML);

    if (widthRes) {
      screenWidth = widthRes[2];
    }

    if (heightRes) {
      screenHeight = heightRes[2];
    }

    if (screenWidth && screenHeight) {
      this._pageWidth = Units.parseUnitValue(screenWidth);
      this._pageHeight = Units.parseUnitValue(screenHeight);
      this._widthInPixels = svgBBox.width;
      this._heightInPixels = svgBBox.height;
    }

    this._pageScale =
      (this._widthInPixels / this._pageWidth.value) *
      this.unitFactor(this.pageWidth.unit);
  }

  public static getInstance(
    rootSVG: Snap.Element | undefined = undefined
  ): Units | null {
    if (!Units.instance) {
      if (rootSVG) {
        this.instance = new Units(rootSVG);
      } else {
        throw "Units: The first call to get instance must specify the SVG rootSVG";
      }
    } else {
      if (rootSVG) {
        this.instance = new Units(rootSVG);
      }
    }
    return Units.instance;
  }

  public static removeInstance(): Units | null {
    if (Units.instance) {
      Units.instance = null;
    }
    return Units.instance;
  }

  public static isMeasurementUnit(value: string): boolean {
    return Units.measurementUnits.includes(value);
  }

  /**
   * Parse the string extracting the value followed by the unit.
   * @param valueUnit : string
   */
  public static parseUnitValue(unitValue: string): UnitValue {
    Units.UnitReg.lastIndex = 0;
    const result = Units.UnitReg.exec(unitValue);
    const retValue: UnitValue = { value: NaN, unit: "px" };

    if (result) {
      retValue.value = Number(result[1]);
      retValue.unit = result[3];
    }
    return retValue;
  }
  /**
   * Converts the value from the specified measurement unit into screen pixels which is the unit of measure that all
   * calculations are performed
   * @param {Number} val - the value in the units specified
   * @param {String} unit - The unit of measure inches, feet, picas, millimeters, centimeters, and meters.
   * @returns {Number} - The val converted into points.
   */

  public normalizeMeasure(val: number | string, unit: string): number {
    let retval: number;
    if (typeof val == "string") {
      retval = parseFloat(val.replace(",", "."));
    } else {
      retval = val;
    }
    switch (unit) {
      case '"':
      case "in":
      case "inch":
        retval *= Units.inchToPoints * this.pageScale;
        break;

      case "'":
      case "ft":
      case "feet":
      case "foot":
        retval *= Units.feetToPoints * this.pageScale;
        break;

      case "yard":
      case "yd":
        retval *= Units.yardToPoints * this.pageScale;
        break;

      case "mm":
      case "millimetre":
      case "millimeter":
        retval *= Units.mmToPoints * this.pageScale;
        break;

      case "cm":
      case "centimetre":
      case "centimeter":
        retval *= Units.cmToPoints * this.pageScale;
        break;

      case "metre":
      case "meter":
        retval *= Units.meterToPoints * this.pageScale;
        break;

      case "pi":
      case "pica":
      case "pc":
        retval *= Units.picaToPoints * this.pageScale;
        break;

      case "pt":
      case "point":
      case "points":
        retval *= this.pageScale;
        break;

      case "px":
        break;

      default:
        retval *= this.pageScale;
        console.log(
          `normalizeMeasure: unit of measure ${unit} not recognized, defaulting to points`
        );
        break;
    }
    return retval;
  }

  /**
   * Returns the unitFactor to convert 1 unit measure into into screen pixel coordinates
   * @param unit
   */
  public unitFactor(unit: string): number {
    let retval;
    switch (unit) {
      case '"':
      case "in":
      case "inch":
        retval = (1 / Units.inchToPoints) * this.pageScale;
        break;

      case "'":
      case "ft":
      case "feet":
      case "foot":
        retval = (1 / Units.feetToPoints) * this.pageScale;
        break;

      case "yard":
      case "yd":
        retval = (1 / Units.yardToPoints) * this.pageScale;
        break;

      case "mm":
      case "millimetre":
      case "millimeter":
        retval = (1 / Units.mmToPoints) * this.pageScale;
        break;

      case "cm":
      case "centimetre":
      case "centimeter":
        retval = (1 / Units.cmToPoints) * this.pageScale;
        break;

      case "metre":
      case "meter":
        retval = (1 / Units.meterToPoints) * this.pageScale;
        break;

      case "pi":
      case "pica":
        retval = (1 / Units.picaToPoints) * this.pageScale;
        break;

      case "pt":
      case "point":
      case "points":
        retval = 1 * this.pageScale;
        break;
      case "px":
        retval = 1;
        break;
      default:
        retval = 1 * this.pageScale;
        console.log(
          `unit factor: unit of measure ${unit} not recognized, defaulting to points`
        );
        break;
    }
    return retval;
  }

  /**
   * Returns the conversionFactor to convert 1 unit measure into 1 point
   * @param unit
   */
  public conversionFactor(unit: string): number {
    let retval;
    switch (unit) {
      case '"':
      case "in":
      case "inch":
        retval = 1 / Units.inchToPoints;
        break;

      case "'":
      case "ft":
      case "feet":
      case "foot":
        retval = 1 / Units.feetToPoints;
        break;

      case "yard":
      case "yd":
        retval = 1 / Units.yardToPoints;
        break;

      case "mm":
      case "millimetre":
      case "millimeter":
        retval = 1 / Units.mmToPoints;
        break;

      case "cm":
      case "centimetre":
      case "centimeter":
        retval = 1 / Units.cmToPoints;
        break;

      case "metre":
      case "meter":
        retval = 1 / Units.meterToPoints;
        break;

      case "pi":
      case "pica":
        retval = 1 / Units.picaToPoints;
        break;

      case "pt":
      case "point":
      case "points":
        retval = 1;
        break;
      case "px":
        retval = 1;
        break;
      default:
        retval = 1;
        console.log(
          `conversion factor: unit of measure ${unit} not recognized, defaulting to points`
        );
        break;
    }
    return retval;
  }
}
