


































































import Vue from "vue";

export default Vue.extend({
  components: {},
  props: {},
  data: function () {
    return {
      showModal: false,
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showHistoryModal;
  },
  methods: {
    /**
     * save changes
     */
    saveChanges: function () {
      const lastChange = this.$store.getters.history.length - 1;
      const changeType = this.$store.getters.history[lastChange].type;
      const changePrev = this.$store.getters.history[lastChange].prev;
      this.$store.commit("popHistory");
      this.$store.commit(changeType, changePrev);
      this.$store.commit("popHistory");
      //close the modal
      this.$store.commit("setShowHistoryModal", false);
      // update the display
      this.$forceUpdate();
      this.$store.commit("setRerender", !this.$store.getters.rerender);
    },
    /**
     * close the modal
     * @param {Event} e - the event that triggered the close
     */
    closeClicked: function () {
      // close the modal
      this.$store.commit("setShowHistoryModal", false);
    },
  },
  computed: {},
  watch: {
    /**
     * when showHistoryModal from the store changes
     * @param {boolean} newValue - the value of the showHistoryModal from the store
     */
    "$store.state.signDesigner.showHistoryModal": function (newValue: boolean) {
      //   const this = this;
      this.showModal = newValue;
    },
  },
});
