


















































import Vue from "vue";
import { asMoney, asDate, capitalize } from "@/filters/common";
import { IInvoice } from "../types";

export default Vue.extend({
  props: ["invoice"],
  filters: {
    asMoney,
    asDate,
    capitalize,
  },
  data: function () {
    return {
      reason: "",
    };
  },
  methods: {
    isEmpty(obj: IInvoice): boolean {
      return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
    },
    toTitleCase(str: string): string {
      str = str.toLowerCase();
      return str.slice(0, 1).toUpperCase() + str.slice(1);
    },
  },
  computed: {},
  watch: {},
});
