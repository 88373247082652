




































































import Vue from "vue";
import { IPriceTier, IPricePlan, IBillingCycle } from "../types";

export default Vue.extend({
  methods: {
    priceTier: function (id: string): IPriceTier {
      return this.$store.getters.priceTierById(id);
    },
    pricePlan: function (id: string): IPricePlan {
      return this.$store.getters.pricePlanById(this.priceTier(id)?.pricePlanId);
    },
    billingCycle: function (priceTierId: string): IBillingCycle {
      return this.$store.getters.billingCycleById(
        this.priceTier(priceTierId).billingCycle
      );
    },
  },
  computed: {
    numberOfUsers: function (): number {
      return this.$store.getters.users.length;
    },
  },
});
