





















import Vue from "vue";
import { IProductType } from "../../types";

export default Vue.extend({
  props: {
    signData: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      signtypes: [
        { id: 1, name: "Department Id" },
        { id: 2, name: "Room Id" },
        { id: 3, name: "Amenity" },
      ],
    };
  },
  methods: {},
  computed: {
    filteredProductTypes: function (): Array<IProductType> {
      let result = [];
      if (this.$store.getters.isProductCategorySelected) {
        for (let i = 0; i < this.$store.getters.productTypes.length; i++) {
          let type = this.$store.getters.productTypes[i];
          for (
            let j = 0;
            j < this.$store.getters.productCategories.length;
            j++
          ) {
            const category = this.$store.getters.productCategories[j];
            if (category.isSelected) {
              if (type.categoryIds.includes(category.uuid)) {
                result.push(type);
              }
            }
          }
        }
      } else {
        result = this.$store.getters.productTypes;
      }
      return result;
    },
  },
});
