















































































































import Vue from "vue";

//import { Matthews } from "../types";
//import { Compact } from "vue-color"
import hexcodes from "../../assets/hexcodes.json";
import matthews from "../../assets/matthews.json";

import { iColor, IField, iHexCode, iMatthews } from "../../types";
import {
  rgbToHex,
  hexString,
  hexToRGB,
  findElementById,
  rgbStringToHex,
} from "../../utilities";

// import matthews from "./assets/matthews.json";

export default Vue.extend({
  components: {},
  data: function () {
    return {
      showModal: false,
      searchText: "",
      selectedLibrary: "hexcodes",
      selectedColors: [] as Array<iColor>,
      originalColor: {} as iColor,
      fieldColors: [] as Array<iColor>,
      inheritedColors: [],
      colorLibraries: [
        { id: 0, name: "Custom" },
        { id: 1, name: "Matthews Paint" },
        { id: 2, name: "Pantone PMS" },
        { id: 3, name: "Rowmark" },
      ],
    };
  },
  mounted: function () {
    this.showModal = this.$store.getters.showPickColorsModal;
  },
  methods: {
    getTextColor: function (hexColor: string) {
      if (hexColor.substr(0, 1) === "#") {
        hexColor = hexColor.slice(1);
      }
      const r = parseInt(hexColor.slice(0, 2), 16);
      const g = parseInt(hexColor.slice(2, 4), 16);
      const b = parseInt(hexColor.slice(4, 6), 16);
      if (0.213 * r + 0.715 * g + 0.072 * b > 127) {
        return "000000";
      } else {
        return "FFFFFF";
      }
    },

    /**
     * Add the clicked on color to the selected colors list
     * @param {iColor && iHexCode && iMatthews} color - the clicked on color
     */
    addToSelectedColors(color: iColor & iHexCode & iMatthews) {
      let newColor = {} as iColor;

      if (this.selectedLibrary === "hexcodes") {
        newColor.id = color.id;
        newColor.name = color.name;
        newColor.hex = color.hex;
      } else if (this.selectedLibrary === "matthews") {
        newColor.id = color.id;
        newColor.name = color.name;
        newColor.hex = rgbToHex(color.r, color.g, color.b);
      }
      this.selectedColors.push(newColor);
    },
    /**
     * remove the clicked on color from the selected colors list
     * @param {number} color_index - the selected colors list index
     */
    removeFromSelectedColors(color_index: number) {
      this.selectedColors.splice(color_index, 1);
    },
    /**
     * save changes
     */
    saveChanges: function () {
      //close the window
      this.$store.commit("setShowPickColorsModal", false);
    },
    /**
     * close the modal
     * @param {Event} e - the event that triggered the close
     */
    closeModal: function (e: Event) {
      this.$emit("colorSelected", this.originalColor);
      e.preventDefault();
      e.stopPropagation();
      this.$store.commit("setShowPickColorsModal", false);
    },
    /**
     * return the hexstring of a set of r, g, b colors
     * @param {number} r - the red color value
     * @param {number} g - the green color value
     * @param {number} b - the blue color value
     * @returns {string} a hex code string with the leading hash (i.e., #0176bc)
     */
    getRGBToHex: function (r: number, g: number, b: number): string {
      return rgbToHex(r, g, b);
    },
    getHexString: function (hexCode: string): string {
      return hexString(hexCode);
    },
  },
  computed: {
    /**
     * return the colors for the selected color library
     */
    getLibraryColors: function () {
      if (this.selectedLibrary === "hexcodes") {
        if (this.searchText === "") {
          return hexcodes;
        } else {
          return hexcodes.filter((hexcode): boolean => {
            return hexcode.name
              .toLowerCase()
              .includes(this.searchText.toLowerCase());
          });
        }
      } else if (this.selectedLibrary === "matthews") {
        if (this.searchText === "") {
          return matthews;
        } else {
          return matthews.filter((item): boolean => {
            return item.name
              .toLowerCase()
              .includes(this.searchText.toLowerCase());
          });
        }
      }
      return [];
    },
  },
  watch: {
    /**
     * when the showAddColorsModal in the store changes
     * @param {boolean} newValue - the value of the showAddColorsModal from the store
     */
    "$store.state.signDesigner.showPickColorsModal": function (newValue) {
      let element = findElementById(
        this.$store.getters.signData.sides[this.$store.getters.currentSide],
        this.$store.getters.selectedElementIds[0]
      );

      if (element) {
        let fillColor = "";
        if (element.fill.startsWith("rgb")) {
          fillColor = rgbStringToHex(element.fill);
        } else {
          fillColor = element.fill;
        }
        this.originalColor.hex = fillColor;
      }
      this.selectedColors = [];
      this.$store.getters.colors.forEach((color: iColor) => {
        this.selectedColors.push(color);
      });
      this.fieldColors = [];
      this.inheritedColors = [];

      this.$store.getters.fields.forEach((field: IField) => {
        if (field.type.startsWith("color")) {
          let color: iColor = {
            id: field.id,
            name: field.name,
            label: "",
            library: "",
            manufacturerId: "",
            match: "",
            hex: "#ffffff",
            rgb: hexToRGB("#ffffff"),
          };
          this.fieldColors.push(color);
        }
      });
      this.showModal = newValue;
    },
  },
});
