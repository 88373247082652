


























import Vue from "vue";
import { PropType } from "vue";
import { IDashboardState } from "../types";

export default Vue.extend({
  components: {},
  props: {
    states: {
      type: Array as PropType<IDashboardState[]>,
      required: true,
    },
  },
  data: function () {
    return {};
  },
  computed: {
    totalSigns: function () {
      let result = 0;
      this.states.forEach((state) => {
        result += state.signs.length;
      });
      return result;
    },
  },
});
