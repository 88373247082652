























































import Vue from "vue";
import { uppercase } from "@/filters/common";

export default Vue.extend({
  filters: {
    uppercase,
  },
  props: {
    title: {
      //the title of the section
      type: String,
      required: true,
    },
    initiallyOpen: {
      // should the section be initially open
      type: Boolean,
      default: true,
    },
    showPlusSign: {
      // show the add plus sign in the header
      type: Boolean,
      default: false,
    },
    showTrash: {
      // show the minus sign in the header
      type: Boolean,
      default: false,
    },
    open: {
      // is the section open
      type: Boolean,
      default: false,
    },
    showCaret: {
      // should we show the expnad/collapse caret beside the title
      type: Boolean,
      default: false,
    },
    isOpenable: {
      // should clicking on the header open up the section
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      isOpen: true,
    };
  },
  mounted: function () {
    if (this.open) {
      this.isOpen = this.open;
    } else {
      this.isOpen = this.initiallyOpen;
    }
  },
  methods: {
    toggleOpenFlag: function () {
      if (this.isOpenable) {
        this.isOpen = !this.isOpen;
      }
    },
  },
  computed: {},
  watch: {
    /**
     * if open variable changes
     * @param {boolean} value - the new value of open
     */
    open: function (value: boolean) {
      this.isOpen = value;
    },
  },
});
