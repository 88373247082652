import { render, staticRenderFns } from "./DateWidget.vue?vue&type=template&id=47ab6489&scoped=true&"
import script from "./DateWidget.vue?vue&type=script&lang=ts&"
export * from "./DateWidget.vue?vue&type=script&lang=ts&"
import style0 from "./DateWidget.vue?vue&type=style&index=0&id=47ab6489&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47ab6489",
  null
  
)

export default component.exports