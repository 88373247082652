var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"list-group-item user-permission",class:{ 'has-error': _vm.userPerm.error },on:{"mouseover":function($event){if (_vm.imageFailCount < 2) {
      _vm.imageFailed = false;
    }}}},[_c('div',[(_vm.userPerm.imageUrl && !_vm.imageFailed)?_c('img',{staticClass:"img-circle m-r",class:{
        'removed-user-image':
          _vm.userPerm.original_permission !== null &&
          _vm.userPerm.permission === null,
      },attrs:{"alt":"image","width":"38","height":"38","id":"image_thumbnail","src":_vm.userPerm.imageUrl},on:{"error":function($event){_vm.imageFailed = true;
        _vm.imageFailCount++;}}}):_vm._e()]),_c('div',{staticClass:"user-info"},[(_vm.userPerm.error)?_c('p',{staticClass:"help-block"},[_c('i',{staticClass:"fas fa-exclamation-triangle"}),_vm._v(" "+_vm._s(_vm.userPerm.error)+" ")]):_vm._e(),_c('p',{staticClass:"m-b-none",class:{
        strikethrough:
          _vm.userPerm.original_permission !== null &&
          _vm.userPerm.permission === null,
        'text-muted':
          _vm.userPerm.original_permission !== null &&
          _vm.userPerm.permission === null,
      }},[(_vm.userPerm.firstName || _vm.userPerm.lastName)?[_c('strong',[_vm._v(" "+_vm._s(_vm.userPerm.firstName)+" "+_vm._s(_vm.userPerm.lastName)+" ")]),_c('br'),_vm._v(" "+_vm._s(_vm.userPerm.email)+" ")]:_c('strong',[_vm._v(" "+_vm._s(_vm.userPerm.email)+" ")])],2)]),_c('div',{staticClass:"text-right"},[(!_vm.editable)?_c('div',{staticClass:"not-a-button",attrs:{"title":_vm.userPerm.permission === null
          ? ''
          : _vm.permissions.get(_vm.userPerm.permission).info}},[_vm._v(" "+_vm._s(_vm.userPerm.permission === null ? _vm.userPerm.original_permission === null ? "None" : "Remove" : _vm.permissions.get(_vm.userPerm.permission).name)+" ")]):_c('div',{staticClass:"btn-group m-t-xs m-b-xs"},[_c('div',{staticClass:"dropdown inline"},[_c('button',{staticClass:"btn dropdown-toggle btn-default",attrs:{"type":"button","title":_vm.userPerm.permission === null
              ? ''
              : _vm.permissions.get(_vm.userPerm.permission).info,"data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[(_vm.userPerm.permission !== null)?_c('span',[_vm._v(" "+_vm._s(_vm.permissions.get(_vm.userPerm.permission).name)+" "),_c('span',{staticClass:"caret"})]):_c('span',{staticClass:"fas fa-plus"})]),_c('ul',{staticClass:"dropdown-menu"},[_vm._l((_vm.permissions),function(ref){
              var id = ref[0];
              var permission = ref[1];
return _c('li',{key:id,attrs:{"title":permission.info}},[_c('a',{class:{
                strikethrough:
                  id == _vm.userPerm.original_permission &&
                  id !== _vm.userPerm.permission,
              },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('input', id)}}},[_c('span',{staticClass:"fa-check fas m-r-xs",class:{ invisible: _vm.userPerm.permission !== id }}),_vm._v(" "+_vm._s(permission.name)+" ")])])}),(
              _vm.userPerm.permission !== null ||
              _vm.userPerm.original_permission !== null
            )?[_c('li',{staticClass:"divider",attrs:{"role":"separator"}}),_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('input', null)}}},[_c('span',{staticClass:"fa-check fas m-r-xs",class:{ invisible: _vm.userPerm.permission !== null }}),_vm._v(" Remove ")])])]:_vm._e()],2)]),(_vm.userPerm.original_permission !== _vm.userPerm.permission)?_c('div',{staticClass:"inline"},[(_vm.userPerm.original_permission === null)?_c('i',{staticClass:"fas fa-plus-circle m-l-xs m-r-n-sm unsaved-bullet",attrs:{"title":"Save to add user permission"}}):(_vm.userPerm.permission === null)?_c('i',{staticClass:"fas fa-minus-circle m-l-xs m-r-n-sm unsaved-bullet",attrs:{"title":"Save to remove user permission"}}):_c('i',{staticClass:"fas fa-circle m-l-xs m-r-n-sm unsaved-bullet",attrs:{"title":"Save to accept change"}})]):_vm._e()]),(_vm.otherPerms && _vm.otherPerms.size > 0)?_vm._l((_vm.otherPerms),function(ref){
            var permObject = ref[0];
            var userPerm = ref[1];
return _c('p',{key:permObject.name || _vm.MODELS[permObject.objectType].name,staticClass:"m-t-xs m-b-xs m-l",attrs:{"title":_vm.otherPermsHint}},[_c('span',{staticClass:"other-perm"},[_c('span',{class:userPerm.permission === null && userPerm.original_permission
                ? ['strikethrough', 'text-muted']
                : []},[_c('strong',[_vm._v(" "+_vm._s(_vm.MODELS[permObject.objectType].permissions.get( userPerm.permission || userPerm.original_permission ).name)+" ")]),_vm._v(" "+_vm._s(_vm.MODELS[permObject.objectType].permissions.get( userPerm.permission || userPerm.original_permission ).preposition || "of")+" "+_vm._s(permObject.name || _vm.MODELS[permObject.objectType].name)+" ")]),(
              userPerm.permission && userPerm.original_permission === null
            )?_c('i',{staticClass:"fas fa-plus-circle m-r-xxs m-r-n-sm unsaved-bullet",attrs:{"title":"Save to add user permission"}}):(
              userPerm.original_permission && userPerm.permission === null
            )?_c('i',{staticClass:"fas fa-minus-circle m-r-xxs m-r-n-sm unsaved-bullet",attrs:{"title":"Save to remove user permission"}}):(userPerm.original_permission != userPerm.permission)?_c('i',{staticClass:"fas fa-circle m-r-xxs m-r-n-sm unsaved-bullet",attrs:{"title":"Save to accept change"}}):_vm._e()])])}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }