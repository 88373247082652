






























































































































































































import Vue from "vue";
import { IField, iSignChild } from "../../types";
import {
  updateSVGDataName,
  updateSVGAttribute,
  convertPointsToUnit,
  convertUnitToPoints,
} from "../../utilities";
import CollapsibleSection from "../CollapsibleSection.vue";
import MeasurementInput from "../MeasurementInput.vue";

export default Vue.extend({
  components: { CollapsibleSection, MeasurementInput },
  data: function () {
    return {};
  },
  updated: function () {
    // let field: IField | null = this.findFieldByElementId(
    //   this.$store.getters.selectedElementIds[0]
    // );
    // if (field) {
    //   this.width = field.width;
    //   this.height = field.height;
    // } else {
    //   let element = this.findElementById(
    //     this.$store.getters.signData.sides[this.$store.getters.currentSide],
    //     this.$store.getters.selectedElementIds[0]
    //   );
    //   if (element) {
    //     this.width = element.width;
    //     this.height = element.height;
    //   }
    // }
  },
  methods: {
    /**
     * find sign child element by id
     * @param {iSignChild} element - the sign child element to start searching at
     * @param {string} id - the element id we are looking for
     * @returns {iSignChild | null} The sign child element that was found or null if none found
     */
    findElementById: function (
      element: iSignChild,
      id: string
    ): iSignChild | null {
      if (element.id == id) {
        return element;
      } else if (element.children != null) {
        var i;
        var result = null;
        for (i = 0; result == null && i < element.children.length; i++) {
          result = this.findElementById(element.children[i], id);
        }
        return result;
      }
      return null;
    },
    /**
     * find field element by id
     * @param {string} id - the element id we are looking for
     * @returns {iField | null} The field that was found or null if none found
     */
    findFieldByElementId: function (id: string): IField | null {
      for (let i = 0; i < this.$store.getters.fields.length; i++) {
        for (
          let elementIndex = 0;
          elementIndex < this.$store.getters.fields[i].elementIds.length;
          elementIndex++
        ) {
          if (this.$store.getters.fields[i].elementIds[elementIndex] === id) {
            return this.$store.getters.fields[i];
          }
        }
      }
      return null;
    },
    /**
     * set the vertical alignment
     * @param {string} alignment - a vertical alignment string (bottom | middle | top)
     */
    setVerticalAlignment: function (alignment: string) {
      this.verticalAlignment = alignment;
    },
    /**
     * set the horizontal alignment
     * @param {string} alignment - a horizontal alignment string (left | center | right)
     */
    setHorizontalAlignment: function (alignment: string) {
      this.horizontalAlignment = alignment;
    },
  },
  computed: {
    /**
     * get the signChild element for the first selected element id
     * @returns {iSignChild | null} the found signChild or null if none found
     */
    element: function (): iSignChild | null {
      return this.findElementById(
        this.$store.getters.signData.sides[this.$store.getters.currentSide],
        this.$store.getters.selectedElementIds[0]
      );
    },
    /**
     * should the section be shown open?
     * @returns {boolean} True if section should be shown open
     */
    openSection: function () {
      if (this.element) {
        const fieldType = this.element.fieldType.toLowerCase();
        if (fieldType === "visual" || fieldType.startsWith("icon")) {
          return true;
        }
      }
      return false;
    },
    /**
     * getter/setter for width
     */
    width: {
      get(): number {
        if (this.$store.getters.selectedField) {
          return convertPointsToUnit(
            this.$store.getters.selectedField.width,
            this.$store.getters.signData.measurementUnit.unit
          );
        } else {
          if (this.element) {
            return convertPointsToUnit(
              this.element.width,
              this.$store.getters.signData.measurementUnit.unit
            );
          } else {
            return 0;
          }
        }
      },
      set(value: string) {
        let width = convertUnitToPoints(
          parseFloat(value),
          this.$store.getters.signData.measurementUnit.unit
        );
        updateSVGAttribute(
          this.$store.getters.selectedField.elementIds[0],
          this.$store.getters.selectedField.type,
          "width",
          width.toString(),
          this.$store.getters.canvas
        );
        this.$store.commit("updateSelectedFieldWidth", width);
        this.$store.commit("setRerender", !this.$store.getters.rerender);
      },
    },
    /**
     * getter/setter for height
     */
    height: {
      get(): number {
        if (this.$store.getters.selectedField) {
          return convertPointsToUnit(
            this.$store.getters.selectedField.height,
            this.$store.getters.signData.measurementUnit.unit
          );
        } else {
          if (this.element) {
            return convertPointsToUnit(
              this.element.height,
              this.$store.getters.signData.measurementUnit.unit
            );
          } else {
            return 0;
          }
        }
      },
      set(value: string) {
        let height = convertUnitToPoints(
          parseFloat(value),
          this.$store.getters.signData.measurementUnit.unit
        );
        updateSVGAttribute(
          this.$store.getters.selectedField.elementIds[0],
          this.$store.getters.selectedField.type,
          "height",
          height.toString(),
          this.$store.getters.canvas
        );
        this.$store.commit("updateSelectedFieldHeight", height);
        this.$store.commit("setRerender", !this.$store.getters.rerender);
      },
    },
    /**
     * getter/setter for verticalAlignment
     */
    verticalAlignment: {
      get(): string {
        if (this.$store.getters.selectedField) {
          return this.$store.getters.selectedField.verticalAlignment;
        } else {
          return "top";
        }
      },
      set(value: string) {
        updateSVGDataName(
          this.$store.getters.selectedField.elementIds[0],
          this.$store.getters.selectedField.type,
          this.$store.getters.selectedField.verticalAlignment,
          value,
          this.$store.getters.canvas
        );
        this.$store.commit("updateSelectedFieldVerticalAlignment", value);
      },
    },
    /**
     * getter/setter for horizontalAlignment
     */
    horizontalAlignment: {
      get(): string {
        if (this.$store.getters.selectedField) {
          return this.$store.getters.selectedField.horizontalAlignment;
        } else {
          return "left";
        }
      },
      set(value: string) {
        updateSVGDataName(
          this.$store.getters.selectedField.elementIds[0],
          this.$store.getters.selectedField.type,
          this.$store.getters.selectedField.horizontalAlignment,
          value,
          this.$store.getters.canvas
        );
        this.$store.commit("updateSelectedFieldHorizontalAlignment", value);
      },
    },
  },
  watch: {},
});
